import PropTypes from 'prop-types';
import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import { FAIcon } from 'reactifi';

const PlainTextTable = ({ data, fields, infoText }) => (
  <div className="plain-text-table">
    {infoText && <div className="info-text">{infoText}</div>}
    <div className="table-wrapper">
      <table>
        <thead>
          <tr>
            {fields.map(field => (
              <th>
                {field.title}
                {field.tooltip && (
                  <OverlayTrigger
                    placement="top"
                    overlay={<Tooltip>{field.tooltip}</Tooltip>}>
                    <span>
                      <FAIcon icon="circle-info" additionalClasses="text-info m-l-5" />
                    </span>
                  </OverlayTrigger>
                )}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map(item => (
            <tr>
              {fields.map(field => <td>{item[field.name]}</td>)}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  </div>
);

PlainTextTable.propTypes = {
  data: PropTypes.array.isRequired,
  fields: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      tooltip: PropTypes.string
    })
  ).isRequired,
  infoText: PropTypes.string
};

export default PlainTextTable;
