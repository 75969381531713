import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  buildGenericProps,
  JsonApiDecorator,
  ConfirmationModal,
  InfoTooltip,
  Page,
  ToggleCheckbox,
  Toolbar,
  ListView,
  FAIcon,
  Util
} from "reactifi";
import SAMLModal from '../components/SAMLModal';
import i18n from "lib/i18n";

import * as identityProvidersActionCreators from "../actions/identityProvidersActionCreators";

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "saml_id_providers", {});
  props.organizationId = ownProps.route.organization_id;

  props.successMessage = state.api.successMessage;
  props.errorMessage = state.api.errorMessage;

  let apiStore = new JsonApiDecorator(state.api);
  if (apiStore.saml_id_providers) {
    props.saml_id_providers = apiStore.saml_id_providers.all();
  }
  if (apiStore.organizations) {
    props.organization = apiStore.organizations.find(props.organizationId);
  }
  if (apiStore.saml_metadata) {
    props.samlMetadata = apiStore.saml_metadata.data[0].attributes;
  }

  return props;
}

class IdentityProvidersContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    organizationId: PropTypes.string,
    organization: PropTypes.object,
    saml_id_providers: PropTypes.array,
    samlMetadata: PropTypes.object,
    item: PropTypes.any,
    itemId: PropTypes.any,
    isLoadingSamlIdProviders: PropTypes.bool,
    router: PropTypes.any,
    errorMessage: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      identityProvidersActionCreators,
      this.props.dispatch
    );

    this.state = {
      showModal: false,
      showSamlModal: false,
      tempItem: {}
    };
  }

  componentDidMount() {
    this.actions.findOrganization(this.props.organizationId);
  }

  componentDidUpdate() {
    if (this.props.organization && !this.props.samlMetadata) {
      this.actions.getSamlMetadata(this.props.organization.slug);
    }
  }

  deleteAction = (event, Item) => {
    this.setState({ showModal: true, tempItem: Item });
  }

  deleteItem = async () => {
    const { organization } = this.props;
    if (organization.sso_exclusive &&
      this.props.saml_id_providers.length === 1) {
      organization.sso_exclusive = false;
      await this.actions.updateOrganization(organization);
    }
    await this.actions.deleteItem(this.state.tempItem);
    this.setState({ showModal: false, tempItem: {} });
    this.actions.loadItems();
  }

  addIdentityProvider = () => {
    this.props.router.push('/add');
  }

  toggleSsoExclusive = () => {
    const { organization } = this.props;
    organization.sso_exclusive = !organization.sso_exclusive;
    this.actions.updateOrganization(organization);
  }

  toggleSamlModal = () => {
    this.setState({ showSamlModal: !this.state.showSamlModal });
  }

  checkSsoTogglePermissions = () => {
    const { organization, saml_id_providers } = this.props;
    const hasAltLogin = organization?.capability_list?.includes("Capabilities::AlternativeLogin");
    const hasSso = organization && saml_id_providers?.length > 0;
    return hasSso && !hasAltLogin;
  }

  render() {
    let fields = [
      { name: "display_name", title: i18n.t("Display Name"), width: '20%', canSort: true },
      { name: "idp_entity_id", title: i18n.t("Entity Id"), width: '20%', canSort: true },
      {
        name: "idp_sso_target_url",
        title: i18n.t("SSO Login URL"),
        width: '40%',
        canSort: true
      },
      {
        name: "default_user_type",
        title: i18n.t("Default User Type"),
        width: '20%',
        canSort: true
      }
    ];

    const actions = [
      { type: 'edit', actionType: 'link', action: row => `/${row.id}` },
      { type: 'delete', actionType: 'callToAction', action: this.deleteAction }
    ];

    const options = {
      onRowClick: (e, row) => this.props.router.push(`/${row.id}`)
    };

    let actionButton = {
      action: this.addIdentityProvider,
      label: i18n.t("New Identity Provider"),
      ...Util.dataAttribsForEl(this.props.organization, { action: 'add-new-identiy-provider' })
    };

    const canToggleSsoExclusive = this.checkSsoTogglePermissions();

    let toolbar = (
      <Toolbar toolbarClass="identity-providers-toolbar">
        <div className="saml-metadata">
          <span className="saml-metadata-label">
            {i18n.t('EVERFI SAML Metadata:')}
          </span>
          {this.props.organization &&
            <a onClick={this.toggleSamlModal}>
              <FAIcon icon="eye" /> {i18n.t("View")}
            </a>
          }
        </div>
        {canToggleSsoExclusive ?
          <div className="text-end sso-exclusive">
            <ToggleCheckbox
              className="round sm"
              checked={this.props.organization.sso_exclusive}
              onChange={this.toggleSsoExclusive}
              id="toggle_sso_exclusive"
              label={i18n.t('Use SSO Exclusively')}
            />
            <InfoTooltip
              name="sso_exclusive"
              label={i18n.t('More information about Use SSO Exclusively')}
              tooltip={i18n.t('Turning this on will require all users to login using your Identity Providers. If this is off, users will be able to login through Foundry credentials or through your Identity Providers.')} />
          </div> : null}
      </Toolbar>
    );

    const deleteMessage = (<div>
      <div>{
        i18n.t("Are you sure you want to delete this identity provider?")
      }</div>
      {canToggleSsoExclusive &&
        this.props.organization.sso_exclusive &&
        this.props.saml_id_providers.length === 1 ?
        (<div className="m-t-15">
          <FAIcon icon="triangle-exclamation" size="medium" color="yellow" additionalClasses="m-r-5" />
          {
            i18n.t("Warning: You currently only have one IdP configured. Deleting it will disable the Use SSO Exclusively option you currently have enabled. Are you sure you want to proceed?")
          }
        </div>) : null}
    </div>);

    return (
      <Page title={i18n.t('Identity Providers')}
        pageType="parent"
        contentType="list"
        actionButton={actionButton}
        toolbar={toolbar}
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        clearMessages={this.actions.clearMessages}
        usePageWrapper={true}
      >
        <ListView
          {...this.props}
          keyField="id"
          data={this.props.saml_id_providers}
          loadAction={this.actions.loadItems}
          isLoadingData={this.props.isLoadingSamlIdProviders || !this.props.organization}
          fields={fields}
          actions={actions}
          options={options}
          totalCount={this.props.saml_id_providers.length}
          fullPage={false}
          hasPagination={false}
          noRecordsMessage="No Identity Providers have been found."
        />

        <ConfirmationModal
          title={i18n.t("Delete Identity Provider")}
          children={deleteMessage}
          show={this.state.showModal}
          onConfirm={this.deleteItem}
          onCancel={() => this.setState({ showModal: false })}
        />
        {this.props.organization &&
          <SAMLModal
            showSamlModal={this.state.showSamlModal}
            toggleSamlModal={this.toggleSamlModal}
            info={this.props.samlMetadata}
            downloadUrl={`/${this.props.organization.slug}/saml/metadata`}
            downloadCertificate={`/${this.props.organization.slug}/saml/metadata?only_certificate=true`}
          />
        }
      </Page>
    );
  }
}

export default connect(mapStateToProps)(IdentityProvidersContainer);
