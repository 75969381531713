import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import {
  FieldGroup,
  FloatingLabelFormField,
  MaterialCheckbox,
  ModalForm,
  FAIcon
} from 'reactifi';
import i18n from 'lib/i18n';
import { addDays } from 'date-fns';
import { Util } from "@everfi/reactifi"
import { CheckboxConfirmationGatewayModal } from 'lib/components/CheckboxConfirmationGatewayModal';

const DEFAULT_REDACTION_DELAY_DAYS = 90;

export default class OrganizationDetailsModal extends Component {
  static defaultProps = {
    data: {}
  };

  static propTypes = {
    data: PropTypes.object,
    showModal: PropTypes.bool.isRequired,
    onCancel: PropTypes.func.isRequired,
    updateAction: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    viewer: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.state = {
      demo: props.data.demo,
      active: props.data.active
    };
  }

  getRedactionDelayDays = () => {
    return this.props.data?.redaction_delay_days || DEFAULT_REDACTION_DELAY_DAYS;
  }

  getRedactionDate() {
    const today = new Date();
    return Util.format(addDays(today.toString(), this.getRedactionDelayDays()), 'MM/dd/yyyy');
  }

  getRedactionMessage() {
    return (
      <Fragment>
        <p>
          {i18n.t("By deactivating this account, the organization's data will be completely redacted in {{ days }} days.", {
            days: this.getRedactionDelayDays()
          })}
        </p>
        <p>
          {i18n.t(
            'Prior to redaction the account can be reactivated, but once the redaction is complete it cannot be reversed an users will no longer be able to access this org.'
          )}
        </p>
        <p>
          {i18n.t(
            "If this account is deactivated, it's data will be redacted on "
          )}
          <strong>{this.getRedactionDate()}</strong>.
        </p>
      </Fragment>
    );
  }

  getRedactionHelperText() {
    const { data } = this.props;
    const { active } = this.state;

    if (active) return null;

    let message = i18n.t('Org data will redact on {{ date }}', {
      date: data?.redaction_scheduled_at
        ? Util.format(data.redaction_scheduled_at, 'MM/dd/yyyy')
        : this.getRedactionDate()
    });

    if (data?.redacted_at) {
      message = i18n.t('Org data redacted on {{ date }}', {
        date: Util.format(data.redacted_at, 'MM/dd/yyyy')
      });
    }

    return (
      <span>
        <FAIcon icon="circle-info" color="red" /> {message}
      </span>
    );
  }

  render() {
    const { errorMessage, showModal, onCancel, data, updateAction } =
      this.props;

    return (
      <ModalForm
        title={i18n.t('Edit Organization')}
        data={data}
        show={showModal}
        onCancel={onCancel}
        bsSize="lg"
        updateAction={updateAction}
        errorMessage={errorMessage}
        saveAndAddButton={false}
        saveButtonText={i18n.t('Update')}
        saveButtonDisabled={!!data?.redacted_at}
      >
        <FieldGroup
          className="container-fluid organization-details"
          disabled={!!data?.redacted_at}
        >
          <div className="row">
            <div className="col-12">
              <h4>{i18n.t('Organization Details')}</h4>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="name"
                caption={i18n.t('Name')}
                required={true}
                className="col-half-width"
              />
            </FieldGroup>
          </FieldGroup>
          {!this.state.demo && (
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t('Salesforce Alternative Acct Code')}
                  className="col-half-width"
                  name="salesforce_id"
                  required={true}
                />
              </FieldGroup>
            </FieldGroup>
          )}
          {!this.state.demo && (
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t('Blackbaud Salesforce ID')}
                  className="col-half-width"
                  name="bb_salesforce_id"
                  required={true}
                />
              </FieldGroup>
            </FieldGroup>
          )}
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Slug')}
                className="col-half-width"
                name="slug"
                required={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Google Analytics ID')}
                className="col-half-width"
                name="google_analytics_id"
                required={false}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t(
                  'Sender Alias - if blank, Name of org will be used'
                )}
                className="col-half-width"
                name="sender_alias"
                required={false}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Organization redirect')}
                className="col-half-width"
                name="org_redirect_url"
                required={false}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <CheckboxConfirmationGatewayModal
                component={MaterialCheckbox}
                data-attribute="active"
                data-object-id={data.id}
                data-object-type={'organizations'}
                description={this.getRedactionMessage()}
                name="active"
                onChange={(e) => this.setState({ active: e })}
                title={i18n.t('Deactivate Account')}
                tooltip={{
                  label: i18n.t('More information about active accounts'),
                  name: 'active',
                  tooltip: i18n.t(
                    'If an account is not marked as active, no progress can be made on assignments and learners will not be able to log in.'
                  ),
                  tooltipPosition: 'top'
                }}
              >
                {i18n.t('Active account')}
              </CheckboxConfirmationGatewayModal>
            </FieldGroup>
            <FieldGroup className="col mb-1 ps-4">
              {this.getRedactionHelperText()}
            </FieldGroup>
          </FieldGroup>
          {this.props.viewer === 'admin' && (
            <FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <MaterialCheckbox
                    data-attribute="demo"
                    data-object-type={'organizations'}
                    data-object-id={data.id}
                    name="demo"
                    onChange={(e) => this.setState({ demo: e })}
                    tooltip={{
                      name: 'demo',
                      label: i18n.t('More information about demo accounts'),
                      tooltip: i18n.t(
                        'Demo accounts are used for internal testing or prospects, and allow assignments with ungated content. Data from demo orgs is excluded from reporting.'
                      ),
                      tooltipPosition: 'top'
                    }}
                  >
                    {i18n.t('Demo account')}
                  </MaterialCheckbox>
                </FieldGroup>
              </FieldGroup>
            </FieldGroup>
          )}
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <MaterialCheckbox
                data-attribute="editable_fields"
                data-object-type={'organizations'}
                data-object-id={data.id}
                name="editable_fields"
                tooltip={{
                  name: 'editable_fields',
                  label: i18n.t(
                    'More information about editable personal information'
                  ),
                  tooltip: i18n.t(
                    'This is an account default, but can be overridden for any user(s). Users with this checked can change their password and other personal details.'
                  ),
                  tooltipPosition: 'top'
                }}
              >
                {i18n.t('Allow users to edit their personal information')}
              </MaterialCheckbox>
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    );
  }
}
