import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ModalForm, FieldGroup, FloatingLabelFormField, SelectControl } from 'reactifi';

import i18n from 'lib/i18n';

class OrganizationUserGroupingsModal extends Component {

  static propTypes = {
    categories: PropTypes.any.isRequired,
    user: PropTypes.any,
    errorMessage: PropTypes.any,
    selectedCategories: PropTypes.object,
    showManageCategories: PropTypes.bool,
    onCancel: PropTypes.func,
    saveUserGroupValue: PropTypes.func
  };

  constructor(props) {
    super(props);
  }

  render() {
    if (!this.props.user){
      return null;
    }
    return (
      <ModalForm title={i18n.t('Manage Labels for ') + this.props.user.first_name + ' ' + this.props.user.last_name} data={this.props.selectedCategories} show={this.props.showManageCategories} onCancel={this.props.onCancel} bsSize="lg"
        addAction={this.props.saveUserGroupValue} errorMessage={this.props.errorMessage} saveAndAddButton={false} saveButtonText={i18n.t('Save')}>
        {this.props.categories.length ? this.props.categories.map(function(category, index) {
          let categoryLabelOptions = category.category_labels.map(categoryLabel => {
            return {
              label: categoryLabel.name,
              value: categoryLabel.id
            }
          });
          let categoryDataValue = category.label;

          return (
            <FieldGroup className="row" key={index}>
              <FloatingLabelFormField className="col-md-12" name={`group_value_${category.id}`} dataValue={categoryDataValue} caption={i18n.t(category.name)}>
                <SelectControl options={categoryLabelOptions} multi={false} valueKey="value" labelKey="label" valueOverride={false} />
              </FloatingLabelFormField>
            </FieldGroup>
          );
        }) : <p>{i18n.t('No Custom Label found for user\'s organization')}</p> }
      </ModalForm>
    )
  }
}

OrganizationUserGroupingsModal.defaultProps = {
  categories: []
}

export default OrganizationUserGroupingsModal;
