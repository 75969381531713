import {
  createEntity,
  selectEntity,
  updateEntity,
  displayError,
  readEntityById,
  readEntity,
  readLocal,
  deleteEntity,
  clearApiState,
  displaySuccessMessage,
  ApiUtil
} from "reactifi";
import i18n from "lib/i18n";

export { clearMessages, displayError } from "reactifi";

//Config API
const apiTarget = "categories";

export function createCategoryLabel(category_label, afterSave) {
  return createEntity(
    category_label,
    dispatch => {
      // do it this way so the modal unloads and reloads, which makes sure the form
      // clears
      dispatch(selectEntity(null, apiTarget));
      if (afterSave) afterSave();
    },
    i18n.t("Custom Label created successfully!")
  );
}

export function loadItems($filters = {}, page, sort) {
  if (!sort) {
    sort = { value: "name", direction: "asc" };
  }
  return readEntity(
    apiTarget,
    "category_labels",
    $filters,
    sort && [sort],
    page
  );
}

export function loadItem(id) {
  return readEntityById(id, apiTarget, "category_labels");
}

export function selectItem(id) {
  return selectEntity(id, apiTarget, "category_labels");
}

export function deleteCategory(category) {
  return deleteEntity(category, 'categories', i18n.t('Category deleted successfully.'))
}

export function updateItem(item, afterSave) {
  return updateEntity(
    item,
    dispatch => {
      dispatch(selectEntity(null, apiTarget));
      if (afterSave) afterSave();
    },
    i18n.t("Custom Label updated successfully")
  );
}

export function deleteItem(item, multiple = false) {
  return async (dispatch) => {
    try {
      await fetch(`/api/data/categories/${item.category_id}/category_labels/${item.id}`, {
        method: 'DELETE',
        headers: {
          'Accept': 'application/vnd.api+json',
          'Content-Type': 'application/vnd.api+json',
          'Cache': 'no-cache',
          'X-HTTP-SEARCH-INDEX-METHOD': 'synchronous',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        credentials: 'include'
      });

      if(!multiple) {
        dispatch(displaySuccessMessage(i18n.t('Custom Label deleted successfully.')));
      }
    } catch (e) {
      dispatch(displayError(e));
    }
  };
}

export function loadUsersWithLabel(labelId) {
  return readEntity('users', '', { category_label_ids: [labelId] }, null, { number: 1, size: 1 });
}

export function loadTeamsWithLabel(labelId) {
  return readEntity('teams', '', { category_label_ids: [labelId] }, null, { number: 1, size: 1 });
}

export function loadAssignmentsWithLabel(contentServiceUrl, token, labelId) {
  return async dispatch => {
    try {
      const url = `${contentServiceUrl}/api/v1/dynamic_assignment_filters?filter[category_label_ids][]=${labelId}&page[number]=1&page[size]=1`;
      let data = await ApiUtil.loadExternalData(url, token, false);
      data.endpoint = url;

      dispatch(clearApiState('assignment_dynamic_assignment_filters'));
      dispatch(readLocal(data));
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}
