import { useConfirmAction } from 'reactifi';
import i18n from "lib/i18n";

const useInvitePlatformConfirmAction = (action, email) => {
  return useConfirmAction({
    action,
    children: i18n.t('An email will be sent to {{ email }}', { email }),
    confirmButtonText: i18n.t('Invite to Platform'),
    title: i18n.t('Invite to Platform?')
  })
};

export default useInvitePlatformConfirmAction;
