import React from "react";
import PropTypes from "prop-types";
import { ListModule, useContainerProps } from "reactifi";
import { Util } from "@everfi/reactifi";
import i18n from "lib/i18n";

const BenchmarksList = props => {
  props = useContainerProps(props);
  const { deleteMember, selectMember } = props;

  const actionConfig = [
    {
      type: "edit",
      selectMember
    },
    {
      type: "delete",
      actionType: "confirm",
      confirmTitle: i18n.t("Delete Benchmark Attribute"),
      confirmChildren: i18n.t("Are you sure you want to delete this benchmark attribute? It may impact benchmark reports."),
      deleteMember
    }
  ];

  const fields = [
    { name: 'name', title: i18n.t('Name'), canSort: true, defaultSort: true, width: '100%' },
    { name: 'groups_count', title: i18n.t('Count Values'), canSort: true, width: '180' },
    { name: 'orgs_count', title: i18n.t('Count Orgs Using'), canSort: true, width: '180' },
    {
      name: 'updated_at', title: i18n.t('Last Modified'), canSort: true, width: '180',
      formatter: (cell) => {
        return Util.format(cell, 'yyyy-MM-dd');
      }
    }
  ];

  const options = {
    onRowClick: (_, row) => {
      selectMember(row.id)
    }
  };

  return (
    <>
      <ListModule
        {...props}
        fields={fields}
        actions={actionConfig}
        options={options}
      />
    </>
  );
};

BenchmarksList.propTypes = {
  deleteMember: PropTypes.func,
  selectMember: PropTypes.func
};

export default BenchmarksList;
