import React from "react";
import PropTypes from "prop-types";
import { Button, CopyUtil, IconCategoryValue, IconCategoryValueGroup } from "reactifi";
import i18n from "lib/i18n";
import { Util } from "@everfi/reactifi";

const formatDate = date => {
  if (date) return Util.format(date, "LLL d, yyyy h:mm a");
  return i18n.t('N/A');
};

const HeroColumn3 = props => {
  const { cportal_url, unlimitedAccess, user = {} } = props;
  return (
    <>
      <IconCategoryValue
        category={i18n.t('User Status')}
        value={user.active ? i18n.t('Active') : i18n.t('Inactive')}
        data-action="user-status"
      />
      {unlimitedAccess && (
        <IconCategoryValueGroup>
          <IconCategoryValue
            category={i18n.t('User Created')}
            value={formatDate(user.created_at)}
            data-action="user-created-date"
          />
          <IconCategoryValue
            category={i18n.t('Last Updated')}
            value={formatDate(user.updated_at)}
            data-action="user-updated-date"
          />
          <IconCategoryValue
            category={i18n.t('Last Login')}
            value={formatDate(user.current_sign_in_at)}
            data-action="last-login-date"
          />
        </IconCategoryValueGroup>
      )}
      {unlimitedAccess && user['is_learner?'] && (
        <Button
          icon="copy"
          label={i18n.t('Copy Login URL')}
          onClick={() => CopyUtil.copyText(cportal_url)}
          style="link"
        />
      )}
    </>
  )
};

HeroColumn3.propTypes = {
  cportal_url: PropTypes.string,
  unlimitedAccess: PropTypes.bool,
  user: PropTypes.shape({
    active: PropTypes.bool,
    created_at: PropTypes.string,
    current_sign_in_at: PropTypes.string,
    "is_learner?": PropTypes.bool,
    updated_at: PropTypes.string
  })
};

export default HeroColumn3;
