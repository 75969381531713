import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from 'reactifi';
import LocationsContainer from './containers/LocationsContainer';
import createStore from './store/locationsStore';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

const LocationsIndex = (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route path="/" component={LocationsContainer} googleMapsApiKey={_railsContext.googleMapsApiKey} viewer={props.viewer}
          organizationId={props.organization_id}/>
      </Router>
    </RootProvider>
  );
};

LocationsIndex.propTypes = {
  viewer: PropTypes.string,
  organization_id: PropTypes.number
};

export default LocationsIndex;
