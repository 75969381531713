import React from 'react';
import { createContainer } from 'reactifi';
import TagManager from '../components/TagManager';
import config from '../configs/TagManagersContainerConfig';

const Container = createContainer(config);

const TagManagerContainer = props => {
  return (
    <Container {...props}>
      <TagManager />
    </Container>
  );
};

export default TagManagerContainer;
