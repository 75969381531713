import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Util } from "@everfi/reactifi"

import { PagingDataList, StringUtil, AccessControlUtil, ActionButtons, ComplexTableColumn } from 'reactifi';
import { isBulkAwardsDownloadEnabled } from '../../../externals/components/functions/isBulkAwardsDownloadEnabled';
import i18n from 'lib/i18n';
import AwardsDownloadModal from './userDetails/AwardsDownloadModal';

export default class UserDetailCourses extends Component {
  static propTypes = {
    businessLine: PropTypes.shape({
      id: PropTypes.string,
      slug: PropTypes.string,
      label: PropTypes.string,
      description: PropTypes.string
    }),
    capabilityList: PropTypes.object,
    clearTrainingPeriods: PropTypes.func,
    clearUserAssignments: PropTypes.func,
    clearUserProgress: PropTypes.func,
    checkSimulatable: PropTypes.func,
    contentServiceUrl: PropTypes.string,
    currentUserId: PropTypes.string,
    currentViewer: PropTypes.shape({
      all_managed_business_lines: PropTypes.arrayOf(PropTypes.string),
      primary_managed_business_lines: PropTypes.array,
      id: PropTypes.string
    }),
    isFitAdmin: PropTypes.bool,
    loadingProgress: PropTypes.bool,
    onPageChange: PropTypes.func,
    organizationId: PropTypes.string,
    permissions: PropTypes.arrayOf(PropTypes.string),
    router: PropTypes.object,
    trainingPeriods: PropTypes.array,
    user: PropTypes.object,
    userAwards: PropTypes.array,
    userProgresses: PropTypes.array,
    userProgressMeta: PropTypes.object,
    viewer: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoadingActivities: false,
      showAwardsModal: false,
      selectedAwards: null
    }
  }

  componentWillUnmount() {
    this.props.clearUserAssignments();
    this.props.clearUserProgress();
    this.props.clearTrainingPeriods();
  }

  showAssignmentProgress = (e, row) => {
    const { currentUserId, organizationId } = this.props;
    const { user_assignment_id, progress: { id: progress_id } } = row;
    if (row.participation_status !== 'error') {
      const url = this.props.viewer === 'admin' ?
        `/${organizationId}/users/${currentUserId}/assignments/${user_assignment_id}/progress/${progress_id}` :
        `/${currentUserId}/assignments/${user_assignment_id}/progress/${progress_id}`;
      this.props.router.push(url);
    }
  }

  getUserProgressData = (userProgresses) => {
    return userProgresses.map((progress) => {
      const { trainingPeriods } = this.props;
      let progressTrainingPeriod = trainingPeriods.find((tp) => tp.id === String(progress?.assignments?.[0]?.training_period_id));
      let composedData = [];
      composedData = Object.assign({}, progress, {
        progress_id: progress.id,
        name: progress.content?.name ?? '',
        content_id: progress.content?.id ?? '',
        content_type: progress.content?.content_type ?? '',
        user_assignment_ids: progress.user_assignments ? progress.user_assignments.map(ua => ua.id) : [],
        invited_on: progress.inviteDate ? Util.format(progress.inviteDate, 'MM/dd/yyyy') : i18n.t('Never'),
        last_reminded_at: progress.lastReminderDate ? Util.format(progress.lastReminderDate, 'MM/dd/yyyy') : i18n.t('Never'),
        participation_status: progress.participationStatus === 'error' ? 'excluded' : progress.participationStatus,
        training_period: progressTrainingPeriod ? progressTrainingPeriod.name : null
      });
      return composedData;
    }).flat();
  };

  goToProgressPage = (row, csUrl, redirect) => e => {
    e.preventDefault();
    e.stopPropagation();

    if (redirect) {
      const url = `${csUrl}/admin/course_progress/#/${row.id}?return_to=${encodeURIComponent(window.location)}`;
      window.location.href = `${url}`;
    }
  };

  isPrimaryAdmin = () => {
    if (!this.props.user) {
      return false;
    }

    const res = this.props.user.business_lines.filter(val =>
      this.props.currentViewer && this.props.currentViewer.primary_managed_business_lines.some(({ value }) => (val.value) === (value))
    );
    return (res.includes('corporate-compliance') || res.includes('higher-education') || res.includes('faculty-staff'));
  }

  hasCertificateCapability = () => {
    return this.props.capabilityList && isBulkAwardsDownloadEnabled(this.props.capabilityList, this.isPrimaryAdmin(), this.props.currentViewer);
  }

  selectContentAwards = (awards) => {
    this.setState({ showAwardsModal: true, selectedAwards: awards });
  }

  closeContentAwardsModal = () => {
    this.setState({ showAwardsModal: false, selectedAwards: null });
  }

  formatDownloadCertificate = (row) => {
    const award = this.props.userAwards?.find(award => {
      return row.user_assignment_ids.includes(String(award.user_assignment_id)) && award.content_ids.includes(String(row.content_id));
    });
    const awards = this.props.userAwards?.map(award => {
      if (row.user_assignment_ids.includes(String(award.user_assignment_id)) && award.content_ids.includes(String(row.content_id))) {
        return award;
      }
    }).filter(item => item);

    const canShowCertificate = !!award?.award_id;
    const progress_status = this.getProgressStatus(row);
    const progressComplete = progress_status === 'completed';
    const canDownload = canShowCertificate && progressComplete;

    let url, tooltip;
    if (awards?.length > 1 && canDownload) {
      return {
        icon: 'file-lines',
        title: 'Certificate',
        tooltip: 'Certificate',
        actionType: 'callToAction',
        disabled: !canDownload,
        action: () => this.selectContentAwards(awards)
      };
    }

    if (canDownload) {
      url = award?.download_link;
    }

    if (canShowCertificate && !progressComplete) {
      tooltip = i18n.t('Certificates will become available after the learner completes this activity');
    } else if (!canShowCertificate) {
      tooltip = i18n.t('Certificates are not available for this learning activity');
    }

    return {
      icon: 'file-lines',
      title: 'Certificate',
      tooltip: tooltip || 'Certificate',
      type: 'download',
      className: !canDownload ? 'disabled' : '',
      actionType: 'externalLink',
      disabled: !canDownload,
      action: url,
      target: "_blank"
    };
  }

  actionsFormatter = (_, row) => {
    if (!row) return null;

    const { currentUserId, organizationId, permissions, viewer } = this.props;
    const { progress_id, user_assignments } = row;
    const user_assignment_id = user_assignments?.length && user_assignments[0].id;

    const canSimulateProgress = this.props.checkSimulatable(row);
    const viewUrl = viewer === 'admin' ?
      `/${organizationId}/users/${currentUserId}/assignments/${user_assignment_id}/progress/${progress_id}` :
      `/${currentUserId}/assignments/${user_assignment_id}/progress/${progress_id}`;

    let buttons = [{
      icon: 'eye',
      title: 'View',
      tooltip: 'View',
      type: 'view',
      actionType: 'link',
      disabled: !progress_id,
      action: () => viewUrl
    }];

    if ((this.isPrimaryAdmin() && this.hasCertificateCapability()) || this.props.isFitAdmin) {
      const certButton = this.formatDownloadCertificate(row);
      buttons.push(certButton);
    }
    const hasSimulatePermission = AccessControlUtil.hasPermission('simulate_progress', permissions);

    if (viewer === 'admin' && hasSimulatePermission) {
      buttons.push({
        icon: 'arrow-right',
        title: 'Simulate Progress',
        tooltip: 'Simulate Progress',
        type: 'view',
        actionType: 'callToAction',
        disabled: !canSimulateProgress,
        action: this.goToProgressPage(row, this.props.contentServiceUrl, canSimulateProgress)
      });
    }

    return <ActionButtons row={row} buttons={buttons} />;
  };

  getProgressStatus = (progress) => {
    if (progress) {
      if (progress.policies_missing) {
        return "Missing policy signature(s)";
      }
      return progress.status;
    }
    return "not_started";
  };

  showAssignmentsList = () => {
    const { currentViewer, businessLine, user } = this.props;

    if (!currentViewer) {
      return true;
    }

    const { all_managed_business_lines, id } = currentViewer;

    return (id && id === user.id) || all_managed_business_lines.includes(businessLine);
  }

  formatParticipationIcon = (cell, row) => {
    let icon;
    if (row.past_due) {
      icon = null;
    } else {
      switch (row.participation_status) {
        case 'assigned':
          icon = 'clipboard-list';
          break;
        case 'closed':
          icon = 'clipboard-check';
          break;
        case 'excluded':
          icon = 'square-exclamation';
          break;
        case 'unassigned':
          icon = 'clipboard';
          break;
        default:
          icon = 'clipboard-list';
      }
    }
    return icon;
  }

  formatLearningActivity = (cell, row) => {
    let columnConfig = [
      { label: row.training_period }
    ]
    let statusArray = [
      { label: StringUtil.UnderscoresToTitleCase(row.participation_status), icon: this.formatParticipationIcon(cell, row) },
      { label: StringUtil.UnderscoresToTitleCase(this.getProgressStatus(row)) }
    ];
    if (row.past_due) {
      statusArray.unshift({ label: 'Past Due', icon: 'square-exclamation' });
    }
    columnConfig.push(statusArray);
    return <ComplexTableColumn header={row.name} columnConfig={columnConfig} />
  }

  formatAssignments = (cell, row) => {
    if (!row.assignments) {
      return <>{i18n.t("No Assignments")} </>
    }

    return <ul className="user-details-assignment-list">
      {row.assignments.map(assignment => <li>{assignment.name}</li>)}
    </ul>
  }

  get fields() {
    //progress status name value is mis-labeled intentionally to avoid displaying the wrong overlay text
    const fields = [
      { name: 'name', title: i18n.t('Activity Name'), width: '30%', formatter: this.formatLearningActivity },
      { name: 'assignment_names', title: i18n.t('Assigned In'), width: '25%', formatter: this.formatAssignments },
      { name: 'invited_on', title: i18n.t('Invite Date') },
      { name: 'due_on', title: i18n.t('Due Date'), formatter: (cell, row) => Util.format(row.due_on, 'MM/dd/yyyy') },
      { name: 'last_reminded_at', title: i18n.t('Last Reminded') }
    ];
    fields.push({ name: 'actions', title: i18n.t('Actions'), formatter: this.actionsFormatter });

    return fields;
  }

  render() {
    const { businessLine, currentUserId, loadingProgress, userProgresses, userProgressMeta, onPageChange } = this.props;
    if (!businessLine) {
      return null;
    }

    const data = this.getUserProgressData(userProgresses).filter(assignment => assignment.user_id === currentUserId);

    return (<>
      {this.showAssignmentsList() && <div>
        <PagingDataList
          data={data}
          fields={this.fields}
          keyField="id"
          totalCount={userProgressMeta && userProgressMeta.total_count}
          onPageChange={onPageChange}
          striped={false}
          trClassName="table-row-pointer"
          isLoadingData={loadingProgress}
        />
      </div>}
      {this.state.showAwardsModal &&
        <AwardsDownloadModal awards={this.state.selectedAwards} show={this.state.showAwardsModal} onClose={this.closeContentAwardsModal} />}
    </>);
  }
}
