import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { buildGenericProps, JsonApiDecorator } from "reactifi";

import * as webhooksActionCreators from "../actions/webhooksActionCreators";
import WebhookDomainList from "../components/WebhookDomainList";

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, 'domains', ['webhooks']);

  props.webhookEventTypes = ownProps.route.webhook_event_types;
  if (props.currentEntity) {
    props.currentDomain = props.currentEntity;
  }
  const apiStore = new JsonApiDecorator(state.api);
  const { currentWebhookId, currentDomainId } = state.webhook;
  const { applications, webhooks } = apiStore;
  if (applications) {
    props.applications = applications.all();
    props.domains.forEach((domain) => {
      domain.application = applications.find(domain.application_id.toString());
    });
  } else {
    props.applications = [];
  }
  props.applications = applications ? applications.all() : [];
  if (currentWebhookId) {
    if (currentWebhookId === 'add') {
      props.currentWebhook = apiStore.newObject('webhooks');
      props.currentWebhook.domain_id = currentDomainId;
    } else if (webhooks) {
      props.currentWebhook = webhooks.find(currentWebhookId.toString());
    }
  }

  return props;
}

class WebhooksContainer extends React.Component {
  static propTypes = {
    domains: PropTypes.array,
    currentDomain: PropTypes.object,
    currentWebhook: PropTypes.object,
    webhookEventTypes: PropTypes.array,
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      webhooksActionCreators,
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.actions.loadDomains();
    this.actions.loadApplications();
  }

  render() {
    return <WebhookDomainList {...this.props} {...this.actions} />;
  }
}

export default connect(mapStateToProps)(WebhooksContainer);
