import { selectEntity, readEntity, updateEntity, deleteEntity, createEntity, readLocal, ApiUtil } from 'reactifi';
export { clearMessages, clearApiState } from 'reactifi';
import { buildUrl } from 'reactifi/dist/es/json-api/JsonApiUtils';
import i18n from 'lib/i18n';

export function selectTrainingPeriod(trainingPeriodId) {
  return selectEntity(trainingPeriodId, 'training_periods');
}

export function readTrainingPeriods(organizationId, status = 'active', searchValue, page = 1, currentBusinessLine) {
  let filters = { organization_id: organizationId, status };

  if (currentBusinessLine) {
    filters.business_lines = [currentBusinessLine];
  }

  const query = {
    name: {
      term: searchValue,
      fields: {
        name: 'word_start',
        description: 'word_start'
      }
    }
  };

  if (status === 'all') delete filters.status;
  return readEntity('training_periods', null, filters, null, page, searchValue && query);
}

export function updateTrainingPeriod(trainingPeriod) {
  return updateEntity(trainingPeriod, dispatch => {
    dispatch(selectEntity(null, 'training_periods'));
  }, i18n.t('Training Period updated successfully'));
}

export function deleteTrainingPeriod(trainingPeriod) {
  return deleteEntity(trainingPeriod, 'training_periods', i18n.t('Training Period deleted successfully.'))
}

export function createTrainingPeriod(trainingPeriod) {
  return createEntity(trainingPeriod, dispatch => {
    dispatch(selectEntity(null, 'training_periods'));
  }, i18n.t('Training Period created successfully'));
}

export function loadRelatedAssignment(contentServiceUrl, token, trainingPeriodId, lobId, first = true) {
  return async dispatch => {
    const page = first ? { number: 1, size: 1 } : {};
    const url = buildUrl(
      `${contentServiceUrl}/api/v1/assignments.json`,
      {
        filters: {
          training_period_id: trainingPeriodId,
          business_line: lobId
        },
        page
      }
    );
    const response = await ApiUtil.loadExternalData(url, token, false);
    dispatch(readLocal(response));

    return response;
  }
}
