import React, { Component } from "react";
import PropTypes from "prop-types";
import { Collapse } from 'react-bootstrap';
import { ListView, ConfirmationModal, SelectControl, FloatingLabelFormField, FAIcon } from 'reactifi';
import i18n from "lib/i18n";

export default class WebhookList extends Component {
  static propTypes = {
    domain: PropTypes.object,
    webhookEventTypes: PropTypes.array,
    selectWebhook: PropTypes.func,
    deleteWebhook: PropTypes.func,
    sendTestWebhook: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      open: false,
      testWebhook: null,
      testWebhookEventType: null,
      deleteWebhook: null
    };
  }

  renderWebhooksSummary = () => {
    const { domain: { webhooks } } = this.props;
    const count = webhooks ? webhooks.length : 0;
    switch (count) {
      case 0:
        return i18n.t('There are no webhooks associated with this domain');
      case 1:
        return i18n.t('There is 1 webhook associated with this domain');
      default:
        return i18n.t('There are {{ count }} webhooks associated with this domain', { count });
    }
  }

  toggleList = () => {
    this.setState({ open: !this.state.open });
  }

  addWebhook = () => {
    const { domain, selectWebhook } = this.props;
    selectWebhook('add', domain.id);
  }

  editWebhook = (id) => {
    this.props.selectWebhook(id);
  }

  deleteWebhook = (webhook) => {
    this.setState({ deleteWebhook: webhook });
  }

  sendTestWebhook = (webhook) => {
    this.setState({ testWebhook: webhook });
  }

  onSelectTestWebhook = (eventType) => {
    this.setState({ testWebhookEventType: eventType && eventType.name });
  }

  onConfirmSendWebhook = () => {
    this.props.sendTestWebhook(this.state.testWebhook, this.state.testWebhookEventType);
    this.setState({
      testWebhook: null,
      testWebhookEventType: null
    });
  }

  onCancelSendWebhook = () => {
    this.setState({
      testWebhook: null,
      testWebhookEventType: null
    });
  }

  onConfirmDeleteWebhook = () => {
    this.props.deleteWebhook(this.state.deleteWebhook);
    this.setState({
      deleteWebhook: null
    });
  }

  onCancelDeleteWebhook = () => {
    this.setState({
      deleteWebhook: null
    });
  }

  get fields() {
    return [
      { name: "path", title: i18n.t("Path"), canSort: false },
      { name: "active", title: i18n.t("Active"), canSort: false, width: 100, formatter: cell => cell ? i18n.t('Yes') : i18n.t('No') }
    ];
  }

  get actions() {
    return [
      { icon: 'paper-plane-top', title: '', tooltip: i18n.t('Send test webhook'), type: 'send-test', actionType: 'callToAction', action: (e, row) => this.sendTestWebhook(row) },
      { icon: 'pen', type: 'edit', actionType: 'callToAction', action: (e, row) => this.editWebhook(row.id) },
      { icon: 'trash', type: 'delete', actionType: 'callToAction', action: (e, row) => this.deleteWebhook(row) }
    ];
  }

  renderTestWebhookModal = () => {
    const { domain, webhookEventTypes } = this.props;
    const { testWebhook, testWebhookEventType } = this.state;
    if (!testWebhook) {
      return null;
    }
    if (!domain.verified || !testWebhook.active) {
      return (
        <ConfirmationModal
          title={i18n.t("This webhook must be activated using the edit icon before you can send a test webhook.")}
          onConfirm={this.onCancelSendWebhook}
          show={true}
          confirmOnly={true}
        />
      );
    }
    return (
      <ConfirmationModal
        title={i18n.t("Send a test event to your webhook endpoint at {{ endpoint }} ", { endpoint: `https://${domain.name}/${testWebhook.path}` })}
        onConfirm={this.onConfirmSendWebhook}
        onCancel={this.onCancelSendWebhook}
        show={true}
        confirmButtonDisabled={!testWebhookEventType}
      >
        <div className="row">
          <div className="col-12">
            <FloatingLabelFormField name="testWebhookEventType" caption={i18n.t('Event Type')} dataValue={testWebhookEventType}>
              <SelectControl options={webhookEventTypes} valueKey="name" labelKey="display_name" onChange={this.onSelectTestWebhook} />
            </FloatingLabelFormField>
          </div>
        </div>
      </ConfirmationModal>
    );
  }

  renderDeleteModal = () => {
    const { deleteWebhook } = this.state;
    if (!deleteWebhook) {
      return null;
    }
    return (
      <ConfirmationModal
        title={i18n.t("Delete Webhook at {{ path }}", { path: deleteWebhook.path })}
        children={
          <div>
            <p>{i18n.t("Are you sure you want to delete the webhook at {{ path }}?", { path: deleteWebhook.path })}</p>
          </div>
        }
        onConfirm={this.onConfirmDeleteWebhook}
        onCancel={this.onCancelDeleteWebhook}
        show={true}
      />
    );
  }

  render() {
    const { domain: { webhooks } } = this.props;
    const { open } = this.state;
    const hasWebhooks = webhooks && webhooks.length > 0;
    const iconRotation = open ? 90 : null;

    let webhookLabel = this.renderWebhooksSummary();
    if (hasWebhooks) {
      webhookLabel = (<a onClick={this.toggleList}>
        {webhookLabel}
        <FAIcon icon="angle-right" rotation={iconRotation} additionalClasses="m-l-5" />
      </a>);
    }

    return (
      <div>
        <a
          className="btn-secondary pull-right"
          onClick={this.addWebhook}
        >
          <FAIcon icon="plus" additionalClasses="m-l-0 m-r-5" />
          {i18n.t('Add Webhook')}
        </a>
        {webhookLabel}
        <div className="clearfix" />
        <Collapse in={open}>
          <div>
            {webhooks &&
              <ListView
                data={webhooks}
                loadAction={() => {}}
                fields={this.fields}
                actions={this.actions}
                totalCount={hasWebhooks ? webhooks.length : 0}
                fullPage={false}
                hasPagination={false}
              />}
          </div>
        </Collapse>
        {this.renderTestWebhookModal()}
        {this.renderDeleteModal()}
      </div>
    );
  }
}
