import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup } from 'reactifi/dist/es/components/Form/FieldGroup'
import { SelectControl } from 'reactifi/dist/es/components/Form/Controls/SelectControl'
import { MaskedFormControl } from 'reactifi/dist/es/components/Form/Controls/MaskedFormControl'
import { MaterialCheckbox } from 'reactifi/dist/es/material/MaterialCheckbox'
import { FloatingLabelFormField } from 'reactifi/dist/es/material/FloatingLabelFormField'
import { RegistrationFormField } from 'reactifi/dist/es/components/Form/Registration/RegistrationFormField'
import { RegistrationFieldGroup } from 'reactifi/dist/es/components/Form/Registration/RegistrationFieldGroup'
import i18n from 'lib/i18n';

export default class VolunteerRegistration extends React.Component{
  static propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    errorMessages: PropTypes.array,
    className: PropTypes.string,
    onValidityCheck: PropTypes.func,
    organizationName: PropTypes.string
  };

  constructor(props){
    super(props);
    this.onToggleIsEmployee = this.onToggleIsEmployee.bind(this);
    this.onAlternateEmailCheckedChange = this.onAlternateEmailCheckedChange.bind(this);
    const isEmployeeBool = typeof props.data.is_employee === 'string' ? props.data.is_employee === 'true' : props.data.is_employee;
    this.state = {
      isEmployee: !!isEmployeeBool,
      alternateEmailChecked: !!props.data.alternate_email
    };
    if(this.state.alternateEmailChecked){
      props.data.alternate_email_checked = true;
    }
  }

  buildDisplay(i){
    return (i.location_formatted_address);
  }

  onToggleIsEmployee() {
    this.setState({
      isEmployee: !this.state.isEmployee
    }, () => {
      if(!this.state.isEmployee){
        this.setState({ doesNotBelongToAnyWorkLocation: false, alternateEmailChecked: false });
        this.props.data.title = "";
        this.props.data.alternate_email_checked = false;
        this.props.data.alternate_email = "";
      }
    });
  }

  onAlternateEmailCheckedChange() {
    this.setState({
      alternateEmailChecked: !this.state.alternateEmailChecked
    }, () => {
      if(!this.state.alternateEmailChecked){
        this.props.data.alternate_email = "";
      }
      else{
        this.props.data.alternate_email_checked = true;
        const alternateEmailField = document.getElementById('[1][alternate_email]-label');
        if(alternateEmailField){
          alternateEmailField.focus();
        }
      }
    });
  }

  onElementChange = (data) => {
    this.props.onValidityCheck(data.length > 0);
  }

  onMobileChange = (data) => {
    this.props.onValidityCheck(/^\(\d{3}\) \d{3}-\d{4}$/.test(data));
  }

  mapObjToOptions = (obj) => {
    return Object.keys(obj).map((k) => {
      return { label: k, value: obj[k] };
    });
  }



  render(){
    let metadata = this.props.data.metadata;
    let languages = this.mapObjToOptions(metadata.languages.choices);

    return (
      <FieldGroup {...this.props} className={this.props.className}>
        <RegistrationFieldGroup index={this.props.index} metadata={metadata} errorMessages={this.props.errorMessages}>
          <RegistrationFormField key="languages">
            <FloatingLabelFormField 
              caption={i18n.t('Presentation Language Capabilities')} 
              name="presentation_language_capabilities"
              required={true}
            >
              <SelectControl onChange={this.onElementChange} options={languages} multi={true} valueKey="value" labelKey="label" placeholderText="" />
            </FloatingLabelFormField>
          </RegistrationFormField>
          <RegistrationFormField key="phone">
            <FloatingLabelFormField caption={i18n.t('Mobile Phone')}>
              <MaskedFormControl onChange={this.onMobileChange} mask="(111) 111-1111" type="tel" pattern="^\(\d{3}\) \d{3}-\d{4}$"
                placeholderChar=" " placeholder=" "/>
            </FloatingLabelFormField>
          </RegistrationFormField>
          <RegistrationFormField key="is_employee">
            <MaterialCheckbox
              className="m-l-25"
              onChange={this.onToggleIsEmployee}
              inline={false}
            >
              {i18n.t(`I am an employee of ${this.props.organizationName}`)}
            </MaterialCheckbox>
          </RegistrationFormField>
          {this.state.isEmployee ?
            <FieldGroup>
              <RegistrationFormField key="title">
                <FloatingLabelFormField onChange={this.onElementChange} caption={i18n.t('Current Position/Job Title')} required={true}/>
              </RegistrationFormField>
              <RegistrationFormField key="alternate_email_checked">
                <MaterialCheckbox
                  className="m-l-25"
                  onChange={this.onAlternateEmailCheckedChange}
                  inline={false}
                >
                  {i18n.t('I cannot receive emails at the above email address off-work hours.')}
                </MaterialCheckbox>
              </RegistrationFormField>
              {this.state.alternateEmailChecked ?
                <RegistrationFormField key="alternate_email">
                  <FloatingLabelFormField
                    onChange={this.onElementChange}
                    caption={i18n.t('Off-work Hours Email Address')}
                    hintText={i18n.t('If you cannot receive emails at off-work hours, please provide an alternative email.')}
                    type="email"
                    required={true}
                  />
                </RegistrationFormField> : null}
            </FieldGroup> : null}
        </RegistrationFieldGroup>
      </FieldGroup>);
  }
}
