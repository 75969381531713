import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { buildGenericProps, JsonApiDecorator } from 'reactifi';
import { findUserProgress, loadUser, clearUserAssignments } from '../actions/orgUsersActionCreators';

import OrgUserProgress from '../components/OrgUserProgress';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'users');

  const apiStore = new JsonApiDecorator(state.api);
  const { user_assignments, users, progress } = apiStore;

  const { user_id, user_assignment_id, progress_id } = ownProps.routeParams;
  const { jwt, contentServiceUrl } = ownProps.route;

  props.userAssignment = user_assignments
    ? user_assignments.first(['assignment', 'progresses'])
    : null;

  if (progress) {
    props.progress = progress.find(progress_id);
  }

  if (users) {
    props.currentEntity = users.find(user_id, 'location');
  }

  props = Object.assign(props, {
    jwt,
    user_id,
    user_assignment_id,
    contentServiceUrl
  });

  return props;
}

class OrgUserProgressContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    user_assignment_id: PropTypes.string.isRequired,
    user_id: PropTypes.string.isRequired,
    contentServiceUrl: PropTypes.string.isRequired,
    jwt: PropTypes.string.isRequired,
    currentEntity: PropTypes.object,
    router: PropTypes.object,
    progress:PropTypes.object,
    userAssignment: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(
      { findUserProgress, loadUser, clearUserAssignments },
      this.props.dispatch
    );
  }

  async componentDidMount() {
    const { contentServiceUrl, user_id, jwt, user_assignment_id } = this.props;
    await this.actions.loadUser(user_id);
    await this.actions.findUserProgress(
      contentServiceUrl,
      user_id,
      jwt,
      user_assignment_id
    );
  }

  componentWillUnmount() {
    this.actions.clearUserAssignments();
  }

  render() {
    return (
      <OrgUserProgress
        router={this.props.router}
        user={this.props.currentEntity}
        user_id={this.props.user_id}
        progress={this.props.progress}
        userAssignment={this.props.userAssignment}
      />
    );
  }
}

export default connect(mapStateToProps)(OrgUserProgressContainer);
