import { updateEntity, readEntityById } from 'reactifi';
import i18n from 'lib/i18n';

export function findOrganization(id) {
  return readEntityById(id, 'organizations');
}

export function updateOrganization(organization) {
  return updateEntity(organization, null, i18n.t('Organization tag manager acceptance banner settings updated.'))
}
