import i18n from 'lib/i18n';
import orgChart from '../../../../images/reportimages/org_chart.png';
import report from '../../../../images/reportimages/report.png';
import clipboard from '../../../../images/reportimages/clipboard.png';

export const PATHNAME = window.location.pathname.substring(0, window.location.pathname.lastIndexOf('/'));

export const TASKS = [
  {
    slug: 'locations',
    title: i18n.t('Create Your Locations'),
    description: i18n.t('Locations ensure that your learners will get the correct content. Locations can also be used to target assignments, specify policies and resources, and filter reports. You may have one or more locations preloaded in your account, but verify all your locations are added before completing this step.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Add-Locations',
    goToLabel: i18n.t('Go to Locations'),
    goToUrl: `${window.location.origin}${PATHNAME}/locations/${window.location.search}`
  }, {
    slug: 'categories',
    title: i18n.t('Add Custom Categories'),
    description: i18n.t('Custom Categories will help you group, manage, and assign learners. Consider how you want to segment your learner population when you assign content. It’s easiest to do this step before you add users to your account.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Custom-Categories',
    goToLabel: i18n.t('Go to Custom Categories'),
    goToUrl: `${window.location.origin}${PATHNAME}/categories/${window.location.search}`
  }, {
    slug: 'manage_users',
    title: i18n.t('Add Users'),
    description: i18n.t('Users must be uploaded before you can assign training to them. For the easiest account set up, don’t do this step until all of your locations and custom categories are in the system.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Add-Users',
    goToLabel: i18n.t('Go to Manage Users'),
    goToUrl: `${window.location.origin}${PATHNAME}/users/${window.location.search}`
  }, {
    slug: 'content_library',
    title: i18n.t('Customize Content'),
    description: i18n.t('EVERFI courses are customizable to align with your organization’s branding and messaging. We recommend at least adding in your policies and resources.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Customization-Options',
    goToLabel: i18n.t('Go to Content Library')
  }, {
    slug: 'manage_communications',
    title: i18n.t('Customize Communications'),
    description: i18n.t('We send emails to learners when they are assigned content and until they complete their assignments. Default Invite and Reminder templates are already loaded in your account, but you can customize them to meet your needs.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Communications-Overview',
    goToLabel: i18n.t('Go to Communications')
  }, {
    slug: 'training_periods',
    title: i18n.t('Create Your Training Period'),
    description: i18n.t('Training Periods help you manage your yearly trainings. Your account comes with a default, but we recommend customizing it to meet your needs. You’ll need to add a new Training Period next cycle.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Training-Periods',
    goToLabel: i18n.t('Go to Training Periods'),
    goToUrl: `${window.location.origin}${PATHNAME}/training_periods/${window.location.search}`
  }, {
    slug: 'create_assignment',
    title: i18n.t('Create a Test Assignment'),
    description: i18n.t('To get familiar with creating assignments, we recommend creating a test assignment with the settings you will use for your real assignment. After receiving your assignment invitation, you can access the course to see what your learners will see.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Assignments',
    goToLabel: i18n.t('Go to Assignments')
  }, {
    slug: 'assignment_status',
    title: i18n.t('Archive Your Test Assignment'),
    description: i18n.t('Once you are done with your test assignment, archive it to remove it from your learner dashboard and clean up your assignments page.'),
    learnMoreUrl: 'https://help.everfi.com/s/article/Assignment-Status-Guide',
    goToLabel: i18n.t('Go to Assignments')
  }
];


export const SETUP_STEPS = [
  {
    slug: 'account',
    title: i18n.t('Set Up Your Account'),
    imageUrl: orgChart,
    taskKeys: ['locations', 'categories', 'manage_users', 'assignments']
  },
  {

    slug: 'content',
    title: i18n.t('Customize Content'),
    imageUrl: report,
    taskKeys: [
      'content_library', 'manage_communications'
    ]
  },
  {
    slug: 'assignment',
    title: i18n.t('Create Your First Assignment'),
    imageUrl: clipboard,
    taskKeys: [
      'training_periods', 'create_assignment', 'assignment_status'
    ]
  }
];