/* eslint react/no-multi-comp: 0 */
import PropTypes from 'prop-types';
import React from 'react';
import { FAIcon } from 'reactifi';
import { Util } from "@everfi/reactifi";
import i18n from 'lib/i18n';
import { differenceInDays } from 'date-fns';

const CertificateInfoBlock = ({ certificate }) => {
  const getDaysToExpirationMessage = expirationDate => {
    const daysDiff = differenceInDays(new Date(expirationDate), new Date());

    if (daysDiff <= 0) {
      return <div className="text-danger">{i18n.t('Certificate is expired')}</div>;
    }

    return (
      <div className="text-success">
        {`${i18n.t('Certificate expires in')} ${daysDiff} ${i18n.t('days')}`}
      </div>
    );
  }

  if (!certificate) {
    return null;
  }

  const { common_name, serial, expiration_date, filename } = certificate;

  return (
    <div className="well certificate-panel container m-0">
      <div className="certificate-icon col-lg-1 p-0">
        <FAIcon size="medium" maskIcon="circle" icon="lock-keyhole" transform="shrink-8" additionalClasses="m-t-10 m-b-5" />
      </div>
      <div className="certificate-info col-lg-11">
        <div className="title m-b-10">
          <strong>{i18n.t('Common Name:')} {common_name}</strong>
        </div>
        <div className="specs">
          {filename && <div>{filename}</div>}
          <div>{i18n.t('Serial Number:')} {serial}</div>
          <div>{i18n.t('Expires')} {Util.format(expiration_date, "PPpp")}</div>
          {getDaysToExpirationMessage(expiration_date)}
        </div>
      </div>
    </div>
  );
};

CertificateInfoBlock.propTypes = {
  certificate: PropTypes.shape({
    common_name: PropTypes.string,
    expiration_date: PropTypes.string,
    filename: PropTypes.string,
    serial: PropTypes.string
  })
};

export default CertificateInfoBlock;
