import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup
} from 'reactifi';


export default class BrandingNavigationWell extends Component {
  static propTypes = {
    currentStyleGuide: PropTypes.object,
    getProperties: PropTypes.func,
    invalidHex: PropTypes.bool,
    complianceMessage: PropTypes.string
  }

  constructor(props){
    super(props);
    this.state = {
      currentStyleGuide: props.currentStyleGuide
    }
  }

  render () {
    let { currentStyleGuide, complianceMessage } = this.props;

    return (
      <FieldGroup className="branding-well-container" data={currentStyleGuide}>
        <h4>{i18n.t("Navigation")}</h4>
        <FieldGroup className="row">
          {this.props.getProperties('navigation')}
          { this.props.invalidHex && <FieldGroup className="error-text">
            <p>{ i18n.t("Please make sure that the entered hexcolors are valid.") }</p>
          </FieldGroup> }
          { complianceMessage && <FieldGroup className="error-text">
            <p>{ complianceMessage }</p>
          </FieldGroup> }
        </FieldGroup>
      </FieldGroup>
    )
  }
}
