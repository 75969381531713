import React from 'react'

import { createContainer } from 'reactifi';

import OnboardingChecklist from '../components/OnboardingChecklist';
import { loadUsers, updateChecklistItem, updateChecklist, loadChecklist } from '../actions/onboardingChecklistActions';

const mapStateToProps = (state, ownProps) => {
  const { apiStore } = ownProps;
  const props = {
    users: apiStore.users ? apiStore.users.all() : []
  };
  return props;
}

const Container = createContainer({ entity: 'checklists', includes: 'checklist_items', mapStateToProps, actionCreators: { loadUsers, loadChecklist, updateChecklistItem, updateChecklist }  });

const OnboardingChecklistContainer = props => {
  return (
    <Container>
      <OnboardingChecklist {...props} />
    </Container>
  )
}

export default OnboardingChecklistContainer;