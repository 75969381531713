import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup } from 'reactifi/dist/es/components/Form/FieldGroup'
import { FloatingLabelFormField } from 'reactifi/dist/es/material/FloatingLabelFormField'
import { RegistrationFormField } from 'reactifi/dist/es/components/Form/Registration/RegistrationFormField'
import { RegistrationFieldGroup } from 'reactifi/dist/es/components/Form/Registration/RegistrationFieldGroup'
import i18n from 'lib/i18n';

export default class ManagerRegistration extends React.Component{
  static propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    errorMessages: PropTypes.array,
    className: PropTypes.string
  };

  render(){
    let metadata = this.props.data.metadata;
    return (
      <FieldGroup {...this.props} className={this.props.className}>
        <RegistrationFieldGroup index={this.props.index} metadata={metadata} errorMessages={this.props.errorMessages}>
          <RegistrationFormField key="title">
            <FloatingLabelFormField caption={i18n.t('Current Position/Job Title')}/>
          </RegistrationFormField>
        </RegistrationFieldGroup>
      </FieldGroup>);
  }
}
