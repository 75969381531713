import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { buildGenericProps, JsonApiDecorator, UrlUtil } from "reactifi";

import * as datafiExportsActionCreators from "../actions/datafiExportsActionCreators";
import DatafiExports from '../components/DatafiExports';
import { loadRuleSets } from '../../common/actions/commonActionCreators';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "data_exports", {});
  props.contentServiceUrl = ownProps.route.contentServiceUrl;
  props.dataTypes = ownProps.route.dataTypes;
  props.evalufiUrl = ownProps.route.evalufiUrl;
  props.jwt = ownProps.route.jwt;

  const apiStore = new JsonApiDecorator(state.api);
  const { rule_sets } = apiStore;
  props.currentBusinessLine = UrlUtil.getCurrentBusinessLine(rule_sets && rule_sets.all());

  if (props.currentEntity && props.dataTypes.length === 1) {
    props.currentEntity.data_type = props.dataTypes[0].type;
  }

  return props;
}

class DatafiExportsContainer extends React.Component {
  static propTypes = {
    currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    contentServiceUrl: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    dataTypes: PropTypes.array.isRequired,
    evalufiUrl: PropTypes.string.isRequired,
    jwt: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      { ...datafiExportsActionCreators, loadRuleSets },
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.actions.loadRuleSets();
  }

  render() {
    return <DatafiExports {...this.props} {...this.actions} />;
  }
}

export default connect(mapStateToProps)(DatafiExportsContainer);
