import { useConfirmAction } from 'reactifi';
import i18n from "lib/i18n";

const useReactivateUserConfirmAction = (action) => {
  return useConfirmAction({
    action,
    children: i18n.t('Are you sure you want to reactivate the selected user?'),
    confirmButtonText: i18n.t('Reactivate User'),
    title: i18n.t('Reactivate User?')
  })
};

export default useReactivateUserConfirmAction;
