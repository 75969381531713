import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  ActionButtons,
  FieldGroup,
  StringUtil,
  ConfirmationModal,
  Button,
  Util
} from 'reactifi';
import { Form } from 'react-bootstrap';

export default class BrandingStyleGuidesWell extends Component {
  static propTypes = {
    currentOrganization: PropTypes.object,
    deleteStyleGuide: PropTypes.func,
    errorMessage: PropTypes.string,
    router: PropTypes.object,
    selectStyleGuide: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.state = {
      defaultStyleGuideId: String(props.currentOrganization.default_style_guide_id) || "",
      showDeleteModal: false
    }
  }

  handleEdit = (e, guide) => {
    this.props.selectStyleGuide(guide.id);
    this.props.router.push(`${this.props.currentOrganization.id}/branding/${guide.id}`)
  }

  handleDelete = (e, guide) => {
    this.setState({ showDeleteModal: true, selectedStyleGuide: guide })
  }

  onCancelDeleteGuide = () => {
    this.setState({ showDeleteModal: false, selectedStyleGuide: null })
  }

  deleteGuide = () => {
    this.props.deleteStyleGuide(this.state.selectedStyleGuide);
    this.setState({ showDeleteModal: false, selectedStyleGuide: null })
  }

  addAction = (e, id) => {
    this.props.router.push(`${this.props.currentOrganization.id}/branding/add`)
  }

  onChangeDefault = (e, id) => {
    this.setState({ defaultStyleGuideId: id });
  }

  getActionConfig() {
    return [
      {
        icon: "pen",
        title: "Edit",
        tooltip: "Edit Style Guide",
        type: "edit",
        actionType: "callToAction",
        action: this.handleEdit
      },
      {
        icon: "trash",
        title: "Delete",
        tooltip: "Delete Style Guide",
        type: "delete",
        actionType: "callToAction",
        action: this.handleDelete
      }
    ];
  }

  getStyleGuides = () => {
    let { currentOrganization: { organization_brand_style_guides } } = this.props;
    return organization_brand_style_guides
      .filter(obsg => obsg.type === 'organization_brand_style_guides')
      .map((guide, index) => {
        return (
          <div className="row style-guide-row" data-action="style-guide" key={index}>
            <div className="col-4">
              <strong>{StringUtil.UnderscoresToTitleCase(guide.name)}</strong>
            </div>
            <div className="col-4">
              <Form.Check
                type="radio"
                key={index}
                className="radio m-b-10"
              >
                <Form.Check.Input type="radio" name="default_style_guide_id" value={guide.id} checked={guide.id === this.state.defaultStyleGuideId} onChange={(e) => this.onChangeDefault(e, guide.id)} />
                <i className="input-helper" />
                <Form.Check.Label>{guide.id === this.state.defaultStyleGuideId ? i18n.t("Default") : ''}</Form.Check.Label>
              </Form.Check>
            </div>
            <div className="col-4">
              <ActionButtons row={Object.assign({}, guide, {
                can_edit: true,
                can_delete: guide.id !== this.state.defaultStyleGuideId
              })}
              showLabels={true}
              buttons={this.getActionConfig()}
              />
            </div>
          </div>
        )
      })
  }

  render() {
    let { errorMessage, currentOrganization } = this.props;
    return (
      <FieldGroup
        data={currentOrganization}
        key="organization_brand_style_guides"
        errorMessage={errorMessage}
        className="branding-well-container"
        data-action="style-guides-card"
      >
        <FieldGroup className="row">
          <h4 className="p-l-0">{i18n.t("Style Guides")}</h4>
          <div className="col-12 p-l-0">{i18n.t("Style Guides allow for customized colors for various elements in the EverFi experience.")}</div>
        </FieldGroup>
        <FieldGroup data-action="style-guide-list">
          <div className="row style-guide-row" data-action="style-guide">
            <div className="col-4">
              <strong>None</strong>
            </div>
            <div className="col-8">
              <Form.Check
                type="radio"
                className="radio m-b-10">
                <Form.Check.Input type="radio" name="default_style_guide_id" value={null} checked={String(this.state.defaultStyleGuideId) === 'null'} onChange={(e) => this.onChangeDefault(e, null)} />
                <i className="input-helper" />
                <Form.Check.Label>{String(this.state.defaultStyleGuideId) === 'null' ? i18n.t("Default") : ''}</Form.Check.Label>
              </Form.Check>
            </div>
          </div>
          {this.getStyleGuides()}
        </FieldGroup>
        <FieldGroup className="row">
          <div className="col-4 p-l-0">
            <Button
              {...Util.dataAttribsForEl(currentOrganization, { action: 'add-style-guide' })}
              onClick={this.addAction}
              style="secondary"
              label={i18n.t('Add')}
              icon="plus"
            />
          </div>
        </FieldGroup>
        <ConfirmationModal
          title={i18n.t("Delete Style Guide")}
          children={i18n.t("Are you sure you want to delete this Style Guide?")}
          onConfirm={this.deleteGuide}
          onCancel={this.onCancelDeleteGuide}
          show={this.state.showDeleteModal}
        />
      </FieldGroup>
    )
  }
}
