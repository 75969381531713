import React from 'react';
import PropTypes from 'prop-types';
import * as registrationComponents from '../../bundles/Registration/components/index';

export default class CustomComponent extends React.Component {
  static propTypes = {
    componentName: PropTypes.string,
    errorMessage: PropTypes.object
  };

  importComponent(component) {
    if (component.default) {
      return component.default;
    }
    return component;
  }

  render() {
    let componentSrc = registrationComponents[this.props.componentName];
    
    if (componentSrc) {
      // needs to be a capital letter variable
      let Component = this.importComponent(componentSrc)
      return (<Component {...this.props}
        errorMessages={this.props.errorMessage && this.props.errorMessage.errors ? this.props.errorMessage.errors : null} />);
    } else {
      return null;
    }
  }
}
