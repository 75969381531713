import { 
  createEntity,
  updateEntity,
  deleteEntity, 
  readEntity,
  baseEndpointPath 
} from 'reactifi';

import { setEndpointPath } from 'redux-json-api';

export { clearMessages, displayError } from 'reactifi';

export function selectOrgBenchmarks(organizationId){
  return async (dispatch) => {
    await dispatch(setEndpointPath(`${baseEndpointPath}/organizations/${organizationId}`));
    await dispatch(readEntity('benchmark_group_organizations', ['benchmark_category', 'benchmark_group'], '',
      [{ value: 'id', direction: 'asc' }]
    ));
    dispatch(setEndpointPath(`${baseEndpointPath}`));
  }
}

export function deleteOrgBenchmark(organizationId, benchmarkGroupOrg){
  return async (dispatch) => {
    await dispatch(setEndpointPath(`${baseEndpointPath}/organizations/${organizationId}`));
    await dispatch(deleteEntity(benchmarkGroupOrg, 'benchmark_group_organizations'));
    dispatch(setEndpointPath(`${baseEndpointPath}`));
  }
}

export function createOrgBenchmark(organizationId, benchmarkGroupOrg) {
  return async (dispatch) => {
    dispatch(setEndpointPath(`${baseEndpointPath}/organizations/${organizationId}`));
    return dispatch(createEntity(benchmarkGroupOrg, (dispatch) => {
      dispatch(setEndpointPath(baseEndpointPath));
    }))
  }
}

export function updateOrgBenchmark(organizationId, benchmarkGroupOrg) {
  return async (dispatch) => {
    dispatch(setEndpointPath(`${baseEndpointPath}/organizations/${organizationId}`));
    return dispatch(updateEntity(benchmarkGroupOrg, (dispatch) => {
      dispatch(setEndpointPath(baseEndpointPath));
    }))
  }
}

export function findBenchmarks() {
  return readEntity('benchmark_categories','benchmark_groups',{});
}
  
