import { ConfirmationModal } from '@everfi/reactifi';
import React, { useState, Fragment } from 'react';
import noop from 'lodash/noop';
import PropTypes from "prop-types";

export const CheckboxConfirmationGatewayModal = ({
  children,
  component: Component,
  description,
  onChange = noop,
  onValueChange = noop,
  title,
  ...props
}) => {
  const [newValue, setNewValue] = useState({
    value: props?.dataValue,
    skip: undefined
  });
  const [showModal, setShowModal] = useState(false);

  const proxyValueChange = (value) => {
    setNewValue((prev) => ({ ...prev, value: value[props?.name] }));

    if(value[props?.name]) {
      onValueChange(value);
    }
  };

  const proxyChange = (value, skip) => {
    setNewValue({ value, skip });
    if (!value) {
      setShowModal(true);
    } else {
      onChange(value, skip);
    }
  };

  const onConfirm = () => {
    onValueChange({ [props?.name]: newValue.value });
    onChange(newValue.value, newValue.skip);
    setShowModal(false);
  };

  const onDeny = () => {
    setNewValue((prev) => ({ ...prev, value: !prev.value }));
    setShowModal(false);
  };

  return (
    <Fragment>
      <Component
        {...props}
        dataValue={newValue.value}
        onChange={proxyChange}
        onValueChange={proxyValueChange}
        valueOverride={true}
      >
        {children}
      </Component>
      <ConfirmationModal
        onCancel={onDeny}
        onConfirm={onConfirm}
        show={showModal}
        title={title}
      >
        {description}
      </ConfirmationModal>
    </Fragment>
  );
};

CheckboxConfirmationGatewayModal.propTypes = {
  children: PropTypes.node,
  component: PropTypes.element,
  dataValue: PropTypes.any,
  description: PropTypes.node,
  name: PropTypes.string,
  onChange: PropTypes.func,
  onValueChange: PropTypes.func,
  title: PropTypes.string
}
