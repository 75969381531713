import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as orgUsersActionCreators from '../actions/registrationActionCreators';
import { bindActionCreators } from 'redux';
import OrgWizardUserDetails from '../../Organizations/components/OrgWizardUserDetails';
import { Button, WizardModal, Step } from 'reactifi';
import i18n from 'lib/i18n';
import Modal from 'react-bootstrap/Modal';
import queryString from 'query-string';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class RegistrationWizard extends Component {
  static propTypes = {
    backDisabled: PropTypes.bool,
    currentOrganizationId: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    deleteMeFromSupressionList: PropTypes.func,
    dispatch: PropTypes.func,
    errorMessage: PropTypes.string,
    googleMapsApiKey: PropTypes.string,
    invalidRegistration: PropTypes.string,
    isCreate: PropTypes.bool,
    jwt: PropTypes.string,
    loadUserFormData: PropTypes.func,
    notifiUrl: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    organization: PropTypes.shape({
      privacy_policy_url: PropTypes.string
    }),
    register: PropTypes.func,
    registrationSets: PropTypes.shape({
      registration_context: PropTypes.object
    }),
    registrations: PropTypes.array,
    ruleSets: PropTypes.array.isRequired,
    suppressionLists: PropTypes.array,
    suppressionListEmails: PropTypes.array,
    updateUser: PropTypes.func,
    updateUserRuleSets: PropTypes.func,
    user: PropTypes.object,
    validRegistration: PropTypes.bool,
    viewer: PropTypes.string,
    wizardStep: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgUsersActionCreators, this.props.dispatch);

    this.state = {
      isValidProfile: false,
      under_13: false
    };
  }

  onValidityCheck = (valid) => {
    this.setState({ isValidProfile: valid });
  }

  onFullFormFiledCheck = (filled) => {
    this.setState({ isFormFullFilled: filled });
  }

  isSelfRegistered = () => {
    const { registrationSets } = this.props;
    return !!registrationSets && registrationSets.registration_context === 'self_registered';
  }

  get title() {
    const { isCreate, invalidRegistration } = this.props;
    if (this.isSelfRegistered()) {
      if (!isCreate && invalidRegistration) {
        return i18n.t('Please take a moment to complete your profile');
      }

      if (this.isAgeForm() && isCreate) {
        return i18n.t('Please select an answer')
      }
      if (this.isRegistrationForUnder13()) {
        return i18n.t('Please take a moment to complete your profile');
      }

      return i18n.t('Profile Information');
    }
    // not self-registed
    return isCreate ? i18n.t('Add User') : i18n.t('Update User');
  }

  get saveBtn() {
    const { isCreate, invalidRegistration } = this.props;
    if (this.isSelfRegistered()) {
      if (this.isRegistrationForUnder13()) {
        return i18n.t('Register');
      }
      if (isCreate) {
        return i18n.t('Continue');
      }

      return invalidRegistration ? i18n.t('Complete Profile') : i18n.t('Save');
    }
    // not self-registed
    return isCreate ? i18n.t('Create User') : i18n.t('Save');
  }

  get description() {
    return this.isSelfRegistered() && !this.isRegistrationForUnder13() && !this.isAgeForm() ? `* ${i18n.t('Required Fields')}` : null;
  }

  onCoppaAgeSelectionChange = (value) => {
    this.setState({ 'under_13': value, 'ageSelected': true });
  }

  coppaRegistration = (value) => {
    let radioButtons = document.getElementsByName("under_13");
    radioButtons.forEach(radio => {
      radio.checked = false;
    });
    window.location.href = sanitizeUrl(`${window.location.pathname}?under_13=${this.state.under_13}`);
  }

  isCoppaCapabilityEnabled = () => {
    return (this.props.registrations && this.props.registrations[0] && this.props.registrations[0].metadata && this.props.registrations[0].metadata.under_13.available);
  }

  isAgeForm = () => {
    return this.props.isCreate && this.isCoppaCapabilityEnabled() && !window.location.search;
  }

  isRegistrationForUnder13 = () => {
    let params = queryString.parse(window.location.search);
    return this.isCoppaCapabilityEnabled() && params && params.under_13 === 'true';
  }

  componentDidMount = () => {
    if (this.props.jwt) {
      this.actions.loadSuppressionListEmails(this.props.notifiUrl, this.props.jwt);
    }
  }

  render() {
    const { organization } = this.props;
    const isSelfRegistered = this.isSelfRegistered();
    const customPolicyUrl = organization?.privacy_policy_url;
    const customTermsUrl = organization?.privacy_choices_url;
    const policyUrl = customPolicyUrl || 'https://everfi.com/privacy-policy/';
    const termsUrl = customTermsUrl || 'https://everfi.com/terms-of-service/';
    const prefix = customPolicyUrl ? i18n.t('our') : "EVERFI's";

    const privacyText = <span className="privacy-text" dangerouslySetInnerHTML={{
      __html: i18n.t('agreeToTerms', { buttonLabel: this.saveBtn, prefix, policyUrl, termsUrl })
    }} />
    const branded = this.props.viewer !== 'customer_admin' && !this.props.user["is_manager?"];
    return (
      <WizardModal
        title={this.title}
        titleComponentType="h2"
        description={this.description}
        descriptionComponentClass="p3"
        finalButtonLabel={this.saveBtn}
        initialStep={this.props.wizardStep}
        limitHeight={true}
        onCancel={this.props.onCancel}
        allowCancel={!!this.props.onCancel}
        branded={true}
        backDisabled={this.props.backDisabled}
        wizardClass={`${isSelfRegistered && "self-registered"} ${this.isCoppaCapabilityEnabled() && "coppa-enabled"}`}
        hasScrollableForm={isSelfRegistered && !this.isAgeForm()}
        footerText={privacyText}
        customFooter={
          this.isAgeForm() ? (
            <Modal.Footer data-action="modal-footer">
              <Button
                branded={branded}
                disabled={!this.state.ageSelected}
                onClick={this.coppaRegistration}
                label={i18n.t("Next")}
              />
            </Modal.Footer>
          ) : null
        }
        disableContinueButton={!this.state.isFormFullFilled || !this.state.isValidProfile}
      >
        <Step title={i18n.t("User Details")}>
          <OrgWizardUserDetails
            {...this.props}
            user={this.props.currentUser}
            isCreate={this.props.isCreate}
            register={this.props.register}
            branded={branded}
            updateUser={this.props.updateUser}
            googleMapsApiKey={this.props.googleMapsApiKey}
            loadLocation={this.actions.loadLocation}
            onValidityCheck={this.onValidityCheck}
            onFullFormFiledCheck={this.onFullFormFiledCheck}
            isSelfRegistered={isSelfRegistered}
            onCoppaAgeSelectionChange={this.onCoppaAgeSelectionChange}
            isAgeForm={this.isAgeForm()}
            deleteMeFromSupressionList={this.props.deleteMeFromSupressionList}
          />
        </Step>
      </WizardModal>);
  }
}
