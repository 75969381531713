import React from 'react'
import i18n from 'lib/i18n'
import { ConfirmationModal } from 'reactifi'

const DeleteAdministratorModal = (props) => (
  <ConfirmationModal
    title={i18n.t("Delete Administrator")}
    children={i18n.t("Are you sure you want to delete this administrator?")}
    {...props}
  />
);

export default DeleteAdministratorModal
