import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldGroup, Button } from 'reactifi';
import CategoryLabelComponent from './CategoryLabelComponent';
import i18n from 'lib/i18n';
import union from 'lodash/union'

export default class CategoryLabelSelector extends Component {
  static propTypes = {
    businessLine: PropTypes.string,
    categoryLabels: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    })),
    disabled: PropTypes.bool,
    labelIds: PropTypes.arrayOf(PropTypes.string),
    onLabelsChange: PropTypes.func,
    organizationId: PropTypes.string,
    runValidation: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedLabelIds: this.props.labelIds,
      selectedLabels: [],
      selections: [{ category_id: null, labels: [] }],
      selectedCategories: []
    };
  }

  componentDidMount() {
    this.setInitialSelections();
  }

  componentDidUpdate(prevProps) {
    if (this.props.labelIds?.length && this.props.labelIds !== prevProps.labelIds) {
      this.setInitialSelections();
    }
  }

  setInitialSelections = () => {
    const { selectedLabelIds } = this.state;
    if (!selectedLabelIds.length) return;

    const selections = selectedLabelIds.reduce((selection, labelId, index) => {
      const label = this.state.selectedLabels[index] ?? this.props.categoryLabels.find(label => label.id === labelId);
      const currentSelection = selection.find(sel => sel.category_id === label.category_id);
      if (currentSelection) {
        currentSelection.labels = [...currentSelection.labels, label];
      } else {
        selection = [...selection, {
          category_id: label.category_id,
          labels: [label]
        }];
      }
      return selection;
    }, []);
    this.setState({ selections });
  }

  handleAddCategory = () => {
    this.setState({
      selections: [
        ...this.state.selections,
        { category_id: null, labels: [] }
      ]
    });
  }

  handleChangeLabels = (selectedIds, selectedLabels, categoryId) => {
    const prevSelectedLabels = this.props.categoryLabels.filter(label => this.state.selectedLabelIds.includes(label.id));
    const otherCategoryLabels = prevSelectedLabels.filter(label => String(label.category_id) !== String(categoryId));
    const otherCategoryLabelIds = otherCategoryLabels.map(label => label.id);

    this.setState({ 
      selectedLabelIds: union(otherCategoryLabelIds, selectedIds), 
      selectedLabels: union(otherCategoryLabels, selectedLabels)
    }, () => {
      this.props.onLabelsChange(this.state.selectedLabelIds);
    });
  }

  handleChangeCategory = (previous, actual) => {
    const newSelectedCategories = this.state.selectedCategories.filter((category) => category !== previous);
    if (actual) {
      newSelectedCategories.push(actual);
    }
    this.setState({ selectedCategories: newSelectedCategories });
  }

  renderCategoryLabels = () => {
    const selectionIds = [...new Set(this.state.selections.map(item => String(item.category_id)))];
    const sortedSelections = [...this.state.selections].sort((curr, next) => {
      if (!curr.category_id) return 1;
      if (!next.category_id) return -1;

      return curr.category_id - next.category_id;
    });

    return sortedSelections.map((selection, index) => (
      <CategoryLabelComponent
        businessLine={this.props.businessLine}
        disabled={this.props.disabled}
        key={selection.category_id || `temp${index}`}
        onLabelsChange={this.handleChangeLabels}
        organizationId={this.props.organizationId}
        runValidation={this.props.runValidation}
        data={{
          category_id: selection.category_id && selection.category_id.toString(),
          labels: selection.labels
        }}
        categoryLabels={this.props.categoryLabels}
        onCategoryUpdate={this.handleChangeCategory}
        selectionIds={[...selectionIds, ...this.state.selectedCategories]}
      />
    ));
  }

  render() {
    return (
      <>
        {this.renderCategoryLabels()}
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <Button
              disabled={this.props.disabled}
              style="link"
              icon="plus"
              onClick={this.handleAddCategory}
              data-action="add-another-category"
              label={i18n.t('Add Another Category')}
            />
          </FieldGroup>
        </FieldGroup>
      </>
    );
  }
}
