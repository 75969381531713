import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import createStore from './store/rostersStore';

import ListContainer from './containers/ListContainer';
import UploadContainer from './containers/UploadContainer';
import UploadConfirmationContainer from '../common/Components/upload/UploadConfirmationContainer';


let index = (props, _railsContext) => {
  const store = createStore({});
  const history = syncHistoryWithStore(hashHistory, store);
  const left_text_button = "Back to Manage Users";
  const right_text_button = "View My Uploads";
  const left_url_button = `/${props.organization_slug}/cportal/users`;
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route path="/" component={ListContainer} />
        <Route path="/confirmation" component={UploadConfirmationContainer} organization_slug={props.organization_slug} left_text_button={left_text_button} right_text_button={right_text_button} left_url_button={left_url_button} />
        <Route path="/:id" component={UploadContainer} organization_id={props.organization_id} organization_slug={props.organization_slug} organization_capabilities={props.organization_capabilities} />
      </Router>
    </RootProvider>
  )
}

index.propTypes = {
  organization_capabilities: PropTypes.arrayOf(PropTypes.string),
  organization_id: PropTypes.string,
  organization_slug: PropTypes.string
}

export default index;
