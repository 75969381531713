import React, { useCallback, useState } from "react";
import OrgWizardUser from "../../OrgWizardUser";

const useEditModalActions = props => {
  const {
    currentUserId,
    organizationId,
    selectCurrentUser,
    selectUserDetails,
    user
  } = props;

  const [showModal, setShowModal] = useState(false);
  const onCancel = useCallback(() => {
    selectCurrentUser(currentUserId);
    selectUserDetails();
    setShowModal(false);
  }, [currentUserId]);

  const onEditUser = useCallback(() => {
    setShowModal(true);
  }, []);

  const modal = showModal ? (
    <OrgWizardUser
      {...props}
      currentOrganizationId={organizationId}
      currentUser={user}
      isCreate={false}
      onCancel={onCancel}
      wizardStep={0}
    />
  ) : null;
  
  return {
    onEditUser,
    modal
  };
};

export default useEditModalActions;
