import {
  selectEntity,
  updateEntity,
  createEntity,
  readEntity,
  displaySuccessMessage,
  readEntityById,
  setActivePage,
  setActiveSearch,
  setActiveSort
} from 'reactifi';

import types from '../constants/actionTypes';

export { clearMessages, displayError, displayErrorMessage, displaySuccessMessage, setActiveFilters, setActiveState } from 'reactifi';

export function selectOrganization(organizationId) {
  return selectEntity(organizationId, 'organizations', 'favicon,logo,background_image,capabilities,organization_brand_style_guides,tag_managers');
}

export function findOrganizations(filters, page, sort, searchValue) {
  if (!sort) {
    sort = { value: 'name', direction: 'asc' };
  }
  const query = searchValue && {
    name: {
      term: searchValue,
      fields: {
        bb_salesforce_id: 'text_start',
        id: 'exact',
        name: 'word_start',
        salesforce_id: 'text_start',
        slug: 'text_start',
        short_id: 'exact'
      }
    }
  };
  return readEntity('organizations', '', null, sort && [sort], page, query);
}

export function findReports(orgId) {
  return readEntity('charts', null, { organization_id: orgId });
}

export function setActivePageAndFindOrganizations(page) {
  return async function (dispatch, getState) {
    const { api: { activeFilters, activeSearch, activeSort } } = getState();
    dispatch(setActivePage(page));
    dispatch(findOrganizations(activeFilters, { number: page }, activeSort, activeSearch));
  }
}

export function setActiveSortAndFindOrganizations(sort) {
  return async function (dispatch, getState) {
    const { api: { activeFilters, activeSearch } } = getState();
    dispatch(setActivePage(1));
    dispatch(setActiveSort(sort));
    dispatch(findOrganizations(activeFilters, { number: 1 }, sort, activeSearch));
  }
}

export function setActiveSearchAndFindOrganizations(search) {
  return async function (dispatch, getState) {
    const { api: { activeFilters, activeSort } } = getState();
    dispatch(setActivePage(1));
    dispatch(setActiveSearch(search));
    dispatch(findOrganizations(activeFilters, { number: 1 }, activeSort, search));
  }
}

export function findOrganization(id) {
  return readEntityById(id, 'organizations', 'favicon,logo,background_image,capabilities,organization_brand_style_guides,tag_managers');
}

export function updateOnSave(organization, callback) {
  return updateEntity(
    organization,
    async dispatch => {
      await dispatch(selectEntity(organization.id, organization.type));
      dispatch(displaySuccessMessage("Organization updated successfully!"));
      callback();
    },
    false
  );
}

export function updateOrganization(organization) {
  return updateEntity(organization, async dispatch => {
    await dispatch(selectEntity(organization.id, 'organizations', 'logo,background_image,capabilities,organization_brand_style_guides'));
    dispatch(displaySuccessMessage("Organization updated successfully!"))
  }, false);
}

export function createOrganization(organization, addAnother) {
  return createEntity(organization, (dispatch, getState, entity) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(clearSlug());
    dispatch(selectEntity(null, 'organizations'));
    if (addAnother) {
      dispatch(selectEntity('add', 'organizations'));
    } else {
      dispatch(selectEntity(entity.data.id, 'organizations'));
    }
  });
}

export const generateSlug = (slug) => {
  return {
    type: types.GENERATE_SLUG,
    slug
  }
}

export const clearSlug = () => {
  return {
    type: types.CLEAR_SLUG
  }
}
