import React from "react";
import PropTypes from "prop-types";
import { ListModule, useContainerProps } from "reactifi";
import { BUSINESS_LINE_MAP } from "../../common/businessLines";
import i18n from "lib/i18n";

const DownloadsList = props => {
  props = useContainerProps(props);
  const { currentBusinessLine, deleteMember, isAdmin, selectMember } = props;

  const actions = isAdmin ?
    [
      {
        type: 'edit',
        selectMember
      },
      {
        type: "delete",
        actionType: "confirm",
        confirmTitle: i18n.t("Delete Download"),
        confirmChildren: i18n.t("Are you sure you want to delete this download?"),
        deleteMember
      }
    ] :
    [
      {
        icon: 'down-to-line',
        title: '',
        tooltip: 'Download',
        actionType: 'externalLink',
        action: row => row.download_url,
        target: '_blank',
        download: true
      }
    ];

  const fields = [
    { name: "name", title: i18n.t("Name"), canSort: true, defaultSort: true, width: "100%", formatter: (_, row) => (
      <a href={row.download_url} target="_blank" rel="noopener noreferrer">{row.name}</a>
    ) },
    { name: "year", title: i18n.t("Year"), canSort: true, width: 120, formatter: cell => cell || i18n.t('N/A') },
    ...(currentBusinessLine === undefined ? [{ name: "business_line", title: i18n.t("Training Context"), canSort: true, width: 170, formatter: cell => BUSINESS_LINE_MAP[cell] }] : [])
    /* { name: "file_type", title: i18n.t("File Type"), canSort: true }, -- hidden for now -- */
  ];

  if (currentBusinessLine === false) {
    return null;
  }

  return (
    <ListModule
      {...props}
      actions={actions}
      fields={fields}
      useCoreTable={false}
    />
  );
}

DownloadsList.propTypes = {
  currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  isAdmin: PropTypes.bool,
  deleteMember: PropTypes.func,
  selectMember: PropTypes.func
};

export default DownloadsList;
