import PropTypes from 'prop-types';
import React from 'react';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import createStore from './store/chartsStore';

import ChartsContainer from './containers/ChartsContainer';
import ChartsPageContainer from './containers/ChartsPageContainer';

let index = (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);

  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route
          contexts={props.contexts}
          component={ChartsContainer}
          path="/"
          orgCapabilities={props.organization_capabilities}
        />
        <Route
          contexts={props.contexts}
          component={ChartsPageContainer}
          path="/chart/:id"
        />
      </Router>
    </RootProvider>
  );
};

index.propTypes = {
  contexts: PropTypes.object
};

export default index;
