import PropTypes from 'prop-types';
import React, { Component } from 'react';
import {
  ApiUtil,
  FieldGroup,
  FileUpload,
  FloatingLabelFormField,
  FormSection,
  MaterialCheckbox,
  ModalForm,
  RadioGroupControl,
  s3MediaUploader,
  SelectAsyncControl,
  SelectControl
} from 'reactifi';
import i18n from 'lib/i18n';
import images from '../../../../images/reportimages';

const AVAILABLE_FOR_OPTIONS = Object.freeze([
  { value: 'report_list', label: i18n.t('Report List') },
  { value: 'embed', label: i18n.t('Embed') }
]);

const GROUP_OPTIONS = Object.freeze([
  { value: 'Training Management', label: i18n.t('Training Management') },
  { value: 'Engagement', label: i18n.t('Engagement') },
  { value: 'Insights', label: i18n.t('Insights') }
]);

const DOWNLOAD_OPTIONS = Object.freeze([
  { value: "", label: i18n.t('Not Available') },
  { value: "tableau_api", label: i18n.t('Available via the Tableau REST API') },
  { value: "workbook", label: i18n.t('Available via a hidden view in the Tableau workbook') }
]);

export default class Chart extends Component {
  static propTypes = {
    chartsMeta: PropTypes.object,
    create: PropTypes.func.isRequired,
    current: PropTypes.object,
    displayErrorMessage: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    ruleSets: PropTypes.array.isRequired,
    select: PropTypes.func.isRequired,
    update: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      reportImageUrl: null,
      exportType: null
    };
  }

  async componentDidUpdate(prevProps) {
    const { current } = this.props;
    let reportImageUrl = null;
    if (current && current !== prevProps.current) {
      if (current.image_url) {
        reportImageUrl = current.image_url;
      }
      this.setState({ exportType: current.export_availability, reportImageUrl });
      const organizationIds = current.organization_ids;
      let initialOrganizationData = [];
      if (organizationIds?.length) {
        const organizationData = await ApiUtil.loadApiData(`/api/data/organizations.json?filter[id]=${organizationIds.join(',')}`);
        initialOrganizationData = organizationData.map((organization) => ({
          id: organization.id,
          display: this.buildOrganizationDisplay(organization)
        }));
      }
      this.setState({ initialOrganizationData });

      const suppressOrganizationIds = current.suppress_organization_ids;
      let initialSuppressOrganizationData = [];
      if (suppressOrganizationIds?.length) {
        const organizationData = await ApiUtil.loadApiData(`/api/data/organizations.json?filter[id]=${suppressOrganizationIds.join(',')}`);
        initialSuppressOrganizationData = organizationData.map((organization) => ({
          id: organization.id,
          display: this.buildOrganizationDisplay(organization)
        }));
      }
      this.setState({ initialSuppressOrganizationData });
    }
  }

  onCancel = () => {
    this.setState({ initialOrganizationData: null });
    this.setState({ initialSuppressOrganizationData: null });
    this.props.select(null);
  }

  save = async (chart, action) => {
    this.setState({ reportImageUrl: null })
    try {
      await action(chart);
    } catch (e) {
      this.props.displayErrorMessage('JSON for Properties is Invalid');
    }
    this.onCancel();
  }

  onExportChange = (exportType) => {
    this.setState({ exportType })
  }

  getUserTypeRoles = () => {
    return this.props.ruleSets.reduce((userTypeRoles, rs) => {
      rs.roles.forEach(role => {
        userTypeRoles = [...userTypeRoles, {
          label: `${rs.label} - ${role.label}`,
          value: `${rs.id}:${role.slug}`
        }];
      });

      return userTypeRoles;
    }, []);
  }

  onChartImageUpload = async response => {
    const { current } = this.props;
    current.image_id = response.data.id;
    current.image_url = response.data.attributes.url;

    this.setState({
      reportImageUrl: response.data.attributes.url
    })
  };

  deleteChartImage = async () => {
    const { current } = this.props;
    this.setState({ reportImageUrl: null })
    current.image_id = null;
    current.image = null;
    current.image_url = null;
  }

  buildOrganizationDisplay = organization => organization.name ? `${organization.name}` : `${organization.id}`;

  getChartImage = (placeholderImage) => {
    const { current } = this.props;
    const { reportImageUrl } = this.state;
    let hasImage = Boolean(current && current.image && current.image_url)
    let reportImage = reportImageUrl || placeholderImage;

    return (
      <FormSection>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FileUpload
              className="file-upload-inline"
              uploader={s3MediaUploader}
              accept={['.jpg', '.png', '.gif', '.jpeg']}
              description={{
                textArray: [i18n.t('Image format: JPG, PNG or GIF')]
              }}
              header={{ text: i18n.t('Image'), className: "form-title" }}
              name="image"
              icon={{
                className: 'collection-image',
                iconSize: 'medium',
                fileClassName: 'file',
                noFileClassName: 'file-slash'
              }}
              input={{ name: 'image' }}
              button={{
                text: i18n.t('Upload Image'),
                className: 'btn btn-secondary'
              }}
              actions={{
                success: this.onChartImageUpload,
                failure: ({ message }) => this.props.displayErrorMessage(message),
                delete: this.deleteChartImage
              }}
              embedPreviewUrl={reportImage}
              deleteButton={true}
              label={{ text: hasImage && current.image.name, closable: false }}
              instructionalText={i18n.t("Upload an image with a 1:1 aspect ratio at a minimum size of 300 x 300px. This will help users understand what the report  is about. If you don't upload one, a fallback image similar to the one below will be used.")}
            />
          </FieldGroup>
        </FieldGroup>
      </FormSection>

    )
  }

  render() {
    const { chartsMeta } = this.props;
    const chartCount = chartsMeta && chartsMeta.total_count;
    const placeholderImage = chartCount ? images[chartCount % images.length] : images[0];
    const isPeriscope = this.props.current && this.props.current.chart_type === 'Datafi::PeriscopeChart';

    let modalTitle = i18n.t('Add a New Chart');
    if (this.props.current && this.props.current.id) {
      modalTitle = i18n.t('Edit %s', { postProcess: 'sprintf', sprintf: [this.props.current.name] });
    }
    return (
      <ModalForm
        data={this.props.current}
        errorMessage={this.props.errorMessage}
        title={modalTitle}
        onCancel={() => this.onCancel()}
        addAction={(chart) => this.save(chart, this.props.create)}
        updateAction={(chart) => this.save(chart, this.props.update)}
        saveAndAddButton={false}
      >
        <FieldGroup className="container-fluid">
          <FormSection title={i18n.t('Chart Display')}>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField name="slug" caption={i18n.t('Slug')} required={true} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField name="name" caption={i18n.t('Name')} required={true} maxlength={59} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField name="description" caption={i18n.t('Description')} as="textarea" maxlength={79} />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FloatingLabelFormField className="col-12" caption={i18n.t('Report Group')} name="group" required={true}>
                <SelectControl options={GROUP_OPTIONS} multi={false} valueKey="value" labelKey="label" />
              </FloatingLabelFormField>
            </FieldGroup>
          </FormSection>
          {this.getChartImage(placeholderImage)}
          <FormSection title={i18n.t('Chart Details')}>
            {isPeriscope ?
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="path" caption={i18n.t('Path')} required={true} />
                </FieldGroup>
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="dashboard_id" caption={i18n.t('Dashboard ID')} required={true} />
                </FieldGroup>
              </FieldGroup> :
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="dashboard" caption={i18n.t('Dashboard')} required={true} />
                </FieldGroup>
                <FieldGroup className="col-12">
                  <FloatingLabelFormField name="view" caption={i18n.t('View')} required={true} />
                </FieldGroup>
              </FieldGroup>
            }
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField caption={i18n.t('User Types/Roles')} name="rule_set_roles" required={true}>
                  <SelectControl options={this.getUserTypeRoles()} multi={true} valueKey="value" labelKey="label" />
                </FloatingLabelFormField>
              </FieldGroup>

            </FieldGroup>
            <FieldGroup className="row">
              <FloatingLabelFormField className="col-12" caption={i18n.t('Available For')} name="available_for">
                <SelectControl options={AVAILABLE_FOR_OPTIONS} multi={true} valueKey="value" labelKey="label" />
              </FloatingLabelFormField>
            </FieldGroup>
            <FieldGroup>
              <MaterialCheckbox name="maintenance" inline={true} valueOverride={true}>
                {i18n.t('Maintenance?')}
              </MaterialCheckbox>
            </FieldGroup>
            <FieldGroup>
              <MaterialCheckbox name="show_toolbar" inline={true} valueOverride={true}>
                {i18n.t('Show Toolbar?')}
              </MaterialCheckbox>
            </FieldGroup>
            <FieldGroup>
              <MaterialCheckbox name="use_cache" inline={true} valueOverride={true}>
                {i18n.t('Use cache?')}
              </MaterialCheckbox>
            </FieldGroup>
            <FieldGroup>
              <MaterialCheckbox name="disable_refresh" inline={true} valueOverride={true}>
                {i18n.t('Disable Refresh Data?')}
              </MaterialCheckbox>
            </FieldGroup>
          </FormSection>
          {!isPeriscope &&
            <FormSection title={i18n.t('CSV Data Download Availability')} description={i18n.t('Select the mechanism (if any) that allows a customer admin to download additional granular data for the embedded report.')}>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <RadioGroupControl
                    name="export_availability"
                    valueList={DOWNLOAD_OPTIONS}
                    valueKey="value"
                    labelKey="label"
                    onChange={this.onExportChange}
                  />
                </FieldGroup>
              </FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField
                    caption={i18n.t('Workbook for CSV Export')}
                    disabled={this.state.exportType !== 'tableau_api'}
                    name="csv_workbook"
                    required={this.state.exportType === 'tableau_api'}
                  />
                </FieldGroup>
              </FieldGroup>
              <FieldGroup className="row">
                <FieldGroup className="col-12">
                  <FloatingLabelFormField
                    caption={i18n.t('View for CSV Export')}
                    disabled={!this.state.exportType}
                    name="csv_view"
                    required={!!this.state.exportType}
                  />
                </FieldGroup>
              </FieldGroup>
            </FormSection>
          }
          {this.state.initialOrganizationData &&
            <FormSection title={i18n.t('Limited or General Availability')} description={i18n.t('Select organization(s) to limit access to this report. If none are selected, the report is generally available.')}>
              <FieldGroup className="row">
                <FloatingLabelFormField
                  className="col-12"
                  caption={i18n.t('Organization(s)')}
                  name="organization_ids">
                  <SelectAsyncControl
                    multi={true}
                    queryFields={{ name: 'word_start,salesforce_id.text_start,slug.text_start,name.exact,short_id.exact' }}
                    placeholder=""
                    dataUrl="/api/data/organizations.json"
                    buildDisplay={this.buildOrganizationDisplay}
                    labelKey="display"
                    initialData={this.state.initialOrganizationData}
                    displayProperty="name"
                    sortBy="name"
                    pageSize={20}
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FormSection>
          }
          {this.state.initialSuppressOrganizationData &&
            <FormSection title={i18n.t('Suppress Availability')} description={i18n.t('Select organization(s) to suppress access to this report. If none are selected, the report is generally available. Only organizations selected should not have access to this report.')}>
              <FieldGroup className="row">
                <FloatingLabelFormField
                  className="col-12"
                  caption={i18n.t('Organization(s)')}
                  name="suppress_organization_ids">
                  <SelectAsyncControl
                    multi={true}
                    queryFields={{ name: 'word_start,salesforce_id.text_start,slug.text_start,name.exact,short_id.exact' }}
                    placeholder=""
                    dataUrl="/api/data/organizations.json"
                    buildDisplay={this.buildOrganizationDisplay}
                    labelKey="display"
                    initialData={this.state.initialSuppressOrganizationData}
                    displayProperty="name"
                    sortBy="name"
                    pageSize={20}
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FormSection>
          }
        </FieldGroup>
      </ModalForm>)
  }
}
