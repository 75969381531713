import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator, NestedForms, AlertMessage, AlertMessageTypes, PageHeader } from 'reactifi';
import * as orgUsersActionCreators from '../actions/orgUsersActionCreators';
import CustomComponent from '../../../lib/components/CustomComponent';
import i18n from 'lib/i18n';
import { sanitizeUrl } from '@braintree/sanitize-url';

function mapStateToProps(state, ownProps) {
  let props = {
    errorMessages: state.api.errorMessages,
    googleMapsApiKey: ownProps.route.googleMapsApiKey,
    successMessage: state.api.successMessage,
    errorMessage: state.api.errorMessage
  };

  let apiStore = new JsonApiDecorator(state.api);
  if (apiStore.registration_sets) {
    props.registrationSets = apiStore.registration_sets.first();
    props.registrations = props.registrationSets.registrations;

    props.isCreate = !props.registrationSets.id && ownProps.routeParams.user_id === 'add';
    props.organizationName = props.registrationSets.organization_name || '';
    props.organizationId = props.registrationSets.organization_id || '';
  }
  else {
    props.registrations = null;
  }
  return props;
}

class OrganizationUserFormContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    organizationId: PropTypes.string,
    googleMapsApiKey: PropTypes.string,
    registrationSets: PropTypes.object.isRequired,
    registrations: PropTypes.array.isRequired,
    errorMessages: PropTypes.array,
    isCreate: PropTypes.bool,
    organizationName: PropTypes.string,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgUsersActionCreators, this.props.dispatch);
    this.addAction = this.addAction.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.redirect = this.redirect.bind(this);
  }

  async addAction() {
    const redirect = await this.actions.register(this.props.registrationSets);
    if (redirect && !this.props.errorMessages) {
      setTimeout(this.redirect, 2000);
    }
  }

  updateAction() {
    this.actions.updateUser(this.props.registrationSets);
  }

  redirect() {
    const url = sanitizeUrl(window.location.href);
    window.location.href = url.substring(0, url.lastIndexOf('/'));
  }

  render() {
    return (
      <div>
        <PageHeader title={this.props.isCreate ? i18n.t('Add a New User ') : i18n.t('Edit User')} />
        <div className="card_wrapper">
          <div className="page-panel">
            <AlertMessage message={this.props.errorMessage} messageType={AlertMessageTypes.danger} visible={this.props.errorMessage} clearMessages={this.actions.clearMessages} />
            <AlertMessage message={this.props.successMessage} messageType={AlertMessageTypes.success} visible={this.props.successMessage} clearMessages={this.actions.clearMessages} />
            {this.props.registrations ?
              <NestedForms data={this.props.registrations} saveButton={true} saveButtonText={this.props.isCreate ? i18n.t('Create User') : i18n.t('Update User')}
                cancelButton={true} onCancel={this.redirect} addAction={this.addAction} updateAction={this.updateAction} errorMessages={this.props.errorMessages}
                fieldGroupClass="modal-body" buttonStyle="primary" buttonGroupClass="modal-footer" isCreate={this.props.isCreate}>
                <CustomComponent googleMapsApiKey={this.props.googleMapsApiKey} isCreate={this.props.isCreate}
                  organizationName={this.props.organizationName} organizationId={this.props.organizationId} loadLocation={this.actions.loadLocation} />
              </NestedForms> :
              <div>{i18n.t('No User Type information available')}<br />
                <a onClick={(e) => this.redirect()}>{i18n.t('Go to organization users to select a User Type')}</a>
              </div>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps)(OrganizationUserFormContainer);
