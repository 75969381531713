import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { orgActionCreators, orgCapabilitiesActions } from '../actions';
import i18n from 'lib/i18n';

import {
  AlertMessage,
  AlertMessageTypes,
  Display50Pct50Pct,
  IconCategoryValue,
  Header,
  MessagesModule,
  NavTabs,
  PageWrapper,
  ProgressSpinner,
  Section,
  Util
} from 'reactifi';
import mapStateToProps from '../store/organizationMap';
import { OrganizationRedactionAlert } from '../components/OrganizationRedactionAlert';

class OrganizationContainer extends Component {
  static propTypes = {
    params: PropTypes.object,
    capabilities: PropTypes.array,
    currentOrganization: PropTypes.object,
    dispatch: PropTypes.func,
    successMessage: PropTypes.string,
    errorMessage: PropTypes.string,
    errorMessages: PropTypes.string,
    showAlertMessage: PropTypes.bool,
    orgCapabilities: PropTypes.array,
    route: PropTypes.shape({
      permissions: PropTypes.arrayOf(PropTypes.string),
      childRoutes: PropTypes.array
    }),
    children: PropTypes.object,
    router: PropTypes.object
  };

  static defaultProps = {
    capabilities: [],
    currentOrganization: {},
    orgCapabilities: []
  };

  constructor(props) {
    super(props);

    this.state = {
      orgLoaded: false,
      orgCapabilities: null
    };

    this.actions = bindActionCreators(
      { ...orgActionCreators, ...orgCapabilitiesActions },
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.loadCapabilities();
    this.loadCurrentOrganization();
  }

  componentDidUpdate(prevProps) {
    if(this.props.params?.id !== prevProps.params?.id) {
      this.loadCapabilities();
      this.loadCurrentOrganization();
    }
  }

  loadCapabilities = async () => {
    const { params } = this.props;
    await this.actions.findCapabilities({ organization_id: params.id });
    this.actions.selectOrgCapabilities(params.id);
  };

  loadCurrentOrganization = async () => {
    const { params } = this.props;
    await this.actions.findOrganization(params.id);

    this.setState({ orgLoaded: true });
  };

  renderAlertMessages = () => {
    const { successMessage, errorMessage, errorMessages } = this.props;
    const error = errorMessages ? errorMessages[0].error : errorMessage;
    const message = successMessage || error;
    const messageType = successMessage
      ? AlertMessageTypes.success
      : AlertMessageTypes.danger;

    return (
      <AlertMessage
        message={message}
        messageType={messageType}
        visible={Boolean(message)}
        clearMessages={this.actions.clearMessages}
      />
    );
  };

  get headerTabs() {
    const { route, orgCapabilities } = this.props;
    return (
      <NavTabs
        {...this.props}
        permissions={route.permissions}
        capabilities={orgCapabilities.map(
          (capability) => capability.capability_type
        )}
        classNames="organization uppercase border-bottom-0"
        childRoutes={route.childRoutes}
      />
    );
  }

  setOrgCapabilities = () => {
    const { orgCapabilities } = this.props;
    if (
      orgCapabilities.length > 0 && 
      orgCapabilities !== this.state.orgCapabilities
    ) {
      this.setState({ orgCapabilities });
    }
  };

  filterCapabilities(groupName, capabilityNames) {
    const { capabilities } = this.props;

    return capabilities
      .filter((capability) => {
        return (
          capability.group_name === groupName &&
          capabilityNames.includes(capability.name)
        );
      })
      .map((cap) => cap.label)
      .join(', ')
  }

  get orgHeaderDetails() {
    const {
      currentOrganization: {
        active,
        bb_salesforce_id,
        bb_legal_entity_id,
        id,
        salesforce_id
      }
    } = this.props;

    this.setOrgCapabilities();

    if (!this.state.orgCapabilities) return null;

    const capabilityNames = this.state.orgCapabilities.map((cap) => cap.capability_type);
    const orgLearningContext = this.filterCapabilities('learning_context', capabilityNames);
    const orgIntegrations = this.filterCapabilities('integrations', capabilityNames);
    const orgAdvFeatures = this.filterCapabilities('advanced_features', capabilityNames);

    return (
      <Display50Pct50Pct className="settings-org-details-container">
        <Display50Pct50Pct>
          <div className="settings-details-container">
            <IconCategoryValue category={i18n.t('ID')} value={id} />
            <IconCategoryValue
              category={i18n.t('SF ID')}
              value={salesforce_id || '-'}
            />
            <IconCategoryValue
              category={i18n.t('BB SF ID')}
              value={bb_salesforce_id || '-'}
            />
            <IconCategoryValue
              category={i18n.t('Legal Entity ID')}
              value={bb_legal_entity_id || '-'}
            />
            <IconCategoryValue
              category={i18n.t('Active?')}
              value={active ? i18n.t('yes') : i18n.t('no')}
            />
          </div>
          <div className="settings-details-container">
            <IconCategoryValue
              category={i18n.t('Learning Context')}
              value={orgLearningContext}
              type="stacked"
            />
          </div>
        </Display50Pct50Pct>
        <Display50Pct50Pct>
          <div className="settings-details-container">
            <IconCategoryValue
              category={i18n.t('Integrations')}
              value={orgIntegrations}
              type="stacked"
            />
          </div>
          <div className="settings-details-container">
            <IconCategoryValue
              category={i18n.t('Advanced Capabilities')}
              value={orgAdvFeatures}
              type="stacked"
            />
          </div>
        </Display50Pct50Pct>
      </Display50Pct50Pct>
    );
  }

  goBack = () => this.props.router.push('/');

  render() {
    const {
      children,
      currentOrganization,
      errorMessage,
      router,
      successMessage
    } = this.props;

    if (!currentOrganization) return null;

    const actionButton = {
      ...Util.dataAttribsForEl(currentOrganization, {
        action: 'view-org-settings'
      }),
      label: i18n.t('Settings'),
      action: () => router.push(`${currentOrganization.id}/settings`)
    };

    return (
      <PageWrapper>
        <MessagesModule
          successMessage={successMessage}
          errorMessage={errorMessage}
          clearMessages={this.actions.clearMessages}
        />
        <Header
          back={this.goBack}
          button={actionButton}
          icon="buildings"
          router={router}
          additionalTitleDisplay={
            <OrganizationRedactionAlert
              currentOrganization={currentOrganization}
            />
          }
          title={
            currentOrganization
              ? currentOrganization.name
              : i18n.t('Organization not found')
          }
        >
          <div className="org-header-content-container">
            {this.orgHeaderDetails}
            {this.headerTabs}
          </div>
        </Header>
        {this.state.orgLoaded ? (
          currentOrganization && children
        ) : (
          <Section>
            <ProgressSpinner />
          </Section>
        )}
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(OrganizationContainer);
