import React from "react";
import PropTypes from "prop-types";
import { RootProvider } from 'reactifi';
import CategoriesContainer from "../Categories/containers/categoriesContainer";
import CategoryContainer from '../Categories/containers/CategoryContainer';
import createStore from "./store/categoriesStore";
import { Router, Route, hashHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";

let index = (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);
  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route
          path="/"
          component={CategoriesContainer}
          organization_id={props.organization_id}
          viewer={props.viewer}
        />
        <Route
          path="/add"
          component={CategoryContainer}
          organization_id={props.organization_id}
          viewer={props.viewer}
        />
        <Route
          path="/:id"
          component={CategoryContainer}
          organization_id={props.organization_id}
          viewer={props.viewer}
          contentServiceUrl={props.content_service_url}
          jwt={props.jwt}
          orgCapabilities={props.organization_capabilities}
        />
      </Router>
    </RootProvider>
  );
};

index.propTypes = {
  organization_id: PropTypes.string,
  viewer: PropTypes.any,
  organization_capabilities: PropTypes.arrayOf(PropTypes.string),
  content_service_url: PropTypes.string,
  jwt: PropTypes.string
};

export default index;
