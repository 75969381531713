import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import { PageWrapper, Header, Section, MessagesModule, ExplainerText, buildGenericProps, NoResultsCard, FileUpload, csvUploader, SearchComponent } from 'reactifi';
import * as locationActionCreators from '../actions/locationActionCreators';
import LocationTable from '../components/LocationTableComponent';
import LocationForm from '../components/LocationForm';
import DeleteLocationModal from '../../common/Components/DeleteLocationConfirmationDialog';
import { NoResultButtons } from '../../common/Components/NoResultsButtons';
import debounce from 'lodash/debounce';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'locations', ['organization']);
  if (props.currentEntity) {
    props.currentLocation = props.currentEntity;
  }
  props.filter = { status: "*" };
  if (ownProps.location && ownProps.location.query && ownProps.location.query.filterField) {
    props.filter[ownProps.location.query.filterField] = ownProps.location.query.filterValue;
  }
  props.googleMapsApiKey = ownProps.route.googleMapsApiKey;
  props.organizationId = ownProps.location.query.filterValue;
  props.viewer = ownProps.route.viewer;
  props.locationMeta = state.api.locations ? state.api.locations.meta : null;
  return props;
}

export class LocationsContainer extends React.Component {
  static propTypes = {
    locations: PropTypes.array,
    locationMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    currentLocation: PropTypes.object,
    googleMapsApiKey: PropTypes.string.isRequired,
    successMessage: PropTypes.string,
    clearMessages: PropTypes.func,
    isLoadingLocations: PropTypes.bool,
    organizationId: PropTypes.string,
    viewer: PropTypes.string.isRequired,
    filter: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(locationActionCreators, this.props.dispatch);
    this.noResultsMessage = i18n.t("Locations are where employees work");
    this.noResultsButtons = [NoResultButtons(this.actions.selectLocation, i18n.t('Create a Location'))];
    this.filterLocations = debounce(this.filterLocations, 350, { leading: false, trailing: true });

    this.state = {
      filtered: false,
      activePage: 1,
      sortName: "external_id",
      sortOrder: "asc",
      showModal: false,
      selectedLocation: null
    }
  }

  componentDidMount() {
    this.filterLocations();
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.filterLocations);
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sort: {
        value: sortName,
        direction: sortOrder
      },
      sortName,
      sortOrder,
      activePage: 1
    }, this.filterLocations);
  }

  onSearchChange = ({ keyword }) => {
    this.setState({ activePage: 1, activeSearch: keyword }, this.filterLocations);
  }

  filterLocations = () => {
    this.actions.readLocations('', this.props.filter, this.state.sort, { number: this.state.activePage }, this.state.activeSearch);
    this.setState({ filtered: true });
  }

  createLocation = async (location, addAnother) => {
    var entity = await this.actions.createLocation(location, addAnother);
    if (entity) {
      setTimeout(this.filterLocations, 1000);
    }
  }

  onLocationInfoUpload = () => {
    this.actions.displaySuccessMessage(i18n.t("File was uploaded successfully"));
    setTimeout(() => {
      this.setState({ activePage: 1 });
      this.actions.readLocations('', this.props.filter, this.state.sort, { number: this.state.activePage });
    }, 1000);
  }

  onUploadFailure = (response) => {
    this.actions.uploadError(response);
  }

  displayCSVUpload = () => {
    if (this.props.viewer !== 'organization_administrator') return null;

    return (
      <div>
        <FileUpload
          uploader={csvUploader}
          url="/api/data/locations/upload_csv"
          description={{ textArray: [i18n.t('Upload a list of locations at your organization.')] }}
          header={{ text: i18n.t('Location') }}
          name="locationInformation"
          tooltip={i18n.t("Uploads locations available in the CSV")}
          icon={{ className: 'images', iconSize: 'medium', fileClassName: 'file', noFileClassName: 'file-slash' }}
          input={{ name: 'locationInformation' }}
          button={{ text: i18n.t('Choose File'), className: 'btn btn-secondary' }}
          actions={{ success: this.onLocationInfoUpload, failure: this.onUploadFailure }}
          templateBtn={{
            href: "/templates/Location_Uploader_Template.csv",
            buttonLabel: i18n.t("Download Template"),
            'data-action': 'download-template'
          }}
        />
      </div>
    );
  }

  onDeleteClickLocation = selectedLocation => {
    this.setState({ selectedLocation, showModal: true });
  };

  onEditClickLocation = location => this.actions.selectLocation(location.id);

  deleteLocation = async () => {
    await this.actions.deleteLocation(this.state.selectedLocation);
    this.filterLocations();
    this.onCancelDeleteLocation();
  };

  onCancelDeleteLocation = () => this.setState({
    selectedLocation: null,
    showModal: false
  });

  render() {
    return (
      <PageWrapper>
        <MessagesModule
          successMessage={this.props.successMessage}
          errorMessage={this.props.currentLocation ? null : this.props.errorMessage}
          clearMessages={this.props.clearMessages}
        />
        <Header title={i18n.t('Locations')} button={{
          label: i18n.t("New Location"),
          'data-action': 'new-location',
          action: () => this.actions.selectLocation('add')
        }} />
        <Section>
          <ExplainerText>
            {i18n.t("Setup locations to reflect the local laws and different administrative conditions for learners. This helps you stay organized and your learners get the right training.")}
          </ExplainerText>
          {this.displayCSVUpload()}
        </Section>
        <Section type="list" noBorder={true} noPadding={true}>
          <SearchComponent
            name="keyword"
            placeholder={i18n.t('Search by Name, Location, Zip Code')}
            searchAction={this.onSearchChange}
            wrapperClass="full-width"
          />
        </Section>
        <Section type="list">
          {this.props.isLoadingLocations || this.props.locations.length || this.state.filtered ?
            <LocationTable
              options={{
                onSortChange: this.onSortChange,
                ...this.state.sort
              }}
              fieldsProps={{
                viewer: this.props.viewer,
                onEdit: this.onEditClickLocation,
                onDelete: this.onDeleteClickLocation
              }}
              onPageChange={this.onPageChange}
              isLoadingData={this.props.isLoadingLocations}
              totalCount={this.props.locationMeta ? this.props.locationMeta.total_count : 0}
              activePage={this.state.activePage}
              data={this.props.locations}
            />
            :
            <NoResultsCard message={this.noResultsMessage} buttons={this.noResultsButtons} />
          }
        </Section>
        <LocationForm currentLocation={this.props.currentLocation} selectLocation={this.actions.selectLocation} clearMessages={this.actions.clearMessages}
          googleMapsApiKey={this.props.googleMapsApiKey} errorMessage={this.props.errorMessage} viewer={this.props.viewer} organizationId={this.props.organizationId}
          updateLocation={this.actions.updateLocation} createLocation={this.createLocation} loadOrganization={this.actions.loadOrganization} />
        <DeleteLocationModal
          onConfirm={this.deleteLocation}
          onCancel={this.onCancelDeleteLocation}
          show={this.state.showModal}
        />
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(LocationsContainer);
