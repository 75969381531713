import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import createStore from './store/userUploadsStore';
import ListContainer from './containers/ListContainer';
import UploadContainer from './containers/UploadContainer';
import UploadConfirmationContainer from '../common/Components/upload/UploadConfirmationContainer';

let index = (props, _railsContext) => {
  const store = createStore({});
  const history = syncHistoryWithStore(hashHistory, store);
  const left_text_button = "Back to Manage Users";
  const right_text_button = "View My Uploads";
  const left_url_button = `/${props.organization_slug}/cportal/users`;
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route
          path="/"
          component={ListContainer}
          batchId={props.batch_id}
          organization_id={props.organization_id}
          reportType={props.report_type}
          userBusinessLines={props.user_business_lines}
        />
        <Route
          path="/confirmation"
          component={UploadConfirmationContainer}
          left_text_button={left_text_button}
          left_url_button={left_url_button}
          organization_slug={props.organization_slug}
          right_text_button={right_text_button}
        />
        <Route
          path="/:id"
          component={UploadContainer}
          editable_fields_default={props.editable_fields_default}
          organization_capabilities={props.organization_capabilities}
          organization_id={props.organization_id}
          organization_slug={props.organization_slug}
          user_is_pii_restricted_admin={props.user_is_pii_restricted_admin}
        />
      </Router>
    </RootProvider>
  )
}

index.propTypes = {
  editable_fields_default: PropTypes.bool,
  organization_id: PropTypes.string,
  organization_slug: PropTypes.string,
  organization_capabilities: PropTypes.arrayOf(PropTypes.string),
  user_business_lines: PropTypes.arrayOf(PropTypes.string)
}

export default index;
