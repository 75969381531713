import React from 'react';
import { PagingDataList, FAIcon } from 'reactifi';
import PropTypes from "prop-types";
import i18n from 'lib/i18n';

export default class LocationTable extends React.Component {
  static propTypes = {
    fieldsProps: PropTypes.object
  }

  get actionConfig() {
    return [
      {
        icon: "pen",
        title: "",
        tooltip: i18n.t("Edit Location"),
        type: "edit",
        actionType: "callToAction",
        action: this.editAction
      },
      {
        icon: "trash",
        title: "",
        tooltip: i18n.t("Delete Location"),
        type: "delete",
        actionType: "callToAction",
        action: this.deleteAction
      }
    ];
  }

  editAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.fieldsProps.onEdit(row)
  }

  deleteAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.fieldsProps.onDelete(row);
  }

  formatAddress = (cell, row) => {
    if(['unprocessed', 'processing'].includes(row.status)) {
      return (<span><FAIcon icon="spinner" spin={true} />{i18n.t(' Validation Pending')}</span>)
    } else if(row.status === 'error') {
      return (<span className="error-text">{i18n.t('Address not found')}</span>)
    } else {
      return cell
    }
  }

  getFields = ({ viewer }) => {
    let fields = [
      { name: 'external_id', title: i18n.t('External ID'), width: '20%', canSort: true },
      { name: 'name', title: i18n.t('Name'), width: '20%', canSort: true },
      {
        name: 'address_formatted',
        title: i18n.t('Address'),
        width: '30%',
        canSort: true,
        formatter: (cell, row) => this.formatAddress(cell, row)
      },
      { name: 'address_room', title: i18n.t('Address Line 2'), width: '30%', canSort: true },
      { name: 'contact_name', title: i18n.t('Contact'), width: '20%', canSort: true }
    ];
    if (viewer === 'admin') {
      fields = fields.concat([{
        name: 'organization_name',
        title: i18n.t('Organization Name'),
        width: '20%',
        canSort: true,
        formatter: (cell, row) => row.organization ? row.organization.name : ''
      }]);
    }
    return fields;
  }

  render() {
    const { fieldsProps, ...props } = this.props;
    return <PagingDataList fields={this.getFields(fieldsProps)} actions={this.actionConfig} keyField="id" {...props}/>;
  }
}
