import React from "react";
import PropTypes from 'prop-types';
import { Wrapper, createContainer } from "reactifi";
import Downloads from "../components/Downloads";
import config from '../configs/downloadsContainerConfig';

const Container = createContainer(config);

const DownloadsContainer = props => {
  return (
    <Container {...props}>
      <Downloads />
    </Container>
  );
};

DownloadsContainer.propTypes = {
  WrapperComponent: PropTypes.elementType
};

DownloadsContainer.defaultProps = {
  WrapperComponent: Wrapper
};

export default DownloadsContainer;
