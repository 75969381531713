import React from 'react';
import PropTypes from 'prop-types';
import { DisplayWidthForBreakpoints, ExplainerText, Section, useContainerProps } from 'reactifi';
import i18n from 'lib/i18n';
import _get from 'lodash/get';
import { engage, impact, learn, promote, train } from '../../../../images/interstitial';
import AppWrapper from './AppWrapper';

const APP_CONFIG = Object.freeze([
  {
    id: 'Train',
    title: i18n.t('Train'),
    description: i18n.t('Assign courses and manage learning'),
    image: train,
    showRuleSetModal: true
  },
  {
    id: 'Learn',
    title: i18n.t('Learn'),
    description: i18n.t('Complete training assigned to you'),
    image: learn
  },
  {
    id: 'Impact',
    title: i18n.t('Impact'),
    description: i18n.t('View and share the impact of initiatives'),
    image: impact
  },
  {
    id: 'Promote',
    title: i18n.t('Promote'),
    description: i18n.t('Manage educational marketing programs'),
    image: promote
  },
  {
    id: 'Engage',
    title: i18n.t('Engage'),
    description: i18n.t('Manage webinars and in-person events'),
    image: engage
  }
]);

const Interstitial = props => {
  props = useContainerProps(props);

  const { route: { ruleSets: ruleSetsConfig } } = props;

  const appElements = APP_CONFIG
    .filter(config => !!ruleSetsConfig[config.id])
    .map(config => {
      const ruleSets = ruleSetsConfig[config.id];
      const href = _get(ruleSets, '[0].attributes.homepage.url');
      const ruleSetNames = ruleSets
        .map(ruleSet => _get(ruleSet, 'attributes.name'))
        .filter(Boolean);
      return { ...config, href, ruleSets: ruleSetNames };
    });

  return (
    <Section className="interstitial">
      <DisplayWidthForBreakpoints>
        <>
          <ExplainerText align="center" size="large">
            {i18n.t('Welcome! It looks like you have more than one app. Please select why you are here:')}
          </ExplainerText>
          <AppWrapper appElements={appElements} />
        </>
      </DisplayWidthForBreakpoints>
    </Section>
  );
};

Interstitial.propTypes = {
  ruleSets: PropTypes.object,
  route: PropTypes.object
};

export default Interstitial;
