import i18n from 'lib/i18n';

export const availableTagManagers = [
  { label: i18n.t('Google Tag Manager'), value: 'google' },
  { label: i18n.t('Tealium iQ (Third-Party URL)'), value: 'tealium' },
  { label: i18n.t('Adobe Launch'), value: 'adobe' }
];

export const checkInput = (
  input = '',
  context = '',
  setErrorMessage = () => {}
) => {
  switch (context) {
    case 'adobe':
      if (!input.startsWith('//assets.adobetm.com')) {
        setErrorMessage(
          i18n.t('Address must start with //assets.adobedtm.com')
        );
        return;
      }
      break;
  }

  setErrorMessage(null);
};
