import React from "react";
import PropTypes from "prop-types";
import {
  ModalForm,
  FieldGroup,
  FloatingLabelFormField,
  CheckboxGroupControl,
  MaterialCheckbox,
  FormField
} from "reactifi";
import i18n from "lib/i18n";

export default class AdministratorForm extends React.Component {
  static propTypes = {
    administratorGroups: PropTypes.array,
    clearMessages: PropTypes.func.isRequired,
    createAdministrator: PropTypes.func.isRequired,
    currentAdministrator: PropTypes.object,
    errorMessage: PropTypes.string,
    selectAdministrator: PropTypes.func.isRequired,
    updateAdministrator: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);
    this.state = {
      selectedAdministratorGroups: this.setAdminGroups()
    }
  }

  selectAdminGroups = (value) => {
    this.setState({ selectedAdministratorGroups: value })
  }

  setAdminGroups = () => {
    let { currentAdministrator } = this.props;
    if(currentAdministrator) {
      return currentAdministrator.administrator_group_ids;
    }
    return [];
  }

  onCancel = () => {
    this.props.selectAdministrator(null);
    this.setState({ selectedAdministratorGroups: [] });
    this.props.clearMessages();
  }

  updateAdministrator = () => {
    this.props.currentAdministrator.administrator_group_ids = this.state.selectedAdministratorGroups;
    this.props.updateAdministrator(this.props.currentAdministrator);
  }

  createAdministrator = async (administrator, addAnother) => {
    administrator.administrator_group_ids = this.state.selectedAdministratorGroups;
    await this.props.createAdministrator(administrator, addAnother);
  }

  render() {
    let modalTitle =
      this.props.currentAdministrator && this.props.currentAdministrator.id
        ? i18n.t("Edit %s %s", {
          postProcess: "sprintf",
          sprintf: [this.props.currentAdministrator.first_name, this.props.currentAdministrator.last_name]
        })
        : i18n.t("Add a New Administrator");

    return (
      <ModalForm
        data={this.props.currentAdministrator}
        errorMessage={this.props.errorMessage}
        title={modalTitle}
        onCancel={this.onCancel}
        addAction={this.createAdministrator}
        updateAction={this.updateAdministrator}
      >
        <FieldGroup className="container-fluid">
          <div className="row">
            <div className="col-12">
              <h4>{i18n.t("Administrator Details")}</h4>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-6">
              <FloatingLabelFormField
                name="first_name"
                caption={i18n.t("First Name")}
                required={true}
              />
            </FieldGroup>
            <FieldGroup className="col-6">
              <FloatingLabelFormField
                name="last_name"
                caption={i18n.t("Last Name")}
                required={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="email"
                caption={i18n.t("Email")}
                required={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="azure_ad_email"
                caption={i18n.t("AzureAD Email (i. e. firstname.lastname@blackbaud.me)")}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <MaterialCheckbox
                name="active"
                label={i18n.t("Admin Account Active")}
              />
            </FieldGroup>
          </FieldGroup>
          <div className="row">
            <div className="col-12">
              <h5>{i18n.t("Roles")}</h5>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FormField name="administrator_group_ids" className="bordered-input-container" required={true}>
                <CheckboxGroupControl
                  className="input-container scrollable-input-container"
                  valueList={this.props.administratorGroups}
                  valueKey="id"
                  labelKey="name"
                  onChange={this.selectAdminGroups}
                  CheckboxComponent={MaterialCheckbox}/>
              </FormField>
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    );
  }
}
