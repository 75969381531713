import React from "react";
import PropTypes from "prop-types";
import {
  FieldGroup,
  FormField,
  FloatingLabelFormField,
  DateTimePickerControl,
  SelectAsyncControl,
  ProgressSpinner,
  ApiUtil
} from "reactifi";
import i18n from "lib/i18n";
import uniq from 'lodash/uniq';

export default class SurveyData extends React.Component {
  static propTypes = {
    currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    contentServiceUrl: PropTypes.string.isRequired,
    dataExport: PropTypes.shape({
      filters: PropTypes.object
    }),
    evalufiUrl: PropTypes.string.isRequired,
    jwt: PropTypes.string.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {
      displayFilters: {}
    };
  }

  async componentDidMount() {
    const { dataExport: { filters } } = this.props;
    this.setState({ maxDate: new Date() });
    if (!filters) {
      return;
    }
    this.setState({ isLoading: true });
    const [content, evaluation] = await Promise.all([
      this.initContent(filters.course_id),
      this.initEvaluation(filters.evaluation_id)
    ]);
    this.onFromDateChange(filters.begin_date);
    this.setState({
      content, evaluation, isLoading: undefined
    });
  }

  initContent = async contentId => {
    if (!contentId) {
      return null;
    }
    const { contentServiceUrl, jwt } = this.props;
    const apiUrl = `${contentServiceUrl}/api/v1/contents/${contentId}.json`;
    const content = await ApiUtil.loadExternalData(apiUrl, jwt);
    if (content) {
      await this.onContentChange({ data: content });
    }
    return content;
  }

  initEvaluation = async evaluationId => {
    if (!evaluationId) {
      return null;
    }
    const { evalufiUrl, jwt } = this.props;
    const apiUrl = `${evalufiUrl}/api/v1/surveys/${evaluationId}.json`;
    const evaluation = await ApiUtil.loadExternalData(apiUrl, jwt);
    if (evaluation) {
      this.onEvaluationChange({ data: evaluation });
    }
    return evaluation;
  }

  mergeDisplayFilters = (key, value) => {
    const { displayFilters } = this.state;
    this.setState({ displayFilters: { ...displayFilters, [key]: value } });
  }

  onContentChange = async contentEntity => {
    if (!contentEntity || !contentEntity.data) {
      return;
    }
    const content = contentEntity.data;
    if (!content) {
      this.setState({ content: undefined, evalufi_slugs: undefined });
      this.mergeDisplayFilters('content', undefined);
      return;
    }
    this.mergeDisplayFilters('content', content.name);
    const evalufiSlugs = await this.getEvalufiSlugsFromContent(content);
    this.setState({ content, evalufiSlugs });
  }

  getEvalufiSlugsFromContent = async content => {
    const { content_type } = content;
    if (content_type === 'group') {
      const { contentServiceUrl, jwt } = this.props;
      const apiUrl = `${contentServiceUrl}/api/v1/content_group_items.json?include=content&filter[content_group_id]=${content.id}`;
      const result = await ApiUtil.loadExternalData(apiUrl, jwt, false);
      let promises = [];
      if (result && result.included) {
        promises = result.included.map(async included => {
          const includedContent = Object.assign({ id: included.id, type: included.type }, included.attributes);
          return await this.getEvalufiSlugsFromContent(includedContent);
        });
      }
      const evalufiSlugs = await Promise.all(promises);
      return uniq(evalufiSlugs.filter(array => array).flat());
    } else if (content_type === 'survey') {
      return [content.evalufi_slug];
    } else if (['course', 'course_module'].includes(content_type)) {
      return Object.values(content.data.evaluations).filter(slug => slug);
    }
  }

  onEvaluationChange = evaluationEntity => {
    const evaluation = evaluationEntity && evaluationEntity.data;
    if (!evaluation) {
      this.mergeDisplayFilters('evaluation', undefined);
    } else {
      this.mergeDisplayFilters('evaluation', evaluation.name);
    }
  }

  onFromDateChange = fromDate => {
    this.setState({ minEndDate: fromDate ? new Date(fromDate) : undefined });
  }

  renderSelectCourse = () => {
    const { contentServiceUrl, currentBusinessLine, jwt } = this.props;
    const { content } = this.state;

    return (<FieldGroup className="row">
      <FloatingLabelFormField
        className="col-12"
        caption={i18n.t("Select Course")}
        name="filters[course_id]"
        required={true}>
        <SelectAsyncControl
          multi={false}
          filterField="keyword"
          placeholder=""
          initialData={content && {
            id: content.id,
            display: content.name
          }}
          dataUrl={`${contentServiceUrl}/api/v1/contents.json`}
          authToken={jwt}
          filters={{ type: 'course,course_module,group,survey', active_only: false, business_lines: currentBusinessLine }}
          displayProperty="name"
          sortBy="name"
          pageSize={100}
          onChange={(content) => this.onContentChange(content)}
        />
      </FloatingLabelFormField>
    </FieldGroup>);
  }

  renderSelectEvaluation = () => {
    const { evalufiUrl, jwt } = this.props;
    const { evalufiSlugs, content, evaluation } = this.state;
    const noEvaluations = evalufiSlugs && evalufiSlugs.length === 0;

    return (
      <FieldGroup className="col-12 p-0">
        {noEvaluations &&
          <div className="col-12 foundry-red">
            {i18n.t('The content you selected does not have any evaluations.')}
          </div>}
        <FieldGroup>
          <FloatingLabelFormField
            caption={i18n.t("Select Evaluation")}
            name="filters[evaluation_id]"
            disabled={!content}
            required={true}>
            <SelectAsyncControl
              multi={false}
              filterField="keyword"
              placeholder=""
              initialData={evaluation && {
                id: evaluation.id,
                display: evaluation.name
              }}
              dataUrl={`${evalufiUrl}/api/v1/surveys.json`}
              authToken={jwt}
              filters={{ slug: evalufiSlugs, sqs: false }}
              pageSize={100}
              displayProperty="name"
              sortBy="name"
              cacheEnabled={false}
              onChange={(evaluation) => this.onEvaluationChange(evaluation)}
            />
          </FloatingLabelFormField>
        </FieldGroup>
      </FieldGroup>);
  }

  renderDateRange = () => {
    const { minEndDate, maxDate } = this.state;

    return (<FieldGroup className="row">
      <FieldGroup className="col-6">
        <FloatingLabelFormField caption={i18n.t("From Date")} name="filters[begin_date]">
          <DateTimePickerControl momentFormat="YYYY-MM-DD" maxDate={maxDate} onChange={this.onFromDateChange} />
        </FloatingLabelFormField>
      </FieldGroup>
      <FieldGroup className="col-6">
        <FloatingLabelFormField caption={i18n.t("To Date")} name="filters[end_date]">
          <DateTimePickerControl momentFormat="YYYY-MM-DD" minDate={minEndDate} maxDate={maxDate} />
        </FloatingLabelFormField>
      </FieldGroup>
    </FieldGroup>);
  }

  renderHiddenDisplayFilters = () => {
    const { displayFilters } = this.state;
    const displayFiltersString = JSON.stringify(displayFilters);

    return (<FormField type="hidden"
      name="display_filters"
      className="hidden"
      dataValue={displayFiltersString}
      valueOverride={true} />);
  }

  render() {
    const { currentBusinessLine, dataExport } = this.props;
    const { isLoading } = this.state;

    if (isLoading || currentBusinessLine === false) {
      return <ProgressSpinner size="lg" />;
    }

    return (<FieldGroup data={dataExport}>
      {this.renderHiddenDisplayFilters()}
      {this.renderSelectCourse()}
      {this.renderSelectEvaluation()}
      {this.renderDateRange()}
    </FieldGroup>);
  }
}
