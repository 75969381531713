import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { ConstructionCard, AccessControlUtil } from 'reactifi';

const OrgBenchmarkingAttributesCard = props => {
  const { permissions, showOrgBenchmarkModal } = props;
  const canManageBenchmarks = AccessControlUtil.hasPermission("manage_benchmarks", permissions);
  const editButton = [{
    label: "Edit",
    style: "secondary",
    action: showOrgBenchmarkModal
  }]

  return (
    <ConstructionCard
      alwaysCollapsed={true}
      title={i18n.t("Benchmarking Attributes")}
      buttonConfig={canManageBenchmarks ? editButton : null}
    />
  );
};

OrgBenchmarkingAttributesCard.propTypes = {
  permissions: PropTypes.array,
  showOrgBenchmarkModal: PropTypes.func
};

export default OrgBenchmarkingAttributesCard;
