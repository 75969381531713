import React from "react";
import PropTypes from "prop-types";
import { NOT_EDITABLE_ATTRIBUTES, SAML_ATTRIBUTES } from '../../common/samlAttributes';
import { FloatingLabelFormField, SelectControl, MaterialCheckbox, FieldGroup, Table, FormField, StringUtil, Button } from "reactifi";
import i18n from "lib/i18n";

export default class SamlAttribute extends React.Component {
  static propTypes = {
    name: PropTypes.string,
    data: PropTypes.object,
    onValueChange: PropTypes.func,
    onChange: PropTypes.func
  };

  constructor(props) {
    super(props);
    let defaultEntity = {
      ar_attribute: '',
      saml_attribute: '',
      editable: false
    };

    this.state = {
      entity: defaultEntity,
      defaultEntity: defaultEntity,
      attributes: [],
      data: props.data
    }

    if (props.data) {
      this.state.attributes = props.data[props.name] || [];
    }
  }

  static sanitize(attributes) {
    attributes = attributes.slice();

    return attributes
      .map(attribute => {
        if (attribute.temporary) {
          delete attribute.id;
          delete attribute.temporary;
        }
        return attribute;
      })
      .filter(attribute => !attribute.id || attribute._destroy);
  }

  onChange = (value) => {
    if (typeof this.props.onChange === "function") {
      this.props.onChange(value);
    }
    if (typeof this.props.onValueChange === "function") {
      const name = this.props.name + "_attributes";
      this.props.onValueChange({ [name]: value });
    }
  }

  addItem = () => {
    let attributes = this.state.attributes;
    let entity = this.state.defaultEntity;

    let addObject = Object.assign(
      {
        id: "temporary" + this.state.attributes.length,
        temporary: true
      },
      this.state.entity
    )
    if(addObject.ar_attribute) {
      attributes.push(addObject);
    }

    this.setState({ attributes });
    this.setState({ entity });

    this.onChange(SamlAttribute.sanitize(this.state.attributes));

  }

  removeItem = (item) => {
    const idx = this.state.attributes.indexOf(item);

    this.state.attributes.splice(idx, 1);

    if (!item.temporary) {
      let attributes = this.state.attributes;
      attributes.push(
        Object.assign(
          {
            _destroy: 1 //maybe 1
          },
          item
        )
      );
      this.setState({ attributes })
    }

    this.onChange(SamlAttribute.sanitize(this.state.attributes));
  }

  getOptions = () => {
    let options = SAML_ATTRIBUTES.slice();

    if (this.state.attributes.length) {
      let usedOptions = this.state.attributes.filter(option => option.ar_attribute && !option._destroy).map(op => op.ar_attribute);
      options = options.filter(option => !usedOptions.find(usedOption => usedOption === option.value))
    }
    return options;
  }

  canBeEditable = (attribute) => {
    return !NOT_EDITABLE_ATTRIBUTES.includes(attribute);
  }

  render() {
    let options = this.getOptions();

    let fields = [
      { name: "ar_attribute", title: "Foundry User Property", formatter: (cell, row) => {
        if(cell === 'location') {
          cell = 'location_name'
        }
        return StringUtil.UnderscoresToTitleCase(cell)
      } },
      { name: "saml_attribute", title: "SAML Attribute" },
      { name: "editable", title: "Is editable?", formatter: (cell, row) => cell ? "Yes" : "No" },
      {
        name: "actions",
        title: i18n.t("Actions"),
        formatter: (cell, row) => (
          <Button
            label={i18n.t("Remove")}
            style="primary"
            onClick={() => {this.removeItem(row)}}
          />
        )
      }
    ];

    return (
      <div className="card form-view-wrapper">
        <h4 className="section-top-header">{`${i18n.t("SAML Attribute Map")}`}</h4>
        <div>{i18n.t("Attributes will set values for new users (if permitted) and overwrite values for existing users.")}</div>
        <div className="card-body p-l-0">
          <div className="clearfix" />
          <div className="form-list-item">
            <FieldGroup
              data={this.state.entity}
              onValueChange={ent => {
                if (!this.canBeEditable(ent.ar_attribute)) {
                  ent.editable = false;
                }

                this.setState({
                  entity: Object.assign({}, this.state.entity, ent)
                });
              }}
            >
              <FloatingLabelFormField className="col-lg-4" caption={i18n.t("Foundry User Property")} name="ar_attribute">
                <SelectControl options={options} multi={false} valueKey="value" labelKey="label" valueOverride={true} />
              </FloatingLabelFormField>
              <FloatingLabelFormField className="col-lg-4" caption={i18n.t("SAML Attribute")} name="saml_attribute" valueOverride={true}/>
              {this.canBeEditable(this.state.entity.ar_attribute) && (
                <FormField className="col-lg-3 editable-saml-checkbox" name="editable">
                  <MaterialCheckbox inline={true} valueOverride={true}>
                    {i18n.t("Is editable?")}
                  </MaterialCheckbox>
                </FormField>
              )}
            </FieldGroup>
            <div className="clearfix" />
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4">
                  <Button
                    label={i18n.t("Add")}
                    style="secondary"
                    onClick={this.addItem}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr />
        <div className="clearfix" />
        <div className="card-body">
          <div className="clearfix" />
          <div className="form-list-item">
            <Table
              fields={fields}
              keyField="id"
              data={this.state.attributes.filter(attr => !attr._destroy)}
            />
          </div>
        </div>
      </div>
    );
  }
}
