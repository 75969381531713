import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { ApiUtil, PageWrapper, Header } from 'reactifi';
import i18n from 'lib/i18n';

import OrgUserChangelog from '../../Organizations/components/OrgUserChangelog';

const ChartsChangelogContainer = props => {

  const [changelog, setChangelog] = useState([]);
  const [authors, setAuthors] = useState([]);

  useEffect(() => {
    if (props.params.slug) {
      getChangelogData(props.params.slug);
    }
  }, [props.params.slug]);

  const getAuthorsId = (data, type) => [...new Set(data.filter(item => item.author_type === type).map(item => item.author_id))];

  const getAuthors = async (data) => {
    const adminAuthors = getAuthorsId(data, 'Administrator');
    const userAuthors = getAuthorsId(data, 'User');

    const admins = (adminAuthors.length > 0) ? await ApiUtil.loadApiData(`/api/data/administrators.json?filter[id]=${adminAuthors}`) : [];
    const users = (userAuthors.length > 0) ? await ApiUtil.loadApiData(`/api/data/users.json?filter[id]=${userAuthors}`) : [];
    setAuthors([...admins, ...users]);
  }

  const getChangelogData = async (chartSlug) => {
    const result = await ApiUtil.loadApiData(`/api/data/charts/${chartSlug}/changes.json`);
    setChangelog(result);
    getAuthors(result);
  }

  return (
    <PageWrapper>
      <Header
        back={false}
        icon="clock-rotate-left"
        title={i18n.t('Changelog')}
      />
      <OrgUserChangelog 
        authors={authors}
        changelog={changelog}
        changelogMeta={{ total_count: changelog.length }}
      />
    </PageWrapper>
  );
}

ChartsChangelogContainer.propTypes = {
  params: PropTypes.shape({
    slug: PropTypes.string
  })
};

export default ChartsChangelogContainer;
