import i18n from "lib/i18n";

export const LABELS = Object.freeze([
  { id: 'students_quote', label: i18n.t('Student\'s Quote') },
  { id: 'teachers_quote', label: i18n.t('Teacher\'s Quote') }
]);

const ImpactStoryCardProps = props => {
  const {
    body,
    call_to_action,
    call_to_action_text,
    external_url,
    headline,
    id,
    image_url,
    label
  } = props;

  const specialLabel = LABELS.find(item => item.id === label);
  const buttonConfig = call_to_action ?
    [{
      "data-action": "view-impact_story",
      "data-object-id": id,
      "data-object-type": "impact_story",
      href: external_url,
      label: call_to_action_text,
      style: 'secondary',
      target: '_blank'
    }] :
    undefined;

  return {
    buttonConfig: buttonConfig,
    buttonPosition: "bottom",
    description: body,
    mediaSize: "large",
    mediaUrl: image_url,
    specialLabel: specialLabel && { label: specialLabel.label, icon: "bell-on" },
    title: headline,
    type: "wide"
  };
};

export default ImpactStoryCardProps;
