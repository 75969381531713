import reducers from '../reducers';
import { genericStoreSetup, readLocal } from 'reactifi';

export default props => {
  const initialState = {
    api: {
      googleMapsApiKey: props.googleMapsApiKey,
      domain: props.domain,
      errorMessages: props.errorMessages
    }
  };
  const store = genericStoreSetup(reducers, initialState);
  store.dispatch(readLocal(JSON.parse(props.registrations)));
  return store;
};