/* global tableau */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import mapStateToProps from '../store/organizationMap';

import { TableauChart } from 'reactifi';

export class OrganizationHealthContainer extends Component {
  static propTypes = {
    route: PropTypes.object,
    context: PropTypes.shape({
      iframe_src: PropTypes.string.isRequired,
      chart: PropTypes.shape({
        name: PropTypes.string
      })
    })
  };

  render() {
    const { route } = this.props;
    const tableauUrl = `${
      route.tableauUrl
    }/#/views/Customer_Health_Report/CustomerHealthReport`;

    return (
      <div>
        <TableauChart chartSourceUrl={tableauUrl} tableau={tableau} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(OrganizationHealthContainer);
