import React from 'react';
import { PagingDataList } from 'reactifi';
import { hasPermission } from 'reactifi/dist/es/utils/AccessControlUtil';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { Util } from "@everfi/reactifi";

const SystemMessagingTableComponent = (props) => {

  const viewAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    props.fieldsProps.onView(row);
  };

  const editAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    props.fieldsProps.onEdit(row);
  };

  const deleteAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    props.fieldsProps.onDelete(row);
  };

  const actionConfig = () => {
    const canManage = hasPermission('manage_system_messages', props.permissions);

    if (canManage) {
      return [
        {
          icon: 'pen',
          title: '',
          tooltip: i18n.t('Edit Message'),
          type: 'edit',
          actionType: 'callToAction',
          action: editAction
        },
        {
          icon: 'trash',
          title: '',
          tooltip: i18n.t('Delete Message'),
          type: 'delete',
          actionType: 'callToAction',
          action: deleteAction
        }
      ]
    } else {
      return [
        {
          icon: 'eye',
          title: i18n.t('View'),
          tooltip: i18n.t('View Message'),
          type: 'view',
          actionType: 'callToAction',
          action: viewAction
        }
      ]
    }
  };

  const fields = [
    {
      name: 'title',
      title: i18n.t('Message Title'),
      canSort: true,
      width: '20%'
    },
    {
      name: 'status',
      title: i18n.t('Status'),
      canSort: true,
      width: '16%'
    },
    {
      name: 'start_date',
      title: i18n.t('Start Date'),
      canSort: true,
      width: '16%',
      formatter: (cell) => Util.format(cell, 'P')
    },
    {
      name: 'end_date',
      title: i18n.t('End Date'),
      canSort: true,
      width: '16%',
      formatter: (cell) => Util.format(cell, 'P')
    },
    {
      name: 'updated_at',
      title: i18n.t('Last Updated'),
      canSort: true,
      width: '16%',
      formatter: (cell) => Util.format(cell, 'MM/dd/yyyy h:mm:ss')
    },
    {
      name: 'updated_by',
      title: i18n.t('Updated By'),
      canSort: true,
      width: '16%'
    }
  ];

  const options = {
    onRowClick: editAction,
    defaultSorted: [
      {
        dataField: 'start_date',
        order: 'asc'
      }
    ]
  };

  return (
    <PagingDataList
      {...props}
      fields={fields}
      keyField="id"
      actions={actionConfig()}
      options={options}
    />
  );
};

SystemMessagingTableComponent.propTypes = {
  permissions: PropTypes.arrayOf(PropTypes.string).isRequired,
  fieldsProps: PropTypes.shape({
    onView: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
  }).isRequired
};

export default SystemMessagingTableComponent;
