import i18n from 'lib/i18n';

const xformBenchmarkBeforeSave = benchmark => {
  benchmark.benchmark_groups_attributes = benchmark.benchmark_groups;
  return benchmark;
}

const config = {
  entity: 'benchmark_categories',
  includes: 'benchmark_groups',
  selectMember: {
    updateRoute: true
  },
  createMember: {
    successMessage: i18n.t("Benchmark created successfully."),
    preprocess: xformBenchmarkBeforeSave
  },
  updateMember: {
    successMessage: i18n.t("Benchmark updated successfully."),
    preprocess: xformBenchmarkBeforeSave
  },
  deleteMember: {
    successMessage: i18n.t("Benchmark deleted successfully.")
  }
};

export default config;
