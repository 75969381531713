import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, IconCategoryValue, PageWrapper, MessagesModule, Header, Section, ListModule, ExplainerText } from "reactifi";
import { Capitalize } from 'reactifi/dist/es/utils/StringUtil';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import i18n from "lib/i18n";

import DownloadModal from './DownloadModal';
import DatafiExportForm from './DatafiExportForm';
import * as DisplayFiltersComponents from './displayFilters';

export default class DatafiExports extends React.Component {
  static propTypes = {
    currentEntity: PropTypes.object,
    data_exports: PropTypes.array,
    data_exportsMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    dataTypes: PropTypes.arrayOf(PropTypes.shape({
      type: PropTypes.string,
      label: PropTypes.string
    })),
    deleteDataExport: PropTypes.func.isRequired,
    isLoadingDataExports: PropTypes.bool,
    loadDataExports: PropTypes.func.isRequired,
    selectDataExport: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {};
  }

  downloadAction = (e, dataExport) => {
    this.setState({
      downloadDataExport: dataExport
    });
  }

  closeDownloadModal = () => {
    this.setState({
      downloadDataExport: undefined
    });
  }

  deleteAction = (e, dataExport) => {
    this.setState({
      deleteDataExport: dataExport
    });
  }

  deleteDataExport = () => {
    this.props.deleteDataExport(this.state.deleteDataExport);
    this.closeDeleteModal();
  }

  closeDeleteModal = () => {
    this.setState({
      deleteDataExport: undefined
    });
  }

  copyAction = (e, dataExport) => {
    this.setState({ wizardStep: 1, isCopying: true }, () => this.afterCopy(dataExport));
  }

  afterCopy = async (dataExport) => {
    const { selectDataExport } = this.props
    await selectDataExport('add');
    const { currentEntity } = this.props;
    Object.assign(currentEntity, {
      data_type: dataExport.data_type,
      name: dataExport.name,
      filters: { ...dataExport.filters }
    });
    this.setState({ isCopying: undefined });
  }

  newDataExport = () => {
    this.setState({ wizardStep: undefined }, () => this.afterNew());
  }

  afterNew = async () => {
    const { selectDataExport } = this.props
    await selectDataExport('add');
  }

  getDataTypeLabel = (type) => {
    const dataType = this.props.dataTypes.find(dataType => dataType.type === type);
    return (dataType || {}).label;
  }

  renderDeleteModal = () => (
    <ConfirmationModal
      title={i18n.t("Delete Bulk Data Download")}
      show={!!this.state.deleteDataExport}
      onConfirm={this.deleteDataExport}
      onCancel={this.closeDeleteModal}
    >
      {i18n.t("Are you sure you want to delete this bulk data download?")}
    </ConfirmationModal>
  )

  get fields() {
    return [
      { name: "name", title: i18n.t("File Name"), width: "30%" },
      {
        name: "data_type",
        title: i18n.t("Data Type"),
        width: "20%",
        formatter: (cell) => this.getDataTypeLabel(cell)
      },
      {
        name: "filters",
        title: i18n.t("Attributes"),
        width: "50%",
        formatter: this.attributesFormatter
      },
      {
        name: "status",
        title: i18n.t("Status"),
        width: 120,
        formatter: this.statusFormatter
      }
    ];
  }

  get actions() {
    return [
      { icon: 'down-to-line', title: '', tooltip: 'Download', actionType: 'callToAction', action: this.downloadAction, disabled: row => row.status !== "completed" },
      { icon: 'trash', title: '', tooltip: 'Delete', actionType: 'callToAction', action: this.deleteAction, disabled: row => ["pending", "processing"].includes(row.status) },
      { icon: 'copy', title: '', tooltip: 'Copy', actionType: 'callToAction', action: this.copyAction, filter: row => row.status === 'expired' }
    ];
  }

  attributesFormatter = (cell, row) => {
    if (!row.data_type) {
      return null;
    }
    const dataType = row.data_type.replace('Datafi::Exports::', '');
    const DisplayFiltersComponent = DisplayFiltersComponents[dataType];
    if (!DisplayFiltersComponent) {
      return null;
    }
    return <DisplayFiltersComponent dataExport={row} />;
  }

  statusFormatter = (cell, row) => {
    switch (cell) {
      case 'pending':
      case 'generating':
        return i18n.t('Processing');
      case 'error':
      case 'completed_without_download':
        return <OverlayTrigger placement="top" overlay={(
          <Tooltip id={`error_${row.id}`}>
            {cell === 'completed_without_download' ?
              i18n.t('Not enough data to generate report') :
              i18n.t('Error: Failed to generate report')}
          </Tooltip>)}
        >
          <IconCategoryValue icon="triangle-exclamation" value={i18n.t('Error')} color="red" />
        </OverlayTrigger>;
      case 'completed':
        return i18n.t('Complete');
      case 'expired':
        return i18n.t('Expired');
      default:
        return Capitalize(cell);
    }
  }

  renderListView = () => {
    const {
      data_exports, data_exportsMeta, isLoadingDataExports, loadDataExports
    } = this.props;

    return (
      <PageWrapper>
        <MessagesModule {...this.props} />
        <Header
          title={i18n.t('Data Downloads')}
          button={{
            "data-action": "generate-new-download",
            label: i18n.t("Generate New Download"),
            action: this.newDataExport
          }}
        />
        <Section>
          <ExplainerText>
            {i18n.t('Data Downloads allow you to view large data sets of survey responses in .csv format. Create a data set by clicking the “Generate New Download” button, and then download the file when it is available in the table below. Each download will be available for 24 hours. For more info, please visit our')}
            <a href="https://help.everfi.com/s/article/Data-Exports" target="_blank" rel="noopener noreferrer">{i18n.t('help site')}</a>.
          </ExplainerText>
        </Section>
        <Section type="list">
          <ListModule
            actions={this.actions}
            collection={data_exports}
            collectionMeta={data_exportsMeta}
            fields={this.fields}
            isLoadingData={isLoadingDataExports}
            loadCollection={loadDataExports}
            options={{ noDataText: i18n.t("You don't have any bulk data downloads available yet. Create one by clicking \"Generate New Download\" above.") }}
          />
        </Section>
      </PageWrapper>
    );
  }

  render() {
    const { currentEntity } = this.props;
    const { downloadDataExport, isCopying } = this.state;

    return (
      <React.Fragment>
        {this.renderListView()}
        <DownloadModal dataExport={downloadDataExport}
          onClose={this.closeDownloadModal} />
        {this.renderDeleteModal()}
        {!isCopying && <DatafiExportForm
          {...this.props}
          wizardStep={this.state.wizardStep}
          dataExport={currentEntity}
        />}
      </React.Fragment>
    );
  }
}
