import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup, FloatingLabelFormField, FormModule, MultiRowFieldGroup, useContainerProps } from 'reactifi';
import i18n from 'lib/i18n';

const BenchmarkForm = props => {
  props = useContainerProps(props);
  const { selectMember } = props;

  return (
    <FormModule
      onCancel={() => selectMember(null)}
      deepMerge={true}
    >
      <FloatingLabelFormField
        name="name"
        caption={i18n.t('Attribute Name')}
        required={true}
      />
      <MultiRowFieldGroup
        name="benchmark_groups"
        min={1}
        addButton={{ label: i18n.t('Add a Value') }}>
        <FieldGroup>
          <FloatingLabelFormField
            name="name"
            caption={i18n.t('Value Name')}
            placeholder={i18n.t('Enter value name')}
            required={true}
          />
        </FieldGroup>
      </MultiRowFieldGroup>
    </FormModule>
  );
}

BenchmarkForm.propTypes = {
  selectMember: PropTypes.func
};

export default BenchmarkForm;
