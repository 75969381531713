import React from 'react';
import { App } from 'reactifi';
import { TagManagersContainer, TagManagerContainer } from './containers';

const index = ({ organization_id, tag_manager_banner_enabled }, _railsContext) => {
  return (
    <App {..._railsContext}>
      <TagManagersContainer path="/" organizationId={organization_id} tagManagerBannerEnabled={!!tag_manager_banner_enabled} />
      <TagManagerContainer path="/:id" />
    </App>
  );
}

export default index;
