import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { buildGenericProps, Page, ConfirmationModal, FAIcon, Button } from "reactifi";
import * as dataIntegrationActions from '../actions/dataIntegrationActions';
import i18n from "lib/i18n";

import DataIntegrationItem from '../components/DataIntegrationItem';
import DataIntegrationEmpty from '../components/DataIntegrationEmpty';
import DataIntegrationForm from '../components/DataIntegrationForm';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "applications", {});

  props.currentOrganizationId = ownProps.params.id || ownProps.route.organization_id;
  if (props.currentEntity) {
    props.currentApplication = props.currentEntity;
    if (!props.currentEntity.id) {
      props.currentApplication.redirect_uri = "https://www.everfi.com";
    }
  }

  return props;
}

class DataIntegrationContainer extends React.Component {
  static propTypes = {
    applications: PropTypes.array,
    currentApplication: PropTypes.object,
    currentEntity: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(dataIntegrationActions, this.props.dispatch);
    this.state = {
      showModal: false,
      showRegenerateModal: false,
      loaded: false
    };
  }

  async componentDidMount() {
    await this.actions.loadApplications();
    this.setState({ loaded: true });
  }

  showModal = async () => {
    await this.actions.selectApplication("add");
  }

  closeModal = async () => {
    await this.actions.selectApplication(null);
  }

  showForm = async (id) => {
    await this.actions.selectApplication(id)
  }

  showRegenerateModal = (app) => {
    this.setState({ showRegenerateModal: true,  appToRegenerate: app });
  }

  confirmRegenerateSecret = async () => {
    await this.actions.regenerateSecret(this.state.appToRegenerate);
    this.cancelRegenerateSecret();
  }

  cancelRegenerateSecret = () => {
    this.setState({ showRegenerateModal: false, appToRegenerate: null })
  }

  renderRegisterAppButton() {
    return (
      <Button
        style="primary"
        className="register-app-btn"
        onClick={this.showModal}
        icon="plus"
        data-action="register-app"
        label={i18n.t('Register App')}
      />
    )
  }

  render() {
    const { dispatch, applications, currentEntity, currentApplication, errorMessage, successMessage } = this.props;
    return (
      <Page
        title={i18n.t('Data Integration: Registered Applications')}
        pageType="parent"
        contentType="cards"
        actionButton={this.renderRegisterAppButton()}
        successMessage={successMessage}
        errorMessage={errorMessage}
        usePageWrapper={true}
      >
        {this.state.showRegenerateModal &&
          <ConfirmationModal
            title={i18n.t("Regenerate client secret for %s?", { postProcess: 'sprintf', sprintf: [this.state.appToRegenerate.name] })}
            id="regenerate-secret-modal"
            onConfirm={this.confirmRegenerateSecret}
            onCancel={this.cancelRegenerateSecret}
            show={this.state.showRegenerateModal} >
            <div>{i18n.t("Are you sure you want to regenerate the client secret for this API?")}</div>
            <div className="modal-text"><FAIcon icon="triangle-exclamation" color="yellow" size="medium" additionalClasses="m-r-5"/>{i18n.t(" Warning: Regenerating this value will break any existing API integrations that use this client secret until those integrations get updated with the new value.")}</div>
          </ConfirmationModal>
        }
        {this.state.loaded && applications.length > 0 && applications.map((app) => (
          <DataIntegrationItem
            dispatch={dispatch}
            application={app}
            key={app.client_id}
            showForm={() => this.showForm(app.id)}
            regenerateSecret={this.showRegenerateModal}
            deleteApplication={this.actions.deleteApplication}
            displaySuccessMessage={this.actions.displaySuccessMessage}
            displayErrorMessage={this.actions.displayErrorMessage}
          />
        ))}
        {this.state.loaded && applications.length <= 0 && (
          <DataIntegrationEmpty
            actionButton={this.showModal}
          />
        )}
        {currentEntity && (
          <DataIntegrationForm
            errorMessage={errorMessage}
            successMessage={successMessage}
            closeModal={this.closeModal}
            currentApplication={currentApplication}
            selectApplication={this.actions.selectApplication}
            createApplication={this.actions.createApplication}
            updateApplication={this.actions.updateApplication}
          />
        )}
      </Page>
    );
  }
}

export default connect(mapStateToProps)(DataIntegrationContainer);
