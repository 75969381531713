import PropTypes from 'prop-types';
import React from 'react';
import { Button } from 'reactifi';
import i18n from 'lib/i18n';

const ManageBBIdProfile = ({ bbIdEnabled, isManager }) => {
  if (!isManager || !bbIdEnabled) return null;

  return (
    <div className="manage-bbid-profile m-t-20 fg-float">
      <p>{i18n.t("Your email address and password are managed through Blackbaud's Indentity Management System (BBId). To make changes to your login credentials, visit your BBId profile.")}</p>
      <Button
        label={i18n.t('Manage BBId Profile')}
        href="https://app.blackbaud.com/user"
        target="_blank"
        rel="noopener noreferrer"
        style="secondary"
        data-action="goto-manage-bbid"
      />
    </div>
  );  
}

ManageBBIdProfile.propTypes = {
  bbIdEnabled: PropTypes.bool,
  isManager: PropTypes.bool
};

export default ManageBBIdProfile;
