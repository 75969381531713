import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';
import {
  TableauChart,
  TableauCloudChart,
  PageWrapper,
  ChartWrapper,
  MessagesModule,
  Header,
  Section
} from 'reactifi';

const ChartsPage = (props) => {
  const { context } = props;

  const contextConfig = useMemo(() => {
    if (!context) return null;

    switch (context.type) {
      case 'Datafi::TableauChartContext':
        return {
          ChartComponent: TableauChart,
          chartProps: {
            chartSourceUrl: context.iframe_src,
            tableau: window.tableau
          }
        };
      case 'Datafi::TableauCloudChartContext':
        return {
          ChartComponent: TableauCloudChart,
          chartProps: {
            tableau: window.tableauV3
          }
        };
      default:
        throw new Error('Invalid chart type provided');
    }
  }, [context]);

  const goBackAndReload = useCallback(() => {
    // Go back and full reload the reports page to get new tableau ticket
    props.router.push('/');
    window.location.reload();
  }, [props.router]);

  if (!contextConfig) {
    goBackAndReload();
    return null;
  }

  const { ChartComponent, chartProps } = contextConfig;

  return (
    <PageWrapper>
      <MessagesModule {...props} />
      <Header title={context.chart.name} back={goBackAndReload} />
      <Section type="chart">
        <ChartWrapper>
          <ChartComponent
            {...props}
            {...chartProps}
            chart={context.chart}
            showReportToolbar={context.chart?.show_toolbar}
            onError={props.displayErrorMessage}
          />
        </ChartWrapper>
      </Section>
    </PageWrapper>
  );
};

ChartsPage.propTypes = {
  clearMessages: PropTypes.func,
  context: PropTypes.shape({
    type: PropTypes.string,
    iframe_src: PropTypes.string.isRequired,
    chart: PropTypes.shape({
      name: PropTypes.string,
      show_toolbar: PropTypes.bool
    })
  }),
  currentEntity: PropTypes.object,
  displayErrorMessage: PropTypes.func,
  displaySuccessMessage: PropTypes.func,
  errorMessage: PropTypes.string,
  router: PropTypes.shape({
    push: PropTypes.func
  })
};

export default ChartsPage;
