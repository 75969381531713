import React from 'react';
import { createContainer } from 'reactifi';
import ImpactReports from '../components/ImpactReports';
import config from '../configs/impactReportsConfig';

const Container = createContainer(config);

const ImpactReportsContainer = props => {
  return (
    <Container {...props}>
      <ImpactReports />
    </Container>
  );
};

export default ImpactReportsContainer;
