import { readEntity, clearApiState } from 'reactifi';
import { convertCustomFilters } from 'externals/components/functions/convertCustomFilters';

export { clearMessages, displaySuccessMessage, setActiveState } from 'reactifi';

export function findUsers(filters, page, sort, searchValue) {
  if (!sort) {
    sort = { value: 'first_name', direction: 'asc' };
  }
  const query = {
    name: {
      term: searchValue,
      fields: {
        full_name: 'word_start',
        email: 'word_start'
      }
    }
  };
  const apiFilters = Object.assign({}, filters);
  apiFilters.custom = convertCustomFilters(filters.custom);
  return readEntity('users', 'organization,location', apiFilters, sort && [sort], page, searchValue && query);
}

export function loadUsers(page, sort) {
  if (!sort) {
    sort = { value: 'first_name', direction: 'asc' };
  }
  return readEntity('users', 'organization,location', '', sort && [sort], page)
}

export function loadRuleSets() {
  return readEntity('rule_sets');
}

export function clearUsers() {
  return async dispatch => {
    dispatch(clearApiState('users'));
  }
}
