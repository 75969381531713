import { clearApiState, ApiUtil, createEntity, updateEntity, clearAndRead, displaySuccessMessage, displayError, displayErrorMessage, readEntity, selectEntity, readLocal } from 'reactifi';
export { clearMessages } from 'reactifi';
import { createEntity as createEntityApi } from 'redux-json-api';
import i18n from 'lib/i18n';

export function register(registrationSets){
  return async function(dispatch, getState){
    let path = createEntity(registrationSets, null, i18n.t("User account created successfully!"));
    return await path(dispatch, getState);
  }
}

export function loadUserFormData(ruleSet, orgId, roleSlug) {
  return async function(dispatch, getState){
    let url = `organizations/${orgId}/registrations/new.json?rule_set_id=${ruleSet.id}&role=${roleSlug}`;
    await clearAndRead(dispatch, getState, 'registration_sets', url);
    // window.location.href = `${window.location.href.replace(/\/$/, "")}/add`;
  }
}

export function updateUser(registrationSets) {
  return async function(dispatch, getState) {
    let path = updateEntity(registrationSets, null, i18n.t("Profile updated successfully!"), true);
    return await path(dispatch, getState);
  }
}

export function loadLocation(id, organizationId) {
  return async dispatch => {
    const url = `/api/data/locations/${id}.json?filter[organization_id]=${organizationId}`;
    const data = await ApiUtil.loadApiData(url, false);
    dispatch(readLocal(data));
  }
}

export function loadRuleSets(){
  return readEntity('rule_sets');
}

export function loadUsers() {
  return async function(dispatch, getState){
    let url = `users`;
    await clearAndRead(dispatch, getState, 'users', url);
  }
}

export function loadCategories(filters) {
  return readEntity('categories', ['category_labels'], filters);
}

export function readCategoryLabelUsers(filters = {}) {
  return readEntity('category_label_users', 'category_label', filters);
}

export function selectCategoryLabelUser() {
  return async (dispatch) => {
    dispatch(selectEntity('add', 'category_label_users'));
  }
}

export function selectUser(userId, orgId){
  return async function(dispatch, getState){
    let url = `organizations/${orgId}/registrations/${userId}/edit?include=location`;
    await clearAndRead(dispatch, getState, 'registration_sets', url);
  }
}

export function selectUserDetails(userId, orgId){
  return async function(dispatch, getState){
    let url = `organizations/${orgId}/registrations/${userId}/details?include=location`;
    await clearAndRead(dispatch, getState, 'registration_sets', url);
  }
}

export function selectCurrentUser(userId) {
  return selectEntity(userId, "users", "location");
}

export function resetPassword(user_id){
  return async function(dispatch, getState){
    let passwordReset = {
      type: 'password_resets',
      attributes: {
        user_id
      }
    };
    try {
      await dispatch(createEntityApi(passwordReset));
      dispatch(displaySuccessMessage('Password reset instructions will be emailed to this user'));
    } catch (e) {
      dispatch(displayErrorMessage('Password reset failed'));
    }
  }
}

export function loadSuppressionListEmails(notifiUrl, token) {
  return async dispatch => {
    try {
      dispatch(clearApiState('suppression_list_emails'));
      const endpoint = `${notifiUrl}/api/v1/suppression_list_emails.json?include=suppression_list`;
      const data = await ApiUtil.loadExternalData(endpoint, token, false);
      data.endpoint = endpoint;

      dispatch({ type: 'API_READ', payload: data });
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

export function deleteMeFromSupressionList(notifiUrl, token, id) {
  return async dispatch => {
    try {
      const endpoint = `${notifiUrl}/api/v1/suppression_list_emails/${id}`;
      ApiUtil.requestExternalData(endpoint, token, false, 'DELETE');
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

