import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { buildGenericProps, JsonApiDecorator } from 'reactifi';
import { orgUsersActionCreators } from '../actions';
import OrgUserAssignment from '../components/OrgUserAssignment';

const mapStateToProps = (state, ownProps) => {
  let props = buildGenericProps(state, 'user_assignments', ['assignment', 'progresses']);
  const apiStore = new JsonApiDecorator(state.api);

  props = Object.assign(props, {
    contentServiceUrl: ownProps.route.contentServiceUrl,
    jwt: ownProps.route.jwt,
    organizationId: ownProps.params.id || ownProps.route.organization_id,
    progressId: ownProps.params.progress_id,
    userAssignmentId: ownProps.params.user_assignment_id,
    userId: ownProps.params.user_id
  });

  // Map contents coming from assignment.contents include
  props.userAssignments = props.user_assignments.map(userAssignment => {
    if (userAssignment.assignment) {
      userAssignment.assignment.contents = apiStore.contents 
        ? apiStore.contents.where(content => userAssignment.content_ids.includes(content.id)) 
        : [];
    }

    return userAssignment;
  })

  props.currentUser = apiStore.users ? apiStore.users.find(props.userId) : {};
  props.currentUserAssignment = props.user_assignments.find(ua => ua.id === props.userAssignmentId);
  props.currentTrainingPeriod = apiStore.training_periods ? apiStore.training_periods.first() : {};
  return props;
}

class OrganizationUserAssignmentContainer extends Component {
  static propTypes = {
    contentServiceUrl: PropTypes.string.isRequired,
    currentUserAssignment: PropTypes.object.isRequired,
    dispatch: PropTypes.func,
    jwt: PropTypes.string.isRequired,
    progressId: PropTypes.string.isRequired,
    user_assignments: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(orgUsersActionCreators, this.props.dispatch);
  }

  componentDidMount() {
    this.actions.selectCurrentUser(this.props.userId);
    this.actions.loadUserAssignmentsByProgressId(this.props.userId, this.props.progressId, this.props.contentServiceUrl, this.props.jwt);
  }

  componentWillUnmount() {
    this.actions.clearUserAssignments();
    this.actions.clearUserProgress();
  }

  unassignOrReassignUserFromAssignment = async (userAssignmentId, action) => {
    const { contentServiceUrl, jwt, progressId, userId } = this.props;

    await this.actions.unassignOrReassignUserFromAssignment(userAssignmentId, action, contentServiceUrl, jwt);
    this.actions.loadUserAssignmentsByProgressId(userId, progressId, contentServiceUrl, jwt);
  }

  render() {
    if (!this.props.currentUserAssignment) return null;

    return (
      <OrgUserAssignment 
        {...this.props} 
        {...this.actions} 
        userAssignments={this.props.user_assignments} 
        onAssignmentUpdate={this.unassignOrReassignUserFromAssignment}
      />
    );
  }
}

export default connect(mapStateToProps)(OrganizationUserAssignmentContainer);
