import i18n from "lib/i18n";

export const BUSINESS_LINE_MAP = Object.freeze({
  'corporate-compliance': i18n.t('Conduct and Culture'),
  'higher-education': i18n.t('Higher Education'),
  'faculty-staff': i18n.t('Faculty/Staff'),
  'at-work': i18n.t('Financial Education'),
  'event-tracking': i18n.t('Event Management'),
  'k12': i18n.t('K12 School or District')
});
