import React from "react";
import { createContainer } from "reactifi";
import Interstitial from "../components/Interstitial";

const Container = createContainer({});

const InterstitialContainer = props => {
  return (
    <Container {...props}>
      <Interstitial />
    </Container>
  );
}

export default InterstitialContainer;
