import React from 'react';
import { createContainer } from 'reactifi';
import Benchmark from '../components/Benchmark';
import config from '../configs/benchmarksContainerConfig';

const Container = createContainer(config);

const BenchmarkContainer = props => {
  return (
    <Container {...props}>
      <Benchmark />
    </Container>
  );
};

export default BenchmarkContainer;
