import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import { isEqual, debounce } from 'lodash';
import { Page, buildGenericProps, NoResultsCard, JsonApiDecorator, SelectFilter, FilterBar } from 'reactifi';
import * as administratorActionCreators from '../actions/administratorActionCreators';
import AdministratorTable from '../components/AdministratorTableComponent';
import AdministratorForm from '../components/AdministratorForm';
import DeleteAdministratorModal from '../../common/Components/DeleteAdministratorConfirmationDialog';
import { NoResultButtons } from '../../common/Components/NoResultsButtons';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'administrators', ['administrator_groups']);
  if (props.currentEntity) {
    props.currentAdministrator = props.currentEntity;
  }

  props = Object.assign(props, {
    successMessage: state.api.successMessage,
    errorMessage: state.api.errorMessage
  });

  let apiStore = new JsonApiDecorator(state.api);

  const { administrator_groups } = apiStore;

  props.administratorGroups = administrator_groups ? administrator_groups.all() : [];
  props.administratorMeta = state.api.administrators ? state.api.administrators.meta : null;

  props.viewer = ownProps.route.viewer;
  return props;
}

export class AdministratorsContainer extends React.Component {
  static propTypes = {
    administratorGroups: PropTypes.array,
    administrators: PropTypes.array,
    administratorMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    currentAdministrator: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isLoadingAdministrators: PropTypes.bool,
    successMessage: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(administratorActionCreators, this.props.dispatch);
    this.noResultsButtons = [NoResultButtons(this.actions.selectAdministrator, i18n.t('Create an Administrator'))];
    this.filterAdministrators = debounce(this.filterAdministrators, 350, { leading: false, trailing: true });

    this.state = {
      filtered: false,
      activePage: 1,
      sortName: "first_name",
      sortOrder: "asc",
      showModal: false,
      selectedAdministrator: null
    }
  }

  componentDidMount() {
    this.filterAdministrators();
    this.actions.readAdministratorGroups();
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.filterAdministrators);
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sort: {
        value: sortName,
        direction: sortOrder
      },
      sortName,
      sortOrder,
      activePage: 1
    }, this.filterAdministrators);
  }

  handleSearchChange = ({ searchValue }) => {
    if (searchValue === this.state.searchValue) return;
    this.setState({ keyword: searchValue, activePage: 1 }, this.filterAdministrators);
  }

  filterAdministrators = () => {
    const { filters, keyword, sort, activePage } = this.state;
    this.actions.readAdministrators('', Object.assign({}, filters, { keyword }), sort, { number: activePage });
    this.setState({ filtered: true });
  }

  createAdministrator = async (administrator, addAnother) => {
    var entity = await this.actions.createAdministrator(administrator, addAnother);
    if (entity) {
      setTimeout(this.filterAdministrators, 1000);
    }
  }

  onDeleteClickAdministrator = selectedAdministrator => this.setState({ selectedAdministrator, showModal: true });

  onEditClickAdministrator = administrator => this.actions.selectAdministrator(administrator.id);

  deleteAdministrator = async () => {
    await this.actions.deleteAdministrator(this.state.selectedAdministrator);
    this.actions.readAdministrators();
    this.onCancelDeleteAdministrator();
  };

  onCancelDeleteAdministrator = () => this.setState({
    selectedAdministrator: null,
    showModal: false
  });

  onFilterChange = (selectedFilters) => {
    let newFilters = {};
    Object.keys(selectedFilters).forEach((filter) => {
      if (filter === 'administrator_group_ids') {
        newFilters[filter] = selectedFilters[filter].map(group => group.id);
      }
    });
    if (!isEqual(newFilters, this.state.filters)) {
      this.setState({ filters: newFilters, activePage: 1 }, this.filterAdministrators);
    }
  }

  pageToolbar = () => {
    const filtersConfigObj = {
      filtersConfig: [{
        dataObjectType: 'administrator-group',
        dataAction: 'filter-administrator-group',
        name: 'administrator_group_ids',
        label: i18n.t('Administrator Groups'),
        options: this.props.administratorGroups,
        isMulti: true,
        type: SelectFilter,
        valueKey: 'id',
        labelKey: 'name'
      }]
    };
    return (
      <FilterBar
        filterConfig={filtersConfigObj.filtersConfig}
        onSelectFilters={this.onFilterChange}
        searchBox={
          {
            isOnlySearch: false,
            fullWidth: true,
            placeholder: i18n.t('Search by First Name, Last Name, Email'),
            searchAction: this.handleSearchChange,
            value: this.state.keyword,
            valueChangeDelay: 1500
          }
        }
      />
    );
  }

  render() {
    let actionButton = {
      label: i18n.t("New Administrator"),
      'data-action': 'new-administrator',
      action: () => this.actions.selectAdministrator('add')
    };

    return (
      <Page title={i18n.t('Administrators')}
        pageType="parent"
        contentType="list"
        actionButton={actionButton}
        successMessage={this.props.successMessage}
        errorMessage={this.props.currentAdministrator ? null : this.props.errorMessage}
        clearMessages={this.actions.clearMessages}
        usePageWrapper={true}
      >
        <DeleteAdministratorModal
          onConfirm={this.deleteAdministrator}
          onCancel={this.onCancelDeleteAdministrator}
          show={this.state.showModal}
        />
        <div className="page-panel">
          {this.pageToolbar()}
          {this.props.currentAdministrator &&
            <AdministratorForm
              administratorGroups={this.props.administratorGroups}
              clearMessages={this.actions.clearMessages}
              createAdministrator={this.createAdministrator}
              currentAdministrator={this.props.currentAdministrator}
              errorMessage={this.props.errorMessage}
              selectAdministrator={this.actions.selectAdministrator}
              updateAdministrator={this.actions.updateAdministrator} />}
          {this.props.administrators ?
            <AdministratorTable
              options={{
                onSortChange: this.onSortChange,
                sortName: this.state.sortName,
                sortOrder: this.state.sortOrder,
                ...this.state.sort
              }}
              fieldsProps={{
                onEdit: this.onEditClickAdministrator,
                onDelete: this.onDeleteClickAdministrator
              }}
              onPageChange={this.onPageChange}
              isLoadingData={this.props.isLoadingAdministrators}
              totalCount={this.props.administratorMeta ? this.props.administratorMeta.total_count : 0}
              activePage={this.state.activePage}
              data={this.props.administrators}
            />
            :
            <NoResultsCard message={this.noResultsMessage} buttons={this.noResultsButtons} />
          }
        </div>
      </Page>
    );
  }
}

export default connect(mapStateToProps)(AdministratorsContainer);
