import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import i18n from 'lib/i18n';
import merge from 'lodash/merge';

import { orgBrandingActions } from '../actions';

import {
  Form,
  FieldGroup,
  FormControl,
  FormField,
  FloatingLabelFormField,
  buildGenericProps,
  JsonApiDecorator,
  ColorUtil,
  StringUtil
} from 'reactifi';
import BrandingButtonsWell from '../components/branding/BrandingButtonsWell';
import BrandingNavigationWell from '../components/branding/BrandingNavigationWell';
import BrandingLinkWell from '../components/branding/BrandingLinkWell';
import BrandingHeaderWell from '../components/branding/BrandingHeaderWell';
import BrandingColorBoxPreview from '../components/branding/BrandingColorBoxPreviewComponent';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, 'organization_brand_style_guides');
  const stateApiDecorator = new JsonApiDecorator(state.api);

  props.newGuide = () => stateApiDecorator.newObject('organization_brand_style_guides');

  if(stateApiDecorator.brand_style_guide_configs) {
    props.defaultStyleConfig = Object.assign({}, stateApiDecorator.brand_style_guide_configs.first());
  }

  props.isCreating = false;
  if(ownProps.route.path === 'add' && stateApiDecorator.brand_style_guide_configs) {
    let defaultGuide = stateApiDecorator.brand_style_guide_configs.first();
    props.currentStyleGuide = merge(props.newGuide(), defaultGuide);
    props.currentStyleGuide.type = 'organization_brand_style_guides';
    props.currentStyleGuide.default = false;
    props.isCreating = true;
  } else if (ownProps.routeParams.guide_id && stateApiDecorator.organization_brand_style_guides && stateApiDecorator.brand_style_guide_configs) {
    let defaultGuide = stateApiDecorator.brand_style_guide_configs.first();
    let styleGuide = stateApiDecorator.organization_brand_style_guides.find(ownProps.routeParams.guide_id);
    props.currentStyleGuide = merge(props.newGuide(), defaultGuide, styleGuide);
  }

  props.orgMeta = state.api.organizations ? state.api.organizations.meta : null;
  return props;
}

class OrganizationStyleGuideContainer extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    currentStyleGuide: PropTypes.object,
    defaultStyleConfig: PropTypes.object,
    newGuide: PropTypes.func,
    isCreating: PropTypes.bool,
    dispatch: PropTypes.func,
    params: PropTypes.object,
    router: PropTypes.object
  }

  constructor(props){
    super(props);
    this.state = {
      currentStyleGuide: props.currentStyleGuide || null
    }

    this.actions = bindActionCreators(
      { ...orgBrandingActions },
      this.props.dispatch
    );
  }

  static getDerivedStateFromProps(props, state) {
    if(!state.currentStyleGuide && props.currentStyleGuide) {
      return {
        currentStyleGuide: props.currentStyleGuide
      }
    }
    return null
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.currentStyleGuide !== prevState.currentStyleGuide) {
      this.setPreviewStyles();
    }
  }

  componentDidMount() {
    this.actions.loadDefaultStyleGuide();
    this.setPreviewStyles();
  }

  onSave = async () => {
    let guideToSave = this.state.currentStyleGuide;
    if(this.props.isCreating) {
      guideToSave.organization_id = this.props.params.id;
      await this.actions.createStyleGuide(guideToSave, this.onCancel);
    } else {
      await this.actions.updateStyleGuide(guideToSave, this.onCancel);
    }
  }

  onCancel = () => {
    if(this.props.router){
      this.props.router.goBack();
    }
  }

  onColorChange = (color, element, property) => {
    let { currentStyleGuide } = this.state;
    let { defaultStyleConfig, newGuide } = this.props;
    if(color) {
      if(property.includes("color")) {
        if(ColorUtil.isValidHexString(color)){
          this.checkContrastRatio(ColorUtil.addHashToColorIfNeeded(color), element, property);
          currentStyleGuide.properties[element][property].value = ColorUtil.addHashToColorIfNeeded(color);
          this.setState({ currentStyleGuide: Object.assign(newGuide(), currentStyleGuide), [`invalidHex_${element}`]: false })
        } else {
          this.setState({ [`invalidHex_${element}`]: true });
        }
      } else {
        this.onPropertyChange(color, element, property)
      }
    } else {
      currentStyleGuide.properties[element][property].value = defaultStyleConfig.properties[element][property].value;
      this.setState({ currentStyleGuide: Object.assign(newGuide(), currentStyleGuide) })
    }
    this.setPreviewStyles();
  }

  onPropertyChange = (value, element, property) => {
    let { currentStyleGuide } = this.state;
    let { defaultStyleConfig, newGuide } = this.props;
    if(value) {
      currentStyleGuide.properties[element][property].value = value.indexOf("px") >= 0 ? value : value.concat("px");
    } else {
      currentStyleGuide.properties[element][property].value = defaultStyleConfig.properties[element][property].value;
    }
    this.setState({ currentStyleGuide: Object.assign(newGuide(), currentStyleGuide) });
  }

  contrastValidations = (color, element) => {
    let { currentStyleGuide } = this.state;
    let compliantBackgroundColor = true;
    let compliantBackgroundHoverColor = true;
    return {
      font_color: () => {
        if(currentStyleGuide.properties[element].background_color) {
          compliantBackgroundColor = ColorUtil.isContrastRatioAACompliant(color, currentStyleGuide.properties[element].background_color.value);
        }
        if(currentStyleGuide.properties[element].background_color_on_hover) {
          compliantBackgroundHoverColor = ColorUtil.isContrastRatioAACompliant(color, currentStyleGuide.properties[element].background_color_on_hover.value);
        }
        return { compliantBackgroundColor, compliantBackgroundHoverColor };
      },
      background_color: () => {
        compliantBackgroundColor = ColorUtil.isContrastRatioAACompliant(color, currentStyleGuide.properties[element].font_color.value);
        if(currentStyleGuide.properties[element].background_color_on_hover) {
          compliantBackgroundHoverColor = ColorUtil.isContrastRatioAACompliant(currentStyleGuide.properties[element].background_color_on_hover.value, currentStyleGuide.properties[element].font_color.value);
        }
        return { compliantBackgroundColor, compliantBackgroundHoverColor };
      },
      background_color_on_hover: () => {
        compliantBackgroundHoverColor = ColorUtil.isContrastRatioAACompliant(color, currentStyleGuide.properties[element].font_color_on_hover.value);
        compliantBackgroundColor = ColorUtil.isContrastRatioAACompliant(currentStyleGuide.properties[element].background_color.value, currentStyleGuide.properties[element].font_color.value);
        return { compliantBackgroundColor, compliantBackgroundHoverColor };
      },
      font_color_on_hover: () => {
        if(element.includes('button')) {
          compliantBackgroundHoverColor = ColorUtil.isContrastRatioAACompliant(color, currentStyleGuide.properties[element].background_color_on_hover.value)
        }
        return { compliantBackgroundColor, compliantBackgroundHoverColor };
      },
      border_color: () => {
        return { compliantBackgroundColor, compliantBackgroundHoverColor };
      },
      border_color_on_hover: () => {
        return { compliantBackgroundColor, compliantBackgroundHoverColor };
      }
    }
  }

  checkContrastRatio = (color, element, property) => {
    let message;
    let propertyType = 'Font Color';
    if(property.includes('hover')) {
      propertyType = 'Font Color on Hover';
    }
    const { compliantBackgroundColor, compliantBackgroundHoverColor } = this.contrastValidations(color, element)[property]();

    if(!compliantBackgroundColor || !compliantBackgroundHoverColor) {
      let nonCompliantProperties;
      if(!compliantBackgroundColor && !compliantBackgroundHoverColor) {
        nonCompliantProperties = 'Background and Background on Hover Colors '
      } else if (!compliantBackgroundColor) {
        nonCompliantProperties = 'Background Color ';
      } else if (!compliantBackgroundHoverColor) {
        nonCompliantProperties = 'Background on Hover Color '
      }
      message = i18n.t(`The selected ${propertyType} doesn't meet a sufficient contrast ratio with the selected ${nonCompliantProperties} as per WCAG 2.0 standards. We recommend choosing another color.`);
      this.setState({ [`${element}_message`]: message });
    } else {
      this.setState({ [`${element}_message`]: null })
    }
  }

  getProperties = (element_type) => {
    let { currentStyleGuide } = this.state;
    let propertyCollection = currentStyleGuide.properties[element_type] && Object.keys(currentStyleGuide.properties[element_type]).map((property) => {
      return (
        <FieldGroup className="col-6 branding-property">
          <div className="branding-element-property">{i18n.t(StringUtil.UnderscoresToTitleCase(property))}</div>
          {property.split("_").includes("color") &&
            <BrandingColorBoxPreview color={currentStyleGuide.properties[element_type][property].value}
              className="pull-left" />}
          <FormField className="hex-field"
            name={`properties[${element_type}][${property}][value]`}>
            <FormControl
              placeholder={currentStyleGuide.properties[element_type].value}
              onChange={value => this.onColorChange(value, element_type, property)}/>
          </FormField>
        </FieldGroup>
      )
    })
    return propertyCollection;
  }

  getStyles = (element, hover) => {
    let { currentStyleGuide } = this.state;
    let styles = Object.keys(currentStyleGuide.properties[element])
      .filter((key) => {
        if(hover){
          return ["hover", "border", "font"].some((type) => key.includes(type))
        } else {
          return !key.includes("hover")
        }
      })
      .reduce((style, key) => {
        let newKey;
        if(key.includes('font')) {
          newKey = 'color';
        } else {
          newKey = key.replace(/_([a-z])/g, (char) => char[1].toUpperCase());
        }
        if(hover) {
          newKey = newKey.split('OnHover')[0]
        }
        return (
          { ...style, [newKey]: currentStyleGuide.properties[element][key].value }
        )
      }, {});
    if(element.includes('button')) {
      styles.borderStyle = 'solid';
      styles.borderWidth = '2px';
    }
    return styles
  }

  setPreviewStyles = () => {
    let { currentStyleGuide } = this.state;
    if (currentStyleGuide && currentStyleGuide.properties) {
      for(let [element] of Object.entries(currentStyleGuide.properties)) {
        if(!element.includes('navigation')) {
          let styles = this.getStyles(element, false);
          this.setState({ [`${element}_styles`]: styles });
          if(element !== 'heading') {
            let hoverStyles = this.getStyles(element, true);
            this.setState({ [`${element}_hover_styles`]: hoverStyles })
          }
        }
      }
    }
  }

  render() {
    let { errorMessage, isCreating } = this.props;
    let { currentStyleGuide } = this.state;

    if(!currentStyleGuide) {
      return null
    }
    return (
      currentStyleGuide &&
        <Form
          buttonGroupClass="form-controls-container row text-end"
          data={currentStyleGuide}
          title={i18n.t("Style Guide")}
          buttonStyle="primary"
          onCancel={this.onCancel}
          saveButtonText={i18n.t(`${isCreating ? 'Save' : 'Update'} Style Guide`)}
          addAction={this.onSave}
          updateAction={this.onSave}
          disableButtons={false}
          saveButtonAreaClassName="form-buttons-container-right"
        >
          <FieldGroup className="row style-guide-name">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="name"
                maxLength={140}
                caption={i18n.t("Style Guide Name")}
                required={true} />
            </FieldGroup>
          </FieldGroup>

          <BrandingButtonsWell
            currentStyleGuide={currentStyleGuide}
            errorMessage={errorMessage}
            getProperties={this.getProperties}
            invalidPrimaryHex={this.state.invalidHex_primary_button}
            invalidSecondaryHex={this.state.invalidHex_secondary_button}
            invalidTertiaryHex={this.state.invalidHex_tertiary_button}
            primaryComplianceMessage={this.state.primary_button_message}
            secondaryComplianceMessage={this.state.secondary_button_message}
            tertiaryComplianceMessage={this.state.tertiary_button_message}
            primaryStyles={this.state.primary_button_styles}
            primaryHoverStyles={this.state.primary_button_hover_styles}
            secondaryStyles={this.state.secondary_button_styles}
            secondaryHoverStyles={this.state.secondary_button_hover_styles}
            tertiaryStyles={this.state.tertiary_button_styles}
            tertiaryHoverStyles={this.state.tertiary_button_hover_styles} />

          <BrandingNavigationWell
            currentStyleGuide={currentStyleGuide}
            errorMessage={errorMessage}
            getProperties={this.getProperties}
            invalidHex={this.state.invalidHex_navigation}
            complianceMessage={this.state.navigation_message} />

          <BrandingLinkWell
            currentStyleGuide={currentStyleGuide}
            errorMessage={errorMessage}
            getProperties={this.getProperties}
            invalidHex={this.state.invalidHex_link}
            linkStyles={this.state.link_styles}
            linkHoverStyles={this.state.link_hover_styles} />

          <BrandingHeaderWell
            currentStyleGuide={currentStyleGuide}
            errorMessage={errorMessage}
            getProperties={this.getProperties}
            invalidHex={this.state.invalidHex_heading}
            headerStyles={this.state.heading_styles} />
        </Form>
    );
  }
}

export default connect(mapStateToProps)(OrganizationStyleGuideContainer);
