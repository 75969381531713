import React from 'react'
import i18n from 'lib/i18n'
import { ConfirmationModal } from 'reactifi'

const DeleteLocationModal = (props) => (
  <ConfirmationModal
    title={i18n.t("Delete Location")}
    children={i18n.t("Are you sure you want to delete this location? It may impact historical reports.")}
    {...props}
  />
);

export default DeleteLocationModal