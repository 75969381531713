import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  buildGenericProps,
  AlertMessage,
  AlertMessageTypes,
  Page,
  JsonApiDecorator,
  ConfirmationModal,
  getPageSize,
  Button
} from "reactifi";
import * as categoriesActionCreators from "../actions/CategoriesActionCreators";
import { CategoriesList } from "../components";
import i18n from "lib/i18n";

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "categories", [
    "category_labels"
  ]);

  props.organizationId = ownProps.route.organization_id;
  props.successMessage = state.api.successMessage;

  if (state.api.errorMessage) {
    props.errorMessage = state.api.errorMessage;
    props.showAlertMessage = true;
  }

  if (state.api.errorMessages) {
    props.errorMessages = state.api.errorMessages;
  }

  let apiStore = new JsonApiDecorator(state.api);
  props.currentCategory = props.currentEntity;

  if (apiStore.categories) {
    props.categories = apiStore.categories.all(
      "category_labels"
    );
  }

  if (props.currentCategory && apiStore.categories) {
    props.currentCategory.category_labels = props.currentCategory.id
      ? props.categories.find(category => category.id === props.currentCategory.id)
        .category_labels
      : [];
  }

  props.metaData = state.api.categories
    ? state.api.categories.meta
    : null;

  props.createNewStore = type => apiStore.newObject(type);

  return props;
}

export class GroupingsContainer extends Component {
  static propTypes = {
    currentCategory: PropTypes.object,
    router: PropTypes.object,
    organizationId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    updateGroup: PropTypes.func,
    errorMessage: PropTypes.string,
    errorMessages: PropTypes.array,
    showAlertMessage: PropTypes.bool,
    categories: PropTypes.array,
    categoriesMeta: PropTypes.object,
    successMessage: PropTypes.string,
    createNewStore: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      categoriesActionCreators,
      this.props.dispatch
    );

    this.fetchData = this.fetchData.bind(this);
    this.onPageChange = this.onPageChange.bind(this);
    this.onSortChange = this.onSortChange.bind(this);

    this.state = {
      activePage: 1,
      sortName: "name",
      sortOrder: "asc"
    };
  }

  componentDidMount() {
    this.fetchData(1);
  }

  async fetchData(pageNumber) {
    await this.actions.loadItems(
      {},
      { number: pageNumber, size: getPageSize() },
      this.state.sort
    );
  }

  onPageChange(pageNumber) {
    this.setState(
      {
        activePage: pageNumber
      },
      () => this.actions.loadItems({}, this.getPage(), this.state.sort)
    );
  }

  onSortChange(sortName, sortOrder) {
    this.setState(
      {
        sort: {
          value: sortName,
          direction: sortOrder
        },
        sortName,
        sortOrder,
        activePage: 1
      },
      () => this.actions.loadItems({}, this.getPage(), this.state.sort)
    );
  }

  createCategory = async () => {
    await this.actions.selectItem("add");
    this.props.router.push("add");
  }

  editAction = async (event, item) => {
    await this.actions.selectItem(item.id);
    this.props.router.push(item.id);
  }

  deleteAction = async (event, item) => {
    await this.actions.selectItem(item.id);

    this.setState({ showDeleteModal: true });
  }

  getPage = () => {
    return { number: this.state.activePage, size: getPageSize() };
  };

  deleteCategory = async (item) => {
    await this.actions.deleteCategory(this.props.currentCategory);

    this.setState({ showDeleteModal: false });

    this.actions.loadItems({}, this.getPage(), this.state.sort);
  }

  renderAlertMessages() {
    const {
      successMessage,
      errorMessage,
      errorMessages,
      showAlertMessage
    } = this.props;

    return (
      <div>
        <AlertMessage
          message={errorMessage}
          messageType={AlertMessageTypes.danger}
          visible={errorMessage && showAlertMessage}
          clearMessages={this.actions.clearMessages}
        />
        {errorMessages &&
          errorMessages.map(message => (
            <AlertMessage
              message={message}
              messageType={AlertMessageTypes.danger}
              visible={true}
              clearMessages={this.actions.clearMessages}
            />
          ))}
        <AlertMessage
          message={successMessage}
          messageType={AlertMessageTypes.success}
          visible={successMessage && showAlertMessage}
          clearMessages={this.actions.clearMessages}
        />
      </div>
    );
  }

  renderEmptyState = () => {
    return (
      <div className="custom-groupings-bg">
        <div className="custom-groupings-text empty-custom-groupings text-center">
          {i18n.t("Create a category such as \"Department\" to establish labels, like \"Engineering\" and \"Finance\" to classify and manage your users.")}
        </div>
        <div className="custom-groupings">
          <div className="mx-auto custom-grouping-image m-t-25" />
          <div className="col-12 m-t-20 p-t-15 text-center">
            <Button
              label={i18n.t("Create Custom Category")}
              icon="plus"
              style="primary"
              onClick={this.createCategory}
              data-action="create-custom-category"
            />
          </div>
        </div>
      </div>
    );
  }

  render() {
    let data = this.props.categories;

    return (
      <Page
        title={i18n.t("Manage Custom Categories & Labels")}
        contentType="cards"
        usePageWrapper={true}
      >
        {this.renderAlertMessages()}

        {data.length > 0 ? (
          <div className="custom-groupings-bg">
            <div className="custom-groupings-text">
              {i18n.t("Use categories and labels to group learners so that you can easily assign content and segment completion reporting based on the groups you create. Example categories could include: \"Department,\" \"Hire Year\", or \"Matriculation Semester\". Learn more")}<a href="https://help.everfi.com/s/article/Custom-Categories" target="_blank" rel="noopener noreferrer">{i18n.t(' here.')}</a>
            </div>
            <CategoriesList
              data={data}
              onPageChange={this.onPageChange}
              onSortChange={this.onSortChange}
              editAction={this.editAction}
              viewAction={this.viewAction}
              deleteAction={this.deleteAction}
              activePage={this.state.activePage}
              sortName={this.state.sortName}
              sortOrder={this.state.sortOrder}
              createCategory={this.createCategory}
              {...this.props}
            />
          </div>
        ) :
          this.renderEmptyState()
        }
        <ConfirmationModal
          title={i18n.t('Delete Custom Category')}
          children={i18n.t('Are you sure?')}
          show={this.state.showDeleteModal}
          onConfirm={this.deleteCategory}
          onCancel={() => this.setState({ showDeleteModal: false })}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps)(GroupingsContainer);
