import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildGenericProps, JsonApiDecorator, Page, AccessControlWrapper, Button } from 'reactifi';
import * as locationActionCreators from '../../Locations/actions/locationActionCreators';
import LocationForm from '../../Locations/components/LocationForm';
import i18n from 'lib/i18n';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'locations', ['organization']);

  const stateApiDecorator = new JsonApiDecorator(state.api);

  if (stateApiDecorator.locations) {
    props.currentLocation = stateApiDecorator.locations.find(ownProps.params.location_id);
  }

  props.googleMapsApiKey = ownProps.route.googleMapsApiKey;
  props.permissions = ownProps.route.permissions;
  props.organizationId = ownProps.params.id;
  props.locationId = ownProps.params.location_id;
  return props;
}

export class OrganizationLocationsDetailsContainer extends React.Component {
  static propTypes = {
    locations: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    currentLocation: PropTypes.object,
    googleMapsApiKey: PropTypes.string.isRequired,
    successMessage: PropTypes.string,
    organizationId: PropTypes.string,
    locationId: PropTypes.string,
    viewer: PropTypes.string.isRequired,
    router: PropTypes.any,
    permissions: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(locationActionCreators, this.props.dispatch);

    this.state = {
      currentLocation: ''
    };
  }

  componentDidMount() {
    if (!this.props.currentLocation) {
      this.actions.findLocation(this.props.locationId);
    }
  }

  openEditModal = () => {
    this.setState({ currentLocation: this.props.currentLocation });
  }

  closeModal = () => {
    this.setState({ currentLocation: null });
  }

  render() {
    const locations = [];
    this.props.locations.forEach(function (location) {
      if (location.address_latitude && location.address_longitude) {
        return locations.push({ id: location.id, lat: location.address_latitude, lng: location.address_longitude });
      }
    });

    const actionButton = (
      <AccessControlWrapper requiredPermission="edit_locations" permissions={this.props.permissions}>
        <Button variant="primary"
          onClick={(e) => this.openEditModal()}
          label={i18n.t('Edit')}
          icon="pen" />
      </AccessControlWrapper>
    );

    return (
      <Page title={this.props.currentLocation ? this.props.currentLocation.name : ''}
        pageType="child"
        contentType="fullwidth"
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        clearMessages={this.actions.clearMessages}
        actionButton={actionButton}
        backButton={this.props.router.goBack}
        usePageWrapper={true}>

        <div className="page-panel">
          <div className="page-panel-body">
            <LocationForm currentLocation={this.state.currentLocation} selectLocation={this.actions.selectLocation} clearMessages={this.actions.clearMessages}
              googleMapsApiKey={this.props.googleMapsApiKey} errorMessage={this.props.errorMessage} viewer={this.props.viewer} organizationId={this.props.organizationId}
              updateLocation={this.actions.updateLocation} createLocation={() => { }} closeModal={this.closeModal} loadOrganization={this.actions.loadOrganization} />

            <div className="container-fluid tabbed-nav-content">
              <div className="row">
                <div className="col-md-12">
                  <h4>{i18n.t('Details')}</h4>
                </div>
              </div>
              {this.props.currentLocation &&
                <div className="row">
                  <div className="col-md-3">
                    <h5>{i18n.t('Location Name')}</h5>
                    <span className="value">{this.props.currentLocation.name}</span>
                  </div>
                  <div className="col-md-3">
                    <h5>{i18n.t('Address')}</h5>
                    <span className="value">{this.props.currentLocation.address_formatted}</span>
                  </div>
                  <div className="col-md-3">
                    <h5>{i18n.t('Contacts')}</h5>
                    <span className="value">{this.props.currentLocation.contact_name}</span>
                  </div>
                </div>}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default connect(mapStateToProps)(OrganizationLocationsDetailsContainer);
