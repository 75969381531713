import React from 'react';
import PropTypes from 'prop-types';
import { Step } from 'reactifi';

export default class UploadStep extends Step {
  static propTypes = {
    title: PropTypes.string.isRequired,
    intro: PropTypes.object,
    onUpdateData: PropTypes.func,
    data: PropTypes.object
  };

  updateData = data => {
    if (typeof this.props.onUpdateData === 'function') {
      this.props.onUpdateData(data);
    }
  };

  renderIntro = () => {
    return this.props.intro || null;
  };

  renderBody = () => {
    return null;
  };

  renderOutro = () => {
    return null;
  }

  render() {
    const { title } = this.props;

    return (
      <div className="grey-box">
        <div className="grey-box-header">
          <h4>{ title }</h4>
        </div>
        {this.renderIntro()}
        {this.renderBody()}
        {this.renderOutro()}
      </div>
    )
  }
}
