import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { FieldGroup, FormField, FormControl, ColorUtil } from 'reactifi';
import isEqual from 'lodash/isEqual';
import BrandingColorBoxPreview from './BrandingColorBoxPreviewComponent.js';

const CONTRAST_REF_COLORS = {
  LIGHT: '#E4E9ED',
  DARK: '#30363C'
};
const DEFAULT_COLORS = {
  LIGHT: '#dff7f6',
  DARK: '#0d7978'
};

export default class BrandingGeneralColorsWell extends Component {
  static propTypes = {
    currentOrganization: PropTypes.shape({
      brand_customizations: PropTypes.object
    })
  }

  constructor(props){
    super(props);
    const { brand_customizations: brandCustomization } = this.props.currentOrganization;

    this.state = {
      disableFormSave: false,
      dark_color: brandCustomization.dark_color || DEFAULT_COLORS.DARK,
      light_color: brandCustomization.light_color || DEFAULT_COLORS.LIGHT,
      invalidHex: false,
      badDarkConstrastRatio: false,
      badLightConstrastRatio: false
    };

    this.defaultDarkColor = brandCustomization.dark_color || DEFAULT_COLORS.DARK;
    this.defaultLightColor = brandCustomization.light_color || DEFAULT_COLORS.LIGHT;
    this.onDarkColorChange = this.onColorChange('dark_color');
    this.onLightColorChange = this.onColorChange('light_color');
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentOrganization && !isEqual(this.props.currentOrganization, prevProps.currentOrganization)) {
      const { currentOrganization: { brand_customizations: { dark_color, light_color } } } = this.props;

      this.setState({
        dark_color,
        light_color,
        badDarkConstrastRatio: dark_color && !ColorUtil.isContrastRatioAACompliant(dark_color, CONTRAST_REF_COLORS.LIGHT),
        badLightConstrastRatio: light_color && !ColorUtil.isContrastRatioAACompliant(light_color, CONTRAST_REF_COLORS.DARK)
      });
    }
  }

  onColorChange = key => val => {
    this.setState({
      [key]: ColorUtil.addHashToColorIfNeeded(val),
      invalidHex: val !== "" && !ColorUtil.isValidHexString(val)
    }, () => {
      const { dark_color, light_color } = this.state;

      if (key === 'dark_color' && ColorUtil.isValidHexString(dark_color)) {
        this.setState({
          badDarkConstrastRatio: !ColorUtil.isContrastRatioAACompliant(dark_color, CONTRAST_REF_COLORS.LIGHT)
        });
      }

      if (key === 'light_color' && ColorUtil.isValidHexString(light_color)) {
        this.setState({
          badLightConstrastRatio: !ColorUtil.isContrastRatioAACompliant(CONTRAST_REF_COLORS.DARK, light_color)
        });
      }
    });
  };

  getContrastErrorMessage = () => (
    <FieldGroup className="error-text">
      <p>{ i18n.t("The color selected doesn't meet the sufficient contrast ratio as per WCAG 2.0 standards, we recommend choosing another color.") }</p>
    </FieldGroup>
  );

  render () {
    let { currentOrganization } = this.props;
    const { dark_color, light_color } = this.state;
    const darkColor = (dark_color === "" || !ColorUtil.isValidHexString(dark_color)) ? "" : dark_color;
    const lightColor = (light_color === "" || !ColorUtil.isValidHexString(light_color)) ? "" : light_color;

    return (
      <FieldGroup className="branding-well-container" data={currentOrganization} data-action="brand-colors-card">
        <h4>{i18n.t("General Brand Colors")}</h4>
        <div>{i18n.t("Brand colors will be applied throughout the EverFi experience.")}</div>
        <FieldGroup className="row">
          <FieldGroup className="col-sm-4">
            <div><strong>{i18n.t("Dark Color")}</strong></div>
            <BrandingColorBoxPreview color={ColorUtil.addHashToColorIfNeeded(darkColor)}
              className="pull-left" />
            <FormField className="pull-left"
              name="brand_customizations[dark_color]">
              <FormControl name="brand_customizations[dark_color]"
                placeholder={this.defaultDarkColor}
                onChange={this.onDarkColorChange}/>
            </FormField>
          </FieldGroup>
          <div className="col-sm-3 preview-box">
            <div className="box-common preview-dark" style={{ color: ColorUtil.addHashToColorIfNeeded(darkColor) }}>
              {i18n.t('Preview')}
            </div>
          </div>
          <div className="col-sm-3 preview-box">
            <div className="box-common preview-dark-reverse" style={{ backgroundColor: ColorUtil.addHashToColorIfNeeded(darkColor) }}>
              {i18n.t('Preview')}
            </div>
          </div>
        </FieldGroup>
        { this.state.badDarkConstrastRatio && this.getContrastErrorMessage() }
        <FieldGroup className="row">
          <FieldGroup className="col-sm-4">
            <div><strong>{i18n.t("Light Color")}</strong></div>
            <BrandingColorBoxPreview color={ColorUtil.addHashToColorIfNeeded(lightColor)}
              className="pull-left" />
            <FormField className="pull-left"
              name="brand_customizations[light_color]">
              <FormControl name="brand_customizations[light_color]"
                placeholder={this.defaultLightColor}
                onChange={this.onLightColorChange}/>
            </FormField>
          </FieldGroup>
          <div className="col-sm-3 preview-box">
            <div className="box-common preview-light" style={{ color: ColorUtil.addHashToColorIfNeeded(lightColor) }}>
              {i18n.t('Preview')}
            </div>
          </div>
          <div className="col-sm-3 preview-box">
            <div className="box-common preview-light-reverse" style={{ backgroundColor: ColorUtil.addHashToColorIfNeeded(lightColor) }}>
              {i18n.t('Preview')}
            </div>
          </div>
        </FieldGroup>
        { this.state.badLightConstrastRatio && this.getContrastErrorMessage() }
        { this.state.invalidHex && <FieldGroup className="error-text">
          <p>{ i18n.t("Please make sure that the entered hexcolors are valid.") }</p>
        </FieldGroup> }
      </FieldGroup>
    )
  }
}
