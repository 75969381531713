import React from "react";
import PropTypes from "prop-types";
import {
  ModalForm,
  AddressPickerControl,
  FieldGroup,
  FloatingLabelFormField,
  MaskedFormControl,
  FormSection
} from "reactifi";
import i18n from "lib/i18n";

export default class LocationForm extends React.Component {
  static propTypes = {
    currentLocation: PropTypes.object,
    errorMessage: PropTypes.string,
    errorMessages: PropTypes.array,
    selectLocation: PropTypes.func.isRequired,
    createLocation: PropTypes.func.isRequired,
    updateLocation: PropTypes.func.isRequired,
    clearMessages: PropTypes.func.isRequired,
    closeModal: PropTypes.func,
    googleMapsApiKey: PropTypes.string.isRequired,
    loadOrganization: PropTypes.func.isRequired,
    organizationId: PropTypes.string
  };

  constructor(props) {
    super(props)
    this.state = {
      errorMessage: props.errorMessage ? props.errorMessage : ''
    }
  }

  onCancel = () => {
    this.props.selectLocation(null);
    this.closeModal();
    this.props.clearMessages();
  }

  updateLocation = () => {
    this.props.updateLocation(this.props.currentLocation);
    this.closeModal();
  }

  closeModal = () => {
    if (this.props.closeModal) {
      this.props.closeModal();
    }
  }

  getInitialOrganizationData = () => {
    return this.props.currentLocation &&
      this.props.currentLocation.organization_id
      ? {
        id: this.props.currentLocation.organization.id,
        display: this.props.currentLocation.organization.name
      }
      : {};
  }

  onOrganizationChange = (val) => {
    if (val && val.id) {
      this.props.loadOrganization(val.id);
    }
  }

  createLocation = (location, addAnother) => {
    location.organization_id = this.props.organizationId;
    this.props.createLocation(location, addAnother);
  }

  static getDerivedStateFromProps(nextProps) {
    const { errorMessage, errorMessages } = nextProps;
    const error = errorMessages ? errorMessages[0].error : errorMessage;
    return {
      errorMessage: error
    };
  }

  onInvalidFormSubmit = () => {
    this.setState({ errorMessage: 'Errors on the form!' }, () => {
      setTimeout(() => {
        this.setState({ errorMessage: '' })
      }, 4000)
    });
  }

  render() {
    const { currentLocation } = this.props;

    let modalTitle =
      this.props.currentLocation && this.props.currentLocation.id
        ? i18n.t("Edit %s", {
          postProcess: "sprintf",
          sprintf: [this.props.currentLocation.name]
        })
        : i18n.t("Add a New Location");

    return (
      <ModalForm
        data={this.props.currentLocation}
        data-object-id={currentLocation && currentLocation.id}
        data-object-type="locations"
        errorMessage={this.state.errorMessage}
        title={modalTitle}
        onCancel={this.onCancel}
        addAction={this.createLocation}
        updateAction={this.updateLocation}
        onInvalidFormSubmit={this.onInvalidFormSubmit}
      >
        <FieldGroup className="container-fluid">
          <FormSection title={i18n.t("Location Details")}>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("External Location ID")}
                  name="external_id"
                  required={true}
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t("Name")}
                  name="name"
                  required={true}
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="address"
                  caption={i18n.t("Address (Type and select below)")}
                  required={true}
                >
                  <AddressPickerControl
                    data-attribute="address"
                    data-object-id={currentLocation && currentLocation.id}
                    data-object-type="locations"
                    googleMapsApiKey={this.props.googleMapsApiKey}
                    includeStatesAndCities={true}
                    includeCountries={true}
                    initialData={this.props.currentLocation}
                    placeholder=""
                    addressFieldPrefix="address"
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  caption={i18n.t("Address Line 2")}
                  name="address_room"
                />
              </FieldGroup>
            </FieldGroup>
          </FormSection>
          <FormSection
            title={i18n.t("Contact Information")}
            description={i18n.t("Identify a contact responsible for this location. Optional.")}
          >
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("Name")}
                  name="contact_name"
                />
              </FieldGroup>
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  name="contact_phone"
                  caption={i18n.t("Mobile Phone")}
                  required={false}
                >
                  <MaskedFormControl
                    mask="(111) 111-1111"
                    type="tel"
                    pattern="^\(\d{3}\) \d{3}-\d{4}$"
                    placeholderChar=" "
                    placeholder=" "
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-6">
                <FloatingLabelFormField
                  caption={i18n.t("Email")}
                  name="contact_email"
                  type="email"
                  required={false}
                />
              </FieldGroup>
            </FieldGroup>
          </FormSection>
        </FieldGroup>
      </ModalForm>
    );
  }
}
