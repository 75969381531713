import PropTypes from 'prop-types';
import React, { Component } from 'react';
import * as orgUsersActionCreators from '../actions/orgUsersActionCreators';
import { bindActionCreators } from 'redux';
import OrgWizardUserLogin from './OrgWizardUserLogin';
import OrgWizardUserTypes from './OrgWizardUserTypes';
import OrgWizardUserDetails from './OrgWizardUserDetails';
import { AccessControlUtil, Button, Step, Util, WizardModal } from 'reactifi';
import i18n from 'lib/i18n';

export default class OrgWizardUser extends Component {

  static propTypes = {
    currentOrganizationId: PropTypes.string.isRequired,
    currentUser: PropTypes.object,
    deselectUser: PropTypes.func.isRequired,
    dispatch: PropTypes.func,
    errorMessage: PropTypes.string,
    googleMapsApiKey: PropTypes.string,
    isCreate: PropTypes.bool,
    isUserSecondaryManager: PropTypes.bool,
    loadUserFormData: PropTypes.func,
    onCancel: PropTypes.func.isRequired,
    onSelectUser: PropTypes.func,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    register: PropTypes.func,
    ruleSets: PropTypes.array.isRequired,
    updateUser: PropTypes.func,
    updateUserRuleSets: PropTypes.func,
    wizardStep: PropTypes.number
  }

  static defaultProps = {
    isUserSecondaryManager: false
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(orgUsersActionCreators, this.props.dispatch);

    this.state = {
      createAndAdd: false,
      isValidProfile: false,
      selectedUserTypes: this.props.currentUser?.rule_set_roles || {},
      userTypeUpdated: false
    }
  }

  componentWillUnmount() {
    this.props.deselectUser();
  }

  onValidityCheck = valid => {
    this.setState({ isValidProfile: valid });
  }

  setAddAndClear = () => {
    this.setState({ createAndAdd: true, login: '' });
  }

  renderExtraWizardButtons = (currentStep, goingToStep, isLastStep) => {
    if (!isLastStep || !this.props.isCreate) {
      return null;
    }

    return (
      <Button data-action="create-and-add-another-user" onClick={() => this.setAddAndClear()} disabled={!!goingToStep} >
        {i18n.t('Create & Add Another')}
      </Button>
    );
  }

  updateLogin = (login, loginType) => {
    this.setState({ login, loginType });
  }

  updateUserRuleSets = (ruleSets, orgId) => {
    this.props.updateUserRuleSets(ruleSets, orgId);
  }

  setSelectedUserTypes = selectedUserTypes => {
    this.setState({ selectedUserTypes });
  }

  onCancel = () => {
    this.setState({ selectedUserTypes: {}, userTypeUpdated: false });
    this.props.onCancel();
  }

  setUserTypeUpdated = userTypeUpdated => {
    this.setState({ userTypeUpdated });
  }

  renderSteps = () => {
    const canModifyOutsideLob = AccessControlUtil.hasCapability("Capabilities::ModifyLearnersOutsideLob", this.props.orgCapabilities);
    return [
      ...(this.props.isCreate && canModifyOutsideLob) ? [
        (<Step title={i18n.t('User Login')} key="login">
          <OrgWizardUserLogin
            {...this.props}
            isCreate={this.props.isCreate}
            user={this.props.currentUser}
            loadUserFormData={this.props.loadUserFormData}
            currentOrganizationId={this.props.currentOrganizationId}
            updateLogin={this.updateLogin}
            onSelectUser={this.props.onSelectUser}
            login={this.state.login}
            loginType={this.state.loginType}
          />
        </Step>)] : [],
      ...this.props.isUserSecondaryManager ? [] : [
        (<Step title={i18n.t('User Types')} key="types">
          <OrgWizardUserTypes
            {...this.props}
            isCreate={this.props.isCreate}
            user={this.props.currentUser}
            setSelectedUserTypes={this.setSelectedUserTypes}
            selectedUserTypes={this.state.selectedUserTypes}
            updateUserRuleSets={this.props.updateUserRuleSets}
            loadUserFormData={this.props.loadUserFormData}
            currentOrganizationId={this.props.currentOrganizationId}
            ruleSets={this.props.ruleSets}
            login={this.state.login}
            loginType={this.state.loginType}
            setUserTypeUpdated={this.setUserTypeUpdated}
          />
        </Step>)],
      (<Step title={i18n.t('User Details')} key="details">
        <OrgWizardUserDetails
          {...this.props}
          user={this.props.currentUser}
          isCreate={this.props.isCreate}
          register={this.props.register}
          updateUser={this.props.updateUser}
          googleMapsApiKey={this.props.googleMapsApiKey}
          loadLocation={this.actions.loadLocation}
          createAndAdd={this.state.createAndAdd}
          onValidityCheck={this.onValidityCheck}
          login={this.state.login}
          loginType={this.state.loginType}
          userTypeUpdated={this.state.userTypeUpdated}
        />
      </Step>)
    ];
  }

  render() {
    const {
      currentUser,
      errorMessage,
      isCreate,
      wizardStep
    } = this.props;

    const sharedDataAttributes = currentUser && Util.dataAttribsForEl(currentUser);

    return (
      <WizardModal
        disableContinueButton={!this.state.isValidProfile}
        errorMessage={errorMessage}
        finalButtonLabel={isCreate ? i18n.t('Create User') : i18n.t('Update User')}
        footerButtonAttributes={{
          cancelButton: {
            ...sharedDataAttributes,
            'data-action': 'close-user-modal',
            id: 'user-modal-cancel-button'
          },
          nextButton: {
            ...sharedDataAttributes,
            'data-action': 'next-user-modal-step',
            id: 'user-modal-next-step-button'
          },
          previousButton: {
            ...sharedDataAttributes,
            'data-action': 'previous-user-modal-step',
            id: 'user-modal-previous-step-button'
          }
        }}
        initialStep={wizardStep}
        limitHeight={true}
        modalSize="medium"
        onCancel={this.onCancel}
        onResetSteps={() => this.setState({ createAndAdd: false, selectedUserTypes: {} })}
        renderExtraButtons={this.renderExtraWizardButtons}
        title={isCreate ? i18n.t('Add User') : i18n.t('Edit User')}
      >
        {this.renderSteps()}
      </WizardModal>
    );
  }
}
