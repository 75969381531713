import { selectEntity, updateEntity, createEntity, readEntity, readEntityById, displayError, clearApiState, ApiUtil, JsonApiUtils } from 'reactifi';
import i18n from 'lib/i18n'
export { clearMessages, displaySuccessMessage } from 'reactifi';

const TEAM_ENTITY = 'teams';

export function selectTeam(teamId) {
  return selectEntity(teamId, TEAM_ENTITY);
}

export function findTeam(teamId, includes) {
  return readEntityById(teamId, TEAM_ENTITY, includes);
}

export function readTeams(includes, filters, sort = { value: 'name', direction: 'asc' }, page) {
  return readEntity(TEAM_ENTITY, includes, filters, sort && [sort], page);
}

export function updateTeam(team) {
  return updateEntity(team, (dispatch) => {
    dispatch(selectEntity(null, TEAM_ENTITY));
  }, i18n.t('Team updated successfully'));
}

// Temporary hide for ECA-6013
// export function deleteTeam(team) {
//   return deleteEntity(team, TEAM_ENTITY, i18n.t('Team deleted successfully.'))
// }

export function createTeam(team, addAnother) {
  return createEntity(team, (dispatch) => {
    dispatch(selectEntity(null, TEAM_ENTITY));
    if (addAnother) {
      dispatch(selectEntity('add', TEAM_ENTITY));
    }
  }, i18n.t('Team created successfully!'));
}

export function loadBusinessLines() {
  return readEntity('business_lines');
}

export function loadCategories(filters) {
  return readEntity('categories', ['category_labels'], filters, [{ value: 'name', direction: 'asc' }]);
}

export function loadRuleSets(filters) {
  return readEntity('rule_sets', '', filters);
}

export function loadUsers(filters, userType, page) {
  return async dispatch => {
    try {
      const filterParams = JsonApiUtils.getFilterParams(filters);
      const pageParams = JsonApiUtils.getPageParams(page);
      const params = [filterParams, pageParams].filter(Boolean).join('&');
      const endpoint = `/api/data/users.json?${params}`;
      const data = await ApiUtil.loadData(endpoint, {}, false);
      data.endpoint = endpoint;

      dispatch(clearApiState('users'));
      dispatch({ type: 'API_READ', payload: data, userType });
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

export function loadTeamMembers(filters, page) {
  return loadUsers(filters, 'members', page);
}
