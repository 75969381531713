import React from "react";
import { createContainer } from "reactifi";
import OrganizationImpactStories from "../components/OrganizationImpactStories";
import config from '../configs/impactStoriesConfig';

const Container = createContainer(config);

const OrganizationImpactStoriesContainer = props => {
  return (
    <Container {...props} WrapperComponent={React.Fragment}>
      <OrganizationImpactStories />
    </Container>
  );
};

export default OrganizationImpactStoriesContainer;
