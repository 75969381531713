import { Box } from '@chakra-ui/react';
import i18n from 'lib/i18n';
import PropTypes from 'prop-types';
import React, { useState, useMemo } from 'react';
import {
  DateTimePickerControl,
  FieldGroup,
  FieldGroup5050,
  FileUpload,
  FloatingLabelFormField,
  FormModule,
  FormSection,
  SelectControl,
  s3MediaUploader,
  useContainerProps
} from 'reactifi';
import { LABELS } from '../../../ImpactDashboard/components/ImpactStoryCardProps';
import { impactStoryPath } from '../../common/paths';

const ADMIN_TYPES = Object.freeze([
  'executive',
  'cc_admin',
  'at_work_manager',
  'he_admin',
  'fac_staff_admin',
  'event_manager'
]);

const OrganizationImpactStoryForm = (props) => {
  const {
    currentEntity,
    displayErrorMessage,
    organizationRoles,
    params: { id: organizationId },
    router,
    updateMember
  } = useContainerProps(props);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [imageId, setImageId] = useState(currentEntity.image_id);
  const [imageName, setImageName] = useState(currentEntity.image_name);
  const [imageUrl, setImageUrl] = useState(currentEntity.image_url);
  const [ctaToggled, setCtaToggled] = useState(false);

  const adminTypeOptions = useMemo(() => {
    return ADMIN_TYPES.map((adminType) => {
      const name = organizationRoles[adminType];
      return name && { id: adminType, name };
    }).filter(Boolean);
  }, [organizationRoles]);

  const setDate = (setDateFunction, date) => {
    setDateFunction(date && new Date(date));
  };

  const onUploadImage = ({ data }) => {
    const {
      id,
      attributes: { name, url }
    } = data;

    setImageId(id);
    setImageName(name);
    setImageUrl(url);
  };

  const onDeleteImage = () => {
    setImageId(null);
    setImageName(null);
    setImageUrl(null);
  };

  const navigateToImpactStoriesList = () => {
    router.push(impactStoryPath(organizationId));
  };

  const updateImpactStory = async (impactStory) => {
    impactStory.image_id = imageId;
    impactStory.image_url = imageUrl;

    const success = await updateMember(impactStory);
    if (success) navigateToImpactStoriesList();
  };

  return (
    <FormModule
      onCancel={navigateToImpactStoriesList}
      onSuccess={navigateToImpactStoriesList}
      showAlertMessages={false}
      updateMember={updateImpactStory}
    >
      <input name="organization_id" type="hidden" value={organizationId} />
      <input name="image_id" type="hidden" value={imageId} />

      <Box
        as={FieldGroup}
        sx={{
          backgroundColor: 'white',
          borderRadius: '.75rem',
          '.chakra-card': { overflow: 'initial' }
        }}
      >
        <FormSection isCard={true} title={i18n.t('Impact Story Setting')}>
          <FloatingLabelFormField
            caption={i18n.t('Impact Story Name')}
            hintText={i18n.t(
              'This is an internal facing name for refrencing purposes. E.g. Student Quote Endeavor 2019'
            )}
            name="name"
            required={true}
          />
          <FloatingLabelFormField
            caption={i18n.t('User Type')}
            hintText={i18n.t(
              'This impact stories will appear on the dashboard for each user type you select. You can add more than one user types.'
            )}
            name="admin_type"
            required={true}
          >
            <SelectControl
              labelKey="name"
              multi={true}
              options={adminTypeOptions}
              valueKey="id"
            />
          </FloatingLabelFormField>
          <FieldGroup5050>
            <FloatingLabelFormField
              caption={i18n.t('Start Date')}
              hintText={i18n.t(
                'Date impact story appears on selected dashboard.'
              )}
              name="start_date"
              required={true}
            >
              <DateTimePickerControl
                maxDate={endDate}
                momentFormat="YYYY-MM-DD"
                onChange={(date) => setDate(setStartDate, date)}
                utc={true}
              />
            </FloatingLabelFormField>
            <FloatingLabelFormField
              caption={i18n.t('End Date')}
              hintText={i18n.t(
                'Date impact story is removed from selected dashboard.'
              )}
              name="end_date"
              required={true}
            >
              <DateTimePickerControl
                minDate={startDate}
                momentFormat="YYYY-MM-DD"
                onChange={(date) => setDate(setEndDate, date)}
                utc={true}
              />
            </FloatingLabelFormField>
          </FieldGroup5050>
        </FormSection>
      </Box>

      <Box
        as={FieldGroup}
        sx={{ backgroundColor: 'white', borderRadius: '.75rem' }}
      >
        <FormSection
          backgroundColor="white"
          style={{ backgroundColor: 'white' }}
          sx={{
            backgroundColor: 'white',
            borderRadius: '.75rem',
            '.chakra-card': { overflow: 'initial' }
          }}
          isCard={true}
          title={i18n.t('Impact Story Details')}
        >
          <FloatingLabelFormField
            caption={i18n.t('Impact Story Headline')}
            hintText={i18n.t(
              'Choose a succint and catchy title (headline) for your impact story. Suggested number of character: 100'
            )}
            name="headline"
            required={true}
          />
          <FloatingLabelFormField
            caption={i18n.t('Impact Story Body')}
            as="textarea"
            hintText={i18n.t(
              'Write a more detailed body for this Impact story. Suggested number of character: 450'
            )}
            name="body"
            required={true}
          />
          <FloatingLabelFormField
            caption={i18n.t('Label')}
            hintText={i18n.t(
              'Use a label to categorize this story, e.g. "Student’s Quote"'
            )}
            name="label"
          >
            <SelectControl
              clearable={true}
              labelKey="label"
              options={LABELS}
              valueKey="id"
            />
          </FloatingLabelFormField>
          <FormSection
            description={i18n.t(
              'Call to Action is a button or link on the Impact Story card where users click on to find further information.'
            )}
            onToggle={setCtaToggled}
            title={i18n.t('Call to Action (CTA)')}
            toggle={{ name: 'call_to_action', label: i18n.t('Show CTA') }}
          >
            <FloatingLabelFormField
              caption={i18n.t('Call to Action Text')}
              hintText={i18n.t(
                'You can specify the text that goes on the button or link, e.g. "Read More" or "Enter"'
              )}
              name="call_to_action_text"
              required={ctaToggled}
            />
            <FloatingLabelFormField
              caption={i18n.t('External URL')}
              hintText={i18n.t(
                'Enter a URL to specify where users go when they click on the button or link.'
              )}
              name="external_url"
              required={ctaToggled}
              type="url"
              validationType="url"
            />
          </FormSection>
          <FormSection
            description={i18n.t(
              'This is an image that will be placed on the right side of the Impact Story card. Upload an image with 16:9 aspect ratio. Min size: 480 x 270 px'
            )}
            title={i18n.t('Media')}
          >
            <FileUpload
              accept={['.jpg', '.png', '.gif', '.jpeg']}
              actions={{
                success: onUploadImage,
                failure: (e) => displayErrorMessage(e.message),
                delete: onDeleteImage
              }}
              button={{
                text: i18n.t('Upload Image'),
                className: 'btn btn-secondary'
              }}
              className="file-upload-minimal"
              deleteButton={imageUrl}
              description={null}
              embedPreviewUrl={imageUrl}
              header={null}
              icon={{
                className: 'images',
                iconSize: 'medium',
                fileClassName: 'file',
                noFileClassName: 'file-slash'
              }}
              input={{ name: 'image' }}
              label={{ text: imageName, closable: false }}
              name="image"
              uploader={s3MediaUploader}
            />
            <FloatingLabelFormField
              caption={i18n.t('Alternative Text')}
              hintText={i18n.t(
                'Add alternate text that describes the image. This text is read in place of the image when users use a screen reader.'
              )}
              name="image_alt_text"
            />
          </FormSection>
        </FormSection>
      </Box>
    </FormModule>
  );
};

OrganizationImpactStoryForm.propTypes = {
  currentEntity: PropTypes.object,
  displayErrorMessage: PropTypes.func,
  organizationRoles: PropTypes.object,
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  updateMember: PropTypes.func
};

export default OrganizationImpactStoryForm;
