import ReactOnRails from 'react-on-rails';
import {
  AttentionGrabber,
  AutoLogout,
  ErrorPage,
  HeaderMenu,
  InteractiveNotifications,
  RailsReactHookWrapper,
  ProgressSpinner,
  SystemBanner,
  SystemNotifications,
  StorageUtil
} from 'reactifi';
import ActionableDashboardApp from './ActionableDashboard';
import AdministratorsApp from './Administrators';
import BenchmarksApp from './Benchmarks';
import CategoriesApp from './Categories';
import ChartsApp from './Charts';
import CustPortalReportsApp from './CustPortalReports';
import DatafiExportsApp from './DatafiExports';
import DataIntegrationApp from './DataIntegration';
import DownloadsApp from './Downloads';
import EmbedContentApp from './EmbedContentDomains';
import FoundryUsersApp from './FoundryUsers';
import IdentityProvidersApp from './IdentityProviders';
import ImpactDashboardApp from './ImpactDashboard';
import ImportBatchesApp from './ImportBatches';
import InterstitialApp from './Interstitial';
import LocationsApp from './Locations';
import OnboardingChecklistApp from './OnboardingChecklist';
import OrganizationsApp from './Organizations';
import RegistrationApp from './Registration';
import RostersApp from './Rosters';
import TagManagerApp from './TagManager';
import TeamsApp from './Teams';
import UploadsApp from './UploadsApp';
import UsersApp from './Users';
import WebhooksApp from './Webhooks';
import SystemMessagingApp from './SystemMessaging';
import TrainingPeriodsApp from './TrainingPeriods';
import CookiesApp from './Cookies';

StorageUtil.clearSessionActiveProps();

ReactOnRails.register({
  ActionableDashboardApp,
  AdministratorsApp,
  AttentionGrabber,
  AutoLogout,
  BenchmarksApp,
  CategoriesApp,
  ChartsApp,
  CookiesApp,
  CustPortalReportsApp,
  DatafiExportsApp,
  DataIntegrationApp,
  DownloadsApp,
  EmbedContentApp,
  ErrorPage,
  FoundryUsersApp,
  HeaderMenu,
  IdentityProvidersApp,
  ImpactDashboardApp,
  ImportBatchesApp,
  InteractiveNotifications,
  InterstitialApp,
  LocationsApp,
  TagManagerApp,
  OnboardingChecklistApp,
  OrganizationsApp,
  ProgressSpinner,
  RailsReactHookWrapper,
  RegistrationApp,
  RostersApp,
  SystemMessagingApp,
  SystemNotifications,
  SystemBanner,
  TeamsApp,
  TrainingPeriodsApp,
  UploadsApp,
  UsersApp,
  WebhooksApp
});
