import React from "react";
import PropTypes from "prop-types";
import { ListModule, useContainerProps } from "reactifi";
import i18n from "lib/i18n";
import { Util } from "@everfi/reactifi";
import { impactStoryPath } from '../../common/paths';

const OrganizationImpactStoriesList = props => {
  props = useContainerProps(props);
  const { deleteMember, loadCollection, organizationRoles, organizationId, router } = props;

  const actions = [
    {
      type: 'edit',
      selectMember: id => { router.push(impactStoryPath(organizationId, id)) }
    },
    {
      type: "delete",
      actionType: "confirm",
      confirmTitle: i18n.t("Delete Impact Story"),
      confirmChildren: i18n.t("Are you sure you want to delete this impact story?"),
      deleteMember
    },
    {
      action: row => impactStoryPath(organizationId, `copy/${row.id}`),
      actionType: "link",
      icon: 'copy',
      title: '',
      tooltip: i18n.t('Copy')
    }
  ];

  const fields = [
    { name: 'name', title: i18n.t('Impact Story Name'), width: '60%' },
    { name: 'admin_type', title: i18n.t('User Types'), width: '40%', formatter: cell => cell.map(role => organizationRoles[role]).join(', ') },
    { name: 'start_date', title: i18n.t('Start Date'), width: 110, formatter: cell => Util.format(cell, 'P') },
    { name: 'end_date', title: i18n.t('End Date'), width: 110, formatter: cell => Util.format(cell, 'P') },
    { name: 'active', title: i18n.t('Status'), width: 100, formatter: cell => cell ? i18n.t('Active') : i18n.t('Inactive') }
  ];

  const loadCollectionOverride = () => loadCollection({ filters: { organization_id: organizationId } });

  return (
    <ListModule
      {...props}
      actions={actions}
      fields={fields}
      loadCollection={loadCollectionOverride}
      options={{ noDataText: i18n.t('There are no impact stories created yet. Click "Create Impact Story" to create the first one.') }}
    />
  );
}

OrganizationImpactStoriesList.propTypes = {
  deleteMember: PropTypes.func,
  loadCollection: PropTypes.func,
  organizationRoles: PropTypes.func,
  organizationId: PropTypes.string,
  router: PropTypes.shape({
    push: PropTypes.func
  })
};

export default OrganizationImpactStoriesList;
