import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { CardsModule, ConfirmationModal, DisplayMaxWidthMedium, ExplainerText, Header, IconCategoryValueGroup, IconCategoryValue, MessagesModule, PageWrapper, Section } from 'reactifi';
import i18n from 'lib/i18n';
import OrgUserAssignmentPanel from './OrgUserAssignmentPanel';

const OrgUserAssignment = props => {
  const { currentTrainingPeriod, currentUser, currentUserAssignment: { content = {}, assignment = {}, progresses = [] }, progressId, router, userAssignments } = props;
  const [assignmentToUnassign, setAssignmentToUnassign] = useState(null);
  const [assignmentToReassign, setAssignmentToReassign] = useState(null);
  const contentName = progresses?.find(prog => prog.id === progressId)?.content_name;
  const pageTitle = i18n.t('%s - %s Progress Record', { postProcess: 'sprintf', sprintf: [currentUser.full_name, contentName] });

  useEffect(() => {
    if(assignment.training_period_id) {
      props.loadTrainingPeriodById(assignment.training_period_id);
    }
  }, []);

  const hideActionModal = () => {
    setAssignmentToUnassign(null);
    setAssignmentToReassign(null);
  }

  const onConfirmAction = async (userAssignment, isUnassign) => {
    const { contentServiceUrl, jwt } = props;
    const action = isUnassign ? 'unassign' : 'reassign';

    await props.onAssignmentUpdate(userAssignment.id, action, contentServiceUrl, jwt);

    hideActionModal();
  }

  const renderPageHero = () => (
    <Section title={i18n.t('Progress Record Details')} dataObjectType="explainer">
      <ExplainerText className="m-t-0">{i18n.t('The assignments listed below include everywhere the learner is assigned the selected learning activity.')}</ExplainerText>
      <IconCategoryValueGroup className="m-b-0">
        <IconCategoryValue category={i18n.t('Learning Activity')} value={contentName} />
        <IconCategoryValue category={i18n.t('Training Period')} value={currentTrainingPeriod?.name || i18n.t('None')} />
      </IconCategoryValueGroup>
    </Section>
  );

  const renderActionModal = () => {
    const selectedUserAssignment = assignmentToUnassign || assignmentToReassign;

    if (!selectedUserAssignment) return null;

    const { assignment = {} } = selectedUserAssignment;
    const isUnassign = !!assignmentToUnassign;
    const i18nOptions =  { postProcess: 'sprintf', sprintf: [assignment.name] };
    const modalTitle = isUnassign ? i18n.t('Unassign %s', i18nOptions) : i18n.t('Reassign %s', i18nOptions);
    const confirmBtnText = isUnassign ? i18n.t('Unassign') : i18n.t('Reassign');

    return (
      <ConfirmationModal
        title={modalTitle}
        data={selectedUserAssignment}
        canClickOff={true}
        onConfirm={assignment => onConfirmAction(assignment, isUnassign)}
        onCancel={hideActionModal}
        confirmButtonText={confirmBtnText}
      >
        {isUnassign && (
          <p className="m-t-10">
            {i18n.t('Unassigning %s from %s will also unassign them from all other learning activities included in this assignment.', {
              postProcess: 'sprintf',
              sprintf: [currentUser.full_name, assignment.name]
            })}
          </p>
        )}
        <p>{i18n.t('Note: This process may take a while.')}</p>
      </ConfirmationModal>
    );
  }

  return (
    <PageWrapper>
      <MessagesModule {...props} />
      <Header title={pageTitle} back={true} router={router} />
      {renderPageHero()}
      <Section type="cards" title={i18n.t('Assignment Records')} dataObjectType="user_assignments">
        <DisplayMaxWidthMedium>
          <CardsModule
            type="wide"
            resultsDisplayed={false}
            showPageSizeDropdown={false}
            collection={userAssignments}
          >
            {userAssignment => (
              <OrgUserAssignmentPanel
                userAssignment={userAssignment}
                content={content}
                progressId={progressId}
                onUnassign={setAssignmentToUnassign}
                onReassign={setAssignmentToReassign}
              />
            )}
          </CardsModule>
        </DisplayMaxWidthMedium>
      </Section>
      {renderActionModal()}
    </PageWrapper>
  );
}

OrgUserAssignment.propTypes = {
  contentServiceUrl: PropTypes.string,
  currentTrainingPeriod: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  currentUser: PropTypes.shape({
    full_name: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  currentUserAssignment: PropTypes.shape({
    assignment: PropTypes.shape({
      id: PropTypes.string,
      training_period_id: PropTypes.number
    }),
    content: PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string
    }).isRequired,
    participation_status: PropTypes.string,
    progress_id: PropTypes.string,
    progress_status: PropTypes.string,
    progresses: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string
    }))
  }).isRequired,
  jwt: PropTypes.string,
  loadTrainingPeriodById: PropTypes.func.isRequired,
  onAssignmentUpdate: PropTypes.func.isRequired,
  progressId: PropTypes.string,
  router: PropTypes.object.isRequired,
  userAssignments: PropTypes.array.isRequired
};

export default OrgUserAssignment;
