import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { orgActionCreators, orgBrandingActions } from '../actions';
import i18n from 'lib/i18n';
import { ExplainerText, Section } from 'reactifi';

import OrganizationBrandingFormContainer from './OrganizationBrandingFormContainer';

import mapStateToProps from '../store/organizationMap';

class OrganizationBrandingContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    currentOrganization: PropTypes.object,
    displayError: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    children: PropTypes.array
  };

  static defaultProps = {
    currentOrganization: null
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      { ...orgActionCreators, ...orgBrandingActions },
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.actions.loadDefaultStyleGuide();
  }

  render() {
    const { currentOrganization, children } = this.props;

    return (
      currentOrganization && (
        <>
          <Section>
            <ExplainerText>
              {i18n.t("Customize an organization’s branding by uploading a logo, background image, and brand colors. Note that some branding elements only appear for learners.")}
            </ExplainerText>
          </Section>
          <Section type="list">
            <div className="container-fluid tabbed-nav-content">
              <div className="col-10 col-md-8 org-branding-form-container">
                {children || <OrganizationBrandingFormContainer {...this.props} {...this.actions} />} 
              </div>
            </div>
          </Section>
        </>
      )
    );
  }
}

export default connect(mapStateToProps)(OrganizationBrandingContainer);
