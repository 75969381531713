import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildGenericProps, ProgressSpinner, FloatingLabelFormField } from 'reactifi';
import i18n from 'lib/i18n';

import * as rosterActions from '../actions/rosterActions';

import { PREVIEW } from '../../common/Components/upload/UploadConstants';
import UploadWizard from '../../common/Components/upload/UploadWizard';

import UploadInstructions from '../components/UploadInstructions';

function mapStateToProps(state, ownProps) {

  const props = buildGenericProps(state, 'rosters');

  props.organization_id = ownProps.route.organization_id;
  props.orgCapabilities = ownProps.route.organization_capabilities;
  props.rosterId = ownProps.routeParams.id;

  return props;
}

class UploadContainer extends Component {
  static propTypes = {
    currentEntity: PropTypes.shape({
      name: PropTypes.string
    }),
    dispatch: PropTypes.func.isRequired,
    organization_id: PropTypes.string,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    rosterId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ])
  }

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(rosterActions, this.props.dispatch);

    this.state = {
      wizardData: {}
    };
  }

  componentDidMount() {
    this.actions.selectRoster(this.props.rosterId);
  }

  onWizardDataChange = data => {
    this.setState({ wizardData: data });
  };

  renderFileIntro = () => {
    let headerNumber = 1;

    return (
      <div>
        <h4>{headerNumber++}. {i18n.t("Name Your List")}</h4>
        {i18n.t("Choose a name for the list you are creating to be able to reference it later.")}
        <div className="row">
          <div className="col-sm-6">
            <FloatingLabelFormField
              name="name"
              caption={i18n.t("Name")}
              required={true}
              dataValue={this.state.wizardData.name}
              className="col-half-width"
              onChange={(name) => this.onWizardDataChange({ name })}
            />
          </div>
        </div>
        <h4>{headerNumber++}. {i18n.t("Upload File")}</h4>
        {i18n.t("Upload the list in .CSV format.")}
      </div>
    );
  };

  renderPreviewIntro = () => {
    return (<div>
      {i18n.t('Please review the preview below, only the first five rows are visible.  ' +
        'If the information looks correct click Create List.  ' +
        'Otherwise, click Back and make any changes to your file before uploading again.')}
    </div>);
  };

  preProcessData = data => {
    data.organization_id = this.props.organization_id;
    data.name = this.state.wizardData.name;
    data.status = PREVIEW;
    return data;
  };

  preProcessEntity = entity => {
    entity.organization_id = this.props.organization_id;
    entity.name = this.state.wizardData.name;
    entity.status = "";
    entity.import = true;
    return entity;
  };

  render() {
    if (this.props.currentEntity) {
      return (
        <UploadWizard
          {...this.props}
          {...this.actions}
          data={this.state.wizardData}
          title={i18n.t('Upload a List')}
          finalButtonLabel={i18n.t('Create List')}
          imageClassName="upload-new-users-image"
          selectAction={this.actions.selectRoster}
          updateAction={this.actions.updateRoster}
          createAction={this.actions.createRoster}
          updateAndCloseAction={this.actions.updateRosterAndClose}
          defaultValues={{ name: this.props.currentEntity.name }}
          onDataChange={this.onWizardDataChange}
          instructionsStep={{
            title: i18n.t('Prepare List'),
            component: UploadInstructions
          }}
          fileStep={{
            intro: this.renderFileIntro(),
            acceptTypes: ['.csv', '.xsl', 'application/vnd.ms-excel'],
            preProcessData: this.preProcessData
          }}
          previewStep={{
            title: i18n.t('Preview and Import List'),
            intro: this.renderPreviewIntro(),
            preProcessEntity: this.preProcessEntity
          }}
        />
      );
    } else {
      return <ProgressSpinner/>;
    }
  }
}

export default connect(mapStateToProps)(UploadContainer);
