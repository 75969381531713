import React from 'react';
import PropTypes from 'prop-types';
import { App, PageWrapper } from 'reactifi';
import DownloadsContainer from './containers/DownloadsContainer';

const index = (props, _railsContext) => {
  return (
    <App organizationId={props.organization_id} {..._railsContext}>
      <DownloadsContainer WrapperComponent={PageWrapper} path="/" />
    </App>
  );
}

index.propTypes = {
  organization_id: PropTypes.string
};

export default index;
