import PropTypes from 'prop-types';
import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { buildGenericProps, ProgressSpinner } from "reactifi";
import i18n from "lib/i18n";
import * as importBatchActions from "../actions/importBatchActions";

import UploadWizard from '../../common/Components/upload/UploadWizard';

import UploadFile from '../components/UploadFile';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "import_batches");
  props.importBatchId = ownProps.routeParams.id;
  props.jwt = ownProps.route.jwt;
  props.contentServiceUrl = ownProps.route.contentServiceUrl;
  return props;
}

class UploadContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    contentPackages: PropTypes.array,
    contentServiceUrl: PropTypes.string,
    currentEntity: PropTypes.shape({
      content_package_ids: PropTypes.array
    }),
    importBatchId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    jwt: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(importBatchActions, this.props.dispatch);

    this.state = {
      wizardData: {}
    };
  }

  componentDidMount() {
    this.actions.selectImportBatch(this.props.importBatchId);
  }

  onWizardDataChange = data => {
    this.setState({ wizardData: data });
  };

  render() {
    if (this.props.currentEntity) {
      return (
        <UploadWizard
          {...this.props}
          {...this.actions}
          title={i18n.t('Import an Organization')}
          finalButtonLabel={i18n.t('Import Organization')}
          imageClassName="upload-new-users-image"
          selectAction={this.actions.selectImportBatch}
          createAction={this.actions.createImportBatch}
          defaultValues={{ content_package_ids: this.props.currentEntity.content_package_ids }}
          onDataChange={this.onWizardDataChange}
          fileStep={{
            title: i18n.t('Select Content Packages'),
            component: UploadFile,
            acceptTypes: [".json", "application/json"],
            props: {
              contentServiceUrl: this.props.contentServiceUrl,
              jwt: this.props.jwt
            }
          }}
        />
      );
    } else {
      return <ProgressSpinner/>;
    }
  }
}

export default connect(mapStateToProps)(UploadContainer);
