import { readEntity, clearApiState } from 'reactifi';
import { readEndpoint } from 'redux-json-api';

export function loadBusinessLines() {
  return readEntity('business_lines');
}

export function loadRuleSets(organizationId) {
  return readEntity('rule_sets', '', organizationId ? { organization_id: organizationId } : undefined);
}

export function selectOrgCapabilities(organizationId) {
  return async dispatch => {
    dispatch(clearApiState('capabilities'));
    await dispatch(readEndpoint(`organizations/${organizationId}/capabilities`));
  }
}
