import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { AccessControlUtil, Button, IconCategoryValue, ConstructionCard, Display50Pct50Pct, ExpandableContent, DisplayFlexColumns } from 'reactifi';
import ORG_TYPES from '../../constants/organizationTypes';

const OrgDetailsCard = props => {
  const {
    currentOrganization,
    copyLoginPageUrl,
    permissions,
    reports,
    showEditOrgDetailsModal,
    viewer
  } = props;
  const [generalReports, setGeneralReports] = useState([]);
  const [limitedReports, setLimitedReports] = useState([]);
  const [suppressedReports, setSuppressedReports] = useState([]);

  useEffect(() => {
    const suppressedReportData = reports.filter(rep => rep?.suppress_organization_ids?.includes(currentOrganization.id));
    const suppressedIds = suppressedReportData.map(rep => rep.id);
    const generalReportData = reports.filter(rep => !rep?.organization_ids?.length && !suppressedIds.includes(rep.id));
    const limitedReportData = reports.filter(rep => rep?.organization_ids?.length && !suppressedIds.includes(rep.id));
    setGeneralReports(generalReportData);
    setLimitedReports(limitedReportData);
    setSuppressedReports(suppressedReportData);
  }, [reports]);

  const getGeneralReports = () => {
    return (
      <>
        <h5 data-object-attribute="org-general-reports">{i18n.t("General Availability")}</h5>
        {generalReports.map(report => <div data-object-type="charts" data-object-id={`general-report-${report.id}`}>{report.name}</div>)}
      </>
    )
  }

  const getLimitedReports = () => {
    return (
      <>
        <h5 data-object-attribute="org-limited-reports">{i18n.t("Limited Availability")}</h5>
        {limitedReports.map(report => <div data-object-type="charts" data-object-id={`limited-report-${report.id}`}>{report.name}</div>)}
      </>
    )
  }

  const getSuppressedReports = () => {
    return (
      <>
        <h5 data-object-attribute="org-suppressed-reports">{i18n.t("Suppress Availability")}</h5>
        {suppressedReports.map(report => <div data-object-type="charts" data-object-id={`limited-report-${report.id}`}>{report.name}</div>)}
      </>
    )
  };

  const buttonConfig = [
    {
      label: i18n.t('Edit Details'),
      dataAction: "edit-details",
      style: 'secondary',
      action: showEditOrgDetailsModal
    }
  ];

  const hasManagePermission = AccessControlUtil.hasPermission('manage_organization_details', permissions);
  const hasSetOrgTypePermission = AccessControlUtil.hasPermission('set_organization_type', permissions);
  const orgType = ORG_TYPES.find(type => type.id === currentOrganization.organization_type_id)?.label || i18n.t('Other');

  return (
    <ConstructionCard
      alwaysExpanded={true}
      buttonConfig={hasManagePermission ? buttonConfig : null}
      className="m-b-30"
      cardContentClasses="m-t-30"
      title={i18n.t('Organization Details')}>
      <Display50Pct50Pct>
        <div className="settings-org-details-container">
          <IconCategoryValue
            category={i18n.t("Name")}
            value={currentOrganization.name}
            className="org-details-item"
          />
          {hasSetOrgTypePermission && (
            <IconCategoryValue
              category={i18n.t("Type")}
              value={orgType}
            />
          )}
          {currentOrganization.sender_alias && (
            <IconCategoryValue
              category={i18n.t("Sender Alias")}
              value={currentOrganization.sender_alias}
            />
          )}
          <IconCategoryValue
            category={i18n.t("ID")}
            value={currentOrganization.short_id}
          />
          <IconCategoryValue
            category={i18n.t("Unique ID")}
            value={currentOrganization.uuid}
          />
          <IconCategoryValue
            category={i18n.t("SF ID")}
            value={currentOrganization.salesforce_id || '-'}
          />
          <IconCategoryValue
            category={i18n.t("BB SF ID")}
            value={currentOrganization.bb_salesforce_id || '-'}
          />
          <IconCategoryValue
            category={i18n.t("Legal Entity ID")}
            value={currentOrganization.bb_legal_entity_id || '-'}
          />
          <div className="settings-secondary-org-setting m-t-20">
            <IconCategoryValue
              category={i18n.t("Slug")}
              value={currentOrganization.slug}
            />
            <IconCategoryValue
              category={i18n.t("Active?")}
              value={currentOrganization.active ? i18n.t('Yes') : i18n.t('No')}
            />
            {viewer === "admin" && (
              <IconCategoryValue
                category={i18n.t("Demo Account?")}
                value={currentOrganization.demo ? i18n.t('Yes') : i18n.t('No')}
              />
            )}
          </div>
        </div>
        <div>
          {currentOrganization.cportal_url && (
            <div className="org-details-learner-section">
              <Button
                data-action="copy-customer-login-url"
                icon="copy"
                label={i18n.t("Copy Customer Login URL")}
                onClick={copyLoginPageUrl}
                style="link"
              />
            </div>
          )}
        </div>

      </Display50Pct50Pct>
      <ExpandableContent title={i18n.t("Reports")} data={currentOrganization} dataAttributes={{ action: 'toggle-organization-reports' }} >
        <div>
          <DisplayFlexColumns>
            {getGeneralReports()}
            {getLimitedReports()}
            {getSuppressedReports()}
          </DisplayFlexColumns>
        </div>
      </ExpandableContent>
    </ConstructionCard>
  );
};

OrgDetailsCard.propTypes = {
  copyLoginPageUrl: PropTypes.func,
  currentOrganization: PropTypes.object,
  permissions: PropTypes.array,
  reports: PropTypes.array,
  showEditOrgDetailsModal: PropTypes.func,
  viewer: PropTypes.string
};

export default OrgDetailsCard;
