import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  CopyUtil,
  FieldGroup,
  FloatingLabelFormField,
  GenericModal,
  FAIcon
} from 'reactifi';
import i18n from "lib/i18n";

export default class SAMLModal extends Component {
  static propTypes = {
    downloadCertificate: PropTypes.string,
    downloadUrl: PropTypes.string,
    info: PropTypes.object,
    showSamlModal: PropTypes.bool,
    toggleSamlModal: PropTypes.func
  }

  copyToClipboard = value => {
    CopyUtil.copyText(value, document.getElementById('saml-modal'));
  };

  renderMetadataFields = () => {
    const { downloadCertificate, info } = this.props;
    if (!info) return null;

    const metadataFields = {
      'entity_id': i18n.t('Entity ID'),
      'assertion_consumer_service_url': i18n.t('ACS URL'),
      'certificate_algorithm': i18n.t('Certificate Algorithm'),
      'single_logout_service_url': i18n.t('SLO URL'),
      'certificate': i18n.t('Encryption Certificate')
    };

    return (
      <FieldGroup data={info}>
        {
          Object.keys(metadataFields).map(metadataId => {
            return (
              <FieldGroup className="row">
                <FieldGroup className="col-9">
                  <FloatingLabelFormField
                    disabled={true}
                    id={metadataId}
                    name={metadataId}
                    caption={metadataFields[metadataId]}
                  />
                </FieldGroup>
                <FieldGroup className="col-3">
                  <Button
                    style="secondary"
                    className="m-t-5"
                    onClick={() => this.copyToClipboard(info[metadataId])}
                  >
                    <FAIcon icon="copy" additionalClasses="m-l-0 m-r-5" />
                    {i18n.t('Copy')}
                  </Button>
                </FieldGroup>
              </FieldGroup>
            );
          })
        }

        <FieldGroup>
          <a href={downloadCertificate} download={true} id="saml-modal-download-cert-button">
            {i18n.t('Download encryption certificate')}
          </a>
        </FieldGroup>
      </FieldGroup>
    )
  }

  getCloseButton = () => {
    return {
      style: "primary",
      isTurnedOn: true,
      text: i18n.t('Close')
    };
  }



  render() {
    const { downloadUrl, showSamlModal, toggleSamlModal } = this.props;

    return (
      <GenericModal
        canClose={true}
        footerCloseButton={this.getCloseButton()}
        stickyFooter={true}
        id="saml-modal"
        onClose={toggleSamlModal}
        show={showSamlModal}
        size="large"
        title={i18n.t('EVERFI Metadata for IdPs')}
      >
        <p>{i18n.t("Here is EVERFI's metadata, which you may need to configure your Identity Provider.")}</p>
        <Button
          style="primary"
          download={true}
          href={downloadUrl}
          id="saml-modal-download-button"
        >
          {i18n.t('Download Full Metadata')}
        </Button>
        <h4>{i18n.t('OR')}</h4>
        {this.renderMetadataFields()}
      </GenericModal>
    );
  }
}
