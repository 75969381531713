import PropTypes from 'prop-types';
import React, { Component } from "react";
import { Util } from "@everfi/reactifi";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  buildGenericProps,
  getPageSize
} from "reactifi";
import i18n from "lib/i18n";
import * as importBatchActionCreators from "../actions/importBatchActions";
import UploadList from '../../common/Components/upload/UploadList';
import { FAILED, DONE } from '../../common/Components/upload/UploadConstants';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "import_batches");
  props.listMeta = state.api.import_batches
    ? state.api.import_batches.meta
    : null;

  return props;
}

class ListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    import_batches: PropTypes.array,
    isLoadingImportBatches: PropTypes.bool,
    listMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    router: PropTypes.object,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(
      importBatchActionCreators,
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.actions.findImportBatches("", { number: 1, size: getPageSize() });
  }

  get fields() {
    return [
      {
        name: "media_name",
        title: i18n.t("File name"),
        canSort: true,
        formatter: (cell, row) => {
          return <span className="col-text">&nbsp;{cell}</span>;
        }
      },
      {
        name: "files",
        title: i18n.t("Files"),
        canSort: false,
        formatter: (cell, row) => {
          if ((row.status !== DONE && row.status !== FAILED) || !row.summary.errors.length) {
            return null;
          }

          const urlFailure = `/api/data/import_batches/${row.id
            }/error_file.txt`;
          return (
            <div>
              <a href={urlFailure}>{i18n.t("Failed")}</a>
            </div>
          );
        }
      },
      {
        name: "completion_percentage",
        title: i18n.t("% Complete"),
        canSort: false
      },
      {
        name: 'updated_at', title: i18n.t('Upload'), canSort: true, formatter: (cell, row) => {
          return Util.format(cell, 'P')
        }
      }
    ];
  }

  render() {
    const { router, errorMessage, successMessage, showAlertMessage,
      listMeta, import_batches, isLoadingImportBatches } = this.props;
    return (
      <UploadList
        title={i18n.t('Upload Organizations')}
        uploadLabel={i18n.t('New Upload')}
        findAction={this.actions.findImportBatches}
        fields={this.fields}
        router={router}
        errorMessage={errorMessage}
        successMessage={successMessage}
        clearMessages={this.actions.clearMessages}
        showAlertMessage={showAlertMessage}
        list={import_batches}
        listMeta={listMeta}
        isLoading={isLoadingImportBatches}
      />
    );
  }
}

export default connect(mapStateToProps)(ListContainer);
