import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { ApiUtil } from 'reactifi';

import Page from "../components/userDetails/Page";
import mapStateToProps from '../store/userDetailsMap';
import { orgUsersActionCreators, orgActionCreators } from '../actions';

class OrganizationUserDetailsContainer extends Component {
  static propTypes = {
    certificateServiceUrl: PropTypes.string,
    currentUserId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    jwt: PropTypes.string.isRequired,
    organizationId: PropTypes.string,
    organizationName: PropTypes.string,
    organizationTitle: PropTypes.string,
    scormContexts: PropTypes.array,
    user: PropTypes.shape({
      business_lines: PropTypes.arrayOf(PropTypes.string),
      teams_as_member_ids: PropTypes.arrayOf(PropTypes.string)
    }),
    userBusinessLines: PropTypes.array,
    userIsPrimaryManager: PropTypes.bool,
    userIsSecondaryManager: PropTypes.bool,
    userManagedBusinessLines: PropTypes.arrayOf(PropTypes.string),
    userManagedTeams: PropTypes.arrayOf(PropTypes.string),
    userIsTeamManager: PropTypes.arrayOf(PropTypes.string),
    viewer: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      currentViewer: null
    };

    this.actions = bindActionCreators(
      { ...orgActionCreators, ...orgUsersActionCreators },
      props.dispatch
    );
  }

  async componentDidMount() {
    const { certificateServiceUrl, currentUserId, jwt, organizationId, organizationTitle, userBusinessLines, viewer } = this.props;

    this.actions.clearMessages();
    this.actions.selectCurrentUser(currentUserId);
    this.actions.loadBusinessLines();
    this.actions.loadTrainingPeriods(organizationId);
    this.actions.loadRuleSets(organizationId, userBusinessLines);
    this.actions.loadOrgRuleSets(organizationId);
    this.actions.loadCategories({
      organization_id: organizationId
    });
    this.actions.selectCategoryLabelUser();
    this.actions.readCategoryLabelUsers({
      user_id: currentUserId
    });
    if (!organizationTitle) {
      this.actions.findOrganization(organizationId);
    }
    let currentViewer = null;
    if (viewer !== 'admin') {
      currentViewer = await ApiUtil.loadExternalData('/api/data/users/me', jwt);
    }

    if ((!currentViewer?.['is_scorm_manager?'] || viewer === 'admin') && certificateServiceUrl) {
      await this.actions.getCertificates(certificateServiceUrl, jwt, currentUserId);
    }
    this.setState({ currentViewer });
    this.selectUserDetails();
  }

  componentWillUnmount() {
    this.actions.clearMessages();
    this.actions.clearUserAssignments();
  }

  isUserSecondaryManager = () => {
    return !this.props.userIsPrimaryManager && this.props.userIsSecondaryManager;
  }

  isUserInSameBusinessLine = () => {
    const { userManagedBusinessLines, user: { business_lines } = {} } = this.props;
    return userManagedBusinessLines && business_lines && userManagedBusinessLines.some(bl => business_lines.includes(bl));
  }

  isUserInManagedTeam = () => {
    const { userManagedTeams, user: { teams_as_member_ids } = {} } = this.props;
    return userManagedTeams && teams_as_member_ids && userManagedTeams.some(team => teams_as_member_ids.includes(team));
  }

  selectUserDetails = () => {
    return this.actions.selectUserDetails(
      this.props.currentUserId,
      this.props.organizationId,
      this.props.viewer
    );
  }

  deselectUser = () => {
    this.actions.loadUser(null);
  }

  showUnlimitedAccess = () => {
    // FIT Administrators -> Show
    // Primary Managers -> Show if user is in the same LOB
    // Secondary Managers -> Show if user is in the same LOB and team
    return this.props.viewer === 'admin' ||
      (this.props.userIsPrimaryManager && this.isUserInSameBusinessLine()) ||
      (this.isUserSecondaryManager() && this.isUserInManagedTeam())
  }

  render() {
    const {
      viewer
    } = this.props;

    return (
      <Page
        {...this.actions}
        {...this.props}
        currentViewer={this.state.currentViewer}
        isFitAdmin={viewer === 'admin'}
        isUserInManagedTeam={this.isUserInManagedTeam()}
        isUserInSameBusinessLine={this.isUserInSameBusinessLine()}
        isUserSecondaryManager={this.isUserSecondaryManager()}
        deselectUser={this.deselectUser}
        selectUserDetails={this.selectUserDetails}
        unlimitedAccess={this.showUnlimitedAccess()}
      />
    );
  }
}

export default connect(mapStateToProps)(OrganizationUserDetailsContainer);
