import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { FieldGroup, FloatingLabelFormField, SelectControl } from 'reactifi';
import i18n from 'lib/i18n';

export default class OrganizationBenchmarkEntry extends Component {
  static propTypes = {
    benchmarkCategories: PropTypes.array.isRequired,
    data: PropTypes.object,
    onValueChange: PropTypes.func
  };

  benchmarkGroups = () => {
    if (!this.props.data.benchmark_category_id) {
      return [];
    }
    const selectedCategory = this.props.benchmarkCategories.find(category => category.id === this.props.data.benchmark_category_id);
    return selectedCategory ? selectedCategory.benchmark_groups : [];
  };

  render() {
    return (
      <FieldGroup data={this.props.data} onValueChange={this.props.onValueChange}>
        <FieldGroup className="row">
          <FieldGroup className="col-6">
            <FloatingLabelFormField
              caption={i18n.t('Benchmark Attribute')}
              name="benchmark_category_id"
              required={true}
            >
              <SelectControl
                labelKey="name"
                multi={false}
                name="benchmark_category_id"
                options={this.props.benchmarkCategories}
                valueKey="id"
              />
            </FloatingLabelFormField>
          </FieldGroup>
          <FieldGroup className="col-6">
            <FloatingLabelFormField
              caption={i18n.t('Value')}
              disabled={!this.props.data.benchmark_category_id}
              name="benchmark_group_id"
              required={true}
            >
              <SelectControl
                clearable={true}
                labelKey="name"
                multi={false}
                name="benchmark_group_id"
                options={this.benchmarkGroups()}
                valueKey="id"
                valueOverride={true}
              />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
      </FieldGroup>
    );
  }
}
