import { createEntity, readEntity, updateEntity, selectEntity, deleteEntity,
  readLocal, displaySuccessMessage, displayErrorMessage } from 'reactifi';
export { displaySuccessMessage, displayErrorMessage } from 'reactifi';
import i18n from 'lib/i18n';

export function selectApplication(applicationId) {
  return selectEntity(applicationId, 'applications');
}

export function loadApplications() {
  return readEntity('applications');
}

export function createApplication(application) {
  return createEntity(application, (dispatch) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null, 'applications'));
  }, "Application created successfully");
}

export function updateApplication(application) {
  return updateEntity(application, (dispatch) => {
    dispatch(selectEntity(null, 'applications'));
  }, "Application updated successfully");
}

export function deleteApplication(application) {
  return deleteEntity(application, 'applications', i18n.t('Application deleted successfully.'))
}

export function regenerateSecret(application) {
  return async function (dispatch) {
    try {
      const response = await fetch(`/api/data/doorkeeper/applications/${application.id}/regenerate`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify(application.unwrap())
      })
      const data = await response.json();
      dispatch(readLocal(data));
      dispatch(displaySuccessMessage(i18n.t("Secret regenerated.")));
    } catch (e) {
      dispatch(displayErrorMessage(i18n.t("Unable to regenerate secret.")));
    }
  };
}
