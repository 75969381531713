import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Page, Wizard } from 'reactifi';
import UploadStep from './UploadStep';
import UploadFileStep from './UploadFileStep';
import UploadPreviewStep from './UploadPreviewStep';
import isEqual from 'lodash/isEqual';

export default class UploadWizard extends Component {
  static propTypes = {
    title: PropTypes.string.isRequired,
    finalButtonLabel: PropTypes.string.isRequired,
    imageClassName: PropTypes.string,
    instructionsStep: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.object,
      component: PropTypes.func,
      props: PropTypes.object
    }),
    fileStep: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.object,
      acceptTypes: PropTypes.any.isRequired,
      component: PropTypes.func,
      preProcessData: PropTypes.func,
      props: PropTypes.object
    }),
    previewStep: PropTypes.shape({
      title: PropTypes.string.isRequired,
      intro: PropTypes.object,
      component: PropTypes.func,
      preProcessEntity: PropTypes.func,
      props: PropTypes.object
    }),
    defaultValues: PropTypes.object,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string,
    clearMessages: PropTypes.func,
    displayError: PropTypes.func,
    displayErrorMessage: PropTypes.func,
    displaySuccessMessage: PropTypes.func,
    dispatch: PropTypes.func.isRequired,
    currentEntity: PropTypes.object,
    router: PropTypes.object.isRequired,
    updateAction: PropTypes.func,
    createAction: PropTypes.func.isRequired,
    selectAction: PropTypes.func.isRequired,
    updateAndCloseAction: PropTypes.func,
    onDataChange: PropTypes.func,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    backButton: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.func
    ]),
    replaceRouteOnEntitySelection: PropTypes.bool,
    onConfirmation: PropTypes.func,
    data: PropTypes.object
  };

  static defaultProps = {
    replaceRouteOnEntitySelection: true
  };

  constructor(props) {
    super(props);

    this.state = {
      data: Object.assign({}, props.defaultValues)
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.replaceRouteOnEntitySelection && !prevProps.currentEntity?.id && this.props.currentEntity?.id) {
      this.props.router.replace(`/${this.props.currentEntity.id}`);
    }
    if (!isEqual(this.props.data, prevProps.data)) {
      this.setState({ data: this.props.data });
    }
  }

  onCancel = () => {
    this.props.selectAction(null);
    this.props.router.replace("/");
  };

  onUpdateData = data => {
    this.setState({ data });
    if (typeof this.props.onDataChange === 'function') {
      this.props.onDataChange(data);
    }
  };

  onConfirmation = () => {
    this.props.selectAction(null);
    if (this.props.onConfirmation) {
      this.props.onConfirmation();
    } else {
      this.props.router.replace("/confirmation");
    }
  }

  getInstructionsStep = () => {
    const { instructionsStep, displaySuccessMessage, displayErrorMessage, dispatch } = this.props;
    const UploadInstructions = instructionsStep && instructionsStep.component || UploadStep;

    if (!instructionsStep) {
      return null;
    }

    return <UploadInstructions
      {...instructionsStep.props}
      title={instructionsStep.title}
      intro={instructionsStep.intro}
      displaySuccessMessage={displaySuccessMessage}
      displayErrorMessage={displayErrorMessage}
      dispatch={dispatch}
      onUpdateData={this.onUpdateData}
      data={this.state.data}
      orgCapabilities={this.props.orgCapabilities}
    />
  };

  getFileStep = () => {
    const {
      fileStep, previewStep, currentEntity,
      displaySuccessMessage, displayError, displayErrorMessage, dispatch,
      selectAction, createAction, updateAction
    } = this.props;
    const UploadFile = fileStep && fileStep.component || UploadFileStep;

    return <UploadFile
      {...fileStep.props}
      title={fileStep.title}
      intro={fileStep.intro}
      acceptTypes={fileStep.acceptTypes}
      preProcessData={fileStep.preProcessData}
      currentEntity={currentEntity}
      displaySuccessMessage={displaySuccessMessage}
      displayErrorMessage={displayErrorMessage}
      displayError={displayError}
      dispatch={dispatch}
      onUpdateData={this.onUpdateData}
      data={this.state.data}
      selectAction={selectAction}
      createAction={createAction}
      updateAction={updateAction}
      closeOnNext={!previewStep}
      onClose={this.onCancel}
    />
  };

  getPreviewStep = () => {
    const {
      previewStep, currentEntity,
      displaySuccessMessage, displayErrorMessage, dispatch,
      selectAction, updateAndCloseAction
    } = this.props;
    const UploadPreview = previewStep && previewStep.component || UploadPreviewStep;

    if (!previewStep) {
      return null;
    }

    return <UploadPreview
      {...previewStep.props}
      title={previewStep.title}
      intro={previewStep.intro}
      preProcessEntity={previewStep.preProcessEntity}
      currentEntity={currentEntity}
      displaySuccessMessage={displaySuccessMessage}
      displayErrorMessage={displayErrorMessage}
      dispatch={dispatch}
      onUpdateData={this.onUpdateData}
      data={this.state.data}
      selectAction={selectAction}
      updateAndCloseAction={updateAndCloseAction}
      onConfirmation={this.onConfirmation}
    />
  };

  getWizardSteps = () => {
    const steps = [
      this.getInstructionsStep(),
      this.getFileStep(),
      this.getPreviewStep()
    ].filter(step => !!step);

    return steps;
  };

  renderImage = () => {
    const { imageClassName } = this.props;
    if (!imageClassName) {
      return null;
    }
    return (<div className="row">
      <div className="col-12">
        <div className={`mx-auto ${imageClassName}`} />
      </div>
    </div>);
  };

  render() {
    const { title, finalButtonLabel, currentEntity, successMessage, errorMessage, clearMessages, backButton, router } = this.props;
    const steps = this.getWizardSteps();

    return (
      <Page
        pageType="child form"
        contentType="tabs upload-wizard"
        title={title}
        successMessage={successMessage}
        errorMessage={errorMessage}
        clearMessages={clearMessages}
        backButton={backButton || router.goBack}
        usePageWrapper={true}
      >
        { this.renderImage() }
        <Wizard onCancel={this.onCancel}
          initialStep={currentEntity.id ? steps.length - 1 : 0}
          finalButtonLabel={finalButtonLabel}
          children={steps} />
      </Page>
    );
  }
}
