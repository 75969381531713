import React from 'react';
import { App } from 'reactifi';
import OnboardingChecklistContainer from './containers/OnboardingChecklistContainer';

const index = (props, _railsContext) => {
  return (
    <App {..._railsContext}>
      <OnboardingChecklistContainer
        path="/"
        checklistId={props.checklist_id}
        currentUser={props.current_user}
        contentServiceUrl={_railsContext.contentServiceUrl}
        notifiUrl={_railsContext.notifiUrl}
      />
    </App>
  );
};

export default index;
