import { useConfirmAction } from 'reactifi';
import i18n from "lib/i18n";

const useDeactivateUserConfirmAction = (action) => {
  return useConfirmAction({
    action,
    children: i18n.t('Are you sure you want to deactivate the selected user?'),
    confirmButtonText: i18n.t('Deactivate User'),
    title: i18n.t('Deactivate User?')
  })
};

export default useDeactivateUserConfirmAction;
