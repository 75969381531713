import PropTypes from 'prop-types';
import React from 'react';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import createStore from './store/identityProvidersStore';

import IdentityProvidersContainer from './containers/IdentityProvidersContainer';
import IdentityProviderContainer from './containers/IdentityProviderContainer';


let index = (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);

  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/add" {...props} component={IdentityProviderContainer} />
        <Route path="/:id" {...props} component={IdentityProviderContainer} />
        <Route path="/" {...props} component={IdentityProvidersContainer} />
      </Router>
    </RootProvider>
  );
}

index.propTypes = {
  organization_capabilities: PropTypes.arrayOf(PropTypes.string),
  organization_id: PropTypes.string
}

export default index;
