import React from 'react';
import PropTypes from 'prop-types';
import { FloatingLabelFormField, SelectControl, Button } from 'reactifi';
import i18n from 'lib/i18n';

import UploadStep from '../../common/Components/upload/UploadStep';

export default class OrgUploadRolesInstructionsStep extends UploadStep {
  static propTypes = {
    organizationId: PropTypes.string,
    ruleSets: PropTypes.array,
    displayErrorMessage: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      roleToAdd: null,
      roleToRemove: null
    }
  }

  componentDidUpdate(prevProps){
    if (!prevProps.data.oldRole && this.props.ruleSets?.length === 1) {
      const oldRole = this.props.ruleSets[0].name;
      this.updateData({ oldRole });
    }
  }

  goForward = () => {
    const { data } = this.props;
    if (!data.oldRole && !data.newRole){
      this.props.displayErrorMessage(i18n.t('Please select roles to add or remove.'));
      return false;
    } else {
      return true;
    }
  };

  onOldRoleChange = oldRole => {
    const roleToRemove = oldRole && oldRole.name;
    this.updateData({ oldRole: roleToRemove, newRole: this.state.roleToAdd });
    this.setState({ roleToRemove });
  }

  onNewRoleChange = newRole => {
    const roleToAdd = newRole && newRole.name;
    this.updateData({ newRole: roleToAdd, oldRole: this.state.roleToRemove });
    this.setState({ roleToAdd });
  }

  getTemplateUrl = () => {
    const { data, organizationId } = this.props;

    if (!data || !data.newRole) {
      return '';
    }

    return `/api/data/rule_sets/sample.csv?organization_id=${organizationId}&rule_set=${data.newRole}&differentiated=true&mode=update_rule_sets`;
  }

  renderBody = () => {
    const { ruleSets } = this.props;
    const hasMultipleRuleSets = ruleSets && ruleSets.length !== 1;

    return (
      <div>
        <div>
          {i18n.t('Use this user role update tool when you have a list of users you want to change from one role to another, for example, Faculty/Staff Learner to Higher Ed Learner.')}
        </div>
        {hasMultipleRuleSets && (
          <div className="row m-t-20">
            <div className="col-12">
              {i18n.t('What role would you like to remove?')}
            </div>
            <div className="col-12">
              <FloatingLabelFormField
                name="unregistration_rule_sets"
                caption={i18n.t('Old Role')}
                className="col-half-width"
              >
                <SelectControl
                  options={ruleSets.filter(rs => rs.name !== this.state.roleToAdd)}
                  multi={false}
                  valueKey="name"
                  labelKey="label"
                  onChange={this.onOldRoleChange}
                />
              </FloatingLabelFormField>
            </div>
          </div>
        )}
        <div className={`row ${hasMultipleRuleSets ? '' : 'm-t-20'}`}>
          <div className="col-12">
            {i18n.t('What role would you like to add?')}
          </div>
          <div className="col-12">
            <FloatingLabelFormField
              name="registration_rule_sets"
              caption={i18n.t('New Role')}
              className="col-half-width"
            >
              <SelectControl
                options={ruleSets.filter(rs => rs.name !== this.state.roleToRemove)}
                multi={false}
                valueKey="name"
                labelKey="label"
                onChange={this.onNewRoleChange}
              />
            </FloatingLabelFormField>
          </div>
        </div>
        <div>
          {i18n.t('Download the User List Template to prepare the list of users who will be assigned a new role. You will only need their name and email. Make sure you save your file as a .csv.')}
        </div>
        <div className="row">
          <div className="col-12">
            <Button
              label={i18n.t('Download Template')}
              icon="down-to-line"
              style="secondary"
              href={this.getTemplateUrl()}
              data-action="download-template"
              className="template-button"
              download={true}
              disabled={!this.props.data || !this.props.data.newRole}
            />
          </div>
        </div>
      </div>
    );
  };
}
