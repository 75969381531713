import React from "react";
import PropTypes from "prop-types";
import { ConfirmationModal } from "reactifi";
import i18n from "lib/i18n";

export default class DownloadModal extends React.Component {
  static propTypes = {
    dataExport: PropTypes.shape({
      download_url: PropTypes.string,
      name: PropTypes.string
    }),
    onClose: PropTypes.func.isRequired
  }

  downloadDataExport = () => {
    const { dataExport, onClose } = this.props;

    // create an anchor tag to initiaze the download
    const downloadLink = document.createElement('a');
    downloadLink.href = dataExport.download_url;
    downloadLink.download = dataExport.name;
    downloadLink.target = "_blank";
    downloadLink.rel = 'noopener noreferrer';
    downloadLink.click();

    onClose();
  }

  render() {
    const { dataExport, onClose } = this.props;

    if (!dataExport) {
      return null;
    }

    return (<ConfirmationModal
      title={i18n.t("Data Terms of Use")}
      show={true}
      onConfirm={this.downloadDataExport}
      onCancel={onClose}
      confirmButtonText={i18n.t("Agree")}
    >
      <div className="m-b-15">
        {i18n.t("By initiating this download I take responsibility to store and share this data securely and to share it within my organization only. To protect the privacy of the respondents, this data does not contain personally identifiable information (PII). You agree that you shall not make any attempt to identify individual respondents.")}
      </div>
      <div>
        {i18n.t("EVERFI reserves the right to review any content that references EVERFI data prior to publication.")}
      </div>
    </ConfirmationModal>);
  }
}
