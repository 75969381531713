import React from "react";
import PropTypes from "prop-types";
import { IconCategoryValue, IconCategoryValueGroup } from "reactifi";
import i18n from "lib/i18n";
import OrgUserDetails from "../OrgUserDetails";

const INLINE_VALUE_CONFIG = Object.freeze([
  { label: i18n.t('Alternative Login'), key: 'username' },
  { label: i18n.t('Employee ID'), key: 'employee_id' },
  { label: i18n.t('Student ID'), key: 'student_id' },
  { label: i18n.t('SSO ID'), key: 'sso_id' }
]);

const HeroColumn1 = props => {
  const { user = {} } = props;

  let email = user.email || user.parent_email;
  if (email) {
    email = <a href={`mailto:${email}`} target="_blank" rel="noopener noreferrer">{email}</a>;
  } else {
    email = i18n.t('N/A');
  }
  return (
    <>
      <IconCategoryValueGroup>
        <IconCategoryValue icon="envelope" value={email} />
        {INLINE_VALUE_CONFIG.map(config => {
          const value = user[config.key];
          return value && <IconCategoryValue key={config.key} category={config.label} value={value} />
        })}
      </IconCategoryValueGroup>
      <OrgUserDetails {...props} target="details" />
    </>
  );
};

HeroColumn1.propTypes = {
  user: PropTypes.shape({
    email: PropTypes.string,
    employee_id: PropTypes.string,
    parent_email: PropTypes.string,
    sso_id: PropTypes.string,
    student_id: PropTypes.string,
    username: PropTypes.string
  })
};

export default HeroColumn1;
