import React from "react";
import PropTypes from "prop-types";
import i18n from "lib/i18n";
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { FieldGroup, FloatingLabelFormField, ConfirmationModal, InlineNotification, CopyUtil, Button, FAIcon } from 'reactifi';
import { Util } from "@everfi/reactifi";
import isEqual from 'lodash/isEqual';

import WebhookForm from "./WebhookForm";
import WebhookList from "./WebhookList";

const FAKE_VERIFICATION_TOKEN = '••••••••••••••••••••••••••••••';
const FAKE_SECRET_TOKEN = '••••••••••••••••••••••••••••••';

export default class WebhookDomainItem extends React.Component {
  static propTypes = {
    currentWebhook: PropTypes.object,
    deleteDomain: PropTypes.func.isRequired,
    displaySuccessMessage: PropTypes.func.isRequired,
    displayErrorMessage: PropTypes.func.isRequired,
    domain: PropTypes.object.isRequired,
    regenerateVerificationToken: PropTypes.func.isRequired,
    regenerateSecretToken: PropTypes.func.isRequired,
    secret_token: PropTypes.string,
    verification_token: PropTypes.string,
    verifyDomain: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false,
      showRegenerateModal: false,
      verification_token: null,
      secret_token: null
    }
  }

  componentDidUpdate(prevProps) {
    const { domain } = this.props;
    if (domain && !isEqual(domain, prevProps.domain)) {
      if (domain.verification_token || domain.secret_token) {
        this.setState({
          verification_token: domain.verification_token || this.state.verification_token,
          secret_token: domain.secret_token || this.state.secret_token
        })
      }
    }
  }

  copyToClipboard = (value, container) => {
    const success = container ? CopyUtil.copyText(value, document.querySelector('#form-modal')) : CopyUtil.copyText(value);
    if (success) {
      this.props.displaySuccessMessage(i18n.t("Copied!"));
    } else {
      this.props.displayErrorMessage(i18n.t("Unable to copy"));
    }
  }

  onDeleteDomain = () => this.setState({ showDeleteModal: true });

  onCancelDeleteDomain = () => this.setState({ showDeleteModal: false });

  onConfirmDeleteDomain = () => {
    this.props.deleteDomain(this.props.domain);
    this.setState({ showDeleteModal: false });
  }

  renderDeleteButton = () => {
    const { domain } = this.props;
    const { webhooks } = domain;
    const canDelete = !webhooks || webhooks.length === 0;

    const button = (
      <Button
        onClick={() => canDelete && this.onDeleteDomain()}
        data-action="delete-webhook"
        style="secondary"
        label={i18n.t("Delete")}
        icon="trash"
      />
    );

    if (canDelete) return button;

    const tooltip = (
      <Tooltip id={`tooltip-delete-${domain.id}`}>
        {i18n.t('You cannot delete a domain with webhooks in place. You must remove all the webhooks before deleting it.')}
      </Tooltip>
    );

    return (
      <OverlayTrigger placement="left" overlay={tooltip}>
        {button}
      </OverlayTrigger>
    );
  }

  renderVerificationIcon = () => {
    const { domain: { verified } } = this.props;
    const iconVerifiedClasses = verified ?
      'shield-check' :
      'triangle-exclamation';
    const iconColor = verified ?
      'green' : 'yellow'

    return (<OverlayTrigger
      placement="top"
      overlay={verified ? this.renderVerifiedTooltip() : this.renderUnverifiedTooltip()}
    >
      <FAIcon icon={iconVerifiedClasses} color={iconColor} />
    </OverlayTrigger>);
  }

  renderVerifiedTooltip = () => {
    const { domain } = this.props;
    return (<Tooltip id={`tooltip-verified-${domain.id}`}>
      {i18n.t('Domain last verified on {{ date }}', { date: Util.format(domain.verified_at, 'P') })}
    </Tooltip>);
  }

  renderUnverifiedTooltip = () => {
    const { domain } = this.props;
    return (<Tooltip id={`tooltip-unverified-${domain.id}`}>
      {i18n.t('Domain has not been verified or cannot be verified')}
    </Tooltip>);
  }

  regenerateSecret = (domain, tokenType) => {
    this.setState({ showRegenerateModal: true, tokenType, domainToRegenerate: domain })
  }

  confirmRegenerateSecret = async () => {
    const { tokenType, domainToRegenerate } = this.state;
    const { regenerateVerificationToken, regenerateSecretToken } = this.props;
    if (tokenType === 'verification') {
      await regenerateVerificationToken(domainToRegenerate);
    } else if (tokenType === 'secret') {
      await regenerateSecretToken(domainToRegenerate);
    }
    this.cancelRegenerateSecret();
  }

  cancelRegenerateSecret = () => {
    this.setState({ showRegenerateModal: false, tokenType: null, domainToRegenerate: null })
  }

  getTitleText = () => {
    const { tokenType, domainToRegenerate } = this.state;
    let objectType = tokenType === 'secret' ? 'domain secret token' : 'domain verification token';
    return i18n.t("Regenerate the %s for %s", { postProcess: 'sprintf', sprintf: [objectType, domainToRegenerate.name] })
  }

  getWarningText = () => {
    const { tokenType } = this.state;
    let objectType = tokenType === 'secret' ? 'domain secret token' : 'domain verification token';
    return (
      <div className="modal-text">
        <div>{i18n.t("Are you sure you want to regenerate the %s for this Webhook Domain?", { postProcess: 'sprintf', sprintf: [objectType] })}</div>
        <div className="modal-text"><FAIcon icon="triangle-exclamation" color="yellow" additionalClasses="m-r-5" />{i18n.t(" Warning: Regenerating this value will break any existing webhook integrations that use this %s until those integrations get updated with the new value.", { postProcess: 'sprintf', sprintf: [objectType] })}</div>
      </div>
    )
  }

  render() {
    const { domain, verifyDomain, currentWebhook } = this.props;
    const { verification_token, secret_token } = this.state;

    return (
      <div className="card">
        <div className="card-header">
          <div className="pull-right">
            <Button onClick={() => verifyDomain(domain)} style="secondary" data-action="verify-domain">
              <FAIcon icon="shield-check" />
              <span className="action-label">{i18n.t("Verify")}</span>
            </Button>
            {this.renderDeleteButton()}
          </div>
          <h3 className="m-t-0">
            {this.renderVerificationIcon()}
            {domain.name}
            {domain.application ?
              <small className="m-l-5">({domain.application.name})</small> :
              null}
          </h3>
          <div className="clearfix" />
        </div>
        <div className="card-body">
          <FieldGroup className="row" data={this.state}>
            <FieldGroup className="col-lg-6">
              <InlineNotification type="info" show={!!verification_token}>
                <span>{i18n.t('Make sure to copy your new verification token now. You won\'t be able to see it again!')}</span>
              </InlineNotification>
              <FieldGroup className="row">
                {verification_token ? (
                  <FieldGroup className="col-lg-6">
                    <FloatingLabelFormField
                      name="verification_token"
                      className="m-0"
                      caption={i18n.t("Verification Token")}
                      disabled={true}
                      valueOverride={true}
                    />
                  </FieldGroup>
                ) : (
                  <div className="col-lg-6">
                    <FloatingLabelFormField
                      name="verification_token"
                      className="m-0"
                      caption={i18n.t("Verification Token")}
                      disabled={true}
                      dataValue={FAKE_VERIFICATION_TOKEN}
                    />
                  </div>
                )}
              </FieldGroup>
              <FieldGroup className="row">
                <div className="col-lg-12 m-b-20">
                  <Button
                    style="secondary"
                    className="m-t-10"
                    onClick={() => this.copyToClipboard(verification_token)}
                    disabled={!verification_token}
                    data-action="copy-verification-token"
                  >
                    <FAIcon icon="copy" additionalClasses="m-l-0 m-r-5" />
                    {i18n.t('Copy')}
                  </Button>
                  <Button
                    style="secondary"
                    className="m-t-10"
                    onClick={() => this.regenerateSecret(domain, 'verification')}
                    data-action="regenerate-verification-token"
                  >
                    <FAIcon icon="rotate" additionalClasses="m-l-0 m-r-5" />
                    {i18n.t('Regenerate')}
                  </Button>
                </div>
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="col-lg-6">
              <InlineNotification type="info" show={!!secret_token}>
                <span>{i18n.t('Make sure to copy your new secret token now. You won\'t be able to see it again!')}</span>
              </InlineNotification>
              <FieldGroup className="row">
                {secret_token ? (
                  <FieldGroup className="col-lg-6">
                    <FloatingLabelFormField
                      name="secret_token"
                      className="m-0"
                      caption={i18n.t("Secret Token")}
                      disabled={true}
                      valueOverride={true}
                    />
                  </FieldGroup>
                ) : (
                  <div className="col-lg-6">
                    <FloatingLabelFormField
                      name="secret_token"
                      className="m-0"
                      caption={i18n.t("Secret Token")}
                      disabled={true}
                      dataValue={FAKE_SECRET_TOKEN}
                    />
                  </div>
                )}
              </FieldGroup>
              <FieldGroup className="row">
                <div className="col-lg-12 m-b-20">
                  <Button
                    style="secondary"
                    className="m-t-10"
                    onClick={() => this.copyToClipboard(secret_token)}
                    disabled={!secret_token}
                    data-action="copy-secret-token"
                  >
                    <FAIcon icon="copy" additionalClasses="m-l-0 m-r-5" />
                    {i18n.t('Copy')}
                  </Button>
                  <Button
                    style="secondary"
                    className="m-t-10"
                    onClick={() => this.regenerateSecret(domain, 'secret')}
                    data-action="regenerate-secret-token"
                  >
                    <FAIcon icon="rotate" additionalClasses="m-l-0 m-r-5" />
                    {i18n.t('Regenerate')}
                  </Button>
                </div>
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>
        </div>
        <div className="card-footer">
          <WebhookList {...this.props} />
        </div>
        {this.state.showRegenerateModal &&
          <ConfirmationModal
            title={this.getTitleText()}
            id="regenerate-secret-modal"
            onConfirm={this.confirmRegenerateSecret}
            onCancel={this.cancelRegenerateSecret}
            show={this.state.showRegenerateModal} >
            {this.getWarningText()}
          </ConfirmationModal>
        }
        {currentWebhook && <WebhookForm {...this.props} copyToClipboard={this.copyToClipboard} />}
        <ConfirmationModal
          title={i18n.t("Delete {{ name }} Domain", { name: domain.name })}
          children={
            <div>
              <p>{i18n.t("Are you sure you want to delete the {{ name }} domain?", { name: domain.name })}</p>
              <p>{i18n.t("Warning: you won't be able to create webhooks using this domain anymore.")}</p>
            </div>
          }
          onConfirm={this.onConfirmDeleteDomain}
          onCancel={this.onCancelDeleteDomain}
          show={this.state.showDeleteModal}
        />
      </div>
    )
  }
}
