import React from 'react';
import PropTypes from 'prop-types';
import { SelectionModal } from 'reactifi/dist/es/components/SelectionModal';
import { SelectionModalButton } from 'reactifi/dist/es/components/SelectionModalButton';
import i18n from 'lib/i18n';

export default class BusinessLineSelectionModal extends React.Component {
  static propTypes = {
    businessLines: PropTypes.arrayOf(PropTypes.shape({
      slug: PropTypes.string,
      label: PropTypes.string
    })).isRequired,
    onSelect: PropTypes.func,
    onClose: PropTypes.func,
    selectedBusinessLine: PropTypes.string
  };

  getBusinessLineDescription = (bl) => {
    const learnerTypes = {
      'higher-education': 'student',
      'faculty-staff': 'faculty and staff',
      'corporate-compliance': 'employee',
      'at-work': 'employee'
    };

    return i18n.t(`Upload ${learnerTypes[bl.slug]} learners to your ${bl.label} line of business`);
  }

  render() {
    const { businessLines, onClose, onSelect, selectedBusinessLine } = this.props;

    if (!businessLines) {
      return null;
    }

    return (
      <SelectionModal
        id="lob-selection-modal"
        footerCloseButton={{ isTurnedOn: true, text: i18n.t('Upload Users') }}
        onClose={onClose}
        canClose={true}
        selectionMade={selectedBusinessLine}
        title={i18n.t('Select a Line of Business')}
      >
        {businessLines.map(bl => (
          <SelectionModalButton
            key={bl.slug}
            title={bl.label}
            selected={selectedBusinessLine === bl.slug}
            onClick={() => onSelect(bl.slug)}
          >
            <p>{this.getBusinessLineDescription(bl)}</p>
          </SelectionModalButton>
        ))}
      </SelectionModal>
    );
  }
}
