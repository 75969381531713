import { useConfirmAction } from 'reactifi';
import i18n from "lib/i18n";

const usePasswordResetConfirmAction = action => {
  return useConfirmAction({
    action,
    children: i18n.t("Performing a temporary password reset will reset the user's password temporarily to the new password for {{ hours }} hour(s). Do you wish to continue?", { hours: '12' }),
    confirmButtonText: i18n.t('Send Password Reset'),
    title: i18n.t('Send Password Reset?')
  })
};

export default usePasswordResetConfirmAction;
