import { updateEntity, readEntity, readEntityById } from 'reactifi';

export function loadChecklist(checklistId) {
  return readEntityById(checklistId, 'checklists', 'checklist_items');
}

export function loadUsers(userIds) {
  return readEntity('users', null, { id: userIds });
}

export function updateChecklistItem(checklistItem, checklistId) {
  return updateEntity(checklistItem,
    dispatch => {
      dispatch(readEntityById(checklistId, 'checklists', 'checklist_items'));
    }, null, null, null, `api/data/checklists/${checklistId}`);
}


export function updateChecklist(checklist) {
  return async dispatch => {
    await dispatch(updateEntity(checklist));
  }
}
