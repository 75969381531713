import React from 'react';
import { App } from 'reactifi';
import ActionableDashboardContainer from './containers/ActionableDashboardContainer';

const index = (props, _railsContext) => {
  return (
    <App {..._railsContext}>
      <ActionableDashboardContainer 
        path="/" 
        contexts={props.contexts}
        user={props.current_user}
        notifiUrl={props.notifi_url}
        jwt={props.jwt}
        organization={props.organization}
        contentServiceUrl={props.content_service_url} 
        nextUrl={props.next_url}
      />
    </App>
  );
};

export default index;
