import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { ModalForm, FloatingLabelFormField, SelectControl } from 'reactifi';

export default class OrgUserEditRuleSets extends Component {

  static propTypes = {
    user: PropTypes.object,
    errorMessage: PropTypes.string,
    ruleSets: PropTypes.array,
    onUpdateRuleSets: PropTypes.func,
    onCancel: PropTypes.func
  }

  constructor(props) {
    super(props);
    this.onUpdateRuleSets = this.onUpdateRuleSets.bind(this);
    this.onRuleSetChange = this.onRuleSetChange.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.currentUser = Object.assign({}, props.user);
    this.state = {
      user: Object.assign({}, props.user)
    };
  }

  onUpdateRuleSets(user) {
    this.props.user.rule_set_roles = {};
    user.ruleSetIds.forEach((ruleSetId) => {
      this.props.user.rule_set_roles[`${ruleSetId}`] = user[`${ruleSetId}_selectedRole`];
    });
    this.props.onUpdateRuleSets(this.props.user);
  }

  onRuleSetChange(value) {
    this.setState({ user: this.currentUser });
  }

  onCancel() {
    this.setState({ user: this.props.user });
    this.props.onCancel();
  }

  render() {
    let ruleSets = this.props.ruleSets.map((ruleSet) => {
      return { label: ruleSet.label, value: ruleSet.id };
    });

    return (
      <ModalForm title={i18n.t("Edit User Types")} data={this.currentUser} onCancel={this.onCancel} bsSize="lg"
        updateAction={this.onUpdateRuleSets} errorMessage={this.props.errorMessage}>
        <FloatingLabelFormField name="ruleSetIds" caption={i18n.t("User Types")} required={true}>
          <SelectControl options={ruleSets} multi={true} valueKey="value" labelKey="label" onChange={this.onRuleSetChange} />
        </FloatingLabelFormField>

        {this.currentUser && this.currentUser.ruleSetIds.map((obj) => {
          return (
            <FloatingLabelFormField key={`${obj}`} name={`${obj}_selectedRole`} caption={i18n.t(`${this.props.ruleSets.find((ruleSet) => ruleSet.id === obj).label} Roles`)} required={true}>
              <SelectControl options={this.props.ruleSets.find((ruleSet) => ruleSet.id === obj).roles} multi={false} valueKey="slug" labelKey="label" />
            </FloatingLabelFormField>)
        })}
      </ModalForm>);
  }
}