import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import mapStateToProps from '../store/organizationMap';
import * as orgActions from '../actions/orgActionCreators';
import * as orgCapabilitiesActions from '../actions/orgCapabilitiesActions';
import Organizations from '../components/Organizations';

class OrganizationsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isLoadingOrganizations: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      { ...orgActions, ...orgCapabilitiesActions },
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.actions.findOrganizations();
  }

  render() {
    return <Organizations {...this.props} {...this.actions} />;
  }
}

export default connect(mapStateToProps)(OrganizationsContainer);
