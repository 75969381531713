import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Header, Section, AnnouncementCard, useContainerProps, DisplayWidthForCards, UrlUtil } from 'reactifi';
import i18n from 'lib/i18n';
import { announcement } from '../../../../images/dashboardimages';
import FinEdDashboard from './FinEdDashboard';
import AssignedLearningDashboard from './AssignedLearningDashboard';

// this can allow custom dashboards for each LOB if needed in the future (for now just FinEd)
const DASHBOARD_COMPONENTS = Object.freeze({
  'at-work': FinEdDashboard,
  'corporate-compliance': AssignedLearningDashboard,
  'higher-education': AssignedLearningDashboard,
  'faculty-staff': AssignedLearningDashboard,
  'event-tracking': AssignedLearningDashboard,
  'scorm': AssignedLearningDashboard
});

const ActionableDashboard = props => {
  props = useContainerProps(props);
  const { jwt, notifiUrl, organization: { id: orgId }, ruleSets, systemMessages, user } = props;
  const currentBusinessLine = UrlUtil.getCurrentBusinessLine(ruleSets) || null;
  const userBusinessLine = user.business_lines.length > 1 ? currentBusinessLine : user.business_lines[0];

  useEffect(() => {
    props.loadRuleSets(orgId);
    props.readSystemMessages(user.id);
  }, []);

  useEffect(() => {
    if (user.business_lines.length > 1) {
      // multi-lob user, if role is selected load with that LOB
      if (currentBusinessLine) {
        props.loadTrainingPeriods(orgId, currentBusinessLine);
        props.readTodoItems(notifiUrl, jwt, currentBusinessLine);
      }
    } else {
      // single-lob user, load with the only LOB available
      props.loadTrainingPeriods(orgId, user.business_lines[0]);
      props.readTodoItems(notifiUrl, jwt, user.business_lines[0]);
    }
  }, [currentBusinessLine]);

  if (!userBusinessLine) return null;

  const renderAnnouncements = () => {
    if (!systemMessages) return null;

    return <AnnouncementCard systemMessages={systemMessages} emptyStateImage={announcement} />;
  }

  const DashboardComponent = DASHBOARD_COMPONENTS[userBusinessLine] || AssignedLearningDashboard;

  return (
    <>
      <Header title={`${i18n.t('Welcome back')}, ${user.first_name}!`} data={{ objectType: 'user', objectId: user.id }} />
      <Section type="cards" className="actionable-dashboard">
        <DisplayWidthForCards>
          <DashboardComponent
            {...props}
            renderAnnouncements={renderAnnouncements}
            businessLine={userBusinessLine}
          />
        </DisplayWidthForCards>
      </Section>
    </>
  );
};

ActionableDashboard.propTypes = {
  jwt: PropTypes.string,
  loadRuleSets: PropTypes.func,
  loadTrainingPeriods: PropTypes.func,
  notifiUrl: PropTypes.string,
  organization: PropTypes.shape({
    id: PropTypes.string,
    slug: PropTypes.string
  }).isRequired,
  readTodoItems: PropTypes.func,
  readSystemMessages: PropTypes.func,
  ruleSets: PropTypes.array,
  systemMessages: PropTypes.array,
  user: PropTypes.shape({
    business_lines: PropTypes.array,
    first_name: PropTypes.string,
    id: PropTypes.string
  }).isRequired
};

export default ActionableDashboard;
