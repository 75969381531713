import React from 'react';
import PropTypes from 'prop-types';

const BrandingColorBoxPreview = ({ color, className }) => (
  <div className={`color-box-preview ${className}`}>
    <div className="color-box" style={{ 'backgroundColor': `${color}` }}/>
  </div>
);

BrandingColorBoxPreview.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string.isRequired
};

export default BrandingColorBoxPreview;
