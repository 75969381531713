import React from 'react'
import { PagingDataList, AccessControlUtil } from 'reactifi'
import PropTypes from "prop-types"
import i18n from 'lib/i18n'

export default class LocationTable extends React.Component {
  static propTypes = {
    fieldsProps: PropTypes.object,
    permissions: PropTypes.arrayOf(PropTypes.string),
    sortOptions: PropTypes.object
  }

  handleRowClick = (e, row) => {
    let router = this.props.sortOptions.router;
    let orgId = this.props.sortOptions.organizationId;
    router.push(`/${orgId}/locations/${row.id}/details`);
  }

  getOptions = ({ onSortChange, sortName, sortOrder }) => ({
    onSortChange: onSortChange,
    sortName: sortName,
    sortOrder: sortOrder,
    onRowClick: this.handleRowClick
  });

  get actionsConfig() {
    if (!AccessControlUtil.hasPermission('edit_locations', this.props.permissions)) {
      return null;
    }

    return [
      {
        icon: "eye",
        title: i18n.t('View'),
        tooltip: i18n.t('View Location'),
        type: "view",
        actionType: "callToAction",
        action: this.handleRowClick
      },
      {
        icon: 'pen',
        title: '',
        tooltip: i18n.t('Edit Location'),
        type: 'view',
        actionType: 'callToAction',
        action: this.props.fieldsProps.editLocationHandler
      },
      {
        icon: 'trash',
        title: '',
        tooltip: i18n.t('Delete Location'),
        type: 'view',
        actionType: 'callToAction',
        action: this.props.fieldsProps.deleteLocationHandler
      }
    ];
  }

  getFields = () => {
    let fields = [
      { name: 'external_id', title: i18n.t('External ID'), width: '20%', canSort: true },
      { name: 'name', title: i18n.t('Name'), width: '20%', canSort: true },
      {
        name: 'address_formatted',
        title: i18n.t('Address'),
        width: '30%',
        canSort: true,
        formatter: cell => cell || (<span className="error-text">{i18n.t('Address not found')}</span>)
      },
      { name: 'address_room', title: i18n.t('Address Line 2'), width: '30%', canSort: true },
      { name: 'contact_name', title: i18n.t('Contact'), width: '20%', canSort: true }
    ];

    return fields;
  }

  render() {
    const { sortOptions, ...props } = this.props;
    return <PagingDataList
      trClassName="tr-orgazanization-row"
      fields={this.getFields()}
      actions={this.actionsConfig}
      options={this.getOptions(sortOptions)}
      keyField="id"
      {...props}
    />
  }
}
