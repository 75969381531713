import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import SimpleEdit from "../../common/SimpleEdit";
import * as embedContentDomainsActionCreators from "../actions/embedContentDomainsActionCreators";
import { FloatingLabelFormField, MaterialCheckbox, Page, JsonApiDecorator } from "reactifi";
import i18n from "lib/i18n";

function mapStateToProps (state, ownProps){
  const props = SimpleEdit.mapStateToProps('embed_content_domains')(state, ownProps);
  const apiStore = new JsonApiDecorator(state.api);

  props.embedContentDomains = apiStore.embed_content_domains ? apiStore.embed_content_domains.all(): [];

  return props;
}

class EmbedContentDomainContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func,
    errorMessage: PropTypes.string,
    item: PropTypes.any,
    itemId: PropTypes.any,
    successMessage: PropTypes.string,
    router: PropTypes.any
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(embedContentDomainsActionCreators, this.props.dispatch);
  }

  componentDidMount() {
    if(this.props.itemId) {
      this.actions.selectItem(this.props.itemId);
    }

    this.actions.loadItems();
  }

  get pageTitle() {
    return this.props.item && this.props.item.domain
      ? `${i18n.t('Embed Content Domain: ')}${this.props.item.domain}`
      : i18n.t('New Content Domain');
  }

  render() {
    return (
      <Page
        title={this.pageTitle}
        pageType="child"
        contentType="form"
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        clearMessages={this.actions.clearMessages}
        usePageWrapper={true}
      >

        <SimpleEdit
          item={this.props.item}
          router={this.props.router}
          actionCreators={embedContentDomainsActionCreators}
          dispatch={this.props.dispatch}
          itemId={this.props.itemId}
          goBackAfterAdd={true}>

          <h5>{i18n.t('Domain')}</h5>
          <FloatingLabelFormField
            wrapperClassName="col-lg-6"
            name="domain"
            caption={i18n.t("Enter Content Domain")}/>

          <MaterialCheckbox
            name="allow_subdomains"
            className="col-lg-6">
            {i18n.t("Allow subdomains?")}
          </MaterialCheckbox>

        </SimpleEdit>
      </Page>
    );
  }
}

export default connect(mapStateToProps)(EmbedContentDomainContainer);
