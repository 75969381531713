import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { FAIcon, FieldGroup, MaterialCheckbox, ConfirmationModal } from 'reactifi';

import UploadFileStep from '../../common/Components/upload/UploadFileStep';

export default class UploadFile extends UploadFileStep {
  static propTypes = {
    uploadType: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      processBlanks: false
    };
  }

  onToggleDeleteBlankData = processBlanks => {
    this.setState({ processBlanks });
  }

  onDeleteBlankDataPrompt = confirm => {
    this.setState({ showDeleteBlankDataModal: false });
    this.state.resolveBlankDataPrompt(confirm);
  }

  createOrUpdateRegistrationBatch = async data => {
    if (this.props.currentEntity.id) {
      return await this.props.updateAction(data);
    } else {
      return await this.props.createAction(data);
    }
  }

  goForward = async () => {
    let data = await this.form.save();
    if (data) {
      data = this.preProcessData(data);

      // if no delete blanks option or checkbox is disabled, go forward and create batch
      if (!this.state.processBlanks) {
        await this.createOrUpdateRegistrationBatch(data);
        return true;
      }

      // if delete blanks option is checked, show confirmation modal and proceed if accepted
      this.setState({ showDeleteBlankDataModal: true });
      const confirmBlankDataPromise = new Promise(resolveBlankDataPrompt => {
        this.setState({ resolveBlankDataPrompt });
      });

      const dataPromptAccepted = await confirmBlankDataPromise;

      if (dataPromptAccepted) {
        await this.createOrUpdateRegistrationBatch(data);
      }

      return dataPromptAccepted;
    } else {
      if (!this.state.media_id){
        this.props.displayErrorMessage(i18n.t('Please upload a file'));
      }
    }
  }

  preProcessData = data => {
    data.registration_rule_sets = { [this.props.data?.selectedRuleSet]: this.props.data?.selectedRole };
    if (!data.id) {
      data.differentiated = true;
      /* TODO:
        That is just an example how to set RegistrationBatch#operation.
        Need to set it to `update` for users mass updation, as a part of ECA-3256.
      */
      data.operation = 'create';
    }
    data.operation = this.props.uploadType === "update" ? "update" : "create";
    data.media_id = this.state.media_id;
    data.process_blanks = this.state.processBlanks;

    return data;
  }

  renderIntro = () => {
    return (
      <div>
        <div>{i18n.t('File format must be .csv')}</div>
        <div>
          <FAIcon icon="triangle-exclamation" additionalClasses="m-r-10"/>
          {i18n.t('Before uploading your user list file, be sure you are using the ')}
          <a onClick={this.props.previousStep}>{i18n.t(' User Upload Template')}</a>
        </div>
      </div>
    );
  }

  renderOutro = () => {
    if (this.props.uploadType !== 'update') {
      return null;
    }

    return (
      <>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <MaterialCheckbox 
              name="process_blanks" 
              onChange={this.onToggleDeleteBlankData} 
              dataValue={this.state.processBlanks} 
              valueOverride={true}
            >
              {i18n.t('Delete learner data when cells are left blank')}
            </MaterialCheckbox>
          </FieldGroup>
        </FieldGroup>
        <ConfirmationModal
          title={i18n.t('Delete Data from Blank Cells?')}
          show={this.state.showDeleteBlankDataModal}
          onConfirm={() => this.onDeleteBlankDataPrompt(true)}
          onCancel={() => this.onDeleteBlankDataPrompt(false)}
          confirmButtonText={i18n.t('Delete Data')}
        >
          <>
            <p>{i18n.t('By selecting the option to delete learner data from blank cells, we will delete a learner\'s data from any empty cell in the uploaded template.')}</p>
            <p>{i18n.t('If you wish to only change learner data in the cells you filled in, please go back and uncheck the delete learner data selection.')}</p>
          </>
        </ConfirmationModal>
      </>
    );
  }
}
