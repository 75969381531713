/* eslint no-script-url: 0 */
import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { AccessControlUtil, AccessControlWrapper, ApiUtil, Button, ConfirmationModal, CopyUtil, ExplainerText, Header, FAIcon, MessagesModule, PageWrapper, PagingDataList, Section, Util, getPageSize, FilterBar, SelectFilter, DateFilter, ToggleFilter } from 'reactifi';
import { Dropdown, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { i18n } from 'reactifi/dist/es/i18n';
import { find, pull, difference } from 'lodash';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { isAfter } from 'date-fns';
import { sanitizeUrl } from '@braintree/sanitize-url';

import { AlphabetizeArrayOfObjects } from 'reactifi/dist/es/utils/StringUtil';
import { hasBusinessLine, hasCapability } from 'reactifi/dist/es/utils/AccessControlUtil';

import OrgUserEditRuleSets from './OrgUserEditRuleSets';
import OrgWizardUser from './OrgWizardUser';
import OrgUserCategoryLabelsModal from './OrgUserCategoryLabelsModal';
import OrgUserLocationModal from './OrgUserLocationModal';
import { loadLocations } from '../actions/orgUsersActionCreators';
import BusinessLineSelectionModal from '../../common/Components/BusinessLineSelectionModal';
import { convertCustomFilters } from 'externals/components/functions/convertCustomFilters';
import { buildUsersQuery } from '../common/queries';
import { USER_FILTERS } from '../constants/userFilters';
import LegacyAssignmentsBanner from '../../../externals/components/LegacyAssignmentsBanner';

const LEARNER_SELECT_ALL_LIMIT = 10000;
const TEAMS_CAPABILITY = 'Capabilities::TeamManagement';
const ALT_LOGIN_CAPABILITY = 'Capabilities::AlternativeLogin';
const COPPA_CAPABILITY = 'Capabilities::Coppa';
const SCORM_CAPABILITY = 'Capabilities::ScormSso';
const SSO_CAPABILITY = 'Capabilities::SamlSso';

export default class OrganizationUsers extends Component {
  static propTypes = {
    adminifiUrl: PropTypes.string,
    activeFilters: PropTypes.shape({
      custom: PropTypes.object,
      filters: PropTypes.object
    }),
    activeSort: PropTypes.shape({
      sortName: PropTypes.string,
      sortOrder: PropTypes.string
    }),
    activeSearch: PropTypes.string,
    activePage: PropTypes.number,
    bulkUpdate: PropTypes.func,
    businessLines: PropTypes.array.isRequired,
    clearMessages: PropTypes.func.isRequired,
    clearUsers: PropTypes.func.isRequired,
    contents: PropTypes.array,
    contentServiceUrl: PropTypes.string,
    currentUser: PropTypes.object,
    currentOrganizationId: PropTypes.string.isRequired,
    categories: PropTypes.array,
    disabled: PropTypes.bool,
    displayErrorMessage: PropTypes.func,
    displaySuccessMessage: PropTypes.func,
    downloadUsers: PropTypes.func.isRequired,
    downloadUrl: PropTypes.string,
    downloadProcessing: PropTypes.bool,
    downloadTimedOut: PropTypes.bool,
    endTimeout: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isLoadingUsers: PropTypes.bool,
    isAdvisor: PropTypes.bool,
    isManager: PropTypes.bool,
    jwt: PropTypes.string,
    loadUser: PropTypes.func,
    loadUserFormData: PropTypes.func.isRequired,
    locations: PropTypes.array,
    organization: PropTypes.object,
    organizationId: PropTypes.string,
    organizationSlug: PropTypes.string,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    permissions: PropTypes.arrayOf(PropTypes.string),
    registerFromAdmin: PropTypes.func,
    refreshUsers: PropTypes.func.isRequired,
    router: PropTypes.shape({
      push: PropTypes.func
    }),
    ruleSets: PropTypes.array.isRequired,
    selectUser: PropTypes.func.isRequired,
    setActiveFilters: PropTypes.func.isRequired,
    setActiveSort: PropTypes.func.isRequired,
    setActiveSearch: PropTypes.func.isRequired,
    setActivePage: PropTypes.func.isRequired,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    updateUser: PropTypes.func.isRequired,
    updateUserRuleSets: PropTypes.func.isRequired,
    updateUserFromAdmin: PropTypes.func,
    users: PropTypes.array,
    userMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    userBusinessLines: PropTypes.arrayOf(PropTypes.string),
    userIsPiiRestrictedNextAdvisor: PropTypes.bool,
    userIsPrimaryManager: PropTypes.bool,
    userIsSecondaryManager: PropTypes.bool,
    userManagedBusinessLines: PropTypes.array,
    userTeams: PropTypes.arrayOf(PropTypes.string),
    userIsTeamManager: PropTypes.arrayOf(PropTypes.string),
    userManagedTeams: PropTypes.arrayOf(PropTypes.string),
    viewer: PropTypes.string
  };

  static defaultProps = {
    users: []
  };

  constructor(props) {
    super(props);
    this.setActiveFiltersDebounce = debounce(this.props.setActiveFilters, 1500, { leading: false, trailing: true });
    this.findUsersByFiltersDebounce = debounce(this.findUsersByFilters, 1500, { leading: false, trailing: true });
    this.refreshUsersDebounce = debounce(this.props.refreshUsers, 1500, { leading: false, trailing: true });

    this.state = {
      editRuleSets: false,
      showCreateUserModal: false,
      showApplyCategoryModal: false,
      showHiddenSelectionModal: false,
      showLOBSelectModal: false,
      showDeactivateConfirmation: false,
      showCreateConfirmation: false,
      selectedUploadLOB: null,
      searchValue: '',
      activePage: 1,
      filters: {},
      wizardStep: 0,
      isCreate: false,
      sortName: "last_name",
      sortOrder: "asc",
      selected_IDs: [],
      selected_Users: [],
      newUser: null,
      updatingBatch: false,
      locations: [],
      isMounted: false
    };
  }

  componentDidMount() {
    this.setState({ isMounted: true });
    this.getLocations();
  }

  componentWillUnmount() {
    this.setState({ isMounted: false });
  }

  getLocations = async () => {
    const locations = await loadLocations(this.props.currentOrganizationId)
    const formatLocations = locations.data.map(loc => ({ id: loc.id, name: loc.attributes.name, address_formatted: loc.attributes.address_formatted }))
    if (this.state.isMounted) {
      this.setState({ locations: formatLocations });
    }
  }

  getUserBusinessLines = () => {
    const { businessLines, userBusinessLines, ruleSets } = this.props;

    if (!businessLines) {
      return [];
    }

    let managedBusinessLines = businessLines;
    if (userBusinessLines) {
      managedBusinessLines = businessLines.filter(
        bl => userBusinessLines.includes(bl.slug) && ruleSets.find(rs => rs.is_manager && rs.business_lines.includes(bl.slug))
      );
    }

    return managedBusinessLines;
  }

  goToUserUploadsPage = (bl, type) => {
    window.location.href = sanitizeUrl(`/${this.props.organizationSlug}/cportal/user_uploads#/add?business_line=${bl}${type ? `&type=${type}` : ''}`);
  }

  onCreateUser = () => {
    this.setState(
      { isCreate: true, wizardStep: 0 },
      this.setState({ showCreateUserModal: true })
    );
  }

  onSelectCreateUser = (create_type) => {
    const userLOBs = this.getUserBusinessLines();

    if (create_type === 'create') {
      this.onCreateUser();
    } else {
      if (userLOBs && userLOBs.length === 1) {
        this.goToUserUploadsPage(userLOBs[0].slug);
      } else {
        this.setState({ showLOBSelectModal: true });
      }
    }
  };

  deselectUser = () => {
    this.currentUser = null;
    this.props.loadUser(null);
  }

  selectUser = (user) => {
    this.currentUser = user;
    let userId = user?.id;
    if (userId && this.props.currentOrganizationId) {
      this.props.selectUser(
        userId,
        this.props.currentOrganizationId,
        this.props.viewer
      );
      this.setState(
        { isCreate: false, wizardStep: 0 },
        this.setState({ showCreateUserModal: true })
      );
    } else {
      this.props.selectUser(null)
    }
  };

  onUpdateUsers = () => {
    const userLOBs = this.getUserBusinessLines();

    if (userLOBs && userLOBs.length === 1) {
      this.goToUserUploadsPage(userLOBs[0].slug, 'update');
    } else {
      this.setState({ showLOBSelectModal: true, updatingBatch: true });
    }
  };

  register = async (registrationSets, addAnother) => {
    const { currentOrganizationId } = this.props;
    const adminifiRegistration = registrationSets?.registrations?.[0];
    const { email } = adminifiRegistration || {};

    if (email) {
      const exists = await ApiUtil.loadApiData(`/api/data/users?filter[organization_id]=${currentOrganizationId}&filter[email]=${email}`);
      if (exists.length > 0) {
        this.props.displayErrorMessage(`${email} ${i18n.t('has already been taken')}`);
      }
    }

    const user = await this.props.registerFromAdmin(registrationSets, addAnother);
    this.props.clearUsers();

    if (user) {
      if (addAnother) {
        this.setState({ wizardStep: 0 });
        return;
      }

      if (adminifiRegistration && email) {
        this.goToUserDetailsPage(user);
      } else {
        // alternative login flow
        const { first_name, last_name, password } = adminifiRegistration;

        this.setState({
          newUser: Object.assign({}, user, {
            full_name: `${first_name} ${last_name}`,
            password
          })
        }, () => {
          this.onCancel();
          this.setState({ showCreateConfirmation: true });
        });
      }
    } else {
      setTimeout(this.props.refreshUsers, 750);
      this.onCancel();
    }
  };

  updateUser = async (user) => {
    await this.props.updateUserFromAdmin(user);
    this.refreshUsersDebounce();
    this.onCancel();
  };

  onCancel = () => {
    this.props.refreshUsers();
    this.setState({
      editRuleSets: false,
      showCreateUserModal: false,
      showBatchLocationModal: false,
      showApplyCategoryModal: false,
      showHiddenSelectionModal: false,
      showDeactivateConfirmation: false,
      showCreateConfirmation: false
    });
  };

  onEditRuleSets = (user) => {
    this.currentUser = user;
    this.setState({ editRuleSets: true });
  };

  onUpdateRuleSets = (user) => {
    this.setState({ editRuleSets: false });
    this.props.updateUserRuleSets(user, this.props.organization.id);
  };

  onPageChange = (pageNumber) => {
    this.props.setActivePage(pageNumber);
  };

  onShowDeactivateConfirmation = () => {
    if (this.state.selected_IDs.length < 1) {
      return;
    }
    this.setState({ showDeactivateConfirmation: true });
  };

  get totalUserCount() {
    const { userMeta } = this.props;
    if (!userMeta) {
      return 0;
    }
    return userMeta.total_count || 0;
  }

  get selectedUserCount() {
    return this.state.selected_IDs.length;
  }

  updateCategories = (category, clear) => {
    let all_users_count = this.totalUserCount.toLocaleString('en');
    let selected_users_count = this.selectedUserCount.toLocaleString('en');
    let action = {
      data: {
        attributes: {
          user_ids: this.state.selected_IDs,
          category_id: category.category_id,
          ...(clear ? null : { category_label: category.category_label }),
          ...(clear ? { action: 'delete' } : {})
        },
        type: 'bulk_action_categories'
      }
    };
    let messageVars, successMessage;
    if (clear) {
      messageVars = [selected_users_count, all_users_count, category.name];
      successMessage = i18n.t('%s of %s users successfully removed label from %s', { postProcess: 'sprintf', sprintf: messageVars });
    } else {
      messageVars = [selected_users_count, all_users_count, category.category_label, category.name];
      successMessage = i18n.t('%s of %s users successfully updated to %s in %s', { postProcess: 'sprintf', sprintf: messageVars });
    }

    this.props.bulkUpdate('category', action, successMessage);
    this.onCancel();
    this.resetAllSelections();
    this.clearSelectedUsersAndRefresh();
  };

  updateLocations = (locations) => {
    let selected_location = find(this.state.locations, [
      'id',
      locations.location_id
    ]);

    const selected_location_name = selected_location ? selected_location.name : ""

    let all_users_count = this.totalUserCount.toLocaleString('en');
    let selected_users_count = this.selectedUserCount.toLocaleString(
      'en'
    );
    let action = {
      data: {
        attributes: {
          user_ids: this.state.selected_IDs,
          location_id: locations.location_id
        },
        type: 'bulk_action_locations'
      }
    };
    const messageVars = [selected_users_count, all_users_count, selected_location_name];
    const successMessage = i18n.t('%s of %s users successfully updated with location: %s', { postProcess: 'sprintf', sprintf: messageVars });
    this.props.bulkUpdate('location', action, successMessage);
    this.onCancel();
    this.resetAllSelections();
  };

  updateDeactivate = () => {
    let all_users_count = this.totalUserCount.toLocaleString('en');
    let selected_users_count = this.selectedUserCount.toLocaleString(
      'en'
    );
    let action = {
      data: {
        attributes: {
          user_ids: this.state.selected_IDs
        },
        type: 'bulk_action_deactivates'
      }
    };
    const messageVars = [selected_users_count, all_users_count];
    const successMessage = i18n.t('%s of %s users successfully deactivated.', { postProcess: 'sprintf', sprintf: messageVars });
    this.props.bulkUpdate('deactivate', action, successMessage);
    this.onCancel();
    this.clearSelectedUsersAndRefresh()
  };

  updateReactivate = () => {
    let all_users_count = this.totalUserCount.toLocaleString('en');
    let selected_users_count = this.selectedUserCount.toLocaleString(
      'en'
    );
    let action = {
      data: {
        attributes: {
          user_ids: this.state.selected_IDs
        },
        type: 'bulk_action_activates'
      }
    };
    const messageVars = [selected_users_count, all_users_count];
    const successMessage = i18n.t('%s of %s users successfully reactivated.', { postProcess: 'sprintf', sprintf: messageVars });
    this.props.bulkUpdate('activate', action, successMessage)
    this.clearSelectedUsersAndRefresh()
  }

  onSortChange = (sortName, sortOrder) => {
    this.props.setActiveSort({ value: sortName, direction: sortOrder });
  };

  clearSelectedUsersAndRefresh = () => {
    this.setState({ isLoadingData: true });
    setTimeout(() => {
      this.props.refreshUsers();
      this.setState({
        searchValue: '',
        activePage: 1,
        sortName: "last_name",
        sortOrder: "asc",
        selected_IDs: [],
        selected_Users: [],
        isLoadingData: false
      });
    }, 4000);
  };

  downloadParams = () => {
    return {
      organization_id: this.props.currentOrganizationId,
      filters: this.props.activeFilters && this.props.activeFilters.custom
    };
  };

  get fields() {
    const {
      orgCapabilities,
      userIsPiiRestrictedNextAdvisor
    } = this.props;

    const displayUsername = AccessControlUtil.hasCapability(ALT_LOGIN_CAPABILITY, orgCapabilities) || AccessControlUtil.hasCapability(COPPA_CAPABILITY, orgCapabilities);
    const displaySsoId = AccessControlUtil.hasCapability(SSO_CAPABILITY, orgCapabilities) || AccessControlUtil.hasCapability(SCORM_CAPABILITY, orgCapabilities)
    const fields = [
      { name: 'first_name', title: i18n.t('First Name'), width: '18%', canSort: true },
      { name: 'last_name', title: i18n.t('Last Name'), width: '18%', canSort: true },
      // Hide e-mails for PII Restricted Advisors
      ...!userIsPiiRestrictedNextAdvisor ? [{
        name: 'email',
        title: i18n.t('Email'),
        width: '28%',
        canSort: true,
        formatter: (cell, row) => {
          if (row.email) {
            return <a href={sanitizeUrl(`mailto:${row.email}`)}>{row.email}</a>;
          } else if (row.under_13 && row.parent_email) {
            return (
              <span>
                {row.parent_email}
                <OverlayTrigger
                  placement="top"
                  overlay={(
                    <Tooltip id="tooltip-parent-email">
                      {i18n.t('This email is for the parent/guardian of a minor.')}
                    </Tooltip>
                  )}
                >
                  <span><FAIcon icon="circle-info" additionalClasses="text-info m-l-5" /></span>
                </OverlayTrigger>
              </span>);
          } else if (!row.email && row.external_attributes.employee_id) {
            return row.external_attributes.employee_id;
          } else if (
            !row.email &&
            !row.external_attributes.employee_id &&
            row.external_attributes.student_id
          ) {
            return row.external_attributes.student_id;
          } else {
            return null;
          }
        }
      }] : [],
      ...displayUsername ? [{ name: 'username', title: i18n.t('Username'), width: '18%', canSort: true }] : [],
      ...displaySsoId ? [{ name: 'sso_id', title: i18n.t('SSO ID'), width: '18%', canSort: true }] : [],
      {
        name: 'ruleSetsDisplay',
        title: i18n.t('User Type: Roles'),
        width: '36%',
        canSort: false
      }
    ];

    return fields;
  }

  get actions() {
    if (this.props.viewer !== 'admin') {
      return null;
    } else {
      return [
        {
          icon: "eye",
          title: i18n.t('View'),
          tooltip: i18n.t('View User'),
          type: "view",
          actionType: "callToAction",
          action: this.onRowClick
        },
        {
          icon: 'pen',
          title: i18n.t('Edit'),
          tooltip: i18n.t('Edit User'),
          type: 'view',
          actionType: 'callToAction',
          action: (e, row) => {
            e.preventDefault();
            e.stopPropagation();
            this.selectUser(row);
          }
        },
        {
          icon: 'mask',
          title: i18n.t('Impersonate'),
          tooltip: i18n.t('Impersonate'),
          type: 'view',
          actionType: 'callToAction',
          action: (e, row) => {
            e.preventDefault();
            e.stopPropagation();
            window.location.href = sanitizeUrl(`${row.id}/impersonation`);
          },
          filter: row => {
            const canImpersonateManagers = !row['is_manager?'] || AccessControlUtil.hasPermission('impersonate_managers', this.props.permissions);
            return canImpersonateManagers && row.active && row.rule_sets.some((rule_set) => rule_set.name !== 'scorm')
          }
        }
      ];
    }
  }

  onShowHiddenSelections = () => {
    this.setState({ showHiddenSelectionModal: true });
  };

  onSelectLocation = () => {
    if (this.state.selected_IDs.length < 1) {
      return;
    }

    let locObject = {
      location_id: null
    };

    this.setState({
      locationObject: locObject,
      showBatchLocationModal: true
    });
  };

  onSelectCategory = () => {
    if (this.state.selected_IDs.length < 1) {
      return;
    }

    this.setState({
      showApplyCategoryModal: true
    });
  }

  handleRowSelect = (row, isSelected, e) => {
    let id_array = this.state.selected_IDs || [];
    if (isSelected) {
      id_array.push(row.id);
    } else {
      pull(id_array, row.id);
    }

    this.setState({ selected_IDs: id_array });
  };

  selectionsAreHidden = () => {
    if (this.state.selected_IDs.length > (getPageSize())) {
      return true;
    } else {
      var currentPageIDs = this.props.users.map(obj => obj.id);
      let remainingArray = difference(
        this.state.selected_IDs,
        currentPageIDs
      );
      return remainingArray.length > 0;
    }
  };

  resetAllSelections = () => {
    this.setState({ selected_IDs: [] });
  };

  selectAll = async (isSelected, rows) => {
    if (!this.selectAllEnabled()) {
      return;
    }
    this.setState({ isLoadingData: true });

    const endpoint = "/api/data/users.json";

    const customFilters = this.props.activeFilters ? this.props.activeFilters.custom : {};
    const combinedFilters = {
      organization_id: this.props.currentOrganizationId,
      custom: convertCustomFilters(customFilters)
    };
    const query = buildUsersQuery(this.props.activeSearch);

    const results = await Util.selectAll({
      endpoint,
      filters: combinedFilters,
      query,
      isChecked: isSelected,
      totalCount: this.totalUserCount,
      selectedIds: this.state.selected_IDs,
      entity: "user",
      sortValue: "email"
    });
    if (this.state.isMounted) {
      this.setState({ selected_IDs: results, isLoadingData: false });
    }
  };

  selectAllEnabled = () => {
    return this.totalUserCount <= LEARNER_SELECT_ALL_LIMIT;
  };

  isUserSecondaryManager = () => {
    return !this.props.userIsPrimaryManager && this.props.userIsSecondaryManager;
  }

  isScormAdmin = () => !(this.props.ruleSets || []).some(ruleSet => !['scorm', 'scorm_manager'].includes(ruleSet.name));

  renderActionButtons = () => {
    const isFoundryAdmin = this.props.viewer === 'admin';
    const isSecondaryManager = !isFoundryAdmin && this.isUserSecondaryManager();
    const isScormAdmin = !isFoundryAdmin && this.isScormAdmin();
    const { permissions, userIsTeamManager } = this.props;

    if (isFoundryAdmin) {
      return (
        <Fragment>
          <AccessControlWrapper requiredPermission="create_users" permissions={permissions}>
            <Button
              className="manageusers-bulk"
              style="secondary"
              data-action="add-user"
              onClick={e => this.onSelectCreateUser('create')}
            >
              <FAIcon icon="plus" />
              {i18n.t('New User')}
            </Button>
          </AccessControlWrapper>
          {/*<AccessControlWrapper requiredPermission="import_batches" permissions={permissions}>
            <Link to={`/${this.props.currentOrganizationId}/upload_roles`}>
              <Button className="btn btn-secondary">
                {i18n.t('Update User Roles')}
              </Button>
            </Link>
          </AccessControlWrapper>*/}
        </Fragment>
      );
    }

    if (!this.props.ruleSets || !this.props.ruleSets.length) {
      return null;
    }

    if (isScormAdmin) {
      return (
        <Button
          className="manageusers-add"
          title={i18n.t('Add New User')}
          onClick={this.onCreateUser}
          data-action="add-user"
        >
          <FAIcon icon="plus" />
          {i18n.t('Add New User')}
        </Button>
      );
    }

    return !isSecondaryManager && !userIsTeamManager ? (
      <>
        <Dropdown id="add-users-dropdown" onSelect={this.onSelectCreateUser} data-type="dropdown">
          <Dropdown.Toggle variant="primary" className="manageusers-bulk ui-default" data-action="add-new-users-dropdown">
            <FAIcon icon="plus" />
            {i18n.t('Add New Users')}
            <span className="caret" />
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item eventKey="import" data-action="upload-users">{i18n.t('Upload New Users')}</Dropdown.Item>
            <Dropdown.Item eventKey="create" data-action="add-user">{i18n.t('Add New User')}</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        <Button
          className="manageusers-update"
          title={i18n.t('Update Users')}
          onClick={this.onUpdateUsers}
          data-action="update-users"
        >
          <FAIcon icon="user-group" additionalClasses="m-r-5" />
          {i18n.t('Update Users')}
        </Button>
      </>
    ) : null;
  };

  onRowClick = (e, row) => {
    const { isLoadingData } = this.state
    const { router, viewer, organization } = this.props;

    if (isLoadingData || !row.id) {
      return;
    }

    if (viewer === "admin" && organization) {
      router.push(
        `${organization.id}/users/${row.id}/details`
      );

      return
    }

    router.push(
      `${row.id}/details`
    );
  }

  onCreateConfirmCancel = () => {
    this.findUsersByFiltersDebounce();
    this.onCancel();
  }

  copyUserPasswordToClipboard = () => {
    if (this.state.newUser) {
      const success = CopyUtil.copyText(this.state.newUser.password, document.querySelector('#confirmation-modal'));

      if (success) {
        this.props.displaySuccessMessage(i18n.t('The password has been copied to your clipboard.'))
      } else {
        this.props.displayErrorMessage(i18n.t('Oops, unable to copy'))
      }
    }
  };

  goToUserDetailsPage = (user) => {
    const url = this.props.viewer === 'admin' ? `${this.props.organization.id}/users/${user.id}/details` : `${user.id}/details`;
    this.props.router.push(url);
  }

  getOptions = () => {
    return ({
      onSortChange: this.onSortChange,
      sortName: this.props.activeSort && this.props.activeSort.sortName,
      sortOrder: this.props.activeSort && this.props.activeSort.sortOrder,
      onRowClick: this.onRowClick
    });
  }

  handleSelectUploadLOB = (businessLine) => this.setState({ selectedUploadLOB: businessLine });

  handleUploadModalClose = (cancel) => {
    const { selectedUploadLOB, updatingBatch } = this.state;

    if (selectedUploadLOB && !cancel) {
      const type = updatingBatch ? 'update' : null;
      this.goToUserUploadsPage(selectedUploadLOB, type);
    }

    this.setState({ showLOBSelectModal: false, selectedUploadLOB: null, updatingBatch: false });
  }

  isEverfiAdmin = () => this.props.viewer === 'admin';

  renderPageWrapper = children => {
    if (this.isEverfiAdmin()) {
      return (
        <>
          <Section>
            <ExplainerText>
              {i18n.t('This is the list of all users in this organization. Customer admins will see a subset of this list depending on their role in our system.')}
            </ExplainerText>
            {this.renderActionButtons()}
          </Section>
          {children}
        </>
      );
    } else {
      return (
        <PageWrapper>
          <LegacyAssignmentsBanner capabilities={this.props.orgCapabilities} />
          <MessagesModule
            successMessage={this.props.successMessage}
            errorMessage={this.state.showCreateUserModal ? null : this.props.errorMessage}
            clearMessages={this.props.clearMessages}
          />
          <Header
            title={this.props.organization ? `${this.props.organization.name} Users` : i18n.t('Users')}
            button={this.renderActionButtons()}
          />
          {children}
        </PageWrapper>
      );
    }
  }

  /* Filters Start */
  findUsersByFilters = (selectedFilters = {}) => {
    const { isLoadingUsers, activeFilters, currentOrganizationId, userIsSecondaryManager, userManagedTeams } = this.props;

    if (!isLoadingUsers) {
      if (Object.keys(selectedFilters).length === 0) {
        this.resetFilters();
        return;
      }

      // set default learning context & teams for customer admins
      if (!this.isEverfiAdmin()) {
        selectedFilters.business_lines = this.getUserBusinessLines();

        if (userIsSecondaryManager && !selectedFilters.teams_as_member_ids) {
          selectedFilters.teams_as_member_ids = userManagedTeams;
        }
      }

      if (!isEqual(activeFilters.custom, selectedFilters)) {
        this.setActiveFiltersDebounce(Object.assign({}, activeFilters, { organization_id: currentOrganizationId, custom: selectedFilters }));
      }
    }
  };

  resetFilters = () => {
    this.setActiveFiltersDebounce({
      organization_id: this.props.currentOrganizationId,
      custom: {
        ...(!this.isEverfiAdmin() ? { business_lines: this.props.userBusinessLines } : {})
      }
    });
  };

  handleSearchChange = ({ searchValue }) => {
    this.props.setActiveSearch(searchValue);
  };

  locationLabelFunction = (location) => {
    return `${location.name} (${location.address_formatted})`;
  };

  canFilterTeams = () => {
    const { userBusinessLines } = this.props;
    return this.isEverfiAdmin() || hasBusinessLine(['corporate-compliance', 'higher-education', 'faculty-staff'], userBusinessLines);
  }

  teamCapabilityEnabled = () => hasCapability(TEAMS_CAPABILITY, this.props.orgCapabilities);

  getTeams = () => {
    const { userBusinessLines, currentOrganizationId } = this.props;
    let selectedBusinessLine = (userBusinessLines || []).join(',');
    let filters = { organization_id: currentOrganizationId };

    filters.business_line = selectedBusinessLine;

    return filters;
  }

  getRosters = () => {
    let { userBusinessLines, currentOrganizationId } = this.props;
    const filters = { organization_id: currentOrganizationId, status: 'complete' };
    if (userBusinessLines && userBusinessLines.length === 1) {
      filters.business_line = userBusinessLines[0];
    }
    return filters;
  }

  onRuleSetChange = ruleSet => {
    this.setState({ selectedRuleSetId: ruleSet.id });
  }

  filterableRuleSets(ruleSets) {
    const teamsEnabled = AccessControlUtil.hasCapability(TEAMS_CAPABILITY, this.props.orgCapabilities);

    if (this.props.viewer !== 'admin' && !teamsEnabled) {
      const rolesToHide = ['primary', 'secondary'];

      return ruleSets.map(ruleSet => ({
        ...ruleSet,
        roles: ruleSet.roles.filter(role => !rolesToHide.includes(role.slug))
      }));
    }

    return ruleSets;
  }

  isDateTileDisabled = ({ date }) => {
    return isAfter(new Date(date), new Date());
  }

  getUrlFilters = () => {
    const url = new URL(window.location.href);
    const urlParams = new URLSearchParams(url.search);
    const filterParam = urlParams.get('filter');

    return filterParam ? JSON.parse(atob(filterParam)) : {};
  }

  getFiltersConfig = () => {
    const isEverfiAdmin = this.isEverfiAdmin();
    const urlFilters = this.getUrlFilters();
    const { activeFilters = {}, adminifiUrl, contentServiceUrl, jwt, ruleSets, businessLines, contents, userBusinessLines, currentOrganizationId } = this.props;
    let initialFilters = { ...activeFilters.custom, ...urlFilters };

    const locationsUrl = adminifiUrl ? `${adminifiUrl}api/data/locations.json` : `/api/data/locations.json`;
    const teamsUrl = adminifiUrl ? `${adminifiUrl}api/data/teams.json` : `/api/data/teams.json`;
    const rosterUrl = adminifiUrl ? `${adminifiUrl}api/data/rosters.json?exclude_empty=true` : `/api/data/rosters.json?exclude_empty=true`;
    const contentUrl = contentServiceUrl ? `${contentServiceUrl}/api/v1/contents.json` : `/api/data/contents.json`;

    const teamFilters = this.getTeams();
    const rosterFilters = this.getRosters();
    const custom_labels = this.props.categories.map((category) => {
      return {
        id: category.id,
        label: category.name,
        categoryOptions: category.category_labels.map((category_label) => {
          return {
            id: category_label.id,
            type: category.name,
            label: category_label.name
          }
        })
      }
    });

    let ruleSetsRolesConfig = [];

    if (isEverfiAdmin) {
      ruleSetsRolesConfig = ruleSets.map(ruleSet => ({
        id: ruleSet.id,
        label: ruleSet.label,
        categoryOptions: ruleSet.roles.map(role => ({ id: role.slug, label: role.label }))
      }));
    } else {
      ruleSetsRolesConfig = ruleSets
        .filter(ruleSet => {
          return ruleSet.business_lines.find(bl => this.getUserBusinessLines()
            .map(business_line => business_line.slug).includes(bl)) && ruleSet
        }).map(ruleSet => ({
          id: ruleSet.id,
          label: ruleSet.label,
          categoryOptions: ruleSet.roles.map(role => ({ id: role.slug, label: role.label }))
        }));
    }

    const filtersConfig = [
      {
        name: USER_FILTERS.USER_TYPE_ROLES,
        label: i18n.t('User Type & Roles'),
        options: ruleSetsRolesConfig,
        isMulti: true,
        isTwoStep: true,
        type: SelectFilter
      },
      {
        name: USER_FILTERS.CUSTOM_LABELS,
        label: i18n.t('Custom Labels'),
        options: custom_labels,
        isMulti: true,
        isTwoStep: true,
        type: SelectFilter
      },
      {
        name: USER_FILTERS.DATE_USER_ADDED,
        label: i18n.t('Date User Added'),
        isMulti: true,
        tileDisabled: {
          fromDate: this.isDateTileDisabled,
          toDate: this.isDateTileDisabled
        },
        type: DateFilter
      },
      {
        name: USER_FILTERS.QUICK_LIST,
        label: i18n.t('Quick Lists'),
        isAsync: true,
        asyncOptions: {
          dataUrl: rosterUrl,
          queryFields: { name: 'word_start' },
          filters: rosterFilters
        },
        labelKey: 'name',
        valueKey: 'id',
        type: SelectFilter
      },
      {
        name: USER_FILTERS.EMAIL_ISSUES,
        label: i18n.t('Email Issues'),
        isMulti: false,
        checkedLabel: i18n.t('Filtering by Bounced Emails'),
        uncheckedLabel: i18n.t('Not Filtering by Bounced Emails'),
        type: ToggleFilter
      },
      {
        name: USER_FILTERS.USER_STATUS,
        label: i18n.t('User Status'),
        options: [
          { id: 'true', label: 'Active' },
          { id: 'false', label: 'Deactivated' }],
        isMulti: true,
        type: SelectFilter
      }
    ];

    if (this.props.locations && this.props.locations.length > 1) {
      let locationsFilter = {
        name: USER_FILTERS.LOCATIONS,
        label: i18n.t('Locations'),
        isAsync: true,
        asyncOptions: {
          dataUrl: locationsUrl,
          filters: { organization_id: currentOrganizationId },
          filterField: 'keyword',
          labelFunction: this.locationLabelFunction,
          sortBy: "name,address_formatted"
        },
        labelKey: 'name',
        valueKey: 'id',
        type: SelectFilter
      }
      filtersConfig.splice(3, 0, locationsFilter);
    }

    if (isEverfiAdmin) {
      let businessLinesFilter = {
        name: USER_FILTERS.LEARNING_CONTEXT,
        label: i18n.t('Learning Context'),
        options: AlphabetizeArrayOfObjects(businessLines, 'label'),
        isMulti: true,
        type: SelectFilter
      };
      filtersConfig.splice(0, 0, businessLinesFilter);
    } else {
      let coursesCompletedFilter = {
        name: USER_FILTERS.CONTENT_COMPLETED,
        label: i18n.t('Content Completed'),
        options: contents,
        isMulti: true,
        isAsync: true,
        asyncOptions: {
          authToken: jwt,
          dataUrl: contentUrl,
          queryFields: { name: 'word_start' },
          filters: { business_lines: userBusinessLines }
        }, labelKey: 'name',
        valueKey: 'id',
        uniqueKey: 'content_completed',
        showSelection: false,
        type: SelectFilter
      }

      let contentNotAssignedFilter = {
        name: USER_FILTERS.CONTENT_NOT_ASSIGNNED,
        label: i18n.t('Content Not Assigned'),
        options: contents,
        isMulti: true,
        isAsync: true,
        asyncOptions: {
          authToken: jwt,
          dataUrl: contentUrl,
          queryFields: { name: 'word_start' },
          filters: { business_lines: userBusinessLines }
        }, labelKey: 'name',
        valueKey: 'id',
        uniqueKey: 'content_not_assigned',
        showSelection: false,
        type: SelectFilter
      }

      filtersConfig.splice(5, 0, coursesCompletedFilter, contentNotAssignedFilter);
    }

    if (this.canFilterTeams() && this.teamCapabilityEnabled()) {
      let teamsFilter = {
        name: USER_FILTERS.TEAMS,
        label: i18n.t('Teams'),
        isAsync: true, asyncOptions: {
          dataUrl: teamsUrl,
          filters: teamFilters,
          queryFields: { name: 'word_start' }
        },
        labelKey: 'name',
        valueKey: 'id',
        type: SelectFilter
      };

      const initialTeamsFilters = initialFilters[teamsFilter.name];
      const stringValues = initialTeamsFilters?.filter?.(vk => typeof vk === "string");

      if (stringValues?.length) {
        initialFilters = Object.assign({}, initialFilters, { [teamsFilter.name]: initialTeamsFilters.filter(initialFilter => typeof initialFilter !== "string") });
      }

      filtersConfig.splice(4, 0, teamsFilter);
    }

    if (!initialFilters?.active) {
      initialFilters = Object.assign({}, initialFilters, { active: { id: "true", label: "Active" } });
    }

    return { filtersConfig, initialFilters };
  }
  /* Filters End */

  render() {
    const { newUser, showLOBSelectModal } = this.state;
    const { userIsPiiRestrictedNextAdvisor, isAdvisor } = this.props;
    const hasActionBar = !this.isEverfiAdmin() && !this.isUserSecondaryManager() && !userIsPiiRestrictedNextAdvisor && !isAdvisor;

    const selectRow = hasActionBar ? {
      mode: 'checkbox', // multi select
      clicktoSelect: true,
      hideSelectAll: this.state.isLoadingData || this.props.disabled || !this.selectAllEnabled(),
      onSelect: this.handleRowSelect,
      onSelectAll: this.selectAll,
      selected: this.state.selected_IDs
    } : null;

    const actionBar = hasActionBar ? [
      { label: i18n.t('Set Category'), icon: "tag", onClick: this.onSelectCategory },
      { label: i18n.t('Set Location'), icon: "location-dot", onClick: this.onSelectLocation },
      { label: i18n.t('Deactivate'), icon: "ban", onClick: this.onShowDeactivateConfirmation },
      { label: i18n.t('Reactivate'), icon: "rotate-left", onClick: this.updateReactivate }
    ] : null;

    const tooManyResults = !this.selectAllEnabled();
    const filtersConfigObj = this.getFiltersConfig();

    return this.renderPageWrapper((
      <Section type="list">
        {this.state.showCreateUserModal && (
          <OrgWizardUser
            {...this.props}
            wizardStep={this.state.wizardStep}
            onCancel={this.onCancel}
            onSelectUser={user => this.selectUser(user)}
            deselectUser={this.deselectUser}
            isCreate={this.state.isCreate}
            currentUser={this.currentUser}
            register={this.register}
            updateUser={this.updateUser}
          />
        )}

        {this.state.showBatchLocationModal && (
          <OrgUserLocationModal
            locationObject={this.state.locationObject}
            locations={this.state.locations}
            userCount={this.state.selected_IDs.length}
            onCancel={this.onCancel}
            onSubmit={this.updateLocations}
          />
        )}

        {this.state.showApplyCategoryModal && (
          <OrgUserCategoryLabelsModal
            businessLine={this.props.businessLines && this.props.businessLines.length ? this.props.businessLines[0].slug : null}
            onCancel={this.onCancel}
            onSubmit={this.updateCategories}
            organizationId={this.props.currentOrganizationId}
          />
        )}

        {this.state.editRuleSets && (
          <OrgUserEditRuleSets
            user={this.currentUser}
            ruleSets={this.props.ruleSets}
            onUpdateRuleSets={this.onUpdateRuleSets}
            onCancel={this.onCancel}
          />
        )}

        <div className="page-panel">
          <FilterBar
            filterConfig={filtersConfigObj.filtersConfig}
            onSelectFilters={this.findUsersByFiltersDebounce}
            initialFilters={filtersConfigObj.initialFilters}
            searchBox={
              {
                isOnlySearch: false,
                fullWidth: false,
                placeholder: i18n.t('Search Users'),
                searchAction: this.handleSearchChange,
                value: this.props.activeSearch,
                valueChangeDelay: 1500
              }
            }
          />

          <PagingDataList
            data={this.props.users}
            dataObjectType="users"
            fields={this.fields}
            actions={this.actions}
            trClassName="table-row-pointer"
            selectRow={selectRow}
            keyField="id"
            options={this.getOptions()}
            isLoadingData={this.props.isLoadingUsers || this.state.isLoadingData}
            onPageChange={this.onPageChange}
            totalCount={this.totalUserCount}
            activePage={this.props.activePage}
            showDownloadLink={this.props.isManager}
            downloadUrl={this.props.downloadUrl}
            downloadProcessing={this.props.downloadProcessing}
            downloadTimedOut={this.props.downloadTimedOut}
            downloadAction={this.props.downloadUsers}
            downloadParams={this.downloadParams()}
            closeTimeoutModal={this.props.endTimeout}
            actionBar={actionBar}
            selectedItems={{
              count: this.state.selected_IDs.length,
              tooltip: this.selectionsAreHidden() ? i18n.t('All items matching your filters and search are selected, not just what you see on this page.') : null,
              onClearSelection: this.resetAllSelections,
              warning: tooManyResults && i18n.t('Your search returned too many results, please use the provided filters to use the "select all" checkbox')
            }}
            useCoreTable={false}
          />

        </div>

        <ConfirmationModal
          title={i18n.t('Deactivate Selected Users?')}
          show={this.state.showDeactivateConfirmation}
          onConfirm={this.updateDeactivate}
          onCancel={this.onCancel}
        >
          {i18n.t('Are you sure you want to deactivate the %s selected users?',
            { postProcess: 'sprintf', sprintf: [this.state.selected_IDs.length.toLocaleString('en')] })
          }
        </ConfirmationModal>
        {newUser && (
          <ConfirmationModal
            title={i18n.t('New User Added Successfully!')}
            show={this.state.showCreateConfirmation}
            onConfirm={() => this.goToUserDetailsPage(newUser)}
            onCancel={this.onCreateConfirmCancel}
            confirmButtonText={i18n.t('View User Detail')}
          >
            {`${newUser.full_name} ${i18n.t('was added to our system. Please click ')}`}
            <a href="javascript:void(0)" onClick={this.copyUserPasswordToClipboard}>{i18n.t('here')}</a>
            {i18n.t(' to copy the password you set for the user.')}
          </ConfirmationModal>
        )}
        {showLOBSelectModal && (
          <BusinessLineSelectionModal
            businessLines={this.getUserBusinessLines()}
            onSelect={this.handleSelectUploadLOB}
            onClose={this.handleUploadModalClose}
            selectedBusinessLine={this.state.selectedUploadLOB}
          />
        )}
      </Section>
    ));
  }
}
