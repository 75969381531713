import PropTypes from 'prop-types';
import React from 'react';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import createStore from './store/embedContentDomainStore';

import EmbedContentDomainsContainer from './containers/EmbedContentDomainsContainer';
import EmbedContentDomainContainer from './containers/EmbedContentDomainContainer';


let index = (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);

  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/add" component={EmbedContentDomainContainer} />
        <Route path="/:id" component={EmbedContentDomainContainer} />
        <Route path="/" component={EmbedContentDomainsContainer} organizationId={props.organization_id} />
      </Router>
    </RootProvider>
  );
}

index.propTypes = {
  organization_id: PropTypes.string
}

export default index;
