import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from '@everfi/reactifi';
import TrainingPeriodsContainer from './containers/TrainingPeriodsContainer';
import createStore from './store/trainingPeriodsStore';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

const TrainingPeriodsIndex = (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route 
          path="/" 
          component={TrainingPeriodsContainer} 
          organizationId={props.organization_id}
          jwt={props.jwt}
          contentServiceUrl={props.content_service_url}
          viewer={props.viewer}
          permissions={props.permissions}
          userIsPrimaryManager={props.user_is_primary_manager}
          orgCapabilities={props.organization_capabilities}
          userManagedBusinessLines={props.user_managed_business_lines}
        />
      </Router>
    </RootProvider>
  );
};

TrainingPeriodsIndex.propTypes = {
  content_service_url: PropTypes.string,
  jwt: PropTypes.string,
  organization_capabilities: PropTypes.arrayOf(PropTypes.string),
  organization_id: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string),
  user_is_primary_manager: PropTypes.bool,
  user_managed_business_lines: PropTypes.arrayOf(PropTypes.string),
  viewer: PropTypes.string
};

export default TrainingPeriodsIndex;
