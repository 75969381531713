import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup, RegistrationFieldGroup, RegistrationFormField, FloatingLabelFormField, SelectControl } from 'reactifi';
import i18n from 'lib/i18n';

export default class LearnerRegistration extends React.Component {
  static propTypes = {
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    errorMessages: PropTypes.array,
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    onValidityCheck: PropTypes.func
  };

  supportedLanguages() {
    return [{ id: 'en', name: 'English' }, { id: 'es', name: 'Spanish' }];
  }

  render() {
    const { className, data, errorMessages, index, onValidityCheck } = this.props;
    return (
      <FieldGroup
        {...this.props}
        className={className}
        onValueChange={() => { onValidityCheck(true) }}
      >
        <RegistrationFieldGroup index={index} metadata={data.metadata} errorMessages={errorMessages}>
          <RegistrationFormField key="language">
            <FloatingLabelFormField caption={i18n.t("Preferred Language")}>
              <SelectControl
                data-select="preferred_language"
                joinValues={true} /* this is a hack that avoids code in react-select that uses a string ref */
                labelKey="label"
                multi={false}
                name="preferred_language"
                options={data.metadata.language.choices}
                valueKey="value"
              />
            </FloatingLabelFormField>
          </RegistrationFormField>
        </RegistrationFieldGroup>
      </FieldGroup>
    );
  }
}
