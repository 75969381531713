import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { PageWrapper, SelectControl, ConfirmationModal, Button, FilterBar, SelectFilter, Section, ExplainerText, MessagesModule, Header } from 'reactifi';
import { hasPermission } from 'reactifi/dist/es/utils/AccessControlUtil';
import mapStateToProps from '../store/trainingPeriodsMap';
import * as trainingPeriodActionCreators from '../actions/trainingPeriodsActionCreators';
import * as commonActionCreators from '../../common/actions/commonActionCreators';
import i18n from 'lib/i18n';
import TrainingPeriodsForm from '../components/TrainingPeriodsForm';
import TrainingPeriodsTable from '../components/TrainingPeriodsTable';
import { LOB_CAPABILITIES_MAP } from '../../common/capabilities';
import debounce from 'lodash/debounce';
import isEqual from "lodash/isEqual";

const STATUS_FILTER_OPTIONS = [{
  name: i18n.t('Show All Training Periods'),
  value: 'all'
}, {
  name: i18n.t('Show Active Training Periods'),
  value: 'active'
}, {
  name: i18n.t('Show Draft Training Periods'),
  value: 'draft'
}, {
  name: i18n.t('Show Archived Training Periods'),
  value: 'archived'
}];

export class TrainingPeriodsContainer extends React.Component {
  static propTypes = {
    businessLines: PropTypes.array,
    capabilities: PropTypes.array,
    collectionMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    contentServiceUrl: PropTypes.string.isRequired,
    currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    currentTrainingPeriod: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isLoadingTrainingPeriods: PropTypes.bool,
    organizationId: PropTypes.string,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    successMessage: PropTypes.string,
    token: PropTypes.string.isRequired,
    trainingPeriods: PropTypes.array,
    viewer: PropTypes.string,
    userManagedBusinessLines: PropTypes.arrayOf(PropTypes.string),
    userIsPrimaryManager: PropTypes.bool,
    permissions: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators({ ...trainingPeriodActionCreators, ...commonActionCreators }, props.dispatch);
    this.loadTrainingPeriodsDebounced = debounce(this.loadTrainingPeriods, 350, { leading: false, trailing: true });
    this.onFiltersChangeDebounced = debounce(this.onFiltersChange, 500);

    this.state = {
      activePage: 1,
      showModal: false,
      status: [STATUS_FILTER_OPTIONS.find(filter => filter.value === 'active').value],
      showDeleteModal: null,
      searchValue: ''
    };
  }

  async componentDidMount() {
    await this.actions.loadBusinessLines();
    await this.actions.loadRuleSets();
    this.loadTrainingPeriodsDebounced();

    if (this.isEverfiAdmin()) {
      // For FIT admins, we don't have capabilities available in the user
      await this.actions.selectOrgCapabilities(this.props.organizationId);
    }
  }

  componentDidUpdate(_prevProps, prevState) {
    if(this.shouldLoadTrainingPeriods(prevState, this.state)) {
      this.loadTrainingPeriodsDebounced();
    }
  }

  shouldLoadTrainingPeriods = (prevState, currentState) => {
    const statusChanged = !isEqual(prevState.status, currentState.status);
    const searchValueChanged = prevState.searchValue !== currentState.searchValue;
    const activePageChanged = prevState.activePage !== currentState.activePage;
    
    return statusChanged || searchValueChanged || activePageChanged;
  }

  isEverfiAdmin = () => {
    return this.props.viewer === 'admin';
  }

  loadTrainingPeriods = () => {
    const { status, searchValue, activePage } = this.state;
    this.actions.readTrainingPeriods(
      this.props.organizationId,
      status,
      searchValue,
      activePage,
      this.props.currentBusinessLine
    );
  }

  openModal = async id => {
    await this.actions.selectTrainingPeriod(id);
    this.setState({ showModal: true });
  }

  closeModal = () => {
    this.actions.selectTrainingPeriod(null);
    this.setState({ showModal: false });
  }

  onSelectTrainingPeriodStatusFilter = filterType => {
    if(!filterType) return
    if(filterType.value === "all") {
      this.setState({ status: STATUS_FILTER_OPTIONS.filter(status => status.value !== "all").map((val => val.value)) });
    } else {
      this.setState({ status: [filterType.value] });
    }
  }

  createTrainingPeriod = async trainingPeriod => {
    await this.actions.createTrainingPeriod(trainingPeriod);
    this.loadTrainingPeriodsDebounced();
  }

  deleteTrainingPeriod = async () => {
    await this.actions.deleteTrainingPeriod(this.state.showDeleteModal);
    this.onCancelDeleteTrainingPeriod();
    this.loadTrainingPeriodsDebounced();
  }

  onCancelDeleteTrainingPeriod = () => {
    this.setState({ showDeleteModal: null });
    this.actions.clearApiState('assignments');
  }

  getCapabilities = () => {
    if (!this.isEverfiAdmin()) {
      return this.props.orgCapabilities;
    }

    return this.props.capabilities.map(cap => cap.capability_type);
  }

  isBusinessLineAvailable = lobId => {
    const item = LOB_CAPABILITIES_MAP.find(lob => lob.id === lobId);
    const capabilities = this.getCapabilities();

    if (!item || !capabilities) {
      return false;
    }

    return capabilities.includes(item.capability);
  }

  getAvailableBusinessLines = () => {
    return this.props.businessLines.filter(lob => this.isBusinessLineAvailable(lob.id));
  }

  handleEditTrainingPeriod = period => {
    this.openModal(period.id);
  }

  handleDeleteTrainingPeriod = async period => {
    this.setState({ showDeleteModal: period });
  }

  handleSearchChange = ({ searchValue }) => {
    if(searchValue === this.state.searchValue) return;
    this.setState({ searchValue, activePage: 1 });
  }

  canEditTrainingPeriods = () => {
    if (this.isEverfiAdmin()) {
      return hasPermission('manage_training_periods', this.props.permissions);
    }

    return this.props.userIsPrimaryManager;
  }

  onPageChange = activePage => {
    this.setState({ activePage });
  }

  loadAssignmentByBusinessLine = async businessLine => {
    const response = await this.actions.loadRelatedAssignment(
      this.props.contentServiceUrl,
      this.props.token,
      this.props.currentTrainingPeriod.id,
      businessLine
    );

    return response.data;
  }

  loadAssignmentsByTrainingPeriodIds = async periodIds => {
    if (!periodIds?.length) {
      return [];
    }

    const response = await this.actions.loadRelatedAssignment(
      this.props.contentServiceUrl,
      this.props.token,
      periodIds,
      undefined,
      false
    );

    return response.data;
  }

  get dropdownFilter() {
    // temp code for single select filter until updated with new filters
    let val = this.state.status[0];
    if(this.state.status.length === 3) {
      val = "all";
    }

    return (
      <SelectControl
        className="toolbar-select m-t-0 m-r-20 training-period-select"
        clearable={false}
        dataValue={STATUS_FILTER_OPTIONS.find(status => status.value === val)}
        labelKey="name"
        name="training_period_status_filter"
        onChange={this.onSelectTrainingPeriodStatusFilter}
        options={STATUS_FILTER_OPTIONS}
        valueKey="value"
        valueOverride={true}
      />
    );
  }

  get actionButton() {
    if (!this.canEditTrainingPeriods()) {
      return null;
    }

    return (
      <Button
        onClick={() => this.openModal('add')}
        icon="plus"
        style={this.isEverfiAdmin() ? 'secondary' : 'primary'}
        data-action="new-training-period"
        label={i18n.t('New Training Period')}
      />
    );
  }

  onFiltersChange = (filterType) => {
    if(filterType.training_period_status){
      let statusValues = filterType.training_period_status.map((status) => {
        return status.value;
      });
      this.setState({ status: statusValues });
    } else {
      this.setState({ activePage: 1, status: ["active"] });
    }
  }

  renderFilters = () => {
    const options = [
      { id: 1, label: i18n.t('Active'), value: 'active' },
      { id: 2, label: i18n.t('Draft'), value: 'draft' } ,
      { id: 3, label: i18n.t('Archived'), value: 'archived' }
    ];

    const filtersConfig = [{
      name: 'training_period_status',
      label: i18n.t("Training Period Status"),
      options,
      isMulti: true,
      type: SelectFilter
    }];

    return (
      <FilterBar
        key={filtersConfig}
        filterConfig={filtersConfig}
        initialFilters={{ training_period_status: [1] }}
        searchBox={
          {
            isOnlySearch: false,
            fullWidth: false,
            searchAction: this.handleSearchChange,
            value: this.state.searchValue,
            placeholder: i18n.t('Search')
          }
        }
        onSelectFilters={this.onFiltersChangeDebounced}
      />
    );
  }

  get pageDescription() {
    return this.isEverfiAdmin() ?
      i18n.t('Training Periods are tags that allow customer admins to label assignments and later on use as filters to generate reports on learners progress. Training Periods help organizations meet their training goals.') :
      i18n.t('Training Periods help you manage your assignments by ensuring learners only receive content once per training cycle. We recommend naming each Training Period to represent a meaningful timeframe so you can easily use them when reporting on training progress.');
  }

  pageHero = (hasButton = true) => {
    return (
      <Section dataObjectType="explainer">
        <ExplainerText>{this.pageDescription}</ExplainerText>
        {hasButton && this.actionButton}
      </Section>
    );
  }

  renderPageWrapper = (children, isEverfiAdmin) => {
    if (isEverfiAdmin) {
      return (
        <>
          {this.pageHero()}
          {children}
        </>
      );
    }

    return (
      <PageWrapper>
        <MessagesModule
          successMessage={this.props.successMessage}
          errorMessage={this.props.currentTrainingPeriod ? null : this.props.errorMessage}
          clearMessages={this.actions.clearMessages}
        />
        <Header title={i18n.t('Training Periods')} button={this.actionButton} />
        {this.pageHero(false)}
        {children}
      </PageWrapper>
    );
  }

  renderTable() {
    return (
      <TrainingPeriodsTable
        data={this.props.trainingPeriods}
        fieldsProps={{
          onEdit: this.handleEditTrainingPeriod,
          onDelete: this.handleDeleteTrainingPeriod
        }}
        businessLines={this.getAvailableBusinessLines()}
        onPageChange={this.onPageChange}
        isLoadingData={this.props.isLoadingTrainingPeriods}
        totalCount={this.props.collectionMeta ? this.props.collectionMeta.total_count : 0}
        activePage={this.state.activePage}
        showActions={this.canEditTrainingPeriods()}
        isEverfiAdmin={this.isEverfiAdmin()}
        userManagedBusinessLines={this.props.userManagedBusinessLines}
        loadAssignmentsByTrainingPeriodIds={this.loadAssignmentsByTrainingPeriodIds}
      />
    );
  }

  render() {
    const { currentTrainingPeriod } = this.props;
    const isEverfiAdmin = this.isEverfiAdmin();

    return this.renderPageWrapper((
      <Section type="list" dataObjectType="training-periods">
        {this.renderFilters()}

        {currentTrainingPeriod && (
          <TrainingPeriodsForm
            currentTrainingPeriod={currentTrainingPeriod}
            businessLines={this.getAvailableBusinessLines()}
            onCancel={this.closeModal}
            addAction={this.createTrainingPeriod}
            updateAction={this.actions.updateTrainingPeriod}
            errorMessage={this.props.errorMessage}
            loadAssignmentByBusinessLine={this.loadAssignmentByBusinessLine}
            isEverfiAdmin={this.isEverfiAdmin()}
            userManagedBusinessLines={this.props.userManagedBusinessLines}
          />
        )}

        {this.renderTable()}

        <ConfirmationModal
          data={this.state.selectTrainingPeriod}
          show={!!this.state.showDeleteModal}
          title={i18n.t('Delete Training Period')}
          children={i18n.t('Are you sure you want to delete this training period?')}
          onConfirm={this.deleteTrainingPeriod}
          onCancel={this.onCancelDeleteTrainingPeriod}
        />
      </Section>
    ), isEverfiAdmin);
  }
}

export default connect(mapStateToProps)(TrainingPeriodsContainer);
