import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DisplayMaxWidthMedium,
  Header,
  Section,
  useContainerProps
} from 'reactifi';
import i18n from 'lib/i18n';
import { impactStoryPath } from '../common/paths';
import OrganizationImpactStoryExplainer from './impactStories/OrganizationImpactStoryExplainer';
import OrganizationImpactStoryForm from './impactStories/OrganizationImpactStoryForm';

const OrganizationImpactStory = props => {
  props = useContainerProps(props);
  const {
    currentEntity,
    copyMember,
    findOrganization,
    organization,
    params: {
      id: organizationId,
      impact_story_copy_id: impactStoryCopyId,
      impact_story_id: impactStoryId
    },
    router,
    selectMember
  } = props;

  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    if (!organization) {
      findOrganization(organizationId);
    }
  }, [organizationId]);

  useEffect(() => {
    if (impactStoryCopyId) {
      copyMember(impactStoryCopyId);
    } else {
      selectMember(impactStoryId);
    }
    setRefresh(true);
  }, [impactStoryCopyId, impactStoryId]);

  useEffect(() => {
    if (refresh) {
      setRefresh(false);
    }
  }, [refresh]);

  return (
    <>
      <Header
        back={() => router.push(impactStoryPath(organizationId))}
        title={i18n.t('Create Impact Story')}
      />
      <Section>
        <OrganizationImpactStoryExplainer />
      </Section>
      <Section type="cards">
        <DisplayMaxWidthMedium>
          {!refresh && currentEntity && <OrganizationImpactStoryForm />}
        </DisplayMaxWidthMedium>
      </Section>
    </>
  );
};

OrganizationImpactStory.propTypes = {
  copyMember: PropTypes.func,
  currentEntity: PropTypes.object,
  findOrganization: PropTypes.func,
  organization: PropTypes.object,
  params: PropTypes.shape({
    id: PropTypes.string,
    impact_story_copy_id: PropTypes.string,
    impact_story_id: PropTypes.string
  }),
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  selectMember: PropTypes.func
};

export default OrganizationImpactStory;
