import React from "react";
import PropTypes from "prop-types";
import {
  FieldGroup,
  FloatingLabelFormField,
  SelectControl
} from "reactifi";
import i18n from "lib/i18n";

export default class DataTypeStep extends React.Component {
  static propTypes = {
    cancelComplete: PropTypes.func.isRequired,
    canceling: PropTypes.bool,
    dataTypes: PropTypes.array,
    dataExport: PropTypes.shape({
      data_type: PropTypes.string
    }),
    displayErrorMessage: PropTypes.func.isRequired,
    goingToStep: PropTypes.number,
    stepChangeCancel: PropTypes.func.isRequired,
    stepChangeComplete: PropTypes.func.isRequired
  }

  componentDidUpdate(prevProps) {
    if (prevProps.goingToStep !== this.props.goingToStep) {
      this.validateNavigation();
    }
    if (this.props.canceling && !prevProps.canceling) {
      this.handleCancel();
    }
  }

  validateNavigation = () => {
    const {
      dataExport,
      displayErrorMessage,
      stepChangeCancel,
      stepChangeComplete
    } = this.props;

    if (!dataExport.data_type) {
      displayErrorMessage(i18n.t("Data Type is required"));
      stepChangeCancel();
    } else {
      stepChangeComplete();
    }
  }

  handleCancel = () => { this.props.cancelComplete(); }

  onDataTypeChange = (value) => {
    const { dataExport } = this.props;
    dataExport.data_type = value && value.type;
  }

  render() {
    const { dataExport, dataTypes } = this.props;

    return (<React.Fragment>
      <div>{i18n.t("Select the type of data you would like to download.")}</div>
      <FieldGroup className="row" data={dataExport}>
        <FloatingLabelFormField
          className="col-12"
          caption={i18n.t("Select Data Type")}
          name="data_type">
          <SelectControl
            onChange={this.onDataTypeChange}
            options={dataTypes}
            multi={false}
            valueKey="type"
            labelKey="label"
          />
        </FloatingLabelFormField>
      </FieldGroup>
    </React.Fragment>);
  }
}
