import { createEntity, selectEntity, deleteEntity, updateEntity, displayError, readEntityById, readEntity, displaySuccessMessage } from 'reactifi';
import i18n from 'lib/i18n';

export { clearMessages, displayError } from 'reactifi';

export function createItem(item) {
  return createEntity(item, (dispatch) => {
    dispatch(selectItem(null));
  }, i18n.t('Content domain created successfully.'));
}

export function loadItems() {
  return readEntity('embed_content_domains');
}

export function loadItem(id) {
  return readEntityById(id, 'embed_content_domains');
}

export function selectItem(id) {
  return selectEntity(id, 'embed_content_domains');
}

export function updateItem(item) {
  return updateEntity(item, (dispatch) => {
    dispatch(selectEntity(null, 'embed_content_domains'));
  }, i18n.t('Content domain updated successfully.'));
}

export function deleteItem(item) {
  return async (dispatch) => {
    try {
      await dispatch(deleteEntity(item, 'embed_content_domains'));
      dispatch(selectItem(null));
      dispatch(displaySuccessMessage(i18n.t('Content domain deleted successfully.')));
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}

export function findOrganization(id) {
  return readEntityById(id, 'organizations');
}
