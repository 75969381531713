import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { MessagesModule, PageWrapper, UrlUtil } from "reactifi";
import Content from "./Content";
import Hero from "./Hero";
import Nav from "./Nav";
import usePageActions from "./hooks/usePageActions";
import isEqual from 'lodash/isEqual';

const SUPPORTED_LOBS = Object.freeze(['higher-education', 'corporate-compliance', 'faculty-staff', 'scorm']);

const Page = props => {
  const {
    businessLines,
    clearUserAssignments,
    clearUserProgress,
    contentServiceUrl,
    currentViewer,
    findUserProgressByBusinessLine,
    jwt,
    user = {},
    viewer
  } = props;

  const { actions, components } = usePageActions(props);
  const [activeTab, setActiveTab] = useState(null);
  const [activePage, setActivePage] = useState(1);
  const [activeFilters, setActiveFilters] = useState({});
  const [availableBusinessLines, setAvailableBusinessLines] = useState([]);
  const [loadingProgress, setLoadingProgress] = useState(false);

  useEffect(() => {
    const isManager = (
      currentViewer?.['is_manager?'] ||
      currentViewer?.['is_scorm_manager?'] ||
      viewer === 'admin'
    );
    const isLearner = user["is_learner?"] || !!(((user || {}).user_types || {}).Scorm)

    // filter out LOBs outside user type and unsupported LOBs
    if (isLearner && user.business_lines && isManager) {
      const available = businessLines.filter(bl => user.business_lines.includes(bl.slug) && SUPPORTED_LOBS.includes(bl.slug));
      const contextBusinessLine = currentViewer && UrlUtil.getCurrentBusinessLine(currentViewer.rule_sets);
      // if role-based navigation is on and there is a selected role, return only that role LOBs
      const value = contextBusinessLine ? available.filter(bl => contextBusinessLine.includes(bl.slug)) : available;
      if (user?.business_lines?.includes("scorm")) {
        value.push({ id: 'scorm', label: 'SCORM', slug: 'scorm' })
      }
      if (value.length) {
        setAvailableBusinessLines(value)
      }
    }
  }, [businessLines, user, currentViewer]);

  const getUserProgress = async () => {
    if (activeTab) {
      setLoadingProgress(true);
      await findUserProgressByBusinessLine(
        activeTab,
        contentServiceUrl,
        user.id,
        jwt,
        activePage,
        activeFilters);
      setLoadingProgress(false);
    }
  };

  const onFilterChange = (filters) => {
    if (filters && !isEqual(filters, activeFilters)) {
      clearProgress();
      setActivePage(1);
      setActiveFilters(filters);
    }
  }

  const onPageChange = (page) => {
    clearProgress();
    if (page === activePage) { //trigger the get if on page1, changing pageSize
      getUserProgress();
    } else {
      setActivePage(page);
    }
  }

  const clearProgress = () => {
    clearUserProgress();
  }

  useEffect(() => {
    getUserProgress();
  }, [activePage, activeFilters])

  useEffect(() => {
    if (activeTab !== "scorm") {
      getUserProgress();
    }
  }, [activeTab])

  useEffect(() => {
    if (availableBusinessLines.length && !activeTab) {
      setActiveTab(availableBusinessLines[0].id);
    }
  }, [availableBusinessLines])

  const onTabChange = (tabKey) => {
    clearUserAssignments();
    clearProgress();
    setActiveTab(tabKey);
  }

  return (
    <PageWrapper>
      <MessagesModule {...props} />
      <Nav {...props} {...actions} />
      {!!user && (
        <>
          <Hero {...props}
            availableBusinessLines={availableBusinessLines}
            selectedBusinessLine={activeTab}
            onTabChange={onTabChange} />
          <Content {...props}
            activeTab={activeTab}
            activeFilters={activeFilters}
            availableBusinessLines={availableBusinessLines}
            selectedBusinessLine={activeTab}
            onFilterChange={onFilterChange}
            onPageChange={onPageChange}
            loadingProgress={loadingProgress} />
          {components}
        </>
      )}
    </PageWrapper>
  );
};

Page.propTypes = {
  businessLines: PropTypes.array,
  clearUserAssignments: PropTypes.func,
  clearUserProgress: PropTypes.func,
  contentServiceUrl: PropTypes.string,
  currentViewer: PropTypes.object,
  findUserAssignmentsByBusinessLine: PropTypes.func,
  findUserProgressByBusinessLine: PropTypes.func,
  jwt: PropTypes.string,
  resetPassword: PropTypes.func,
  user: PropTypes.object,
  viewer: PropTypes.string
};

export default Page;
