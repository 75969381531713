import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ConfirmationModal, ExplainerText, Header, MaterialCheckbox, Section, useContainerProps } from 'reactifi';
import i18n from 'lib/i18n';
import TagManagersList from './TagManagersList';

const TagManagers = props => {
  props = useContainerProps(props);
  const { organization = {}, organizationId } = props;
  const [bannerEnabled, setBannerEnabled] = useState(props.tagManagerBannerEnabled);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    props.findOrganization(organizationId);
  }, [organizationId]);

  const button = {
    action: () => props.selectMember('add'),
    label: i18n.t('Add Tag Manager'),
    icon: 'plus'
  };

  const toggleTagManagerBanner = async () => {
    const updatedOrg = Object.assign(organization, { tag_manager_banner_enabled: !bannerEnabled });
    await props.updateOrganization(updatedOrg);

    setBannerEnabled(enabled => !enabled);
    setShowConfirmationModal(false);
  }

  const renderToggleBannerConfirmModal = () => {
    const modalBody = bannerEnabled 
      ? i18n.t('Are you sure you want to disable the tag manager cookie acceptance banner on your learner-facing pages?')
      : i18n.t('Are you sure you want to enable the tag manager cookie acceptance banner? It will now be visible across all of your learner-facing pages.');
    
    return (
      <ConfirmationModal
        children={modalBody}
        show={showConfirmationModal}
        onConfirm={toggleTagManagerBanner}
        onCancel={() => setShowConfirmationModal(false)}
        confirmButtonText={i18n.t('Yes')}
        cancelButtonText={i18n.t('No')}
      />
    );
  }

  return (
    <>
      <Header button={button} title={i18n.t('Tag Managers')} />
      <Section>
        <ExplainerText>
          {i18n.t('By default a banner will appear on your site to allow learners to accept the cookies from the tag manager. This banner can be disabled by checking the box below.')}
        </ExplainerText>
        <MaterialCheckbox
          dataValue={!bannerEnabled}
          label={i18n.t('Disable tag manager cookie acceptance banner')}
          validateChange={() => setShowConfirmationModal(true)}
          valueOverride={true}
          className="m-b-0 p-b-0"
          data-object-action="disable-tag-manager-cookie-banner"
        />
      </Section>
      <Section type="list">
        <TagManagersList />
      </Section>
      {renderToggleBannerConfirmModal()}
    </>
  );
};

TagManagers.propTypes = {
  findOrganization: PropTypes.func,
  organization: PropTypes.object,
  organizationId: PropTypes.string,
  selectMember: PropTypes.func,
  tagManagerBannerEnabled: PropTypes.bool,
  updateOrganization: PropTypes.func
};

export default TagManagers;
