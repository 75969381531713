import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator, buildGenericProps } from 'reactifi';

import ChartsPage from '../components/ChartsPage';
import * as actionCreators from '../actions/chartsPageActionCreators';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "data_exports", {});
  let apiStore = new JsonApiDecorator({ contexts: ownProps.route.contexts });
  
  props.chartId = ownProps.routeParams.id;
  props.contexts = apiStore.contexts.all();
  props.context = props.contexts.find(context => String(context.chart_id) === String(props.chartId))
  props.errorMessage = state.api.errorMessage;

  return props;

}

class ChartsPageContainer extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(actionCreators, this.props.dispatch);
  }

  render() {
    return (
      <ChartsPage {...this.props} {...this.actions} />
    )
  }

}

export default connect(mapStateToProps)(ChartsPageContainer);
