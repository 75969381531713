import React from 'react';
import PropTypes from 'prop-types';
import { AvailableField, IconCategoryValue, StringUtil } from 'reactifi';
import i18n from 'lib/i18n';
import isEqual from 'lodash/isEqual';

export default class RegistrationBaseReadOnly extends React.Component {
  static propTypes = {
    registration: PropTypes.object,
    ruleSets: PropTypes.array,
    user: PropTypes.object,
    isSelfRegistered: PropTypes.bool,
    target: PropTypes.string,
    unlimitedAccess: PropTypes.bool
  }

  constructor(props) {
    super(props);
    if (this.props.registration && this.props.registration.metadata) {
      this.state = {
        availableFields: Object.entries(this.props.registration.metadata)
          .filter(([_, value]) => value.available)
          .map(([key, _]) => key)
      }
    } else {
      this.state = {};
    }
  }

  shouldComponentUpdate(nextProps) {
    // Skip re-render unless user changes
    return !isEqual(nextProps.registration, this.props.registration);
  }

  getNofificationSettings = () => {
    const { registration } = this.props;
    const inv = registration.suppress_invites ? 'off' : 'on';
    const rem = registration.suppress_reminders ? 'off' : 'on';
    return (<>
      {i18n.t('Invitations {{ invStatus }}, Reminders {{remStatus }}', { invStatus: inv, remStatus: rem })}
    </>);
  }

  getPrivacySettings = () => {
    const { registration } = this.props;
    const marketing = registration.opt_out_of_marketing ? 'off' : 'on';
    const sales = registration.opt_out_of_sales_data ? 'off' : 'on';
    return (<>
      {i18n.t('Targeted ads {{marketingStatus}}, Sale of data {{salesStatus}}', { marketingStatus: marketing, salesStatus: sales })}
    </>);
  }

  matchesTarget = key => {
    const { target } = this.props;
    return !target || target === key;
  }

  renderDetails = () => {
    const { isSelfRegistered, registration, ruleSets, unlimitedAccess, user } = this.props;
    const { availableFields } = this.state;

    if (!user || !this.matchesTarget("details")) {
      return null;
    }

    let ruleSetValues;
    if (registration.display_all_user_rule_sets) {
      ruleSetValues = (
        <>
          {user.rule_sets.sort((ruleSet_a, ruleSet_b) => ruleSet_a.id - ruleSet_b.id).map((ruleSet) => {
            return (
              <div data-action={`${ruleSet.label}-${ruleSet.role}`} key={ruleSet.id}>{ruleSet.label}: {StringUtil.UnderscoresToTitleCase(ruleSet.role)}</div>
            )
          })}
        </>
      );
    } else {
      ruleSetValues = ruleSets ? (
        <>
          {Object.entries(registration.rule_set_roles).sort(([ruleA], [ruleB]) => ruleA.rule_set_id - ruleB.rule_set_id).map(([rule_set_id, role_slug]) => {

            let ruleSet = ruleSets.find((rs) => String(rs.id) === String(rule_set_id));
            let role = ruleSet ? ruleSet.roles.find((role) => role.slug === role_slug) : null;

            return ruleSet && role ? <div key={ruleSet.id}>{ruleSet.label}: {StringUtil.Capitalize(role.label)}</div> : null;
          })}
        </>
      ) : null;
    }

    return (
      <>
        <IconCategoryValue
          category={i18n.t('User Type: Role')}
          type="stacked"
          value={ruleSetValues}
          data-action="user-type-role"
        />

        {!isSelfRegistered && unlimitedAccess &&
          <>
            <AvailableField names={['suppress_invites', 'suppress_reminders']} available={availableFields}>
              <IconCategoryValue
                category={i18n.t('Notification Settings')}
                type="inline"
                value={this.getNofificationSettings()}
                data-action="notification-settings"
              />
            </AvailableField>
            <IconCategoryValue
              category={i18n.t('Privacy Settings')}
              type="inline"
              value={this.getPrivacySettings()}
              data-action="privacy-settings"
            />
          </>
        }
      </>
    );
  }

  renderLocation = () => {
    if (!this.matchesTarget("location")) {
      return null;
    }

    const { registration } = this.props;
    const { availableFields } = this.state;

    return (
      <>
        {registration.does_not_belong_to_any_location ?
          <AvailableField names={['work_address']} available={availableFields}>
            <IconCategoryValue
              category={i18n.t('Location')}
              icon="location-dot"
              value={registration.work_address_formatted}
              data-action="user-location"
            />
          </AvailableField> :
          <AvailableField names={['location_id']} available={availableFields}>
            <IconCategoryValue
              category={i18n.t('Location')}
              icon="location-dot"
              value={(registration.location || {}).name || i18n.t('No Location')}
              data-action="user-location"
            />
          </AvailableField>}
      </>
    );
  }

  renderTeam = () => {
    if (!this.matchesTarget("team")) {
      return null;
    }

    const { user = {} } = this.props;

    const teamNames = (user.teams_as_member || []).map(team => team.name);

    if (!teamNames.length) {
      return null;
    }

    return (
      <IconCategoryValue
        category={i18n.t('Team')}
        data-action="user-team"
        type="stacked"
        value={(
          <>
            {teamNames.map((teamName, index) => (
              <div key={index}>{teamName}</div>
            ))}
          </>
        )}
      />
    );
  }

  render() {
    const { registration } = this.props;

    if (!registration) {
      return null;
    }

    return (
      <>
        {this.renderDetails()}
        {this.renderLocation()}
        {this.renderTeam()}
      </>
    )
  }
}
