import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  ModalForm,
  FieldGroup,
  FormField,
  FloatingLabelFormField,
  SelectControl,
  FormSection
} from 'reactifi';
import i18n from "lib/i18n";

const PERIOD_STATUSES = [
  {
    label: i18n.t('Active'),
    value: 'active'
  }, {
    label: i18n.t('Draft'),
    value: 'draft'
  }, {
    label: i18n.t('Archived'),
    value: 'archived'
  }
];

export default class TrainingPeriodsForm extends Component {
  static propTypes = {
    addAction: PropTypes.func,
    businessLines: PropTypes.array,
    currentTrainingPeriod: PropTypes.object,
    errorMessage: PropTypes.string,
    isEverfiAdmin: PropTypes.bool,
    loadAssignmentByBusinessLine: PropTypes.func,
    onCancel: PropTypes.func,
    updateAction: PropTypes.func,
    userManagedBusinessLines: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);

    this.state = {
      assignmentLockedBusinessLines: []
    };
  }

  componentDidMount() {
    const { currentTrainingPeriod } = this.props;
    if (!currentTrainingPeriod.id) {
      return;
    }

    // only when editing a training period, check which business lines are
    // related to an assignment and cannot be removed
    currentTrainingPeriod.business_lines.forEach(async lob => {
      const related = await this.props.loadAssignmentByBusinessLine(lob);
      if (related && related.length) {
        this.setState({ 
          assignmentLockedBusinessLines: [...this.state.assignmentLockedBusinessLines, lob] 
        });
      }
    });
  }

  isSelectableBusinessLine = lob => {
    const { currentTrainingPeriod, userManagedBusinessLines, isEverfiAdmin } = this.props;

    return isEverfiAdmin || currentTrainingPeriod.business_lines.includes(lob.slug) || userManagedBusinessLines.includes(lob.slug);
  }

  getEditableBusinessLines = () => {
    const { currentTrainingPeriod, businessLines, userManagedBusinessLines, isEverfiAdmin } = this.props;
    const { assignmentLockedBusinessLines } = this.state;

    let editableBusinessLines = [];
    if (currentTrainingPeriod.id) {
      // if editing a training period, only admin's own managed business lines
      // or already selected ones are selectable, and after that check that business lines
      // with related assignments cannot be cleared from the SelectControl

      editableBusinessLines = businessLines
        .filter(lob => this.isSelectableBusinessLine(lob))
        .map(lob => {
          const isReadOnly = (!isEverfiAdmin && !userManagedBusinessLines.includes(lob.slug)) || assignmentLockedBusinessLines.includes(lob.slug);

          return {
            slug: lob.slug,
            label: lob.label,
            clearableValue: !isReadOnly,
            disabled: isReadOnly
          };
        });
    } else {
      // if new training period, allow admin's own managed business lines (all for FIT admin)
      editableBusinessLines = isEverfiAdmin ? businessLines : businessLines.filter(lob => userManagedBusinessLines.includes(lob.slug));
    }

    return editableBusinessLines;
  }

  getHiddenBusinessLines = () => {
    const { currentTrainingPeriod } = this.props;
    const editableBusinessLines = this.getEditableBusinessLines();

    if (!currentTrainingPeriod.id || !currentTrainingPeriod.business_lines) {
      return [];
    }

    return currentTrainingPeriod.business_lines.filter(lob => !editableBusinessLines.some(editableLob => editableLob.slug === lob));
  }

  get modalTitle() {
    const { currentTrainingPeriod } = this.props;

    if (!currentTrainingPeriod || !currentTrainingPeriod.id) {
      return i18n.t('Create New Training Period');
    }

    return i18n.t(`Edit ${currentTrainingPeriod.name}`);
  }

  render() {
    const { currentTrainingPeriod } = this.props;
    const canClearAllBusinessLines = !currentTrainingPeriod || !currentTrainingPeriod.id;
    const hiddenBusinessLines = this.getHiddenBusinessLines();

    return (
      <ModalForm
        data={currentTrainingPeriod}
        errorMessage={this.props.errorMessage}
        title={this.modalTitle}
        onCancel={this.props.onCancel}
        addAction={this.props.addAction}
        updateAction={this.props.updateAction}
        saveAndAddButton={false}
      >
        <FieldGroup className="container-fluid">
          <FormSection>
            <FormField name="organization_id" type="hidden" className="hidden" />
            <div className="row m-b-15">
              {i18n.t('Use training periods to create assignments for your users during a time frame of your choosing. For example, you can create a yearly compliance training period, or a training period for the current school year.')}
            </div>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="name"
                  caption={i18n.t('Training Period Name')}
                  required={true}
                  hintText={i18n.t('Tip: Use date and/or duration as part of the name.')}
                />
              </FieldGroup>
            </FieldGroup>

            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="business_lines"
                  required={true}
                  caption={i18n.t('Lines of Business')}
                  hintText={i18n.t('You may select multiple lines of business for one training period.')}
                >
                  <SelectControl
                    options={this.getEditableBusinessLines()}
                    multi={true}
                    valueKey="slug"
                    labelKey="label"
                    valueOverride={false}
                    clearable={canClearAllBusinessLines}
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
            {hiddenBusinessLines.map(lob => <input key={lob} type="hidden" name="business_lines[]" value={lob} />)}

            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField 
                  name="description" 
                  caption={i18n.t('Description')}
                  as="textarea"
                />
              </FieldGroup>
            </FieldGroup>

            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="status"
                  required={true}
                  caption={i18n.t('Training Period State')}
                  hintText={i18n.t('Use the Active state to begin using this training period right away, or use the Draft state if you’d like to activate it later.')}
                >
                  <SelectControl
                    options={PERIOD_STATUSES}
                    multi={false}
                    valueKey="value"
                    labelKey="label"
                    valueOverride={false}
                  />
                </FloatingLabelFormField>
              </FieldGroup>
            </FieldGroup>
          </FormSection>
        </FieldGroup>
      </ModalForm>
    )
  }
}