import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import { JsonApiDecorator, FieldGroup, Form, PageHeader, ConfirmationModal } from "reactifi";
import i18n from "lib/i18n";

export default class SimpleEdit extends React.Component {
  static propTypes = {
    actionCreators: PropTypes.any,
    beforeSave: PropTypes.func,
    children: PropTypes.any,
    confirmBeforeSave: PropTypes.bool,
    confirmTitle: PropTypes.string,
    confirmBody: PropTypes.node,
    dispatch: PropTypes.any,
    errorMessage: PropTypes.string,
    errorMessages: PropTypes.any,
    formHasError: PropTypes.func,
    goBackAfterAdd: PropTypes.bool,
    item: PropTypes.any,
    itemId: PropTypes.any,
    router: PropTypes.any,
    title: PropTypes.string
  };

  componentDidMount() {
    if (this.props.itemId) {
      this.actions.loadItem(this.props.itemId);
    }
  }

  static mapStateToProps = resourceName => {
    return (state, ownProps) => {
      let apiStore = new JsonApiDecorator(state.api);

      let props = {
        itemId: ownProps.routeParams.id,
        errorMessages: state.api.errorMessages,
        errorMessage: state.api.errorMessage,
        successMessage: state.api.successMessage
      };

      if (apiStore[resourceName] && props.itemId) {
        props.item = apiStore[resourceName].find(props.itemId);
      }

      if (!props.itemId) {
        props.item = apiStore.newObject(resourceName);
      }

      return props;
    };
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      props.actionCreators,
      this.props.dispatch
    );

    this.state = {
      disableButtons: false,
      showConfirmModal: false,
      operationType: null,
      action: null,
      item: null
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.confirmBeforeSave !== state.confirmBeforeSave) {
      return {
        confirmBeforeSave: props.confirmBeforeSave
      }
    }
    return null;
  }

  formErrorCheck = () => {
    // backwards compatibility check
    if (typeof this.props.formHasError === 'function') {
      return this.props.formHasError()
    }
    return false;
  }

  async _saveAction(method, type, item, confirmBeforeSave) {
    if (this.formErrorCheck()) {
      this.actions.displayErrorMessage(i18n.t('Please check form for errors'));
    } else {
      if (confirmBeforeSave) {
        this.setState({
          showConfirmModal: true,
          operationType: type,
          action: method,
          item
        });
      } else {
        let beforeSave = this.props.beforeSave;
        if (beforeSave) {
          beforeSave(item);
        }
        this.setState({ disableButtons: true });
        const response = await method(item);

        if (response && !this.props.errorMessages) {
          if (type === 'update' || this.props.goBackAfterAdd) {
            this.props.router.push('/');
          } else {
            this.props.router.push(`/${response.data.id}`);
          }
        }
      }
    }
    this.setState({ disableButtons: false });
  }

  updateAction = async item => {
    await this._saveAction(this.actions.updateItem, 'update', item, this.state.confirmBeforeSave);
  }

  addAction = async item => {
    await this._saveAction(this.actions.createItem, 'add', item, this.state.confirmBeforeSave);
  }

  onCancel = () => this.setState({ showConfirmModal: false });

  onConfirm = async () => {
    const { action, operationType, item } = this.state;
    await this._saveAction(action, operationType, item, false);
    this.setState({ showConfirmModal: false });
  }

  render() {
    if (!this.props.item) {
      return <div />;
    }

    return (
      <div className="form-view-wrapper">
        {this.props.title ?
          <PageHeader title={this.props.title || ""} /> : ''}
        <br />
        <Form
          data={this.props.item}
          onCancel={() => this.props.router.push("/")}
          updateAction={this.updateAction}
          addAction={this.addAction}
          errorMessage={this.props.errorMessage}
          disableButtons={this.state.disableButtons}
          buttonGroupClass="modal-footer"
          saveButtonAreaClassName="form-buttons-container-right"
        >
          <FieldGroup className="container-fluid">
            {this.props.children &&
              this.props.children.map((child, idx) => (
                <FieldGroup className={`col-lg-12`} key={idx}>
                  {child}
                </FieldGroup>
              ))}
          </FieldGroup>
        </Form>
        <ConfirmationModal
          title={this.props.confirmTitle}
          children={this.props.confirmBody || i18n.t('Are you sure?')}
          onConfirm={this.onConfirm}
          onCancel={this.onCancel}
          show={this.state.showConfirmModal}
        />
      </div>
    );
  }
}
