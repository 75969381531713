import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup,
  FileUpload,
  s3MediaUploader,
  Util
} from 'reactifi';

export default class BrandingFaviconWell extends Component {
  static propTypes = {
    currentOrganization: PropTypes.object,
    deleteAction: PropTypes.func,
    displayError: PropTypes.func,
    faviconImg: PropTypes.object,
    faviconImgUrl: PropTypes.string,
    onFaviconImageUpload: PropTypes.func
  }

  render() {
    let { faviconImg, faviconImgUrl, currentOrganization } = this.props;
    return (
      <FieldGroup className="branding-well-container" data={currentOrganization}>
        <FieldGroup className="row">
          <FileUpload
            accept={['.jpg', '.jpeg', '.png', '.ico']}
            actions={{
              delete: this.props.deleteAction,
              failure: this.props.displayError,
              success: this.props.onFaviconImageUpload
            }}
            button={{
              className: 'btn btn-secondary',
              dataAttributes: Util.dataAttribsForEl(currentOrganization, { action: 'upload-favicon' }),
              text: i18n.t('Upload Favicon')
            }}
            className="file-upload-inline favicon-upload"
            data-action="favicon-image-card"
            deleteButton={true}
            deleteButtonDataAttributes={Util.dataAttribsForEl(faviconImg, { action: 'delete-favicon' })}
            description={{
              textArray: [i18n.t('Image format: JPG, PNG or ICO')]
            }}
            embedPreviewUrl={faviconImgUrl}
            header={{ text: i18n.t('Favicon Image') }}
            icon={{
              className: 'images',
              iconSize: 'medium',
              fileClassName: 'file',
              noFileClassName: 'file-slash'
            }}
            input={{ name: 'favicon_image' }}
            instructionalText={i18n.t("Uploaded image will appear next to the website name in the browser tab, scaled and constrined to 16px in height.")}
            label={{ text: faviconImg.name, closable: false }}
            name="favicon_image"
            uploader={s3MediaUploader}
          />
        </FieldGroup>
      </FieldGroup>
    )
  }
}
