import React from "react";
import PropTypes from "prop-types";
import { IconCategoryValue } from "reactifi";
import i18n from "lib/i18n";
import { Util } from "@everfi/reactifi"

export default class SurveyData extends React.Component {
  static propTypes = {
    dataExport: PropTypes.shape({
      filters: PropTypes.object,
      display_filters: PropTypes.string
    }).isRequired
  }

  renderDateRange = () => {
    const { dataExport: { filters: { begin_date, end_date } } } = this.props;
    const beginDate = begin_date ?
      Util.format(begin_date, 'MM/dd/yyyy') :
      i18n.t('beginning');
    const endDate = end_date ?
      Util.format(end_date, 'MM/dd/yyyy') :
      i18n.t('now');
    const date = begin_date || end_date ? `${beginDate} - ${endDate}` : i18n.t('all');
    return <IconCategoryValue category={i18n.t('Date range')} value={date} />;
  }

  render() {
    const { dataExport: { display_filters } } = this.props;
    const displayFilters = JSON.parse(display_filters || '{}');

    return (
      <>
        <IconCategoryValue category={i18n.t('Course')} value={displayFilters.content} />
        <IconCategoryValue category={i18n.t('Evaluation')} value={displayFilters.evaluation} />
        {this.renderDateRange()}
      </>
    );
  }
}
