import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import {
  Section,
  PageWrapper,
  Header,
  ExplainerText,
  MessagesModule,
  buildGenericProps,
  JsonApiDecorator,
  SearchComponent,
  Button,
  UrlUtil
} from 'reactifi';
import * as teamsActionCreators from '../actions/teamsActionCreators';
import TeamTable from '../components/TeamTableComponent';
import TeamForm from '../components/TeamForm';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'teams', ['category_labels', 'locations']);
  const apiStore = new JsonApiDecorator(state.api);

  if (props.currentEntity) {
    props.currentTeam = props.currentEntity;
  }

  props.organizationId = ownProps.route.organizationId;
  props.viewer = ownProps.route.viewer;
  props.userManagedBusinessLines = ownProps.route.userManagedBusinessLines;
  props.orgCapabilities = ownProps.route.orgCapabilities;
  props.userRuleSetRoles = ownProps.route.userRuleSetRoles;
  props.viewerId = ownProps.route.viewerId;
  props.teamMeta = state.api.teams ? state.api.teams.meta : null;
  props.categories = apiStore.categories ? apiStore.categories.all('category_labels') : [];
  props.category_labels = apiStore.category_labels ? apiStore.category_labels.all() : [];
  props.businessLines = apiStore.business_lines ? apiStore.business_lines.all() : [];
  props.ruleSets = apiStore.rule_sets ? apiStore.rule_sets.all() : [];
  props.currentBusinessLine = props.userManagedBusinessLines.length > 1
    ? UrlUtil.getCurrentBusinessLine(props.ruleSets)
    : props.userManagedBusinessLines[0];

  props.isAdvisor = ['advisor', 'pii_restricted_next_advisor'].some(val => Object.values(props.userRuleSetRoles).includes(val));

  return props;
}

export class TeamsContainer extends React.Component {
  static propTypes = {
    businessLines: PropTypes.array,
    categories: PropTypes.array,
    category_labels: PropTypes.array,
    currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    currentTeam: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isAdvisor: PropTypes.bool,
    isLoadingTeams: PropTypes.bool,
    organizationId: PropTypes.string,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    route: PropTypes.shape({
      childRoutes: PropTypes.array,
      routeKey: PropTypes.string
    }),
    router: PropTypes.shape({
      push: PropTypes.func
    }),
    ruleSets: PropTypes.array,
    successMessage: PropTypes.string,
    teamMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    teams: PropTypes.array,
    userManagedBusinessLines: PropTypes.arrayOf(PropTypes.string),
    userRuleSetRoles: PropTypes.object,
    viewer: PropTypes.string.isRequired,
    viewerId: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(teamsActionCreators, this.props.dispatch);

    this.state = {
      activePage: 1,
      showModal: false,
      selectedTeam: null
    }
  }

  async componentDidMount() {
    await this.actions.loadBusinessLines();
    await this.actions.loadCategories({ organization_id: this.props.organizationId });
    await this.actions.loadRuleSets();
    this.getTeams();
  }


  componentDidUpdate(prevProps) {
    const { currentBusinessLine, route, router } = this.props;

    const childRoute = route?.childRoutes?.find(route => route.routeKey === currentBusinessLine);
    if(childRoute && currentBusinessLine !== prevProps.currentBusinessLine) {
      router.push(`/${childRoute.path}`);
    }
  }

  onPageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, this.getTeams);
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sort: {
        value: sortName,
        direction: sortOrder
      },
      sortName,
      sortOrder,
      activePage: 1
    }, this.getTeams);
  }

  getTeams = () => {
    this.actions.readTeams(
      'category_labels,locations',
      Object.assign({}, this.state.filters, { business_line: this.props.currentBusinessLine || this.state.selectedTab }),
      this.state.sort,
      { number: this.state.activePage }
    );
  }

  handleSearchChange = (filters) => {
    this.setState({
      filters,
      activePage: 1
    }, () => this.actions.readTeams('category_labels,locations', Object.assign({}, this.state.filters, { business_line: this.props.currentBusinessLine || this.state.selectedTab }), this.state.sort, { number: this.state.activePage }));
  }

  handleCreateTeam = async (team, addAnother) => {
    var entity = await this.actions.createTeam(team, addAnother);
    if (entity) {
      setTimeout(this.getTeams, 1000);
    }
  }

  // Temporary hide for ECA-6013
  // handleDeleteTeam = selectedTeam => this.setState({ selectedTeam, showModal: true });

  handleViewTeam = team => this.props.router.push(`/team/${team.id}`);

  handleEditTeam = team => this.actions.selectTeam(team.id);

  // Temporary hide for ECA-6013
  // deleteTeam = () => {
  //   this.actions.deleteTeam(this.state.selectedTeam);
  //   this.onCancelDeleteTeam();
  // }

  // Temporary hide for ECA-6013
  // onCancelDeleteTeam = () => this.setState({
  //   selectedTeam: null,
  //   showModal: false
  // })

  onTabSelect = (tabpath) => {
    let selectedTab = this.props.route.childRoutes.find(route => route.routeKey === tabpath).routeKey;
    this.setState({
      selectedTab,
      activePage: 1
    }, this.getTeams);
  }

  getUserBusinessLines = () => {
    const { currentBusinessLine } = this.props;

    return currentBusinessLine ? [{ id: currentBusinessLine }] : [];
  }

  getUserTeamsAsManager = () => {
    const { ruleSets } = this.props;

    return ruleSets.filter(rule => rule.is_manager).reduce((acc, curr) => {
      return [...acc, ...curr.business_lines];
    }, []);
  }

  get actionButton() {
    return (
      <Button
        onClick={() => this.actions.selectTeam('add')}
        style="primary"
        icon="plus"
        data-action="create-team"
        label={i18n.t('Create a Team')}
      />
    );
  }

  get headerTabs() {
    return null;
  }

  get explainerText() {
    const { viewer, currentBusinessLine } = this.props;
    const isAtWorkAdmin = viewer === 'organization_administrator' && currentBusinessLine === 'at-work';
    const text = isAtWorkAdmin
      ? i18n.t('Use Teams to group Advisors as Team Managers. As Team Managers, Advisors can  view learning content and email invitations to their contacts.')
      : i18n.t('Use Teams to automatically group users by their locations and custom categories. Teams can be used to quickly assign content to groups of learners and view reports on their progress. Add Team Managers to allow users to create and view assignments for their Teams without giving them Primary Admin permissions. For more info, please visit our');

    return (
      <ExplainerText>
        {text}
        {!isAtWorkAdmin && (
          <>
            <a href="https://help.everfi.com/s/article/Teams" target="_blank" rel="noopener noreferrer">{i18n.t('help site')}</a>.
          </>
        )}
      </ExplainerText>
    );
  }

  render() {
    const userBusinessLines = this.getUserBusinessLines();
    const userTeamsAsManager = this.getUserTeamsAsManager();
    const userContext = this.props.currentBusinessLine || this.state.selectedTab;

    return (
      <PageWrapper>
        <MessagesModule {...this.props} clearMessages={this.actions.clearMessages}/>
        <Header
          button={userTeamsAsManager.includes(userContext) && !this.props.isAdvisor && this.actionButton}
          title={i18n.t('Teams')}
          router={this.props.router}
          data={{ objectType: 'teams', objectId: this.props.teams.id }}
        >
          {this.headerTabs}
        </Header>
        <Section>
          {this.explainerText}
        </Section>
        <Section type="list" noBorder={true} noPadding={true}>
          <SearchComponent
            name="keyword"
            placeholder={i18n.t('Search by Team Name')}
            searchAction={this.handleSearchChange}
          />
        </Section>
        <Section type="list">
          {this.props.currentTeam && (
            <TeamForm
              orgCapabilities={this.props.orgCapabilities}
              currentBusinessLine={this.props.currentBusinessLine}
              currentTeam={this.props.currentTeam}
              selectTeam={this.actions.selectTeam}
              clearMessages={this.actions.clearMessages}
              errorMessage={this.props.errorMessage}
              isAdvisor={this.props.isAdvisor}
              viewer={this.props.viewer}
              organizationId={this.props.organizationId}
              updateTeam={this.actions.updateTeam}
              createTeam={this.handleCreateTeam}
              businessLines={userBusinessLines}
              categories={this.props.categories}
              categoryLabels={this.props.category_labels}
              ruleSets={this.props.ruleSets}
              loadRuleSets={this.actions.loadRuleSets}
            />
          )}
          {this.props.isLoadingTeams || this.props.teams.length ? (
            <TeamTable
              data={this.props.teams}
              options={{
                onSortChange: this.onSortChange,
                ...this.state.sort
              }}
              fieldsProps={{
                viewer: this.props.viewer,
                onEdit: this.handleEditTeam,
                onView: this.handleViewTeam
                // Temporary hide for ECA-6013
                // onDelete: this.handleDeleteTeam
              }}
              onPageChange={this.onPageChange}
              isLoadingData={this.props.isLoadingTeams}
              totalCount={this.props.teamMeta ? this.props.teamMeta.total_count : 0}
              activePage={this.state.activePage}
            />
          ) : (
            <div className="no-results-simple text-center m-t-20">
              <p className="m-5">{i18n.t('You don\'t have any Teams yet.')}</p>
              <p className="m-5">
                {
                  this.props.isAdvisor ?
                    i18n.t('Contact your Administrator to be added to a team.')
                    :
                    i18n.t('Click "Create a Team" above to get started.')
                }
              </p>
            </div>
          )}
          {/*
          // Temporary hide for ECA-6013
          <ConfirmationModal
            show={this.state.showModal}
            title={i18n.t('Delete Team')}
            children={i18n.t('Are you sure you want to delete this team? You will not be able to use the Team for Assignments or Reporting if it is deleted. Deleting the Team will not impact individual user records.')}
            onConfirm={this.deleteTeam}
            onCancel={this.onCancelDeleteTeam}
          /> */}
        </Section>
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(TeamsContainer);
