import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import { isAfter } from 'date-fns';
import { ActionButtons, Page, buildGenericProps, JsonApiDecorator, PagingDataList, SelectFilter, DateFilter, FilterBar } from 'reactifi';
import * as foundryUsersActionCreators from '../actions/foundryUsersActionCreators';
import { sortBy, map, isEqual, isEmpty, debounce } from 'lodash';

const LEARNER_SELECT_ALL_LIMIT = 10000;

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'users', ['organization', 'location']);
  props = Object.assign(props, {
    errorMessages: state.api.errorMessages,
    googleMapsApiKey: ownProps.route.googleMapsApiKey,
    successMessage: state.api.successMessage,
    errorMessage: state.api.errorMessage,
    activeState: state.api.activeState
  });

  let apiStore = new JsonApiDecorator(state.api);

  const { rule_sets } = apiStore;

  props.contentServiceUrl = ownProps.route.contentServiceUrl;
  props.adminifiUrl = ownProps.route.adminifiUrl
  props.jwt = ownProps.route.jwt;

  props.ruleSets = rule_sets ? rule_sets.all() : [];

  return props;
}

export class FoundryUsersContainer extends React.Component {
  static propTypes = {
    activeState: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isLoadingUsers: PropTypes.bool,
    router: PropTypes.shape({
      push: PropTypes.func.isRequired
    }),
    ruleSets: PropTypes.array,
    successMessage: PropTypes.string,
    users: PropTypes.array,
    usersMeta: PropTypes.shape({
      total_count: PropTypes.number
    })
  };

  static defaultProps = {
    users: []
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(foundryUsersActionCreators, this.props.dispatch);
    this.findUsers = debounce(this.findUsers, 350, { leading: false, trailing: true });

    this.state = {
      activePage: 0,
      showFilters: true,
      filterInEffect: false,
      ...props.activeState
    };
  }

  componentDidMount() {
    this.actions.loadRuleSets();
  }

  isUserDataFiltered = () => {
    return !isEmpty(this.state.filters) || !!this.state.searchValue;
  }

  isDateTileDisabled = ({ date }) => {
    return isAfter(new Date(date), new Date())
  }

  findUsers = () => {
    this.actions.setActiveState(this.state);
    const { filters, searchValue } = this.state;

    if (!this.isUserDataFiltered()) {
      // ECA-8026: if filters or search are not present, avoid users request
      this.actions.clearUsers();
      return;
    }

    const combinedfilters = { custom: filters };

    this.actions.findUsers(
      combinedfilters,
      { number: this.state.activePage },
      this.state.sort,
      searchValue
    );
  }

  handleSearchChange = ({ searchValue }) => {
    this.setState({ searchValue, activePage: 1 }, this.findUsers);
  }

  findUsersByFilters = filters => {
    if (!isEqual(this.state.filters, filters)) {
      this.setState({ filters, activePage: 1 }, this.findUsers);
    }
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.findUsers);
  }

  get actionConfig() {
    return [
      {
        icon: "eye",
        title: i18n.t('View'),
        tooltip: i18n.t('View Organization'),
        type: "view",
        actionType: "callToAction",
        action: this.viewAction
      }
    ];
  }

  viewAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    window.location = `organizations#/${row.organization.id}/users/${row.id}/details`
  }

  userTypeRolesFormatter = (cell, row) => {
    if (!row || !row.user_types) {
      return [];
    }

    return sortBy(map(row.user_types, (value, key) => `${key}: ${value}`)).join(', ');
  }

  get fields() {
    return [
      { name: 'first_name', title: i18n.t('First Name'), canSort: true },
      { name: 'last_name', title: i18n.t('Last Name'), canSort: true },
      {
        name: 'email',
        title: i18n.t('Email'),
        canSort: true,
        formatter: (cell, row) => {
          if (row.email) {
            return row.email;
          } else if (!row.email && row.external_attributes.employee_id) {
            return row.external_attributes.employee_id;
          } else if (
            !row.email &&
            !row.external_attributes.employee_id &&
            row.external_attributes.student_id
          ) {
            return row.external_attributes.student_id;
          } else {
            return null;
          }
        }
      },
      { name: 'username', title: i18n.t('Username'), canSort: true },
      {
        name: 'organization_name', title: i18n.t('Organization'), canSort: true, formatter: (cell, row) => {
          return row.organization ? row.organization.name : '';
        }
      },
      {
        name: 'rule_set_names',
        title: i18n.t('User Type: Roles'),
        canSort: false,
        formatter: this.userTypeRolesFormatter
      },
      {
        name: 'actions', title: i18n.t('Actions'), width: '110',
        formatter: (cell, row) => {
          return (
            row && (
              <ActionButtons
                row={row}
                buttons={this.actionConfig}
              />
            )
          );
        }
      }
    ];
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState(
      {
        sort: {
          value: sortName,
          direction: sortOrder
        },
        sortName,
        sortOrder,
        activePage: 1
      },
      this.findUsers
    );
  }

  renderFilters = () => {
    const activeOptions = [
      { id: 'true', label: i18n.t('Active') },
      { id: 'false', label: i18n.t('Deactivated') }
    ];

    const ruleSetsRolesConfig = this.props.ruleSets.map(ruleSet => ({
      id: ruleSet.id,
      label: ruleSet.label,
      categoryOptions: ruleSet.roles.map(role => ({ id: role.slug, label: role.label }))
    }));

    const filterConfig = [
      {
        name: 'created_at',
        label: i18n.t('Date User Added'),
        tileDisabled: {
          fromDate: this.isDateTileDisabled,
          toDate: this.isDateTileDisabled
        },
        type: DateFilter
      },
      {
        name: 'rule_set_roles',
        label: i18n.t('User Type & Roles'),
        options: ruleSetsRolesConfig,
        isMulti: true,
        isTwoStep: true,
        type: SelectFilter
      },
      {
        name: 'active',
        label: i18n.t('User Status'),
        options: activeOptions,
        isMulti: true,
        type: SelectFilter
      }
    ];

    return (
      <FilterBar
        filterConfig={filterConfig}
        initialFilters={this.state.filters}
        searchBox={
          {
            isOnlySearch: false,
            fullWidth: false,
            placeholder: i18n.t('Search'),
            searchAction: this.handleSearchChange,
            value: this.state.searchValue,
            valueChangeDelay: 1500
          }
        }
        onSelectFilters={this.findUsersByFilters}
        disabled={this.props.isLoadingUsers}
      />
    );
  }

  render() {
    const options = {
      onSortChange: this.onSortChange,
      sortName: this.state.sortName,
      sortOrder: this.state.sortOrder,
      onRowClick: this.viewAction
    };

    if (!this.isUserDataFiltered()) {
      options.noDataText = i18n.t('Apply filters or use the search bar above to find the user you are looking for. Results will display in this table after a search or filters have been applied.');
    }

    const totalCount = this.props.usersMeta ? this.props.usersMeta.total_count : 0;
    const tooManyResults = totalCount > LEARNER_SELECT_ALL_LIMIT;

    return (
      <Page title={i18n.t('Foundry Users')}
        pageType="parent"
        contentType="list"
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        clearMessages={this.actions.clearMessages}
        usePageWrapper={true}
      >
        {this.renderFilters()}
        <PagingDataList
          data={this.props.users}
          fields={this.fields}
          keyField="id"
          options={options}
          onPageChange={this.onPageChange}
          totalCount={tooManyResults ? LEARNER_SELECT_ALL_LIMIT : totalCount}
          trClassName="table-row-pointer"
          activePage={this.state.activePage}
          isLoadingData={this.props.isLoadingUsers}
          selectedItems={{
            warning: tooManyResults && i18n.t('Your search returned too many results and has been limited to the first 10,000 results. Please use the provided filters to reduce the results.')
          }}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps)(FoundryUsersContainer);
