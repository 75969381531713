import React from "react";
import PropTypes from "prop-types";
import { ListModule, useContainerProps } from "reactifi";
import { availableTagManagers } from '../TagUtils';
import i18n from "lib/i18n";

const TagManagersList = props => {
  props = useContainerProps(props);
  const { deleteMember, selectMember } = props;

  const actionConfig = [
    {
      type: "edit",
      selectMember
    },
    {
      type: "delete",
      actionType: "confirm",
      confirmTitle: i18n.t("Delete Tag"),
      confirmChildren: i18n.t("Are you sure you want to delete this tag?"),
      deleteMember
    }
  ];

  const fields = [
    { name: 'name', title: i18n.t('Name'), canSort: true, defaultSort: true, width: '100%',
      formatter: (cell, row) => availableTagManagers.filter((item) => item.value === row.name)[0].label },
    { name: 'unique_identifier', title: i18n.t('Unique ID'), canSort: true, width: '180' },
    { name: 'enabled', title: i18n.t('Enabled'), canSort: true, width: '180' }
  ];

  const options = {
    onRowClick: (_, row) => {
      selectMember(row.id)
    }
  };

  return (
    <ListModule
      {...props}
      fields={fields}
      actions={actionConfig}
      options={options}
    />
  );
};

TagManagersList.propTypes = {
  deleteMember: PropTypes.func,
  selectMember: PropTypes.func
};

export default TagManagersList;
