import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from 'reactifi';
import SystemMessagingContainer from './containers/SystemMessagingContainer';
import createStore from './store/systemMessagingStore';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

const SystemMessagingIndex = (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route path="/" component={SystemMessagingContainer} viewer={props.viewer} permissions={props.permissions} />
      </Router>
    </RootProvider>
  );
};

SystemMessagingIndex.propTypes = {
  viewer: PropTypes.string,
  permissions: PropTypes.arrayOf(PropTypes.string)
};

export default SystemMessagingIndex;
