import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { CancelConfirmModal, FieldGroup, MaterialCheckbox, CheckboxControl } from 'reactifi';
import { Card, CardBody } from 'reactifi/dist/es/core/components/Card';
import { Collapse } from '@chakra-ui/react';
import i18n from 'lib/i18n';
import isEqual from 'lodash/isEqual';

const getStateConfig = selectedUserTypes => {
  let config = {};
  let selectedRuleSets = Object.keys(selectedUserTypes);
  for (let ruleSetId of selectedRuleSets) {
    config[`userTypeId_${ruleSetId}`] = true;
    config[`userTypeIdSelectedRole_${ruleSetId}`] = selectedUserTypes[`${ruleSetId}`];
  }
  return config;
}

const OrgWizardUserTypes = props => {
  const {
    businessLines,
    cancelClose,
    cancelComplete,
    canceling,
    clearMessages,
    currentOrganizationId,
    displayErrorMessage,
    goingDirection,
    goingToStep,
    isCreate,
    loadUserFormData,
    locations,
    login,
    loginType,
    orgRuleSets,
    selectedUserTypes,
    setSelectedUserTypes,
    stepChangeCancel,
    stepChangeComplete,
    setUserTypeUpdated,
    updateUserRuleSets,
    user,
    userManagedBusinessLines
  } = props;
  const [showCancelConfirm, setShowCancelConfirm] = useState(false);
  const [stateConfig, setStateConfig] = useState({});

  useEffect(() => {
    setStateConfig(getStateConfig(selectedUserTypes));
  }, [selectedUserTypes]);

  useEffect(() => {
    if (goingToStep !== null && goingToStep !== undefined) {
      if (goingDirection === 'forward') {
        validateNavigation();
      } else {
        clearMessages();
        stepChangeComplete();
      }
    }
  }, [goingToStep]);

  useEffect(() => {
    if (canceling) {
      handleCancel();
    }
  }, [canceling])

  const isLocationMandatoryForRuleSet = (selectedRuleSets, registrations) =>
    registrations?.some(
      (registration) =>
        selectedRuleSets.filter(
          (ruleName) => registration.id.indexOf(ruleName) > -1
        ).length > 0 &&
        !!registration?.metadata?.adminifi_fields?.location_id?.enable
    );

  const validateNavigation = async () => {
    // check that each selected checkbox has one selected role
    // and that there is at least one checkbox selected
    let userTypeSelected = false;
    for (let rs of orgRuleSets) {
      if (stateConfig[`userTypeId_${rs.id}`]) {
        userTypeSelected = true;
        if (!stateConfig[`userTypeIdSelectedRole_${rs.id}`]) {
          stepChangeCancel();
          displayErrorMessage(i18n.t('Please select a Role for the User Type %s', {
            postProcess: 'sprintf',
            sprintf: [rs.label]
          }));
          return;
        }
      }
    }

    if (!userTypeSelected) {
      stepChangeCancel();
      displayErrorMessage(i18n.t("Please select at least one User Type"));
      return;
    }
    // after verifying all the Types have Roles, trigger the action that will do the save
    if (isCreate) {
      let { selectedRole, selectedUserTypeId } = getSelectedRoleUserType();
      if (currentOrganizationId && selectedUserTypeId !== "" && !selectedRole !== "") {
        await validateLocation(stepChangeCancel, displayErrorMessage, stepChangeComplete, selectedUserTypes);
      } else {
        stepChangeCancel();
        displayErrorMessage(i18n.t("There was an unexpected error"));
      }
    } else {
      setRuleSetRoles();
      await updateUserRuleSets(user, currentOrganizationId);
      setUserTypeUpdated(true);
      stepChangeComplete();
    }
  }

  const handleCancel = () => {
    let anythingChanged;
    if (isCreate) {
      anythingChanged = !!stateConfig && Object.keys(stateConfig).some(key => key.includes('userTypeId'));
    } else {
      if (user?.rule_set_roles) {
        anythingChanged = !isEqual(selectedUserTypes, user.rule_set_roles);
      }
    }
    if (anythingChanged) {
      setShowCancelConfirm(true);
    } else {
      clearMessages();
      cancelComplete();
    }
  }

  const getSelectedRoleUserType = () => {
    let selectedRole = "";
    let selectedUserTypeId = {};
    for (var rs of orgRuleSets) {
      if (stateConfig[`userTypeId_${rs.id}`] === true) {
        selectedUserTypeId = rs.id;
        selectedRole = stateConfig[`userTypeIdSelectedRole_${rs.id}`];
        break;
      }
    }
    return { selectedRole, selectedUserTypeId };
  }

  const validateLocation = async (stepChangeCancel, displayErrorMessage, stepChangeComplete, selectedUserTypes) => {
    const selectedRuleSets = orgRuleSets
      .filter((ruleset) => Object.keys(selectedUserTypes).includes(ruleset.id))
      .map((rule) => rule.name);

    try {
      const registrations = await loadUserFormData(currentOrganizationId, selectedUserTypes, login, loginType);
      // we check of location is mandatory for the rules set
      // if yes, then we check for locations and throw an error if there's no location saved
      if (isLocationMandatoryForRuleSet(selectedRuleSets, registrations) && !locations.length) {
        stepChangeCancel();
        displayErrorMessage(i18n.t('Cannot create users without a location. Go to Locations and create one first.'));
      } else {
        stepChangeComplete();
        clearMessages();
      }
    } catch (e) {
      stepChangeCancel();
      displayErrorMessage(i18n.t('Unable to load form data'));
    }
  }

  const setRuleSetRoles = () => {
    let ruleSets = [];
    for (var ruleSet of orgRuleSets) {
      if (stateConfig[`userTypeId_${ruleSet.id}`] === true) {
        ruleSets.push(ruleSet.id);
      }
    }
    user.ruleSetIds = ruleSets;
    let rule_set_roles = {};
    ruleSets.forEach((ruleSetId) => {
      rule_set_roles[`${ruleSetId}`] = stateConfig[`userTypeIdSelectedRole_${ruleSetId}`];
    });
    user.rule_set_roles = rule_set_roles;
  }

  const getAllowedUserRoles = (userType) => {
    if (!userType.visibility_settings.admin_registrable) {
      return null;
    }
    let allowedRoles = userType.roles;
    if (userType.roles && userType.hide_secondary_role_radio) {
      allowedRoles = userType.roles.filter(role => role.slug !== 'secondary');
    }
    return allowedRoles;
  }

  const renderUserTypes = () => {
    let oldLabel = "";
    let hideLabel = false;
    return (Array.isArray(orgRuleSets) && orgRuleSets.map((userType, index) => {
      let newLabel;
      const userTypeBusinessLine = userType.business_lines && userType.business_lines[0];
      const businessLine = userTypeBusinessLine &&
        businessLines.find(item => item.id === userTypeBusinessLine);

      const allowedRoles = getAllowedUserRoles(userType);

      if (!allowedRoles || allowedRoles.length === 0) {
        return null;
      }
      let disabled = !!userManagedBusinessLines && !userManagedBusinessLines.includes(userTypeBusinessLine);

      const checked = selectedUserTypes[userType.id];
      if (disabled && !checked) {
        return null;
      }

      if (loginType === 'username' && (userType.is_manager || userType.name === 'scorm_manager')) {
        return null;
      }

      // Only set the label after we're sure we want to continue.
      // Otherwise we could be comparing against a label that won't end up
      // being displayed.
      newLabel = businessLine ? businessLine.label : i18n.t('Other');
      hideLabel = (newLabel === oldLabel);
      oldLabel = newLabel;

      return (
        <FieldGroup key={`org-wiz-${index}`}>
          {newLabel && !hideLabel ? <h5>{newLabel}</h5> : null}
          <FieldGroup className="m-l-10 m-t-10">
            <MaterialCheckbox
              checked={checked}
              className="checkbox"
              disabled={disabled}
              inline={true}
              id={`checkbox_${index}`}
              name="userTypeRadioGroup"
              onChange={(isChecked) => onUserTypeClick(isChecked, userType)}
              key={index}
              type="checkbox"
              value={userType.id}
              data-object-type="user"
              data-object-attribute="type"
              data-object-id={userType.id}
            >
              {userType.label}
            </MaterialCheckbox>
          </FieldGroup>
          {allowedRoles && allowedRoles.length > 1 ?
            <Collapse in={stateConfig[`userTypeId_${userType.id}`]}>
              <Card variant="unstyled" id={`panel_userTypeId_${userType.id}`} >
                <CardBody>
                  <FieldGroup className="form-group">
                    {allowedRoles.map((role, roleindex) => {
                      const selectedRole = stateConfig[`userTypeIdSelectedRole_${userType.id}`];
                      return (
                        <CheckboxControl
                          className="radio"
                          checked={selectedRole === role.slug}
                          disabled={disabled}
                          inline={true}
                          key={`${userType.id}_${selectedRole}_${roleindex}`}
                          name={`roleGroup_${userType.id}`}
                          id={`radio_${userType.id}_${role.slug}`}
                          onChange={(isChecked) => onUserRoleClick(isChecked, userType.id, role.slug)}
                          type="radio"
                          value={role.slug}
                          data-object-type="user"
                          data-object-attribute="role"
                          data-object-id={`${userType.id}_${role.slug}`}
                        >
                          <i className="input-helper" />{role.label}
                        </CheckboxControl>
                      )
                    })}
                  </FieldGroup>
                </CardBody>
              </Card>
            </Collapse>
            : null}
        </FieldGroup>
      );
    })
    )
  };

  const onUserTypeClick = (isChecked, userType) => {
    const userTypeId = userType.id;
    const allowedRoles = getAllowedUserRoles(userType);
    let updatedUserTypes = Object.assign({}, selectedUserTypes);
    // Assign the role automatically if only 1 role available
    if (isChecked) {
      if (allowedRoles && allowedRoles.length === 1) {
        updatedUserTypes[userTypeId] = userType.roles[0].slug;
      } else {
        updatedUserTypes[userTypeId] = undefined;
      }
    } else if (!isChecked) {
      delete updatedUserTypes[userTypeId];
    }
    setSelectedUserTypes(updatedUserTypes);
  }

  const onUserRoleClick = (isChecked, userTypeId, role) => {
    let updatedUserTypes = Object.assign({}, selectedUserTypes);
    if (isChecked) {
      updatedUserTypes[userTypeId] = role;
    } else {
      delete updatedUserTypes[userTypeId];
    }
    setSelectedUserTypes(updatedUserTypes);
  }

  const onCancelClose = (cancel) => {
    if (cancel) {
      cancelComplete();
    } else {
      setShowCancelConfirm(false);
      cancelClose();
    }
  }

  const infoPanel = i18n.t('Select the way that this person will use this system. This is called a "user type" and each person can have more than one.');

  return (
    <div className="add-user-form">
      <div className="info-panel">
        {infoPanel}
      </div>
      <form>
        {renderUserTypes()}
      </form>
      <CancelConfirmModal show={showCancelConfirm} onCancelClose={onCancelClose} />
    </div>
  )
}

OrgWizardUserTypes.propTypes = {
  businessLines: PropTypes.array,
  canceling: PropTypes.bool,
  cancelClose: PropTypes.func,
  cancelComplete: PropTypes.func,
  clearMessages: PropTypes.func,
  currentOrganizationId: PropTypes.string.isRequired,
  displayErrorMessage: PropTypes.func,
  goingDirection: PropTypes.string,
  goingToStep: PropTypes.number,
  isCreate: PropTypes.bool,
  loadUserFormData: PropTypes.func.isRequired,
  locations: PropTypes.array,
  login: PropTypes.string,
  loginType: PropTypes.string,
  orgRuleSets: PropTypes.array.isRequired,
  selectedUserTypes: PropTypes.object,
  setSelectedUserTypes: PropTypes.func,
  stepChangeCancel: PropTypes.func,
  stepChangeComplete: PropTypes.func,
  updateUserRuleSets: PropTypes.func,
  user: PropTypes.object,
  userManagedBusinessLines: PropTypes.array
};

export default OrgWizardUserTypes;
