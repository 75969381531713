import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup
} from 'reactifi';

export default class BrandingHeaderWell extends Component {
  static propTypes = {
    currentStyleGuide: PropTypes.object,
    getProperties: PropTypes.func,
    invalidHex: PropTypes.bool,
    headerStyles: PropTypes.object
  }

  constructor(props){
    super(props);
    this.state = {
      currentStyleGuide: props.currentStyleGuide
    }
  }

  render () {
    let { currentStyleGuide } = this.props;

    return (
      <FieldGroup className="branding-well-container" data={currentStyleGuide}>
        <h4>{i18n.t("Headers")}</h4>
        <FieldGroup className="row">
          <div className="col-sm-6 header-preview">
            <h2 style={this.props.headerStyles}>{i18n.t("Header Preview")}</h2>
          </div>
        </FieldGroup>
        <FieldGroup className="row">
          {this.props.getProperties('heading')}
          { this.props.invalidHex && <FieldGroup className="error-text">
            <p>{ i18n.t("Please make sure that the entered hexcolors are valid.") }</p>
          </FieldGroup> }
        </FieldGroup>
      </FieldGroup>
    )
  }
}
