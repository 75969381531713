import PropTypes from 'prop-types';
import React from 'react';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import createStore from './store/datafiExportsStore';

import DatafiExportsContainer from './containers/DatafiExportsContainer';


let index = (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);

  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/"
          component={DatafiExportsContainer}
          contentServiceUrl={props.content_service_url}
          dataTypes={props.data_types}
          evalufiUrl={props.evalufi_url}
          jwt={props.jwt} />
      </Router>
    </RootProvider>
  );
}

index.propTypes = {
  content_service_url: PropTypes.string,
  data_types: PropTypes.array.isRequired,
  evalufi_url: PropTypes.string,
  jwt: PropTypes.string
}

export default index;
