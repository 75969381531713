import React from 'react';
import PropTypes from 'prop-types';
import { SystemBanner } from 'reactifi/dist/es/components/system-banner';
import i18n from 'lib/i18n';

const CPN_CAPABILITIES = ['Capabilities::HE', 'Capabilities::FacultyStaff'];
const LEGACY_BANNER_TEXT = <div className="system-banner-text" dangerouslySetInnerHTML={{ __html: i18n.t('legacyBannerHTML') }} />;

const LegacyAssignmentsBanner = ({ capabilities = [] }) => {
  const isLegacy = capabilities.includes('Capabilities::LegacyAssignments');
  const cpnAdmin = capabilities.some(capability => CPN_CAPABILITIES.includes(capability));

  if (!isLegacy || cpnAdmin) return null;

  return <SystemBanner text={LEGACY_BANNER_TEXT} type="info" icon="circle-info" />;
}

LegacyAssignmentsBanner.propTypes = {
  capabilities: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default LegacyAssignmentsBanner;
