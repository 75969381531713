import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { Util } from "@everfi/reactifi";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Tooltip, OverlayTrigger } from "react-bootstrap";
import {
  buildGenericProps,
  getPageSize,
  FAIcon
} from 'reactifi';
import i18n from 'lib/i18n';
import * as rosterActionCreators from '../actions/rosterActions';
import UploadList from '../../common/Components/upload/UploadList';

const ROSTERS_FILTERS = { status: 'complete' };

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, 'rosters');
  props.listMeta = state.api.rosters
    ? state.api.rosters.meta
    : null;

  return props;
}

class ListContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    isLoadingRosters: PropTypes.bool,
    listMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    rosters: PropTypes.array,
    router: PropTypes.object,
    showAlertMessage: PropTypes.string,
    successMessage: PropTypes.string
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(
      rosterActionCreators,
      this.props.dispatch
    );
  }

  componentDidMount() {
    this.actions.findRosters(ROSTERS_FILTERS, { number: 1, size: getPageSize() });
  }

  get fields() {
    return [{ name: 'name', title: i18n.t('List Name'), width: "12%", canSort: true },
      { name: 'media_name', title: i18n.t('File Name'), width: "28%", canSort: true },
      {
        name: 'accepted', title: i18n.t('Accepted'), width: "10%", canSort: false, formatter: (cell, row) => {
          const urlSuccess = `/api/data/rosters/${row.id}/successful_rows.csv`;
          return (
            <span>{row.upload_summary.successful}
              {row.upload_summary.successful > 0 && <OverlayTrigger placement="top" overlay={<Tooltip id="download-csv">{i18n.t('Click to download file')}</Tooltip>}>
                <a href={urlSuccess} download={row.media_name}> <FAIcon icon="down-to-line" /></a>
              </OverlayTrigger>}
            </span>
          )
        }
      },
      {
        name: 'errors', title: i18n.t('Errors'), width: "10%", canSort: false, formatter: (cell, row) => {
          const urlFailed = `/api/data/rosters/${row.id}/failed_rows.csv`;
          return (
            <span>{row.upload_summary.failures}
              {row.upload_summary.failures > 0 && <OverlayTrigger placement="top" overlay={<Tooltip id="download-csv">{i18n.t('Click to download file')}</Tooltip>}>
                <a href={urlFailed} download={row.media_name}> <FAIcon icon="down-to-line" /></a>
              </OverlayTrigger>}
            </span>
          )
        }
      },
      {
        name: 'updated_at', title: i18n.t('Upload'), width: "12%", canSort: true, formatter: (cell, row) => {
          return Util.format(cell, 'P')
        }
      },
      { name: 'uploader_full_name', title: i18n.t('Uploaded by'), canSort: true, width: "16%" }
    ];
  }

  render() {
    const { router, errorMessage, successMessage, showAlertMessage, listMeta, rosters, isLoadingRosters } = this.props;
    return (
      <UploadList
        title={i18n.t('Manage Quick Lists')}
        uploadLabel={i18n.t('Upload New List')}
        uploadDataAction="upload-new-list"
        instructions={<>
          {i18n.t('Create a list of users by uploading their email addresses. Once you create your list, you can use it to manage and assign courses to those learners. For more info, please visit our')}
          <a href="https://help.everfi.com/s/article/Quick-Lists-On-Foundry" target="_blank" rel="noopener noreferrer">{i18n.t('help site')}</a>.
        </>}
        findAction={this.actions.findRosters}
        actionFilters={ROSTERS_FILTERS}
        fields={this.fields}
        router={router}
        errorMessage={errorMessage}
        successMessage={successMessage}
        clearMessages={this.actions.clearMessages}
        showAlertMessage={showAlertMessage}
        list={rosters}
        listMeta={listMeta}
        isLoading={isLoadingRosters}
        deleteAction={this.actions.deleteRoster}
        alwaysAllowDelete={true}
      />
    );
  }
}

export default connect(mapStateToProps)(ListContainer);
