import { baseEndpointPath, readEntity } from 'reactifi';
import { setEndpointPath } from 'redux-json-api';

export function loadUploadedReports(params, organizationId) {
  return async dispatch => {
    await dispatch(setEndpointPath(`${baseEndpointPath}/organizations/${organizationId}`));
    await dispatch(readEntity({ entity: 'uploaded_reports', ...params }));
    dispatch(setEndpointPath(baseEndpointPath));
  }
}
