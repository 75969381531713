import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { bindActionCreators } from 'redux';
import SimpleEdit from "../../common/SimpleEdit";
import SamlAttribute from "../components/SamlAttribute";
import CertificateInfoBlock from "../components/CertificateInfoBlock";
import * as identityProvidersActionCreators from "../actions/identityProvidersActionCreators";
import { Form } from 'react-bootstrap';
import {
  FloatingLabelFormField,
  MaterialCheckbox,
  Page,
  SelectControl,
  FileUpload,
  FieldGroup,
  JsonApiDecorator,
  AccessControlUtil
} from "reactifi";
import i18n from "lib/i18n";
import { getNewestCertificate } from '../functions/helpers';
import { differenceInDays } from 'date-fns';

const SSO_USER_PROVISIONING_CAPABILITY = 'Capabilities::SsoUserProvisioning';

const SIGNATURE_METHODS = [
  { value: 'sha1', label: 'SHA-1' },
  { value: 'sha256', label: 'SHA-256' }
];

function mapStateToProps(state, ownProps) {
  const props = SimpleEdit.mapStateToProps("saml_id_providers")(state, ownProps);
  const isCreate = !props.itemId;

  let apiStore = new JsonApiDecorator(state.api);
  props.organizationId = ownProps.route.organization_id;
  props.orgCapabilities = ownProps.route.organization_capabilities;
  if (apiStore.organizations) {
    props.organization = apiStore.organizations.find(props.organizationId);
  }
  if (props.organization) {
    props.hasAlternativeLogin = AccessControlUtil.hasCapability("Capabilities::AlternativeLogin", props.organization.capability_list);
  }
  props.locations = apiStore.locations ? apiStore.locations.all() : [];
  props.samlCertificates = apiStore.saml_certificates ? apiStore.saml_certificates.all() : [];
  if (props.samlCertificates.length === 1) {
    props.item.saml_certificate_id = props.samlCertificates[0].id;
  } else if (isCreate && props.samlCertificates.length) {
    const newestCertificate = getNewestCertificate(props.samlCertificates);
    props.item.saml_certificate_id = newestCertificate.id;
  }

  if (isCreate) {
    props.item.signature_method = 'sha256';
  }

  return props;
}

class IdentityProviderContainer extends React.Component {
  static propTypes = {
    data: PropTypes.object,
    dispatch: PropTypes.func,
    errorMessage: PropTypes.string,
    hasAlternativeLogin: PropTypes.bool,
    item: PropTypes.any,
    itemId: PropTypes.any,
    locations: PropTypes.array,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    samlCertificates: PropTypes.array,
    successMessage: PropTypes.string,
    router: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.data = Object.assign({ saml_attribute_maps: [] }, this.props.data);
    this.actions = bindActionCreators(identityProvidersActionCreators, this.props.dispatch);
    this.state = {
      rule_sets: [],
      item: this.props.item,
      valueOverride: false,
      SSOMetadataType: this.props.itemId ? 'form' : 'url',
      samlCertificate: null,
      requireSLO: false
    };
  }

  async componentDidMount() {
    const url = `/api/data/rule_sets.json`;
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache': 'no-cache',
        'Strict-Transport-Security': 'max-age=63072000; includeSubDomains'

      },
      credentials: 'include'
    });
    const response = await resp.json();

    let data = response.data.map(rule_set => rule_set.attributes)
    this.setState({ rule_sets: data })
    if (this.props.itemId) {
      await this.actions.selectItem(this.props.itemId);
    }
    this.actions.loadLoactions();
    this.actions.loadCertificates();
    let selectedRuleSet;
    if (this.props.item.default_user_type && this.state.rule_sets) {
      selectedRuleSet = this.state.rule_sets.find((set) => set.name === this.props.item.default_user_type);
      this.selectRuleSet(selectedRuleSet);
    }

    if (this.state.SSOMetadataType === 'form' && this.props.item.sp_logout_enabled) {
      this.setState({ requireSLO: true });
    }

    this.setState({ selfRegistrationEnabled: this.props.item.self_registration_enabled });
  }

  static getDerivedStateFromProps(props) {
    const { item, samlCertificates } = props;
    const samlCertificate = samlCertificates?.find(cert => String(cert.id) === String(item.saml_certificate_id));

    return { item, samlCertificate };
  }

  /**
   * Get uploaded file information
   */
  getLocalData = (files) => {
    const samlFile = files[0];
    const reader = new FileReader();

    reader.onloadend = this.loadUploadFile;

    reader.readAsText(samlFile);
  }

  /**
   * Handles parsing and updating the form information with the uploaded file
   */
  loadUploadFile = (evt) => {
    if (evt.target.readyState === FileReader.DONE) { // DONE == 2
      this.setState({
        item: Object.assign(this.props.item, {
          idp_metadata_content: evt.target.result
        })
      })
    }
  }

  /**
   * Clear all current data and state
   */
  clearForm = (data) => {
    if (!data.idp_metadata_content) {
      delete data.idp_metadata_content;
    }

    delete data.saml_attribute;
    delete data.ar_attribute;
    delete data.editable;
    delete data.relationships;
  }

  selectRuleSet = (selectedRuleSet) => {
    if (selectedRuleSet) {
      this.setState({ selectedRuleSet, selectedRoles: selectedRuleSet.roles, valueOverride: true });
    } else {
      this.setState({ selectedRuleSet: {}, selectedRoles: null })
    }
  }

  toggleSelfRegistration = () => {
    this.setState({ selfRegistrationEnabled: !this.state.selfRegistrationEnabled });
  }

  selectRole = () => {
    this.setState({ valueOverride: false });
  }

  isLocationRequired = ({ business_lines }) => {
    if (business_lines) {
      return business_lines.includes('corporate-compliance') || business_lines.includes('higher-education') || business_lines.includes('faculty-staff');
    } else {
      return false;
    }
  }

  renderRegistrationContent = () => {
    const { selfRegistrationEnabled, selectedRuleSet = {} } = this.state;

    return <FieldGroup>
      <FieldGroup className="card form-view-wrapper m-t-10">
        <h4 className="section-top-header">{i18n.t("Default Values for New Users")}</h4>
        <div>{i18n.t("These defaults apply only when a new user is created. Each of these default values may optionally be overridden by Attribute Maps provided by the identity provider.")}</div>
        <FieldGroup className="card-body">
          <div className="clearfix" />
          <FloatingLabelFormField wrapperClassName="col-lg-12" caption={i18n.t("Default User Type")} name="default_user_type" required={selfRegistrationEnabled}>
            <SelectControl options={this.state.rule_sets} onChange={this.selectRuleSet} multi={false} valueKey="name" labelKey="label" />
          </FloatingLabelFormField>
          {selectedRuleSet ?
            <FloatingLabelFormField wrapperClassName="col-lg-12" caption={i18n.t("Default User Role")} name="default_user_role" required={selfRegistrationEnabled}>
              <SelectControl onChange={this.selectRole} valueOverride={this.state.valueOverride} options={this.state.selectedRoles} multi={false} valueKey="slug" labelKey="label" />
            </FloatingLabelFormField> : null}
          <FloatingLabelFormField wrapperClassName="col-lg-12" caption={i18n.t("Default Location")} name="default_user_location_id" required={selfRegistrationEnabled && this.isLocationRequired(selectedRuleSet)}>
            <SelectControl options={this.props.locations} multi={false} valueKey="id" labelKey="name" />
          </FloatingLabelFormField>
        </FieldGroup>
      </FieldGroup>
      <SamlAttribute name="saml_attribute_maps" />
    </FieldGroup>;
  }

  onSamlCertChange = certificate => {
    const samlCertificate = typeof certificate === 'string' ? this.props.samlCertificates.find(cert => cert.id === certificate) : certificate;
    this.setState({ samlCertificate });
  }

  renderCertificatesField = () => {
    const { samlCertificates, itemId } = this.props;
    const singleOption = !!samlCertificates && samlCertificates.length === 1;

    if (!samlCertificates.length || singleOption) {
      return null;
    }

    return (
      <FieldGroup>
        <FloatingLabelFormField
          name="saml_certificate_id"
          caption={i18n.t("EVERFI SAML Certificate")}
          wrapperClassName="col-lg-12"
          required={true}
          disabled={!itemId}
          tooltip={i18n.t('This is the Foundry signing and encryption certificate. Foundry digitally signs its SAML messages with the EVERFI certificate you select. If your identity provider requires the EVERFI public certificate to verify these signatures, then be sure that the EVERFI certificate you have for the Foundry service provider in your identity provider is the same certificate you select here. Additionally, if you want to encrypt the SAML Assertions your identity provider sends to Foundry, then use this certificate for the encryption certificate. When adding a new identity provider configuration in Foundry, select the newest EVERFI certificate; older certificates are still available for setups added prior to the addition of the newest certificate. If you change the EVERFI certificate here, then you also must update the EVERFI certificates in your identity provider at the same time.')}
          dataValue={this.state.item.saml_certificate_id}
        >
          <SelectControl
            options={samlCertificates}
            multi={false}
            valueKey="id"
            labelKey="name"
            onChange={this.onSamlCertChange}
            valueOverride={true}
          />
        </FloatingLabelFormField>

        <FloatingLabelFormField
          name="signature_method"
          caption={i18n.t('EVERFI Signing Algorithm')}
          wrapperClassName="col-lg-12"
          required={true}
          tooltip={i18n.t('Specify the algorithm for Foundry to sign its outgoing SAML messages. Your identity provider must support the algorithm you choose.')}
        >
          <SelectControl options={SIGNATURE_METHODS} valueKey="value" labelKey="label" />
        </FloatingLabelFormField>

        <CertificateInfoBlock certificate={this.state.samlCertificate} />
      </FieldGroup>
    );
  }

  disableCertificateField = () => {
    const isEditing = !!this.props.itemId;

    if (isEditing) {
      if (!this.state.item.idp_certificate) {
        return this.state.item.idp_cert_fingerprint_algorithm || this.state.item.idp_cert_fingerprint
      }
    } else {
      return this.state.item.idp_cert_fingerprint_algorithm || this.state.item.idp_cert_fingerprint;
    }
  }

  formHasError = () => {
    const formError = document.querySelectorAll(':invalid');
    return (formError && formError.length > 0);
  }

  checkSLO = (value) => {
    const { requireSLO } = this.state;
    if (!value && requireSLO) {
      this.setState({ errorSLO: i18n.t("The identity provider single logout URL must be entered when 'Also log out of this provider when logging out of Foundry' is checked.") })
    } else {
      this.setState({ errorSLO: null })
    }
  }

  shouldShowIDPCertInfoBlock = () => {
    const { item } = this.state;
    const idpCertificateUpdated = item.idp_certificate !== this.props.item.idp_certificate;

    return !!(item.id && !idpCertificateUpdated && item.idp_certificate);
  }

  shouldShowConfirmation = () => {
    const { samlCertificate } = this.state;
    const newestCertificate = getNewestCertificate(this.props.samlCertificates);

    if (!samlCertificate || !newestCertificate) {
      return false;
    }

    // show confirmation in edit mode if the selected saml certificate is not the newest one
    return !!this.props.itemId && samlCertificate.id !== newestCertificate.id;
  }

  getOldCertificateConfirmBody = () => {
    const { samlCertificate } = this.state;

    if (!samlCertificate) {
      return null;
    }

    const daysDiff = differenceInDays(new Date(samlCertificate.expiration_date), new Date());
    const expirationMsg = daysDiff <= 0 ? i18n.t('is expired') : `${i18n.t('expires in')} ${daysDiff} ${i18n.t('days')}`;

    return (
      <div className="old-certificate-confirmation">
        <p>
          {i18n.t(`The EVERFI SAML certificate for this identity provider ${expirationMsg}. If your organization\'s identity provider rejects expired certificates, then single sign-on may fail. We recommend you rotate the EVERFI certificate in your organization\'s identity management system to the latest one as described in `)}
          <a href="https://help.everfi.com/s/article/SSO-Certificate">{i18n.t('EVERFI X.509 Certification Rotation')}</a>.
        </p>
        <p>{i18n.t('Are you sure?')}</p>
      </div>
    );
  }

  render() {
    const { item, SSOMetadataType } = this.state;
    const userProvisioningEnabled = AccessControlUtil.hasCapability(SSO_USER_PROVISIONING_CAPABILITY, this.props.orgCapabilities);

    if (!item) {
      return null;
    }

    const pageTitle = item.display_name ? `${i18n.t('Identity Provider:')} ${item.display_name}` : i18n.t('New Identify Provider');
    const ssoProvisioningReadMoreLink = (
      <>
        {i18n.t(' Read more at ')}
        <a href="https://help.everfi.com/s/article/JIT-Provisioning" target="_blank" rel="noopener noreferrer">
          {i18n.t('Foundry Just-in-time User Provisioning')}
        </a>.
      </>
    );

    return (
      <Page
        title={pageTitle}
        pageType="child"
        contentType="form"
        successMessage={this.props.successMessage}
        clearMessages={this.actions.clearMessages}
        backButton={this.props.router.goBack}
        usePageWrapper={true}
      >
        <SimpleEdit
          item={item}
          router={this.props.router}
          actionCreators={identityProvidersActionCreators}
          dispatch={this.props.dispatch}
          itemId={this.props.itemId}
          beforeSave={this.clearForm}
          errorMessage={this.props.errorMessage}
          formHasError={this.formHasError}
          goBackAfterAdd={true}
          confirmBeforeSave={this.shouldShowConfirmation()}
          confirmTitle={i18n.t('EVERFI Certificate Notice')}
          confirmBody={this.getOldCertificateConfirmBody()}
        >
          <h5>{`${i18n.t('Display Name')} (${i18n.t('32 Characters Max')})`}</h5>
          <div>{i18n.t('Set up your identity provider to let users log in with your existing system. This will cover learners and admin users. This setup is compatible with any system using the SAML 2.0 standard.')}</div>
          <FloatingLabelFormField
            wrapperClassName="col-lg-6"
            name="display_name"
            caption={i18n.t("Enter Display Name")}
            hintText={i18n.t("32 Characters Max")}
            maxlength="32"
          />
          <MaterialCheckbox
            name="sp_initiation_enabled"
            className="col-lg-6">
            {i18n.t("Allow service provider initiated login?")}
          </MaterialCheckbox>
          <MaterialCheckbox
            name="sp_logout_enabled"
            className="col-lg-8"
            onChange={(value) => this.setState({ requireSLO: value })}>
            {i18n.t("Also log users out of this provider when logging out of Foundry")}
          </MaterialCheckbox>
          <MaterialCheckbox
            name="suppress_welcome_email"
            className="col-lg-12">
            {i18n.t("Suppress Welcome Email to users on first login via SSO?")}
          </MaterialCheckbox>
          {this.renderCertificatesField()}

          <div className="form-group-separator" />

          <h5>{i18n.t('Technical Contact')}</h5>
          <div>{i18n.t('Give your users someone to reach out to in case SSO encounters an error.')}</div>
          <FloatingLabelFormField
            wrapperClassName="col-lg-6"
            name="technical_contact_name"
            caption={i18n.t("Name of Technical Contact")}
            required={true}
          />
          <FloatingLabelFormField
            wrapperClassName="col-lg-6"
            name="technical_contact_phone"
            caption={i18n.t("Phone Number of Technical Contact")}
            required={true}
          />
          <FloatingLabelFormField
            wrapperClassName="col-lg-6"
            name="technical_contact_email"
            caption={i18n.t("Email of Technical Contact")}
            required={true}
          />

          <div className="form-group-separator" />

          <h5>{i18n.t('SSO Metadata')}</h5>

          <FieldGroup>
            <Form.Check
              name="radioGroup"
              className="radio m-b-10">
              <Form.Check.Label>{i18n.t("Use a URL")}
                <Form.Check.Input type="radio" name="radioGroup" onChange={(e) => this.setState({ SSOMetadataType: "url" })} checked={SSOMetadataType === "url"} />
                <i className="input-helper" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              name="radioGroup"
              className="radio m-b-10">
              <Form.Check.Label>{i18n.t("Upload XML Data")}
                <Form.Check.Input type="radio" name="radioGroup" onChange={(e) => this.setState({ SSOMetadataType: "xml" })} checked={SSOMetadataType === "xml"} />
                <i className="input-helper" />
              </Form.Check.Label>
            </Form.Check>
            <Form.Check
              name="radioGroup"
              className="radio m-b-10">
              <Form.Check.Label>{i18n.t("Enter parameters in a form")}
                <Form.Check.Input type="radio" name="radioGroup" onChange={(e) => this.setState({ SSOMetadataType: "form" })} checked={SSOMetadataType === "form"} />
                <i className="input-helper" />
              </Form.Check.Label>
            </Form.Check>
          </FieldGroup>

          {SSOMetadataType === "xml" &&
            <FileUpload type="file"
              required={false}
              name="saml_upload"
              onUpload={this.getLocalData}
              label={{ text: i18n.t("Select XML file to upload") }}
              accept={['*.xml']}
            />
          }

          {SSOMetadataType === "form" &&
            <FieldGroup>
              <FloatingLabelFormField
                wrapperClassName="col-lg-6"
                name="idp_entity_id"
                caption={i18n.t("Entity Id of the SSO IDP")}
              />
              <FloatingLabelFormField
                wrapperClassName="col-lg-12"
                name="idp_sso_target_url"
                caption={i18n.t("Single Sign On (SSO) - Login URL of IDP")}
              />
              <FloatingLabelFormField
                wrapperClassName="col-lg-12"
                name="idp_slo_target_url"
                required={this.state.requireSLO}
                onBlur={this.checkSLO}
                errorMessage={this.state.errorSLO}
                caption={i18n.t("Single Log Out (SLO) - Logout URL of IDP (if any)")}
              />
              <FieldGroup className="card card-body bg-light">
                <FieldGroup>
                  <FloatingLabelFormField
                    wrapperClassName="col-lg-12"
                    name="idp_cert_fingerprint_algorithm"
                    caption={i18n.t("IDP Certificate Algorithm")}
                    onBlur={() => this.forceUpdate()}
                    disabled={item.idp_certificate}
                  />
                  <FloatingLabelFormField
                    wrapperClassName="col-lg-12"
                    name="idp_cert_fingerprint"
                    caption={i18n.t("IDP Certificate fingerprint")}
                    onBlur={() => this.forceUpdate()}
                    disabled={item.idp_certificate}
                  />
                  <div className="form-group-separator-with-text">
                    <span>{i18n.t("or")}</span>
                  </div>
                  <FloatingLabelFormField
                    as="textarea"
                    wrapperClassName="col-lg-12"
                    name="idp_certificate"
                    caption={i18n.t("IDP Certificate Text")}
                    onBlur={() => this.forceUpdate()}
                    disabled={this.disableCertificateField()}
                    hintText={i18n.t('Copy and paste the encoded text from your organization\'s certificate into the IDP Certificate')}
                  />
                </FieldGroup>
              </FieldGroup>
              {this.shouldShowIDPCertInfoBlock() && (
                <CertificateInfoBlock certificate={{
                  common_name: item.idp_cert_common_name,
                  serial: item.idp_cert_serial,
                  expiration_date: item.idp_cert_expires_on
                }} />
              )}
            </FieldGroup>
          }

          <div className="form-group-separator" />

          <h5>{`${i18n.t('User Provisioning Through Single Sign-On')} (${i18n.t('Optional')})`}</h5>
          {userProvisioningEnabled ? (
            <FieldGroup>
              <div className="m-b-20">
                {i18n.t("Optionally create new users during SSO if the username provided by the identity provider does not match with any users in the organization. The new user will have the default values and attribute mappings configured in this section. Optionally update existing users during SSO with the same attribute mappings. If you don't want users to be created or updated during SSO, then leave this section blank.")}
                {ssoProvisioningReadMoreLink}
              </div>
              <MaterialCheckbox
                name="self_registration_enabled"
                className="m-b-20"
                onChange={this.toggleSelfRegistration}
                disabled={this.props.hasAlternativeLogin}>
                {i18n.t("Allow automatic registration during SSO?")}
              </MaterialCheckbox>
              {this.props.hasAlternativeLogin &&
                <div className="m-b-10 error-text">{i18n.t("This feature is disabled for organizations using the Alternative Login capability.")}</div>}
              {this.renderRegistrationContent()}
            </FieldGroup>
          ) : (
            <div className="m-b-10">
              {i18n.t("Foundry supports just-in-time user provisioning, through single sign-on. Contact your EVERFI representative to see if this is right for your organization’s needs.")}
              {ssoProvisioningReadMoreLink}
            </div>
          )}
        </SimpleEdit>
      </Page>
    );
  }
}

export default connect(mapStateToProps)(IdentityProviderContainer);
