import React from 'react';
import { createContainer } from 'reactifi';
import TagManagers from '../components/TagManagers';
import config from '../configs/TagManagersContainerConfig';

const Container = createContainer(config);

const TagManagersContainer = props => {
  return (
    <Container {...props}>
      <TagManagers />
    </Container>
  );
};

export default TagManagersContainer;
