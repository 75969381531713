import { selectEntity, updateEntity, createEntity, readEntity, deleteEntity } from 'reactifi';
import i18n from 'lib/i18n';

export { clearMessages, displayErrorMessage } from 'reactifi';

export function selectChart(id) {
  return selectEntity(id, 'charts', 'image');
}

export function loadCharts(filters, page, sort) {
  return readEntity('charts', 'image', filters, sort && [sort], page);
}

export function updateChart(chart) {
  return updateEntity(chart, (dispatch) => {
    dispatch(selectEntity(null, 'charts'));
  }, i18n.t('Chart updated successfully!'));
}

export function deleteChart(chart) {
  return deleteEntity(chart, 'charts', i18n.t('Chart deleted successfully.'));
}

export function createChart(chart, addAnother) {
  return createEntity(chart, (dispatch) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null, 'charts'));
    if (addAnother) {
      dispatch(selectEntity('add', 'charts', 'image'));
    }
  }, i18n.t('Chart created successfully!'));
}

export function loadRuleSets() {
  return readEntity('rule_sets', null, { include_restricted: true });
}
