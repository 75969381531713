import React from "react";
import PropTypes from "prop-types";
import {
  ModalForm,
  FieldGroup,
  FloatingLabelFormField,
  ToggleCheckbox,
  MaterialCheckbox,
  CheckboxGroupControl,
  InfoTooltip,
  Button
} from "reactifi";
import i18n from "lib/i18n";

export default class WebhookForm extends React.Component {
  static propTypes = {
    copyToClipboard: PropTypes.func,
    currentWebhook: PropTypes.object,
    domains: PropTypes.array,
    webhookEventTypes: PropTypes.array,
    selectWebhook: PropTypes.func.isRequired,
    createWebhook: PropTypes.func.isRequired,
    updateWebhook: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  }

  constructor(props) {
    super(props);

    this.state = {
      isSaving: false,
      active: false
    };
  }

  componentDidMount() {
    this.setState({ active: this.props.currentWebhook.active });
  }

  createWebhook = async (webhook) => {
    this.performSaveAction(this.props.createWebhook, webhook);
  }

  updateWebhook = async (webhook) => {
    this.performSaveAction(this.props.updateWebhook, webhook);
  }

  performSaveAction = async (action, webhook) => {
    this.setState({ isSaving: true });
    await action(webhook);
    this.setState({ isSaving: false });
  }

  onCancel = () => {
    this.props.selectWebhook(null);
  }

  toggleActive = () => {
    let { active } = this.state;
    const { currentWebhook } = this.props;
    active = !active;
    currentWebhook.active = active;
    this.setState({ active });
  }

  copyWebhookId = (webhook_id) => {
    this.props.copyToClipboard(webhook_id, document.querySelector('#form-modal'));
  }

  renderForm = () => {
    const { currentWebhook, domains, webhookEventTypes,
      errorMessage, successMessage } = this.props;
    const { isSaving, active } = this.state;
    const domain = domains.find(domain => domain.id === currentWebhook.domain_id);
    const isNew = !currentWebhook.id;
    const title = isNew ? i18n.t("Create New Webhook") : i18n.t("Edit Webhook");
    const webhook_id = currentWebhook.id;

    return (
      <ModalForm
        data={currentWebhook}
        title={title}
        errorMessage={errorMessage}
        successMessage={successMessage}
        onCancel={this.onCancel}
        addAction={this.createWebhook}
        updateAction={this.updateWebhook}
        disableButtons={isSaving}
        saveAndAddButton={false}
      >
        <FieldGroup className="container-fluid">
          {isNew ?
            <div className="row m-b-10">
              <div className="col-12">
                <div>{i18n.t("Foundry will deliver webhooks to the payload URL defined by the Domain followed by the path for the events you specify. For example, a domain of integration.mydomain.com and a path of workflow/triggers combines to a complete payload URL of https://integration.mydomain.com/workflow/triggers")}</div>
              </div>
            </div> : null}
          <input type="hidden" name="domain_id" value={currentWebhook.domain_id} />
          <div className="row">
            <div className="col-12">
              <div className="f-500">{i18n.t('Registered API:')} <span className="foundry-teal">{domain.application.name}</span></div>
              <div className="f-500">{i18n.t('Domain:')} <span className="foundry-teal">{domain.name}</span></div>
              {!isNew && <div className="f-500">{i18n.t('Path:')} <span className="foundry-teal">{currentWebhook.path}</span></div>}
              {!isNew && <div className="f-500">{i18n.t('Payload URL:')} <span className="foundry-teal">{`https://${domain.name}/${currentWebhook.path}`}</span></div>}
            </div>
          </div>
          {isNew &&
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="path"
                  caption={i18n.t("Path")}
                  required={true}
                />
              </FieldGroup>
            </FieldGroup>}
          <div className="row">
            <div className="col-12">
              <div className="toggle-checkbox toggle-checkbox-container">
                <label htmlFor="webhook-active-toggle">
                  {i18n.t('Active')}
                </label>
                <ToggleCheckbox
                  className="round sm m-l-5"
                  checked={active}
                  onChange={this.toggleActive}
                  id="webhook-active-toggle"
                  disabled={!domain.verified}
                />
                {!domain.verified && (
                  <InfoTooltip
                    name="active-toggle"
                    label={i18n.t('Why active toggle is disabled')}
                    tooltip={i18n.t('Foundry will deliver webhooks to any verified domain that is active. A webhook cannot be made active until it is verified. Any web hooks in domains that cannot be verified will be automatically deactivated.')}
                    tooltipPosition="top"
                  />
                )}
              </div>
            </div>
          </div>
          {!isNew && <div className="row">
            <FieldGroup className="col-lg-12 p-t-20">
              <div className="row">
                <div className="col-lg-8">
                  <FloatingLabelFormField
                    name="webhook_id"
                    className="m-0"
                    caption={i18n.t("Webhook ID")}
                    disabled={true}
                    dataValue={webhook_id}
                  />
                </div>
                <div className="col-lg-4 m-b-20">
                  <Button
                    label={i18n.t('Copy')}
                    icon="copy"
                    style="secondary"
                    className="m-t-10"
                    onClick={() => this.copyWebhookId(webhook_id)}
                  />
                </div>
              </div>
            </FieldGroup>
          </div>}
          <div className="row">
            <div className="col-12">
              <h4>{i18n.t('Events')}</h4>
              <div>{i18n.t('Which events would you like to trigger this webhook?')}</div>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <CheckboxGroupControl
                name="event_type_names"
                valueKey="name"
                labelKey="display_name"
                valueList={webhookEventTypes}
                CheckboxComponent={MaterialCheckbox}
                useCheckboxValue={true}
              />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>);
  }

  render() {
    const {
      currentWebhook
    } = this.props;

    if (!currentWebhook) {
      return null;
    }
    return this.renderForm();
  }
}
