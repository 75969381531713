import React from 'react';
import { App } from 'reactifi';
import {
  BenchmarksContainer,
  BenchmarkContainer
} from './containers';

const index = (_, _railsContext) => {
  return (
    <App {..._railsContext}>
      <BenchmarksContainer path="/"  />
      <BenchmarkContainer path="/:id" />
    </App>
  );
}

export default index;
