import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup
} from 'reactifi';


export default class BrandingLinkWell extends Component {
  static propTypes = {
    currentStyleGuide: PropTypes.object,
    getProperties: PropTypes.func,
    invalidHex: PropTypes.bool,
    linkStyles: PropTypes.object,
    linkHoverStyles: PropTypes.object
  }

  constructor(props){
    super(props);
    this.state = {
      currentStyleGuide: props.currentStyleGuide,
      hover: false
    }
  }

  toggleHover = () => this.setState({ hover: !this.state.hover });

  render () {
    let { currentStyleGuide } = this.props;

    return (
      <FieldGroup className="branding-well-container" data={currentStyleGuide}>
        <h4>{i18n.t("Links")}</h4>
        <FieldGroup className="row">
          <div className="col-sm-6 link-preview">
            <a style={this.state.hover ? this.props.linkHoverStyles : this.props.linkStyles}
              onMouseEnter={(e) => this.toggleHover()}
              onMouseLeave={(e) => this.toggleHover()}>{i18n.t("Link Preview")}</a>
          </div>
        </FieldGroup>
        <FieldGroup className="row">
          {this.props.getProperties('link')}
          { this.props.invalidHex && <FieldGroup className="error-text">
            <p>{ i18n.t("Please make sure that the entered hexcolors are valid.") }</p>
          </FieldGroup> }
        </FieldGroup>
      </FieldGroup>
    )
  }
}
