import React from 'react';
import { createContainer } from 'reactifi';
import * as chartsPageActionCreators from '../../CustPortalReports/actions/chartsPageActionCreators';
import ImpactDashboard from '../components/ImpactDashboard';

function mapStateToProps(state, ownProps) {
  const { apiStore } = ownProps;
  const props = {};
  if (state.api && state.api.currentEntityType === 'data_exports') {
    if (state.api.currentEntityId === 'add') {
      props.dataExportEntity = apiStore.newObject('data_exports');
    } else {
      props.dataExportEntity = apiStore?.data_exports
        ? apiStore.data_exports.find(state.api.currentEntityId)
        : {};
    }
  }
  return props;
}

const Container = createContainer({
  entity: 'contexts',
  actionCreators: chartsPageActionCreators,
  mapStateToProps
});

const ImpactDashboardContainer = (props) => {
  return (
    <Container {...props}>
      <ImpactDashboard />
    </Container>
  );
};

export default ImpactDashboardContainer;
