import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ModalForm, FieldGroup, MaterialCheckbox, FAIcon, FloatingLabelFormField, SelectAsyncControl } from 'reactifi';
import { FormControl, Form } from 'react-bootstrap';
import i18n from 'lib/i18n';

class OrgUserCategoryLabelsModal extends Component {
  static propTypes = {
    businessLine: PropTypes.string,
    onCancel: PropTypes.func.isRequired,
    onSubmit: PropTypes.func.isRequired,
    organizationId: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.state = {
      categories: [],
      clearCategory: false,
      items: [],
      selectedCategory: null,
      selectedLabel: null
    };
  }

  onSubmit = (val) => {
    this.props.onSubmit(val, this.state.clearCategory);
  }

  handleSearchChange(e) {
    const searchValue = e.target.value;
    this.setState({ searchValue }, this.updateList);
  }

  updateList = () => {
    const { selectedCategory, searchValue } = this.state;
    if (!selectedCategory) {
      this.setState({ items: [] });
      return;
    }
    let items = (selectedCategory.category_labels || [])
    if (searchValue) {
      items = items.filter(item => item.name.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1)
    }
    this.setState({ items });
  }

  renderModalInfo = () => {
    return (
      <div className="modal-info-box">
        {i18n.t('To add or update labels on the selected users, select a category below, then choose a label. Any previous values will be overwritten.')}
      </div>
    );
  };

  onToggleClearCategory = () => {
    this.setState({
      clearCategory: !this.state.clearCategory,
      selectedLabel: null
    });
  }

  onCategoryChange = selectedCategory => {
    if (this.isValidSelectOption(selectedCategory)) {
      this.setState({ selectedCategory }, this.updateList);
    } else if (!selectedCategory) {
      this.setState({ selectedCategory: null, items: [] });
    }
  }

  isValidSelectOption = value => typeof value === 'object' && value !== null;

  buildDisplay = (category) => {
    this.setState({ categories: [...this.state.categories, category] });
    return category.name;
  }

  setLabel = label => {
    this.setState({ selectedLabel: label.id })
  }

  render() {
    const { businessLine, onCancel, organizationId } = this.props;
    const selectedCategory = this.state.selectedCategory || {};

    const sortedItems = this.state.items.length > 1 ?
      [...this.state.items].sort((item1, item2) => (item1.name > item2.name) ? 1 : -1) :
      this.state.items;

    const categoryURL = `/api/data/categories.json?filter[organization_id]=${organizationId}&filter[business_line]=${businessLine}&include=category_labels`;
    const scrollClassName = 'radio-modal-scroll-container custom-scrollbar';

    return (
      <ModalForm
        addAction={this.onSubmit}
        bsSize="lg"
        className="category-labels-modal"
        data={selectedCategory}
        onCancel={onCancel}
        onClose={onCancel}
        title={i18n.t('Set Category')}
        saveAndAddButton={false}
        saveButtonText="OK"
        updateAction={this.onSubmit}
      >
        {this.renderModalInfo()}

        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField
              name="category_id"
              caption={i18n.t('Select Category')}>
              <SelectAsyncControl
                buildDisplay={this.buildDisplay}
                dataUrl={categoryURL}
                filterField="name"
                queryFields={{ name: 'word_start' }}
                onChange={this.onCategoryChange}
                pageSize={20}
                placeholder=""
                sortBy="name"
                labelKey="display"
              />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>

        <div className="search-wrapper full-width">
          <div className="search-icon">
            <FAIcon icon="search" />
          </div>
          <div className="search-input">
            <FormControl
              type="text"
              bsPrefix=""
              value={this.state.searchValue || ""}
              placeholder={i18n.t('Search Labels')}
              onChange={this.handleSearchChange.bind(this)}
            />
          </div>
        </div>

        {selectedCategory.id ?
          (<FieldGroup className={scrollClassName}>
            {sortedItems.map(category_label => (
              <Form.Check
                type="radio"
                key={category_label.id}
                disabled={this.state.clearCategory}
                className="radio m-b-10">
                <Form.Check.Label>{category_label.name}
                  <Form.Check.Input type="radio" name="category_label" value={category_label.name} checked={this.state.selectedLabel === category_label.id} onChange={() => this.setState({ selectedLabel: category_label.id })} />
                  <i className="input-helper" />
                </Form.Check.Label>
              </Form.Check>
            ))}
          </FieldGroup>) :
          (
            <div className={scrollClassName}>{i18n.t('Select a category above, then you can select a label.')}</div>
          )}
        {selectedCategory.id &&
        <FieldGroup className="row m-t-10">
          <FieldGroup className="col-12">
            <MaterialCheckbox name="clear" onChange={this.onToggleClearCategory}>
              {i18n.t('Clear %s for selected users', { postProcess: 'sprintf', sprintf: [selectedCategory.name] })}
            </MaterialCheckbox>
          </FieldGroup>
        </FieldGroup>}
      </ModalForm>
    );
  }
}

export default OrgUserCategoryLabelsModal;
