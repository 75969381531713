import { selectEntity, updateEntity, createEntity, readEntity,  deleteEntity } from 'reactifi';
import i18n from 'lib/i18n';

export { clearMessages, displaySuccessMessage } from 'reactifi';

const SYSTEM_MESSAGES_ENTITY = 'system_messages';

export function selectSystemMessage(id) {
  return selectEntity(id, SYSTEM_MESSAGES_ENTITY);
}

export function readSystemMessages(includes, filters, sort, page) {
  if (!sort) {
    sort = { value: 'title', direction: 'asc' };
  }

  return readEntity(SYSTEM_MESSAGES_ENTITY, '', filters, sort && [sort], page);
}

export function updateSystemMessage(systemMessage) {
  return updateEntity(systemMessage, (dispatch) => {
    dispatch(selectEntity(null, SYSTEM_MESSAGES_ENTITY));
  }, i18n.t('System Message updated successfully'));
}

export function deleteSystemMessage(systemMessage) {
  return deleteEntity(systemMessage, SYSTEM_MESSAGES_ENTITY, i18n.t('System Message deleted successfully.'))
}

export function createSystemMessage(systemMessage) {
  return createEntity(systemMessage, (dispatch) => {
    dispatch(selectEntity(null));
  }, i18n.t('System Message created successfully'));
}

export function loadRuleSets() {
  return readEntity('rule_sets');
}
