import React from 'react';
import PropTypes from 'prop-types';
import AppElement from './AppElement';
import AppElementPlaceholder from './AppElementPlaceholder';

const AppWrapper = ({ appElements }) => {
  return (
    <div className="interstitial-app-wrapper" data-action="interstitial-app-selector">
      {appElements.map(appElement => (
        <AppElement key={appElement.id} {...appElement} />
      ))}
      {[0, 1].map(id => <AppElementPlaceholder key={`placeholder-${id}`} />)}
    </div>
  );
};

AppWrapper.propTypes = {
  appElements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string
    })
  )
};

export default AppWrapper;
