import { useCallback } from "react";
import useDeactivateUserConfirmAction from "./useDeactivateUserConfirmAction";
import useDeleteModalActions from "./useDeleteModalActions";
import useEditModalActions from "./useEditModalActions";
import useInvitePlatformConfirmAction from "./useInvitePlatformConfirmAction";
import useManageLabelsModalActions from "./useManageLabelsModalActions";
import usePasswordResetConfirmAction from "./usePasswordResetConfirmAction";
import useReactivateUserConfirmAction from "./useReactivateUserConfirmAction";
import useSamlAttributesModalActions from "./useSamlAttributesModalActions";
import useTemporaryPasswordModalActions from "./useTemporaryPasswordModalActions";

const usePageActions = (props) => {
  const {
    clearMessages,
    currentUserId,
    organizationId,
    registrationSets,
    router,
    toggleUserActivation,
    user: { email } = {}
  } = props;

  const toggleActivation = useCallback(() => {
    clearMessages();
    toggleUserActivation(registrationSets);
  }, [registrationSets]);

  // Deactivate User
  const {
    action: deactivateUser,
    confirmationModal: deactivateUserModal
  } = useDeactivateUserConfirmAction(toggleActivation);

  // Delete User
  const {
    onDeleteUser,
    modal: deleteUserModal
  } = useDeleteModalActions(props);

  // Edit User
  const {
    onEditUser,
    modal: editUserModal
  } = useEditModalActions(props);

  // Impersonate
  const impersonate = useCallback(() => {
    window.location.href = `${currentUserId}/impersonation`;
  }, [currentUserId]);

  // Invite to Platform
  const {
    action: sendInvitation,
    confirmationModal: invitePlatformModal
  } = useInvitePlatformConfirmAction(
    useCallback(() => {
      props.sendInvitation(currentUserId)
    }, [currentUserId]),
    email
  );

  // Manage Labels
  const {
    onManageLabels,
    modal: manageLabelsModal
  } = useManageLabelsModalActions(props);

  // Reactivate User
  const {
    action: reactivateUser,
    confirmationModal: reactivateUserModal
  } = useReactivateUserConfirmAction(toggleActivation);

  // SAML Attributes
  const {
    onShowSamlAttributes,
    modal: samlAttributesModal
  } = useSamlAttributesModalActions(props);

  // Send Password Rest
  const {
    action: resetPassword,
    confirmationModal: resetPasswordModal
  } = usePasswordResetConfirmAction(
    useCallback(() => {
      props.resetPassword(currentUserId, organizationId)
    }, [currentUserId, organizationId])
  );

  // Temporary Password
  const {
    onTempPassword,
    modal: tempPasswordModal
  } = useTemporaryPasswordModalActions(props);

  // View Change Log
  const showChangeLog = useCallback(() => {
    router.push(`${organizationId}/users/${currentUserId}/changelog`);
  }, [currentUserId, organizationId]);

  return {
    actions: {
      deactivateUser,
      impersonate,
      onDeleteUser,
      onEditUser,
      onManageLabels,
      onShowSamlAttributes,
      onTempPassword,
      reactivateUser,
      resetPassword,
      sendInvitation,
      showChangeLog
    },
    components: (
      <>
        {deactivateUserModal}
        {deleteUserModal}
        {editUserModal}
        {invitePlatformModal}
        {manageLabelsModal}
        {reactivateUserModal}
        {resetPasswordModal}
        {samlAttributesModal}
        {tempPasswordModal}
      </>
    )
  };
};

export default usePageActions;
