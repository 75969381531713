import React from 'react';
import { ActionButtons, PagingDataList, MaterialCheckbox } from 'reactifi';
import PropTypes from "prop-types";
import i18n from 'lib/i18n'

export default class AdministratorTable extends React.Component {
  static propTypes = {
    fieldsProps: PropTypes.object
  }

  get actionConfig() {
    return [
      {
        icon: "pen",
        title: "",
        tooltip: "Edit Administrator",
        type: "edit",
        actionType: "callToAction",
        action: this.editAction
      },
      {
        icon: "trash",
        title: "",
        tooltip: "Delete Administrator",
        type: "delete",
        actionType: "callToAction",
        action: this.deleteAction
      }
    ];
  }

  editAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.fieldsProps) {
      this.props.fieldsProps.onEdit(row);
    }
  }

  deleteAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.fieldsProps) {
      this.props.fieldsProps.onDelete(row);
    }
  }

  getFields = () => {
    let fields = [
      { name: 'first_name', title: i18n.t('First Name'), canSort: true },
      { name: 'last_name', title: i18n.t('Last Name'), canSort: true },
      { name: 'email', title: i18n.t('Email'), canSort: true },
      { name: 'administrator_group_ids', title: i18n.t('Administrator Groups'), canSort: true, formatter: (cell, row) => {
        return row.group_names.join(", ");
      }
      },
      { name: 'active', title: i18n.t('Active'), canSort: true, formatter: (cell, row) => {
        return (<MaterialCheckbox checked={row.active} disabled={true} />);
      }
      }
    ];
    fields = fields.concat(
      [{ name: 'actions', title: i18n.t('Actions'), width: '110', formatter: (cell, row) => {
        if(!row.all_access) {
          return (
            row && (
              <ActionButtons
                row={Object.assign({}, row, {
                  can_edit: true,
                  can_delete: true
                })}
                buttons={this.actionConfig}
              />
            )
          );
        }
      } 
      }]);
    return fields;
  }

  render() {
    const { fieldsProps, ...props } = this.props;
    return <PagingDataList fields={this.getFields(fieldsProps)} keyField="id" {...props}/>;
  }
}
