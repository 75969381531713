import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { ConstructionCard, Form, FieldGroup, FloatingLabelFormField } from 'reactifi';

const OrgHelpURLsCard = props => {
  const {
    canEditCapabilities,
    currentOrganization,
    updateOrganization,
    onCancelHelpUrls,
    updateOrgField
  } = props;

  return (
    <ConstructionCard
      title={i18n.t('URL Customizations')}
      alwaysExpanded={true}
      className="m-b-30"
      cardContentClasses="w-100"
    >
      <Form
        buttonGroupClass="form-controls-container row text-end"
        data={currentOrganization}
        buttonStyle="primary"
        onCancel={onCancelHelpUrls}
        updateAction={updateOrganization}
        cancelButton={canEditCapabilities()}
        saveButton={canEditCapabilities()}
        saveButtonAreaClassName="form-buttons-container-right"
        saveButtonDataAction="save-help-urls"
        disableButtons={false}
      >
        <FieldGroup className="help-urls-container">
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Learner Help URL')}
                hintText={i18n.t('Link to support resources for learners.')}
                className="col-half-width"
                name="learner_help_url"
                required={true}
                onChange={val => updateOrgField('learner_help_url', val)}
                valueOverride={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Advisor Help URL')}
                hintText={i18n.t('FinEd customer-provided URL that replaces advisor-facing links to EVERFI resources.')}
                className="col-half-width"
                name="advisor_resource_url"
                onChange={val => updateOrgField('advisor_resource_url', val)}
                valueOverride={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Privacy Policy URL')}
                hintText={i18n.t('Customer-provided URL for their Privacy Policy')}
                className="col-half-width"
                name="privacy_policy_url"
                onChange={val => updateOrgField('privacy_policy_url', val)}
                valueOverride={true}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                caption={i18n.t('Privacy Choices URL')}
                hintText={i18n.t('Customer-provided URL for privacy opt-out options')}
                className="col-half-width"
                name="privacy_choices_url"
                onChange={val => updateOrgField('privacy_choices_url', val)}
                valueOverride={true}
              />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </Form>
    </ConstructionCard>
  );
};

OrgHelpURLsCard.propTypes = {
  canEditCapabilities: PropTypes.func,
  currentOrganization: PropTypes.object,
  onCancelHelpUrls: PropTypes.func,
  updateOrganization: PropTypes.func,
  updateOrgField: PropTypes.func
};

export default OrgHelpURLsCard;
