import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ModalForm, FieldGroup, FAIcon } from 'reactifi';
import { Form, FormControl } from 'react-bootstrap';
import i18n from 'lib/i18n';

class OrgUserLocationModal extends Component {
  static propTypes = {
    locationObject: PropTypes.object.isRequired,
    locations: PropTypes.arrayOf(PropTypes.shape({
      address_formatted: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string
    })).isRequired,
    onCancel: PropTypes.func,
    onSubmit: PropTypes.func,
    userCount: PropTypes.number
  };

  constructor(props) {
    super(props);

    this.state = {
      items: [],
      searchValue: ''
    };
  }

  componentDidMount() {
    this.setState({ items: this.props.locations });
  }

  handleSearchChange = event => {
    const searchValue = event.target.value;
    this.setState({ searchValue }, this.updateList);
  }

  updateList = () => {
    const { searchValue } = this.state;
    const updatedList = this.props.locations.filter(item => {
      const { address_formatted, name } = item;

      return name?.toLowerCase().includes(searchValue.toLowerCase()) || address_formatted?.toLowerCase().includes(searchValue.toLowerCase());
    });

    this.setState({ items: updatedList });
  }

  render() {
    const { locationObject, onCancel, onSubmit, userCount } = this.props;
    const message = userCount === 1 ?
      i18n.t("This tool sets the Location for the selected user. It will overwrite the user's current Location.") :
      i18n.t('This tool sets the location for all %s selected users. It will overwrite current values.', { postProcess: 'sprintf', sprintf: [userCount.toLocaleString('en')] });

    return (
      <ModalForm 
        title={i18n.t('Change Location')}
        data={locationObject}
        onCancel={onCancel}
        onClose={onCancel}
        saveAndAddButton={false}
        bsSize="lg"
        saveButtonText="OK"
        addAction={onSubmit}
        updateAction={onSubmit}
      >
        <div className="modal-info-box">
          {message}
        </div>

        <div className="search-wrapper full-width">
          <div className="search-icon">
            <FAIcon icon="search" />
          </div>
          <div className="search-input">
            <FormControl
              type="text"
              bsPrefix=""
              value={this.state.searchValue || ""}
              placeholder={i18n.t('Search Locations')}
              onChange={this.handleSearchChange}
            />
          </div>
        </div>

        <FieldGroup className="radio-modal-scroll-container custom-scrollbar">
          {this.state.items.map(location => (
            <Form.Check
              type="radio"
              key={location.id}
              className="radio m-b-10">
              <Form.Check.Label>{location.name} ({location.address_formatted})
                <Form.Check.Input type="radio" name="location_id" value={location.id} />
                <i className="input-helper" />
              </Form.Check.Label>
            </Form.Check>
          ))}
        </FieldGroup>
      </ModalForm>
    );
  }
}

export default OrgUserLocationModal;
