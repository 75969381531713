import React from 'react';
import PropTypes from 'prop-types';

const DetailColumn = ({ classes, title, value }) => (
  <div className={classes}>
    <h5>{title}</h5>
    <span className="value">{value}</span>
  </div>
);

DetailColumn.propTypes = {
  classes: PropTypes.string,
  title: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
};

export default DetailColumn;
