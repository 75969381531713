import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { ReportCard, SelectFilter, TodoCard, Wrapper } from 'reactifi';
import i18n from 'lib/i18n';
import { checklist } from '../../../../images/dashboardimages';

const VALID_REPORTS = Object.freeze([
  { slug: 'training-progress-actionable', label: i18n.t('Completions by Popular Content'), fullReportSlug: 'training-progress-retool' }
]);

const AssignedLearningDashboard = props => {
  const [trainingPeriodId, setTrainingPeriodId] = useState(null);

  const {
    contexts,
    organization: { slug: orgSlug },
    renderAnnouncements,
    todos,
    trainingPeriods,
    businessLine,
    notifiUrl,
    jwt,
    displayErrorMessage,
    errorMessage
  } = props;
  
  const contextsToRender = contexts.filter(ctx => !!ctx.chart && VALID_REPORTS.map(rp => rp.slug).includes(ctx.chart.slug));

  if (!contextsToRender || !contextsToRender.length || !businessLine) {
    return (
      <div className="no-reports p1">
        {i18n.t('There are no reports available.')}
      </div>
    );
  }

  const getReportPageIdsMap = () => {
    return VALID_REPORTS.reduce((acc, report) => {
      if (report.fullReportSlug) {
        const context = contexts.find(ctx => !!ctx.chart && ctx.chart.slug === report.fullReportSlug);
        if (context) {
          acc[report.slug] = context.chart_id;
        }
      }
      return acc;
    }, {});
  }

  const onDismissTodo = async todoId => {
    const todo = todos.find(todo => todo.id === todoId);

    await props.dismissTodoItem(todo, notifiUrl, jwt);
    props.readTodoItems(notifiUrl, jwt, businessLine);
  }

  const renderTodos = () => {
    if (!todos) return null;

    return <TodoCard todos={todos} emptyStateImage={checklist} onDismiss={onDismissTodo} />;
  }

  const getSelectedTrainingPeriodTitle = () => {
    if (!trainingPeriods || !trainingPeriodId) {
      return null;
    }

    const selectedTrainingPeriod = trainingPeriods.find(period => String(period.id) === String(trainingPeriodId));
    return selectedTrainingPeriod ? selectedTrainingPeriod.name : null;
  }

  const getReportCardTitle = chart => VALID_REPORTS.find(rp => rp.slug === chart.slug).label;

  const onTrainingPeriodChange = (trainingPeriod) => {
    if (trainingPeriod && trainingPeriod.id) {
      setTrainingPeriodId(trainingPeriod.id);
    }
  }

  const onClearTrainingPeriodFilter = () => {
    setTrainingPeriodId(null);
  }

  const options = useMemo(() => trainingPeriods.map((training) => ({ ...training, label: training.name })), [trainingPeriods]);

  return (
    <>
      <div className="dashboard-filters">
        <SelectFilter
          name="training_period"
          label={i18n.t('Training Period')}
          onChange={onTrainingPeriodChange}
          options={options}
          labelKey="name"
          showSelected={true}
          dataObjectType="training_period"
          dataAction="filter-training-period"
          onClearFilters={onClearTrainingPeriodFilter}
        />
        <em>{i18n.t('Data and charts refresh every 24 hours.')}</em>
      </div>
      <Wrapper {...props} className="cards-wrapper narrow-cards-wrapper">
        <div className="cards-list">
          {contextsToRender.map(context => {
            const report = VALID_REPORTS.find(rpt => !!context.chart && rpt.slug === context.chart.slug);
            const hasRelatedReport = !!(report && report.fullReportSlug);
            const relatedChartId = getReportPageIdsMap()[report.slug];
            const viewMoreUrl = hasRelatedReport && relatedChartId ? `/${orgSlug}/cportal/reports?force=true#/chart/${relatedChartId}` : null;

            return (
              <ReportCard
                key={context.chart.slug}
                title={getReportCardTitle(context.chart)}
                size="medium"
                chartContext={context}
                className={`${context.chart.slug}-report`}
                relatedChartId={relatedChartId}
                showViewMore={!!viewMoreUrl}
                viewMoreUrl={viewMoreUrl}
                displayErrorMessage={displayErrorMessage}
                errorMessage={errorMessage}
                filters={{
                  url_training_period_title: getSelectedTrainingPeriodTitle(),
                  url_training_period_id: trainingPeriodId,
                  url_business_line: businessLine,
                  url_scorm: 'False'
                }}
              />
            );
          })}
          {renderTodos()}
          {renderAnnouncements()}
        </div>
      </Wrapper>
    </>
  );
};

AssignedLearningDashboard.propTypes = {
  businessLine: PropTypes.string,
  contexts: PropTypes.arrayOf(PropTypes.shape({
    chart: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      slug: PropTypes.string
    }).isRequired,
    id: PropTypes.string,
    iframe_src: PropTypes.string
  })).isRequired,
  dismissTodoItem: PropTypes.func,
  jwt: PropTypes.string,
  notifiUrl: PropTypes.string,
  organization: PropTypes.shape({
    slug: PropTypes.string
  }).isRequired,
  readTodoItems: PropTypes.func,
  renderAnnouncements: PropTypes.func,
  trainingPeriods: PropTypes.array,
  todos: PropTypes.array,
  updateTodoItem: PropTypes.func,
  user: PropTypes.shape({
    first_name: PropTypes.string,
    id: PropTypes.string
  }).isRequired
};

export default AssignedLearningDashboard;
