import React from 'react';
import PropTypes from 'prop-types'
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildGenericProps, JsonApiDecorator } from 'reactifi';
import * as actionCreators from '../actions/chartsActionCreators';
import Charts from '../components/Charts';

function mapStateToProps(state) {
  const props = buildGenericProps(state, 'charts', 'image');
  props.current = props.currentEntity;
  if (props.current && !props.current.id){
    props.current.use_cache = true;
  }

  const apiStore = new JsonApiDecorator(state.api);
  const { rule_sets } = apiStore;

  props.ruleSets = rule_sets ? rule_sets.where(rs => rs.is_manager || rs.is_scorm_manager || rs.name === 'executive') : [];
  props.meta = state.api.charts ? state.api.charts.meta : null;

  if (props.current && props.current.rule_set_roles && props.ruleSets) {
    props.current.rule_set_roles = props.current.rule_set_roles.map(ruleSetRole => {
      const [ruleSetId, roleSlug] = ruleSetRole.split(':');
      const ruleSet = props.ruleSets.find(rs => rs.id === ruleSetId);
      const role = ruleSet.roles.find(role => role.slug === roleSlug);

      return { label: `${ruleSet.label} - ${role.label}` , value: ruleSetRole };
    });
  }

  return props;
}

class ChartsContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    isLoading: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(actionCreators, this.props.dispatch);
  }

  componentDidMount() {
    this.actions.loadCharts();
    this.actions.loadRuleSets();
  }

  render()  {
    return (
      <div>
        <Charts {...this.props} {...this.actions} />
      </div>
    );
  }
}

export default connect(mapStateToProps)(ChartsContainer)
