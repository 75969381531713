import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from 'reactifi';
import FoundryUsersContainer from './containers/FoundryUsersContainer';
import createStore from './store/foundryUsersStore';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

const FoundryUsersIndex = (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route
          path="/"
          component={FoundryUsersContainer}
          viewer={props.viewer}
          organizationId={props.organization_id}
          contentServiceUrl={props.content_service_url}
          adminifiUrl={props.adminifi_url}
          jwt={props.jwt} />
      </Router>
    </RootProvider>
  );
};

FoundryUsersIndex.propTypes = {
  viewer: PropTypes.string,
  organization_id: PropTypes.number,
  content_service_url: PropTypes.string,
  adminifi_url: PropTypes.string,
  jwt: PropTypes.string
};

export default FoundryUsersIndex;
