import React, { useState } from "react";
import PropTypes from "prop-types";
import i18n from "lib/i18n";

import { GenericModal, FloatingLabelFormField, SelectControl } from "reactifi";

const AwardsDownloadModal = props => {
  const [selectedCertificate, setSelectedCertificate] = useState(null);
  const { awards, show, onClose } = props;

  const buttonDisabled = !selectedCertificate;

  const saveButton = {
    disabled: buttonDisabled,
    saveText: 'Download',
    attributes: {
      target: "_blank",
      href: selectedCertificate?.download_link,
      className: buttonDisabled ? 'disabled': ''
    },
    saveAction: buttonDisabled ? () => {} : onClose,
    type: 'download'
  }

  return (
    <GenericModal
      show={show}
      title={i18n.t('Select Certificate to Download')}
      onClose={onClose}
      canClose={true}
      footerCloseButton={{ isTurnedOn: true }}
      saveButtonConfig={saveButton}
      modalClass="awards-download-modal"
      renderAsLegacy={true}
    >
      <p>{i18n.t("The selected content has multiple certificates available for download. Please select the certificate you would like to download.")}</p>
      <FloatingLabelFormField
        className="col-12 p-t-10"
        caption={i18n.t("Selected Certificate")}
        name="selected_certificate"
      >
        <SelectControl
          onChange={setSelectedCertificate}
          options={awards}
          multi={false}
          valueKey="award_id"
          labelKey="title"
        />
      </FloatingLabelFormField>
    </GenericModal>
  )
}

AwardsDownloadModal.propTypes = {
  awards: PropTypes.array,
  show: PropTypes.bool,
  onClose: PropTypes.func
}

export default AwardsDownloadModal;
