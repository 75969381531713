import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup,
  FileUpload,
  s3MediaUploader,
  Util
} from 'reactifi';

export default class BrandingLogoWell extends Component {
  static propTypes = {
    currentOrganization: PropTypes.object,
    deleteAction: PropTypes.func,
    displayError: PropTypes.func,
    logo: PropTypes.object,
    onLogoUpload: PropTypes.func,
    urlLogo: PropTypes.string
  }

  render() {
    let { logo, urlLogo, currentOrganization } = this.props;
    return (
      <FieldGroup className="branding-well-container" data={currentOrganization}>
        <FieldGroup className="row">
          <FileUpload
            accept={['.jpg', '.png', '.gif', '.jpeg']}
            actions={{
              delete: this.props.deleteAction,
              failure: this.props.displayError,
              success: this.props.onLogoUpload
            }}
            button={{
              className: 'btn btn-secondary',
              dataAttributes: Util.dataAttribsForEl(currentOrganization, { action: 'upload-logo' }),
              text: i18n.t('Upload Logo')
            }}
            className="file-upload-inline logo-upload"
            data-action="logo-image-card"
            deleteButton={true}
            deleteButtonDataAttributes={Util.dataAttribsForEl(logo, { action: 'delete-logo' })}
            description={{
              textArray: [i18n.t('Logo format: JPG, PNG or GIF')]
            }}
            embedPreviewUrl={urlLogo}
            header={{ text: i18n.t('Logo') }}
            icon={{
              className: 'images',
              iconSize: 'medium',
              fileClassName: 'file',
              noFileClassName: 'file-slash'
            }}
            input={{ name: 'logo' }}
            instructionalText={i18n.t("Uploaded image will appear in the top left, scaled and constrained to 40px in height.")}
            label={{ text: logo.name, closable: false }}
            name="logo"
            uploader={s3MediaUploader}
          />
        </FieldGroup>
      </FieldGroup>
    )
  }
}
