import React, { useCallback, useRef } from "react";
import OrgUserTempPasswordModal from "../../OrgUserTempPasswordModal";

const useTemporaryPasswordModalActions = props => {
  const modalRef = useRef(null);

  const onTempPassword = useCallback(() => {
    modalRef.current.triggerTemporaryPassword();
  }, []);

  const modal = (<OrgUserTempPasswordModal ref={modalRef} {...props} />);
  
  return {
    onTempPassword,
    modal
  };
};

export default useTemporaryPasswordModalActions;
