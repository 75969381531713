import PropTypes from 'prop-types';
import React, { Component } from 'react';
import CustomComponent from '../../../lib/components/CustomComponent';

class OrgUserDetails extends Component {
  static propTypes = {
    registrationSets: PropTypes.object,
    user: PropTypes.object,
    ruleSets: PropTypes.array,
    registrations: PropTypes.array,
    organizationName: PropTypes.string,
    organizationId: PropTypes.string,
    displayFieldsInline: PropTypes.bool,
    target: PropTypes.string,
    unlimitedAccess: PropTypes.bool
  }

  isSelfRegistered = () => {
    const { registrationSets } = this.props;
    return !!registrationSets && registrationSets.registration_context === 'self_registered';
  }

  render() {
    const {
      displayFieldsInline,
      organizationId,
      organizationName,
      registrationSets,
      registrations,
      ruleSets,
      target,
      unlimitedAccess,
      user
    } = this.props;

    if(registrationSets && registrations && organizationName && organizationId) {
      return registrations.map(registration => {
        return (
          <CustomComponent
            registration={registration}
            key={registration.id}
            ruleSets={ruleSets}
            componentName={`${registration.component}ReadOnly`}
            displayFieldsInline={displayFieldsInline}
            isSelfRegistered={this.isSelfRegistered()}
            unlimitedAccess={unlimitedAccess}
            user={user}
            target={target}
          />
        );
      });
    }
    return null;
  }
}

export default OrgUserDetails;
