import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, ColorUtil } from 'reactifi';
import i18n from 'lib/i18n';
import isEqual from 'lodash/isEqual';
import mapStateToProps from '../store/organizationMap';
import BrandingLogoWell from '../components/branding/BrandingLogoWell.js';
import BrandingBackgroundWell from '../components/branding/BrandingBackgroundWell.js';
import BrandingWelcomeTextWell from '../components/branding/BrandingWelcomeTextWell.js';
import BrandingGeneralColorsWell from '../components/branding/BrandingGeneralColorsWell.js';
import BrandingStyleGuidesWell from '../components/branding/BrandingStyleGuidesWell.js';
import BrandingFaviconWell from '../components/branding/BrandingFaviconWell.js';
import { encode } from "js-base64";
import { sanitize as sanitizeHTML } from "dompurify";

class OrganizationBrandingFormContainer extends Component {
  static propTypes = {
    currentOrganization: PropTypes.object,
    defaultStyleConfig: PropTypes.object,
    deleteStyleGuide: PropTypes.func,
    displayError: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    router: PropTypes.object,
    selectStyleGuide: PropTypes.func,
    updateOrganization: PropTypes.func
  };

  static defaultProps = {
    currentOrganization: null
  };

  constructor(props) {
    super(props);

    this.state = {
      disableFormSave: false,
      showDeleteConfirmation: false
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.currentOrganization && !isEqual(this.props.currentOrganization, prevProps.currentOrganization)) {
      const { currentOrganization: { background_image, logo_url, favicon_url } } = this.props;

      this.setState({
        logoUrl: logo_url,
        bkgImgUrl: background_image ? `${background_image.s3_post_url}/${background_image.s3_post_fields.key}` : null,
        faviconImgUrl: favicon_url
      });
    }
  }

  onLogoUpload = async response => {
    this.props.currentOrganization.logo_id = response.data.id;

    this.setState({
      logoUrl: response.data.attributes.url
    });
  };

  onBackgroundImageUpload = async response => {
    this.props.currentOrganization.background_image_id = response.data.id;

    this.setState({
      bkgImgUrl: response.data.attributes.url
    });
  };

  onFaviconImageUpload = async response => {
    this.props.currentOrganization.favicon_id = response.data.id;

    this.setState({
      faviconImgUrl: response.data.attributes.url
    });
  };

  onConfirmDeleteBranding = async (deleteEntity) => {
    const { currentOrganization, updateOrganization } = this.props;

    switch (deleteEntity) {
      case 'logo':
        currentOrganization.logo = null;
        currentOrganization.logo_url = null;
        currentOrganization.logo_id = null;
        break;
      case 'background_image':
        currentOrganization.background_image = null;
        currentOrganization.background_image_id = null;
        break;
      case 'favicon_image':
        currentOrganization.favicon = null;
        currentOrganization.favicon_id = null;
        currentOrganization.favicon_url = null;
        break;
      default:
        break;
    }
    await updateOrganization(currentOrganization);
  }

  onSave = async organization => {
    const { updateOrganization } = this.props;

    this.setState({
      disableFormSave: true
    });
    if (organization.brand_customizations) {
      organization.brand_customizations.dark_color = ColorUtil.addHashToColorIfNeeded(organization.brand_customizations.dark_color)
      organization.brand_customizations.light_color = ColorUtil.addHashToColorIfNeeded(organization.brand_customizations.light_color)
    }

    organization.welcome_text = encode(sanitizeHTML(organization.welcome_text));

    await updateOrganization(organization);

    this.setState({
      disableFormSave: false
    });
  };

  getMediaInfo = imageName => {
    const { currentOrganization } = this.props;
    const defaultOps = {
      name: i18n.t('No File'),
      url: ''
    };

    return (
      (currentOrganization && currentOrganization[imageName]) || defaultOps
    );
  };

  render() {
    const { currentOrganization, errorMessage } = this.props;
    const { disableFormSave, logoUrl, bkgImgUrl, faviconImgUrl } = this.state;

    let title = i18n.t('Add a New Organization');
    if (currentOrganization && currentOrganization.id) {
      title = i18n.t('Edit %s', {
        postProcess: 'sprintf',
        sprintf: [currentOrganization.name]
      });
    }
    // Metadata for media
    let logo = this.getMediaInfo('logo');
    let backgroundImg = this.getMediaInfo('background_image');
    let faviconImg = this.getMediaInfo('favicon');

    // Get the url for the media either from the props or state
    const urlLogo = logoUrl || logo.url;
    const backgroundImgUrl = bkgImgUrl || backgroundImg.url;
    const faviconUrl = faviconImgUrl || faviconImg.url;

    return (
      currentOrganization && (
        <Form
          addAction={this.onSave}
          buttonGroupClass="form-controls-container row text-end"
          buttonStyle="primary"
          cancelButton={false}
          data={currentOrganization}
          dataId={currentOrganization.id}
          dataType={currentOrganization.type}
          disableButtons={disableFormSave}
          errorMessage={errorMessage}
          saveButtonAreaClassName="form-buttons-container-right"
          saveButtonDataAction="update-branding"
          saveButtonDisabled={!!this.state.invalidHex}
          saveButtonText={i18n.t("Update Branding")}
          title={title}
          updateAction={this.onSave}
        >
          {/* Logo uploader */}
          <BrandingLogoWell
            currentOrganization={currentOrganization}
            deleteAction={this.onConfirmDeleteBranding}
            displayError={this.props.displayError}
            logo={logo}
            onLogoUpload={this.onLogoUpload}
            urlLogo={urlLogo}
          />

          {/* Background image uploader */}
          <BrandingBackgroundWell
            currentOrganization={currentOrganization}
            backgroundImg={backgroundImg}
            backgroundImgUrl={backgroundImgUrl}
            deleteAction={this.onConfirmDeleteBranding}
            displayError={this.props.displayError}
            onBackgroundImageUpload={this.onBackgroundImageUpload} />

          <BrandingFaviconWell
            currentOrganization={currentOrganization}
            deleteAction={this.onConfirmDeleteBranding}
            displayError={this.props.displayError}
            faviconImg={faviconImg}
            faviconImgUrl={faviconUrl}
            onFaviconImageUpload={this.onFaviconImageUpload} />

          <BrandingGeneralColorsWell
            currentOrganization={currentOrganization}
            errorMessage={this.props.errorMessage} />

          <BrandingStyleGuidesWell
            currentOrganization={currentOrganization}
            defaultStyleConfig={this.props.defaultStyleConfig}
            deleteStyleGuide={this.props.deleteStyleGuide}
            errorMessage={this.props.errorMessage}
            router={this.props.router}
            selectStyleGuide={this.props.selectStyleGuide} />

          <BrandingWelcomeTextWell
            currentOrganization={currentOrganization}
            errorMessage={this.props.errorMessage} />

        </Form>
      )
    );
  }
}

export default connect(mapStateToProps)(OrganizationBrandingFormContainer);
