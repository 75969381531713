import React from "react";
import {
  CardsModule,
  DisplayMaxWidthWide,
  PresentationCard,
  Section,
  useContainerProps
} from "reactifi";
import i18n from "lib/i18n";
import ImpactStoryCardProps from "./ImpactStoryCardProps";

const ImpactStories = props => {
  props = useContainerProps(props);

  return (
    <Section type="cards">
      <DisplayMaxWidthWide>
        <CardsModule
          resultsDisplayed={false}
          showPageSizeDropdown={false}
          label={i18n.t('Impact Stories')}
          type="wide">
          {(impactStory) => (
            <PresentationCard {...ImpactStoryCardProps(impactStory)} />
          )}
        </CardsModule>
      </DisplayMaxWidthWide>
    </Section>
  );
};

export default ImpactStories;
