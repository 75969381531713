import React, { useState } from 'react';
import { ExplainerText, GenericModal, PresentationCard } from 'reactifi';
import i18n from 'lib/i18n';
import ImpactStoryCardProps from '../../../ImpactDashboard/components/ImpactStoryCardProps';
import impactStoryExample from '../../../../../images/impact-story-example.png';

const OrganizationImpactStoryExplainer = () => {
  const [showPreview, setShowPreview] = useState(false);

  const cardProps = ImpactStoryCardProps({
    body: i18n.t('"I will use what I learned in this course when I buy small things, such as groceries or apparel, or when I buy large things, such as car or house, and as I start to manage my own finances."  Student, AR'),
    call_to_action: true,
    call_to_action_text: i18n.t('Read More'),
    external_url: "https://everfi.com/",
    headline: i18n.t('Epic Course'),
    id: "test",
    image_url: impactStoryExample,
    label: "students_quote"
  });

  return (
    <>
      <ExplainerText>
        {i18n.t('Impact stories are stories that you share with an organization showcasing the impact of Everfi courses on their learners. Impact stories appear on the Impact Dashboard when you choose an Executive user type and Admin Dashboard when you choose an Admin user type. To see an example of an Impact Story,')}
        <a onClick={() => setShowPreview(true)}>{i18n.t('click here')}</a>.
      </ExplainerText>
      <GenericModal
        canClose={true}
        onClose={() => setShowPreview(false)}
        show={showPreview}
        size="6xl"
        title={i18n.t('Impact Story Card Preview')}
      >
        <PresentationCard {...cardProps} />
      </GenericModal>
    </>
  );
};

export default OrganizationImpactStoryExplainer;
