import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { AccessControlUtil, Table, StringUtil, ActionButtons, ConfirmationModal } from 'reactifi';
import { Util } from "@everfi/reactifi";
import i18n from 'lib/i18n';

export default class UserDetailScormContexts extends Component {
  static propTypes = {
    canManageScorm: PropTypes.bool,
    canArchiveScorm: PropTypes.bool,
    checkSimulatable: PropTypes.func,
    complianceUrl: PropTypes.string,
    contentServiceUrl: PropTypes.string,
    currentUserId: PropTypes.string,
    findScormContexts: PropTypes.func,
    jwt: PropTypes.string,
    organization: PropTypes.shape({
      scorm_token: PropTypes.string
    }),
    permissions: PropTypes.array,
    scormContexts: PropTypes.array,
    updateScormContext: PropTypes.func,
    user: PropTypes.shape({
      first_name: PropTypes.string,
      last_name: PropTypes.string,
      sso_id: PropTypes.string
    })
  };

  static defaultProps = {
    scormContexts: []
  };

  constructor(props) {
    super(props);

    this.state = {
      isLoadingScormContexts: false,
      resetProgressActivity: null
    };
  }

  componentDidMount() {
    this.findScormContexts();
  }

  findScormContexts = async () => {
    this.setState({ isLoadingScormContexts: true });
    await this.props.findScormContexts(
      this.props.contentServiceUrl,
      this.props.currentUserId,
      this.props.jwt
    );
    this.setState({ isLoadingScormContexts: false });
  }

  formatDate = dateString => Util.format(dateString.split(" ")[0], 'P');

  onConfirmResetProgress = async () => {
    const archivedActivity = Object.assign(this.state.resetProgressActivity, { archived: true });
    await this.props.updateScormContext(
      archivedActivity,
      this.props.contentServiceUrl,
      this.props.jwt
    );
    this.setState({ resetProgressActivity: null }, this.findScormContexts);
  }

  goToProgressPage = (row, csUrl) => {
    const url = `${csUrl}/admin/course_progress/#/${row.progress_id}?return_to=${encodeURIComponent(window.location)}`;
    window.location.href = url;
  };

  actionsFormatter = (cell, row) => {
    if (!row) {
      return null;
    }

    const canSimulateProgress = this.props.checkSimulatable(row);

    const buttons = [];

    if (!row.archived && this.props.canArchiveScorm) {
      buttons.push({
        icon: 'box-archive',
        title: 'Archive Activity',
        tooltip: 'Archive Activity',
        type: 'view',
        actionType: 'callToAction',
        action: () => this.setState({ resetProgressActivity: row })
      });
    }

    if (!row.archived && this.props.canManageScorm) {
      buttons.push({
        icon: 'arrow-right',
        title: 'Simulate Progress',
        tooltip: 'Simulate Progress',
        type: 'view',
        actionType: 'callToAction',
        disabled: !canSimulateProgress,
        action: () => this.goToProgressPage(row, this.props.contentServiceUrl)
      });
    }

    if (AccessControlUtil.hasPermission('all_access', this.props.permissions) && row.scorm_package) {
      const { complianceUrl, user, organization } = this.props;
      const baseUrl = `${complianceUrl}/student/scorm?`;
      const userName = `${user.first_name}, ${user.last_name}`;
      const queryParams = {
        scorm_token: organization.scorm_token,
        location_id: row.progress.learner_profile.location_id,
        content_id: row.progress.content_id,
        business_line: row.scorm_package.business_line,
        package_id: row.package_id,
        role: row.scorm_package.role,
        sso_id: encodeURIComponent(user.sso_id),
        name: encodeURIComponent(userName)
      }
      const fullUrl = baseUrl + Object.entries(queryParams).map(([key, value]) => `${key}=${value}`).join('&');
      buttons.push({
        icon: 'mask',
        title: i18n.t('Course Link'),
        actionType: 'externalLink',
        target: 'blank',
        action: fullUrl
      });
    }

    return <ActionButtons row={row} buttons={buttons} />;
  }

  formatActivityStatus = activity => {
    if (activity.archived) {
      return i18n.t('Archived');
    }

    return StringUtil.UnderscoresToTitleCase(activity.course_status);
  }

  get fields() {
    let fields = [
      { name: 'dispatch_activity', title: i18n.t('Dispatch Activity') },
      { name: 'created_on', title: i18n.t('Created On'), formatter: cell => this.formatDate(cell) },
      { name: 'last_activity', title: i18n.t('Last Activity'), formatter: cell => this.formatDate(cell) },
      { name: 'course_status', title: i18n.t('Course Status'), formatter: (cell, row) => this.formatActivityStatus(row) },
      { name: 'package_id', title: i18n.t('Package ID') }
    ];

    if (this.props.canManageScorm || this.props.canArchiveScorm) {
      fields.push({ name: 'actions', title: i18n.t('Actions'), width: 150, formatter: this.actionsFormatter });
    }

    return fields;
  }

  get options() {
    return {
      defaultSortName: 'created_on',
      defaultSortOrder: 'asc'
    };
  }

  get modalBody() {
    return (
      <div>
        <p>{i18n.t('Archiving the user’s dispatch activity will archive their current assignment, allowing them to retake the content again when they launch it from their LMS.')}</p>
        <p>{i18n.t('Any progress will be archived in Foundry, and the learner will not be able to access the dispatch activity again to complete/review the content.')}</p>
      </div>
    );
  }

  render() {
    const { scormContexts } = this.props;

    return (
      <div>
        <h4>{i18n.t('Dispatch Activities')}</h4>
        <Table
          data={scormContexts}
          fields={this.fields}
          keyField="dispatch_activity"
          options={this.options}
          isLoadingData={this.state.isLoadingScormContexts}
        />
        <ConfirmationModal
          title={i18n.t('Archive Dispatch Activity?')}
          children={this.modalBody}
          show={!!this.state.resetProgressActivity}
          onConfirm={this.onConfirmResetProgress}
          onCancel={() => this.setState({ resetProgressActivity: null })}
          confirmButtonText={i18n.t('Archive Activity')}
        />
      </div>
    );
  }
}
