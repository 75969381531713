import React from "react";
import PropTypes from "prop-types";
import i18n from "lib/i18n";
import { Card, CardBody } from 'reactifi/dist/es/core/components/Card';
import { FieldGroup, FloatingLabelFormField, ConfirmationModal, InlineNotification, CopyUtil, FAIcon, Button } from 'reactifi';

const FAKE_SECRET = '••••••••••••••••••••••••••••••';

export default class DataIntegrationItem extends React.Component {
  static propTypes = {
    showForm: PropTypes.func.isRequired,
    application: PropTypes.object.isRequired,
    regenerateSecret: PropTypes.func.isRequired,
    deleteApplication: PropTypes.func.isRequired,
    displaySuccessMessage: PropTypes.func.isRequired,
    displayErrorMessage: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);

    this.state = {
      showDeleteModal: false
    }
  }

  copyToClipboard = (value) => {
    const success = CopyUtil.copyText(value);
    if (success) {
      this.props.displaySuccessMessage(i18n.t("Copied!"));
    } else {
      this.props.displayErrorMessage(i18n.t("Unable to copy"));
    }
  }

  onDeleteApp = () => this.setState({ showDeleteModal: true });

  onCancelDeleteApp = () => this.setState({ showDeleteModal: false });

  onConfirmDeleteApp = () => {
    this.props.deleteApplication(this.props.application);
    this.setState({ showDeleteModal: false });
  }

  render() {
    const { showForm, regenerateSecret, application } = this.props;

    return (
      <Card>
        <CardBody>
          <InlineNotification type="info" show={application.secret}>
            <span>{i18n.t('Make sure to copy your new client secret token now. You won\'t be able to see it again!')}</span>
          </InlineNotification>
          <div className="m-b-15">
            <span className="h3 m-r-15">{application.name}</span>
            <span>
              <a onClick={showForm}>
                <FAIcon icon="pen" additionalClasses="m-l-0 m-r-5 m-b-5" />
                {i18n.t('Edit')}
              </a>
            </span>
          </div>
          <FieldGroup className="container-fluid">
            <FieldGroup className="row">
              <FieldGroup className="col-4">
                <FloatingLabelFormField
                  name="uid"
                  caption={i18n.t("Client ID")}
                  disabled={true}
                  valueOverride={true}
                  dataValue={application.uid}
                />
              </FieldGroup>
              <FieldGroup className="col-2">
                <Button
                  label={i18n.t('Copy')}
                  icon="copy"
                  style="secondary"
                  data-action="copy-client-id"
                  className="m-t-5"
                  onClick={() => this.copyToClipboard(application.uid)}
                />
              </FieldGroup>
              {application.secret ? (
                <FieldGroup className="col-4">
                  <FloatingLabelFormField
                    name="secret"
                    caption={i18n.t("Client Secret")}
                    disabled={true}
                    valueOverride={true}
                    dataValue={application.secret}
                  />
                </FieldGroup>
              ) : (
                <div className="col-4">
                  <FloatingLabelFormField
                    name="secret"
                    caption={i18n.t("Client Secret")}
                    disabled={true}
                    dataValue={FAKE_SECRET}
                  />
                </div>
              )}
              <FieldGroup className="col-2">
                <Button
                  label={i18n.t('Copy')}
                  icon="copy"
                  style="secondary"
                  className="m-t-5"
                  data-action="copy-client-secret"
                  onClick={() => this.copyToClipboard(application.secret)}
                  disabled={!application.secret}
                />
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="float-end">
            <Button
              label={i18n.t('Regenerate Secret')}
              icon="rotate"
              style="primary"
              onClick={() => regenerateSecret(application)}
            />
            <Button
              label={i18n.t('Delete')}
              icon="trash"
              style="destructiveSolid"
              onClick={this.onDeleteApp}
              className="m-l-5"
            />
          </FieldGroup>
        </CardBody>
        <ConfirmationModal
          title={i18n.t("Delete {{ name }} Application", { name: application.name })}
          children={
            <div>
              <p>{i18n.t("Are you sure you want to delete the {{ name }} application?", { name: application.name })}</p>
              <p>{i18n.t("Warning: deleting this application will break any API integrations that use this application. This action cannot be reversed.")}</p>
            </div>
          }
          onConfirm={this.onConfirmDeleteApp}
          onCancel={this.onCancelDeleteApp}
          show={this.state.showDeleteModal}
        />
      </Card>
    )
  }
}
