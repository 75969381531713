import { buildGenericProps, JsonApiDecorator, UrlUtil } from 'reactifi';

export default function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'training_periods', ['organization']);
  props = Object.assign(props, {
    errorMessages: state.api.errorMessages,
    successMessage: state.api.successMessage,
    errorMessage: state.api.errorMessage,
    contentServiceUrl: ownProps.route.contentServiceUrl,
    token: ownProps.route.jwt,
    organizationId: ownProps.route.organizationId || ownProps.params.id,
    viewer: ownProps.route.viewer,
    userIsPrimaryManager: ownProps.route.userIsPrimaryManager,
    permissions: ownProps.route.permissions,
    orgCapabilities: ownProps.route.orgCapabilities,
    userManagedBusinessLines: ownProps.route.userManagedBusinessLines
  });

  let apiStore = new JsonApiDecorator(state.api);
  if (props.currentEntity && props.currentEntity.type === 'training_periods') {
    props.currentTrainingPeriod = props.currentEntity;
  }

  const { training_periods, business_lines, capabilities, rule_sets } = apiStore;

  props.trainingPeriods = training_periods ? training_periods.all() : [];
  props.businessLines = business_lines ? business_lines.all() : [];
  props.capabilities = capabilities ? capabilities.all() : [];
  props.ruleSets = rule_sets ? rule_sets.all() : [];
  props.currentBusinessLine = UrlUtil.getCurrentBusinessLine(props.ruleSets);

  if (props.currentTrainingPeriod && !props.currentTrainingPeriod.organization_id) {
    props.currentTrainingPeriod.organization_id = props.organizationId;
  }

  return props;
}