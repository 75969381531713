import React, { useCallback, useMemo, useState } from "react";
import OrganizationUserGroupingsModal from "../../OrganizationUserGroupingsModal";
import { getCategoryByLabelId } from "../../../../common/Components/categories/CategoryFunctions";

const useManageLabelsModalActions = props => {
  const {
    categories,
    categoryLabelUser,
    categoryLabelUsers,
    clearMessages,
    createUserGroupValue,
    deleteCategoryLabelUser,
    loadCategories,
    organizationId,
    user
  } = props;

  const [showModal, setShowModal] = useState(false);

  const onCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const onManageLabels = useCallback(async () => {
    clearMessages();
    await loadCategories({ organization_id: organizationId });
    setShowModal(true);
  }, [organizationId]);

  const removeCategoryLabelFromUser = useCallback(async (categoryLabelId) => {
    const userId = categoryLabelUsers.filter(category => category.category_label_id === categoryLabelId);
    const record = categoryLabelUser();
    record.id = userId[0].id;
    await deleteCategoryLabelUser(record);
  }, [categoryLabelUser, categoryLabelUsers]);

  const addCategoryLabelToUser = useCallback(async (categoryLabelId) => {
    const record = categoryLabelUser();
    record.user_id = user.id;
    record.category_label_id = categoryLabelId;
    await createUserGroupValue(record);
  }, [categoryLabelUser, user]);

  const saveUserGroupValue = useCallback(async (items) => {
    const validItems = Object.keys(items).filter((item => !!items[item]));

    const valuesListForAdd = validItems.map(item => Number(items[item]));
    const currentValuesList = categoryLabelUsers.map(item =>
      Number(item.category_label_id)
    );
    const toBeRemovedGroupingValues = currentValuesList.filter(
      group => !valuesListForAdd.includes(group)
    );
    const toBeAddedGroupingValues = valuesListForAdd.filter(
      group => !currentValuesList.includes(group)
    );

    toBeRemovedGroupingValues.forEach(removeCategoryLabelFromUser);

    // ensure the first iteration to add any category is executed in the next tick of the event loop
    setTimeout(() => {
      toBeAddedGroupingValues.forEach(addCategoryLabelToUser);
    }, 0);

    setShowModal(false);
  }, [categoryLabelUsers]);

  const selectedCategories = useMemo(() => {
    const result = {};

    const userCategoryLabels =
      categoryLabelUsers.map((value) => {
        const category = getCategoryByLabelId(
          categories,
          value.category_label_id
        );
        return {
          category_id: category.value,
          category_label_id: value.category_label_id
        };
      }) || {};

    Object.keys(userCategoryLabels).map((categoryLabelKey) => {
      const userCategoryLabel = userCategoryLabels[categoryLabelKey];
      result[
        `group_value_${userCategoryLabel.category_id}`
      ] = userCategoryLabel.category_label_id.toString();
    });

    return result;
  }, [categories, categoryLabelUsers]);

  const modal = (
    <OrganizationUserGroupingsModal
      {...props}
      onCancel={onCancel}
      saveUserGroupValue={saveUserGroupValue}
      selectedCategories={selectedCategories}
      showManageCategories={showModal}
    />
  );
  
  return {
    onManageLabels,
    modal
  };
};

export default useManageLabelsModalActions;
