import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { DisplayMaxWidthMedium, ExplainerText, Header, Section, useContainerProps } from 'reactifi';
import i18n from 'lib/i18n';
import TagManagerForm from './TagManagerForm';

const TagManager = props => {
  props = useContainerProps(props);
  const { currentEntity, params, selectMember } = props;
  const title = (params || {}).id === 'add' ?
    i18n.t('Add Tag') :
    i18n.t('Edit Tag');

  useEffect(() => {
    if (!currentEntity) {
      selectMember();
    }
  }, []);

  return (
    <>
      <Header back={true} title={title} />
      <Section>
        <DisplayMaxWidthMedium hideIfLoadingMember={true} >
          <>
            <ExplainerText>{i18n.t('Add a tag management system to your organization\'s financial education microsites.')}</ExplainerText>
            <TagManagerForm />
          </>
        </DisplayMaxWidthMedium>
      </Section>
    </>
  );
}

TagManager.propTypes = {
  currentEntity: PropTypes.object,
  params: PropTypes.shape({
    id: PropTypes.any
  }),
  selectMember: PropTypes.func
};

export default TagManager;
