import i18n from 'lib/i18n';
import { differenceWith, get, isEqual, uniq } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Stack } from '@chakra-ui/react';
import {
  AccessControlUtil,
  ApiUtil,
  buildGenericProps,
  ConfirmationModal,
  CopyUtil,
  DisplayMaxWidthMedium,
  ExplainerText,
  Header,
  JsonApiDecorator,
  JsonApiUtils,
  MessagesModule,
  PageWrapper,
  Section,
  UrlUtil
} from 'reactifi';
import { loadLanguages } from '../../common/api/languagesApi';
import { LOB_CAPABILITIES_MAP } from '../../common/capabilities';
import { availableTagManagers } from '../../TagManager/TagUtils';
import * as orgActionCreators from '../actions/orgActionCreators';
import * as orgBenchmarkActions from '../actions/orgBenchmarkActions';
import * as orgCapabilitiesActions from '../actions/orgCapabilitiesActions';
import {
  OrganizationBenchmarkModal,
  OrganizationDetailsModal
} from '../components';
import { OrganizationRedactionAlert } from '../components/OrganizationRedactionAlert';
import OrgBaseCapabilitiesCard from '../components/orgSettingsCards/OrgBaseCapabilitiesCard';
import OrgBenchmarkingAttributesCard from '../components/orgSettingsCards/orgBenchmarkingAttributesCard';
import OrgDetailsCard from '../components/orgSettingsCards/OrgDetailsCard';
import OrgHelpURLsCard from '../components/orgSettingsCards/OrgHelpURLsCard';
import OrgLanguagesCard from '../components/orgSettingsCards/OrgLanguagesCard';
import orgMapStateToProps from '../store/organizationMap';

const CAPABILITY_CONFIRM_CONFIG = Object.freeze({
  'Capabilities::ScormSso': {
    disable: {
      title: i18n.t('Disable SCORM Capability'),
      description: i18n.t(
        'Disabling the SCORM capability will permanently break the tokenized links in any SCORM Dispatch packages created for this organization. This action cannot be undone and new packages will need to be created and distributed if reenabled. Do you wish to continue?'
      )
    }
  },
  'Capabilities::LegacyAssignments': {
    disable: {
      title: i18n.t('Disable Legacy Assignment Capability'),
      description: i18n.t(
        'Disabling the Legacy Assignment capability will affect all legacy assignments. This action cannot be undone and all assignments must be archived.'
      )
    }
  }
});

function mapStateToProps(state, ownProps) {
  const orgProps = orgMapStateToProps(state, ownProps);
  const languageProps = buildGenericProps(state, 'languages');
  let props = {
    ...buildGenericProps(state, 'benchmark_categories', ['benchmark_groups']),
    ...buildGenericProps(state, 'benchmark_group_organizations', [
      'benchmark_category',
      'benchmark_group'
    ]),
    ...buildGenericProps(state, 'capabilities', [])
  };
  const apiStore = new JsonApiDecorator(state.api);

  props.orgCapabilities = apiStore.capabilities
    ? apiStore.capabilities.all()
    : [];
  props.capabilities = apiStore.capability_options
    ? apiStore.capability_options.all()
    : [];
  props.reports = apiStore.charts ? apiStore.charts.all() : [];
  props.organizationId = ownProps.router.params.id;
  props.permissions = ownProps.route.permissions;

  props.currentOrganization = apiStore.organizations?.find(
    props.organizationId,
    'favicon,logo,background_image,capabilities,organization_brand_style_guides,tag_managers'
  );

  props.createNewCapabilitiesObject = () => {
    return apiStore.newObject('capabilities');
  };
  props.createNewDetailsObject = () => {
    let newObject = apiStore.newObject('benchmark_group_organizations');
    newObject.visible_to_organization = true;
    return newObject;
  };
  if (languageProps) {
    languageProps.languages = languageProps.languages.filter(
      (language) => language.code !== 'en'
    );
  }
  if (
    orgProps.currentOrganization &&
    orgProps.currentOrganization.language_codes
  ) {
    orgProps.currentOrganization.language_codes =
      orgProps.currentOrganization.language_codes.filter(
        (code) => code !== 'en'
      );
  }

  return Object.assign({}, orgProps, languageProps, props);
}

class OrganizationSettingsContainer extends Component {
  static propTypes = {
    benchmark_categories: PropTypes.array,
    benchmark_group_organizations: PropTypes.array,
    capabilities: PropTypes.array,
    createNewCapabilitiesObject: PropTypes.func.isRequired,
    createNewDetailsObject: PropTypes.func.isRequired,
    currentLocation: PropTypes.object,
    currentOrganization: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    languages: PropTypes.array,
    organizationId: PropTypes.string.isRequired,
    orgCapabilities: PropTypes.array,
    params: PropTypes.shape({
      id: PropTypes.string
    }),
    permissions: PropTypes.arrayOf(PropTypes.string),
    reports: PropTypes.array,
    router: PropTypes.object,
    successMessage: PropTypes.string,
    viewer: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      {
        ...orgCapabilitiesActions,
        ...orgActionCreators,
        ...orgBenchmarkActions,
        loadLanguages
      },
      this.props.dispatch
    );

    this.state = {
      showEditModal: false,
      showBenchmarksModal: false,
      organizationId: props.params.id,
      currentOrganization: props.currentOrganization || {},
      isLoading: true,
      selectedCapabilities: {}
    };
  }

  async componentDidMount() {
    this.actions.loadLanguages();
    await this.actions.findCapabilities({
      organization_id: this.props.organizationId
    });
    await this.actions.selectOrgCapabilities(this.props.organizationId);
    await this.refreshBenchmarkData();
    await this.actions.findOrganization(this.props.organizationId);
    await this.actions.findReports(this.props.organizationId);
    this.getCapabilitiesData();
  }

  componentDidUpdate(prevProps) {
    if (
      !isEqual(this.props.currentOrganization, prevProps.currentOrganization)
    ) {
      this.setState({ currentOrganization: this.props.currentOrganization });
    }
  }

  async refreshBenchmarkData() {
    await this.actions.selectOrgBenchmarks(this.state.organizationId);
    await this.actions.findBenchmarks();
  }

  getCapabilitiesData = () => {
    const orgCapabilities = this.props.orgCapabilities.reduce((cache, curr) => {
      cache[curr.capability_type] = true;
      return cache;
    }, {});

    const selectedCapabilities = this.props.capabilities.reduce(
      (cache, curr) => {
        cache[curr.name] = orgCapabilities[curr.name] || false;
        return cache;
      },
      {}
    );

    this.setState({ selectedCapabilities, isLoading: false });
  };

  handleLanguageSearch = (data) => {
    const { languages } = this.props;

    const searchValue = data?.language_search?.toLowerCase();
    const newLanguages = !searchValue
      ? null
      : languages.filter(({ name }) => name.toLowerCase().includes(searchValue));
    this.setState({ languages: newLanguages });
  };

  languageChanged = (_language_codes) => {
    this.forceUpdate();
  };

  onSaveLanguages = async (organization) => {
    const { updateOrganization } = this.actions;

    await updateOrganization(organization);
  };

  onCancelLanguages = () => {
    this.actions.findOrganization(this.props.organizationId);
  };

  clearLanguages = () => {
    const { currentOrganization } = this.state;
    if (currentOrganization) {
      currentOrganization.language_codes = [];
      this.forceUpdate();
    }
  };

  findRemovedCapabilities = (activeCapabilities) => {
    const {
      currentOrganization: { capabilities }
    } = this.state;
    const orgCapabilities = capabilities.map(
      (capability) => capability.capability_type
    );

    return uniq(
      // Remove reapeated entries (i.e. `event-tracking`)
      differenceWith(orgCapabilities, activeCapabilities, isEqual) // Find removed capabilities
        .map(
          (removed) =>
            LOB_CAPABILITIES_MAP.find((lob) => lob.capability === removed)?.id
        ) // Map the removed capabilities to the applicable learning context capability "id"
        .filter((capability) => !!capability) // Filter out capabilities that are not applicable to users / learners
    );
  };

  validateRemovedCapabilities = async (activeCapabilities) => {
    const { organizationId: organization_id } = this.props;
    const removedCapabilities =
      this.findRemovedCapabilities(activeCapabilities);

    if (!removedCapabilities?.length) return true;

    for (const capability of removedCapabilities) {
      const url = JsonApiUtils.buildUrl(`/api/data/users.json`, {
        fields: {
          users: 'id'
        },
        filters: {
          organization_id,
          business_lines: capability
        },
        page: { number: 1, size: 1 }
      });
      const users = await ApiUtil.loadApiData(url);
      if (users?.length) {
        this.actions.displayErrorMessage(
          i18n.t(
            'Before you can remove this capability, please remove {{capability}} from all users first',
            { capability }
          )
        );
        return false;
      }
    }

    return true;
  };

  onSaveCapabilities = async (capabilities) => {
    const data = Object.keys(capabilities).map((key) => {
      return { key, active: capabilities[key] };
    });
    const activeCapabilities = data
      .filter((cap) => ['on', 'true'].includes(cap.active))
      .map((cap) => cap.key);

    if (await this.validateRemovedCapabilities(activeCapabilities)) {
      const record = this.props.createNewCapabilitiesObject();
      record.capability_types = activeCapabilities;

      await this.actions.updateOrgCapabilities(
        this.props.organizationId,
        record
      );
    }
  };

  onCancelCapabilities = () => {
    this.getCapabilitiesData();
  };

  sanitizeCapabilities = (capabilities) => {
    // convert "false" and "true" strings to boolean if present
    return Object.keys(capabilities).reduce((cache, curr) => {
      cache[curr] = JSON.parse(cache[curr]);
      return cache;
    }, capabilities);
  };

  onCancel = () => {
    this.actions.selectOrganization(this.state.organizationId);
    this.hideEditModal();
  };

  onCancelBenchmark = () => {
    this.refreshBenchmarkData();
    this.hideBenchmarkModal();
  };

  hideEditModal = () => {
    this.setState({ showEditModal: false });
  };

  hideBenchmarkModal = () => {
    this.setState({ showBenchmarksModal: false });
  };

  updateOrganization = async (data) => {
    if (data.help_url && !UrlUtil.IsURL(data.help_url)) {
      this.props.dispatch(
        this.actions.displayErrorMessage(
          i18n.t(
            'Please check the Help URL. It does not appear to be a valid address.'
          )
        )
      );
      return;
    }

    // Readonly props that shouldn't be in the request.
    delete data?.redaction_scheduled_at;
    delete data?.redacted_at;

    await this.actions.updateOnSave(data, this.hideEditModal);
  };

  updateOrgBenchmark = async (benchmarkGroupOrg) => {
    const benchmark = Object.assign(
      this.props.createNewDetailsObject(),
      benchmarkGroupOrg
    );
    const updateAction = benchmark.id
      ? this.actions.updateOrgBenchmark
      : this.actions.createOrgBenchmark;
    await updateAction(this.state.organizationId, benchmark);
    this.refreshBenchmarkData();
  };

  deleteOrgBenchmark = (benchmarkGroupOrg) => {
    this.actions.deleteOrgBenchmark(
      this.state.organizationId,
      benchmarkGroupOrg
    );
  };

  renderEditModalForm() {
    return (
      <OrganizationDetailsModal
        data={this.props.currentOrganization}
        showModal={this.state.showEditModal}
        onCancel={this.onCancel}
        updateAction={this.updateOrganization}
        errorMessage={this.props.errorMessage}
        viewer={this.props.viewer}
      />
    );
  }

  renderBenchmarkModalForm() {
    const {
      benchmark_categories,
      benchmark_group_organizations,
      createNewDetailsObject,
      currentOrganization,
      errorMessage,
      viewer
    } = this.props;

    const { showBenchmarksModal } = this.state;

    return (
      <OrganizationBenchmarkModal
        benchmarkCategories={benchmark_categories}
        createNewDetailsObject={createNewDetailsObject}
        currentOrganization={currentOrganization}
        data={benchmark_group_organizations}
        deleteAction={this.deleteOrgBenchmark}
        errorMessage={errorMessage}
        onCancel={this.onCancelBenchmark}
        showModal={showBenchmarksModal}
        updateAction={this.updateOrgBenchmark}
        viewer={viewer}
      />
    );
  }

  copyLoginPageUrl = () => {
    const { currentOrganization } = this.state;
    if (currentOrganization.cportal_url) {
      const success = CopyUtil.copyText(currentOrganization.cportal_url);
      if (success) {
        this.actions.displaySuccessMessage(
          i18n.t('Customer Login Page URL copied!')
        );
      } else {
        this.actions.displayErrorMessage(i18n.t('Oops, unable to copy'));
      }
    }
  };

  listOfTagManagers = () => {
    const { currentOrganization } = this.props;
    let tags = [];
    currentOrganization.tag_managers.map((tag) => {
      availableTagManagers.filter((item) => {
        if (item.value === tag.name) {
          tags.push(item.label);
        }
      });
    });
    return tags.length > 0 ? tags.join(', ') : i18n.t('No');
  };

  canEditCapabilities = () =>
    AccessControlUtil.hasPermission(
      'edit_capabilities',
      this.props.permissions
    );

  capabilitySelect = (name, value) => {
    const confirmType = value ? 'enable' : 'disable';
    const config = get(CAPABILITY_CONFIRM_CONFIG, `${name}.${confirmType}`);

    if (!config) {
      return;
    }

    this.setState({
      showConfirmModal: true,
      confirmCapability: name,
      confirmType
    });
  };

  onConfirm = () => {
    this.setState({
      showConfirmModal: undefined,
      confirmCapability: undefined,
      confirmType: undefined
    });
  };

  onCancelConfirm = () => {
    const { confirmCapability, confirmType } = this.state;
    let selectedCapabilities = { ...this.state.selectedCapabilities };
    selectedCapabilities[confirmCapability] =
      confirmType === 'disable' ? true : false;
    this.setState({
      showConfirmModal: undefined,
      confirmCapability: undefined,
      confirmType: undefined,
      selectedCapabilities
    });
  };

  updateOrgField = (field, value) => {
    this.setState({
      currentOrganization: Object.assign(this.state.currentOrganization, {
        [field]: value
      })
    });
  };

  onCancelHelpUrls = () => {
    this.actions.selectOrganization(this.state.organizationId);
  };

  getHeaderTitle() {
    const { currentOrganization } = this.props;

    if (currentOrganization) {
      return i18n.t('{{ name }} — Settings', {
        name: currentOrganization.name
      });
    }

    return i18n.t('Organization not found');
  }

  render() {
    const {
      showConfirmModal,
      confirmCapability,
      confirmType,
      isLoading,
      selectedCapabilities
    } = this.state;
    const {
      capabilities,
      currentLocation,
      currentOrganization,
      errorMessage,
      router,
      successMessage,
      permissions,
      reports
    } = this.props;
    const confirmConfig = showConfirmModal
      ? get(CAPABILITY_CONFIRM_CONFIG, `${confirmCapability}.${confirmType}`, {})
      : {};

    const languageCount =
      this.state.currentOrganization?.language_codes?.filter(
        (lang) => lang !== 'en'
      ).length || 0;

    if (!currentOrganization) return null;

    return (
      <PageWrapper>
        <MessagesModule
          successMessage={successMessage}
          errorMessage={currentLocation ? null : errorMessage}
          clearMessages={this.actions.clearMessages}
        />
        <Header
          back={true}
          icon="buildings"
          router={router}
          additionalTitleDisplay={
            <OrganizationRedactionAlert
              currentOrganization={currentOrganization}
            />
          }
          title={this.getHeaderTitle()}
        />
        <Section>
          <ExplainerText>
            {i18n.t(
              "View and edit organization details, capabilities, and languages. Capabilities are used to control an organization's access to certain featues, as well as for internal reporting purposes"
            )}
          </ExplainerText>
        </Section>
        <Section type="cards">
          <Stack>
            <DisplayMaxWidthMedium>
              <OrgDetailsCard
                copyLoginPageUrl={this.copyLoginPageUrl}
                permissions={permissions}
                reports={reports}
                currentOrganization={currentOrganization}
                showEditOrgDetailsModal={() =>
                  this.setState({ showEditModal: true })
                }
              />
            </DisplayMaxWidthMedium>
            <DisplayMaxWidthMedium>
              <OrgBaseCapabilitiesCard
                capabilities={capabilities}
                canEditCapabilities={this.canEditCapabilities}
                capabilitySelect={this.capabilitySelect}
                getCapabilitiesData={this.getCapabilitiesData}
                isLoading={isLoading}
                onSaveCapabilities={this.onSaveCapabilities}
                onCancelCapabilities={this.onCancelCapabilities}
                sanitizeCapabilities={this.sanitizeCapabilities}
                selectedCapabilities={selectedCapabilities}
              />
            </DisplayMaxWidthMedium>
            <DisplayMaxWidthMedium>
              <OrgLanguagesCard
                currentOrganization={this.state.currentOrganization}
                canEditCapabilities={this.canEditCapabilities}
                clearLanguages={this.clearLanguages}
                onSaveLanguages={this.onSaveLanguages}
                onCancelLanguages={this.onCancelLanguages}
                handleLanguageSearch={this.handleLanguageSearch}
                languageChanged={this.languageChanged}
                languages={this.state.languages || this.props.languages}
                languageCount={languageCount}
              />
            </DisplayMaxWidthMedium>
            <DisplayMaxWidthMedium>
              <OrgHelpURLsCard
                canEditCapabilities={this.canEditCapabilities}
                currentOrganization={this.state.currentOrganization}
                updateOrganization={this.updateOrganization}
                onCancelHelpUrls={this.onCancelHelpUrls}
                updateOrgField={this.updateOrgField}
              />
            </DisplayMaxWidthMedium>
            <>
              <DisplayMaxWidthMedium>
                <OrgBenchmarkingAttributesCard
                  permissions={permissions}
                  showOrgBenchmarkModal={() =>
                    this.setState({ showBenchmarksModal: true })
                  }
                />
              </DisplayMaxWidthMedium>
              {this.renderEditModalForm()}
              {this.renderBenchmarkModalForm()}
            </>
          </Stack>
        </Section>
        <ConfirmationModal
          show={showConfirmModal}
          title={confirmConfig.title}
          children={confirmConfig.description}
          onConfirm={this.onConfirm}
          onCancel={this.onCancelConfirm}
        />
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(OrganizationSettingsContainer);
