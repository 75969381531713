import { createEntity, selectEntity, deleteEntity, updateEntity, displayError, readEntityById, readEntity, readLocal, displaySuccessMessage, displayErrorMessage, getPageSize } from 'reactifi';
import { setEndpointPath } from 'redux-json-api';
import i18n from 'lib/i18n';

import types from '../constants/actionTypes';

export { clearMessages, displaySuccessMessage, displayErrorMessage, displayError } from 'reactifi';

export function createDomain(domain) {
  return createEntity(domain, (dispatch) => {
    dispatch(selectEntity(null));
  }, i18n.t('Domain created successfully.'));
}

export function loadDomains(sort, page) {
  return readEntity('domains', 'webhooks', null, sort && [sort], page && { number: page, size: getPageSize() });
}

export function loadDomain(id) {
  return readEntityById(id, 'domains', 'webhooks');
}

export function selectDomain(id) {
  return selectEntity(id, 'domains', 'webhooks');
}

export function updateDomain(domain) {
  return updateEntity(domain, (dispatch) => {
    dispatch(selectEntity(null, 'domains'));
  }, i18n.t('Domain updated successfully.'));
}

export function deleteDomain(domain) {
  return async (dispatch) => {
    try {
      await dispatch(deleteEntity(domain, 'domains'));
      dispatch(displaySuccessMessage(i18n.t('Domain deleted successfully.')));
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}

export function regenerateVerificationToken(domain) {
  return domainRequest(
    domain, 'verification_token_reset', 'domain_verification_tokens',
    i18n.t('Verification token regenerated.'),
    i18n.t('Unable to regenerate verification token.')
  );
}

export function regenerateSecretToken(domain) {
  return domainRequest(
    domain, 'secret_token_reset', 'domain_secret_tokens',
    i18n.t('Secret token regenerated.'),
    i18n.t('Unable to regenerate secret token.')
  );
}

export function verifyDomain(domain) {
  return domainRequest(
    domain, 'verification', 'domain_verifications',
    i18n.t('Domain verification initiated.'),
    i18n.t('Unable to initiate domain verification.')
  );
}

function domainRequest(domain, slug, type, successMessage, errorMessage) {
  return async function (dispatch) {
    try {
      const response = await fetch(`/api/data/domains/${slug}/${domain.id}`, {
        method: 'PATCH',
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify({
          data: {
            id: domain.id,
            type: type
          }
        })
      })
      const data = await response.json();
      await dispatch(readLocal(data));
      await dispatch(readEntityById(domain.id, 'domains', 'webhooks'));
      dispatch(displaySuccessMessage(successMessage));
    } catch (e) {
      dispatch(displayErrorMessage(errorMessage));
    }
  };
}

export function loadApplications() {
  return async (dispatch) => {
    dispatch(setEndpointPath('api/data/doorkeeper'));
    dispatch(readEntity('applications'));
    dispatch(setEndpointPath('api/data'));
  }
}

export function selectWebhook(id, domainId) {
  return {
    type: types.SELECT_WEBHOOK,
    id,
    domainId
  };
}

export function createWebhook(webhook) {
  return createEntity(webhook, (dispatch) => {
    dispatch(selectWebhook(null));
    dispatch(readEntityById(webhook.domain_id, 'domains', 'webhooks'));
  }, i18n.t('Webhook created successfully.'));
}

export function updateWebhook(webhook) {
  return updateEntity(webhook, (dispatch) => {
    dispatch(selectWebhook(null));
  }, i18n.t('Webhook updated successfully.'));
}

export function deleteWebhook(webhook) {
  return async (dispatch) => {
    try {
      await dispatch(deleteEntity(webhook, 'webhooks'));
      dispatch(displaySuccessMessage(i18n.t('Webhook deleted successfully.')));
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}

export function sendTestWebhook(webhook, webhookEventType) {
  return async function (dispatch) {
    try {
      const response = await fetch(`/api/data/webhooks/${webhook.id}/test`, {
        method: 'POST',
        headers: {
          'Content-type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          'X-CSRF-Token': document.querySelector('meta[name=csrf-token]').content
        },
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify({
          data: {
            id: webhook.id,
            type: 'webhooks',
            attributes: {
              event_type_names: [webhookEventType]
            }
          }
        })
      });
      if (response.status === 204) {
        dispatch(displaySuccessMessage(i18n.t('Test webhook sent.')));
      } else {
        dispatch(displayErrorMessage(i18n.t('Unable to send test webhook.')));
      }
    } catch (e) {
      dispatch(displayErrorMessage(i18n.t('Unable to send test webhook.')));
    }
  };
}
