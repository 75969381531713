export const DEFAULT_FILTERS = {
  custom: {
    active: ["true"]
  }
};

export const USER_FILTERS = {
  LEARNING_CONTEXT: 'business_lines',
  USER_TYPE_ROLES: 'rule_set_roles',
  CUSTOM_LABELS: 'category_label_ids',
  DATE_USER_ADDED: 'created_at',
  LOCATIONS: 'location_id',
  QUICK_LIST: 'roster_ids',
  EMAIL_ISSUES: 'bounced_email',
  USER_STATUS: 'active',
  CONTENT_COMPLETED: 'completed_content',
  CONTENT_NOT_ASSIGNNED: 'not_assigned_content_ids',
  TEAMS: 'teams_as_member_ids'
}
