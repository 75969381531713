import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import { PageWrapper, MessagesModule, Header, Section, ListView, DisplayFlex360, Display50Pct50Pct,
  JsonApiDecorator, IconCategoryValue, UrlUtil } from 'reactifi';
import * as teamsActionCreators from '../actions/teamsActionCreators';
import TeamForm from '../components/TeamForm';

function mapStateToProps(state, ownProps) {
  const apiStore = new JsonApiDecorator(state.api);
  let props = {
    errorMessage: state.api.errorMessage,
    organizationId: ownProps.route.organizationId,
    orgCapabilities: ownProps.route.orgCapabilities,
    successMessage: state.api.successMessage,
    teamId: ownProps.routeParams.team_id,
    userIsPiiRestrictedAdmin: ownProps.route.userIsPiiRestrictedAdmin,
    userManagedBusinessLines: ownProps.route.userManagedBusinessLines,
    userRuleSetRoles: ownProps.route.userRuleSetRoles,
    viewer: ownProps.route.viewer
  };

  props.currentTeam = apiStore.teams ? apiStore.teams.find(props.teamId, ['locations', 'category_labels']) : null;

  if (apiStore.users) {
    if (state.teamsReducer.userType === 'managers') {
      props.eligibleManagers = apiStore.users.all();
      props.eligibleManagers.forEach(manager => {
        manager.name = `${manager.first_name} ${manager.last_name}`;
      });
    } else {
      props.currentTeam.members = apiStore.users.all();
      props.membersMeta = state.api.users ? state.api.users.meta : null;
    }
  }

  props.categories = apiStore.categories ? apiStore.categories.all('category_labels') : [];
  props.categoryLabels = apiStore.category_labels ? apiStore.category_labels.all() : [];
  props.businessLines = apiStore.business_lines ? apiStore.business_lines.all() : [];
  props.ruleSets = apiStore.rule_sets ? apiStore.rule_sets.all() : [];
  props.currentBusinessLine = props.userManagedBusinessLines.length > 1
    ? UrlUtil.getCurrentBusinessLine(props.ruleSets)
    : props.userManagedBusinessLines[0];

  props.isAdvisor = ['advisor', 'pii_restricted_next_advisor'].some(val => Object.values(props.userRuleSetRoles).includes(val));

  return props;
}

export class TeamContainer extends React.Component {
  static propTypes = {
    businessLines: PropTypes.array,
    categories: PropTypes.array,
    category_labels: PropTypes.array,
    clearMessages: PropTypes.func,
    currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    currentTeam: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    membersMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    organizationId: PropTypes.string,
    router: PropTypes.shape({
      goBack: PropTypes.func
    }),
    ruleSets: PropTypes.array,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    teamId: PropTypes.string,
    userIsPiiRestrictedAdmin: PropTypes.bool,
    userManagedBusinessLines: PropTypes.arrayOf(PropTypes.string),
    usersMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    viewer: PropTypes.string.isRequired
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(teamsActionCreators, this.props.dispatch);

    this.state = {
      showEditModal: false
    }
  }

  async componentDidMount() {
    await this.actions.loadBusinessLines();
    await this.actions.loadCategories({ organization_id: this.props.organizationId });
    await this.actions.loadRuleSets();
    this.actions.findTeam(this.props.teamId, 'locations,category_labels');
  }

  loadTeamMembers = async (_, activePage) => {
    this.setState({ isLoadingData: true });
    await this.actions.loadTeamMembers({ teams_as_member_ids: this.props.teamId, active: true }, activePage);
    this.setState({ isLoadingData: false });
  }

  closeEditModal = () => {
    this.setState({ showEditModal: false });
    this.actions.findTeam(this.props.teamId, 'locations,category_labels');
    this.actions.loadTeamMembers({ teams_as_member_ids: this.props.teamId, active: true });
  }

  getUserBusinessLines = () => {
    const { currentBusinessLine } = this.props;

    return currentBusinessLine ? [{ id: currentBusinessLine }] : [];
  }

  get businessLine() {
    const { currentTeam, businessLines } = this.props;
    const businessLine = businessLines.find(bl => bl.slug === currentTeam.business_line);

    return businessLine && businessLine.label;
  }

  get locations() {
    return this.props.currentTeam.locations.map(location => `${location.name} (${location.address_formatted})`).join(', ');
  }

  get customCategories() {
    return this.props.currentTeam.category_labels.map(categoryLabel => `${categoryLabel.category_name} - ${categoryLabel.name}`).join(', ');
  }

  get teamManagers() {
    return this.props.currentTeam.current_managers.map(manager => manager.name).join(', ');
  }

  get fields() {
    return [
      { name: 'first_name', title: i18n.t('First Name') },
      { name: 'last_name', title: i18n.t('Last Name') },
      { name: 'email', title: i18n.t('Email') }
    ];
  }

  get teamHeaderDetails() {
    const { currentTeam } = this.props;

    return (
      <Section>
        <DisplayFlex360>
          <Display50Pct50Pct>
            <>
              {currentTeam.description && (
                <IconCategoryValue
                  category={i18n.t("Description")}
                  value={currentTeam.description}
                  type="stacked"
                  className="team-category-margin"
                />
              )}
              {this.teamManagers && (
                <IconCategoryValue
                  category={i18n.t("Team Managers")}
                  value={this.teamManagers}
                  type="stacked"
                />
              )}
            </>
            <>
              {this.businessLine && (
                <IconCategoryValue
                  category={i18n.t("Line of Business")}
                  value={this.businessLine}
                  className="team-category-margin"
                />
              )}
              {currentTeam.email && (
                <IconCategoryValue
                  category={i18n.t("Email")}
                  value={currentTeam.email}
                  className="m-b-0"
                />
              )}
              {currentTeam.website && (
                <IconCategoryValue
                  category={i18n.t("Website")}
                  value={currentTeam.website}
                  className="m-b-0"
                />
              )}
              {currentTeam.phone_number && (
                <IconCategoryValue
                  category={i18n.t("Phone")}
                  value={currentTeam.phone_number}
                  className="m-b-0"
                />
              )}
              {currentTeam.image_url && (
                <IconCategoryValue
                  category={i18n.t("Image URL")}
                  value={currentTeam.image_url}
                />
              )}
            </>
          </Display50Pct50Pct>
          <>
            {this.locations && (
              <IconCategoryValue
                category={i18n.t("Locations")}
                value={this.locations}
                type="stacked"
                className="team-category-margin"
              />
            )}
            {this.customCategories && (
              <IconCategoryValue
                category={i18n.t("Custom Categories")}
                value={this.customCategories}
                type="stacked"
              />
            )}
          </>
        </DisplayFlex360>
      </Section>
    );
  }

  renderTeamMembers() {
    const { currentTeam, membersMeta, userIsPiiRestrictedAdmin } = this.props;
    const memberTotalCount = membersMeta ? membersMeta.total_count : 0;

    if (userIsPiiRestrictedAdmin) return null;

    return (
      <Section type="list">
        <h4 className="m-b-15">{i18n.t('Team Members')}</h4>
        <ListView
          data={currentTeam.members || []}
          fields={this.fields}
          keyField="id"
          fullPage={false}
          totalCount={memberTotalCount}
          loadAction={this.loadTeamMembers}
          isLoadingData={this.state.isLoadingData}
        />
      </Section>
    );
  }

  render() {
    const { currentTeam, errorMessage, router, successMessage } = this.props;
    const userManagedBusinessLines = this.getUserBusinessLines();

    const actionButton = {
      label: i18n.t('Edit Team Info'),
      "data-action": "edit-team-info",
      action: () => this.setState({ showEditModal: true })
    };

    if (!currentTeam) return null;

    return (
      <PageWrapper
        title={currentTeam.name}
      >
        <MessagesModule
          successMessage={successMessage}
          errorMessage={errorMessage}
          clearMessages={this.actions.clearMessages}
        />
        <Header
          back={true}
          router={router}
          title={currentTeam.name}
          button={actionButton}
        >
          {this.teamHeaderDetails}
        </Header>
        {this.renderTeamMembers()}
        {this.state.showEditModal && (
          <TeamForm
            {...this.props}
            {...this.actions}
            businessLines={userManagedBusinessLines}
            onCancel={this.closeEditModal}
            onSave={this.closeEditModal}
          />
        )}
      </PageWrapper>
    );
  }
}

export default connect(mapStateToProps)(TeamContainer);
