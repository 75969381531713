import {
  ApiUtil,
  createCurrentEntity
} from 'reactifi';

export function copyImpactStory(id) {
  return async (dispatch) => {
    const result = await ApiUtil.loadApiData(`/api/data/impact_stories/${id}`);
    await dispatch(createCurrentEntity('impact_stories', { ...result, id: undefined }));
  }
}
