import React, { useCallback, useState } from "react";
import OrgUserSamlAttributesModal from "../../OrgUserSamlAttributesModal";

const useSamlAttributesModalActions = props => {
  const {
    user = {}
  } = props;

  const [showModal, setShowModal] = useState(false);

  const onCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const onShowSamlAttributes = useCallback(() => {
    setShowModal(true);
  }, []);

  const modal = (
    <OrgUserSamlAttributesModal
      show={showModal}
      samlAttributes={user.sso_attributes}
      onCancel={onCancel}
    />
  );
  
  return {
    onShowSamlAttributes,
    modal
  };
};

export default useSamlAttributesModalActions;
