import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Button, Section, useContainerProps } from "reactifi";
import i18n from 'lib/i18n';
import { impactStoryPath } from '../common/paths';
import OrganizationImpactStoriesList from './impactStories/OrganizationImpactStoriesList';
import OrganizationImpactStoryExplainer from './impactStories/OrganizationImpactStoryExplainer';

const OrganizationImpactStories = props => {
  props = useContainerProps(props);
  const { findOrganization, organization, params: { id: organizationId }, router } = props;

  useEffect(() => {
    if (!organization) {
      findOrganization(organizationId);
    }
  }, []);

  return (
    <>
      <Section>
        <OrganizationImpactStoryExplainer />
        <Button
          label={i18n.t('Create Impact Story')}
          onClick={() => router.push(impactStoryPath(organizationId, 'add'))}
          style="secondary"
        />
      </Section>
      <Section type="list">
        <OrganizationImpactStoriesList organizationId={organizationId} />
      </Section>
    </>
  );
};

OrganizationImpactStories.propTypes = {
  findOrganization: PropTypes.func,
  organization: PropTypes.object,
  params: PropTypes.shape({
    id: PropTypes.string
  }),
  router: PropTypes.shape({
    push: PropTypes.func
  })
};

export default OrganizationImpactStories;
