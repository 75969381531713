import React from 'react';
import { Util } from "@everfi/reactifi";
import { AttentionGrabber } from '@everfi/reactifi';
import i18n from 'lib/i18n';
import PropTypes from "prop-types";

export const OrganizationRedactionAlert = ({ currentOrganization }) => {
  if (
    !(
      currentOrganization?.redaction_scheduled_at ||
      currentOrganization?.redacted_at
    )
  ) {
    return null;
  }

  let message = '';

  if (currentOrganization?.redaction_scheduled_at) {
    message = i18n.t('Org Data to redact on {{ date }}', {
      date: Util.format(currentOrganization.redaction_scheduled_at, 'MM/dd/yyyy')
    });
  }

  if (currentOrganization?.redacted_at) {
    message = i18n.t('Org Data redacted on {{ date }}', {
      date: Util.format(currentOrganization.redacted_at, 'MM/dd/yyyy')
    });
  }

  return <AttentionGrabber icon="info-circle" type="error" label={message} />;
};

OrganizationRedactionAlert.propTypes = {
  currentOrganization: PropTypes.shape({
    redacted_at: PropTypes.string,
    redaction_scheduled_at: PropTypes.string
  })
}
