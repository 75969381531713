import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  JsonApiDecorator,
  GenericLabel,
  buildGenericProps,
  Wrapper,
  Header,
  Section,
  Display360Flex,
  DisplayWidthForBreakpoints,
  DisplayFlex,
  MessagesModule
} from 'reactifi';
import { bindActionCreators } from 'redux';
import Cookies from 'js-cookie';
import * as actionCreators from '../actions/registrationActionCreators';
import i18n from 'lib/i18n';
import OrgUserDetails from '../../Organizations/components/OrgUserDetails';
import RegistrationWizard from '../components/RegistrationWizard';
import DisplayCategories from '../../common/Components/categories/DisplayCategories';
import { Util } from '@everfi/reactifi';
import isEqual from 'lodash/isEqual';
import ManageBBIdProfile from '../../common/Components/ManageBBIdProfile';
import { sanitizeUrl } from '@braintree/sanitize-url';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'users');
  props = Object.assign(props, {
    errorMessages: state.api.errorMessages,
    googleMapsApiKey: ownProps.googleMapsApiKey,
    isManager: ownProps.isManager,
    domain: state.api.domain,
    currentUserId: ownProps.currentUserId,
    validRegistration: ownProps.validRegistration,
    organizationId: ownProps.organizationId,
    successMessage: state.api.successMessage,
    bbIdEnabled: ownProps.bbIdEnabled,
    bbIdRequired: ownProps.bbIdRequired
  });

  let apiStore = new JsonApiDecorator(state.api);
  const {
    categories,
    category_label_users,
    suppression_list_emails,
    suppression_lists
  } = apiStore;

  props.categoryLabelUsers = category_label_users
    ? category_label_users.all(['category_label.category'])
    : [];
  props.categories = categories ? categories.all('category_labels') : [];
  props.suppressionListEmails = suppression_list_emails
    ? suppression_list_emails.all()
    : [];
  props.suppressionLists = suppression_lists ? suppression_lists.all() : [];
  props.categoryLabelUser = () => {
    return apiStore.newObject('category_label_users');
  };

  props.registrationSets = apiStore.registration_sets
    ? apiStore.registration_sets.first()
    : {};

  props.isCreate = !props.registrationSets.id;
  props.registrations = props.registrationSets.registrations;
  if (apiStore.rule_sets) {
    props.ruleSets = apiStore.rule_sets.all();
  }

  if (apiStore.users) {
    props.user = apiStore.users.find(props.currentUserId, 'location');
  }
  if (props.registrations && props.user && props.user.location) {
    props.registrations.forEach((reg) => {
      if (
        reg.location_id &&
        String(reg.location_id) === String(props.user.location.id)
      ) {
        reg.location = props.user.location;
      }
    });
  }

  props.organizationName = props.registrationSets.organization_name || '';
  if (props.errorMessages) {
    props.errorMessage = i18n.t(
      'Your profile is incomplete. Please fill out the required fields'
    );
  }

  return props;
}

export class RegistrationContainer extends React.Component {
  static propTypes = {
    bbIdEnabled: PropTypes.bool,
    bbIdRequired: PropTypes.bool,
    categories: PropTypes.array,
    categoryLabels: PropTypes.array,
    categoryLabelUsers: PropTypes.array,
    currentUser: PropTypes.object,
    currentUserId: PropTypes.string,
    dispatch: PropTypes.func.isRequired,
    domain: PropTypes.string,
    errorMessage: PropTypes.string,
    errorMessages: PropTypes.array,
    googleMapsApiKey: PropTypes.string.isRequired,
    i18nLocale: PropTypes.string,
    isCreate: PropTypes.bool,
    isManager: PropTypes.bool,
    organization: PropTypes.object,
    organizationId: PropTypes.string,
    organizationName: PropTypes.string,
    router: PropTypes.shape({
      goBack: PropTypes.func
    }),
    registrations: PropTypes.array,
    registrationSets: PropTypes.object.isRequired,
    ruleSets: PropTypes.array,
    successMessage: PropTypes.string,
    suppresionLists: PropTypes.array,
    suppressionListEmails: PropTypes.array,
    user: PropTypes.object,
    validRegistration: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      showUserModal: false
    };
    this.actions = bindActionCreators(actionCreators, this.props.dispatch);
    this.selectUserDetails = this.selectUserDetails.bind(this);
    this.updateAction = this.updateAction.bind(this);
    this.register = this.register.bind(this);
    this.showUserModal = this.showUserModal.bind(this);
    this.resetPassword = this.resetPassword.bind(this);
  }

  componentDidMount() {
    if (this.props.validRegistration) {
      this.actions.selectCurrentUser('me');
      if (this.props.isManager) {
        this.actions.loadRuleSets();
        this.actions.loadCategories({
          organization_id: this.props.organizationId
        });
        this.actions.selectCategoryLabelUser();
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.categories.length > 0 &&
      !isEqual(prevProps.categories, this.props.categories)
    ) {
      this.actions.readCategoryLabelUsers({
        user_id: this.props.currentUserId
      });
    }
  }

  selectUserDetails() {
    return this.actions.selectUserDetails(
      this.props.currentUserId,
      this.props.organizationId
    );
  }

  async updateAction() {
    const result = await this.actions.updateUser(this.props.registrationSets);
    this.redirectOnValidRegistration(result);
    return result;
  }

  async register(regSets) {
    const result = await this.actions.register(regSets);
    this.redirectOnValidRegistration(result);
    return result;
  }

  redirectOnValidRegistration = (result) => {
    if (!this.props.validRegistration && result && result.path) {
      window.location.href = sanitizeUrl(result.path);
    }
  };

  async showUserModal() {
    await this.actions.selectUser(
      this.props.currentUserId,
      this.props.organizationId
    );
    this.setState({ showUserModal: true });
  }

  resetPassword() {
    this.actions.resetPassword(
      this.props.currentUserId,
      this.props.organizationId
    );
  }

  onCancelEdit = () => {
    this.setState({
      showUserModal: false
    });
  };

  goBack = () => {
    if (!Cookies.get('prevUrl')) {
      history.back();
      return;
    }
    window.location.href = this.getPrevUrl();
  };

  getPrevUrl = () => {
    if (!Cookies || !Cookies.get('prevUrl')) {
      return '';
    }

    const urlArray = Cookies.get('prevUrl').split('/');
    const lang = localStorage.getItem('i18nextLng').replace('-', '_');

    if (this.props.organization.language_codes.includes(urlArray[3])) {
      urlArray[3] = lang;
      return urlArray.join('/');
    }
    urlArray.splice(3, 0, lang);
    return urlArray.join('/');
  };

  render() {
    const {
      categories,
      categoryLabelUsers,
      errorMessage,
      googleMapsApiKey,
      isCreate,
      organizationId,
      organizationName,
      registrationSets,
      registrations,
      ruleSets,
      successMessage,
      user,
      validRegistration,
      isManager
    } = this.props;
    const { showUserModal } = this.state;
    const DisplayComponent = isManager
      ? DisplayFlex
      : DisplayWidthForBreakpoints;

    if (!validRegistration) {
      return (
        <>
          {/* Skip first role selection modal Bug#2830782 */}
          <span data-skip-role-selection="true" />
          <h1 className="sr-only">{i18n.t('Foundry Registration')}</h1>
          <RegistrationWizard
            {...this.actions}
            {...this.props}
            currentOrganizationId={organizationId}
            wizardStep={0}
            isCreate={isCreate}
            updateUser={this.updateAction}
            register={this.register}
            googleMapsApiKey={googleMapsApiKey}
            invalidRegistration={true}
          />
        </>
      );
    }

    if (!user) {
      return null;
    }

    return (
      <Wrapper>
        <MessagesModule
          successMessage={successMessage}
          errorMessage={errorMessage}
          clearMessages={this.actions.clearMessages}
        />
        <Header
          title={
            isCreate ? i18n.t('Foundry Registration') : i18n.t('My Profile')
          }
          back={() => this.goBack()}
          button={{
            action: this.showUserModal,
            label: i18n.t('Edit Profile'),
            icon: 'pen',
            style: 'primary',
            branded: true,
            'data-action-button': 'edit_profile'
          }}
          WrapperComponent={DisplayComponent}
        />
        <Section>
          <DisplayComponent>
            <>
              <h2 className="m-t-0">
                {user.first_name} {user.last_name}
              </h2>
              {user.under_13 ? (
                <GenericLabel
                  icon="circle-user"
                  label={i18n.t('Username')}
                  value={user.username}
                />
              ) : (
                <GenericLabel
                  icon="paper-plane-top"
                  label={
                    <a
                      className="branded-link prevent-loading-spinner"
                      href={`mailto:${user.email}`}
                    >
                      {user.email}
                    </a>
                  }
                />
              )}
              <GenericLabel
                className="m-t-5"
                icon="clock"
                label={i18n.t('Last Sign-in')}
                value={
                  user.last_sign_in_at
                    ? Util.format(user.last_sign_in_at, 'LLL')
                    : i18n.t('Never')
                }
              />
              {user.employee_id && (
                <GenericLabel
                  label={i18n.t('Employee ID')}
                  value={user.employee_id}
                />
              )}
              {user.student_id && (
                <GenericLabel
                  label={i18n.t('Student ID')}
                  value={user.student_id}
                />
              )}
              <Display360Flex>
                <ManageBBIdProfile
                  bbIdEnabled={this.props.bbIdEnabled}
                  isManager={isManager}
                />
              </Display360Flex>
              <div className="org-details m-t-20">
                <OrgUserDetails
                  registrationSets={registrationSets}
                  registrations={registrations}
                  organizationName={organizationName}
                  organizationId={organizationId}
                  selectUserDetails={this.selectUserDetails}
                  ruleSets={ruleSets}
                  displayFieldsInline={true}
                />
              </div>
              <DisplayCategories
                categories={categories}
                categoryLabelUsers={categoryLabelUsers}
              />
              {showUserModal ? (
                <RegistrationWizard
                  {...this.actions}
                  {...this.props}
                  currentOrganizationId={organizationId}
                  wizardStep={0}
                  onCancel={this.onCancelEdit}
                  isCreate={isCreate}
                  currentUser={user}
                  updateUser={this.updateAction}
                  googleMapsApiKey={googleMapsApiKey}
                />
              ) : null}
            </>
          </DisplayComponent>
        </Section>
      </Wrapper>
    );
  }
}

export default connect(mapStateToProps)(RegistrationContainer);
