import PropTypes from "prop-types";
import React, { Component } from "react";
import {
  PageWrapper,
  Section,
  DisplayWidthForCards,
  CardsModule,
  PresentationCard,
  Header
} from "reactifi";
import images from "../../../../images/reportimages";

import i18n from "lib/i18n";
import LegacyAssignmentsBanner from "../../../externals/components/LegacyAssignmentsBanner";

export default class Charts extends Component {
  static propTypes = {
    contexts: PropTypes.array,
    orgCapabilities: PropTypes.array,
    router: PropTypes.shape({
      push: PropTypes.func
    })
  };

  getImageForCard = (context, index) => {
    if (context.image_url) {
      return context.image_url;
    } else {
      let imagesLength = images.length;
      return images[index % imagesLength];
    }
  };

  selectReport = (id) => {
    this.props.router.push(`/chart/${id}`);
  };

  orderReports = (reports) => {
    const orderedReports = [];
    reports.map((report) => {
      switch (report.props.label) {
        case "Training Management":
          orderedReports[0] = report;
          break;
        case "Engagement":
          orderedReports[1] = report;
          break;
        case "Insights":
          orderedReports[2] = report;
          break;
      }
    });
    return orderedReports;
  };

  groupReports = () => {
    const { contexts } = this.props;
    return contexts.reduce((accumulator, context) => {
      if (context.chart.data.group) {
        accumulator[context.chart.data.group] =
          accumulator[context.chart.data.group] || [];
        accumulator[context.chart.data.group].push(context);
      } else {
        accumulator["Training Management"] =
          accumulator["Training Management"] || [];
        accumulator["Training Management"].push(context);
      }
      return accumulator;
    }, {});
  };

  getReportGroups = () => {
    const groupedReports = this.groupReports();
    const reports = [];

    for (let key in groupedReports) {
      if (Object.prototype.hasOwnProperty.call(groupedReports, key)) {
        groupedReports[key].sort((itemA, itemB) => {
          if (itemA.chart.name > itemB.chart.name) {
            return 1;
          } else if (itemB.chart.name > itemA.chart.name) {
            return -1;
          } else {
            return 0;
          }
        });
        reports.push(
          <CardsModule
            resultsDisplayed={false}
            showPageSizeDropdown={false}
            collection={groupedReports[key]}
            corner="small"
            shadow="light"
            label={key}
            labelPosition="left"
            mediaBackground="light"
            type="narrow"
          >
            {(context, index) => {
              let imageForCard = this.getImageForCard(context, index);
              return (
                <PresentationCard
                  type="narrow"
                  mediaSize="small"
                  mediaPosition="center"
                  mediaUrl={imageForCard}
                  onClick={() => this.selectReport(context.chart_id)}
                  title={context.chart.name}
                  description={context.chart.description}
                  data-object-type="report"
                  data-object-id={context.id}
                  data-action="view-report"
                />
              );
            }}
          </CardsModule>
        );
      }
    }
    return this.orderReports(reports);
  };

  render() {
    return (
      <PageWrapper>
        <LegacyAssignmentsBanner capabilities={this.props.orgCapabilities} />
        <Header title={i18n.t("Reports")} />
        <Section type="cards">
          <DisplayWidthForCards>{this.getReportGroups()}</DisplayWidthForCards>
        </Section>
      </PageWrapper>
    );
  }
}
