import React from 'react';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import createStore from './store/store';
import ChartsContainer from './containers/ChartsContainer';
import ChartsChangelogContainer from './containers/ChartsChangelogContainer';

export default (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);

  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/" component={ChartsContainer} />
        <Route path="/:slug/changelog" component={ChartsChangelogContainer} />
      </Router>
    </RootProvider>
  );
};
