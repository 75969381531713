import React from "react";
import PropTypes from "prop-types";
import {
  WizardModal,
  Step
} from "reactifi";
import i18n from "lib/i18n";

import DataTypeStep from './DataTypeStep';
import DownloadOptionsStep from './DownloadOptionsStep';

export default class DatafiExportForm extends React.Component {
  static propTypes = {
    dataExport: PropTypes.object,
    dataTypes: PropTypes.array,
    selectDataExport: PropTypes.func.isRequired,
    wizardStep: PropTypes.number
  }

  constructor(props) {
    super(props);
    this.state = {
      selectedUserTypes: {}
    };
  }

  onCancel = () => {
    this.props.selectDataExport(null);
  }

  render() {
    const { dataExport, dataTypes, wizardStep } = this.props;

    if (!dataExport) {
      return null;
    }

    const canChooseDataType = dataTypes.length > 1;
    const forceWizardStep = canChooseDataType ? wizardStep : 0;
    const steps = [
      <Step title={i18n.t("Select Download Options")}>
        <DownloadOptionsStep {...this.props} />
      </Step>
    ];
    if (canChooseDataType) {
      steps.unshift(<Step title={i18n.t("Select Data Type")}>
        <DataTypeStep {...this.props} />
      </Step>);
    }

    return (
      <WizardModal
        {...this.props}
        children={steps}
        modalSize="medium"
        title={this.getTitle}
        finalButtonLabel={i18n.t('Create Download')}
        onCancel={this.onCancel}
        useStepTitle={true}
        initialStep={forceWizardStep} />
    );
  }
}
