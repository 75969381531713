import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, IconCategoryValue } from "reactifi";
import { getCategoryByLabelId } from "../../../common/Components/categories/CategoryFunctions";
import OrgUserDetails from "../OrgUserDetails";
import i18n from 'lib/i18n';

const CATEGORY_TRUNCATED_COUNT = 4;

const HeroColumn2 = props => {
  const { categories, categoryLabelUsers = [], unlimitedAccess } = props;
  
  const [showAllCategories, setShowAllCategories] = useState(false);
  const categoryList = useMemo(() => {
    if (!categoryLabelUsers.length) {
      return [];
    }
    return categoryLabelUsers.map(categoryLabelUser => (
      getCategoryByLabelId(categories, categoryLabelUser.category_label_id)
    ));
  }, [categories, categoryLabelUsers]);

  if (!unlimitedAccess) {
    return null;
  }

  const renderCategories = () => {
    if (!categoryList.length) {
      return null;
    }
    let displayedCategoryList = categoryList;
    let showMoreButton = false;
    if (categoryList.length > CATEGORY_TRUNCATED_COUNT) {
      showMoreButton = true;
      if (!showAllCategories) {
        displayedCategoryList = categoryList.slice(0, CATEGORY_TRUNCATED_COUNT);
      }
    }
    const categoryListValue = (
      <>
        {displayedCategoryList.map((category, index) => (
          <div key={index}>{`${category.label}: ${category.value_label}`}</div>
        ))}
      </>
    );
    return (
      <>
        <IconCategoryValue
          category={i18n.t('Custom Categories')}
          type="stacked"
          value={categoryListValue}
          data-action="custom-categories"
        />
        {showMoreButton && (
          <Button
            icon={showAllCategories ? "minus" : "plus"}
            label={showAllCategories ?
              i18n.t('Less') :
              i18n.t('{{ count }} More', { count: categoryList.length - CATEGORY_TRUNCATED_COUNT })
            }
            onClick={() => setShowAllCategories(!showAllCategories)}
            style="link"
          />
        )}
      </>
    )
  }

  return (
    <>
      <OrgUserDetails {...props} target="location" />
      {renderCategories()}
      <OrgUserDetails {...props} target="team" />
    </>
  );
};

HeroColumn2.propTypes = {
  categories: PropTypes.array.isRequired,
  categoryLabelUsers: PropTypes.array.isRequired,
  unlimitedAccess: PropTypes.bool
};

export default HeroColumn2;
