import React from 'react';
import PropTypes from 'prop-types';
import { createContainer } from 'reactifi';
import ActionableDashboard from '../components/ActionableDashboard';
import * as actionCreators from '../actions/actionableDashboardActions';

function mapStateToProps(state, ownProps) {
  const { apiStore } = ownProps;
  const props = {
    trainingPeriods: apiStore.training_periods ? apiStore.training_periods.all() : [],
    systemMessages: apiStore.system_messages ? apiStore.system_messages.all() : [],
    todos: apiStore.todo_item ? apiStore.todo_item.all() : [],
    ruleSets: apiStore.rule_sets ? apiStore.rule_sets.all() : []
  };
  return props;
}

const Container = createContainer({ mapStateToProps, actionCreators });

const ActionableDashboardContainer = props => {
  const contextsData = (props.contexts.data || []).map(ctx => Object.assign({}, { id: ctx.id, ...ctx.attributes }));

  return (
    <Container>
      <ActionableDashboard {...props} contexts={contextsData} />
    </Container>
  );
};

ActionableDashboardContainer.propTypes = {
  contexts: PropTypes.object
}

export default ActionableDashboardContainer;
