import { selectEntity, updateEntity, createEntity, readEntity, readEntityById, displaySuccessMessage, deleteEntity, downloadReport, clearApiState } from 'reactifi';
import i18n from 'lib/i18n';
import { setHeader } from 'redux-json-api';

export { clearMessages, displayError, displayErrorMessage, displaySuccessMessage, endTimeout, fetchReport } from 'reactifi';

export function selectRegistrationBatch(registrationBatchId) {
  return selectEntity(registrationBatchId, 'registration_batches');
}

export function loadRuleSets() {
  return readEntity('rule_sets');
}

export function readRegistrationBatches(registrationBatchId) {
  return readEntityById(registrationBatchId, 'registration_batches');
}

export function findRegistrationBatches(filters, page, sort) {
  if (!sort) {
    sort = { value: "updated_at", direction: "desc" };
  }
  return (dispatch) => {
    dispatch(setHeader({ 'X-SESSION-CONTROL': 'refresh=false' }))
    dispatch(readEntity('registration_batches', '', filters, sort && [sort], page));
  }
}

export function updateRegistrationBatch(registrationBatch) {
  return updateEntity(registrationBatch, (dispatch, getState, entity) => {
    dispatch(selectEntity(entity.data.id, entity.data.type));
  }, false, true);
}

export function updateRegistrationAndClose(registrationBatch) {
  return updateEntity(registrationBatch, (dispatch) => {
    dispatch(selectEntity(null, 'registation_batches'));
    dispatch(displaySuccessMessage(i18n.t("Your upload is processing, we will send you an email when it is complete.")));
  }, false, true);
}

export function createRegistrationBatch(registrationBatch) {
  return async (dispatch) => {
    return dispatch(createEntity(registrationBatch, (dispatch, getState, entity) => {
      dispatch(selectEntity(entity.data.id, entity.data.type));
    }, false, true));
  }
}

export function readLocations() {
  const sort = { value: 'external_id', direction: 'asc' };
  return readEntity('locations', undefined, undefined, sort && [sort]);
}



export function deleteRegistrationBatch(registrationBatch) {
  return deleteEntity(registrationBatch, 'registration_batches', i18n.t('Deleted successfully.'));
}

export function loadBusinessLines() {
  return readEntity('business_lines');
}

export function downloadSucessfulRows(registrationBatchId) {
  return downloadReport('successfulrows', { params: { registration_batch_id: registrationBatchId, report_type: 'sucessfulrows' } });
}

export function downloadFailedRows(registrationBatchId) {
  return downloadReport('failedrows', { params: { registration_batch_id: registrationBatchId, report_type: 'failedrows' } });
}

export function clearDownload() {
  return clearApiState('download');
}
