import React from 'react';
import PropTypes from 'prop-types';
import {
  FieldGroup,
  FileUpload,
  s3MediaUploader,
  Form
} from 'reactifi';

import i18n from 'lib/i18n';

import UploadStep from './UploadStep';

export default class UploadFileStep extends UploadStep {

  constructor(props) {
    super(props);

    this.state = {
      disableButtons: false,
      media_id: props.currentEntity.media_id
    };
  }

  static propTypes = {
    acceptTypes: PropTypes.any.isRequired,
    closeOnNext: PropTypes.bool,
    createAction: PropTypes.func.isRequired,
    currentEntity: PropTypes.object.isRequired,
    displayErrorMessage: PropTypes.func,
    onClose: PropTypes.func.isRequired,
    preProcessData: PropTypes.func,
    selectAction: PropTypes.func.isRequired,
    updateAction: PropTypes.func.isRequired
  };

  fileUploadSuccess = (e) => {
    if (e && e.data && e.data.id) {
      this.setState({ media_id: e.data.id });
    }
  };

  goForward = async () => {
    let data = await this.form.save();
    if (data) {
      data.media_id = this.state.media_id;
      data = this.preProcessData(data);
      if (this.props.currentEntity.id) {
        await this.props.updateAction(data);
      } else {
        await this.props.createAction(data);
      }
      if (this.props.closeOnNext) {
        this.props.onClose();
      } else {
        return true;
      }
    } else {
      if (!this.state.media_id) {
        this.props.displayErrorMessage(i18n.t('Please upload a file'));
      }
    }
  };

  preProcessData = data => {
    if (typeof this.props.preProcessData === 'function') {
      data = this.props.preProcessData(data);
    }
    return data;
  };

  renderBody = () => {
    const { currentEntity, acceptTypes, displayError } = this.props;
    const { media_name } = currentEntity;

    const files = [];
    if (media_name) {
      files.push({ name: media_name });
    }

    return (
      <Form
        data={currentEntity}
        saveButton={false}
        cancelButton={false}
        ref={(form) => { this.form = form; }}
        disableButtons={this.state.disableButtons}
      >
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FileUpload
              uploader={s3MediaUploader}
              required={!this.state.media_id}
              header={false}
              name="filename"
              accept={acceptTypes}
              icon={{
                className: 'images',
                fileClassName: 'file',
                noFileClassName: 'file-slash'
              }}
              input={{ name: "upload" }}
              button={{ text: i18n.t("Choose a File"), className: "btn btn-secondary" }}
              actions={{ success: this.fileUploadSuccess, failure: displayError }}
              files={files}
              className="user-uploader"
            />
          </FieldGroup>
        </FieldGroup>
      </Form>
    );
  }
}
