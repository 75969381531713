import {
  ApiUtil,
  clearAndRead,
  clearApiState,
  clearMessages,
  createEntity,
  deleteEntity,
  displayError,
  displayErrorMessage,
  displaySuccessMessage,
  downloadReport,
  JsonApiUtils,
  readEntity,
  readEntityById,
  readLocal,
  selectEntity,
  setActiveFilters,
  setActivePage,
  setActiveSort,
  setActiveSearch,
  updateEntity,
  UrlUtil
} from 'reactifi';
import { buildUrl } from 'reactifi/dist/es/json-api/JsonApiUtils';

export { clearMessages, displayErrorMessage, endTimeout, displaySuccessMessage, displayError } from 'reactifi';

import { createEntity as createEntityApi } from 'redux-json-api';
import { convertCustomFilters } from 'externals/components/functions/convertCustomFilters';
import { buildUsersQuery } from '../common/queries';

import i18n from 'lib/i18n';

export function clearUserAssignments() {
  return clearApiState('user_assignments');
}

export function clearUserProgress() {
  return clearApiState('progress');
}

export function clearTrainingPeriods() {
  return clearApiState('training_periods');
}

export function clearUsers(){
  return async function(dispatch){
    dispatch(clearApiState('registration_sets'));
    dispatch(clearApiState('users'));
  }
}

export function selectUser(userId, orgId, viewer) {
  return async function(dispatch, getState) {
    let url = `organizations/${orgId}/registrations/${userId}/edit?include=location`;
    const dispatchAction = (action) => {
      if (typeof action === 'function') {
        dispatchAction(action(dispatchAction, getState));
      } else if (action && action.type !== 'DISPLAY_ERROR') {
        dispatch(action);
      }
    }
    await clearAndRead(dispatchAction, getState, 'registration_sets', url);
  };
}

export function selectUserDetails(userId, orgId, viewer) {
  return async function(dispatch, getState) {
    let url = `organizations/${orgId}/registrations/${userId}/details?include=location,teams_as_member,sso_attributes`;
    await clearAndRead(dispatch, getState, 'registration_sets', url);
  };
}

export function deleteUser(user) {
  return deleteEntity(user, 'users', i18n.t('User deleted successfully!'))
}

export function loadUser(userId) {
  return selectEntity(userId, 'users');
}

export function selectCurrentUser(userId) {
  return selectEntity(userId, 'users', 'location,teams_as_member,sso_attributes');
}

export function resetPassword(user_id) {
  return async function(dispatch, getState) {
    let passwordReset = {
      type: 'password_resets',
      attributes: {
        user_id
      }
    };
    try {
      await dispatch(createEntityApi(passwordReset));
      dispatch(
        displaySuccessMessage(
          'Password reset instructions will be emailed to this user'
        )
      );
    } catch (e) {
      dispatch(displayErrorMessage('Password reset failed'));
    }
  };
}

export function sendInvitation(user_id) {
  return async function(dispatch, getState) {
    let inviteToPlatform = {
      type: 'user_invitations',
      attributes: {
        user_id
      }
    };
    try {
      await dispatch(createEntityApi(inviteToPlatform));
      dispatch(
        displaySuccessMessage(
          'Invitation was sent successfully'
        )
      );
    } catch (e) {
      dispatch(displayErrorMessage('Invitation failed'));
    }
  };
}

export function setActivePageAndFetch(page){
  return async function(dispatch, getState) {
    const { api: { activeFilters, activeSort, activeSearch } } = getState();
    dispatch(setActivePage(page));
    dispatch(findUsers(activeFilters, { number: page }, activeSort, activeSearch));
  }
}
export function setActiveFiltersAndFetch(filters, resetSearch, activeSort, activeSearch) {
  return async function(dispatch) {
    dispatch(setActiveFilters(filters));
    dispatch(setActivePage(1));
    if (resetSearch) {
      activeSearch = '';
      dispatch(setActiveSearch(activeSearch));
    }
    dispatch(findUsers(filters, { number: 1 }, activeSort, activeSearch));
  }
}
export function setActiveSortAndFetch(sort){
  return async function(dispatch, getState) {
    const { api: { activeFilters, activeSearch, activePage } } = getState();
    dispatch(setActiveSort(sort));
    dispatch(findUsers(activeFilters, activePage, sort, activeSearch));
  }
}
export function setActiveSearchAndFetch(search){
  return async function(dispatch, getState) {
    const { api: { activeFilters, activeSort } } = getState();
    dispatch(setActiveSearch(search));
    dispatch(setActivePage(1));
    dispatch(findUsers(activeFilters, { number: 1 }, activeSort, search));
  }
}

export function findUsers(filters, page = 1, sort, searchValue) {
  if (!sort) {
    sort = { value: 'last_name', direction: 'asc' };
  }
  const query = buildUsersQuery(searchValue);
  const apiFilters = Object.assign({}, filters);
  apiFilters.custom = convertCustomFilters(filters.custom);
  return readEntity(
    'users',
    '',
    apiFilters,
    sort && [sort],
    page,
    query
  );
}

export function findLocations(filters) {
  return readEntity('locations', '', filters, [
    { value: 'name' },
    { value: 'address_formatted' }
  ]);
}

export function loadOrganization(id) {
  return readEntityById(id, 'organizations', 'logo,background_image');
}

export function findContent(url, token, orgId, businessLines) {

  const filters = [];
  if (orgId) {
    filters.push(`filter[organization_id]=${orgId}`);
  }
  if (businessLines) {
    businessLines.forEach(businessLine => {
      filters.push(`filter[business_lines][]=${businessLine}`);
    });
  }
  const filterParams = filters.join('&');
  const apiUrl = `${url}/api/v1/contents.json?${filterParams}`;

  return async function(dispatch, getState) {
    const contents = await ApiUtil.loadExternalData(apiUrl, token, false);
    dispatch(readLocal(contents));
  };
}

export function findUserProgress(url, userId, token, userAssignmentId) {
  return async function(dispatch, getState) {
    const userAssignments = await ApiUtil.loadExternalData(
      `${url}/api/v1/users/${userId}/user_assignments/${userAssignmentId}.json?include=assignment,progresses.content,content`,
      token,
      false
    );
    dispatch(readLocal(userAssignments));
  };
}

export function findUserAssignmentsByBusinessLine(businessLine, url, userId, token) {
  return async dispatch => {
    try {
      const endpoint = `${url}/api/v1/users/${userId}/user_assignments.json?filter[business_lines]=${businessLine}&` +
        `include=assignment`;
      const data = await ApiUtil.loadExternalData(endpoint, token, false);
      data.endpoint = endpoint;

      dispatch({ type: 'API_READ', payload: data, businessLine });
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

export function findUserProgressByBusinessLine(businessLine, url, userId, token, page, selectedFilters) {
  return async dispatch => {
    try {
      const businessLineFilter = { business_lines: businessLine };
      const filters = Object.assign({}, businessLineFilter, selectedFilters)
      const endpoint = buildUrl(
        `${url}/api/v1/assignments/progress.json?user_id=${userId}`,
        {
          includes: 'content,user_assignments,user_assignments.assignment',
          filters,
          page: { number: page }
        }
      );
      const data = await ApiUtil.loadExternalData(endpoint, token, false);
      data.endpoint = endpoint;

      dispatch({ type: 'API_READ', payload: data, businessLine });
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

export function getCertificates(baseUrl, token, userId, filters) {

  return async dispatch => {
    try {
      const url = buildUrl(
        `${baseUrl}/api/v1/users/${userId}/user_awards.json`,
        {
          filters,
          page: { number: 1, size: 1000 }
        }
      );
      const data = await ApiUtil.loadExternalData(url, token, false)

      if(data) {
        dispatch(readLocal(data));
      }
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

export function findScormContexts(contentServiceUrl, userId, token) {
  return async dispatch => {
    try {
      dispatch(clearApiState('scorm_contexts'));
      const endpoint = `${contentServiceUrl}/api/v1/scorm/contexts.json?user_id=${userId}&include=progress,scorm_package`;
      const data = await ApiUtil.loadExternalData(endpoint, token, false);
      data.endpoint = endpoint;

      dispatch({ type: 'API_READ', payload: data });
    } catch (err) {
      dispatch(displayError(err));
    }
  }
}

export function updateScormContext(context, contentServiceUrl, token) {
  return async dispatch => {
    try {
      let data = context.unwrap();
      delete data.attributes.relationships;

      await fetch(`${contentServiceUrl}/api/v1/scorm/contexts/${context.id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/vnd.api+json',
          Accept: 'application/vnd.api+json',
          Authorization: `Bearer ${token}`,
          Cache: 'no-cache'
        },
        mode: 'cors',
        credentials: 'same-origin',
        body: JSON.stringify({ data })
      });
    } catch (err) {
      dispatch(displayErrorMessage(i18n.t('Dispatch activity could not be updated.')));
    }
  };
}

export function loadRuleSets(organizationId, businessLines) {
  return readEntity('rule_sets', '', { organization_id: organizationId, ...businessLines ? { business_lines: businessLines } : null });
}

export function loadOrgRuleSets(organizationId) {
  return readEntity({
    entity: 'rule_sets',
    entityAlias: 'org_rule_sets',
    filters: { organization_id: organizationId }
  });
}

export function loadBusinessLines() {
  return readEntity('business_lines');
}

export function loadTrainingPeriods(organizationId) {
  return readEntity('training_periods', '', { organization_id: organizationId }, null, { number: 1, size: 100 });
}

export function loadCategories(filters) {
  return readEntity(
    'categories',
    ['category_labels'],
    filters,
    [{ value: 'name', direction: 'asc' }],
    { number: 1, size: 100 }
  );
}

export function loadUserFormData(orgId, selectedRuleSets, login, loginType) {
  let ruleSetFilter = '';
  Object.entries(selectedRuleSets).forEach(([key, value]) => {
    ruleSetFilter += `rule_set_roles[${key}]=${value}&`
  })
  ruleSetFilter = ruleSetFilter.slice(0, -1);
  return async function(dispatch, getState) {
    const url = `organizations/${orgId}/registrations/new.json?${ruleSetFilter}`;
    await clearAndRead(dispatch, getState, 'registration_sets', url);
    const registrations = (((((getState().api.registration_sets || {}).data || [])[0] || {}).attributes || {}).registrations || []);
    const baseRegistration = registrations[0];
    if (baseRegistration && loginType) {
      baseRegistration[loginType] = login;
      baseRegistration.has_email_checked = loginType;
      baseRegistration.metadata[loginType].editable = false;
      baseRegistration.metadata.has_email_checked.editable = false;
    }

    return registrations;
  };
}

export function register(registrationSets) {
  return async function(dispatch, getState) {
    dispatch(clearMessages);
    let path = createEntity(
      registrationSets,
      null,
      i18n.t('User created successfully!'),
      null,
      true
    );
    return await path(dispatch, getState);
  };
}

export function registerFromAdmin(registrationSets, addAnother) {
  return createEntity(
    registrationSets,
    dispatch => {
      if (addAnother) {
        dispatch(selectEntity(null, 'registration_sets'));
      }
    },
    i18n.t('User created successfully!'),
    true,
    true
  );
}

export function updateUser(registrationSets) {
  return updateEntity(
    registrationSets,
    null,
    i18n.t('User updated successfully!'),
    true
  );
}

export function updateUserFromAdmin(registrationSets) {
  return updateEntity(
    registrationSets,
    null,
    i18n.t('User updated successfully!'),
    true
  );
}

export function updateUserRuleSets(user, orgId) {
  return updateEntity(
    user,
    async function(dispatch, getState, redirect) {
      let url = `organizations/${orgId}/registrations/${user.id}/edit`;
      await clearAndRead(dispatch, getState, 'registration_sets', url);
    },
    false
  );
}

export function toggleUserActivation(registrationSets) {
  const regSet = Object.assign(
    Object.create(Object.getPrototypeOf(registrationSets)),
    registrationSets,
    {
      registration_context: 'admin',
      registrations: registrationSets.registrations.map(registration => {
        if (registration.id === 'user_rule_set') {
          registration.active = !registration.active;
        }
        return registration;
      })
    }
  );

  return updateEntity(regSet, async dispatch => {
    await dispatch(selectEntity(regSet.id, 'users', 'location'))
    dispatch(displaySuccessMessage('User updated successfully!'));
  }, false);
}

export function loadLocation(id, organizationId) {
  return async dispatch => {
    const url = `/api/data/locations/${id}.json?filter[organization_id]=${organizationId}`;
    const data = await ApiUtil.loadApiData(url, false);
    dispatch(readLocal(data));
  }
}

export async function loadLocations(organizationId) {
  const url = `/api/data/locations.json?filter[organization_id]=${organizationId}&sort=name,address_formatted`;
  return await ApiUtil.loadApiData(url, false);
}

export function downloadUsers(filters) {
  return downloadReport('user', { params: { organization_id: filters.organization_id }, filters: convertCustomFilters(filters.filters, false) });
}

export function readCategoryLabelUsers(filters = {}) {
  return readEntity(
    'category_label_users',
    'category_label',
    filters
  );
}

export function createUserGroupValue(item) {
  return createEntity(
    item,
    dispatch => {
      //do it this way so the modal unloads and reloads, which makes sure the form clears
      dispatch(selectEntity(null, 'category_label_users'));
      readCategoryLabelUsers();
    },
    i18n.t('User added to label value successfully!')
  );
}

export function bulkUpdate(api, action, successMessage) {
  return async dispatch => {
    try {
      const response = await fetch('/api/data/bulk_actions/' + api,
        {
          method: 'POST',
          headers: {
            'Content-type': 'application/vnd.api+json',
            Accept: 'application/vnd.api+json'
          },
          mode: 'cors',
          credentials: 'same-origin',
          body: JSON.stringify(action)
        }
      );

      const data = await response.json();

      if(data.errors) {
        dispatch(displayErrorMessage(data.errors.map(e => e.title).join(',')));
      } else {
        dispatch(displaySuccessMessage(successMessage));
      }
    } catch (e) {
      dispatch(displayError('An error occured:', e));
    }
  }
}

export function deleteCategoryLabelUser(item) {
  return async dispatch => {
    try {
      await dispatch(
        deleteEntity(
          item.unwrap(),
          'category_label_users',
          i18n.t('User removed from label value.')
        )
      );
      dispatch(selectEntity(null, 'category_label_users'));
      readCategoryLabelUsers();
    } catch (e) {
      dispatch(displayError(e));
    }
  };
}

export function selectCategoryLabelUser() {
  return async dispatch => {
    dispatch(selectEntity('add', 'category_label_users'));
  };
}

export function getBouncedEmail(userId, jwt) {
  let url = `/api/v1/users/${userId}/email_bounce`
  return async function(dispatch, getState) {
    const emailBounce = await ApiUtil.loadExternalData(url, jwt);
    emailBounce.endpoint = url;
    dispatch({ type: 'API_READ', payload: { data: emailBounce } });
  };
}

export function updateUserEmail(userId, jwt, emailBounce, updatedEmail) {
  let url = `/api/v1/users/${userId}/email_bounce?email=${updatedEmail}`;
  return async function (dispatch, getState) {
    if(emailBounce && emailBounce.removed === false && emailBounce.removal_count === 0) {
      await ApiUtil.requestExternalData(url, jwt, true, 'DELETE', { headers: { 'Accept': 'application/vnd.api+json' } });
      dispatch(displaySuccessMessage('User updated successfully!'));
    }
  };
}

export function getUserChangelog(userId, page) {
  return async function(dispatch, getState) {
    await clearAndRead(dispatch, getState, 'versions', `users/${userId}/changes?page[number]=${page}&page[size]=20`);
  };
}

export function findAdministrators(ids) {
  return readEntity('administrators', '', { id: ids.join() });
}

export function findChangeLogUsers(ids) {
  return readEntity('users', '', { id: ids });
}

export const loadUserAssignmentsByProgressId = (userId, progressId, contentServiceUrl, token) => {
  return async dispatch => {
    const url = JsonApiUtils.buildUrl(`${UrlUtil.PathHelper.ensureEndSlash(contentServiceUrl)}api/v1/users/${userId}/user_assignments.json`, {
      filters: { progress_id: progressId },
      includes: 'assignment.contents,progresses,content'
    });
    const data = await ApiUtil.loadExternalData(url, token, false);

    dispatch(readLocal(data));
  }
};

export function loadTrainingPeriodById(tpId) {
  return readEntityById(tpId, 'training_periods');
}

export function unassignOrReassignUserFromAssignment(userAssignmentId, action, contentServiceUrl, token) {
  return async dispatch => {
    const url = `${UrlUtil.PathHelper.ensureEndSlash(contentServiceUrl)}api/v1/user_assignments/${userAssignmentId}/${action}`;

    try {
      await ApiUtil.requestExternalData(url, token, false, 'PATCH');
      const message = action === 'unassign' ? i18n.t('User unassigned successfully') : i18n.t('User reassigned successfully');
      dispatch(displaySuccessMessage(message));
    } catch(err) {
      dispatch(displayErrorMessage(i18n.t('Error updating user assignment status')));
    }
  }
}
