import React from 'react';
import PropTypes from 'prop-types';
import { Wrapper, QuickActionsCard } from 'reactifi';
import i18n from 'lib/i18n';

const FinEdDashboard = props => {
  const { nextUrl, organization: { slug: orgSlug, advisor_resource_url: advisorResourceUrl }, renderAnnouncements } = props;

  const renderQuickActions = () => {
    // Add next org subdomain after url schema
    const nextOrgUrl = nextUrl.split('//').join(`//${orgSlug}.`);

    const quickActions = [
      {
        icon: 'paper-plane-top',
        title: i18n.t('Send Invitations'),
        description: i18n.t('Select a program and then find a playlist, article or other content to share with your contacts.'),
        url: `${nextOrgUrl}/cportal/programs`,
        urlText: i18n.t('Go to Programs')
      },
      {
        icon: 'circle-question',
        title: i18n.t('Need Additional Resources?'),
        description: i18n.t('Learn more about how to engage your community with this education.'),
        url: advisorResourceUrl,
        urlText: i18n.t('View Resources'),
        openInNewTab: true,
        hidden: !advisorResourceUrl
      },
      {
        icon: 'pen',
        title: i18n.t('Update Team Info'),
        description: i18n.t('Need to update your email address, team image, or other contact info?'),
        url: `/${orgSlug}/cportal/teams`,
        urlText: i18n.t('View Team Info')
      }
    ];

    return <QuickActionsCard quickActions={quickActions} />;
  }

  return (
    <Wrapper {...props} className="cards-wrapper narrow-cards-wrapper">
      <div className="cards-list">
        {renderQuickActions()}
        {renderAnnouncements()}
      </div>
    </Wrapper>
  );
};

FinEdDashboard.propTypes = {
  advisor_resource_url: PropTypes.string,
  nextUrl: PropTypes.string.isRequired,
  organization: PropTypes.shape({
    slug: PropTypes.string,
    advisor_resource_url: PropTypes.string
  }).isRequired,
  renderAnnouncements: PropTypes.func
};

export default FinEdDashboard;
