/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { AccessControlUtil, Header } from "reactifi";
import i18n from "lib/i18n";
import { sanitizeUrl } from '@braintree/sanitize-url';

const Nav = props => {
  const {
    deactivateUser,
    impersonate,
    isFitAdmin,
    isUserSecondaryManager,
    onDeleteUser,
    onEditUser,
    onManageLabels,
    onTempPassword,
    orgCapabilities,
    organizationId,
    onShowSamlAttributes,
    organizationTitle,
    permissions,
    reactivateUser,
    resetPassword,
    router,
    sendInvitation,
    unlimitedAccess,
    user,
    viewer,
    currentViewer,
    currentActiveUserId,
    userIsTeamManager
  } = props;

  const isCportalAdmin = viewer === 'customer_admin';

  const onBack = useCallback(() => {
    if (isFitAdmin) {
      return router.push(`${organizationId}/users`);
    } else {
      const { location: { query: { origin, program, subdomain } } } = props;
      if (origin && origin === 'enrollment' && program && subdomain) {
        // clean up url to account for other sources like mobile
        const url = document.referrer.split('/');
        window.location.href = sanitizeUrl(`${url[0]}//${url[2]}/cportal/programs#/${program}?origin=enrollments`);
      } else {
        return router.push('/');
      }
    }
  }, [isFitAdmin, organizationId]);

  const pageTitle = useMemo(() => {
    if (!user) {
      return i18n.t('User not found');
    } else {
      return `${user.first_name} ${user.last_name}${isFitAdmin ? ` - ${organizationTitle}` : ""}`;
    }
  }, [isFitAdmin, user, organizationTitle]);


  const button = useMemo(() => {
    if (!user) {
      return null;
    }

    // Hide Header Action buttons for SCORM Only admins
    if (
      isCportalAdmin &&
      currentViewer &&
      currentViewer.managed_business_lines.length === 1 &&
      currentViewer.managed_business_lines.includes('scorm')
    ) {
      return null;
    }

    const generateHeaderButton = (label, action, type, icon, iconFamily) => (
      {
        action,
        icon,
        iconFamily,
        data: {
          action: type,
          objectId: user.id,
          objectType: 'users'
        },
        id: `user-details-${type}`,
        label
      }
    );

    if (!unlimitedAccess && !userIsTeamManager) {
      if (AccessControlUtil.hasCapability("Capabilities::ModifyLearnersOutsideLob", orgCapabilities)) {
        return {
          ...generateHeaderButton(
            i18n.t('Edit User'),
            onEditUser,
            'edit'
          ),
          style: 'primary'
        }
      } else {
        return null;
      }
    }

    const canImpersonate = (
      isFitAdmin &&
      (!user['is_manager?'] || AccessControlUtil.hasPermission('impersonate_managers', permissions)) &&
      (user.rule_sets || []).some(ruleSet => ruleSet.name !== 'scorm')
    );
    const userIsManager = user?.['is_manager?'] || user?.['is_scorm_manager?'];
    const isBBIdEnabled = user?.['bbid_enabled?'];
    const canManageLabels = isFitAdmin ?
      AccessControlUtil.hasPermission('all_access', permissions) :
      !isUserSecondaryManager;
    const showResetAndInviteButtons = user.active && !isUserSecondaryManager && !!user.email && !isBBIdEnabled;
    const isUserUnder13 = user.under_13 && !!user.parent_email;
    const showSendPasswordReset = !isBBIdEnabled && (showResetAndInviteButtons || isUserUnder13);
    const showTempPasswordButton = !isBBIdEnabled && ((isFitAdmin && !userIsManager) || isCportalAdmin);
    const showSamlAttributesButton = !!user.sso_id && AccessControlUtil.hasPermission('view_sso_attributes', permissions);
    const showDeleteUserButton = isFitAdmin && AccessControlUtil.hasPermission('delete_users', permissions);

    let secondaryButton = {
      action: [],
      label: i18n.t('More Actions'),
      style: 'secondary',
      data: {
        action: 'open-more-actions'
      }
    };

    const primaryButton = {
      ...generateHeaderButton(
        i18n.t('Edit User'),
        [],
        'edit'
      ),
      style: 'primary'
    };

    // Secondary Manager (both PII and Education) have limited access and can
    // only send password reset and edit themselves.
    if (userIsTeamManager) {
      let limitedButton;

      // Check if viewed user is the same as current user. If so,
      // then allow editing the user and can send password reset.
      if (currentActiveUserId === user.id) {
        const resetPasswordBtn = generateHeaderButton(
          i18n.t('Send Password Reset'),
          resetPassword,
          'send-password-reset'
        );

        secondaryButton.action.push(generateHeaderButton(
          i18n.t('Edit User'),
          onEditUser,
          'edit-user'
        ));
        secondaryButton.action.push(resetPasswordBtn);

        limitedButton = secondaryButton;
      } else {
        const resetPasswordBtn = generateHeaderButton(
          i18n.t('Temporary Password'),
          onTempPassword,
          'temporary-password'
        );

        limitedButton = Object.assign(primaryButton, resetPasswordBtn);
      }

      return limitedButton
    }

    if (canImpersonate) {
      Object.assign(primaryButton, generateHeaderButton(
        i18n.t('Impersonate'),
        impersonate,
        'impersonate'
      ));
      secondaryButton.action.push(generateHeaderButton(
        i18n.t('Edit User'),
        onEditUser,
        'edit-user'
      ));
      if (canManageLabels) {
        secondaryButton.action.push(generateHeaderButton(
          i18n.t('Manage Labels'),
          onManageLabels,
          'manage-labels'
        ));
      }
    } else {
      primaryButton.action.push(generateHeaderButton(
        i18n.t('Edit User'),
        onEditUser,
        'edit'
      ));
      if (canManageLabels) {
        secondaryButton.action.push(generateHeaderButton(
          i18n.t('Manage Labels'),
          onManageLabels,
          'manage-labels'
        ));
      }
    }
    if (showResetAndInviteButtons) {
      secondaryButton.action.push(generateHeaderButton(
        i18n.t('Invite to Platform'),
        sendInvitation,
        'invite-to-platform'
      ));
    }
    if (showSendPasswordReset) {
      secondaryButton.action.push(generateHeaderButton(
        i18n.t('Send Password Reset'),
        resetPassword,
        'send-password-reset'
      ));
    }
    if (showTempPasswordButton) {
      secondaryButton.action.push(generateHeaderButton(
        i18n.t('Temporary Password'),
        onTempPassword,
        'temporary-password'
      ));
    }
    if (showSamlAttributesButton) {
      secondaryButton.action.push(generateHeaderButton(
        i18n.t('SAML Attributes'),
        onShowSamlAttributes,
        'saml-attributes'
      ));
    }
    /*
    TEMP DISABLE CHANGE LOG
    if (isFitAdmin) {
      secondaryButton.action.push(generateHeaderButton(
        i18n.t('View Change Log'),
        showChangeLog,
        'view-change-log'
      ));
    }
    */
    if (!isUserSecondaryManager) {
      if (user.active) {
        secondaryButton.action.push(generateHeaderButton(
          i18n.t('Deactivate User'),
          deactivateUser,
          'deactivate-user'
        ));
      } else {
        secondaryButton.action.push(generateHeaderButton(
          i18n.t('Reactivate User'),
          reactivateUser,
          'reactivate-user'
        ));
      }
    }
    if (showDeleteUserButton) {
      secondaryButton.action.push(generateHeaderButton(
        i18n.t('Delete User'),
        onDeleteUser,
        'delete-user'
      ));
    }

    if (secondaryButton.action.length === 1) {
      // if there is only one action, display action label directly
      secondaryButton.label = secondaryButton.action[0].label;
    }

    return [
      ...(secondaryButton.action.length ? [secondaryButton] : []),
      ...(!isUserSecondaryManager ? [primaryButton] : [])
    ];
  }, [isFitAdmin, permissions, user]);

  return (
    <Header
      back={onBack}
      button={user && (viewer === 'admin' || currentViewer) && button}
      icon="circle-user"
      title={pageTitle}
    />
  );
};

Nav.propTypes = {
  currentViewer: PropTypes.object,
  currentActiveUserId: PropTypes.string,
  deactivateUser: PropTypes.func,
  impersonate: PropTypes.func,
  isFitAdmin: PropTypes.bool,
  isUserSecondaryManager: PropTypes.bool,
  location: PropTypes.object,
  onEditUser: PropTypes.func,
  onDeleteUser: PropTypes.func,
  onManageLabels: PropTypes.func,
  onShowSamlAttributes: PropTypes.func,
  onTempPassword: PropTypes.func,
  orgCapabilities: PropTypes.array,
  organizationId: PropTypes.string,
  organizationTitle: PropTypes.string,
  permissions: PropTypes.array,
  reactivateUser: PropTypes.func,
  resetPassword: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  sendInvitation: PropTypes.func,
  unlimitedAccess: PropTypes.bool,
  user: PropTypes.shape({
    active: PropTypes.bool,
    editable_fields: PropTypes.bool,
    email: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    id: PropTypes.string,
    'is_manager?': PropTypes.bool,
    parent_email: PropTypes.string,
    rule_sets: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string
      })
    ),
    sso_id: PropTypes.string,
    under_13: PropTypes.bool
  }),
  userIsTeamManager: PropTypes.arrayOf(PropTypes.string),
  viewer: PropTypes.string
};

export default Nav;
