import React from "react";
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from "react-router";
import { syncHistoryWithStore } from "react-router-redux";

import createStore from "./store/importBatchesStore";

import ListContainer from "./containers/ListContainer";
import UploadContainer from "./containers/UploadContainer";
import UploadConfirmationContainer from '../common/Components/upload/UploadConfirmationContainer';
import PropTypes from "prop-types";

let index = (props, _railsContext) => {
  const store = createStore({});
  const history = syncHistoryWithStore(hashHistory, store);
  const right_text_button = "View My Uploads";
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route path="/" component={ListContainer} />
        <Route path="/confirmation" component={UploadConfirmationContainer} organization_slug={props.organization_slug} right_text_button={right_text_button} />
        <Route path="/:id" contentServiceUrl={props.content_service_url} jwt={props.jwt} component={UploadContainer} />
      </Router>
    </RootProvider>
  );
};

index.propTypes = {
  organization_slug: PropTypes.string,
  content_service_url: PropTypes.string,
  jwt: PropTypes.string
};

export default index;
