import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from 'reactifi';
import OrganizationUsersContainer from '../Organizations/containers/OrganizationUsersContainer';
import OrganizationUserFormContainer from '../Organizations/containers/OrganizationUserFormContainer';
import OrganizationUserDetailsContainer from '../Organizations/containers/OrganizationUserDetailsContainer';
import OrganizationUserAssignmentContainer from '../Organizations/containers/OrganizationUserAssignmentContainer';
import createStore from './store/usersStore';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

let index = (props, _railsContext) => {
  const initialState = {
    api: {
      activeFilters: {
        organization_id: props.organization_id,
        custom: {
          active: ["true"],
          business_lines: props.user_business_lines
        }
      }
    }
  }
  if (props.user_is_secondary_manager && !props.user_is_primary_manager) {
    initialState.api.activeFilters.custom.teams_as_member_ids = props.user_managed_teams;
  }
  const store = createStore(initialState);
  const history = syncHistoryWithStore(hashHistory, store);
  const userProps = {
    userManagedTeams: props.user_managed_teams,
    userTeams: props.user_teams,
    userIsPrimaryManager: props.user_is_primary_manager,
    userIsPiiRestrictedNextAdvisor: props.user_is_pii_restricted_next_advisor,
    userIsSecondaryManager: props.user_is_secondary_manager,
    userIsTeamManager: props.user_is_team_manager,
    userBusinessLines: props.user_business_lines,
    userManagedBusinessLines: props.user_managed_business_lines
  };
  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route
          path="/"
          component={OrganizationUsersContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          organization_id={props.organization_id}
          organization_slug={props.organization_slug}
          viewer={props.viewer}
          jwt={props.jwt}
          contentServiceUrl={props.content_service_url}
          certificateServiceUrl={props.certificate_service_url}
          isManager={props.is_manager}
          orgCapabilities={props.organization_capabilities}
          userRuleSetRoles={props.user_ruleset_roles}
          {...userProps}
        />
        <Route
          path="/:user_id"
          component={OrganizationUserFormContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          organization_id={props.organization_id}
        />
        <Route
          path="/:user_id/details"
          component={OrganizationUserDetailsContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          organization_id={props.organization_id}
          contentServiceUrl={props.content_service_url}
          certificateServiceUrl={props.certificate_service_url}
          viewer={props.viewer}
          jwt={props.jwt}
          singleUsePasswordHours={props.single_use_password_hours}
          orgCapabilities={props.organization_capabilities}
          currentActiveUserId={props.current_active_user_id}
          userIsPiiRestrictedNextAdvisor={props.user_is_pii_restricted_next_advisor}
          userIsTeamManager={props.user_is_team_manager}
          {...userProps}
        />
        <Route
          path="/:user_id/assignments/:user_assignment_id/progress/:progress_id"
          component={OrganizationUserAssignmentContainer}
          jwt={props.jwt}
          viewer={props.viewer}
          organization_id={props.organization_id}
          contentServiceUrl={props.content_service_url}
          permissions={props.permissions}
        />
      </Router>
    </RootProvider>
  );
};

index.propTypes = {
  content_service_url: PropTypes.string,
  is_manager: PropTypes.bool,
  jwt: PropTypes.string,
  organization_capabilities: PropTypes.arrayOf(PropTypes.string),
  organization_id: PropTypes.string,
  organization_slug: PropTypes.string,
  single_use_password_hours: PropTypes.number,
  user_business_lines: PropTypes.arrayOf(PropTypes.string),
  user_is_pii_restricted_next_advisor: PropTypes.bool,
  user_is_primary_manager: PropTypes.bool,
  user_is_secondary_manager: PropTypes.bool,
  user_managed_teams: PropTypes.arrayOf(PropTypes.string),
  user_teams: PropTypes.arrayOf(PropTypes.string),
  viewer: PropTypes.string
}

export default index;
