import {
  selectEntity,
  createEntity,
  readEntity,
  readEntityById
} from 'reactifi';
import i18n from "lib/i18n";

export { clearMessages, displayError, displayErrorMessage, displaySuccessMessage } from 'reactifi';

export function selectImportBatch(importBatchId) {
  return selectEntity(importBatchId, 'import_batches');
}

export function readImportBatches(importBatchId) {
  return readEntityById(importBatchId, 'import_batches');
}

export function findImportBatches(filters, page, sort) {
  return readEntity('import_batches', '', filters, sort && [sort], page);
}

export function createImportBatch(importBatch) {
  return async (dispatch) => {
    return dispatch(createEntity(importBatch, null, i18n.t("Import Batch created successfully!")))
  }
}
