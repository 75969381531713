import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'reactifi'

import i18n from 'lib/i18n';

import UploadStep from './UploadStep';

export default class UploadPreviewStep extends UploadStep {

  static propTypes = {
    currentEntity: PropTypes.object.isRequired,
    onConfirmation: PropTypes.func.isRequired,
    updateAndCloseAction: PropTypes.func.isRequired,
    selectAction: PropTypes.func.isRequired,
    preProcessEntity: PropTypes.func
  };

  goForward = async () => {
    let { updateAndCloseAction, currentEntity } = this.props;
    currentEntity = this.preProcessEntity(currentEntity);
    await updateAndCloseAction(currentEntity);
    this.props.onConfirmation();
  };

  preProcessEntity = entity => {
    if (typeof this.props.preProcessEntity === 'function') {
      entity = this.props.preProcessEntity(entity);
    }
    return entity;
  };

  renderBody = () => {
    const { currentEntity } = this.props;
    const { filename, media_name } = currentEntity;
    const { csv_preview } = currentEntity;
    let rows, headers, fields;
    if (csv_preview) {
      rows = csv_preview.rows;
      headers = csv_preview.headers;
      fields = headers.map(header => {
        return { name: header, title: header, width: '150px' };
      });
    }

    return (
      <div>
        <div className="f-500">
          {i18n.t('Previewing: %s', { postProcess: 'sprintf', sprintf: [media_name ? media_name : filename] })}
        </div>
        { csv_preview ?
          <Table
            wrapperClasses="upload-preview-table-container"
            data={rows}
            fields={fields} keyField="id"
          /> : null }
      </div>
    );
  };

}
