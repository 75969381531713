import React from "react";
import PropTypes from "prop-types";
import { Display50Pct50Pct, DisplayFlex240, Section } from "reactifi";
import { Tabs, Tab } from 'react-bootstrap';
import i18n from "lib/i18n";
import BouncedEmailBanner from "./BouncedEmailBanner";
import HeroColumn1 from "./HeroColumn1";
import HeroColumn2 from "./HeroColumn2";
import HeroColumn3 from "./HeroColumn3";

const Hero = props => {
  const multiBL = props.availableBusinessLines && props.availableBusinessLines.length > 1;
  return (
    <Section title={i18n.t('User Details')} noPadding={multiBL} >
      <BouncedEmailBanner {...props} />
      <DisplayFlex240>
        <Display50Pct50Pct>
          <HeroColumn1 {...props} />
          <HeroColumn2 {...props} />
        </Display50Pct50Pct>
        <HeroColumn3 {...props} />
      </DisplayFlex240>
      {multiBL && <nav
        className="nav-tabs"
        data-object-type="assignments"
        data-action="activities-business-line-tabs">
        <Tabs
          activeKey={props.activeTabKey}
          id="tabsId"
          onSelect={props.onTabChange}
          data-tabs={true}>
          {props.availableBusinessLines.map((bl, idx) => {
            return (
              <Tab
                eventKey={bl.id}
                key={bl.id}
                title={bl.label}
                data-object-type="assignments"
                data-action={`select-${bl.slug}-tab`} />
            )
          })}
        </Tabs>
      </nav>}
    </Section>
  );
};

Hero.propTypes = {
  availableBusinessLines: PropTypes.array,
  activeTabKey: PropTypes.any,
  onTabChange: PropTypes.func
}

export default Hero;
