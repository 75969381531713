import React, { useCallback, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { ConfirmationModal, ExplainerText, FloatingLabelFormField, FAIcon, InlineNotification } from "reactifi";
import i18n from "lib/i18n";

const BouncedEmailBanner = props => {
  const {
    currentUserId,
    emailBounces,
    emailBounces: {
      removal_count
    } = {},
    getBouncedEmail,
    isUserInManagedTeam,
    isUserSecondaryManager,
    jwt,
    selectCurrentUser,
    updateUserEmail,
    user: {
      bounced_email,
      email
    } = {}
  } = props;

  const [showBanner, setShowBanner] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [updatedEmail, setUpdatedEmail] = useState();
  useEffect(() => {
    setShowBanner(!!bounced_email);
  }, [bounced_email]);

  const disableEmailConfirm = useMemo(() => {
    if (removal_count < 1) {
      return false;
    } else {
      return email === updatedEmail;
    }
  }, [removal_count, updatedEmail, email]);

  const updateUsersEmail = useCallback(async () => {
    try {
      await getBouncedEmail(currentUserId, jwt)
    } finally {
      setShowModal(true);
      setUpdatedEmail(email);
    }
  }, [currentUserId, jwt, email]);

  const cancelEmailUpdate = useCallback(() => {
    setShowModal(false);
    setUpdatedEmail();
  }, []);

  const confirmEmailUpdate = useCallback(async () => {
    await updateUserEmail(currentUserId, jwt, emailBounces, updatedEmail);
    selectCurrentUser(currentUserId);
    cancelEmailUpdate();
  }, [currentUserId, emailBounces, jwt, updatedEmail]);

  const canShowCorrectionLink = useMemo(() => {
    return isUserSecondaryManager && !isUserInManagedTeam ? false : true;
  }, [isUserSecondaryManager, isUserInManagedTeam]);

  return (
    <>
      <InlineNotification type="error" show={showBanner} dataAttribute="bounced_email">
        <span>{i18n.t('This user\'s email is bouncing.')}</span>
        {canShowCorrectionLink && <a
          className="m-l-10" onClick={updateUsersEmail} data-action="correct-bouncing-email">
          <FAIcon icon="rotate-left"/>
          {i18n.t(' Correct user\'s email')}
        </a>}
      </InlineNotification>
      <ConfirmationModal
        show={showModal}
        title={i18n.t("Update Email")}
        onCancel={cancelEmailUpdate}
        onConfirm={confirmEmailUpdate}
        confirmButtonText={i18n.t('Confirm')}
        confirmButtonDisabled={disableEmailConfirm}
        canClickOff={true}
      >
        <ExplainerText>
          {removal_count > 0 ?
            i18n.t("Please update the user's email address if it is incorrect to remove the user from the bounce list.  This email address has been removed from the bounce list before and was readded, so if you believe it is now valid, please reach out to support.") :
            i18n.t("Please confirm the user's email address is correct or update it if it is incorrect. Clicking confirm will remove the user from the bounce list. Please note: this may only be done once and if it bounces again you will need to contact support to fix it.")}
        </ExplainerText>
        <FloatingLabelFormField
          caption={i18n.t("User Email")}
          dataValue={email}
          onChange={setUpdatedEmail} />
      </ConfirmationModal>
    </>
  );
};

BouncedEmailBanner.propTypes = {
  currentUserId: PropTypes.string,
  emailBounces: PropTypes.shape({
    removal_count: PropTypes.number
  }),
  getBouncedEmail: PropTypes.func,
  isUserInManagedTeam: PropTypes.func,
  isUserSecondaryManager: PropTypes.bool,
  jwt: PropTypes.string,
  selectCurrentUser: PropTypes.func,
  updateUserEmail: PropTypes.func,
  user: PropTypes.shape({
    bounced_email: PropTypes.bool,
    email: PropTypes.string
  })
};

export default BouncedEmailBanner;
