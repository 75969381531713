import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider, AccessControlUtil } from 'reactifi';
import TeamsContainer from './containers/TeamsContainer';
import TeamContainer from './containers/TeamContainer';
import createStore from './store/teamsStore';
import { Router, Route, hashHistory, Redirect } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

const TeamsIndex = (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);

  const tabList = [
    {
      path: "compliance",
      routeKey: 'corporate-compliance',
      title: 'Conduct & Culture',
      component: TeamsContainer
    },{
      path: "highered",
      routeKey: 'higher-education',
      title: 'Higher Education: Students',
      component: TeamsContainer
    },{
      path: "atwork",
      routeKey: 'at-work',
      title: 'Financial Education',
      component: TeamsContainer
    },{
      path: "facstaff",
      routeKey: 'faculty-staff',
      title: 'Faculty & Staff',
      component: TeamsContainer
    },{
      path: "events",
      routeKey: 'event-tracking',
      title: 'Event Tracking',
      component: TeamsContainer
    }]

  const filteredTabs = tabList.filter(tab => {
    return AccessControlUtil.hasBusinessLine(tab.routeKey, props.user_managed_business_lines);
  })

  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route
          component={TeamContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          organizationId={props.organization_id}
          orgCapabilities={props.organization_capabilities}
          path="/team/:team_id"
          userIsPiiRestrictedAdmin={props.user_is_pii_restricted_admin}
          userManagedBusinessLines={props.user_managed_business_lines}
          userRuleSetRoles={props.user_ruleset_roles}
          viewer={props.viewer}
          viewerId={props.viewer_id}
        />
        <Route
          component={TeamsContainer}
          googleMapsApiKey={_railsContext.googleMapsApiKey}
          organizationId={props.organization_id}
          orgCapabilities={props.organization_capabilities}
          path="/"
          userIsPiiRestrictedAdmin={props.user_is_pii_restricted_admin}
          userManagedBusinessLines={props.user_managed_business_lines}
          userRuleSetRoles={props.user_ruleset_roles}
          viewer={props.viewer}
          viewerId={props.viewer_id}
        >
          {filteredTabs.map((tab, index) => (<Route keys={index} title={tab.title} {...tab} key={tab.routeKey} />))}

          <Redirect from="*" to={filteredTabs[0].path} />
        </Route>
      </Router>
    </RootProvider>
  );
};

TeamsIndex.propTypes = {
  organization_capabilities: PropTypes.arrayOf(PropTypes.string),
  organization_id: PropTypes.number,
  user_is_pii_restricted_admin: PropTypes.bool,
  user_managed_business_lines: PropTypes.arrayOf(PropTypes.string),
  user_ruleset_roles: PropTypes.object,
  viewer: PropTypes.string,
  viewer_id:PropTypes.string
};

export default TeamsIndex;
