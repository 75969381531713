import React from "react";
import PropTypes from "prop-types";
import {
  ModalForm,
  FieldGroup,
  FloatingLabelFormField,
  FormField
} from "reactifi";
import i18n from "lib/i18n";

export default class DataIntegrationForm extends React.Component {
  static propTypes = {
    currentApplication: PropTypes.object,
    updateApplication: PropTypes.func.isRequired,
    createApplication: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  }

  updateApplication = () => {
    this.props.updateApplication(this.props.currentApplication);
  }

  createApplication = async (application) => {
    await this.props.createApplication(application);
  }

  render() {
    const {
      currentApplication,
      closeModal,
      errorMessage,
      successMessage
    } = this.props;

    return (
      <ModalForm
        data={currentApplication}
        errorMessage={errorMessage}
        successMessage={successMessage}
        title={i18n.t("Register New Application")}
        onCancel={closeModal}
        onClose={closeModal}
        addAction={this.createApplication}
        updateAction={this.updateApplication}
        saveAndAddButton={false}
      >
        <FieldGroup className="container-fluid">
          <FormField className="m-b-0 hidden" type="hidden" name="redirect_uri" />
          <div className="row m-b-10">
            <div className="col-12">
              <div>{i18n.t("To get started, enter the name of the application you'd like to register.")}</div>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-6">
              <FloatingLabelFormField
                name="name"
                caption={i18n.t("Application Name")}
                required={true}
              />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    );
  }
}