export default function teams(state = {}, action) {
  if(action.type === 'API_READ' && action.userType) {
    const newState = Object.assign({}, state, {
      userType: action.userType
    });
    return newState;
  } else {
    return state;
  }
}
