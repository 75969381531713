import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup } from 'reactifi/dist/es/components/Form/FieldGroup'
import { RegistrationFieldGroup } from 'reactifi/dist/es/components/Form/Registration/RegistrationFieldGroup'

export default class HEAdminRegistration extends React.Component{
  static propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    errorMessages: PropTypes.array,
    className: PropTypes.string
  };

  render(){
    let metadata = this.props.data.metadata;
    return (
      <FieldGroup {...this.props} className={this.props.className} >
        <RegistrationFieldGroup index={this.props.index} metadata={metadata} errorMessages={this.props.errorMessages} className="registration-component-modal-grid" />
      </FieldGroup>
    );
  }
}
