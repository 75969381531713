import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup,
  FloatingLabelFormField
} from 'reactifi';


export default class BrandingWelcomeTextWell extends Component {
  static propTypes = {
    errorMessage: PropTypes.string,
    currentOrganization: PropTypes.object
  }

  render () {
    return (
      <FieldGroup
        {...this.props}
        data={this.props.currentOrganization}
        data-action="welcome-text-card"
        key="welcome_text"
        className="branding-well-container"
      >
        <FieldGroup className="col-md-12 row">
          <h4>{i18n.t("Welcome Text")}</h4>
          <div className="col-12 p-l-0">{i18n.t("Welcome text appears on the login page, which may not be seen by all users.")}</div>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField
              name="welcome_text"
              caption={i18n.t('Welcome Text')}
              className="col-half-width"
              as="textarea"
            />
          </FieldGroup>
        </FieldGroup>
      </FieldGroup>
    )
  }
}
