import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FieldDisplay } from 'reactifi'
import { getCategoryByLabelId } from './CategoryFunctions';

export default class DisplayCategories extends Component {
  static propTypes = {
    categories: PropTypes.array.isRequired,
    categoryLabelUsers: PropTypes.array.isRequired
  };

  constructor(props) {
    super(props);
  }

  render() {
    const { categoryLabelUsers } = this.props;
    if (!categoryLabelUsers || !categoryLabelUsers.length) return null;

    return (
      <div>
        {categoryLabelUsers.map(categoryLabelUser => {
          let category = getCategoryByLabelId(this.props.categories, categoryLabelUser.category_label_id);

          return (
            <FieldDisplay key={category.value} label={category.label} value={category.value_label} />
          );
        })}
      </div>
    );
  }
}
