import React from 'react';
import PropTypes from 'prop-types';
import { RootProvider } from 'reactifi';
import AdministratorsContainer from './containers/AdministratorsContainer';
import createStore from './store/administratorsStore';
import { Router, Route, hashHistory } from 'react-router'
import { syncHistoryWithStore } from 'react-router-redux'

const AdministratorsIndex = (props, _railsContext) => {
  const store = createStore(props);
  const history = syncHistoryWithStore(hashHistory, store);
  return (
    <RootProvider store={store}>
      <Router history={history}>
        <Route path="/" component={AdministratorsContainer} viewer={props.viewer} />
      </Router>
    </RootProvider>
  );
};

AdministratorsIndex.propTypes = {
  viewer: PropTypes.string
};

export default AdministratorsIndex;
