import React, { Component } from "react";
import PropTypes from 'prop-types';
import i18n from "lib/i18n";
import { Button } from 'reactifi';

export default class WebhookDomainEmpty extends Component {
  static propTypes = {
    action: PropTypes.func.isRequired
  }

  render() {
    return (
      <div className="text-center">
        <p className="p-b-25 m-b-25">
          {i18n.t("Looks like you don't have any webhook domains created.")}
          <br />
          {i18n.t("The webhook domain is the starting point for webhooks. Enter the domain portion of your webhook URL. You will add the remainder of the URL path when you add a webhook for this domain. For example: integration.mydomain.com")}
        </p>
        <Button
          style="secondary"
          data-action="create-new-webhook"
          className="new-domain-btn"
          onClick={this.props.action}
          label={i18n.t('New Webhook Domain')}
          icon="plus"
        />
      </div>
    )
  }
}
