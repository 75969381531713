import { readEndpoint } from 'redux-json-api';
import { createEntity, selectEntity, deleteEntity, displayError, readEntity,
  pollUrl, displaySuccessMessage, displayErrorMessage } from 'reactifi';
import i18n from 'lib/i18n';

export { clearMessages, displayError, displayErrorMessage } from 'reactifi';

export function createDataExport(item) {
  return async (dispatch) => {
    try {
      const result = await dispatch(createEntity(item, null, i18n.t("Bulk data download created successfully.")));
      dispatch(selectDataExport(null));
      if (result && result.data) {
        dispatch(pollDataExport(result.data.id));
      }
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}

export function loadDataExports() {
  return readEntity('data_exports');
}

export function selectDataExport(itemId) {
  return selectEntity(itemId, 'data_exports');
}

export function deleteDataExport(item) {
  return async (dispatch) => {
    try {
      await dispatch(deleteEntity(item, 'data_exports', i18n.t("Bulk data download deleted successfully.")));
      dispatch(selectDataExport(null));
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}

function pollDataExport(dataExportId) {
  return async (dispatch) => {
    pollUrl({
      interval: 1000, 
      max: Number.POSITIVE_INFINITY,
      getData: async () => await dispatch(readEndpoint(`data_exports/${dataExportId}`)),
      checkComplete: (result) => !['pending', 'generating'].includes(result.data.attributes.status),
      onFinish: (result) => {
        switch (result.data.attributes.status) {
          case 'error':
            dispatch(displayErrorMessage(i18n.t("Bulk data could not be generated.")));
            break;
          case 'completed_without_download':
            dispatch(displayErrorMessage(i18n.t("Not enough data to generate report.")));
            break;
          case 'completed':
            dispatch(displaySuccessMessage(i18n.t("Bulk data is ready to download.")));
            break;
        }
      },
      onTimeout: () => {}
    });
  }
}
