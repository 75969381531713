import React from 'react';
import { createContainer } from 'reactifi';
import ImpactStories from '../components/ImpactStories';

const Container = createContainer({ entity: 'impact_stories' });

const ImpactStoriesContainer = props => {
  return (
    <Container {...props} WrapperComponent={React.Fragment}>
      <ImpactStories hideIfCollectionEmpty={true} />
    </Container>
  );
};

export default ImpactStoriesContainer;
