import React from 'react';
import { App } from 'reactifi';
import PropTypes from 'prop-types';
import RegistrationContainer from './containers/RegistrationContainer';
import createStore from './store/registrationStore';

const index = (props, _railsContext) => {
  return (
    <App createStore={() => createStore(props || {})}>
      <RegistrationContainer
        {..._railsContext}
        {...props}
        organizationId={props.organization.id}
        currentUserId={props.user.id}
        notifiUrl={props.notifi_url}
        bbIdEnabled={props.bbIdEnabled || props.bbIdEnabled === 'true'}
        bbIdRequired={props.bbIdRequired || props.bbIdRequired === 'true'}
        path="/"
      />
    </App>
  );
};

index.propTypes = {
  bbIdEnabled: PropTypes.bool,
  notifi_url: PropTypes.string,
  organization: PropTypes.object,
  user: PropTypes.object
};

export default index;
