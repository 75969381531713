import React from 'react';
import PropTypes from 'prop-types';
import { AccessControlUtil, Button, ConfirmationModal, FloatingLabelFormField, IconCategoryValue, SelectControl, Storage, StringUtil, Table } from 'reactifi';
import i18n from 'lib/i18n';

import UploadStep from '../../common/Components/upload/UploadStep';
import PlainTextTable from '../../common/Components/PlainTextTable';

const ALT_LOGIN_CAPABILITY = 'Capabilities::AlternativeLogin';
const SSO_CAPABILITY = 'Capabilities::SamlSso';
const RESTRICT_LEARNER_CAPABILITY = 'Capabilities::RestrictLearnerInformation';
const LOGIN_OPTIONS = [
  { name: 'email', label: i18n.t('Users Have Email') },
  { name: 'username', label: i18n.t('Users Do Not Have Email') }
];

export default class UploadInstructions extends UploadStep {
  static propTypes = {
    dispatch: PropTypes.func,
    displayErrorMessage: PropTypes.func,
    displaySuccessMessage: PropTypes.func,
    locations: PropTypes.array,
    organization_id: PropTypes.string,
    organization_slug: PropTypes.string,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    ruleSets: PropTypes.array,
    uploadBusinessLine: PropTypes.string,
    userIsPiiRestrictedAdmin: PropTypes.bool
  };

  constructor(props) {
    super(props);
    this.state = {
      showCopy: document.queryCommandSupported('copy'),
      batch_registrable_groupings: [],
      show_instructional_video: false,
      ruleSet: undefined
    };
  }

  componentDidMount() {
    if (this.props.ruleSets && this.props.uploadBusinessLine) {
      const selectedRuleSet = this.props.ruleSets.find(rs => rs.business_lines.includes(this.props.uploadBusinessLine));

      if (selectedRuleSet) {
        const url = `/api/data/batch_registrable_groupings.json?rule_set=${selectedRuleSet.name}`;
        const selectedRole = selectedRuleSet?.roles[selectedRuleSet.roles.length - 1]?.slug;
        Promise.all([
          this.loadBatchGroupings(url)
        ]).then(data => {
          this.setState({ batch_registrable_groupings: data[0].data });
        });

        this.updateData({ selectedRuleSet: selectedRuleSet.name, selectedRole });
      }
    }
  }

  async loadBatchGroupings(url) {
    const resp = await fetch(url, {
      method: "GET",
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Cache': 'no-cache'
      },
      credentials: 'include'
    });
    return await resp.json();
  }

  static getDerivedStateFromProps(props, state) {
    if (!state.selectedLocations && props.locations?.length) {
      const selectedLocations = props.locations.map(loc => loc.name).join('\n');
      return { selectedLocations };
    }
    return null;
  }

  onRuleSetSelected = (data) => {
    const selectedRole = data?.roles && data.roles[data.roles?.length - 1]?.slug;
    this.updateData({ selectedRuleSet: data?.name, selectedRole });
    this.setState({ ruleSet: data?.name, role: selectedRole });
  };

  onLocationSelect = (e) => {
    let selectedLocations = Array.from(e.target.querySelectorAll("option:checked"), e => e.value).join('\n');
    if (!selectedLocations) {
      selectedLocations = this.props.locations.map((loc) => loc.name).join('\n');
    }
    this.setState({ selectedLocations });
  }

  onConfirmPasswordPrompt = confirm => {
    this.setState({
      showConfirmPasswordPrompt: false
    });
    this.state.resolvePasswordPrompt(confirm);
  }

  goForward = () => {
    const { data: { selectedRuleSet }, displayErrorMessage } = this.props;
    const isValidLoginType = !this.isLoginTypeRequired() || !!this.state.loginType;

    if (!selectedRuleSet) {
      displayErrorMessage(i18n.t("Please select a user type."));
    } else if (!isValidLoginType) {
      displayErrorMessage(i18n.t("Please select a login type."));
      this.setState({ loginTypeError: true });
    } else if (!this.isUsernameLogin()) {
      return true;
    } else {
      this.setState({ showConfirmPasswordPrompt: true });
      const confirmPasswordPromise = new Promise(resolvePasswordPrompt => {
        this.setState({ resolvePasswordPrompt });
      });
      return confirmPasswordPromise;
    }

    return false;
  };

  get fields() {
    const batchRegistrableGroupings = this.state.batch_registrable_groupings;
    let setHeaders = new Set();
    const formatHeaders = [];
    batchRegistrableGroupings.map(item => {
      let headerName = item.attributes.header;
      if (!setHeaders.has(headerName)) {
        setHeaders.add(headerName);
        const obj = { name: `${headerName}`, title: i18n.t(`${StringUtil.Capitalize(headerName)}`), width: '200px' };
        formatHeaders.push(obj);
      }
    })
    return formatHeaders;
  }

  rows = () => {
    const groupings = this.state.batch_registrable_groupings;
    let newData = [];
    let i = 0;
    //eslint-disable-next-line no-loop-func
    while (groupings.some(grouping => grouping.attributes.values.length > i)) {
      let val = {};
      //eslint-disable-next-line no-loop-func
      groupings.forEach(grouping => {
        val[grouping.attributes.header] = grouping.attributes.values[i] ? grouping.attributes.values[i] : "";
      })
      newData.push(val);
      i++;
    }
    return newData;
  }

  renderLegendTable = () => {
    const headers = this.fields;
    const rows = this.rows();
    return (
      rows.length ?
        <Table
          wrapperClasses="upload-preview-table-container"
          data={rows}
          fields={headers}
          keyField="id"
        />
        : null
    )
  }

  renderLegendLink = () => {
    const { organization_id, data: { selectedRuleSet } } = this.props;
    const legendUrl = selectedRuleSet ? `/api/data/batch_registrable_groupings.csv?rule_set=${selectedRuleSet}` : '';
    const linkText = i18n.t('Download legend as .CSV');
    const handleDownload = () => Storage.setItem('suppressLoadingClass', true);

    return selectedRuleSet ? (
      <Button
        data={{
          action: 'download-legend-as-csv',
          objectType: 'organizations',
          objectId: organization_id
        }}
        download={true}
        href={legendUrl}
        onClick={handleDownload}
        icon="down-to-line"
        label={linkText}
        style="link"
      />
    ) : (
      <IconCategoryValue
        className="text-muted"
        icon="down-to-line"
        value={linkText}
      />
    );
  }

  isAltLoginEnabled = () => AccessControlUtil.hasCapability(ALT_LOGIN_CAPABILITY, this.props.orgCapabilities);

  isSsoEnabled = () => AccessControlUtil.hasCapability(SSO_CAPABILITY, this.props.orgCapabilities);

  isRestrictedLearnerOrg = () => AccessControlUtil.hasCapability(RESTRICT_LEARNER_CAPABILITY, this.props.orgCapabilities);

  isLoginTypeRequired = () => this.isAltLoginEnabled() && !this.props.userIsPiiRestrictedAdmin;

  isUsernameLogin = () => this.isAltLoginEnabled() && this.state.loginType === 'username';

  onLoginTypeSelected = (data) => this.setState({ loginType: data && data.name });

  getTemplateUrl = (isDisabled) => {
    if (isDisabled) return;
    const { data, organization_id, uploadType } = this.props;
    const { loginType } = this.state;

    if (!data || !data.selectedRuleSet) {
      return '';
    }

    return `/api/data/rule_sets/sample.csv?organization_id=${organization_id}&rule_set=${data.selectedRuleSet}&differentiated=true${loginType === 'username' ? '&alternative_login=true' : ''}${uploadType === 'update' ? '&update=true' : ''}`;
  }

  getSampleTableData = () => {
    const ruleSet = this.props.data.selectedRuleSet;
    const isHigherEd = ruleSet === 'he_learner';
    const isCc = ruleSet === 'cc_learner';

    const data = [
      { first_name: 'Ali', last_name: 'Ansari', username: 'aansari', email: 'aliansari@domain.com', password: 'domain123', student_id: '713245', employee_id: '3212', sso_id: 'aliansari', first_day_of_work: '12/12/18', position: 'Q/A', supervisor: 'Y', non_supervisor: 'N', undergrad: 'Y', graduate: 'N', non_traditional: 'N', greek: 'N', location: '', custom_category: '' },
      { first_name: 'Jane', last_name: 'Doe', username: '', email: 'janedoe@domain.com', password: '', student_id: '675934', employee_id: '34297', sso_id: 'janedoe', first_day_of_work: '12/12/19', position: 'Engineer', supervisor: 'N', non_supervisor: 'Y', undergrad: 'N', graduate: 'Y', non_traditional: 'N', greek: 'N', location: '', custom_category: '' },
      { first_name: 'Lola', last_name: 'Pecan', username: 'lpecan', email: 'lolapecan@domain.com', password: 'domain123', student_id: '34859', employee_id: '9579', sso_id: 'lolapecan', first_day_of_work: '10/12/14', position: 'HR', supervisor: 'N', non_supervisor: 'Y', undergrad: 'Y', graduate: 'N', non_traditional: 'N', greek: 'N', location: '', custom_category: '' }
    ];

    let fields = [
      { name: 'first_name', title: 'First Name*' },
      { name: 'last_name', title: 'Last Name*' }
    ];

    if (this.isUsernameLogin()) {
      let loginFields = [
        { name: 'username', title: 'Username*', tooltip: i18n.t('Set a unique username for each learner. You must also set a password for them so that they can log in.') },
        {
          name: 'password', title: 'Password*', tooltip: (
            <ul className="p-l-10">
              <li>{i18n.t('The minimum number of characters for password is 8.')}</li>
              <li>{i18n.t('You can set the same password for all your learners.')}</li>
              <li>{i18n.t('Learners are prompted to change their password upon login for the first time.')}</li>
              <li>{i18n.t('Updating a user\'s password will require them to change their password the next time they log in.')}</li>
              <li>{i18n.t('The password will expire in 30 days. You will need to update the password again if the learner has not logged in and set their own password within 30 days.')}</li>
            </ul>
          )
        }
      ]
      fields = fields.concat(loginFields);
    } else {
      fields = fields.concat({ name: 'email', title: 'Email*', tooltip: i18n.t('Email will be used for login, communication, and password recovery.') });
    }

    if (this.isSsoEnabled()) {
      fields = fields.concat({ name: 'sso_id', title: 'SSO ID (case sensitive and required for SSO login)' });
    }

    if (isHigherEd || isCc) {
      let heccFields = [
        ...isHigherEd ? [{ name: 'student_id', title: 'Student Id (optional)' }] : [],
        ...!isHigherEd ? [{ name: 'employee_id', title: 'Employee Id (optional)' }] : [],
        ...!isHigherEd ? [{ name: 'supervisor', title: '(Y/N) Supervisor' }] : [],
        ...!isHigherEd ? [{ name: 'non_supervisor', title: '(Y/N) Non-supervisor' }] : [],
        ...isHigherEd ? [{ name: 'undergrad', title: '(Y/N) Undergrad' }] : [],
        ...isHigherEd ? [{ name: 'graduate', title: '(Y/N) Graduate' }] : [],
        ...isHigherEd ? [{ name: 'non_traditional', title: '(Y/N) Non-Traditional' }] : [],
        ...isHigherEd ? [{ name: 'greek', title: '(Y/N) Greek' }] : [],
        { name: 'location', title: 'Location*', tooltip: i18n.t('Copy and paste the exact location from the legend.') }
      ];
      fields = fields.concat(heccFields);
    }

    fields = fields.concat({ name: 'custom_category', title: '[Custom Category]' });

    return {
      data,
      fields
    };
  }

  renderUserSampleTable = () => {
    const { data, fields } = this.getSampleTableData();

    return (
      <PlainTextTable
        data={data}
        fields={fields}
        infoText={i18n.t('Example of a filled in User List Template. Check info for details.')}
      />
    );
  }

  getStep1Description = () => {
    const opening = 'Select a';
    const trailing = 'in order to download the appropiate template.';
    const loginOptionString = this.isLoginTypeRequired() ? 'and login option ' : '';

    if (!this.props.ruleSets || this.props.ruleSets.length <= 1) {
      return this.isAltLoginEnabled() ? i18n.t(`${opening} login option ${trailing}`) : null;
    }

    return i18n.t(`${opening} user type ${loginOptionString}${trailing}`);
  }

  showRuleSetSelector = () => {
    const { ruleSets } = this.props;
    const selectedLOBRuleSets = ruleSets && ruleSets.filter(rs => rs.business_lines && rs.business_lines.includes(this.props.uploadBusinessLine));

    return selectedLOBRuleSets && selectedLOBRuleSets.length > 1;
  }

  renderBody = () => {
    const { data: { selectedRuleSet }, organization_id, ruleSets, uploadBusinessLine } = this.props;
    const isDisabled = (this.showRuleSetSelector() && !this.state.ruleSet) || (this.isLoginTypeRequired() && !this.state.loginType);
    const templateUrl = this.getTemplateUrl(isDisabled);
    let headerNumber = 1;

    return (
      <div>
        <div className="row">
          <div className="col-sm-12 instruction-text">
            {i18n.t('Add new users by uploading a spreadsheet of user data.')}
          </div>
        </div>
        <div className="grey-box-header">{headerNumber++}. {i18n.t('Download the User List Template')}</div>
        <div>{this.getStep1Description()}</div>
        {this.showRuleSetSelector() && (
          <div className="row">
            <div className="col-sm-6">
              <FloatingLabelFormField
                name="registration_rule_sets"
                caption={i18n.t('Select User Type')}
                required={true}
                dataValue={selectedRuleSet}
                errorMessage={this.state.ruleSetError}
                className="col-half-width m-b-0">
                <SelectControl
                  multi={false}
                  onChange={this.onRuleSetSelected}
                  options={ruleSets.filter(rs => rs.business_lines && rs.business_lines.includes(uploadBusinessLine) && !!rs.pii_restricted === this.isRestrictedLearnerOrg())}
                  valueKey="name"
                  labelKey="label"
                />
              </FloatingLabelFormField>
            </div>
          </div>
        )}
        {this.isLoginTypeRequired() && (
          <div className="row">
            <div className="col-sm-6">
              <FloatingLabelFormField
                name="login_option"
                caption={i18n.t('Select Whether Users Have Email or Not?')}
                required={true}
                className="col-half-width m-b-0"
                runValidation={this.state.loginTypeError}
              >
                <SelectControl
                  multi={false}
                  onChange={this.onLoginTypeSelected}
                  options={LOGIN_OPTIONS}
                  valueKey="name"
                  labelKey="label"
                />
              </FloatingLabelFormField>
            </div>
          </div>
        )}
        <div className="row">
          <div className="col-sm-6 m-t-10">
            <Button
              data={{
                action: 'download-template',
                objectType: 'organizations',
                objectId: organization_id
              }}
              disabled={isDisabled}
              download={true}
              href={templateUrl}
              target="_blank"
              icon="down-to-line"
              label={i18n.t('Download Template')}
              style="secondary"
            />
          </div>
        </div>
        {this.rows().length > 0 && (
          <>
            <div className="grey-box-header">{headerNumber++}. {i18n.t('Download Legend')}</div>
            <div>{i18n.t('Use the legend below to copy and paste exact values under the appropiate columns when inserting data into template.')}</div>
            <div className="row">
              <div className="col-12 m-t-10">
                {this.renderLegendLink()}
              </div>
            </div>
            {this.renderLegendTable()}
          </>
        )}
        <div className="grey-box-header">{headerNumber++}. {i18n.t('Insert User Data into the User List Template')}</div>
        <div>{i18n.t('Following the example below paste your users information into the appropiate columns in the User List Template.')}</div>
        {this.renderUserSampleTable()}
        <div className="grey-box-header">{headerNumber++}. {i18n.t('Save file as .CSV')}</div>
        <ConfirmationModal
          canClickOff={true}
          onConfirm={() => this.onConfirmPasswordPrompt(true)}
          onCancel={() => this.onConfirmPasswordPrompt(false)}
          show={this.state.showConfirmPasswordPrompt}
          title={i18n.t('Please Note')}
          cancelButtonText={i18n.t('Cancel')}
          confirmButtonText={i18n.t('Continue')}>
          {i18n.t('Passwords will expire in 180 days. You will need to update passwords for any learner that has not logged in and set their own password within 180 days.')}
        </ConfirmationModal>
      </div>
    );
  }
}
