import i18n from 'lib/i18n';

/**
 * These should be immutable and the IDs should correspond to
 * the ones being returned from /api/data/organization_types
 */
const ORG_TYPES = Object.freeze([
  { id: 1, label: i18n.t('District') },
  { id: 2, label: i18n.t('School') },
  { id: 3, label: i18n.t('Other') }
]);

export default ORG_TYPES;
