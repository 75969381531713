import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildGenericProps, JsonApiDecorator, ProgressSpinner } from 'reactifi';
import i18n from 'lib/i18n';

import { orgActionCreators, orgUsersActionCreators } from '../actions';
import { selectRegistrationBatch, createRegistrationBatch, updateRegistrationBatch, updateRegistrationAndClose } from '../../UploadsApp/actions/registrationBatchActions';

import UploadWizard from '../../common/Components/upload/UploadWizard';

import OrgUploadRolesInstructionsStep from '../components/OrgUploadRolesInstructionsStep';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, 'registration_batches');

  props.organizationId = ownProps.routeParams.id;

  let apiStore = new JsonApiDecorator(state.api);
  props.ruleSets = apiStore.rule_sets ? apiStore.rule_sets.where(rs => rs.is_learner) : [];

  return props;
}

class OrganizationUploadRolesContainer extends Component {

  static propTypes = {
    currentEntity: PropTypes.shape({
      name: PropTypes.string
    }),
    dispatch: PropTypes.func.isRequired,
    organizationId: PropTypes.string,
    rosterId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    router: PropTypes.shape({
      push: PropTypes.func,
      replace: PropTypes.func
    }),
    ruleSets: PropTypes.array
  }

  constructor(props) {
    super(props);

    this.actions = bindActionCreators({ 
      ...orgActionCreators, 
      ...orgUsersActionCreators,
      selectRegistrationBatch,
      createRegistrationBatch,
      updateRegistrationBatch,
      updateRegistrationAndClose
    }, this.props.dispatch);

    this.state = {
      wizardData: {}
    };
  }

  componentDidMount() {
    this.actions.selectRegistrationBatch(null);
    this.actions.loadRuleSets(this.props.organizationId);
    this.actions.selectRegistrationBatch('add');
  }

  onWizardDataChange = data => {
    this.setState({ wizardData: data });
  };

  renderFileIntro = () => {
    return (
      <div>
        <div>{i18n.t('File format must be .csv')}</div>
      </div>
    );
  };

  renderPreviewIntro = () => {
    const { wizardData } = this.state;
    const oldRole = this.props.ruleSets.find(rs => rs.name === wizardData.oldRole);
    const newRole = this.props.ruleSets.find(rs => rs.name === wizardData.newRole);

    return (
      <div>
        {i18n.t(
          'Please review the preview below - only the first five rows are visible.  ' +
          'If the information looks correct click Update Users below.  ' +
          'If something looks wrong, go back and make changes to your file before finishing this step.'
        )}
        <div className="m-t-10">
          <strong>{i18n.t('Removing Role')}:</strong> {oldRole && oldRole.label}
        </div>
        <div className="m-b-10">
          <strong>{i18n.t('Adding Role')}:</strong> {newRole && newRole.label}
        </div>
      </div>
    );
  };

  preProcessData = data => {
    data.organization_id = this.props.organizationId;
    data.registration_rule_sets = { [this.state.wizardData.newRole]: 'default' };
    data.unregistration_rule_sets = { [this.state.wizardData.oldRole]: 'default' };
    if (!data.id) {
      data.differentiated = true;
      data.operation = 'create';
    }

    return data;
  };

  preProcessEntity = entity => {
    entity.organization_id = this.props.organizationId;
    entity.registration_rule_sets = { [this.state.wizardData.newRole]: 'default' };
    entity.unregistration_rule_sets = { [this.state.wizardData.oldRole]: 'default' };
    entity.import = true;
    entity.operation = 'update';
    return entity;
  };

  onUploadConfirmation = () => {
    this.props.router.replace(`/${this.props.organizationId}/upload_roles/confirmation`);
  }

  backButton = () => {
    this.props.router.push(`/${this.props.organizationId}/users`);
  }

  render() {
    if (!this.props.currentEntity || !this.props.ruleSets) {
      return <ProgressSpinner />;
    }

    return (
      <UploadWizard
        {...this.props}
        {...this.actions}
        title={i18n.t('Update User Roles')}
        finalButtonLabel={i18n.t('Update Users')}
        imageClassName="upload-existing-users-image"
        selectAction={this.actions.selectRegistrationBatch}
        createAction={this.actions.createRegistrationBatch}
        updateAction={this.actions.updateRegistrationBatch}
        updateAndCloseAction={this.actions.updateRegistrationAndClose}
        onDataChange={this.onWizardDataChange}
        backButton={this.backButton}
        instructionsStep={{
          title: i18n.t('Prepare to Update User Roles'),
          component: OrgUploadRolesInstructionsStep,
          props: {
            organizationId: this.props.organizationId,
            ruleSets: this.props.ruleSets
          }
        }}
        fileStep={{
          title: i18n.t('Upload User List'),
          intro: this.renderFileIntro(),
          acceptTypes: ['.csv', '.xsl', 'application/vnd.ms-excel'],
          preProcessData: this.preProcessData
        }}
        previewStep={{
          title: i18n.t('Confirm User Update'),
          intro: this.renderPreviewIntro(),
          preProcessEntity: this.preProcessEntity
        }}
        replaceRouteOnEntitySelection={false}
        onConfirmation={this.onUploadConfirmation}
      />
    );
  }
}

export default connect(mapStateToProps)(OrganizationUploadRolesContainer);
