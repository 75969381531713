export const NOT_EDITABLE_ATTRIBUTES = Object.freeze(['user_type']);

export const SAML_ATTRIBUTES = Object.freeze([
  { label: "First Name", value: "first_name" },
  { label: "Last Name", value: "last_name" },
  { label: "Email", value: "email" },
  { label: "User Type", value: "user_type" },
  { label: "Role", value: "role" },
  { label: "Location Name", value: "location" }
]);
