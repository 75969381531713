import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { buildGenericProps, JsonApiDecorator, ConfirmationModal, Page, ListView } from "reactifi";
import i18n from "lib/i18n";

import * as embedContentDomainsActionCreators from "../actions/embedContentDomainsActionCreators";

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, 'embed_content_domains', {});
  props.organizationId = ownProps.route.organizationId;
  props.successMessage = state.api.successMessage;

  const apiStore = new JsonApiDecorator(state.api);
  props.embedContentDomains = apiStore.embed_content_domains ? apiStore.embed_content_domains.all() : [];
  props.metaData = { total_count: props.embedContentDomains.length };

  if (apiStore.organizations) {
    props.organization = apiStore.organizations.find(props.organizationId);
  }

  return props;
}

class EmbedContentDomainsContainer extends React.Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    clearMessages: PropTypes.func,
    organizationId: PropTypes.string,
    organization: PropTypes.object,
    embedContentDomains: PropTypes.array,
    item: PropTypes.any,
    itemId: PropTypes.any,
    isLoadingEmbedContentDomains: PropTypes.bool,
    router: PropTypes.any,
    errorMessage: PropTypes.string
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(
      embedContentDomainsActionCreators,
      this.props.dispatch
    );

    this.state = {
      showModal: false,
      tempItem: {}
    };
  }

  componentDidMount() {
    this.actions.findOrganization(this.props.organizationId);
  }

  deleteAction = (event, item) => {
    this.setState({ showModal: true, tempItem: item });
  }

  deleteItem = async () => {
    await this.actions.deleteItem(this.state.tempItem);
    this.setState({ showModal: false, tempItem: {} });
    this.actions.loadItems();
  }

  addContentDomain = () => {
    this.props.router.push('/add');
  }

  get fields() {
    return [
      { name: "domain", title: i18n.t("Domain"), width: '50%', canSort: false },
      { name: "allow_subdomains", title: i18n.t("Allow Subdomains"), width: '50%', canSort: false, formatter: cell => cell ? i18n.t('Yes') : i18n.t('No') }
    ];
  }

  get actionsConfig() {
    return [
      { type: 'edit', actionType: 'link', action: row => `/${row.id}` },
      { type: 'delete', actionType: 'callToAction', action: this.deleteAction }
    ];
  }

  get actionButton() {
    return {
      label: i18n.t("New Domain"),
      "data-action": "new-domain",
      action: this.addContentDomain
    };
  }

  render() {
    return (
      <Page
        title={i18n.t('Embed Content')}
        pageType="parent"
        contentType="fullwidth"
        actionButton={this.actionButton}
        successMessage={this.props.successMessage}
        errorMessage={this.props.errorMessage}
        clearMessages={this.actions.clearMessages}
        usePageWrapper={true}
      >
        <ListView
          {...this.props}
          data={this.props.embedContentDomains}
          loadAction={this.actions.loadItems}
          isLoadingData={this.props.isLoadingEmbedContentDomains || !this.props.organization}
          fields={this.fields}
          actions={this.actionsConfig}
          totalCount={this.props.embedContentDomains.length}
          fullPage={false}
          hasPagination={false}
          noRecordsMessage="No Embed Content Domains have been found."
        />

        <ConfirmationModal
          title={i18n.t("Delete Domain")}
          show={this.state.showModal}
          onConfirm={this.deleteItem}
          onCancel={() => this.setState({ showModal: false })}>
          {i18n.t("Are you sure you want to delete this embed content domain?")}
        </ConfirmationModal>
      </Page>
    );
  }
}

export default connect(mapStateToProps)(EmbedContentDomainsContainer);
