import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import i18n from 'lib/i18n';
import { Header, Section, WizardCards, useContainerProps, JsonApiDecorator } from "reactifi";
import { useEffectDeep } from 'reactifi/dist/es/hooks/useEffectDeep';
import { SETUP_STEPS, TASKS, PATHNAME } from '../constants/index';
import { sanitizeUrl } from '@braintree/sanitize-url';

const OnboardingChecklist = props => {
  props = useContainerProps(props);
  const {
    checklistId,
    checklists,
    currentUser,
    loadUsers,
    loadChecklist,
    updateChecklistItem,
    updateChecklist,
    users,
    contentServiceUrl,
    notifiUrl
  } = props;

  const [setupSteps, setSetupSteps] = useState([]);
  const [checklist, setChecklist] = useState(null);
  const [checklistItems, setChecklistItems] = useState([]);
  const firstIncompleteIndex = setupSteps.findIndex(step => step.tasks.some(task => task.status !== 'complete'));
  const initialStep = firstIncompleteIndex === -1 ? setupSteps.length - 1  : firstIncompleteIndex;

  useEffect(() => {
    loadChecklist(checklistId);
    updateTasksWithAppUrls();
  }, [])

  useEffectDeep(() => {
    if(checklists.length !== 0 && checklists[0].checklist_items.length) {
      setChecklist(checklists[0]);
      setChecklistItems(checklists[0].checklist_items);
    }
  }, [checklists])

  useEffectDeep(() => {
    const userIds = [...new Set(checklistItems
      .filter(item => item.completed_by_id)
      .map(item => item.completed_by_id))];

    if(userIds.length) {
      loadUsers(userIds);
    }

    populateSetupSteps();
  }, [checklistItems])

  useEffectDeep(() => {
    populateSetupSteps();
  },  [users])

  const updateTasksWithAppUrls = () => {
    TASKS.map(task => {
      switch(task.slug) {
        case 'content_library':
          task.goToUrl = `${contentServiceUrl}${PATHNAME}/content_library${window.location.search}`;
          break;

        case 'create_assignment':
        case 'assignment_status':
          task.goToUrl = `${contentServiceUrl}${PATHNAME}/assignments${window.location.search}`;
          break;

        case 'manage_communications':
          task.goToUrl = `${notifiUrl}${PATHNAME}/templates${window.location.search}`
          break;
      }
    });
  }

  const populateSetupSteps = () => {
    SETUP_STEPS.map(step => {
      step.tasks = [];
      step.taskKeys.map((taskKey) => {

        const checklistItem = checklistItems.find(item => (item.slug === taskKey));
        const stepTask = TASKS.find(task => (task.slug === taskKey));
        if(!checklistItem || !stepTask) {
          return;
        }
        if(checklistItem.completed_by_id) {
          stepTask.user = users.find(user => user.id === checklistItem.completed_by_id)?.full_name;
        }
        step.tasks.push(Object.assign({}, stepTask, checklistItem));
      });
    });

    setSetupSteps(SETUP_STEPS.filter(step => (step.tasks.length)));
  }

  const onTaskComplete = (task, isComplete) => {
    task.complete = isComplete;
    const checklistItemObj = new JsonApiDecorator({}).newObject('checklist_items');
    Object.assign(checklistItemObj, task);
    updateChecklistItem(checklistItemObj, checklistId);
  }

  const onSetupComplete = () => {
    const checklistObj = new JsonApiDecorator({}).newObject('checklists');
    checklistObj.complete = true;
    Object.assign(checklistObj, checklist);
    updateChecklist(checklistObj);
    let homeUrl = window.location.pathname.split('/');
    homeUrl.pop();
    window.location.href = sanitizeUrl(`${window.location.origin}${homeUrl.join('/')}`);
  }

  return (
    <>
      <Header title={`${i18n.t('Welcome to Foundry')}, ${currentUser.first_name}!`} data={{ objectType: 'user', objectId: currentUser.id }} />
      <Section type="cards">
        { setupSteps?.length && <WizardCards
          steps={setupSteps}
          saveBtnLabel={i18n.t('Complete Setup')}
          stepCardProps={{
            size: 'wide',
            titleSize: 18,
            titleCentered: true
          }}
          taskCardProps={{ titleSize: 16 }}
          onCompleteTask={(task) => onTaskComplete(task, true)}
          onUndoTask={(task)=> onTaskComplete(task, false)}
          onFinish={onSetupComplete}
          initialStep={initialStep}
        /> }
      </Section>
    </>
  )
}

OnboardingChecklist.propTypes = {
  checklistId: PropTypes.string,
  checklists: PropTypes.object,
  contentServiceUrl: PropTypes.string,
  currentUser: PropTypes.object,
  notifiUrl: PropTypes.string,
  loadChecklist: PropTypes.func,
  loadUsers: PropTypes.func,
  users: PropTypes.array,
  updateChecklistItem: PropTypes.func,
  updateChecklist: PropTypes.func
}

export default OnboardingChecklist
