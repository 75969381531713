import PropTypes from 'prop-types';
import React from 'react';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';

import createStore from './store/webhookStore';

import WebhooksContainer from './containers/WebhooksContainer';

let index = (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);

  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        <Route path="/" component={WebhooksContainer} webhook_event_types={props.webhook_event_types} />
      </Router>
    </RootProvider>
  );
}

index.propTypes = {
  webhook_event_types: PropTypes.array
}

export default index;
