import { selectEntity, updateEntity, createEntity, readEntity, readEntityById,  deleteEntity } from 'reactifi';
import i18n from 'lib/i18n'
export { clearMessages, displaySuccessMessage } from 'reactifi';

export function selectAdministrator(administratorId) {
  return selectEntity(administratorId, 'administrators');
}

export function findAdministrator(administratorId) {
  return readEntityById(administratorId, 'administrators');
}

export function readAdministrators(includes, filters, sort, page) {
  if (!sort) {
    sort = { value: "first_name", direction: "asc" };
  }
  return readEntity('administrators', 'administrator_groups', filters, sort && [sort], page);
}

export function readAdministratorGroups(includes, filters, sort, page = { size: 50 }) {
  return readEntity('administrator_groups', includes, filters, sort, page);
}

export function updateAdministrator(administrator) {
  return updateEntity(administrator, (dispatch) => {
    dispatch(selectEntity(null, 'administrators'));
  }, "Administrator updated successfully");
}

export function deleteAdministrator(administrator) {
  return deleteEntity(administrator, 'administrators', i18n.t('Administrator deleted successfully.'))
}

export function createAdministrator(administrator, addAnother) {
  return createEntity(administrator, (dispatch) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null));
    if (addAnother) {
      dispatch(selectEntity('add', 'administrators'));
    }
  }, "Administrator created successfully");
}
