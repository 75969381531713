import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Util } from "@everfi/reactifi"
import i18n from 'lib/i18n';
import { GenericModal } from 'reactifi/dist/es/components/GenericModal';
import { ResponsiveDataList } from 'reactifi/dist/es/components/Table/ResponsiveDataList';

export default class OrgUserSamlAttributesModal extends Component {
  static propTypes = {
    show: PropTypes.bool.isRequired,
    samlAttributes: PropTypes.arrayOf(PropTypes.shape({
      attribute_name: PropTypes.string,
      attribute_value: PropTypes.string,
      updated_at: PropTypes.string
    })).isRequired,
    onCancel: PropTypes.func.isRequired
  }

  static defaultProps = {
    samlAttributes: []
  };

  get fields() {
    return [
      { name: 'attribute_name', title: i18n.t('Attribute Name') },
      { name: 'attribute_value', title: i18n.t('Attribute Value') },
      { name: 'updated_at', title: i18n.t('Updated'), formatter: cell => Util.format(cell, 'P') }
    ];
  }

  render() {
    return (
      <GenericModal
        id="saml-attributes-modal"
        show={this.props.show}
        canClose={true}
        title={i18n.t('SAML Attributes in Most Recent Single Sign-on')}
        onClose={this.props.onCancel}
      >
        <ResponsiveDataList
          data={this.props.samlAttributes}
          fields={this.fields}
          keyField="id"
        />
      </GenericModal>
    );
  }
}
