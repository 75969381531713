import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildGenericProps, JsonApiDecorator, ProgressSpinner, UrlUtil } from 'reactifi';
import i18n from 'lib/i18n';

import * as registrationBatchActions from '../actions/registrationBatchActions';

import UploadWizard from '../../common/Components/upload/UploadWizard';
import UploadInstructions from '../components/UploadInstructions';
import UploadFile from '../components/UploadFile';

function mapStateToProps(state, ownProps) {

  const props = buildGenericProps(state, 'registration_batches');

  props.organization_id = ownProps.route.organization_id;
  props.organization_slug = ownProps.route.organization_slug;
  props.registrationBatchId = ownProps.routeParams.id;
  props.uploadType = ownProps.location.query.type;
  props.orgCapabilities = ownProps.route.organization_capabilities;
  props.userIsPiiRestrictedAdmin = ownProps.route.user_is_pii_restricted_admin;

  let apiStore = new JsonApiDecorator(state.api);
  if (apiStore.rule_sets) {
    props.ruleSets = apiStore.rule_sets.where(rs => rs.uploadable);
    props.managerRuleSets = apiStore.rule_sets.where(rs => rs.is_manager);
  }

  props.locations = apiStore.locations ? apiStore.locations.all() : [];
  props.businessLines = apiStore.business_lines ? apiStore.business_lines.all() : [];
  props.uploadBusinessLine = UrlUtil.getCurrentBusinessLine(props.managerRuleSets) ?? ownProps.location.query.business_line;

  if (props.currentEntity) {
    mapRuleSets(props);
    if (!props.currentEntity.id){
      props.currentEntity.editable_fields = ownProps.route.editable_fields_default;
      props.currentEntity.user = { editable_fields: ownProps.route.editable_fields_default };
      props.currentEntity.organization_id = props.organization_id;
    } else {
      if (props.currentEntity.operation) {
        props.uploadType = props.currentEntity.operation;
      }
    }
  }

  props.errorMessage = state.api.errorMessage;

  return props;
}

function mapRuleSets(props) {
  if (props.currentEntity.registration_rule_sets) {
    let key = Object.keys(props.currentEntity.registration_rule_sets)[0];
    if (props.ruleSets) {
      props.currentEntity.registration_rule_sets.label = props.ruleSets.reduce((set, rule, i) => {
        return rule.name === key ? props.ruleSets[i].label : set;
      });
      props.currentEntity.registration_rule_sets.role = props.currentEntity.registration_rule_sets[key];
    }
  }
}

class UploadContainer extends Component {

  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    currentEntity: PropTypes.shape({
      registration_rule_sets: PropTypes.array
    }),
    organization_id: PropTypes.string,
    organization_slug: PropTypes.string,
    locations: PropTypes.array,
    registrationBatchId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    ruleSets: PropTypes.array,
    uploadType: PropTypes.string,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    businessLines: PropTypes.array,
    uploadBusinessLine: PropTypes.string,
    userIsPiiRestrictedAdmin: PropTypes.bool
  }

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(registrationBatchActions, this.props.dispatch);

    this.state = {
      wizardData: {}
    };
  }

  componentDidMount() {
    this.actions.selectRegistrationBatch(null);
    this.actions.loadRuleSets();
    this.actions.selectRegistrationBatch(this.props.registrationBatchId);
    this.actions.readLocations();
    this.actions.loadBusinessLines();
  }

  renderPreviewIntro = () => {
    if (this.props.uploadType === 'update') {
      return (
        <div>
          {i18n.t('Please review the preview window below. If the information looks correct,  ' +
            'click Update Users. If not, go back and make any changes to your  ' +
            'file before confirming the upload.')}
        </div>
      );
    }

    return (
      <div>
        {i18n.t('Please review the preview below, only the first five rows are visible.  ' +
          'If the information looks correct click Import Users.  ' +
          'Otherwise, click Back and make any changes to your file before uploading again.')}
      </div>
    );
  };

  preProcessEntity = entity => {
    const { wizardData } = this.state;
    entity.import = true;
    if (wizardData && wizardData.selectedRuleSet && wizardData.selectedRole) {
      entity.registration_rule_sets = { [wizardData.selectedRuleSet]: wizardData.selectedRole };
    }
    ['label', 'role'].forEach(e => delete entity.registration_rule_sets[e]);

    return entity;
  };

  onWizardDataChange = data => {
    this.setState({ wizardData: data });
  };

  render() {
    const { uploadType } = this.props;
    const businessLine = this.props.businessLines && this.props.businessLines.find(bl => bl.slug === this.props.uploadBusinessLine);
    const pageTitle = uploadType === 'update' ? i18n.t('Update Existing Users') : i18n.t('Upload Users');

    if (this.props.currentEntity && this.props.ruleSets) {
      let selectedRuleSet;
      if (this.props.currentEntity.registration_rule_sets) {
        selectedRuleSet = Object.keys(this.props.currentEntity.registration_rule_sets)[0];
      }
      return (
        <UploadWizard
          {...this.props}
          {...this.actions}
          title={`${pageTitle} - ${businessLine && businessLine.label}`}
          finalButtonLabel={uploadType === 'update' ? i18n.t('Update Users') : i18n.t('Import Users')}
          imageClassName={
            uploadType === "update" ?
              "upload-existing-users-image" :
              "upload-new-users-image"}
          selectAction={this.actions.selectRegistrationBatch}
          updateAction={this.actions.updateRegistrationBatch}
          createAction={this.actions.createRegistrationBatch}
          updateAndCloseAction={this.actions.updateRegistrationAndClose}
          defaultValues={{ selectedRuleSet: selectedRuleSet }}
          onDataChange={this.onWizardDataChange}
          instructionsStep={{
            title: i18n.t('Prepare User List'),
            component: UploadInstructions,
            props: {
              organization_id: this.props.organization_id,
              organization_slug: this.props.organization_slug,
              locations: this.props.locations,
              ruleSets: this.props.ruleSets,
              uploadType: this.props.uploadType,
              uploadBusinessLine: this.props.uploadBusinessLine,
              userIsPiiRestrictedAdmin: this.props.userIsPiiRestrictedAdmin
            }
          }}
          fileStep={{
            title: i18n.t('Upload User List'),
            component: UploadFile,
            acceptTypes: ['.csv', '.xsl', 'application/vnd.ms-excel'],
            props: {
              uploadType
            }
          }}
          previewStep={{
            title: uploadType === 'update' ? i18n.t('Confirm User Update') : i18n.t('Preview and Import Users'),
            intro: this.renderPreviewIntro(),
            preProcessEntity: this.preProcessEntity
          }}
          orgCapabilities={this.props.orgCapabilities}
        />
      );
    } else {
      return <ProgressSpinner />;
    }
  }

}

export default connect(mapStateToProps)(UploadContainer);
