import PropTypes from 'prop-types';
import React, { Component, Fragment } from 'react';
import { Util } from "@everfi/reactifi";
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildGenericProps, getPageSize, JsonApiDecorator, FAIcon, AlertMessage, ConfirmationModal, Storage } from 'reactifi';
import i18n from 'lib/i18n';
import * as regBatchActionCreators from '../actions/registrationBatchActions';
import UploadList from '../../common/Components/upload/UploadList';
import { PROCESSING, COMPLETE } from '../../common/Components/upload/UploadConstants';
import BusinessLineSelectionModal from '../../common/Components/BusinessLineSelectionModal';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, 'registration_batches');
  const apiStore = new JsonApiDecorator(state.api);

  props.organizationId = ownProps.route.organization_id;
  props.userBusinessLines = ownProps.route.userBusinessLines;
  props.businessLines = apiStore.business_lines ? apiStore.business_lines.all() : [];
  props.rule_set_roles = apiStore.rule_sets ? apiStore.rule_sets.all() : [];
  props.listMeta = state.api.registration_batches
    ? state.api.registration_batches.meta
    : null;
  props.batchId = ownProps.route.batchId;
  props.reportType = ownProps.route.reportType;
  if (apiStore.registration_batches && props.batchId) {
    props.emailedRegistrationBatch = apiStore.registration_batches && props.batchId ? apiStore.registration_batches.find(props.batchId) : null;
  }

  return props;
}

class ListContainer extends Component {
  static propTypes = {
    businessLines: PropTypes.array,
    dispatch: PropTypes.func.isRequired,
    downloadProcessing: PropTypes.bool,
    downloadTimedOut: PropTypes.bool,
    downloadUrl: PropTypes.string,
    emailedRegistrationBatch: PropTypes.object,
    errorMessage: PropTypes.string,
    isLoadingRegistrationBatches: PropTypes.bool,
    listMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    organizationId: PropTypes.string,
    registration_batches: PropTypes.array,
    reportType: PropTypes.string,
    router: PropTypes.object,
    rule_set_roles: PropTypes.arrayOf(PropTypes.object),
    batchId: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number
    ]),
    showAlertMessage: PropTypes.func,
    successMessage: PropTypes.string,
    userBusinessLines: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(
      regBatchActionCreators,
      this.props.dispatch
    );

    this.state = {
      showLOBSelectModal: false,
      selectedUploadLOB: null,
      updatingBatch: false
    }
  }

  componentDidMount() {
    this.findRegistrationBatches({}, { number: 1, size: getPageSize() });
    this.actions.loadBusinessLines();
    this.actions.loadRuleSets();
    if (this.props.batchId) {
      this.actions.selectRegistrationBatch(this.props.batchId);
    }
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.emailedRegistrationBatch && this.props.emailedRegistrationBatch && this.getReportDownloadAction) {
      let reportDownloadAction = this.getReportDownloadAction(this.props.emailedRegistrationBatch, this.props.reportType);
      if (reportDownloadAction) reportDownloadAction();
    }
  }

  findRegistrationBatches = async (filters, page, sort) => {
    const { userBusinessLines } = this.props;
    const businessLineFilter = userBusinessLines.length === 1 ? {
      business_line: userBusinessLines[0]
    } : undefined;
    await this.actions.findRegistrationBatches({ ...filters, ...businessLineFilter }, page, sort);
  }

  get fields() {
    return [
      {
        name: 'filename', title: i18n.t('File Name'), width: '28%', canSort: true, formatter: (cell) => <span className="col-text">&nbsp;{cell}</span>
      },
      {
        name: 'upload_type', title: i18n.t('Upload Type'), width: '16%', canSort: true, formatter: (cell, row) => {
          let text;
          let icon;
          if (row.operation === "update") {
            text = i18n.t('Update Existing');
            icon = 'user-group';
          } else {
            text = i18n.t('New Users');
            icon = 'circle-user';
          }
          return (<span className="col-text">
            {text}
            &nbsp;
            <FAIcon icon={icon} />
          </span>);
        }
      },
      {
        name: 'successful_imports', title: i18n.t('Imported Rows'), width: '16%', canSort: true, formatter: (cell, row) => {
          if (row.status === PROCESSING) {
            return row.upload_summary.progress_msg;
          } else if (row.status === COMPLETE) {
            if (row.upload_summary.successful !== 0) {
              return (
                <div className="upload-counts">
                  <div>{row.upload_summary.successful}</div>
                  {this.renderReportDownload(row, 'successful')}
                </div>
              );
            } else {
              return row.upload_summary.successful;
            }
          }
          return '';
        }
      },
      {
        name: 'rejected_imports', title: i18n.t('Rejected Rows'), width: '16%', canSort: true, formatter: (cell, row) => {
          const icon = <FAIcon icon="triangle-exclamation" />;
          if (row.status === COMPLETE) {
            if (row.upload_summary.failures !== 0) {
              return (
                <div className="upload-counts">
                  {icon}
                  <div>{row.upload_summary.failures}</div>
                  {this.renderReportDownload(row, 'failed')}
                </div>
              )
            } else {
              return row.upload_summary.failures;
            }
          }
          return '';
        }
      },
      {
        name: 'created_at', title: i18n.t('Upload'), width: '12%', canSort: true, formatter: (cell, row) => {
          return Util.format(cell, 'P')
        }
      },
      {
        name: 'process_blanks', title: i18n.t('Delete Blanks?'), width: '12%', formatter: cell => cell ? i18n.t('Yes') : i18n.t('No')
      }
    ];
  }

  onSelectUpload = (uploadType) => {
    const userLOBs = this.getUserBusinessLines();

    switch (uploadType) {
      case "add":
        if (userLOBs && userLOBs.length === 1) {
          this.props.router.push(`/add?business_line=${userLOBs[0].slug}`);
        } else {
          this.setState({ showLOBSelectModal: true });
        }

        break;
      case "update":
        if (userLOBs && userLOBs.length === 1) {
          this.props.router.push(`/add?business_line=${userLOBs[0].slug}&type=update`);
        } else {
          this.setState({ showLOBSelectModal: true, updatingBatch: true });
        }

        break;
    }
  };

  onAddNewUsers = () => this.onSelectUpload('add');

  onUpdateExistingUsers = () => this.onSelectUpload('update');

  downloadReport = (action, param, type) => {
    if (this.props.downloadProcessing) {
      return;
    }
    action(param, type);
  };

  getReportDownloadAction = (regBatch, type) => {
    let action;
    switch (type) {
      case 'successful':
        if (regBatch.successful_rows_report_id) {
          action = () => this.downloadReport(this.actions.fetchReport, regBatch.successful_rows_report_id, 'successfulrows');
        } else {
          action = () => this.downloadReport(this.actions.downloadSucessfulRows, regBatch.id);
        }
        break;
      case 'failed':
        if (regBatch.failed_rows_report_id) {
          action = () => this.downloadReport(this.actions.fetchReport, regBatch.failed_rows_report_id, 'failedrows');
        } else {
          action = () => this.downloadReport(this.actions.downloadFailedRows, regBatch.id);
        }
        break;
      default:
        action = () => {};
        break;
    }
    return action;
  }

  renderReportDownload = (row, type) => {
    let action = this.getReportDownloadAction(row, type);
    return (
      <a onClick={action}>
        <FAIcon icon="down-to-line" /> {i18n.t(' Download.csv')}
      </a>
    );
  };

  handleSelectUploadLOB = (businessLine) => this.setState({ selectedUploadLOB: businessLine });

  handleUploadModalClose = (cancel) => {
    const { selectedUploadLOB, updatingBatch } = this.state;

    if (selectedUploadLOB && !cancel) {
      let url = `/add?business_line=${selectedUploadLOB}${updatingBatch ? '&type=update' : ''}`;
      this.props.router.push(url);
    }

    this.setState({ showLOBSelectModal: false, selectedUploadLOB: null, uploadType: null });
  }

  getUserBusinessLines = () => {
    const { businessLines, userBusinessLines, rule_set_roles } = this.props;

    if (!businessLines) {
      return [];
    }

    if (userBusinessLines) {
      return businessLines.filter(bl => userBusinessLines.includes(bl.slug) && rule_set_roles.find(element => element.is_manager && element.business_lines.includes(bl.slug)));
    } else {
      return businessLines;
    }
  }

  handleDownload = () => {
    Storage.setItem("suppressLoadingClass", true);
  }

  render() {
    const {
      downloadProcessing,
      downloadTimedOut,
      downloadUrl,
      errorMessage,
      isLoadingRegistrationBatches,
      listMeta,
      registration_batches,
      router,
      showAlertMessage,
      successMessage,
      organizationId
    } = this.props;
    const { clearMessages } = this.actions;

    return (
      <Fragment>
        <ConfirmationModal title={i18n.t("Report Sent")} onConfirm={this.actions.endTimeout} canClose={true} show={downloadTimedOut} confirmOnly={true}>
          <div>{i18n.t("Seems that it's taking longer than we expected to create your report. Once your report is ready we'll send you an email with a link to download it.")}</div>
        </ConfirmationModal>
        <ConfirmationModal title={i18n.t("Report Ready")} onConfirm={this.actions.clearDownload} canClose={true} show={downloadUrl} confirmOnly={true} confirmButtonText={i18n.t('Close')}>
          <div>
            <a download={true} href={downloadUrl} onClick={this.handleDownload}>{i18n.t('Click here to download')}</a>
          </div>
        </ConfirmationModal>
        {downloadProcessing && <AlertMessage message={i18n.t("Your report is processing.")} manualDismiss={true} />}
        <UploadList
          title={i18n.t('User Uploads')}
          actionButton={[
            {
              action: this.onUpdateExistingUsers,
              data: {
                action: 'update-existing-users',
                objectId: organizationId,
                objectType: 'organizations'
              },
              label: i18n.t('Update Existing Users'),
              style: 'secondary'
            },
            {
              action: this.onAddNewUsers,
              data: {
                action: 'add-new-users',
                objectId: organizationId,
                objectType: 'organizations'
              },
              label: i18n.t('Add New Users'),
              style: 'primary'
            }
          ]}
          instructions={<>
            {i18n.t('View and manage all of your organization’s user uploads and uploaded user updates. Larger files may take some time to process, so check back here for the status of recent uploads. If an upload has errors, download the rejected rows to see what went wrong. For more info, please visit our')}
            <a href="https://help.everfi.com/s/article/Add-Users" target="_blank" rel="noopener noreferrer">{i18n.t('help site')}</a>.
          </>}
          findAction={this.findRegistrationBatches}
          deleteAction={this.actions.deleteRegistrationBatch}
          fields={this.fields}
          router={router}
          errorMessage={errorMessage}
          successMessage={successMessage}
          clearMessages={clearMessages}
          showAlertMessage={showAlertMessage}
          list={registration_batches}
          listMeta={listMeta}
          isLoading={isLoadingRegistrationBatches}
          organizationId={organizationId}
        />
        {this.state.showLOBSelectModal && (
          <BusinessLineSelectionModal
            businessLines={this.getUserBusinessLines()}
            onSelect={this.handleSelectUploadLOB}
            onClose={this.handleUploadModalClose}
            selectedBusinessLine={this.state.selectedUploadLOB}
          />
        )}
      </Fragment>
    );
  }
}

export default connect(mapStateToProps)(ListContainer);
