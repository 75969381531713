import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { Util } from "@everfi/reactifi"

import { UserProgressApp } from '../../../externals';

export default class OrgUserProgress extends Component {
  static propTypes = {
    user: PropTypes.object,
    userAssignment: PropTypes.object,
    progress: PropTypes.object,
    router: PropTypes.object
  };

  render() {
    const { progress, user, userAssignment } = this.props;

    if (!userAssignment || !user) return null;
    const { first_name, last_name, email, last_sign_in_at } = this.props.user;

    const signedIn = last_sign_in_at ? Util.format(last_sign_in_at, 'P') : i18n.t('Never');
    const fullName = `${first_name} ${last_name}`;

    const progresses = progress ? [progress] : userAssignment.progresses;

    return (
      <UserProgressApp
        assignmentName={userAssignment.assignment.name}
        participationStatus={userAssignment.participation_status}
        progressStatus={userAssignment.progress_status}
        fullName={fullName}
        lastSignedIn={signedIn}
        email={email}
        progresses={progresses}
        router={this.props.router}
      />
    );
  }
}
