import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import {
  AccessControlWrapper,
  AlertMessage,
  AlertMessageTypes,
  Button,
  csvUploader,
  ExplainerText,
  FileUpload,
  NoResultsCard,
  SearchComponent,
  Section,
  buildGenericProps
} from 'reactifi';
import * as locationActionCreators from '../../Locations/actions/locationActionCreators';
import LocationForm from '../../Locations/components/LocationForm';
import LocationTable from '../components/LocationTableComponent';
import DeleteLocationModal from '../../common/Components/DeleteLocationConfirmationDialog';
import { NoResultButtons } from '../../common/Components/NoResultsButtons';
import debounce from 'lodash/debounce';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'locations', ['organization']);
  props.permissions = ownProps.route.permissions;
  if (props.currentEntity && props.currentEntity.type === 'locations') {
    props.currentLocation = props.currentEntity;
  }

  props.filter = { organization_id: ownProps.params.id, status: "*" };
  props.googleMapsApiKey = ownProps.route.googleMapsApiKey;
  props.organizationId = ownProps.params.id;
  props.viewer = ownProps.route.viewer;
  props.locationMeta = state.api.locations ? state.api.locations.meta : null;
  return props;
}

export class OrganizationLocationsContainer extends React.Component {
  static propTypes = {
    locations: PropTypes.array,
    locationMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    currentLocation: PropTypes.object,
    googleMapsApiKey: PropTypes.string.isRequired,
    showAlertMessage: PropTypes.bool,
    successMessage: PropTypes.string,
    isLoadingLocations: PropTypes.bool,
    organizationId: PropTypes.string,
    viewer: PropTypes.string.isRequired,
    filter: PropTypes.object,
    router: PropTypes.any,
    permissions: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(locationActionCreators, this.props.dispatch);
    this.noResultsMessage = i18n.t('Locations are where employees work');
    this.noResultsButtons = [NoResultButtons(this.actions.selectLocation, i18n.t('New Location'))];
    this.filterLocations = debounce(this.filterLocations, 350, { leading: false, trailing: true });

    this.state = {
      filtered: false,
      activePage: 1,
      sortName: 'external_id',
      sortOrder: 'asc',
      selectedLocation: null,
      showModal: false
    }
  }

  componentDidMount() {
    this.filterLocations();
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.filterLocations);
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sort: {
        value: sortName,
        direction: sortOrder
      },
      sortName,
      sortOrder,
      activePage: 1
    }, this.filterLocations);
  }

  onSearchChange = ({ keyword }) => {
    this.setState({ activePage: 1, activeSearch: keyword }, this.filterLocations);
  }

  filterLocations = () => {
    this.actions.readLocations('', this.props.filter, this.state.sort, { number: this.state.activePage }, this.state.activeSearch);
    this.setState({ filtered: true });
  }

  createLocation = async (location, addAnother) => {
    var entity = await this.actions.createLocation(location, addAnother);
    if (entity) {
      setTimeout(this.filterLocations, 1000);
    }
  }

  onLocationInfoUpload = () => {
    this.actions.displaySuccessMessage(i18n.t('File was uploaded successfully'));
    this.setState({ activePage: 1 }, () => {
      this.actions.readLocations('', this.props.filter, this.state.sort, { number: this.state.activePage });
    });
  }

  onUploadFailure = response => {
    this.actions.uploadError(response);
  }

  displayCSVUpload = () => {
    return (this.props.viewer === 'organization_administrator' ?
      <div className="page-panel">
        <div className="row">
          <div className="col-md-12">
            <FileUpload uploader={csvUploader}
              url="/api/data/locations/upload_csv"
              description={{ textArray: [i18n.t('Location')] }}
              header={{ text: i18n.t('Location') }}
              name="locationInformation"
              tooltip={i18n.t('Uploads locations available in the CSV')}
              icon={{
                className: 'images',
                iconSize: 'medium',
                fileClassName: 'file',
                noFileClassName: 'file-slash'
              }}
              input={{ name: 'locationInformation' }}
              button={{ text: i18n.t('Upload Location Information'), className: 'btn btn-primary' }}
              actions={{ success: this.onLocationInfoUpload, failure: this.onUploadFailure }} />
          </div>
        </div>
      </div> : null);
  }

  onDeleteClickLocation = (e, selectedLocation) => {
    e.preventDefault();
    e.stopPropagation();
    this.setState({ selectedLocation, showModal: true });
  };

  onEditClickLocation = (e, location) => {
    e.preventDefault();
    e.stopPropagation();
    this.actions.selectLocation(location.id);
  }

  deleteLocation = () => {
    this.actions.deleteLocation(this.state.selectedLocation);
    this.onCancelDeleteLocation();
  };

  onCancelDeleteLocation = () => this.setState({
    selectedLocation: null,
    showModal: false
  });

  render() {
    const { organizationId } = this.props;
    return (
      <>
        <AlertMessage message={this.props.errorMessage} messageType={AlertMessageTypes.danger} clearMessages={this.actions.clearMessages} manualDismiss={true} />
        <AlertMessage message={this.props.successMessage} messageType={AlertMessageTypes.success} visible={this.props.successMessage && this.props.showAlertMessage} clearMessages={this.actions.clearMessages} />
        <DeleteLocationModal
          onConfirm={this.deleteLocation}
          onCancel={this.onCancelDeleteLocation}
          show={this.state.showModal}
        />
        <LocationForm currentLocation={this.props.currentLocation} selectLocation={this.actions.selectLocation} clearMessages={this.actions.clearMessages}
          googleMapsApiKey={this.props.googleMapsApiKey} errorMessage={this.props.errorMessage} viewer={this.props.viewer} organizationId={this.props.organizationId}
          updateLocation={this.actions.updateLocation} createLocation={this.createLocation} loadOrganization={this.actions.loadOrganization} />
        <Section>
          <ExplainerText>
            {i18n.t("This is the list of all locations in this organization. Add locations or edit existing locations in the table.")}
          </ExplainerText>
          <div className="buttons">
            <AccessControlWrapper
              requiredPermission="edit_locations"
              permissions={this.props.permissions}
            >
              <Button
                data-action="add-location"
                data-object-type="organizations"
                data-object-id={organizationId}
                label={<span>{i18n.t('Add Location')}</span>}
                icon="plus"
                style="secondary"
                onClick={(e) => this.actions.selectLocation('add')} />
            </AccessControlWrapper>
          </div>
          {this.displayCSVUpload()}
        </Section>
        <Section noPadding={true} noBorder={true} type="list">
          <SearchComponent
            data-action="search-locations"
            data-field="location-keyword"
            data-object-type="organizations"
            data-object-id={organizationId}
            name="keyword"
            placeholder={i18n.t('Search')}
            searchAction={this.onSearchChange}
          />
        </Section>
        <Section
          dataObjectType="locations"
          type="list"
        >
          {this.props.isLoadingLocations || this.props.locations.length || this.state.filtered ?
            <LocationTable
              fieldsProps={{
                editLocationHandler: this.onEditClickLocation,
                deleteLocationHandler: this.onDeleteClickLocation
              }}
              sortOptions={{
                onSortChange: this.onSortChange,
                sortName: this.state.sortName,
                sortOrder: this.state.sortOrder,
                router: this.props.router,
                organizationId: this.props.organizationId
              }}
              data={this.props.locations}
              isLoadingData={this.props.isLoadingLocations}
              onPageChange={this.onPageChange}
              totalCount={this.props.locationMeta ? this.props.locationMeta.total_count : 0}
              activePage={this.state.activePage}
              permissions={this.props.permissions}
            />
            :
            <NoResultsCard message={this.noResultsMessage} buttons={this.noResultsButtons} />
          }
        </Section>
      </>
    );
  }
}

export default connect(mapStateToProps)(OrganizationLocationsContainer);
