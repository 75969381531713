import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  buildGenericProps,
  JsonApiDecorator,
  Page,
  FloatingLabelFormField,
  Form,
  FormField,
  FieldGroup,
  ConfirmationModal,
  AccessControlUtil,
  CancelConfirmModal
} from "reactifi";
import * as categoriesActionCreators from "../actions/CategoriesActionCreators";

import ValuesList from "../components/ValuesList";

import i18n from "lib/i18n";
import { isEqual } from "lodash";
import { withRouter } from "react-router";

const TEAMS_CAPABILITY = 'Capabilities::TeamManagement';

function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, "categories", [
    "category_labels"
  ]);

  props.organizationId = ownProps.route.organization_id;
  props.successMessage = state.api.successMessage;

  if (state.api.errorMessage) {
    props.errorMessage = state.api.errorMessage;
    props.showAlertMessage = true;
  }

  if (state.api.errorMessages) {
    props.errorMessages = state.api.errorMessages;
  }

  let apiStore = new JsonApiDecorator(state.api);

  if (apiStore.categories) {
    props.categories = apiStore.categories.all(
      "category_labels"
    );
  }

  if (apiStore.categories && ownProps.routeParams.id) {
    props.currentCategory = apiStore.categories.find(ownProps.routeParams.id);
  } else {
    props.currentCategory = apiStore.newObject('categories');
    if (!ownProps.routeParams.id) {
      props.currentCategory.category_labels = [];
    }
  }

  if (props.currentCategory && apiStore.categories) {
    props.currentCategory.category_labels = props.currentCategory.id
      ? props.categories.find(category => category.id === props.currentCategory.id)
        .category_labels
      : [];
  }

  props.metaData = state.api.categories
    ? state.api.categories.meta
    : null;

  props.createNewStore = type => apiStore.newObject(type);

  props.contentServiceUrl = ownProps.route.contentServiceUrl;
  props.token = ownProps.route.jwt;
  props.orgCapabilities = ownProps.route.orgCapabilities;

  props.usersMeta = state.api.users ? state.api.users.meta : null;
  props.teamsMeta = state.api.teams ? state.api.teams.meta : null;
  props.assignmentsMeta = state.api.assignment_dynamic_assignment_filters ? state.api.assignment_dynamic_assignment_filters.meta : null;

  return props;
}

export class CategoryContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func,
    currentCategory: PropTypes.object,
    router: PropTypes.object,
    errorMessage: PropTypes.string,
    categories: PropTypes.array,
    successMessage: PropTypes.string,
    createNewStore: PropTypes.func,
    routeParams: PropTypes.shape({
      id: PropTypes.string
    }),
    contentServiceUrl: PropTypes.string,
    token: PropTypes.string,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    usersMeta: PropTypes.object,
    teamsMeta: PropTypes.object,
    assignmentsMeta: PropTypes.object
  }

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(
      categoriesActionCreators,
      this.props.dispatch
    );

    this.state = {
      showDeleteModal: false,
      showErrorModal: false,
      showDiscardChangesModal: false,
      itemToDelete: null,
      deletionQueue: [],
      changesToSave: false,
      category: props.currentCategory
    };
  }

  componentDidMount() {
    if (!this.props.categories.length && this.props.routeParams.id) {
      this.actions.loadItem(this.props.routeParams.id);
    }
  }

  componentDidUpdate(prevProps) {
    if(!this.state.changesToSave && !isEqual(this.props.currentCategory, prevProps.currentCategory)) {
      this.setState({ category: this.props.currentCategory });
    }
  }

  onSaveCategory = async (item) => {
    this.sanitizeDataValues(item);
    await this.processDeleteQueue();
    await this.actions.createCategoryLabel(item, this.onCancel);
  }

  isCategoryLabelBeingUsed = async (categoryId) => {
    const teamsEnabled = AccessControlUtil.hasCapability(TEAMS_CAPABILITY, this.props.orgCapabilities);

    await Promise.all([
      this.actions.loadUsersWithLabel(categoryId),
      ...(teamsEnabled ? [this.actions.loadTeamsWithLabel(categoryId)] : []),
      this.actions.loadAssignmentsWithLabel(this.props.contentServiceUrl, this.props.token, categoryId)
    ]);

    const userCount = this.props.usersMeta ? this.props.usersMeta.total_count : 0;
    const teamCount = this.props.teamsMeta ? this.props.teamsMeta.total_count : 0;
    const assignmentCount = this.props.assignmentsMeta ? this.props.assignmentsMeta.total_count : 0;

    return userCount || teamCount || assignmentCount;
  }

  onDeleteCategoryLabel = async (item) => {
    const isCategoryLabelBeingUsed = await this.isCategoryLabelBeingUsed(item.id);

    if (!isCategoryLabelBeingUsed) {
      this.setState({
        showDeleteModal: !this.state.changesToSave,
        itemToDelete: item
      });

      if (this.state.changesToSave) {
        this.onConfirmDeleteCategoryLabel();
      }
    } else {
      this.setState({ showErrorModal: true });
    }
  }

  onConfirmDeleteCategoryLabel = async () => {
    this.setState((prev) => ({
      deletionQueue: prev.deletionQueue.concat(prev.itemToDelete),
      changesToSave: true,
      category: Object.assign({}, prev.category, {
        category_labels: prev.category?.category_labels.filter(label => label.id !== prev.itemToDelete?.id)
      })
    }));
    this.onCloseDeleteCategoryLabel();
  }

  onCloseDeleteCategoryLabel = () => {
    this.setState({
      showDeleteModal: false,
      showErrorModal: false,
      itemToDelete: null
    });
  }

  onUpdate = async (item) => {
    if (this._valuesList.state.values.length === 0) {
      item.category_labels = [];
    } else {
      this.sanitizeDataValues(item);
    }
    
    await this.processDeleteQueue();
    await this.actions.updateItem(item, this.exitScreen);
  }

  onCancel = () => {
    if(!this.state.changesToSave) {
      this.exitScreen();
    } else {
      this.setState({ showDiscardChangesModal: true });
    }
  }

  sanitizeDataValues = (item) => {
    item.category_labels =
    item.category_labels &&
    item.category_labels.filter(({ name }) => !!name);
  };

  renderLabelErrorModal = () => {
    const { usersMeta, teamsMeta, assignmentsMeta } = this.props;
    const userCount = usersMeta ? usersMeta.total_count : 0;
    const teamCount = teamsMeta ? teamsMeta.total_count : 0;
    const assignmentCount = assignmentsMeta ? assignmentsMeta.total_count : 0;

    return (
      <ConfirmationModal
        title={i18n.t('Delete Custom Category Label')}
        show={this.state.showErrorModal}
        confirmOnly={true}
        canClickOff={true}
        onCancel={this.onCloseDeleteCategoryLabel}
        onConfirm={this.onCloseDeleteCategoryLabel}
      >
        <p>{i18n.t('This label cannot be deleted:')}</p>
        <ul>
          {userCount > 0 && <li>{i18n.t(`There are ${userCount} users associated with this label.`)}</li>}
          {teamCount > 0 && <li>{i18n.t(`There are ${teamCount} teams associated with this label.`)}</li>}
          {assignmentCount > 0 && <li>{i18n.t(`There are ${assignmentCount} assignments associated with this label.`)}</li>}
        </ul>
      </ConfirmationModal>
    );
  }

  processDeleteQueue = async () => {
    if(!this.state.deletionQueue.length) return;

    const deletionQueueRequests = this.state.deletionQueue.map(item => this.actions.deleteItem(item, true));
    await Promise.all(deletionQueueRequests);
  }

  exitScreen = () => {
    this.props.router.goBack();
  }

  handleDiscardSelection = (confirmed) => {
    if(!confirmed) {
      this.setState({ showDiscardChangesModal: false });
      return;
    }
    
    this.exitScreen();
  }

  render() {
    const { createNewStore, errorMessage, successMessage } = this.props;
    const { category, showDiscardChangesModal, showDeleteModal } = this.state;
    const pageTitle = category.id ? i18n.t(`Manage Category: ${category.name}`) : i18n.t("Create Custom Category");

    return (
      <Page
        title={pageTitle}
        pageType="child"
        backButton={this.onCancel}
        contentType="form custom-category"
        successMessage={successMessage}
        clearMessages={this.actions.clearMessages}
        errorMessage={errorMessage}
        usePageWrapper={true}
      >
        <div className="page-panel">
          <div className="page-panel-body">
            <div className="instructional-text">
              {i18n.t('The category is the broader name of how you want to segment users. The labels are the specific tags you assign to each user. To learn more about custom categories, visit our ')}
              <a href="https://help.everfi.com/s/help/" target="_blank" rel="noopener noreferrer">{i18n.t('knowledge base')}</a>.
            </div>
            {Object.keys(category).length > 1 &&
              <Form
                buttonGroupClass="form-controls-container category-form-controls"
                data={category}
                onCancel={this.onCancel}
                addAction={this.onSaveCategory}
                updateAction={this.onUpdate}
                saveButtonAreaClassName="text-end p-b-10 m-b-20"
                errorMessage={errorMessage}
                showAlertMessages={false}
              >
                <FieldGroup className="row m-t-10">
                  <FloatingLabelFormField
                    name="name"
                    caption={i18n.t("Enter Custom Category Name")}
                    required={true}
                    className="col-10"
                  />
                  <FormField name="organization_id" type="hidden" className="hidden" />
                </FieldGroup>

                <ValuesList
                  title={i18n.t("Add Labels")}
                  createNewStore={createNewStore}
                  ref={(i) => { this._valuesList = i; }}
                  clearMessages={this.actions.clearMessages}
                  onDeleteCategoryLabel={this.onDeleteCategoryLabel}
                />
              </Form>
            }
          </div>
        </div>
        {this.renderLabelErrorModal()}
        <ConfirmationModal
          title={i18n.t('Label Deletion Pending')}
          children={i18n.t('You can select additional custom category labels you wish to delete. Confirm your changes using the Save button once you are done.')}
          show={showDeleteModal}
          onConfirm={this.onConfirmDeleteCategoryLabel}
          confirmOnly={true}
        />
        <CancelConfirmModal
          show={showDiscardChangesModal}
          onCancelClose={this.handleDiscardSelection}
        />
      </Page>
    );
  }
}

export default withRouter(connect(mapStateToProps)(CategoryContainer));
