import { buildGenericProps, JsonApiDecorator } from 'reactifi';
import { orderBy, sortBy } from 'lodash';

export default function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'users');

  props = Object.assign(props, {
    certificateServiceUrl: ownProps.route.certificateServiceUrl,
    complianceUrl: ownProps.route.complianceUrl,
    contentServiceUrl: ownProps.route.contentServiceUrl,
    currentUserId: ownProps.routeParams.user_id,
    currentActiveUserId: ownProps.route.currentActiveUserId,
    errorMessage: state.api.errorMessage,
    errorMessages: state.api.errorMessages,
    googleMapsApiKey: ownProps.route.googleMapsApiKey,
    jwt: ownProps.route.jwt,
    organizationId: ownProps.params.id || ownProps.route.organization_id,
    permissions: ownProps.route.permissions,
    showAlertMessage: state.api.successMessage || state.api.errorMessage,
    singleUsePasswordHours: ownProps.route.singleUsePasswordHours,
    successMessage: state.api.successMessage,
    userBusinessLines: ownProps.route.userBusinessLines,
    userIsPrimaryManager: ownProps.route.userIsPrimaryManager,
    userIsSecondaryManager: ownProps.route.userIsSecondaryManager,
    userIsPiiRestrictedNextAdvisor: ownProps.route.userIsPiiRestrictedNextAdvisor,
    userManagedBusinessLines: ownProps.route.userManagedBusinessLines,
    userManagedTeams: ownProps.route.userManagedTeams,
    userIsTeamManager: ownProps.route.userIsTeamManager,
    userTeams: ownProps.route.userTeams,
    viewer: ownProps.route.viewer
  });

  let apiStore = new JsonApiDecorator(state.api);
  const {
    categories,
    category_label_users,
    business_lines,
    email_bounces,
    training_periods,
    rule_sets,
    org_rule_sets
  } = apiStore;

  mapAwardsToAssignments(apiStore, props);
  mapUserProgresses(apiStore, props, state);
  mapScormContexts(apiStore, props);

  props.businessLines = business_lines ? business_lines.all() : [];
  props.trainingPeriods = training_periods ? training_periods.all() : [];
  props.ruleSets = rule_sets ? rule_sets.all() : [];
  props.orgRuleSets = org_rule_sets ? org_rule_sets.all() : [];
  props.categories = categories
    ? categories.all('category_labels')
    : [];
  props.categoryLabelUsers = category_label_users
    ? category_label_users.all(['category_label.category'])
    : [];
  props.categoryLabelUser = () => {
    return apiStore.newObject('category_label_users');
  };

  props.emailBounces = email_bounces ? email_bounces.first() : {};
  props.newUserRecord = () => apiStore.newObject('users');

  mapRuleSets(apiStore, props);

  if (apiStore.organizations) {
    props.organization = apiStore.organizations.find(props.organizationId);
    if (props.organization) {
      props.capabilityList = props.organization.capability_list;
      props.orgCapabilities = ownProps.route.orgCapabilities || props.capabilityList;
      props.organizationTitle = props.organization.name;
      props.cportal_url = props.organization.cportal_url;
    }
  }
  mapRegistrationSets(apiStore, props);

  return props;
}

function awardedItemIds(userAward) {
  const value = userAward.data?.event?.content_id || userAward.data?.achievement?.award_item_ids;

  return Array.isArray(value) ? value : [ value ];
}

function mapAwardsToAssignments(apiStore, props) {
  const { certificateServiceUrl, jwt } = props;
  const awards = apiStore.user_awards?.all() || [];
  props.userAwards = awards.map(award => ({
    user_assignment_id: award?.domain_object_id,
    content_ids: awardedItemIds(award),
    status: award?.data?.event?.status,
    business_line: award?.data?.event?.business_line,
    award_id: award?.id,
    title: award?.data?.award?.title,
    download_link: `${certificateServiceUrl}/api/v1/users/${award?.user_id}/user_awards/${award?.id}.pdf?access_token=${jwt}`
  }));
}

function mapRuleSets(apiStore, props) {
  if (apiStore.users) {
    props.user = apiStore.users.find(props.currentUserId, ['location', 'teams_as_member', 'sso_attributes']);
    if (props.user) {
      props.user.teams_as_member_ids = props.user.teams_as_member ? props.user.teams_as_member.map(team => team.id) : [];
      if (props.user.rule_set_roles && props.ruleSets) {
        props.user.ruleSetIds = Object.keys(props.user.rule_set_roles);
        props.user.rolesDisplay = Object.keys(props.user.rule_set_roles)
          .map(userRule => {
            let ruleSet = props.ruleSets.find(ruleSet => ruleSet.id === userRule);
            if (ruleSet && ruleSet.roles) {
              let role = ruleSet.roles.find(
                role => role.slug === props.user.rule_set_roles[userRule]
              );
              return `${ruleSet.label} - ${role ? role.label : 'default'}`;
            } else {
              return userRule;
            }
          })
          .join(', ');
        props.user.ruleSetsDisplay = props.user.rule_sets
          .map(rule_set => rule_set.label)
          .join(', ');
      }
    }
  }
}

function mapRegistrationSets(apiStore, props) {
  if (apiStore.registration_sets) {
    props.registrationSets = apiStore.registration_sets.first();
    props.registrations = props.registrationSets.registrations;
    props.organizationName = props.registrationSets.organization_name || '';
    props.organizationId = props.registrationSets.organization_id || '';
    if (props.user && props.user.location) {
      props.registrations.forEach(reg => {
        if (
          reg.location_id &&
          String(reg.location_id) === String(props.user.location.id)
        ) {
          reg.location = props.user.location;
        }
      });
    }
  } else {
    props.registrations = null;
  }
}

function mapUserProgresses(apiStore, props, state) {
  props.userProgresses = [];

  if (apiStore.progress) {
    const progresses = apiStore.progress.all({ content: true, user_assignments: ['assignment'] });
    progresses.forEach((progress) => {
      if (progress.user_assignments && progress.user_assignments.length) {
        const openAssignments = progress.user_assignments.filter(userAssignment => userAssignment.status === 'assigned');
        const assignmentsData = openAssignments.length ? openAssignments : progress.user_assignments;

        progress.assignments = progress.user_assignments.map(userAssignment => userAssignment.assignment);
        progress.due_on = sortBy(assignmentsData, ['due_on'])[0].due_on;
        progress.inviteDate = sortBy(assignmentsData, ['invited_on'])[0].invited_on;
        progress.lastReminderDate = orderBy(assignmentsData.filter(ua => ua.last_reminded_at !== null), ['last_reminded_at'], ['desc'])[0]?.last_reminded_at;
        progress.participationStatus = orderBy(assignmentsData, ['due_on'], ['desc'])[0].participation_status;
        progress.past_due = progress.user_assignments.some((ua) => ua.past_due === true);
      }
    })
    props.userProgresses = progresses;
    props.userProgressMeta = apiStore.progress ? state.api.progress.meta : null;
  }
}

function mapScormContexts(apiStore, props) {
  props.scormContexts = [];

  if (apiStore.scorm_contexts) {
    props.scormContexts = apiStore.scorm_contexts.all(['progress', 'scorm_package']);
    props.scormContexts.forEach(context => {
      if (context.progress) {
        context.progress_id = context.progress.id;
        if (context.progress.data) {
          context.content_type = context.progress.data.type;
        }
      }
    });
  }
}
