import React from "react";
import PropTypes from "prop-types";
import {
  SelectAsyncControl,
  FloatingLabelFormField
} from "reactifi";

import i18n from "lib/i18n";

import UploadFileStep from '../../common/Components/upload/UploadFileStep';

export default class FileUpload extends UploadFileStep {

  static propTypes = {
    data: PropTypes.object,
    contentServiceUrl: PropTypes.string.isRequired,
    jwt: PropTypes.string.isRequired
  };

  preProcessData = data => {
    data.content_package_ids = this.props.data.content_package_ids.map(item => item.id);
    return data;
  };

  onContentPackageSelected = (data) => {
    this.updateData({ content_package_ids: data });
  };

  renderIntro = () => {
    const { data, contentServiceUrl, jwt } = this.props;

    return (
      <div className="import-org-upload-step">
        <div>{i18n.t('Choose the content packages that you want to associate this organization with.')}</div>
        <div className="top-buffer-20">
          <FloatingLabelFormField
            name="content_package_ids"
            caption={i18n.t("Select Content Packages*")}
            dataValue={data.content_package_ids}
            className="col-half-width">
            <SelectAsyncControl
              multi={true}
              filterField="keyword"
              placeholder=""
              dataUrl={`${contentServiceUrl}/api/v1/content_packages.json`}
              authToken={jwt}
              pageSize={10}
              displayProperty="name"
              sortBy="name"
              onChange={this.onContentPackageSelected}
            />
          </FloatingLabelFormField>
        </div>
        <h4>{i18n.t('Upload Organization .json File')}</h4>
        <div>{i18n.t('Choose the organization file you want to import. File format must be .json.')}</div>
      </div>
    );
  };
}
