import React from "react";
import { ActionButtons, Header, ExplainerText, ListModule, Section, useContainerProps } from "reactifi";
import i18n from "lib/i18n";
import { sanitizeUrl } from '@braintree/sanitize-url';

const ImpactReports = props => {
  props = useContainerProps(props);

  const fields = [
    { name: "name", title: i18n.t("Name"), canSort: true, defaultSort: true },
    { name: "year", title: i18n.t("Year"), canSort: true, formatter: cell => cell || i18n.t('N/A') },
    {
      name: "actions",
      title: i18n.t("Actions"),
      width: "125px",
      // eslint-disable-next-line react/no-multi-comp
      formatter: (cell, row) => {
        const buttons = [
          { icon: 'down-to-line', title: '', tooltip: 'Download', actionType: 'externalLink', action: row.download_url, target: '_blank', download: true, rel: 'noopener noreferrer' }
        ];
        return <ActionButtons row={row} buttons={buttons} />;
      }
    }
  ];

  const back = () => {
    //auth ticket for the report gets expired on use, have to make sure we get server load so we get a fresh ticket
    window.location = sanitizeUrl(window.location.href.replace(window.location.hash, ''));
  }

  return (
    <>
      <Header title={i18n.t('Impact Reports')} back={back} />
      <Section>
        <ExplainerText>
          {i18n.t('Downloads allow you to see reports related to the impact that courses have on your organization.')}
        </ExplainerText>
        <ListModule
          fields={fields}
          useCoreTable={false}
        />
      </Section>
    </>
  );
};

export default ImpactReports;
