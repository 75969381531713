import PropTypes from 'prop-types';
import React from 'react';
import { App } from 'reactifi';
import ImpactDashboardContainer from './containers/ImpactDashboardContainer';
import ImpactReportsContainer from './containers/ImpactReportsContainer';

const index = (props, _railsContext) => {
  const initialState = {
    api: {
      contexts: props.contexts
    }
  };
  return (
    <App {..._railsContext} initialState={initialState}>
      <ImpactDashboardContainer path="/" capabilities={props.capabilities} authUrl={props.authentication_url} />
      <ImpactReportsContainer path="/impact_reports" organizationId={props.organization_id}/>
    </App>
  );
}

index.propTypes = {
  capabilities: PropTypes.array,
  contexts: PropTypes.shape({
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string
      })
    )
  }),
  organization_id: PropTypes.string
}

export default index;
