import { buildGenericProps, JsonApiDecorator } from 'reactifi';

/**
 * Maps organization store to props
 * @param   {object}  state
 * @param   {object}  ownProps
 * @returns {object}  props
 * @example <caption>Usage</caption>
 * import React, { Component } from 'react';
 * import { connect } from 'react-redux';
 * import mapStateToProps from 'organizationMap';
 *
 * class MyReactComponent extends component {
 *    render() {
 *      //Make it beautiful
 *    }
 * }
 *
 * export default connect(mapStateToProps)(MyReactComponent)
 */
export default function mapStateToProps(state, ownProps) {
  const props = buildGenericProps(state, 'organizations', [
    'logo',
    'favicon',
    'background_image',
    'organization_brand_style_guides',
    'tag_managers'
  ]);
  const stateApiDecorator = new JsonApiDecorator(state.api);
  props.createNewObject = () => {
    return stateApiDecorator.newObject('capabilities');
  };

  // Create current organization
  if (stateApiDecorator.organizations) {
    // Get current organization based on params
    props.currentOrganization = stateApiDecorator.organizations.find(
      ownProps.params.id,
      ['favicon', 'logo', 'background_image', 'organization_brand_style_guides', 'tag_managers']
    );
  }

  if(props.currentEntity && !props.currentEntity.id) {
    if (state.orgReducer && state.orgReducer.slug) {
      props.currentEntity.slug = state.orgReducer.slug
    }
  }

  props.orgCapabilities = stateApiDecorator.capabilities
    ? stateApiDecorator.capabilities.all()
    : [];

  if (stateApiDecorator.capability_options) {
    props.capabilities = stateApiDecorator.capability_options.all();
  }

  props.orgMeta = state.api.organizations ? state.api.organizations.meta : null;
  props.viewer = ownProps.route.viewer;
  return props;
}
