import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  AccessControlUtil,
  ChartWrapper,
  Header,
  Section,
  TableauChart,
  TableauCloudChart,
  useContainerProps
} from 'reactifi';
import { Tab, Tabs } from 'react-bootstrap';
import ImpactStoriesContainer from '../containers/ImpactStoriesContainer';

const DASHBOARD_SLUG = 'ceos-impact-report';
const IMPACT_TABS = Object.freeze([
  { slug: DASHBOARD_SLUG, title: 'Impact Dashboard' },
  { slug: 'ceos-impact-report-builder', title: 'Report Builder' }
]);

const ImpactDashboard = (props) => {
  props = useContainerProps(props);

  const [activeTab, setActiveTab] = useState(DASHBOARD_SLUG);
  const [activeReport, setActiveReport] = useState(
    props.contexts.find((ctx) => ctx.chart.slug === DASHBOARD_SLUG) || {}
  );
  const { capabilities, displayErrorMessage, router, dataExportEntity } = props;

  const contextConfig = useMemo(() => {
    if (!activeReport) return null;

    switch (activeReport.type) {
      case 'Datafi::TableauChartContext':
        return {
          ChartComponent: TableauChart,
          chartProps: {
            chartSourceUrl: activeReport.iframe_src,
            tableau: window.tableau
          }
        };
      case 'Datafi::TableauCloudChartContext':
        return {
          ChartComponent: TableauCloudChart,
          chartProps: {
            tableau: window.tableauV3
          }
        };
      default:
        throw new Error('Invalid chart type provided');
    }
  }, [activeReport]);

  const buttons = useMemo(() => {
    const btns = [
      {
        href: 'https://www.everfi.com',
        label: i18n.t('Discover Initiatives'),
        style: 'primary',
        target: '_blank'
      }
    ];

    if (
      AccessControlUtil.hasCapability(
        'Capabilities::FileDownloads',
        capabilities
      )
    ) {
      btns.unshift({
        action: () => router.push('/impact_reports'),
        icon: 'down-to-line',
        label: i18n.t('Download Impact Reports'),
        style: 'secondary'
      });
    }

    return btns;
  }, [capabilities, router]);

  const handleSelectReport = (slug) => {
    setActiveTab(slug);
    const activeChart = props.contexts.find((ctx) => ctx.chart.slug === slug);
    if (activeChart) setActiveReport(activeChart);
  };

  const { ChartComponent, chartProps } = contextConfig;

  return (
    <>
      <Header button={buttons} title={i18n.t('Impact Dashboard')}>
        <nav className="nav-tabs uppercase">
          <Tabs
            activeKey={activeTab}
            id="impactTabs"
            onSelect={handleSelectReport}
          >
            {IMPACT_TABS.map((tab) => (
              <Tab key={tab.slug} eventKey={tab.slug} title={tab.title} />
            ))}
          </Tabs>
        </nav>
      </Header>
      <Section type="chart" key={activeTab}>
        <ChartWrapper>
          <ChartComponent
            {...props}
            {...chartProps}
            chartSourceUrl={activeReport.iframe_src}
            showReportToolbar={activeReport.chart?.show_toolbar}
            chart={activeReport.chart}
            context={activeReport}
            onError={displayErrorMessage}
            toolbarType={'light'}
            currentEntity={dataExportEntity}
          />
        </ChartWrapper>
      </Section>
      <ImpactStoriesContainer />
    </>
  );
};

ImpactDashboard.propTypes = {
  capabilities: PropTypes.array,
  contexts: PropTypes.array,
  createMember: PropTypes.func,
  currentEntity: PropTypes.shape({
    type: PropTypes.string,
    iframe_src: PropTypes.string.isRequired,
    chart: PropTypes.shape({
      name: PropTypes.string
    })
  }),
  dataExportEntity: PropTypes.shape({
    type: PropTypes.string
  }),
  displayErrorMessage: PropTypes.func,
  router: PropTypes.shape({
    push: PropTypes.func
  }),
  selectMember: PropTypes.func
};

export default ImpactDashboard;
