import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { ConfirmationModal, Page, PagingDataList } from 'reactifi';
import i18n from 'lib/i18n';
import Chart from './Chart';
import debounce from 'lodash/debounce';

export default class Charts extends Component {
  static propTypes = {
    charts: PropTypes.array,
    chart_id: PropTypes.string,
    clearMessages: PropTypes.func.isRequired,
    createChart: PropTypes.func.isRequired,
    current: PropTypes.object,
    deleteChart: PropTypes.func.isRequired,
    displayErrorMessage: PropTypes.func,
    entity: PropTypes.object,
    errorMessage: PropTypes.string,
    isLoading: PropTypes.bool,
    loadCharts: PropTypes.func.isRequired,
    meta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    ruleSets: PropTypes.array.isRequired,
    selectChart: PropTypes.func.isRequired,
    successMessage: PropTypes.string,
    updateChart: PropTypes.func.isRequired
  };

  static defaultProps = {
    charts: []
  };

  constructor(props) {
    super(props);
    this.refreshCharts = debounce(this.refreshCharts.bind(this), 350, { leading: false, trailing: true });

    this.state = {
      chart_id: this.props.chart_id,
      activePage: 1,
      deleteChartData: null,
      sortName: 'name',
      sortOrder: 'asc'
    }
  }

  refreshCharts = () => {
    this.props.loadCharts('', { number: this.state.activePage }, this.state.sort);
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.refreshCharts);
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState(
      {
        sort: {
          value: sortName,
          direction: sortOrder
        },
        sortName,
        sortOrder,
        activePage: 1
      }, this.refreshCharts);
  }

  deleteChart = (chart) => {
    this.setState({ deleteChartData: chart });
  }

  onCancelDeleteChart = () => {
    this.setState({ deleteChartData: null });
  }

  onConfirmDeleteChart = () => {
    this.props.deleteChart(this.state.deleteChartData, this.props.entity);
    this.setState({ deleteChartData: null });
  }

  editAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.selectChart(row.id);
  }

  deleteAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.deleteChart(row);
  }

  get options() {
    return {
      onSortChange: this.onSortChange,
      defaultSorted: [
        {
          dataField: 'name',
          order: 'asc'
        }
      ],
      onRowClick: this.editAction
    };
  }

  get actions() {
    return [
      {
        icon: 'pen',
        title: i18n.t('Edit'),
        tooltip: i18n.t('Edit Chart'),
        type: 'edit',
        actionType: 'callToAction',
        action: this.editAction
      },
      {
        icon: 'trash',
        title: i18n.t('Delete'),
        tooltip: i18n.t('Delete Chart'),
        type: 'delete',
        actionType: 'callToAction',
        action: this.deleteAction
      }
    ];
  }

  get fields() {
    return [
      { name: 'name', title: i18n.t('Name'), canSort: true, width: '100%' },
      { name: 'slug', title: i18n.t('Slug'), canSort: true, width: '100%' }
    ];
  }

  actionButtonClick = () => {
    this.props.selectChart("add");
  }

  render() {
    let actionButton = {
      label: i18n.t('New Report'),
      'data-action': 'new-report',
      action: this.actionButtonClick
    };

    return (
      <Page
        title={i18n.t('Report Configurations')}
        pageType="parent"
        contentType="list"
        actionButton={actionButton}
        errorMessage={this.props.current ? null : this.props.errorMessage}
        clearMessages={this.props.clearMessages}
        successMessage={this.props.successMessage}
        usePageWrapper={true}
      >
        <Chart
          {...this.props}
          select={this.props.selectChart}
          create={this.props.createChart}
          update={this.props.updateChart}
        />
        <PagingDataList
          data={this.props.charts}
          fields={this.fields}
          actions={this.actions}
          keyField="name"
          options={this.options}
          isLoadingData={this.props.isLoading}
          onPageChange={this.onPageChange}
          totalCount={this.props.meta ? this.props.meta.total_count : 0}
          activePage={this.state.activePage}
        />
        <ConfirmationModal
          title={i18n.t('Chart Configurations')}
          data={this.state.deleteChartData}
          canClickOff={true}
          onCancel={this.onCancelDeleteChart}
          onConfirm={this.onConfirmDeleteChart}
          confirmButtonText={i18n.t('Delete')}
        >
          <div>{i18n.t('Are you sure you would like to delete this chart?')}</div>
        </ConfirmationModal>
      </Page>
    )
  }
}
