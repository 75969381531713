export default function orgReducer (state = {}, action) {
  if(action.type === 'GENERATE_SLUG' && action.slug) {
    const newState = Object.assign({}, state, {
      slug: action.slug
    });
    return newState;
  }
  if(action.type === 'CLEAR_SLUG') {
    const newState = Object.assign({}, state);
    delete newState.slug
    return newState;
  } else {
    return state;
  }
}
