import React, { Component } from "react";
import PropTypes from "prop-types";
import { Page, ProgressSpinner, FAIcon } from "reactifi";
import i18n from "lib/i18n";

import WebhookDomainEmpty from "./WebhookDomainEmpty";
import WebhookDomainForm from "./WebhookDomainForm";
import WebhookDomainItem from "./WebhookDomainItem";

export default class WebhookDomainList extends Component {
  static propTypes = {
    showAlertMessage: PropTypes.bool,
    clearMessages: PropTypes.func,
    domains: PropTypes.array,
    domainsMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    currentDomain: PropTypes.object,
    currentWebhook: PropTypes.object,
    isLoadingDomains: PropTypes.bool,
    applications: PropTypes.array,
    selectDomain: PropTypes.func,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  };

  get actionButton() {
    return {
      label: (<React.Fragment>
        <FAIcon icon="plus" additionalClasses="m-l-0 m-r-5" />
        {i18n.t("New Webhook Domain")}
      </React.Fragment>),
      action: this.newDomain,
      'data-action': 'create-new-webhook'
    };
  }

  selectDomain = (id) => {
    this.props.selectDomain(id);
  }

  newDomain = () => {
    this.props.selectDomain('add');
  }

  renderDomainList = () => {
    const { domains, isLoadingDomains } = this.props;
    if (isLoadingDomains) {
      return <ProgressSpinner/>;
    }
    if (domains.length === 0) {
      return <WebhookDomainEmpty action={this.newDomain}/>;
    }
    return domains.map((domain) => (
      <WebhookDomainItem
        domain={domain}
        key={domain.id}
        {...this.props}
        selectDomain={() => this.selectDomain(domain.id)}
      />
    ));
  }

  render() {
    const { errorMessage, successMessage } = this.props;
    return (
      <Page
        title={i18n.t('Webhook Domains')}
        pageType="parent"
        contentType="cards"
        actionButton={this.actionButton}
        successMessage={successMessage}
        errorMessage={errorMessage}
        usePageWrapper={true}
      >
        {this.renderDomainList()}
        {<WebhookDomainForm {...this.props} />}
      </Page>
    );
  }
}
