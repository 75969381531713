import React, { useCallback, useMemo } from "react";
import PropTypes from "prop-types";
import { Section, FilterBar, SelectFilter } from "reactifi";
import i18n from "lib/i18n";
import {
  UserDetailCourses,
  UserDetailScormContexts
} from "..";

const SIMULATABLE_CONTENT_TYPES = Object.freeze(['course', 'course_module', 'survey', 'poll', 'assessment', 'form']);
const UNSIMULATABLE_PARTICIPATION_STATUSES = Object.freeze(['unassigned', 'closed']);

const Content = props => {
  const {
    activeFilters,
    activeTab,
    availableBusinessLines,
    businessLines,
    contentServiceUrl,
    currentEntity,
    isFitAdmin,
    jwt,
    loadingProgress,
    onFilterChange,
    organizationId,
    permissions,
    scormContexts,
    selectedBusinessLine,
    trainingPeriods,
    unlimitedAccess,
    userAssignments,
    userProgresses
  } = props;

  const checkSimulatable = useCallback(activity => {
    if (UNSIMULATABLE_PARTICIPATION_STATUSES.includes(activity.participation_status)) {
      return false;
    }
    if (activity.progress_id && activity.content_type === 'group') {
      const items = activity?.data?.items || activity?.progress?.data?.items;
      if (items) {
        const hasModule = Object.values(items).find(item => {
          // at this level, all evaluation content types are called assessment
          return item.type === 'module' || item.type === 'course' || item.type === 'assessment';
        });
        return hasModule;
      }
    }
    return activity.progress_id && SIMULATABLE_CONTENT_TYPES.includes(activity.content_type);
  }, []);

  const formatFilterChange = (filters) => {
    const formattedFilters = {};
    if (filters) {
      Object.keys(filters).forEach((filter) => {
        if (filter === 'training_period_id') {
          formattedFilters[filter] = filters[filter].id;
        }
        if (filter === 'status') {
          formattedFilters[filter] = filters[filter].map(status => status.value);
        }
        if (filter === 'content_id') {
          formattedFilters[filter] = filters[filter].map(content => content.id);
        }
      });
    }
    if (formattedFilters !== activeFilters) {
      onFilterChange(formattedFilters);
    }
  };

  const getFilterConfig = () => {
    const trainingPeriodOptions = trainingPeriods.map(tp => ({
      label: tp.name,
      id: tp.id,
      value: tp.id
    }));
    const contentUrl = `${contentServiceUrl}/api/v1/contents.json`;

    const userProgressOptions = [
      {
        label: i18n.t('Not Started'),
        value: "not_started"
      },
      {
        label: i18n.t('In Progress'),
        value: "in_progress"
      },
      {
        label: i18n.t('Completed'),
        value: "completed"
      }
    ];

    const filterConfig = [
      {
        name: 'training_period_id',
        label: i18n.t('Training Period'),
        options: trainingPeriodOptions,
        type: SelectFilter
      },
      {
        name: 'content_id',
        label: i18n.t('Learning Activity'),
        isMulti: true,
        isAsync: true,
        asyncOptions: {
          authToken: jwt,
          dataUrl: contentUrl,
          queryFields: { name: 'word_start' },
          filters: { business_lines: activeTab, organization_id: organizationId }
        },
        labelKey: 'name',
        valueKey: 'id',
        showSelection: false,
        type: SelectFilter
      },
      {
        name: 'status',
        label: i18n.t('Progress Status'),
        options: userProgressOptions,
        isMulti: true,
        type: SelectFilter,
        valueKey: 'value'
      }
    ];

    return filterConfig;
  };

  const activitiesElement = useMemo(() => {
    if (!unlimitedAccess || !businessLines) {
      return null;
    }
    return (
      <>
        {selectedBusinessLine &&
          <FilterBar filterConfig={getFilterConfig()} onSelectFilters={formatFilterChange} />
        }
        <UserDetailCourses
          {...props}
          userProgresses={userProgresses}
          businessLine={selectedBusinessLine}
          checkSimulatable={checkSimulatable}
          loadingProgress={loadingProgress}
        />
      </>
    );
  }, [availableBusinessLines, businessLines, selectedBusinessLine, checkSimulatable, loadingProgress, unlimitedAccess, userAssignments, userProgresses]);

  const scormContextsElement = useMemo(() => {
    if (!((currentEntity || {}).user_types || {}).Scorm) {
      return null;
    }

    let canManageScorm = isFitAdmin && (
      permissions.includes('manage_scorm') || permissions.includes('all_access')
    );

    let canArchiveScorm = canManageScorm || props.currentViewer?.['is_scorm_manager?'] || false;

    return (
      <UserDetailScormContexts
        {...props}
        checkSimulatable={checkSimulatable}
        canManageScorm={canManageScorm}
        canArchiveScorm={canArchiveScorm}
        scormContexts={scormContexts}
      />
    );
  }, [currentEntity, checkSimulatable, isFitAdmin, scormContexts]);

  return (
    <Section title={i18n.t('User Progress Records')} type="list">
      {activeTab === "scorm" ? scormContextsElement : activitiesElement}
    </Section>
  );
};

Content.propTypes = {
  activeFilters: PropTypes.object,
  activeTab: PropTypes.string,
  availableBusinessLines: PropTypes.array,
  businessLines: PropTypes.string,
  contentServiceUrl: PropTypes.string,
  currentEntity: PropTypes.object,
  currentViewer: PropTypes.shape({
    "is_manager?": PropTypes.bool,
    "is_scorm_manager?": PropTypes.bool,
    rule_sets: PropTypes.array
  }),
  isFitAdmin: PropTypes.bool,
  jwt: PropTypes.string,
  loadingProgress: PropTypes.bool,
  onFilterChange: PropTypes.func,
  organizationId: PropTypes.string,
  permissions: PropTypes.array,
  scormContexts: PropTypes.array,
  selectedBusinessLine: PropTypes.string,
  trainingPeriods: PropTypes.array,
  unlimitedAccess: PropTypes.bool,
  user: PropTypes.shape({
    business_lines: PropTypes.arrayOf(PropTypes.string),
    "is_learner?": PropTypes.bool
  }),
  userAssignments: PropTypes.array,
  userProgresses: PropTypes.array,
  viewer: PropTypes.string
};

export default Content;
