import React, { Component } from 'react';
import i18n from 'lib/i18n';

export default class OrgNotFound extends Component {

  goBack() {
    history.back(-1);
  }
  render(){
    const styles = {
      dialogStyle: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "65vh"
      },
      titleStyle: {
        width: "35vw",
        fontSize: "24px",
        color: "#F36E3F"
      },
      listItemStyle: {
        paddingTop: "10px"
      },
      linkStyle: {
        textDecoration: "underline",
        color: "#F36E3F",
        marginTop: "10px",
        fontSize: "16px"
      },
      listStyle: {
        listStyle: "none",
        paddingLeft: "0px",
        width: "35vw"
      }
    }

    return (
      <div style={styles.dialogStyle} className="dialog">
        <h4 style={styles.titleStyle}>{i18n.t("Oops, ...we can't find the page you were looking for.  Here are some links that might help:")}</h4>
        <ul style={styles.listStyle}>
          <li style={styles.listItemStyle}><a style={styles.linkStyle} href="#" onClick={this.goBack}>{i18n.t('Previous Page')}</a></li>
          <li style={styles.listItemStyle}><a style={styles.linkStyle} href="https://www.everfi.com">{i18n.t('EverFi Homepage')}</a></li>
        </ul>
      </div>
    )
  }
}
