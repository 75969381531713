import { createEntity, selectEntity, pollUrl, displayError, displayErrorMessage, displaySuccessMessage } from 'reactifi';
import { readEndpoint } from 'redux-json-api';
import i18n from 'lib/i18n';

export { clearMessages, displayErrorMessage, displaySuccessMessage } from 'reactifi';

export function createChartContext(chart) {
  return createEntity(chart);
}

export function selectDataExport(itemId) {
  return selectEntity(itemId, 'data_exports');
}

export function createDataExport(item) {
  return async (dispatch) => {
    try {
      const result = await dispatch(createEntity(item, null, ""));
      
      if (result && result.data) {
        dispatch(selectDataExport(result.data.id));
      }
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}

function pollingStatusSwitch(dispatch, result) {
  switch (result.data.attributes.status) {
    case 'error':
      dispatch(displayErrorMessage(i18n.t("Bulk data could not be generated.")));
      break;
    case 'completed_without_download':
      dispatch(displayErrorMessage(i18n.t("Not enough data to generate report.")));
      break;
    case 'completed':
      dispatch(displaySuccessMessage(i18n.t("Bulk data is ready to download.")));
      break;
  }
}

export function shortDataExportPoll(dataExportId) {
  return async (dispatch) => {
    return pollUrl({
      interval: 5000, 
      max: 30000, 
      getData: async () => await dispatch(readEndpoint(`data_exports/${dataExportId}`)),
      checkComplete: (result) => !['pending', 'generating'].includes(result.data.attributes.status),
      onFinish: (result) => pollingStatusSwitch(dispatch, result),
      onTimeout: () => {}
    })
  }
}

export function longDataExportPoll(dataExportId) {
  return async (dispatch) => {
    return pollUrl({
      interval: 120000, 
      max: 600000,
      getData: async () => await dispatch(readEndpoint(`data_exports/${dataExportId}`)),
      checkComplete: (result) => !['pending', 'generating'].includes(result.data.attributes.status),
      onFinish: (result) => pollingStatusSwitch(dispatch, result),
      onTimeout: () => { dispatch(displayErrorMessage(i18n.t("It\'s taking longer than expected to generate your report. Please check your email."))) }
    });
  }
}
