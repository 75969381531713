import { selectEntity, updateEntity, createEntity, readEntity, readEntityById, displayError, deleteEntity, clearMessages } from 'reactifi';
import i18n from 'lib/i18n'
export { clearMessages, displaySuccessMessage } from 'reactifi';

export function selectLocation(locationId) {
  return (dispatch) => {
    dispatch(clearMessages());
    dispatch(selectEntity(locationId, 'locations'));
  }
}

export function findLocation(locationId) {
  return readEntityById(locationId, 'locations');
}

export function readLocations(includes, filters, sort, page, searchValue) {
  if (!sort) {
    sort = { value: "external_id", direction: "asc" };
  }
  const query = searchValue ? {
    name: {
      term: searchValue,
      fields: {
        address_formatted: 'word_start',
        external_id: 'word_start',
        name: 'word_start',
        street_number: 'word_start',
        route: 'word_start',
        city: 'word_start',
        state: 'word_start',
        zip: 'word_start'
      }
    }
  } : null;
  return readEntity('locations', includes, filters, sort && [sort], page, query);
}

export function updateLocation(location) {
  return updateEntity(location, (dispatch) => {
    dispatch(selectEntity(null, 'locations'));
  }, "Location updated successfully");
}

export function deleteLocation(location) {
  return deleteEntity(location, 'locations', i18n.t('Location deleted successfully.'))
}

export function createLocation(location, addAnother) {
  return createEntity(location, (dispatch) => {
    //do it this way so the modal unloads and reloads, which makes sure the form clears
    dispatch(selectEntity(null, 'locations'));
    if (addAnother) {
      dispatch(selectEntity('add', 'locations'));
    }
  }, "Location created successfully");
}

export function loadOrganization(id) {
  return readEntityById(id, 'organization');
}

export function uploadError(response) {
  return displayError(response);
}
