import React from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { Link } from 'react-router';
import { ConstructionCard, Form, FormField, FieldGroup, MaterialCheckbox, SearchComponent, CheckboxGroupControl } from 'reactifi';

const OrgLanguagesCard = props => {
  const {
    canEditCapabilities,
    clearLanguages,
    currentOrganization,
    onSaveLanguages,
    onCancelLanguages,
    languages,
    languageCount,
    languageChanged,
    handleLanguageSearch
  } = props;

  const buttonGroupClass = canEditCapabilities() ? 'form-controls-container row text-end' : 'hidden';

  const updateLanguages = () => {
    onSaveLanguages(currentOrganization);
  }

  return (
    <ConstructionCard
      alwaysExpanded={true}
      title={i18n.t('Languages')}
      dataObjectType="languages"
      className="m-b-30"
      cardContentClasses="w-100"
    >
      <Form
        buttonGroupClass={buttonGroupClass}
        data={currentOrganization}
        buttonStyle="primary"
        addAction={updateLanguages}
        updateAction={updateLanguages}
        onCancel={onCancelLanguages}
        cancelButton={canEditCapabilities()}
        saveButton={canEditCapabilities()}
        saveButtonAreaClassName="form-buttons-container-right"
        saveButtonDataAction="save-languages"
        disableButtons={false}
      >
        <FieldGroup>
          <FieldGroup className="language-selector-container">
            <SearchComponent
              name="language_search"
              placeholder={i18n.t('Search')}
              searchAction={handleLanguageSearch}
            />
            <div className="text-with-margin">
              {i18n.t('%d Selected', {
                postProcess: 'sprintf',
                sprintf: [languageCount]
              })}
              {canEditCapabilities() && (
                <Link onClick={clearLanguages} className="pull-right">
                  {i18n.t('Clear')}
                </Link>
              )}
            </div>
            <FormField
              name="language_codes"
              className="bordered-input-container"
              disabled={!canEditCapabilities()}
            >
              {languages && languages.length ? (
                <CheckboxGroupControl
                  className="input-container scrollable-input-container"
                  valueList={languages}
                  valueKey="code"
                  labelKey="name"
                  valueOverride={true}
                  useCheckboxValue={true}
                  onChange={languageChanged}
                  CheckboxComponent={MaterialCheckbox}
                  dataObjectType="organizations"
                  dataObjectAttribute="language_codes"
                  dataObjectAction="select-language-capability"
                />
              ) : (
                <div className="input-container text-with-margin">
                  {i18n.t('No results')}
                </div>
              )}
            </FormField>
          </FieldGroup>
        </FieldGroup>
      </Form>
    </ConstructionCard>
  );
};

OrgLanguagesCard.propTypes = {
  canEditCapabilities: PropTypes.func,
  clearLanguages: PropTypes.func,
  currentOrganization: PropTypes.object,
  handleLanguageSearch: PropTypes.func,
  languages: PropTypes.array,
  languageCount: PropTypes.number,
  languageChanged: PropTypes.func,
  onCancelLanguages: PropTypes.func,
  onSaveLanguages: PropTypes.func
};

export default OrgLanguagesCard;
