import React from 'react';
import PropTypes from 'prop-types';
import { App } from 'reactifi';
import InterstitialContainer from './containers/InterstitialContainer';

const index = (props, _railsContext) => {
  return (
    <App ruleSets={props.rule_sets} {..._railsContext}>
      <InterstitialContainer path="/" />
    </App>
  );
}

index.propTypes = {
  rule_sets: PropTypes.object
};

export default index;
