export const getCategoryLabels = (categoriesArr, categoryId) => {
  const category = categoriesArr.find(category => categoryId === category.id);

  return category.category_labels.map(categoryLabel => {
    return {
      label: categoryLabel.name,
      value: categoryLabel.id
    };
  });
};

export const getCategoryByLabelId = (categoriesArr, categoryLabelId) => {
  const categories = categoriesArr.filter(category => {
    const labels = category.category_labels.map(
      label => parseInt(label.id, 10)
    );
    return labels.indexOf(categoryLabelId) !== -1;
  });

  return categories.length
    ? {
      label: categories[0].name,
      value: categories[0].id,
      value_label: getCategoryLabels(categoriesArr, categories[0].id).filter(
        category_label => String(category_label.value) === String(categoryLabelId)
      )[0].label
    }
    : {};
};
