import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FloatingLabelFormField, FieldGroup, SelectAsyncControl, SelectControl } from 'reactifi';
import i18n from 'lib/i18n';

export default class CategoryLabelComponent extends Component {
  static propTypes = {
    businessLine: PropTypes.string,
    categoryLabels: PropTypes.arrayOf(PropTypes.object),
    data: PropTypes.shape({
      category_id: PropTypes.string,
      labels: PropTypes.arrayOf(PropTypes.object)
    }),
    disabled: PropTypes.bool,
    onLabelsChange: PropTypes.func,
    organizationId: PropTypes.string,
    runValidation: PropTypes.bool,
    selectionIds: PropTypes.arrayOf(PropTypes.number),
    onCategoryUpdate: PropTypes.func
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedCategoryId: this.props.data.category_id,
      selectedLabels: this.props.data.labels || []
    };
  }

  getCategoryLabels = () => {
    const { selectedCategory } = this.state;
    return selectedCategory?.category_labels || selectedCategory?.data?.category_labels || [];
  }

  handleChangeCategory = selectedCategory => {
    this.props.onCategoryUpdate(this.state.selectedCategory?.id, selectedCategory?.id);
    this.setState({ selectedCategory });
    if(!selectedCategory){
      this.handleChangeLabels([]);
    }
  }

  handleChangeLabels = selected => {
    const selectedLabels = selected.map(label => label.id);
    this.setState({ selectedLabels }, () => {
      this.props.onLabelsChange(this.state.selectedLabels, selected, this.state.selectedCategoryId);
    });
  }

  getData = () => {
    const { categoryLabels, data } = this.props;
    if (!data?.labels?.length) return null;

    return { 
      name: data.labels[0].category_name, 
      id: data.labels[0].category_id, 
      data: { category_labels: categoryLabels.filter(label => String(label.category_id) === String(this.state.selectedCategoryId)) } 
    };
  }

  render() {
    const { data, disabled, organizationId, businessLine, selectionIds } = this.props;
    const categoryURL = `/api/data/categories.json?filter[organization_id]=${organizationId}&filter[business_line]=${businessLine}&include=category_labels`;

    return (
      <FieldGroup className="row" data={data}>
        <FieldGroup className="col-5">
          <FloatingLabelFormField disabled={disabled} name="category_id" caption={i18n.t('Select Custom Category')}>
            <SelectAsyncControl
              dataUrl={categoryURL}
              displayProperty="name"
              filterField="name"
              labelKey="display"
              initialData={this.getData()}
              queryFields={{ name: 'word_start' }}
              onChange={this.handleChangeCategory}
              pageSize={20}
              placeholder=""
              sortBy="name"
              valueOverride={false}
              disabledOptionsIds={selectionIds}
            />
          </FloatingLabelFormField>
        </FieldGroup>
        <FieldGroup className="col-7">
          <FloatingLabelFormField name="labels" caption={i18n.t('Select Labels')} disabled={disabled || !this.state.selectedCategory} required={!disabled ? this.state.selectedCategory : false} runValidation={this.props.runValidation}>
            <SelectControl multi={true} options={this.getCategoryLabels()} onChange={this.handleChangeLabels} valueKey="id" labelKey="name" />
          </FloatingLabelFormField>
        </FieldGroup>
      </FieldGroup>
    );
  }
}
