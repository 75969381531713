import PropTypes from 'prop-types';
import React from 'react';
import { RootProvider } from 'reactifi';
import { Router, Route, hashHistory } from 'react-router';
import { syncHistoryWithStore } from 'react-router-redux';
import { setEndpointPath } from 'redux-json-api';

import createStore from './store/dataIntegrationStore';

import DataIntegrationContainer from './containers/DataIntegrationContainer';
// import IdentityProvidersContainer from './containers/IdentityProvidersContainer';
// import IdentityProviderContainer from './containers/IdentityProviderContainer';


let index = (props, _railsContext) => {
  const store = createStore(props || {});
  const history = syncHistoryWithStore(hashHistory, store);
  store.dispatch(setEndpointPath('api/data/doorkeeper'));

  return (
    <RootProvider store={store}>
      <Router onUpdate={() => window.scrollTo(0, 0)} history={history}>
        {/* <Route path="/:id/edit" component={IdentityProviderContainer} />
        <Route path="/add" component={IdentityProviderContainer} />
        <Route path="/:id" component={IdentityProviderContainer} /> */}
        <Route path="/" component={DataIntegrationContainer} organization_id={props.organization_id} />
      </Router>
    </RootProvider>
  );
}

index.propTypes = {
  organization_id: PropTypes.string
}

export default index;