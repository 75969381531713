import React from 'react';
import { ActionButtons, PagingDataList, ExpandableList } from 'reactifi';
import PropTypes from "prop-types";
import i18n from 'lib/i18n'

export default class TeamTable extends React.Component {
  static propTypes = {
    fieldsProps: PropTypes.object
  };

  mapManagers = (managers) => {
    return managers ? managers.map((manager) => ({ label: manager.name })) : [];
  }

  get actionConfig() {
    return [
      {
        icon: "eye",
        title: "",
        tooltip: "Team Details",
        type: "view",
        actionType: "callToAction",
        action: this.viewAction
      },
      {
        icon: "pen",
        title: "",
        tooltip: "Edit Team",
        type: "edit",
        actionType: "callToAction",
        action: this.editAction
      }
      // Temporary hide for ECA-6013
      // {
      //   icon: "trash",
      //   title: "",
      //   tooltip: "Delete Team",
      //   type: "delete",
      //   actionType: "callToAction",
      //   action: this.deleteAction
      // }
    ];
  }

  viewAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.fieldsProps.onView(row);
  }

  editAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.fieldsProps.onEdit(row);
  }

  // Temporary hide for ECA-6013
  // deleteAction = (e, row) => {
  //   e.preventDefault();
  //   e.stopPropagation();
  //   this.props.fieldsProps.onDelete(row);
  // }

  getFields = () => {
    return [
      { name: 'name', title: i18n.t('Team Name'), canSort: true, defaultSort: true },
      { name: 'description', title: i18n.t('Description'), canSort: true },
      {
        name: 'current_managers', title: i18n.t('Managers'), disableDefaultBehavior: true, formatter: (cell, _row) => {
          const managerList = this.mapManagers(cell);
          return managerList.length ? <ExpandableList data={managerList} className="truncated-list" /> : null;
        }
      },
      { name: 'active_members_count', title: i18n.t('Active Users') },
      { name: 'actions', title: i18n.t('Actions'), width: 110,
        formatter: (_cell, row) => {
          return (
            row && (
              <ActionButtons
                row={Object.assign({}, row, {
                  can_edit: true,
                  can_delete: true
                })}
                buttons={this.actionConfig}
              />
            )
          );
        } 
      }
    ];
  }

  render() {
    const { fieldsProps, ...props } = this.props;

    return <PagingDataList {...props} fields={this.getFields(fieldsProps)} keyField="id" />;
  }
}
