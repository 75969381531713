export const LOB_CAPABILITIES_MAP = Object.freeze([
  { id: 'corporate-compliance', capability: 'Capabilities::CC' },
  { id: 'higher-education', capability: 'Capabilities::HE' },
  { id: 'faculty-staff', capability: 'Capabilities::FacultyStaff' },
  { id: 'at-work', capability: 'Capabilities::AtWork' },
  { id: 'event-tracking', capability: 'Capabilities::EventManagement' },
  { id: 'k12', capability: 'Capabilities::K12' }
]);

export const getBusinessLinesFromCapabilities = (capabilities) => {
  return LOB_CAPABILITIES_MAP.filter(mapping => (
    capabilities.find(capability => (
      capability.capability_type === mapping.capability
    ))
  )).map(mapping => mapping.id);
}
