import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { JsonApiDecorator } from 'reactifi';

import Charts from '../components/Charts';
import * as actionCreators from '../actions/chartsActionCreators';

function mapStateToProps(state, ownProps) {
  let apiStore = new JsonApiDecorator({ contexts: ownProps.route.contexts });

  return {
    contexts: apiStore.contexts ? apiStore.contexts.all() : [],
    orgCapabilities: ownProps.route.orgCapabilities
  };
}

class ChartsContainer extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.actions = bindActionCreators(actionCreators, this.props.dispatch);
  }

  render() {
    return <Charts {...this.props} {...this.actions} />;
  }
}

export default connect(mapStateToProps)(ChartsContainer);
