import React, { Component } from "react";
import PropTypes from "prop-types";
import { ActionButtons, FAIcon, PaginationModule } from "reactifi";

import i18n from "lib/i18n";

export default class CategoriesList extends Component {
  static propTypes = {
    activePage: PropTypes.number,
    categoriesMeta: PropTypes.object,
    createCategory: PropTypes.func,
    data: PropTypes.array,
    deleteAction: PropTypes.func,
    editAction: PropTypes.func,
    onPageChange: PropTypes.func
  };

  getActionConfig() {
    const { editAction, deleteAction } = this.props;

    return [
      {
        icon: 'pen',
        title: i18n.t('Edit'),
        tooltip: '',
        type: 'edit',
        actionType: 'callToAction',
        action: editAction
      },
      {
        icon: 'trash',
        title: i18n.t('Delete'),
        tooltip: '',
        type: 'delete',
        actionType: 'callToAction',
        action: deleteAction
      }
    ];
  }

  getPostCard = () => {
    return (
      <div className="card dashed-border no-background foundry-secondary-color clickable create-category-card" onClick={this.props.createCategory}>
        <div className="card-body h3 text-center">
          <div className="cg-title">
            <FAIcon icon="circle-plus" size="medium" additionalClasses="p-b-5" />
            <div className="h4">{i18n.t("Create New Custom Category")}</div>
          </div>
        </div>
      </div>
    )
  }

  render() {
    const { activePage, data, categoriesMeta, onPageChange } = this.props
    return (
      <>
        <PaginationModule
          activePage={activePage}
          collection={data}
          collectionMeta={categoriesMeta}
          onPageChange={onPageChange}
          resultsDisplayed={false}
        >
          <div className="custom-groupings">
            {data.map((category) => {
              const userAssignedToLabels = category?.category_labels?.reduce((acc, value) => acc + value.users_count, 0) || 0;
              return (
                <div className="card" key={category.id}>
                  <div className="card-body card-padding h3 text-center">
                    <div className="cg-title">
                      <FAIcon icon="ball-pile" size="medium" additionalClasses="p-b-5" />
                      <div className="title">{category?.name}</div>
                    </div>
                  </div>
                  <div className="card-footer">
                    <ActionButtons
                      row={Object.assign({}, category, {
                        can_edit: true,
                        can_delete: userAssignedToLabels <= 0
                      })}
                      buttons={this.getActionConfig()}
                    />
                  </div>
                </div>
              );
            })}
            {this.getPostCard()}
          </div>
        </PaginationModule>
      </>
    );
  }
}
