import { baseEndpointPath } from 'reactifi';

const config = props => {
  const { organizationId } = props;
  return {
    entity: 'uploaded_reports',
    basePath: `${baseEndpointPath}/organizations/${organizationId}`
  };
}

export default config;
