import React from 'react';
import PropTypes from 'prop-types';
import { Section, ListModule, PagingDataList } from 'reactifi';
import isPlainObject from 'lodash/isPlainObject';
import i18n from 'lib/i18n';
import { Util } from "@everfi/reactifi"

const LOG_PAGE_SIZE = 20;

const OrgUserChangelog = props => {
  const { activePage, authors, changelog, changelogMeta, isLoadingData, onPageChange } = props;

  const changelogObjectToTableData = entry => {
    return Object.keys(entry).map(change => ({
      field: change,
      original: entry[change] && entry[change].original,
      new: entry[change] && entry[change].new
    }));
  }

  const changeValueFormatter = value => {
    const noValueLabel = <i>{i18n.t('none')}</i>;

    if (isPlainObject(value)) {
      if (!Object.keys(value).length) {
        return noValueLabel;
      }

      return (
        <ListModule
          collection={changelogObjectToTableData(value)}
          fields={[
            { name: 'field', title: i18n.t('Field') },
            { name: 'new', title: i18n.t('Value'), formatter: changeValueFormatter }
          ]}
          resultsDisplayed={false}
        />
      );
    } else if (Array.isArray(value)) {
      return value.length ? value.join(', ') : noValueLabel;
    }

    return value || noValueLabel;
  }

  const fields = [
    {
      name: 'created_at',
      title: i18n.t('Created At'),
      formatter: cell => Util.format(cell, 'MM/dd/yyyy h:mm a')
    },
    { name: 'operation_type', title: i18n.t('Type') },
    {
      name: 'author_id',
      title: i18n.t('Author'),
      formatter: cell => {
        if (!cell) return null;

        const author = authors.find(author => author.id === cell);
        return author ? `${author.first_name} ${author.last_name}` : null;
      }
    },
    {
      name: 'changes',
      title: i18n.t('Changes'),
      width: '70%',
      fitContent: true,
      formatter: cell => (
        <ListModule
          collection={changelogObjectToTableData(cell)}
          fields={[
            { name: 'field', title: i18n.t('Field'), width: '20%' },
            { name: 'original', title: i18n.t('Old Value'), formatter: changeValueFormatter },
            { name: 'new', title: i18n.t('New Value'), width: '50%', formatter: changeValueFormatter }
          ]}
          resultsDisplayed={false}
        />
      )
    }
  ];

  return (
    <Section type="list">
      <PagingDataList
        key={authors}
        data={changelog}
        fields={fields}
        keyField="id"
        isLoadingData={isLoadingData}
        onPageChange={onPageChange}
        totalCount={changelogMeta ? changelogMeta.total_count : 0}
        activePage={activePage}
        pageSize={LOG_PAGE_SIZE}
      />
    </Section>
  );
}

OrgUserChangelog.propTypes = {
  activePage: PropTypes.number,
  authors: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    first_name: PropTypes.string,
    last_name: PropTypes.string
  })).isRequired,
  changelog: PropTypes.arrayOf(PropTypes.shape({
    author_id: PropTypes.string,
    author_type: PropTypes.string,
    changes: PropTypes.object,
    created_at: PropTypes.string,
    operation_type: PropTypes.string
  })).isRequired,
  changelogMeta: PropTypes.shape({
    total_count: PropTypes.number
  }),
  isLoadingData: PropTypes.bool,
  onPageChange: PropTypes.func
};

export default OrgUserChangelog;
