import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Button, FAIcon } from 'reactifi';
import i18n from 'lib/i18n';

export default class UploadConfirmationContainer extends Component {
  static propTypes = {
    router: PropTypes.object.isRequired,
    route: PropTypes.shape({
      left_text_button: PropTypes.string,
      left_url_button: PropTypes.string,
      right_text_button: PropTypes.string,
      right_url_button: PropTypes.string,
      buildLeftButtonUrl: PropTypes.func
    }),
    routeParams: PropTypes.shape({
      id: PropTypes.string
    })
  }

  onViewMyUploads = () => {
    this.props.router.replace("/");
  };

  getLeftButtonUrl = () => {
    const { route, routeParams } = this.props;

    if (route.left_url_button) {
      return route.left_url_button;
    }

    return route.buildLeftButtonUrl ? route.buildLeftButtonUrl(routeParams.id) : null;
  }

  render() {
    const { route } = this.props;

    return (
      <div className="page-template">
        <div className="confirmation-panel">
          <h3 className="confirmation-title-message">
            <span className="confirmation-icon">
              <FAIcon icon="circle-check" color="green" />
            </span>{i18n.t("We are processing your list!")}
          </h3>
          <p className="text-center">{i18n.t("We will notify you when processing is complete.")}</p>
          {route.left_text_button || route.right_text_button ?
            <div className="confirmation-buttons">
              {route.left_text_button && this.getLeftButtonUrl() ?
                <div className="pull-left">
                  <Button
                    label={i18n.t(`${route.left_text_button}`)}
                    icon="angle-left"
                    style="secondary"
                    data-action="back-to-manage-users"
                    href={this.getLeftButtonUrl()}
                  />
                </div>
                : null}
              {route.right_text_button ?
                <div className="pull-right">
                  <Button
                    label={i18n.t(`${route.right_text_button}`)}
                    style="primary"
                    data-action="view-my-uploads"
                    onClick={this.onViewMyUploads}
                  />
                </div>
                : null}
            </div>
            : null}
        </div>
      </div>
    )
  }
}
