import React from 'react';
import PropTypes from 'prop-types';
import { Header, Section, useContainerProps } from 'reactifi';
import i18n from 'lib/i18n';
import BenchmarksList from './BenchmarksList';

const Benchmarks = props => {
  props = useContainerProps(props);

  const button = {
    action: () => props.selectMember('add'),
    'data-action': 'add-benchmarking-attribute',
    label: i18n.t('Add Benchmarking Attribute'),
    icon: 'plus'
  };

  return (
    <>
      <Header button={button} title={i18n.t('Benchmark Attributes')} />
      <Section type="list">
        <BenchmarksList />
      </Section>
    </>
  );
};

Benchmarks.propTypes = {
  selectMember: PropTypes.func
};

export default Benchmarks;
