import React, { useCallback, useState } from "react";
import { ConfirmationModal, FAIcon } from "reactifi";
import i18n from "lib/i18n";

const useDeleteModalActions = props => {
  const {
    deleteUser,
    router,
    user
  } = props;

  const [showModal, setShowModal] = useState(false);

  const onCancel = useCallback(() => {
    setShowModal(false);
  }, []);

  const onConfirm = useCallback(async () => {
    await deleteUser(user);
    onCancel();
    router.goBack();
  }, [user]);

  const onDeleteUser = useCallback(() => {
    setShowModal(true);
  }, []);

  const modal = (
    <ConfirmationModal
      title={i18n.t('Delete User?')}
      show={showModal}
      onConfirm={onConfirm}
      onCancel={onCancel}
      confirmButtonText={i18n.t('Delete User')}
    >
      <div>{i18n.t('Are you sure you want to delete this user?')}</div>
      <div>{i18n.t('All records for this user will also be deleted. This includes, but is not limited to:')}</div>
      <ul>
        <li>{i18n.t('User Assignments')}</li>
        <li>{i18n.t('Progress Records')}</li>
        <li>{i18n.t('User Messages')}</li>
        <li>{i18n.t('Evaluation Responses')}</li>
      </ul>
      <div><FAIcon icon="triangle-exclamation" size="medium" color="yellow" />{i18n.t(' This action cannot be undone.')}</div>
    </ConfirmationModal>
  );
  
  return {
    onDeleteUser,
    modal
  };
};

export default useDeleteModalActions;
