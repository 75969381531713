import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { FloatingLabelFormField, SelectControl, AccessControlUtil, Button } from 'reactifi';
import i18n from 'lib/i18n';
import UploadStep from '../../common/Components/upload/UploadStep';

const ALT_LOGIN_CAPABILITY = 'Capabilities::AlternativeLogin';
const LOGIN_OPTIONS = [
  { name: 'email', label: i18n.t('Users Have Email') },
  { name: 'username', label: i18n.t('Users Do Not Have Email') }
];

export default class UploadInstructions extends UploadStep {
  static propTypes = {
    orgCapabilities: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props){
    super(props);

    this.state = {
      loginType: null
    };
  }

  isAltLoginEnabled = () => AccessControlUtil.hasCapability(ALT_LOGIN_CAPABILITY, this.props.orgCapabilities);

  onLoginTypeSelected = data => {
    this.setState({ loginType: data && data.name });
  }

  getTemplateUrl = () => {
    // these file live in /public
    return this.state.loginType === 'username' ?
      '/templates/Foundry_User_List_Template_Username.csv' :
      '/templates/Foundry_User_List_Template.csv';
  }

  renderBody = () => {
    let headerNumber = 1;

    return (
      <div>
        <h4>{headerNumber++}. {i18n.t('Download the User List Template')}</h4>
        {this.isAltLoginEnabled() && (
          <Fragment>
            <div>
              {i18n.t('Select the login option in order to download the appropiate template.')}
            </div>
            <div className="row">
              <div className="col-sm-6">
                <FloatingLabelFormField
                  name="login_option"
                  caption={i18n.t('Select Whether Users Have Email or Not?')}
                  required={true}
                  className="col-half-width m-b-0"
                >
                  <SelectControl
                    multi={false}
                    onChange={this.onLoginTypeSelected}
                    options={LOGIN_OPTIONS}
                    valueKey="name"
                    labelKey="label"
                  />
                </FloatingLabelFormField>
              </div>
            </div>
          </Fragment>
        )}
        <div className="row">
          <div className="col-sm-6">
            <Button
              href={this.getTemplateUrl()}
              data-action="download-template"
              className="template-button"
              style="secondary"
              download={true}
              disabled={this.isAltLoginEnabled() && !this.state.loginType}
              label={i18n.t("Download Template")}
              icon="down-to-line"
            />
          </div>
        </div>
        <h4>{headerNumber++}. {i18n.t('Insert User Data into the User List Template')}</h4>
        <div>
          {i18n.t('Fill in the template with either email addresses or usernames depending on the template you downloaded in the previous step. The emails or usernames must match the existing users.')}
        </div>
        <h4>{headerNumber++}. {i18n.t('Save file as .CSV')}</h4>
      </div>
    );
  };
}
