import { hasCapability } from 'reactifi/dist/es/utils/AccessControlUtil';
import { getCurrentRuleSetName } from 'reactifi/dist/es/utils/UrlUtil';

const ALLOWED_RULE_SETS = ['he_admin', 'cc_admin', 'fac_staff_admin'];

export function isBulkAwardsDownloadEnabled(orgCapabilities, isPrimaryAdmin, currentViewer) {
  const isLegacy = hasCapability('Capabilities::LegacyAssignments', orgCapabilities);
  const currentRuleSet = getCurrentRuleSetName();

  let isRoleBased = false;
  if (currentRuleSet) {
    isRoleBased = ALLOWED_RULE_SETS.includes(currentRuleSet);
  } else {
    // if role-based nav is enabled but the user has only one role, we get undefined as the current RS
    const viewerRuleSets = currentViewer ? currentViewer.rule_sets.map(rs => rs.name) : [];
    isRoleBased = viewerRuleSets.length > 0 && ALLOWED_RULE_SETS.includes(viewerRuleSets[0]);
  }

  return !isLegacy && isPrimaryAdmin && isRoleBased;
}
