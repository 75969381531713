import React from "react";
import PropTypes from "prop-types";
import {
  FieldGroup,
  FloatingLabelFormField,
  Form
} from "reactifi";
import i18n from "lib/i18n";

import * as DataTypeComponents from './dataTypes';

export default class DownloadOptionsStep extends React.Component {
  static propTypes = {
    cancelComplete: PropTypes.func.isRequired,
    canceling: PropTypes.bool,
    createDataExport: PropTypes.func.isRequired,
    dataExport: PropTypes.shape({ data_type: PropTypes.string }),
    displayErrorMessage: PropTypes.func.isRequired,
    goingDirection: PropTypes.string,
    goingToStep: PropTypes.number,
    stepChangeCancel: PropTypes.func.isRequired,
    stepChangeComplete: PropTypes.func.isRequired
  }

  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate(prevProps) {
    if ([null, undefined].includes(prevProps.goingToStep) &&
      prevProps.goingToStep !== this.props.goingToStep) {
      if (this.props.goingDirection === 'forward') {
        this.setState({ saveClicked: true });
      } else {
        this.props.stepChangeComplete();
      }
    }
    if (this.props.canceling && !prevProps.canceling) {
      this.handleCancel();
    }
  }

  handleCancel = () => { this.props.cancelComplete(); }

  saveComplete = (formValidated) => {
    const { stepChangeCancel } = this.props;
    if (!formValidated){
      stepChangeCancel();
    }
    this.setState({ saveClicked: false });
  }

  renderDataTypeComponent = (dataTypeKey) => {
    const dataType = dataTypeKey.replace('Datafi::Exports::', '');
    const DataTypeComponent = DataTypeComponents[dataType];

    if (!DataTypeComponent) {
      return null;
    }
    return (<DataTypeComponent {...this.props} />);
  }

  renderForm = () => {
    const { createDataExport, dataExport } = this.props;

    return (<Form data={dataExport}
      addAction={createDataExport}
      hasFormButtons={false}
      saveComplete={this.saveComplete}
      triggerSave={this.state.saveClicked}
    >
      <FieldGroup className="row">
        <FloatingLabelFormField
          className="col-12"
          caption={i18n.t("File Name")}
          name="name"
          required={true} />
      </FieldGroup>
      {this.renderDataTypeComponent(dataExport.data_type)}
    </Form>);
  }

  render() {
    const { dataExport } = this.props;
    if (!dataExport.data_type) {
      return null;
    }

    return (<React.Fragment>
      <div className="info-panel">{i18n.t("Select from the options below to customize your data download.")}</div>
      {this.renderForm()}
    </React.Fragment>);
  }
}
