import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import {
  FieldGroup,
  FileUpload,
  s3MediaUploader,
  Util
} from 'reactifi';

export default class BrandingBackgroundWell extends Component {
  static propTypes = {
    backgroundImg: PropTypes.object,
    backgroundImgUrl: PropTypes.string,
    currentOrganization: PropTypes.object,
    deleteAction: PropTypes.func,
    displayError: PropTypes.func,
    onBackgroundImageUpload: PropTypes.func
  }

  render() {
    let { backgroundImg, backgroundImgUrl, currentOrganization } = this.props;
    return (
      <FieldGroup className="branding-well-container" data={currentOrganization} data-action="background-image-card">
        <FieldGroup className="row">
          <FileUpload
            accept={['.jpg', '.png', '.gif', '.jpeg']}
            actions={{
              delete: this.props.deleteAction,
              failure: this.props.displayError,
              success: this.props.onBackgroundImageUpload
            }}
            button={{
              className: 'btn btn-secondary',
              dataAttributes: Util.dataAttribsForEl(currentOrganization, { action: 'upload-image' }),
              text: i18n.t('Upload Image')
            }}
            className="file-upload-inline"
            deleteButton={true}
            deleteButtonDataAttributes={Util.dataAttribsForEl(backgroundImg, { action: 'delete-image' })}
            description={{
              textArray: [i18n.t('Image format: JPG, PNG or GIF')]
            }}
            embedPreviewUrl={backgroundImgUrl}
            header={{ text: i18n.t('Background Image') }}
            icon={{
              className: 'images',
              iconSize: 'medium',
              fileClassName: 'file',
              noFileClassName: 'file-slash'
            }}
            input={{ name: 'background_image' }}
            instructionalText={i18n.t("Uploaded image will appear in the background on the customer's login page. Max width 3000px, minimum 480px. It will be formatted to fit any browser screen, but will usually appear in a 4:3 (laptop) or 16:9 ratio (widescreen monitor), except for mobile logins which can range from 3:2 or 7:1 depending on the device.")}
            label={{ text: backgroundImg.name, closable: false }}
            name="background_image"
            uploader={s3MediaUploader}
          />
        </FieldGroup>
      </FieldGroup>
    )
  }
}
