import { addDays } from 'date-fns';

export function convertCustomFilters(customFilters, encode = true, transformValues = true, isCreate = true) {
  const newFilters = {};

  if (!customFilters) return;

  if (transformValues) fillLearnerStatuses(customFilters);

  Object.entries(customFilters).forEach(([key, value]) => {
    if (value === undefined || value === null) return;

    let filterValue;
    if (transformValues) {
      // apply desired transformations to the output value from the FilterBar
      filterValue = getFilterValue[key] ? getFilterValue[key](value) : getFilterValue.generic(value);
    } else {
      // do not transform input values
      filterValue = value;
    }

    // apply searchkick transformations if available
    if (addSearchKickValue[key]) {
      addSearchKickValue[key](filterValue, newFilters, isCreate);
    } else {
      newFilters[key] = filterValue;
    }
  });

  return encode ? btoa(JSON.stringify(newFilters)) : newFilters;
}

const fillLearnerStatuses = customFilters => {
  // if it's a learner status, we need to remove the key and add it to the learner_statuses object
  Object.keys(customFilters).forEach(key => {
    if (['progress', 'participation', 'past_due', 'assignmentId'].includes(key)) {
      customFilters.learner_statuses = Object.assign({}, customFilters.learner_statuses, {
        [key]: customFilters[key]
      });

      delete customFilters[key];
    }
  });
}

const getFilterValue = {
  rule_set_roles: value => value.flatMap(rs => Array.isArray(rs.values) ? rs.values.map(role => ({ ruleSetId: rs.id, roleSlug: role.id })) : rs),
  category_label_ids: value => {
    const filterObj = value.reduce((acc, category) => {
      acc[category.id] = category.values.map(val => val.id);
      return acc;
    }, {});
    return filterObj;
  },
  completed_content: value => {
    let completedContent = {};
    if (Array.isArray(value)) {
      completedContent = {
        content_ids: value.map(course => typeof course === 'object' ? course.id : course)
      };
    } else {
      completedContent = value;
    }

    return completedContent;
  },
  business_lines: value => value.map(val => val.slug || val),
  learner_statuses: value => {
    const { participation, progress, past_due, assignmentId } = value;
    const newLearnerStatuses = Object.assign({}, value);

    if (past_due) newLearnerStatuses.past_due = past_due.id || past_due;
    if (progress && progress.length) newLearnerStatuses.progress = progress.map(pg => pg.id || pg);
    if (participation && participation.length) newLearnerStatuses.participation = participation.map(ps => ps.id || ps);
    if (assignmentId) newLearnerStatuses.assignmentId = assignmentId;

    return newLearnerStatuses;
  },
  created_at: value => value,
  assignment_ids: value => value,
  generic: value => Array.isArray(value) ? value.map(val => val.id || val) : value.id || value
};

const addSearchKickValue = {
  category_label_ids: function (value, newFilters) {
    const request = Object.keys(value).reduce((requestValues, categoryName) => {
      const categoryRequest = value[categoryName].map(val => ({ category_label_ids: [val] }));
      requestValues.push({ category_label_ids: { ":_or": categoryRequest } });
      return requestValues;
    }, []);
    newFilters.category_label_ids = { ':_and': request };
  },
  completed_content: function (value, newFilters) {
    const { content_ids, intersession, intersession_days } = value;
    let hasIntersession = intersession_days && intersession;

    if (content_ids) {
      if (content_ids.length === 1) {
        newFilters.nested = this.buildContentFilter(content_ids[0], hasIntersession, intersession_days)
      } else {
        const completedContentFilter = content_ids.reduce((filter, content_id) => {
          filter.push({ nested: this.buildContentFilter(content_id, hasIntersession, intersession_days) });
          return filter;
        }, []);
        newFilters[':_and'] = completedContentFilter;
      }
    }
  },
  buildContentFilter: function (content_id, hasIntersession, intersession_days) {
    return {
      path: 'additional_search_data.completed_content',
      where: {
        content_id,
        ...(hasIntersession && { completed_at: { lte: `now-${intersession_days}d/d` } })
      }
    }
  },
  assignment_ids: function (value, newFilters) {
    if (!newFilters.additional_search_data) {
      newFilters.additional_search_data = {};
    }
    newFilters.additional_search_data.assignment_ids = value;
  },
  not_assignment_ids: function (value, newFilters) {
    if (!newFilters.additional_search_data) {
      newFilters.additional_search_data = {};
    }
    newFilters.additional_search_data.assignment_ids = { ':not': value };
  },
  not_assigned_content_ids: function (value, newFilters) {
    if (!newFilters.additional_search_data) {
      newFilters.additional_search_data = {};
    }
    newFilters.additional_search_data.assigned_content_ids = { ':not': value };
  },
  created_at: function (value, newFilters, isCreate) {
    const created_at = {};
    const { to_date, from_date } = value;
    if (to_date) {
      // we add one day to include "today" when selecting a date to
      if (isCreate) {
        created_at[':lte'] = addDays(new Date(to_date), 1);
      } else {
        created_at[':lte'] = new Date(to_date);
      }
    }
    if (from_date) {
      created_at[':gte'] = new Date(from_date);
    }

    newFilters.created_at = created_at;
  },
  rule_set_roles: function (value, newFilters) {
    const selectedRoles = value.map(({ ruleSetId, roleSlug }) => {
      return { rule_set_roles: { [ruleSetId]: roleSlug } };
    });
    this.addRuleSetRoles(selectedRoles, newFilters);
  },
  rule_sets: function (value, newFilters) {
    const selectedRuleSets = value.map(ruleSetId => ({ rule_set_roles: { [ruleSetId]: { ':not': null } } }));
    this.addRuleSetRoles(selectedRuleSets, newFilters);
  },
  addRuleSetRoles: function (ruleSetRoles, newFilters) {
    if (newFilters.rule_set_roles) {
      newFilters.rule_set_roles = {
        ':_and': [
          { ':_or': ruleSetRoles },
          newFilters.rule_set_roles
        ]
      }
    } else {
      newFilters.rule_set_roles = { ':_or': ruleSetRoles };
    }
  },
  learner_statuses: function (value, newFilters) {
    const { assignmentId, participation, progress, past_due } = value;
    if (!newFilters.additional_search_data) {
      newFilters.additional_search_data = {};
    }
    if (past_due) {
      newFilters.additional_search_data.assignments_progress_states = { 'completed': { ':not': assignmentId } };
      newFilters.additional_search_data.past_due_assignment_ids = [assignmentId];
    }
    if (participation && participation.length) {
      this.addStateFilter('participation', participation, assignmentId, newFilters);
    }
    if (progress && progress.length) {
      this.addStateFilter('progress', progress, assignmentId, newFilters);
    }
  },
  addStateFilter: function (state, values, assignmentId, newFilters) {
    if (values.length === 1) {
      newFilters.additional_search_data[`assignments_${state}_states`] = { [values[0]]: assignmentId }
    } else {
      const statesArray = values.map(val => {
        return { additional_search_data: { [`assignments_${state}_states`]: { [val]: assignmentId } } };
      });
      const statesFilter = { ':_or': statesArray };
      if (newFilters.additional_search_data[':_and']) {
        newFilters.additional_search_data[':_and'].push(statesFilter);
      } else {
        newFilters.additional_search_data[':_and'] = [statesFilter];
      }
    }
  }
};
