import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { RuleSetModal } from 'reactifi';

const AppElement = ({ description, href, id, image, ruleSets = [], showRuleSetModal, title }) => {
  const appElementId = `${id}-app-element`;
  const hasModal = showRuleSetModal && ruleSets.length > 1;
  const [showModal, setShowModal] = useState();
  const appHref = hasModal ?
    undefined :
    `${href}?current_rule_set_name=${ruleSets[0]}`;
  const appOnClick = hasModal ?
    () => setShowModal(true) :
    undefined;
    
  return (
    <>
      <div className="interstitial-app-element" id={appElementId} data-action={appElementId}>
        <a
          aria-describedby={appElementId}
          className="interstitial-app-element-link"
          href={appHref}
          onClick={appOnClick}
          tabIndex={0}
        >
          <img src={image} className="interstitial-app-element-image" alt="" />
        </a>
        <h2 className="interstitial-app-element-title">{title}</h2>
        <p className="interstitial-app-element-description">{description}</p>
      </div>
      {hasModal && <RuleSetModal
        href={href}
        onCancel={() => setShowModal()}
        ruleSets={ruleSets}
        show={showModal}
      />}
    </>
  );
};

AppElement.propTypes = {
  description: PropTypes.string,
  href: PropTypes.string,
  id: PropTypes.string,
  image: PropTypes.string,
  ruleSets: PropTypes.array,
  showRuleSetModal: PropTypes.bool,
  title: PropTypes.string
};

export default AppElement;
