import React from "react";
import PropTypes from "prop-types";
import {
  ModalForm,
  FieldGroup,
  FloatingLabelFormField,
  SelectControl
} from "reactifi";
import i18n from "lib/i18n";

export default class WebhookDomainForm extends React.Component {
  static propTypes = {
    currentDomain: PropTypes.object,
    applications: PropTypes.array,
    selectDomain: PropTypes.func.isRequired,
    createDomain: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    successMessage: PropTypes.string
  }

  onCancel = () => {
    this.props.selectDomain(null);
  }

  renderForm = () => {
    const { currentDomain, createDomain,
      errorMessage, successMessage, applications } = this.props;

    return (
      <ModalForm
        data={currentDomain}
        title={i18n.t("Create New Webhook Domain")}
        errorMessage={errorMessage}
        successMessage={successMessage}
        onCancel={this.onCancel}
        addAction={createDomain}
        saveAndAddButton={false}
      >
        <FieldGroup className="container-fluid">
          <div className="row m-b-10">
            <div className="col-12">
              <div>{i18n.t("The webhook domain is the starting point for webhooks. Enter the domain portion of your webhook URL. You will add the remainder of the URL path when you add a webhook for this domain. For example: integration.mydomain.com")}</div>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="application_id"
                caption={i18n.t("Registered API")}
                required={true}
              >
                <SelectControl options={applications} valueKey="id" labelKey="name" multi={false} placeholder=" "/>
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="name"
                caption={i18n.t("Webhook Domain Name")}
                required={true}
              />
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>);
  }

  render() {
    const {
      currentDomain
    } = this.props;

    if (!currentDomain) {
      return null;
    }
    return this.renderForm();
  }
}
