import i18n from 'lib/i18n';
import { buildGenericProps, JsonApiDecorator } from 'reactifi';
import { findOrganization, updateOrganization } from '../actions/tagManagerActions';

const config = {
  entity: 'tag_managers',
  selectMember: {
    updateRoute: true
  },
  createMember: {
    successMessage: i18n.t("Tag created successfully. Please allow for up to 5 minutes for changes to apply.")
  },
  updateMember: {
    successMessage: i18n.t("Tag updated successfully. Please allow for up to 5 minutes for changes to apply.")
  },
  deleteMember: {
    successMessage: i18n.t("Tag deleted successfully. Please allow for up to 5 minutes for changes to apply.")
  },
  actionCreators: {
    findOrganization,
    updateOrganization
  },
  mapStateToProps
};

function mapStateToProps(state) {
  const props = buildGenericProps(state, 'tag_managers');
  let apiStore = new JsonApiDecorator(state.api);

  if (apiStore.organizations) {
    props.organization = apiStore.organizations.first();
  }

  return props;
}

export default config;
