import React from 'react';
import PropTypes from 'prop-types';
import { CardActionBox, CardTitle, IconCategoryValueGroup, IconCategoryValue } from 'reactifi';
import i18n from 'lib/i18n';
import { Util } from "@everfi/reactifi"

const PARTICIPATION_STATUS = {
  selected: { label: i18n.t('Selected'), icon: 'clipboard-list' },
  assigned: { label: i18n.t('Assigned'), icon: 'clipboard-list' },
  unassigned: { label: i18n.t('Unassigned'), icon: 'clipboard' },
  closed: { label: i18n.t('Closed'), icon: 'clipboard-check' },
  excluded: { label: i18n.t('Excluded'), icon: 'square-exclamation' },
  error: { label: i18n.t('Excluded'), icon: 'square-exclamation' }
};

const PROGRESS_STATUS_LABEL = {
  not_started: i18n.t('Not Started'),
  in_progress: i18n.t('Started'),
  completed: i18n.t('Completed'),
  skipped: i18n.t('Skipped')
};

const OrgUserAssignmentPanel = props => {
  const { content, userAssignment, userAssignment: { assignment, participation_status, progress_status }, onReassign, onUnassign } = props;
  const participationStatus = participation_status && PARTICIPATION_STATUS[participation_status];
  const progressStatusLabel = progress_status && PROGRESS_STATUS_LABEL[progress_status];

  const getDueDateField = () => {
    const { due_on, progresses } = userAssignment;
    const { progressId } = props;
    const progress = progresses && progresses.find(prg => prg.id === progressId) || {};

    let label, value;
    if (progress_status === 'completed') {
      label = i18n.t('Completed On');
      value = progress.completed_at;
    } else {
      label = i18n.t('Due Date');
      value = due_on || progress.due_on;
    }

    return { label, value: Util.format(value, 'P') };
  }

  const renderContentList = () => {
    const { contents = [] } = assignment;
    const otherContents = contents.filter(cnt => cnt.id !== content.id);

    if (!otherContents.length) return null;

    return (
      <div className="learning-activities">
        <p className="title">{i18n.t('Other Learning Activities in this Assignment:')}</p>
        <IconCategoryValueGroup className="list">
          {otherContents.map(content => (
            <IconCategoryValue key={content.id} icon="rectangle-vertical-history" value={content.name} />
          ))}
        </IconCategoryValueGroup>
      </div>
    );
  }

  const renderActions = () => {
    if (!['assigned', 'unassigned'].includes(participation_status)) return null;

    const isAssigned = participation_status === 'assigned';
    const actionConfig = [{
      label: isAssigned ? i18n.t('Unassign') : i18n.t('Reassign'),
      style: 'secondary',
      icon: isAssigned ? 'box-archive' : 'square-left',
      action: isAssigned ? () => onUnassign(userAssignment) : () => onReassign(userAssignment),
      data: {
        id: userAssignment.id,
        objectType: 'assignments',
        action: isAssigned ? 'unassign' : 'reassign'
      }
    }];

    return <CardActionBox type="wide" buttons={actionConfig} className="assignment-actions" />;
  }

  const dueDateField = getDueDateField();

  return (
    <div className="org-user-assignment-panel">
      <CardTitle size={16}>{assignment.name}</CardTitle>
      <IconCategoryValueGroup className="assignment-info">
        <IconCategoryValue icon={participationStatus.icon} value={participationStatus.label} />
        <IconCategoryValue category={i18n.t('Status')} value={progressStatusLabel} />
        <IconCategoryValue category={dueDateField.label} value={dueDateField.value} />
      </IconCategoryValueGroup>
      {renderContentList()}
      {renderActions()}
    </div>
  );
}

OrgUserAssignmentPanel.propTypes = {
  content: PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  }).isRequired,
  onReassign: PropTypes.func.isRequired,
  onUnassign: PropTypes.func.isRequired,
  progressId: PropTypes.string,
  userAssignment: PropTypes.shape({
    assignment: PropTypes.shape({
      contents: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string
      })),
      id: PropTypes.string,
      name: PropTypes.string
    }),
    due_on: PropTypes.string,
    id: PropTypes.string,
    participation_status: PropTypes.string,
    progress_status: PropTypes.string,
    progresses: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.string
    }))
  }).isRequired
};

export default OrgUserAssignmentPanel;
