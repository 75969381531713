import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  PageWrapper,
  ResponsiveDataList,
  Section
} from 'reactifi';
import { i18n } from 'reactifi/dist/es/i18n';

const CookiesContainer = props => {
  const [cookies, setCookies] = useState([]);
  const { osanoId } = props;

  useEffect(() => {
    fetchOsanoCookies();
  }, []);

  const fetchOsanoCookies = async () => {
    if(!osanoId) {
      return;
    }

    const osanoUrl = `https://disclosure.api.osano.com/customer/${osanoId}/config/${osanoId}?`;

    const resp = await fetch(osanoUrl);
    if(resp.ok) {
      const data = await resp.json();
      setCookies(data);
    } else {
      setCookies([]);
    }
  }

  const getFields = () => [
    { name: "name", title: i18n.t("Name") },
    { name: "expiry", title: i18n.t("Expiry") },
    { name: "classification", title: i18n.t("Classification") },
    { name: "purpose", title: i18n.t("Purpose") }
  ];

  return (
    <PageWrapper>
      <Section>
        <h1> { i18n.t('Cookies')} </h1>
        { cookies &&
        <ResponsiveDataList
          fields={getFields()}
          data={cookies}
          keyField="name"
        /> }
      </Section>
    </PageWrapper>
  );
};

CookiesContainer.propTypes = {
  osanoId:PropTypes.string.isRequired
}

export default CookiesContainer;
