import React, { useEffect } from 'react';
import PropTypes from "prop-types";
import { DisplayMaxWidthMedium, ExplainerText, Header, Section, useContainerProps } from 'reactifi';
import i18n from 'lib/i18n';
import BenchmarkForm from './BenchmarkForm';

const Benchmark = props => {
  props = useContainerProps(props);
  const { currentEntity, params, selectMember } = props;
  const title = (params || {}).id === 'add' ?
    i18n.t('Add Benchmarking Attribute') :
    i18n.t('Edit Benchmarking Attribute');

  useEffect(() => {
    if (!currentEntity) {
      selectMember();
    }
  }, []);

  return (
    <>
      <Header back={true} title={title} />
      <Section>
        <DisplayMaxWidthMedium hideIfLoadingMember={true} >
          <>
            <ExplainerText>{i18n.t('Define an attribute to use for benchmarking and add values to it. For example: Athletic Conference could be an attribute with values "PAC 12" and "Sun Belt"')}</ExplainerText>
            <BenchmarkForm />
          </>
        </DisplayMaxWidthMedium>
      </Section>
    </>
  );
}

Benchmark.propTypes = {
  currentEntity: PropTypes.object,
  params: PropTypes.shape({
    id: PropTypes.any
  }),
  selectMember: PropTypes.func
};

export default Benchmark;
