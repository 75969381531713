import React from 'react';
import PropTypes from 'prop-types';
import { ModalForm, FieldGroup, FloatingLabelFormField, SelectAsyncControl, SelectControl, FormSection, MaterialCheckbox, InfoTooltip, AccessControlUtil } from 'reactifi';
import CategoryLabelSelector from './CategoryLabelSelector';
import i18n from 'lib/i18n';

export default class TeamForm extends React.Component {
  static propTypes = {
    businessLines: PropTypes.array,
    categories: PropTypes.array,
    categoryLabels: PropTypes.array,
    clearMessages: PropTypes.func,
    createTeam: PropTypes.func,
    currentBusinessLine: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    currentTeam: PropTypes.object,
    errorMessage: PropTypes.string,
    isAdvisor: PropTypes.bool,
    orgCapabilities: PropTypes.array,
    onCancel: PropTypes.func,
    onSave: PropTypes.func,
    organizationId: PropTypes.string,
    ruleSets: PropTypes.array,
    selectTeam: PropTypes.func,
    updateTeam: PropTypes.func,
    viewer: PropTypes.string
  };

  constructor(props) {
    super(props);

    let selectedBusinessLine = null;
    if (props.currentTeam.business_line) {
      selectedBusinessLine = props.currentTeam.business_line;
    } else {
      selectedBusinessLine = props.businessLines.length === 1 ? props.businessLines[0].id : null;
    }

    this.state = {
      categoryLabelIds: props.currentTeam.category_labels ? props.currentTeam.category_labels.map(lbl => lbl.id) : [],
      selectedBusinessLine,
      formInvalid: !props.currentTeam.id,
      invitationBased: !!props.currentTeam.invitation_based
    };
  }

  static getDerivedStateFromProps(props) {
    const { currentTeam = {} } = props;
    if (currentTeam.id) {
      return {
        selectedBusinessLine: currentTeam.business_line,
        invitationBased: currentTeam.invitation_based,
        formInvalid: currentTeam.name?.length < 2
      };
    }
    return null;
  }

  onCancel = () => {
    this.props.selectTeam(null);
    this.props.clearMessages();

    if (this.props.onCancel) this.props.onCancel();
  }

  flattenIds = (obj) => {
    return (obj && obj.length && typeof obj[0] === 'object') ? obj.map(item => item.id) : obj;
  }

  updateTeam = async (team) => {
    const updatedTeam = Object.assign(team, {
      category_label_ids: this.state.categoryLabelIds,
      invitation_based: this.state.invitationBased,
      manager_ids: this.flattenIds(team.current_managers)
    });

    const response = await this.props.updateTeam(updatedTeam);
    if (response?.data && this.props.onSave) {
      this.props.onSave(response.data);
    }
  }

  createTeam = (team, addAnother) => {
    team.category_label_ids = this.state.categoryLabelIds;
    team.invitation_based = this.state.invitationBased;
    team.manager_ids = this.flattenIds(team.current_managers);
    delete team.category_id;
    delete team.labels;
    delete team.current_managers;

    this.props.createTeam(team, addAnother);
  }

  handleCategoryLabelsChange = (categoryLabelIds) => {
    this.setState({ categoryLabelIds });
  }

  get modalTitle() {
    return this.props.currentTeam.id ? `${i18n.t('Edit Team')}: ${this.props.currentTeam.name}` : i18n.t('Create a Team');
  }

  getManagersCustomFilter = () => {
    const { selectedBusinessLine } = this.state;
    if (!selectedBusinessLine) {
      return null;
    }

    const selectedRoles = this.props.ruleSets
      .filter(rs => rs.is_manager && rs.business_lines.includes(selectedBusinessLine))
      .map((rs) => {
        let roleId = 'secondary';
        if (rs.name.includes("pii")) {
          roleId = 'pii_restricted_next_advisor';
        } else if (rs.name === 'at_work_advisor') {
          roleId = 'advisor';
        }
        return { rule_set_roles: { [rs.id]: roleId } };
      });

    const customFilter = { active: true, rule_set_roles: { ':_or': selectedRoles } };

    return btoa(JSON.stringify(customFilter));
  }

  handleBusinessLineChange = (businessLine) => {
    this.setState({ selectedBusinessLine: businessLine && businessLine.id });
  }

  getManagersInitialData = () => {
    const { currentTeam: { current_managers } } = this.props;

    if (!current_managers) return null;

    return current_managers.map(manager => (
      { id: manager.id, display: this.buildManagersDisplay(manager) }
    ));
  }

  checkRequiredTeamName = (e) => {
    const text = e ? e : this.props.currentTeam.name;
    const invalid = text && text.length < 2;
    this.setState({ formInvalid: invalid });
  }

  buildManagersDisplay = manager => `${manager.full_name || manager.name} (${manager.email})`;

  get explainerText() {
    const { viewer, currentBusinessLine } = this.props;
    return (viewer === 'organization_administrator' && currentBusinessLine === 'at-work')
      ?
      i18n.t('Add Advisors as Team Managers to allow them to view and email invitations to the learning content on behalf of the team.')
      :
      i18n.t('Add locations and custom categories to the Team, then designate one or more Team Admins. Users who belong to those locations and categories will automatically be added to the Team. Team Admins will be able to create and manage assignments for learners on their Teams.');
  }

  onToggleInvitationBased = () => {
    this.setState({
      invitationBased: !this.state.invitationBased
    }, () => {
      if (this.state.invitationBased) {
        this.setState({ category_label_ids: null, location_ids: null });
      }
    });
  }

  renderMainFields = () => {
    const { selectedBusinessLine } = this.state;
    const { isAdvisor, orgCapabilities } = this.props;
    const hasElectiveLearningInivatationsCapability = AccessControlUtil.hasCapability('Capabilities::LearningInvitation', orgCapabilities);

    return (
      <FormSection description={this.explainerText}>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField
              name="name"
              caption={i18n.t("Team Name")}
              onBlur={this.checkRequiredTeamName}
              onChange={this.checkRequiredTeamName}
              required={true}
            />
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField
              name="description"
              as="textarea"
              caption={i18n.t('Description')}
            />
          </FieldGroup>
        </FieldGroup>
        {this.props.businessLines.length > 1 && (
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField disabled={isAdvisor} name="business_line" caption={i18n.t('Line of Business')} required={!isAdvisor}>
                <SelectControl multi={false} options={this.props.businessLines} valueKey="slug" labelKey="label" onChange={this.handleBusinessLineChange} />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
        )}
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField name="current_managers" caption={i18n.t('Team Managers')} required={false} disabled={isAdvisor || (!this.props.currentTeam.id && !selectedBusinessLine)}>
              {selectedBusinessLine && (
                <SelectAsyncControl
                  dataUrl="/api/data/users.json"
                  filters={{
                    organization_id: this.props.organizationId,
                    business_lines: selectedBusinessLine,
                    custom: this.getManagersCustomFilter()
                  }}
                  initialData={this.getManagersInitialData()}
                  multi={true}
                  placeholder=""
                  queryFields={{ first_name: 'text_start', last_name: 'text_start', email: 'word_start' }}
                  sortBy="first_name"
                  buildDisplay={this.buildManagersDisplay}
                  cacheEnabled={false}
                  labelKey="display"
                />
              )}
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        {hasElectiveLearningInivatationsCapability && (
          <FieldGroup>  
            <FieldGroup className="row m-b-25">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="phone_number"
                  caption={i18n.t('Phone')}
                  required={false}
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row m-b-25">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="image_url"
                  caption={i18n.t('Image URL')}
                  hintText={i18n.t('A URL that goes to an image to represent the team. Preferably jpeg or png.')}
                  type="url"
                  validationType="url"
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row m-b-25">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="email"
                  caption={i18n.t('Email')}
                  hintText={i18n.t('An email address a learner can use to contact the team.')}
                  type="email"
                  validationType="email"
                />
              </FieldGroup>
            </FieldGroup>
            <FieldGroup className="row">
              <FieldGroup className="col-12">
                <FloatingLabelFormField
                  name="website"
                  caption={i18n.t('Website')}
                  hintText={i18n.t('URL to a website that a learner can go to for more information about the team.')}
                  type="url"
                  validationType="url"
                />
              </FieldGroup>
            </FieldGroup>
          </FieldGroup>  
        )}  
      </FormSection>
    );
  }

  renderAttributeFields = () => {
    const { isAdvisor } = this.props;
    return (
      <FormSection title={i18n.t("Attributes")} >
        <FieldGroup className="row">
          <FieldGroup className="col-12">
            <FloatingLabelFormField disabled={isAdvisor} name="location_ids" caption={i18n.t('Select Locations')}>
              <SelectAsyncControl
                dataUrl="/api/data/locations.json"
                filters={{ organization_id: this.props.organizationId }}
                initialData={this.props.currentTeam.location_ids}
                multi={true}
                placeholder=""
                queryFields={{ name: 'word_start' }}
                displayProperty="name"
                sortBy="name"
              />
            </FloatingLabelFormField>
          </FieldGroup>
        </FieldGroup>
        <CategoryLabelSelector
          businessLine={this.props.currentBusinessLine}
          categories={this.props.categories}
          categoryLabels={this.props.categoryLabels}
          disabled={isAdvisor}
          labelIds={this.props.currentTeam.category_label_ids}
          organizationId={this.props.organizationId}
          onLabelsChange={this.handleCategoryLabelsChange}
        />
      </FormSection>
    );
  }

  render() {
    const { currentTeam, businessLines } = this.props;
    if (!currentTeam) {
      return null;
    }

    const teamData = Object.assign(currentTeam, {
      location_ids: currentTeam.locations ? currentTeam.locations.map(loc => loc.id) : [],
      category_label_ids: currentTeam.category_labels ? currentTeam.category_labels.map(cat => cat.id) : [],
      ...businessLines && businessLines.length === 1 && { business_line: businessLines[0].id }
    });

    let learningInvitationEnabled = AccessControlUtil.hasCapability('Capabilities::LearningInvitation', this.props.orgCapabilities);

    return (
      <ModalForm
        data={teamData}
        className="team-modal scrolling-modal"
        errorMessage={this.props.errorMessage}
        title={this.modalTitle}
        onCancel={this.onCancel}
        addAction={this.createTeam}
        saveButtonDisabled={this.state.formInvalid}
        saveAndAddButton={!currentTeam.id && !this.state.formInvalid}
        updateAction={this.updateTeam}
        bsSize="large"
      >
        <FieldGroup>
          {this.renderMainFields()}
          {learningInvitationEnabled && (
            <FieldGroup className="invitation-based-team m-b-20">
              <MaterialCheckbox disabled={this.props.isAdvisor} name="invitation_based" onChange={this.onToggleInvitationBased}>
                {i18n.t("Invitation Based Team")}
              </MaterialCheckbox>
              <InfoTooltip name="invitation_based" tooltip={i18n.t('This allows learners to automatically be added as Team Members upon registration')} tooltipPosition="top" />
            </FieldGroup>
          )}
          {this.state.invitationBased || this.renderAttributeFields()}
        </FieldGroup>
      </ModalForm>
    );
  }
}
