import React from "react";
import PropTypes from 'prop-types';
import i18n from "lib/i18n";
import { Button } from 'reactifi';

export default class DataIntegrationEmpty extends React.Component {
  static propTypes = {
    actionButton: PropTypes.func.isRequired
  }

  render() {
    return (
      <div className="text-center">
        <p className="p-b-25 m-b-25">
          {i18n.t("Looks like you don't have any applications registered yet for integrations.")}
          <br />
          {i18n.t("Register an application when it's ready to pull data on learners directly into your systems.")}
        </p>
        <Button
          label={i18n.t('Register New App')}
          style="secondary"
          data-action="register-new-app"
          className="register-new-app-btn"
          onClick={this.props.actionButton}
        />
      </div>
    )
  }
}
