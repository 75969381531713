import React from 'react';
import PropTypes from 'prop-types';
import { FieldGroup } from 'reactifi/dist/es/components/Form/FieldGroup';

export default class CustomerAdminRegistration extends React.Component{
  static propTypes = {
    id: PropTypes.string.isRequired,
    index: PropTypes.number.isRequired,
    data: PropTypes.object.isRequired,
    errorMessages: PropTypes.array,
    className: PropTypes.string
  };

  render(){
    return (
      <FieldGroup {...this.props} className={this.props.className}/>);
  }
}