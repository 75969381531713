import clipboard from './clipboard.png';
import connection from './connection.png';
import conversation from './conversation.png';
import digitalInnovation from './digital_innovation.png';
import educationalImpact from './educational_impact.png';
import globe from './globe.png';
import lowTech from './low-tech.png';
import reportImg from './report.png';
import thoughtBubble from './thought_bubble.png'


const images = [
  educationalImpact,
  clipboard,
  lowTech,
  conversation,
  globe,
  digitalInnovation,
  reportImg,
  connection,
  thoughtBubble];

export default images;
