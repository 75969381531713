import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, FieldGroup, FloatingLabelFormField, FormField, FAIcon } from "reactifi";
import i18n from "lib/i18n";
import isEqual from 'lodash/isEqual';

export default class ValuesList extends Component {
  static propTypes = {
    data: PropTypes.object,
    title: PropTypes.string,
    createNewStore: PropTypes.func,
    clearMessages: PropTypes.func.isRequired,
    onDeleteCategoryLabel: PropTypes.func.isRequired
  };

  constructor(props) {
    super(props);

    this.state = {
      values: props.data?.category_labels || [],
      massAddInput: ''
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.data && prevProps.data && !isEqual(this.props.data.category_labels, prevProps.data.category_labels)) {
      this.setState({ values: this.props.data.category_labels || [] });
    }
  }

  onDelete = (value) => {
    const isEditing = !!value.id;

    if (isEditing) {
      this.props.onDeleteCategoryLabel(value);
    } else {
      this.setState({
        values: this.state.values.filter(label => label.name !== value.name)
      });
    }
  };

  addMassValues = (e, value) => {
    const valueMap = this.state.values.reduce((result, value) => {
      result[value.name] = true;
      return result;
    }, {});

    const separatedInput = this.state.massAddInput.split(/,|\n/).map((input) => {
      return input.trim();
    }).filter((input) => {
      return input;
    });

    const newValues = separatedInput.reduce((result, input) => {
      if (valueMap[input]) {
        return result;
      }
      valueMap[input] = true;
      result.push(Object.assign(this.props.createNewStore("category_labels"), {
        name: input
      }));
      return result;
    }, []);

    const values = [
      ...this.state.values,
      ...newValues
    ];

    this.props.clearMessages();
    this.setState({
      massAddInput: '',
      values
    });
  }

  onMassValueChange = (value) => {
    this.setState({ massAddInput: value });
  }

  getCategoryLabels(values) {
    return values.reduce(function(result, item, index, array) {
      result[index] = item;
      return result;
    }, {});
  }

  onValueChange =(index, changeValue) => {
    const values = [ ...this.state.values ];
    const firstKey = Object.keys(changeValue)[0];

    values[index].name = changeValue[firstKey];

    this.setState({ values });
  }

  render() {
    const { title } = this.props;
    const { values, massAddInput } = this.state;
    const categoryLabels = this.getCategoryLabels(values);
    const sortedValues = [...values].sort((itemA, itemB) => (itemA.name > itemB.name) ? 1 : -1);

    return (
      <div className="values-container">
        {title && (
          <FieldGroup className="values-flex values-title">
            <h5>
              {i18n.t(title)}
            </h5>
          </FieldGroup>
        )}
        <div className="m-b-20">
          <div className="m-b-20">{i18n.t("To add labels to this category, paste a list of comma-separated values below, or add one label at a time. When you're finished, select Save.")}</div>
          <FloatingLabelFormField
            name="mass_add_values"
            className="mass_add_values m-t-20 p-t-10"
            row={5}
            caption={i18n.t("Enter Labels")}
            as="textarea"
            dataValue={massAddInput}
            onChange={this.onMassValueChange}
            valueOverride={true}
          />
          <Button data-action="add-these-labels" onClick={this.addMassValues} variant="secondary" className="m-l-0">
            <FAIcon icon="plus" additionalClasses="m-l-0 m-r-5" />
            {i18n.t("Add these Labels")}
          </Button>
        </div>
        {sortedValues.length > 0 &&
          sortedValues.map((value, index) => (
            <FieldGroup
              className="values-flex"
              key={index}
              data={{ category_labels: categoryLabels }}
              onValueChange={change => this.onValueChange(index, change)}
            >
              <FloatingLabelFormField
                name={`category_labels[${index}][name]`}
                valueOverride={true}
                caption={i18n.t("Label")}
                require={true}
                className="m-b-10"
              />

              {value.id && (
                <FormField
                  name={`category_labels[${index}][id]`}
                  type="hidden"
                  valueOverride={true}
                />
              )}

              <FieldGroup>
                <Button onClick={() => this.onDelete(values[index])} style="default">
                  <FAIcon icon="trash" additionalClasses="text-secondary" />
                </Button>
              </FieldGroup>
            </FieldGroup>
          ))}
      </div>
    );
  }
}
