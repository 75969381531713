import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Header, PageWrapper, buildGenericProps, JsonApiDecorator } from 'reactifi';
import i18n from 'lib/i18n';
import { orgUsersActionCreators } from '../actions';
import OrgUserChangelog from '../components/OrgUserChangelog';

const mapStateToProps = (state, ownProps) => {
  let props = buildGenericProps(state, 'versions');
  const apiStore = new JsonApiDecorator(state.api);

  props = Object.assign(props, {
    currentUserId: ownProps.routeParams.user_id,
    organizationId: ownProps.params.id || ownProps.route.organization_id
  });

  props.changelog = apiStore.versions ? apiStore.versions.all() : [];
  props.changelogMeta = state.api.versions ? state.api.versions.meta : {};
  props.administrators = apiStore.administrators ? apiStore.administrators.all() : [];
  props.changeLogUsers = apiStore.users ? apiStore.users.all() : [];
  props.authors = [];
  if(props.administrators.length || props.changeLogUsers.length){
    props.authors = [...new Set([...props.administrators, ...props.changeLogUsers])];
  }

  return props;
}

const mapDispatchToProps = dispatch => bindActionCreators({ ...orgUsersActionCreators }, dispatch);

const OrganizationUserChangelogContainer = ({
  administrators,
  authors,
  changelog,
  changeLogUsers,
  clearUsers,
  currentUserId,
  findAdministrators,
  findChangeLogUsers,
  getUserChangelog,
  router,
  ...props
}) => {
  const [activePage, setActivePage] = useState(1);
  const [isChangelogLoaded, setChangelogLoaded] = useState(false);

  useEffect(() => {
    setChangelogLoaded(false);
    getChangelog();
  }, [activePage]);

  useEffect(() => {
    if (isChangelogLoaded) {
      const administratorIds = [...new Set(changelog.filter(log => !!log.author_id && log.author_type === 'Administrator').map(log => log.author_id))];
      const changeLogUserIds = [...new Set(changelog.filter(log => !!log.author_id && log.author_type === 'User').map(log => log.author_id))];
      if(administratorIds.length) {
        findAdministrators(administratorIds);
      }
      if(changeLogUserIds.length) {
        findChangeLogUsers(changeLogUserIds);
      }
    }
  }, [isChangelogLoaded]);

  const getChangelog = async () => {
    clearUsers();
    await getUserChangelog(currentUserId, activePage);
    setChangelogLoaded(true);
  }

  return (
    <PageWrapper>
      <Header
        back={true}
        router={router}
        icon="clock-rotate-left"
        title={i18n.t('Changelog')}
      />
      <OrgUserChangelog 
        {...props}
        activePage={activePage}
        authors={authors}
        changelog={changelog}
        onPageChange={page => setActivePage(page)}
      />
    </PageWrapper>
  );
}

OrganizationUserChangelogContainer.propTypes = {
  administrators: PropTypes.array,
  authors: PropTypes.array.isRequired,
  changelog: PropTypes.array.isRequired,
  changeLogUsers: PropTypes.array,
  clearUsers: PropTypes.func.isRequired,
  currentUserId: PropTypes.string.isRequired,
  findAdministrators: PropTypes.func.isRequired,
  findChangeLogUsers: PropTypes.func.isRequired,
  getUserChangelog: PropTypes.func.isRequired,
  router: PropTypes.object.isRequired
};

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationUserChangelogContainer);
