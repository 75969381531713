import React from "react";
import { createContainer } from "reactifi";
import OrganizationImpactStory from "../components/OrganizationImpactStory";
import config from '../configs/impactStoriesConfig';

const Container = createContainer(config);

const OrganizationImpactStoryContainer = props => {
  return (
    <Container {...props}>
      <OrganizationImpactStory />
    </Container>
  );
};

export default OrganizationImpactStoryContainer;
