import React from 'react';
import PropTypes from 'prop-types';
import CustomComponent from '../../../lib/components/CustomComponent';
import {
  NOT_EDITABLE_ATTRIBUTES,
  SAML_ATTRIBUTES
} from '../../common/samlAttributes';
import debounce from 'lodash.debounce';
import { NestedForms, RadioGroupControl } from 'reactifi';
import i18n from 'lib/i18n';
import cloneDeep from 'lodash/cloneDeep';
import { sanitizeUrl } from '@braintree/sanitize-url';

export default class OrgWizardUserDetails extends React.Component {
  static propTypes = {
    bbIdEnabled: PropTypes.bool,
    bbIdRequired: PropTypes.bool,
    branded: PropTypes.bool,
    canceling: PropTypes.bool,
    cancelClose: PropTypes.func,
    cancelComplete: PropTypes.func,
    clearMessages: PropTypes.func,
    createAndAdd: PropTypes.bool,
    deleteMeFromSupressionList: PropTypes.func,
    goingToStep: PropTypes.number,
    goingDirection: PropTypes.string,
    errorMessage: PropTypes.string,
    errorMessages: PropTypes.arrayOf(PropTypes.string),
    googleMapsApiKey: PropTypes.string,
    isManager: PropTypes.bool,
    isSelfRegistered: PropTypes.bool,
    jwt: PropTypes.string,
    loadLocation: PropTypes.func,
    notifiUrl: PropTypes.string,
    onCoppaAgeSelectionChange: PropTypes.func,
    organizationId: PropTypes.string,
    organizationName: PropTypes.string,
    onValidityCheck: PropTypes.func,
    onFullFormFiledCheck: PropTypes.func,
    isCreate: PropTypes.bool,
    isAgeForm: PropTypes.bool,
    recaptchaData: PropTypes.object,
    resetSteps: PropTypes.func,
    register: PropTypes.func,
    registrations: PropTypes.array,
    registrationSets: PropTypes.shape({
      registration_context: PropTypes.string
    }),
    stepChangeCancel: PropTypes.func,
    stepChangeComplete: PropTypes.func,
    suppressionLists: PropTypes.array,
    suppressionListEmails: PropTypes.array,
    updateUser: PropTypes.func,
    userTypeUpdated: PropTypes.bool,
    user: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.state = {
      user: Object.assign({}, props.user),
      suppressionListsToDelete: []
    };
  }

  async componentDidUpdate(prevProps) {
    if (
      this.props.goingToStep !== null &&
      this.props.goingToStep !== undefined &&
      prevProps.goingToStep !== this.props.goingToStep
    ) {
      if (this.props.goingDirection === 'forward') {
        this.setState({ saveClicked: true });
      } else {
        this.props.stepChangeComplete();
      }
    }
    if (this.props.createAndAdd && !prevProps.createAndAdd) {
      this.setState({ saveAndAddClicked: true });
    }
    if (this.props.canceling && !prevProps.canceling) {
      this.setState({ cancelClicked: true });
    }
  }

  deleteFromSuppresionList = (listId, adding) => {
    if (adding) {
      this.setState({
        suppressionListsToDelete: [
          ...this.state.suppressionListsToDelete,
          listId
        ]
      });
    } else {
      this.setState({
        suppressionListsToDelete: this.state.suppressionListsToDelete.filter(
          (item) => item !== listId
        )
      });
    }
  };

  isUserDataUpdated = (regSets) => {
    const userRuleSet = regSets.filter((set) => set.id === 'user_rule_set');
    const { first_name, last_name, location_id, email } = this.props.user;

    if (userRuleSet.length) {
      const updated = userRuleSet[0];

      return (
        updated.first_name !== first_name ||
        updated.last_name !== last_name ||
        String(updated.location_id) !== String(location_id) ||
        updated.email !== email
      );
    }

    return false;
  };

  getDisabledFieldsText = () => {
    const { isCreate, user } = this.props;
    if (!isCreate && user && user.sso_id && this.containsDisabledFields()) {
      return (
        <div className="sso-text">
          {i18n.t(
            'Certain fields may be disabled because those values can only be set by your single sign-on (SSO) identity provider. Click '
          )}
          <a
            href="https://help.everfi.com/s/article/SSO-IDP-Setup"
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.t('here')}
          </a>
          {i18n.t(' to learn more.')}
        </div>
      );
    }
    return null;
  };

  containsDisabledFields = () => {
    const { registrations } = this.props;
    const displayedFields = SAML_ATTRIBUTES.map(
      (attribute) => attribute.value
    ).filter((attribute) => !NOT_EDITABLE_ATTRIBUTES.includes(attribute));

    if (!registrations) {
      return false;
    }
    // check if any registration sets contain metadata that is both available and uneditable
    return registrations.some((registration) => {
      // get all the uneditable field names
      const disabledFields = Object.entries(registration.metadata)
        .filter(([_, value]) => value.available && !value.editable)
        .map((entry) => entry[0]);
      // only include field names that we know are displayed
      return disabledFields.some((field) => displayedFields.includes(field));
    });
  };

  updateAction = async (regSets) => {
    if (this.props.jwt) {
      this.state.suppressionListsToDelete.map((aList) =>
        this.props.deleteMeFromSupressionList(
          this.props.notifiUrl,
          this.props.jwt,
          aList
        )
      );
    }
    try {
      await this.props.updateUser(this.props.registrationSets);
      // we force-refresh the page if first_name, last_name, or location was modified
      // so we can display the name and/or location updated in the header menu
      // and also in the profile page
      if (this.isUserDataUpdated(regSets) || this.props.userTypeUpdated) {
        // avoid form resubmission if user navigates back
        window.history.replaceState(null, null, window.location.href);
        window.location.reload();
      } else {
        this.props.stepChangeComplete();
      }
    } catch (e) {
      this.props.stepChangeCancel();
    }
  };

  tryToRegister = async (addAnother = false, captchaToken, version) => {
    const { recaptchaData } = this.props;
    try {
      let regSets = cloneDeep(this.props.registrationSets);
      if (captchaToken && version === 'v3') {
        regSets[recaptchaData.paramNameV3] = {
          [recaptchaData.actionNameV3]: captchaToken
        };
      } else if (captchaToken && version === 'v2') {
        regSets[recaptchaData.paramNameV2] = captchaToken;
      } else if (version === 'QAbypass') {
        regSets['qa-multipass'] = {
          signature: recaptchaData.signature,
          timestamp: recaptchaData.timestamp
        };
      }
      await this.props.register(regSets, addAnother);

      /* eslint-disable no-undef */
      if (
        this.props.errorMessage === 'Forbidden' &&
        typeof grecaptcha !== 'undefined'
      ) {
        grecaptcha.render('recaptcha-v2', { sitekey: recaptchaData.siteKeyV2 });
        const box = document.getElementById('recaptcha-v2');
        box.scrollIntoView({ block: 'end' });
      }
      /* eslint-enable no-undef */

      if (addAnother) {
        this.setState({ saveAndAddClicked: false }, this.props.resetSteps);
      } else {
        this.props.stepChangeComplete();
      }
    } catch (e) {
      this.props.stepChangeCancel();
    }
  };

  addAction = async (_, addAnother) => {
    const { recaptchaData } = this.props;
    /* eslint-disable no-undef */
    if (recaptchaData?.siteKeyV3 && typeof grecaptcha !== 'undefined') {
      grecaptcha.ready(async () => {
        const v2Response = document.getElementById('g-recaptcha-response');
        let captchaToken = v2Response?.value;
        let version = 'v2';

        if (!v2Response) {
          captchaToken = await grecaptcha.execute(recaptchaData.siteKeyV3, {
            action: recaptchaData.actionNameV3
          });
          version = 'v3';
        }
        this.tryToRegister(addAnother, captchaToken, version);
      });
    } else if (recaptchaData?.timestamp) {
      this.tryToRegister(addAnother, null, 'QAbypass');
    } else {
      this.tryToRegister(addAnother);
    }
    /* eslint-enable no-undef */
  };

  saveComplete = (formValidated) => {
    if (!formValidated) {
      this.props.stepChangeCancel();
    }
    this.setState({ saveClicked: false });
  };

  cancelClose = () => {
    this.setState({ cancelClicked: false });
    this.props.cancelClose();
  };

  redirect = () => {
    const url = sanitizeUrl(window.location.href);
    window.location.href = url.substring(0, url.lastIndexOf('/'));
  };

  onAgeSelectionChanged = (value) => {
    this.props.onCoppaAgeSelectionChange(value);
  };

  render() {
    const reg_context = this.props.registrationSets
      ? this.props.registrationSets.registration_context
      : '';
    const infoPanel =
      reg_context === 'self_registered'
        ? ''
        : i18n.t(
          'Enter basic details about this person for better administration and reporting.'
        );
    const saveBtn =
      reg_context === 'self_registered'
        ? i18n.t('Register')
        : this.props.isCreate
          ? i18n.t('Create User')
          : i18n.t('Update User');
    const disabledText = this.getDisabledFieldsText();

    if (this.props.isAgeForm && this.props.isCreate) {
      return (
        <div className="coppa-under-13">
          <RadioGroupControl
            name="under_13"
            controlLabelText={i18n.t('Are you under 13 years of age?')}
            valueList={[
              { value: true, label: i18n.t('Yes') },
              { value: false, label: i18n.t('No') }
            ]}
            valueKey="value"
            labelKey="label"
            onChange={this.onAgeSelectionChanged}
          />
        </div>
      );
    }

    return (
      <div className="add-user-form">
        {infoPanel && (
          <div className="info-panel">
            {infoPanel}
            <p className="m-b-0">
              <small className="text-muted">
                {i18n.t('Fields marked with asterisk(*) are required.')}
              </small>
            </p>
          </div>
        )}
        {disabledText}
        {this.props.registrations ? (
          <>
            <NestedForms
              allowEnterKey={false}
              branded={true}
              data={this.props.registrations}
              saveButtonText={saveBtn}
              addAction={debounce(this.addAction, 350)}
              updateAction={this.updateAction}
              errorMessages={this.props.errorMessages}
              fieldGroupClass=""
              buttonStyle="primary"
              buttonGroupClass="modal-footer"
              isCreate={this.props.isCreate}
              saveButton={false}
              onCancel={this.props.cancelComplete}
              onCancelClose={this.cancelClose}
              cancelButton={false}
              triggerCancel={this.state.cancelClicked}
              triggerSave={this.state.saveClicked}
              triggerSaveAndAdd={this.state.saveAndAddClicked}
              saveComplete={this.saveComplete}
            >
              <CustomComponent
                googleMapsApiKey={this.props.googleMapsApiKey}
                isCreate={this.props.isCreate}
                branded={this.props.branded}
                organizationName={this.props.organizationName}
                organizationId={this.props.organizationId}
                clearMessages={this.props.clearMessages}
                loadLocation={this.props.loadLocation}
                user={this.props.user}
                onValidityCheck={this.props.onValidityCheck}
                onFullFormFiledCheck={this.props.onFullFormFiledCheck}
                isSelfRegistered={this.props.isSelfRegistered}
                notifiUrl={this.props.notifiUrl}
                jwt={this.props.jwt}
                suppressionListEmails={this.props.suppressionListEmails}
                suppressionLists={this.props.suppressionLists}
                deleteFromSuppresionList={this.deleteFromSuppresionList}
                bbIdEnabled={this.props.bbIdEnabled}
                bbIdRequired={this.props.bbIdRequired}
                isManager={this.props.isManager}
              />
            </NestedForms>
            <div id="recaptcha-v2" />
          </>
        ) : (
          <div>
            {i18n.t('No User Type information available')}
            <br />
            <a onClick={(e) => this.redirect()}>
              {i18n.t('Go to organization users to select a User Type')}
            </a>
          </div>
        )}
      </div>
    );
  }
}
