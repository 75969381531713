import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { GenericModal, FieldGroup, MultiRowForm, MaterialCheckbox, Util } from 'reactifi';
import i18n from 'lib/i18n';
import OrganizationBenchmarkEntry from './OrganizationBenchmarkEntry';

export default class OrganizationBenchmarkModal extends Component {
  static propTypes = {
    benchmarkCategories: PropTypes.array.isRequired,
    createNewObject: PropTypes.func.isRequired,
    currentOrganization: PropTypes.object.isRequired,
    data: PropTypes.array,
    deleteAction: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    showModal: PropTypes.bool.isRequired,
    updateAction: PropTypes.func.isRequired
  };

  canAddCategory = () => {
    return !this.props.data.find(category => category.id === undefined);
  };

  onDelete = (benchmarkGroupOrg) => {
    if (!benchmarkGroupOrg.id) {
      const benchmark = this.props.data[benchmarkGroupOrg._key - 1];
      return benchmark ? this.props.deleteAction(benchmark) : null;
    }
    return this.props.deleteAction(benchmarkGroupOrg);
  }

  render() {
    const { currentOrganization,
      createNewObject,
      updateAction,
      onCancel,
      showModal
    } = this.props;

    const footerCloseButton = {
      attributes: {
        id: 'close-benchmark-attributes-button',
        ...Util.dataAttribsForEl(currentOrganization, { action: 'close-benchmark-modal' })
      },
      variant: 'primary',
      isTurnedOn: true,
      text: i18n.t('Done')
    };

    return (
      <GenericModal
        canClose={true}
        className="form-modal"
        footerCloseButton={footerCloseButton}
        onClose={onCancel}
        show={showModal}
        size="2xl"
        title={i18n.t('Edit Benchmark Attributes')}
      >
        <MultiRowForm
          addButtonText={i18n.t('Add Benchmark Attribute')}
          canAdd={this.canAddCategory}
          data={this.props.data}
          deleteAction={this.onDelete}
          displaySaveButtonIcon={false}
          hasAddButton={true}
          hasDeleteButton={true}
          newObject={createNewObject}
          saveAction={updateAction}
        >
          <OrganizationBenchmarkEntry
            benchmarkCategories={this.props.benchmarkCategories}
          />
          <FieldGroup className="row">
            <FieldGroup className="col-12 visible-to-organization-checkbox">
              <MaterialCheckbox name="visible_to_organization">
                {i18n.t("Visible to Customer")}
              </MaterialCheckbox>
            </FieldGroup>
          </FieldGroup>
        </MultiRowForm>
      </GenericModal>
    );
  }
}
