import { selectEntity, updateEntity, createEntity, readEntity, readEntityById, deleteEntity } from 'reactifi';
import i18n from 'lib/i18n';
export { clearMessages, displayError, displayErrorMessage, displaySuccessMessage } from 'reactifi';

export function selectRoster(rosterId) {
  return selectEntity(rosterId, 'rosters');
}

export function readRosters(rosterId) {
  return readEntityById(rosterId, 'rosters');
}

export function findRosters(filters, page, sort, searchValue) {
  if (!sort) {
    sort = { value: "name", direction: "asc" };
  }
  const query = searchValue && {
    name: {
      term: searchValue,
      fields: {
        name: 'word_start'
      }
    }
  };
  return readEntity('rosters', '', filters, sort && [sort], page, query);
}

export function updateRoster(roster) {
  return updateEntity(roster, (dispatch, getState, entity) => {
    dispatch(selectEntity(entity.data.id, entity.data.type));
  });
}

export function updateRosterAndClose(roster) {
  return updateEntity(roster, (dispatch) => {
    dispatch(selectEntity(null, 'rosters'));
  })
}

export function createRoster(roster) {
  return async (dispatch) => {
    return dispatch(createEntity(roster, (dispatch, getState, entity) => {
      dispatch(selectEntity(entity.data.id, entity.data.type));
    }))
  }
}

export function deleteRoster(roster) {
  return deleteEntity(roster, 'rosters', i18n.t('Roster deleted successfully.'))
}

