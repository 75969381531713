import React from 'react';
import PropTypes from 'prop-types';
import { ModalForm, FieldGroup, FloatingLabelFormField, CheckboxGroupControl, MaterialCheckbox, FormField, DateTimePickerControl, SelectControl } from 'reactifi';
import i18n from 'lib/i18n';

const MESSAGE_LEVELS = [
  { value: 'blue', label: i18n.t('Informational (blue)') },
  { value: 'green', label: i18n.t('Normal (green)') },
  { value: 'yellow', label: i18n.t('Warning (yellow)') },
  { value: 'red', label: i18n.t('Urgent (red)') }
];

const MESSAGE_LOCATIONS = [
  { value: 'dashboard', label: i18n.t("Dashboard Announcement") },
  { value: 'login', label: i18n.t('Banner-on-login') }
]

export default class SystemMessagingForm extends React.Component {
  static propTypes = {
    clearMessages: PropTypes.func.isRequired,
    createSystemMessage: PropTypes.func.isRequired,
    currentMessage: PropTypes.object,
    errorMessage: PropTypes.string,
    selectSystemMessage: PropTypes.func.isRequired,
    updateSystemMessage: PropTypes.func.isRequired,
    ruleSets: PropTypes.array,
    canEdit: PropTypes.bool
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedRecipients: this.props.currentMessage.admin_type || []
    };
  }

  selectRecipients = value => {
    this.setState({ selectedRecipients: value })
  }

  onCancel = () => {
    this.props.selectSystemMessage(null);
    this.props.clearMessages();
  }

  updateSystemMessage = systemMessage => {
    const updatedMessage = Object.assign(systemMessage, {
      admin_type: this.state.selectedRecipients
    });
    this.props.updateSystemMessage(updatedMessage);
  }

  createSystemMessage = systemMessage => {
    const newMessage = Object.assign(systemMessage, {
      admin_type: this.state.selectedRecipients
    });
    this.props.createSystemMessage(newMessage);
  }

  get modalTitle() {
    const { currentMessage } = this.props;

    if (!currentMessage || !currentMessage.id) {
      return i18n.t('New System Message');
    }
    if(!this.props.canEdit)
      return currentMessage.title
    return i18n.t(`Edit ${currentMessage.title}`);
  }

  render() {
    return (
      <ModalForm
        data={this.props.currentMessage}
        errorMessage={this.props.errorMessage}
        title={this.modalTitle}
        onCancel={this.onCancel}
        addAction={this.createSystemMessage}
        updateAction={this.updateSystemMessage}
        saveAndAddButton={false}
        saveButtonDisabled={!this.props.canEdit}
        skipCancelConfirmation={!this.props.canEdit}
      >
        <FieldGroup className="container-fluid">
          <div className="row">
            <div className="col-12">
              <p className="info-panel">{i18n.t('This System Message will be visible to all Foundry users between the start and end dates selected below. Set a message to begin today or select a date in the future for the message to activate. You may edit a System Message or its start and end dates at any time.')}</p>
            </div>
          </div>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="title"
                caption={i18n.t('Message Title')}
                required={true}
                disabled={!this.props.canEdit}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField
                name="content"
                caption={i18n.t('Message Content')}
                required={true}
                disabled={!this.props.canEdit}
              />
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-6">
              <FloatingLabelFormField name="start_date" caption={i18n.t('Starts On')} className="icon-label" required={true} disabled={!this.props.canEdit}>
                <DateTimePickerControl disablePicker={!this.props.canEdit} />
              </FloatingLabelFormField>
            </FieldGroup>
            <FieldGroup className="col-6">
              <FloatingLabelFormField name="end_date" caption={i18n.t('Ends On')} className="icon-label" required={true} disabled={!this.props.canEdit}>
                <DateTimePickerControl disablePicker={!this.props.canEdit} />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField caption={i18n.t('Message Level')} name="level" required={true} disabled={!this.props.canEdit}>
                <SelectControl 
                  options={MESSAGE_LEVELS} 
                  multi={false} 
                  valueKey="value" 
                  labelKey="label"
                />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FloatingLabelFormField caption={i18n.t('Message Location')} name="view_locations" required={true} disabled={!this.props.canEdit}>
                <SelectControl 
                  options={MESSAGE_LOCATIONS} 
                  multi={true} 
                  valueKey="value" 
                  labelKey="label"
                />
              </FloatingLabelFormField>
            </FieldGroup>
          </FieldGroup>
          <FieldGroup className="row">
            <FieldGroup className="col-12">
              <FormField name="admin_type" caption={i18n.t('Show Message To:')} required={true} disabled={!this.props.canEdit}>
                <CheckboxGroupControl
                  valueList={this.props.ruleSets}
                  valueKey="name"
                  labelKey="label"
                  onChange={this.selectRecipients}
                  CheckboxComponent={MaterialCheckbox}/>
              </FormField>
            </FieldGroup>
          </FieldGroup>
        </FieldGroup>
      </ModalForm>
    );
  }
}
