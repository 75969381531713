export const buildUsersQuery = searchValue => {
  if (!searchValue) {
    return undefined;
  }
  return {
    name: {
      term: searchValue,
      fields: {
        full_name: 'word_start',
        email: 'word_start',
        username: 'text_start',
        parent_email: 'word_start',
        ...searchValue && searchValue.length > 4 ? { sso_id: 'text_start' } : null
      }
    }
  };
}