import React, { Component } from 'react';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';
import { FieldGroup, Button } from 'reactifi';

export default class BrandingButtonsWell extends Component {
  static propTypes = {
    currentStyleGuide: PropTypes.object,
    getProperties: PropTypes.func,
    invalidPrimaryHex: PropTypes.bool,
    invalidSecondaryHex: PropTypes.bool,
    primaryComplianceMessage: PropTypes.string,
    secondaryComplianceMessage: PropTypes.string,
    primaryStyles: PropTypes.object,
    primaryHoverStyles: PropTypes.object,
    secondaryStyles: PropTypes.object,
    secondaryHoverStyles: PropTypes.object
  }

  constructor(props){
    super(props);
    this.state = {
      currentStyleGuide: props.currentStyleGuide,
      primaryHover: false,
      secondaryHover: false,
      tertiaryHover: false
    }
  }

  toggleHover = (element) => this.setState({ [`${element}Hover`]: !this.state[`${element}Hover`] });

  render () {
    let { currentStyleGuide, primaryComplianceMessage, secondaryComplianceMessage } = this.props;

    return (
      <FieldGroup data={currentStyleGuide} className="branding-buttons-well">
        <FieldGroup className="branding-well-container">
          <h4>{i18n.t("Primary Buttons")}</h4>
          <FieldGroup className="row">
            <div className="col-sm-3 preview-box branding-preview">
              <Button
                label={i18n.t('Preview')}
                className="box-common"
                onMouseEnter={(e) => this.toggleHover('primary')}
                onMouseLeave={(e) => this.toggleHover('primary')}
                cssStyle={this.state.primaryHover ? this.props.primaryHoverStyles : this.props.primaryStyles}
              />
            </div>
          </FieldGroup>
          <FieldGroup className="row">
            {this.props.getProperties('primary_button')}
            {this.props.invalidPrimaryHex && <FieldGroup className="error-text">
              <p>{ i18n.t("Please make sure that the entered hexcolors are valid.") }</p>
            </FieldGroup>}
            {primaryComplianceMessage && <FieldGroup className="error-text">
              <p>{ primaryComplianceMessage }</p>
            </FieldGroup>}
          </FieldGroup>
        </FieldGroup>
        <FieldGroup className="branding-well-container">
          <h4>{i18n.t("Secondary Buttons")}</h4>
          <FieldGroup className="row">
            <div className="col-sm-3 preview-box branding-preview">
              <Button
                label={i18n.t('Preview')}
                className="box-common"
                onMouseEnter={(e) => this.toggleHover('secondary')}
                onMouseLeave={(e) => this.toggleHover('secondary')}
                cssStyle={this.state.secondaryHover ? this.props.secondaryHoverStyles : this.props.secondaryStyles}
              />
            </div>
          </FieldGroup>
          <FieldGroup className="row">
            {this.props.getProperties('secondary_button')}
            {this.props.invalidSecondaryHex && <FieldGroup className="error-text">
              <p>{ i18n.t("Please make sure that the entered hexcolors are valid.") }</p>
            </FieldGroup>}
            {secondaryComplianceMessage && <FieldGroup className="error-text">
              <p>{secondaryComplianceMessage}</p>
            </FieldGroup>}
          </FieldGroup>
        </FieldGroup>
      </FieldGroup>
    )
  }
}
