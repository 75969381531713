import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import i18n from 'lib/i18n';
import { SearchComponent, Toolbar, Page, buildGenericProps, JsonApiDecorator, NoResultsCard, ConfirmationModal } from 'reactifi';
import { hasPermission } from 'reactifi/dist/es/utils/AccessControlUtil';
import * as systemMessagingActionCreators from '../actions/systemMessagingActionCreators';
import SystemMessagingTable from '../components/SystemMessagingTableComponent';
import SystemMessagingForm from '../components/SystemMessagingForm';
import { NoResultButtons } from '../../common/Components/NoResultsButtons';
import { isBefore, isAfter, isSameDay } from 'date-fns';


const SYSTEM_MESSAGE_STATUS = {
  active: i18n.t('ACTIVE'),
  inactive: i18n.t('INACTIVE'),
  not_started: i18n.t('NOT STARTED')
};



const isDateSameOrAfter = (date1, date2) => {
  return isSameDay(date1, date2) || isAfter(date1, date2);
};

const isDateSameOrBefore = (date1, date2) => {
  return isSameDay(date1, date2) || isBefore(date1, date2);
};

const getMessageStatus = (startDate, endDate) => {
  const currentTime = new Date();
  let status;

  if (
    isDateSameOrBefore(startDate, currentTime) &&
    isDateSameOrAfter(endDate, currentTime)
  ) {
    status = SYSTEM_MESSAGE_STATUS.active;
  } else if (isBefore(endDate, currentTime)) {
    status = SYSTEM_MESSAGE_STATUS.inactive;
  } else {
    status = SYSTEM_MESSAGE_STATUS.not_started;
  }
  return status;
};

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'system_messages', []);
  if (props.currentEntity) {
    props.currentMessage = props.currentEntity;
  }

  props = Object.assign(props, {
    successMessage: state.api.successMessage,
    errorMessage: state.api.errorMessage,
    viewer: ownProps.route.viewer,
    permissions: ownProps.route.permissions
  });

  let apiStore = new JsonApiDecorator(state.api);

  props.systemMessages = apiStore.system_messages ? apiStore.system_messages.all() : [];
  props.systemMessages.forEach((message) => {
    message.status = getMessageStatus(message.start_date, message.end_date);
  });

  props.ruleSets = apiStore.rule_sets ? apiStore.rule_sets.where(rs => rs.name !== 'scorm') : [];
  props.ruleSets.push({ name: 'unauthenticated', label: 'Unauthenticated Users' });

  return props;
}

export class SystemMessagingContainer extends React.Component {
  static propTypes = {
    collectionMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    currentMessage: PropTypes.object,
    dispatch: PropTypes.func.isRequired,
    errorMessage: PropTypes.string,
    filter: PropTypes.object,
    isLoadingData: PropTypes.bool,
    ruleSets: PropTypes.array,
    permissions: PropTypes.arrayOf(PropTypes.string),
    successMessage: PropTypes.string,
    systemMessages: PropTypes.array
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators(systemMessagingActionCreators, this.props.dispatch);
    this.noResultsButtons = [NoResultButtons(this.actions.selectSystemMessage, i18n.t('Create a System Message'))];
    this.canEdit = hasPermission('manage_system_messages', this.props.permissions)

    this.state = {
      filtered: false,
      activePage: 1,
      sortName: 'title',
      sortOrder: 'asc',
      showModal: false,
      selectedMessage: null
    }
  }

  componentDidMount() {
    this.getSystemMessages();
    this.actions.loadRuleSets();
  }

  onPageChange = (pageNumber) => {
    this.setState({ activePage: pageNumber }, this.getSystemMessages);
  }

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sort: {
        value: sortName,
        direction: sortOrder
      },
      sortName,
      sortOrder,
      activePage: 1
    }, this.getSystemMessages);
  }

  getSystemMessages = (filters) => {
    this.actions.readSystemMessages('', Object.assign({}, this.props.filter, filters), this.state.sort, { number: this.state.activePage });
    this.setState({ filtered: true });
  }

  createSystemMessage = async systemMessage => {
    var entity = await this.actions.createSystemMessage(systemMessage);
    if (entity) {
      setTimeout(this.getSystemMessages, 1000);
    }
  }

  handleDeleteSystemMessage = selectedMessage => this.setState({ selectedMessage, showModal: true });

  handleEditSystemMessage = systemMessage => this.actions.selectSystemMessage(systemMessage.id);

  handleViewSystemMessage = systemMessage => this.actions.selectSystemMessage(systemMessage.id);

  onConfirmDeleteSystemMessage = async () => {
    await this.actions.deleteSystemMessage(this.state.selectedMessage);
    this.actions.readSystemMessages();
    this.onCancelDeleteSystemMessage();
  };

  onCancelDeleteSystemMessage = () => this.setState({ selectedMessage: null, showModal: false });

  get actionButton() {
    return {
      label: i18n.t('Create Message'),
      'data-action': 'create-message',
      action: () => this.actions.selectSystemMessage('add')
    };
  }

  renderTable() {
    if (!this.props.systemMessages) {
      return <NoResultsCard message={this.noResultsMessage} buttons={this.noResultsButtons} />;
    }

    return (
      <SystemMessagingTable
        data={this.props.systemMessages}
        options={{
          onSortChange: this.onSortChange,
          sortName: this.state.sortName,
          sortOrder: this.state.sortOrder,
          ...this.state.sort
        }}
        fieldsProps={{
          onEdit: this.handleEditSystemMessage,
          onDelete: this.handleDeleteSystemMessage,
          onView: this.handleViewSystemMessage
        }}
        onPageChange={this.onPageChange}
        isLoadingData={this.props.isLoadingData}
        totalCount={this.props.collectionMeta ? this.props.collectionMeta.total_count : 0}
        activePage={this.state.activePage}
        permissions={this.props.permissions}
      />
    );
  }

  render() {
    const systemMessagingExplainerBanner = (
      <div className="system-messaing-explainer-banner">
        {i18n.t('System Messages will appear to active Foundry users when they log into the platform. Messages can be customized to show to users based on their user role, and can appear as a banner (for all users) or on the actionable dashboard (for admins only).')}
      </div>
    );

    return (
      <Page title={i18n.t('System Messaging')}
        pageType="parent"
        contentType="list"
        actionButton={this.canEdit && this.actionButton}
        successMessage={this.props.successMessage}
        errorMessage={this.props.currentMessage ? null : this.props.errorMessage}
        clearMessages={this.actions.clearMessages}
        usePageWrapper={true}
        toolbar={
          <Toolbar toolbarClass="system-messaing-explainer-banner-container">
            {systemMessagingExplainerBanner}
          </Toolbar>
        }
      >
        <SearchComponent
          name="keyword"
          placeholder={i18n.t('Search')}
          searchAction={this.getSystemMessages}
        />
        <div className="page-panel">
          {this.props.currentMessage && (
            <SystemMessagingForm
              clearMessages={this.actions.clearMessages}
              createSystemMessage={this.createSystemMessage}
              currentMessage={this.props.currentMessage}
              errorMessage={this.props.errorMessage}
              selectSystemMessage={this.actions.selectSystemMessage}
              updateSystemMessage={this.actions.updateSystemMessage}
              ruleSets={this.props.ruleSets}
              canEdit={this.canEdit}
            />
          )}
          {this.renderTable()}
        </div>

        <ConfirmationModal
          data={this.state.selectedMessage}
          show={this.state.showModal}
          title={i18n.t('Delete Message')}
          children={i18n.t('Are you sure you want to delete this system message?')}
          onConfirm={this.onConfirmDeleteSystemMessage}
          onCancel={this.onCancelDeleteSystemMessage}
        />
      </Page>
    );
  }
}

export default connect(mapStateToProps)(SystemMessagingContainer);
