import { UrlUtil, baseEndpointPath } from 'reactifi';
import { loadRuleSets, selectOrgCapabilities } from "../../common/actions/commonActionCreators";
import { getBusinessLinesFromCapabilities } from "../../common/capabilities";
import { loadUploadedReports } from '../actions/downloadsActions';
import { BUSINESS_LINE_MAP } from "../../common/businessLines";
import i18n from 'lib/i18n';

export const FILE_TYPES = Object.freeze([
  {
    type: 'impact_report',
    label: i18n.t('Impact Report')
  }
]);

const config = props => {
  const organizationId = props.route.organizationId || props.params.id;
  return {
    entity: 'uploaded_reports',
    basePath: getDownloadBasePath(organizationId),
    excludeMessages: props.route.isAdmin || props.route.viewer === 'admin',
    mapStateToProps,
    actionCreators: {
      loadRuleSets,
      loadUploadedReports,
      selectOrgCapabilities
    },
    createMember: {
      successMessage: i18n.t("Download created successfully.")
    },
    updateMember: {
      successMessage: i18n.t("Download updated successfully.")
    },
    deleteMember: {
      successMessage: i18n.t("Download deleted successfully.")
    }
  };
}

function mapStateToProps(state, ownProps) {
  const props = {
    isAdmin: ownProps.route.isAdmin || ownProps.route.viewer === 'admin',
    organizationId: ownProps.params.id || ownProps.route.organizationId
  };

  if (props.currentEntity && FILE_TYPES.length === 1) {
    props.currentEntity.file_type = FILE_TYPES[0].type;
  }

  const { capabilities, rule_sets } = ownProps.apiStore;
  if (capabilities) {
    props.businessLines =
      getBusinessLinesFromCapabilities(capabilities.all()).map(businessLine => (
        {
          id: businessLine,
          name: BUSINESS_LINE_MAP[businessLine]
        }
      ));
  } else {
    props.businessLines = [];
  }
  props.currentBusinessLine = UrlUtil.getCurrentBusinessLine(rule_sets && rule_sets.all());
  return props;
}

function getDownloadBasePath(organizationId) {
  return `${baseEndpointPath}/organizations/${organizationId}`;
}

export default config;