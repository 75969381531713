import { createEntity, selectEntity, deleteEntity, updateEntity, displayError, readEntityById, readEntity, readLocal, ApiUtil } from 'reactifi';
import i18n from 'lib/i18n';

export { clearMessages, displayError, displayErrorMessage } from 'reactifi';

export function createItem(item) {
  return createEntity(item, null, i18n.t("Identity provider created successfully."));
}

export function loadLoactions() {
  return readEntity('locations');
}

export function loadCertificates() {
  return readEntity('saml_certificates');
}

export function loadItems() {
  return readEntity('saml_id_providers');
}

export function loadItem(id) {
  return readEntityById(id, 'saml_id_providers');
}

export function selectItem(identityProviderId) {
  return selectEntity(identityProviderId, 'saml_id_providers');
}

export function updateItem(item, filters) {
  return updateEntity(item, (dispatch) => {
    dispatch(selectEntity(null, 'saml_id_providers'));
  }, i18n.t("Identity provider updated successfully"));
}

export function deleteItem(item) {
  return async (dispatch) => {
    try {
      await dispatch(deleteEntity(item, 'saml_id_providers', i18n.t("Identity provider deleted successfully.")));
      dispatch(selectItem(null));
    } catch (e) {
      dispatch(displayError(e));
    }
  }
}

export function findOrganization(id) {
  return readEntityById(id, 'organizations');
}

export function updateOrganization(organization) {
  return updateEntity(organization, (dispatch) => {
    dispatch(readEntityById(organization.id, 'organizations'));
  }, organization.sso_exclusive ?
    i18n.t('"Use SSO Exclusively" was sucessfully enabled') :
    i18n.t('"Use SSO Exclusively" was sucessfully disabled')
  );
}

export function getSamlMetadata(orgSlug) {
  return async (dispatch) => {
    const url = `/${orgSlug}/saml/metadata.json`;
    const data = await ApiUtil.loadApiData(url, false);
    dispatch(readLocal({
      data: [{
        attributes: data,
        type: 'saml_metadata'
      }]
    }));
  }
}
