import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { buildGenericProps, JsonApiDecorator } from 'reactifi';
import { orgCapabilitiesActions, orgUsersActionCreators } from '../actions';
import OrganizationUsers from '../components/OrganizationUsers';

import { sortBy, map, values } from 'lodash';

function mapStateToProps(state, ownProps) {
  let props = buildGenericProps(state, 'users', ['organization', 'location']);
  props = Object.assign(props, {
    errorMessages: state.api.errorMessages,
    googleMapsApiKey: ownProps.route.googleMapsApiKey,
    successMessage: state.api.successMessage,
    errorMessage: state.api.errorMessage,
    activePage: state.api.activePage,
    activeFilters: state.api.activeFilters,
    activeSort: state.api.activeSort,
    activeSearch: state.api.activeSearch,
    permissions: ownProps.route.permissions,
    userBusinessLines: ownProps.route.userBusinessLines,
    userManagedTeams: ownProps.route.userManagedTeams,
    userTeams: ownProps.route.userTeams,
    userIsPiiRestrictedNextAdvisor: ownProps.route.userIsPiiRestrictedNextAdvisor,
    userIsPrimaryManager: ownProps.route.userIsPrimaryManager,
    userIsSecondaryManager: ownProps.route.userIsSecondaryManager,
    userIsTeamManager: ownProps.route.userIsTeamManager,
    userManagedBusinessLines: ownProps.route.userManagedBusinessLines,
    orgCapabilities: ownProps.route.orgCapabilities || state.api?.capabilities?.data?.map(cap => cap.attributes.capability_type)
  });

  props.isAdvisor = ownProps.route.userRuleSetRoles ? ['advisor', 'pii_restricted_next_advisor'].some(val => Object.values(ownProps.route.userRuleSetRoles).includes(val)) : null;

  let apiStore = new JsonApiDecorator(state.api);

  const { locations, contents, business_lines, rule_sets, org_rule_sets } = apiStore;
  props.orgRoleOptions = [];
  props.currentOrganizationId = ownProps.params.id || ownProps.route.organization_id;
  props.organizationSlug = ownProps.route.organization_slug;
  props.isManager = ownProps.route.isManager;

  if ('organizations' in apiStore) {
    props.organization = apiStore.organizations.find(props.currentOrganizationId, 'capabilities');

    // for FIT admin, org capabilities does not come from the route, but incuded in the organization
    if (!props.orgCapabilities && props.organization && props.organization.capabilities) {
      props.orgCapabilities = props.organization.capabilities.map(cap => cap.capability_type);
    }
  }

  props.locations = locations ? locations.all() : [];
  props.contents = contents ? contents.all() : [];
  props.businessLines = business_lines ? business_lines.all() : [];
  props.ruleSets = rule_sets ? rule_sets.all() : [];
  props.orgRuleSets = org_rule_sets ? org_rule_sets.all() : [];

  mapCategories(props, apiStore);
  mapRoleDisplay(props);

  props.userMeta = state.api.users ? state.api.users.meta : null;
  props.currentUser = props.currentEntity;
  props.jwt = ownProps.route.jwt;
  props.viewer = ownProps.route.viewer;
  props.googleMapsApiKey = ownProps.route.googleMapsApiKey;
  props.contentServiceUrl = ownProps.route.contentServiceUrl;

  if (props.currentUser) {
    props.currentUser.organization_id = props.currentOrganizationId;
  }

  mapRegistrationSets(apiStore, props);

  return props;
}

function mapRegistrationSets(apiStore, props) {
  if (apiStore.registration_sets) {
    props.registrationSets = apiStore.registration_sets.first();
    props.registrations = props.registrationSets.registrations;
    props.organizationName = props.registrationSets.organization_name || '';
    props.organizationId = props.registrationSets.organization_id || '';
  } else {
    props.registrations = null;
  }
}

function mapCategories(props, apiStore) {
  props.categories = [];

  if (apiStore.categories) {
    props.categories = apiStore.categories.all('category_labels');
    props.categories.forEach(category => {
      category.category_labels.forEach(categoryLabel => {
        categoryLabel.category_id = category.id
      })
    })
  }
}

function mapRoleDisplay(props) {
  props.users.forEach(user => {
    if (user.user_types) {
      user.ruleSetsDisplay = sortBy(map(user.user_types, (value, key) => `${key}: ${value}`)).join(', ')
      user.rolesDisplay = sortBy(values(user.user_types).filter(item => item !== 'default')).join(', ');
    }

    if (user.rule_set_roles) {
      user.ruleSetIds = Object.keys(user.rule_set_roles);
      user.rule_sets.forEach(ruleSet => {
        user[`${ruleSet.id}_selectedRole`] = ruleSet.role;
      });
    }
  });
}

class OrganizationUsersContainer extends Component {
  static propTypes = {
    activePage: PropTypes.any,
    activeFilters: PropTypes.any,
    activeSort: PropTypes.any,
    activeSearch: PropTypes.any,
    contentServiceUrl: PropTypes.string.isRequired,
    currentOrganizationId: PropTypes.string.isRequired,
    dispatch: PropTypes.func.isRequired,
    googleMapsApiKey: PropTypes.string,
    jwt: PropTypes.string.isRequired,
    orgCapabilities: PropTypes.array,
    userBusinessLines: PropTypes.array,
    userRuleSetRoles: PropTypes.object
  };

  constructor(props) {
    super(props);
    this.actions = bindActionCreators({ ...orgUsersActionCreators, ...orgCapabilitiesActions }, this.props.dispatch);
    this.actions.setActivePage = (page) => {
      this.actions.setActivePageAndFetch(page);
    };
    this.actions.setActiveFilters = (filters, resetSearch) => {
      this.actions.setActiveFiltersAndFetch(filters, resetSearch, this.props.activeSort, this.props.activeSearch);
    };
    this.actions.setActiveSort = (sort) => {
      this.actions.setActiveSortAndFetch(sort);
    };
    this.actions.setActiveSearch = (search) => {
      this.actions.setActiveSearchAndFetch(search);
    };
    this.actions.refreshUsers = () => {
      //re-declare these here so that we are sure to get the current props
      //instead of a snapshot from the value at time of construction
      const { activeFilters, activePage, activeSort, activeSearch } = this.props;
      this.actions.findUsers(activeFilters, activePage, activeSort, activeSearch);
    };
  }

  setUpOrganizationUsersContainer = () => {
    const { contentServiceUrl, currentOrganizationId, jwt, userBusinessLines } = this.props;

    this.actions.loadRuleSets(currentOrganizationId, userBusinessLines);
    this.actions.loadOrgRuleSets(currentOrganizationId);
    this.actions.loadBusinessLines();

    const categoryFilters = { organization_id: currentOrganizationId };

    if (userBusinessLines && userBusinessLines.length === 1) {
      categoryFilters.business_line = userBusinessLines[0];
    }

    this.actions.loadCategories(categoryFilters);
    this.actions.findLocations({ organization_id: currentOrganizationId });
    this.actions.findContent(contentServiceUrl, jwt, currentOrganizationId, userBusinessLines);
  }

  componentDidMount() {
    this.setUpOrganizationUsersContainer();
  }

  componentDidUpdate(prevProps) {
    const { currentOrganizationId, orgCapabilities } = this.props;
    if (prevProps.currentOrganizationId !== currentOrganizationId) {
      this.setUpOrganizationUsersContainer();
      this.actions.findCapabilities({ organization_id: currentOrganizationId });
      this.actions.selectOrgCapabilities(currentOrganizationId);
    }
    if (!!prevProps.orgCapabilities && !!orgCapabilities && (prevProps.orgCapabilities.length !== orgCapabilities.length && !orgCapabilities.length)) {
      this.actions.findCapabilities({ organization_id: currentOrganizationId });
      this.actions.selectOrgCapabilities(currentOrganizationId);
    }
  }

  render() {
    return (
      <OrganizationUsers {...this.props} {...this.actions} />
    );
  }
}

export default connect(mapStateToProps)(OrganizationUsersContainer);
