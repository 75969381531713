import React, { Component } from "react";
import PropTypes from "prop-types";
import i18n from "lib/i18n";
import { ConfirmationModal, GenericModal, CopyUtil, Core } from "reactifi";

export default class OrgUserTempPasswordModal extends Component {
  static propTypes = {
    currentUserId: PropTypes.string,
    displayErrorMessage: PropTypes.func,
    displayError: PropTypes.func,
    displaySuccessMessage: PropTypes.func,
    singleUsePasswordHours: PropTypes.number
  };

  constructor() {
    super();
    this.state = {
      showTempPasswordModal: "",
      showConfirmationModal: false
    };
  }

  setTemporaryPassword = (password) => {
    this.setState({ showTempPasswordModal: password });
  };

  triggerTemporaryPassword = () => {
    this.setState({ showConfirmationModal: true });
  };

  onCancelTemporaryPassword = () => {
    this.setState({ showConfirmationModal: false });
  };

  fetchTemporaryPassword = async () => {
    this.onCancelTemporaryPassword();
    let tempPasswordReset = {
      data: {
        type: "single_use_passwords",
        attributes: {
          user_id: this.props.currentUserId
        }
      }
    };
    try {
      const response = await fetch("/api/data/single_use_passwords", {
        method: "POST",
        headers: {
          "Content-Type": "application/vnd.api+json",
          Accept: "application/vnd.api+json",
          "X-CSRF-Token": document.querySelector("meta[name=csrf-token]")
            .content
        },
        mode: "cors",
        credentials: "same-origin",
        body: JSON.stringify(tempPasswordReset)
      });

      const data = await response.json();

      if (data.errors) {
        this.props.displayErrorMessage(
          i18n.t("Creating temporary password failed")
        );
      } else {
        this.setTemporaryPassword(data.data.attributes.password_token);
      }
    } catch (e) {
      this.props.displayError("An error occured:", e);
    }
  };

  copyPassword = (e) => {
    const success = CopyUtil.copyText(
      this.state.showTempPasswordModal,
      document.querySelector("#temp-password-modal")
    );
    if (success) {
      this.props.displaySuccessMessage(i18n.t("Copied to clipboard"));
    } else {
      this.props.displayErrorMessage(i18n.t("Oops, unable to copy"));
    }
  };

  render() {
    const { singleUsePasswordHours } = this.props;
    const CopyButton = () => {
      return (
        <Core.IconButton
          ariaLabel={i18n.t("Copy password")}
          colorScheme="gray"
          icon={{ iconName: "clipboard", prefix: "far" }}
          size="sm"
          tooltip={{
            content: i18n.t("Copy password"),
            placement: "top"
          }}
          variant="ghost"
          onClick={(e) => this.copyPassword(e)}
          data-action="copy-tmp-password"
        />
      );
    };
    return (
      <React.Fragment>
        <ConfirmationModal
          title={i18n.t("Confirm Setting a Temporary Password:")}
          children={i18n.t(
            "Performing a temporary password reset will reset the user's password temporarily to the new password for {{ hours }} hour(s). Do you wish to continue?",
            { hours: singleUsePasswordHours }
          )}
          onConfirm={this.fetchTemporaryPassword}
          onCancel={this.onCancelTemporaryPassword}
          show={this.state.showConfirmationModal}
          confirmButtonText={i18n.t("Confirm")}
        />
        <GenericModal
          show={!!this.state.showTempPasswordModal}
          canClose={true}
          title={i18n.t("Temporary Password")}
          onClose={() => this.setTemporaryPassword("")}
        >
          <Core.Typography mb={"sm"}>
            {i18n.t(
              "This is a one-time password. The user will be prompted to change it upon using this password. This password expires after {{ hours }} hours.",
              { hours: singleUsePasswordHours }
            )}
          </Core.Typography>
          <Core.Layout id="temp-password-modal">
            <Core.Input
              type={Core.FieldTypes.text}
              id="copy-password"
              title="Password"
              value={this.state.showTempPasswordModal}
              isReadOnly={true}
              rightAddOn={<CopyButton />}
            />
          </Core.Layout>
        </GenericModal>
      </React.Fragment>
    );
  }
}
