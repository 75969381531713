import React from 'react';
import { App } from 'reactifi';
import CookiesContainer from './containers/CookiesContainer';

const index = (props) => {
  return (
    <App>
      <CookiesContainer path="/" osanoId={props.osanoId} />
    </App>
  );
}

export default index;
