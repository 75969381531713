import { isSameSecond, max } from 'date-fns';
/**
 * Returns the newest certificate (the one with the furthest expiration date)
 * @param {Array>} certificates Array of EVERFI SAML Certificates
 */
export const getNewestCertificate = certificates => {
  const furthestExpirationDate = max(certificates.map(cert => new Date(cert.expiration_date)));

  return certificates.find(cert => isSameSecond(furthestExpirationDate, new Date(cert.expiration_date)));
};
