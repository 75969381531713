import i18n from 'lib/i18n';
import { findOrganization } from '../actions/orgActionCreators';
import { copyImpactStory } from '../actions/orgImpactStoryActions';

const config = {
  entity: 'impact_stories',
  createMember: {
    successMessage: i18n.t("Impact Story created successfully.")
  },
  updateMember: {
    successMessage: i18n.t("Impact Story updated successfully.")
  },
  deleteMember: {
    successMessage: i18n.t("Impact Story deleted successfully.")
  },
  actionCreators: {
    copyMember: copyImpactStory,
    findOrganization
  },
  mapStateToProps
}

function mapStateToProps(state, ownProps) {

  const organization = ownProps.apiStore.organizations &&
    ownProps.apiStore.organizations.find(ownProps.params.id);

  let organizationRoles = {};
  if (organization) {
    /*
      convert org roles from:
      {
        adminifi: {
          executive: { name: "Executive" }
        },
        compliance: {
          cc_admin: { name: "Employee Training Admin" },
          he_admin: { name: "Higher Education Training Admin" },
          fac_staff_admin: { name: "Faculty/Staff Admin" }
        },
        engage: {
          event_manager: { name: "Events Admin" }
        },
        next: {
          at_work_manager: { name: "Financial Education Admin" }
        }
      }
      to:
      {
        executive: "Executive",
        cc_admin: "Employee Training Admin",
        he_admin: "Higher Education Training Admin",
        fac_staff_admin: "Faculty/Staff Admin",
        event_manager: "Events Admin",
        at_work_manager: "Financial Education Admin"
      }
    */
    organizationRoles = Object.values(organization.roles)
      .map((appRoles) => Object.entries(appRoles).map(
        ([role, roleObject]) => ({ id: role, name: roleObject.name })
      )
      )
      .flat()
      .reduce((roleMap, role) => ({ ...roleMap, [role.id]: role.name }), {});
  }

  return {
    organization,
    organizationRoles
  };
}

export default config;
