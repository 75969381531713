import React from 'react';
import { PagingDataList } from 'reactifi/dist/es/components/Table/PagingDataList';
import { Capitalize } from 'reactifi/dist/es/utils/StringUtil';
import PropTypes from 'prop-types';
import i18n from 'lib/i18n';

export default class TrainingPeriodsTable extends React.Component {
  static propTypes = {
    activePage: PropTypes.number,
    businessLines: PropTypes.arrayOf(
      PropTypes.shape({
        slug: PropTypes.string,
        label: PropTypes.string
      })
    ),
    data: PropTypes.array,
    fieldsProps: PropTypes.shape({
      onEdit: PropTypes.func,
      onDelete: PropTypes.func
    }).isRequired,
    isEverfiAdmin: PropTypes.bool,
    showActions: PropTypes.bool,
    loadAssignmentsByTrainingPeriodIds: PropTypes.func,
    userManagedBusinessLines: PropTypes.arrayOf(PropTypes.string)
  };

  constructor(props) {
    super(props);
    this.state = {
      deletableTrainingPeriods: []
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.isEverfiAdmin) {
      if ((prevProps.data.length !== this.props.data.length) || (prevProps.activePage !== this.props.activePage)){
        this.setDeletableTrainingPeriods();
      }
    }
  }

  editAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.fieldsProps.onEdit(row);
  }

  deleteAction = (e, row) => {
    e.preventDefault();
    e.stopPropagation();
    this.props.fieldsProps.onDelete(row);
  }

  canEditRow = row => {
    const { userManagedBusinessLines, isEverfiAdmin } = this.props;

    return isEverfiAdmin || row.business_lines.some(lob => userManagedBusinessLines.includes(lob));
  }

  setDeletableTrainingPeriods = async () => {
    const trainingPeriodIds = this.props.data.map(trainingPeriod => String(trainingPeriod.id));
    const assignmentsWithTrainingPeriods = await this.props.loadAssignmentsByTrainingPeriodIds(trainingPeriodIds);
    const deletableTrainingPeriodsIds = [...new Set(assignmentsWithTrainingPeriods.map(tp => String(tp.attributes.training_period_id)))];

    this.setState({
      deletableTrainingPeriods: trainingPeriodIds.filter(tp => !deletableTrainingPeriodsIds.includes(tp))
    });
  }
  
  get actions() {
    if (!this.props.showActions) {
      return null;
    }
    return [
      {
        icon: 'pen',
        title: i18n.t('Edit'),
        tooltip: i18n.t('Edit Training Period'),
        type: 'view',
        actionType: 'callToAction',
        action: this.editAction
      },
      ...this.props.isEverfiAdmin ? [{
        icon: 'trash',
        title: i18n.t('Delete'),
        tooltip: i18n.t('Delete Training Period'),
        type: 'view',
        actionType: 'callToAction',
        action: this.deleteAction,
        disabled: row => row.undeletable
      }] : []
    ];
  }

  get fields() {
    let fields = [
      { name: 'name', title: i18n.t('Training Period Name'), width: '33%', canSort: false },
      { name: 'description', title: i18n.t('Description'), width: '33%', canSort: false },
      {
        name: 'business_lines',
        title: i18n.t('Lines of Business'),
        width: '33%',
        canSort: false,
        formatter: cell => {
          const businessLines = this.props.businessLines.filter(lob => cell.includes(lob.slug));
          return businessLines.map(lob => lob.label).join(', ');
        }
      },
      {
        name: 'status',
        title: i18n.t('Status'),
        width: 120,
        canSort: false,
        formatter: cell => cell ? Capitalize(cell) : ''
      }
    ];

    return fields;
  }

  get trainingPeriods () {
    if (!this.props.isEverfiAdmin) {
      return this.props.data;
    }

    return this.props.data.map(tp => Object.assign({}, tp, {
      undeletable: !this.state.deletableTrainingPeriods.includes(tp.id)
    }));
  }

  render() {
    const { fieldsProps, ...props } = this.props; // eslint-disable-line no-unused-vars

    return (
      <PagingDataList
        {...props}
        data={this.trainingPeriods}
        fields={this.fields}
        actions={this.actions}
        keyField="id"
        resultsDisplayed={false}
      />
    );
  }
}
