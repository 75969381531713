import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  PageWrapper, MessagesModule, Header, Section, ExplainerText,
  PagingDataList, ConfirmationModal, StringUtil, FAIcon, Button, FilterBar
} from 'reactifi';

import i18n from 'lib/i18n';
import debounce from 'lodash/debounce';
import { PREVIEW, PROCESSING, DONE, COMPLETE } from './UploadConstants';

const REFRESH_INTERVAL = 5000;

export default class UploadList extends Component {
  static propTypes = {
    actionButton: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.object,
      PropTypes.func
    ]),
    actionFilters: PropTypes.object,
    alwaysAllowDelete: PropTypes.bool,
    clearMessages: PropTypes.func,
    deleteAction: PropTypes.func,
    errorMessage: PropTypes.string,
    fields: PropTypes.array.isRequired,
    filtersConfig: PropTypes.array,
    findAction: PropTypes.func,
    initialFilters: PropTypes.object,
    instructions: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.node
    ]),
    isLoading: PropTypes.bool,
    list: PropTypes.array.isRequired,
    listMeta: PropTypes.shape({
      total_count: PropTypes.number
    }),
    organizationId: PropTypes.string,
    router: PropTypes.object.isRequired,
    successMessage: PropTypes.string,
    searchBox: PropTypes.object,
    title: PropTypes.string.isRequired,
    uploadLabel: PropTypes.string,
    uploadDataAction: PropTypes.string
  };

  static defaultProps = {
    list: []
  };

  constructor(props) {
    super(props);
    this.refreshList = debounce(this.refreshList, 350, { leading: false, trailing: true });
    this.state = {
      activePage: 1,
      sortOrder: "desc",
      sortName: "updated_at",
      filters: {},
      searchValue: ''
    };
  }

  componentWillUnmount() {
    clearTimeout(this.interval);
    this.interval = null;
  }

  onPageChange = activePage => {
    this.setState({ activePage }, this.refreshList);
  };

  onSortChange = (sortName, sortOrder) => {
    this.setState({
      sort: {
        value: sortName,
        direction: sortOrder
      },
      sortName,
      sortOrder,
      activePage: 1
    }, this.refreshList);
  };

  onFiltersChange = (filters) => {
    this.setState({
      filters,
      activePage: 1
    }, this.refreshList);
  };

  onSearchChange = ({ searchValue }) => {
    this.setState({
      searchValue,
      activePage: 1
    }, this.refreshList);
  };

  autoRefreshList = () => {
    this.interval = null;
    this.refreshList();
    this.setState({ autoRefresh: true });
  };

  refreshList = async () => {
    this.props.clearMessages();
    this.setState({ autoRefresh: false });

    const filters = { ...this.state.filters, ...this.props.actionFilters };
    await this.props.findAction(filters, { number: this.state.activePage }, this.state.sort, this.state.searchValue);
    if (!this.props.list.some(item => item.status === PROCESSING)) {
      return;
    }
    if (!this.interval) {
      this.interval = setTimeout(this.autoRefreshList, REFRESH_INTERVAL);
    }
  };

  addUpload = () => {
    this.props.router.push('/add');
  };

  cancelDelete = () => {
    this.setState({ confirmUploadDelete: null });
  }

  confirmDelete = (e, row) => {
    if (e) e.stopPropagation();
    this.setState({ confirmUploadDelete: row });
  }

  deleteRow = () => {
    this.props.deleteAction(this.state.confirmUploadDelete);
    this.setState({ confirmUploadDelete: null });
  };

  render() {
    const {
      alwaysAllowDelete, deleteAction, fields, filtersConfig,
      initialFilters, instructions, isLoading, list, listMeta,
      organizationId, router, searchBox, title, uploadLabel, uploadDataAction
    } = this.props;

    const options = {
      onSortChange: this.onSortChange,
      sortOrder: this.state.sortOrder,
      sortName: this.state.sortName,
      onRowClick: (e, row) => {
        if (row.status === PREVIEW) {
          router.push(`${row.id}`);
        }
      }
    };

    const combinedFields = fields.concat([
      {
        canSort: true,
        name: 'status',
        title: i18n.t('Status'),
        width: '12%',
        formatter: (cell, row) => {
          let icon;
          let _status = ![DONE, COMPLETE].includes(row.status) ? 'Incomplete' : row.status;
          switch (row.status) {
            case DONE:
            case COMPLETE:
              icon = <FAIcon icon="check" />
              break;
            case PREVIEW:
              icon = <FAIcon icon="eye" />
              break;
            case PROCESSING:
              icon = <FAIcon icon="spinner" spin={true} />
              break;
            default:
              icon = <FAIcon icon="circle" style="light" />
          }
          if (row.status) {
            return (
              <div className="upload-status">
                {icon} {StringUtil.Capitalize(_status)}
              </div>
            )
          }
          return '';
        }
      }
    ]);

    const actions = deleteAction ?
      [
        {
          icon: 'trash',
          title: i18n.t('Delete'),
          filter: row => alwaysAllowDelete || ![DONE, COMPLETE, PROCESSING].includes(row.status),
          actionType: 'callToAction',
          action: this.confirmDelete
        }
      ] : null;

    let actionButton = this.props.actionButton || (
      <Button
        style="primary"
        onClick={this.addUpload}
        data-action={uploadDataAction}
        label={uploadLabel}
      />
    );

    return (
      <PageWrapper>
        <MessagesModule {...this.props} />
        <Header title={title} button={actionButton} />
        {instructions && (
          <Section>
            <ExplainerText>{instructions}</ExplainerText>
          </Section>
        )}
        <Section type="list">
          <FilterBar
            filterConfig={filtersConfig}
            initialFilters={initialFilters}
            searchBox={
              {
                ...searchBox,
                data: {
                  action: 'search-user-uploads',
                  id: organizationId,
                  type: "organizations"
                },
                isOnlySearch: true,
                fullWidth: false,
                searchAction: this.onSearchChange,
                value: this.state.searchValue,
                placeholder: i18n.t('Search')
              }
            }
            onSelectFilters={this.onFiltersChange}
          />
          <PagingDataList
            data={list}
            fields={combinedFields}
            actions={actions}
            keyField="id"
            options={options}
            isLoadingData={isLoading && !this.state.autoRefresh}
            onPageChange={this.onPageChange}
            totalCount={listMeta ? listMeta.total_count : 0}
            activePage={this.state.activePage}
            resultsDisplayed={!!listMeta}
          />
        </Section>
        {this.state.confirmUploadDelete &&
          <ConfirmationModal
            title={i18n.t('Delete User Upload?')}
            show={!!this.state.confirmUploadDelete}
            onConfirm={this.deleteRow}
            onCancel={this.cancelDelete}
          >
            {i18n.t('Are you sure you want to delete the user upload for %s?',
              { postProcess: 'sprintf', sprintf: [this.state.confirmUploadDelete.filename] })
            }
          </ConfirmationModal>
        }
      </PageWrapper>
    )
  }
}
