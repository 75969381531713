import {
  createEntity,
  deleteEntity,
  readEntity,
  selectEntity,
  updateEntity,
  displaySuccessMessage
} from 'reactifi';
import i18n from 'lib/i18n';

export { clearMessages, displayError } from 'reactifi';

export function selectStyleGuide(guideId) {
  return selectEntity(guideId, 'organization_brand_style_guides');
}

export function createStyleGuide(guide, goBack){
  return createEntity(guide, () => goBack(), i18n.t("Style Guide created successfully!"))
}

export function deleteStyleGuide(guide){
  return deleteEntity(guide, 'organization_brand_style_guides', i18n.t('Style Guide deleted successfully!'));
}

export function updateStyleGuide(guide, goBack) {
  return updateEntity(guide, async dispatch => {
    dispatch(displaySuccessMessage(i18n.t("Style Guide updated successfully!")));
    goBack();
  }, false);
}

export function loadDefaultStyleGuide() {
  return readEntity('brand_style_guide_configs')
}
