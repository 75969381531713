import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  ExplainerText, FileUpload, FloatingLabelFormField, FormModule,
  SelectControl, s3MediaUploader, useContainerProps
} from "reactifi";
import i18n from "lib/i18n";
import { FILE_TYPES } from '../configs/downloadsContainerConfig';

const DownloadForm = props => {
  props = useContainerProps(props);
  const { businessLines, currentEntity, displayError, selectMember } = props;

  const [mediaId, setMediaId] = useState();

  const selectedMediaId = mediaId || (currentEntity && currentEntity.media_id);

  const onFileUploadSuccess = response => {
    if (response && response.data && response.data.id) {
      setMediaId(response.data.id);
    }
  }

  const renderFileUpload = () => {
    const files = [];
    if (selectedMediaId) {
      files.push({ id: selectedMediaId });
    }

    return (
      <FloatingLabelFormField
        className="media-id-no-border"
        required={!selectedMediaId}
      >
        <FileUpload
          uploader={s3MediaUploader}
          header={null}
          description={{
            className: 'file-upload-description',
            textArray: [i18n.t('File format: CSV or PDF')]
          }}
          required={!selectedMediaId}
          name="media_id"
          parameters={{
            acl: 'private'
          }}
          accept={['.csv', 'text/csv', '.xsl', 'application/vnd.ms-excel', '.pdf', 'application/pdf']}
          icon={{
            className: 'file',
            iconSize: 'medium',
            fileClassName: 'file',
            noFileClassName: 'file-slash'
          }}
          button={{ text: i18n.t("Upload File"), className: "btn btn-secondary" }}
          actions={{ success: onFileUploadSuccess, failure: displayError }}
          files={files}
          className="p-t-10"
        />
      </FloatingLabelFormField>
    );
  }

  const canChooseFileType = FILE_TYPES.length > 1;
  const title = currentEntity ? (
    currentEntity.id ?
      i18n.t('Edit Report') :
      i18n.t('Add Report')
  ) : null;
  const instructions = i18n.t('Upload reports for this org using the tool below. Reports will be downloadable, and the file name will be visible to Primary Training Admins.');

  return (
    <FormModule
      onCancel={() => selectMember(null)}
      isModal={true}
      title={title}
    >
      <ExplainerText>{instructions}</ExplainerText>
      {renderFileUpload()}
      <input name="media_id" type="hidden" value={selectedMediaId} />
      <FloatingLabelFormField name="name" caption={i18n.t("File Name")} required={true} />
      <FloatingLabelFormField name="business_line" caption={i18n.t("Select Line of Business")} required={true}>
        <SelectControl options={businessLines} valueKey="id" labelKey="name" />
      </FloatingLabelFormField>
      <FloatingLabelFormField name="year" type="number" caption={i18n.t("Enter Year")} min={1971} max={2199} />
      {canChooseFileType &&
        <FloatingLabelFormField name="file_type" caption={i18n.t("File Type")} required={true}>
          <SelectControl options={FILE_TYPES} valueKey="type" labelKey="label" />
        </FloatingLabelFormField>}
    </FormModule>
  );
}

DownloadForm.propTypes = {
  businessLines: PropTypes.array,
  currentEntity: PropTypes.object,
  displayError: PropTypes.func,
  selectMember: PropTypes.func
};

export default DownloadForm;
