import {
  createEntity,
  deleteEntity,
  readEntity,
  clearApiState,
  baseEndpointPath
} from 'reactifi';
import i18n from 'lib/i18n';

import { readEndpoint, setEndpointPath } from 'redux-json-api';

export { clearMessages, displayError } from 'reactifi';

export function selectOrgCapabilities(organizationId){
  return async (dispatch) => {
    dispatch(clearApiState('capabilities'));
    await dispatch(readEndpoint(`organizations/${organizationId}/capabilities`));
  }
}

export function deleteOrgCapability(organizationId, capability){
  return async (dispatch) => {
    await dispatch(setEndpointPath(`${baseEndpointPath}/organizations/${organizationId}`));
    await dispatch(deleteEntity(capability, 'capabilities'));
    await dispatch(setEndpointPath(`${baseEndpointPath}`));
    await dispatch(selectOrgCapabilities(organizationId));
  }
}

export function updateOrgCapabilities(organizationId, capabilities) {
  return async (dispatch) => {
    await dispatch(setEndpointPath(`${baseEndpointPath}/organizations/${organizationId}`));
    await dispatch(createEntity(capabilities, null, i18n.t('Capabilities updated successfully!')));
    await dispatch(setEndpointPath(baseEndpointPath));
    await dispatch(selectOrgCapabilities(organizationId));
  }
}

export function findCapabilities(payload = {}) {
  return readEntity('capabilities', '', payload);
}
